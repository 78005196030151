import { gql } from '@apollo/client';

export const FullCategoryNode = gql`
  fragment FullCategoryNode on Category {
    id
    databaseId
    name
    slug
    seo {
      fullHead
      title
      metaDesc
      canonical
    }
    blocks {
      id
      attributes {
        name
        value
      }
      innerHtml
      tagName
      type
    }
    categoryImage {
      categoryDescription
      categoryImage {
        mediaItemUrl
      }
      categoryImageHeader {
        mediaItemUrl
      }
    }
  }
`;

// GET_CATEGORY calls `categories` because `category` query will return english category even for different langugage slugs
// Whereas `categories` with `where` works well
export const GET_CATEGORY = gql`
  query Category($slug: [String]!, $wpmlLanguage: String) {
    categories(where: { slug: $slug, wpmlLanguage: $wpmlLanguage }) {
      edges {
        node {
          ...FullCategoryNode
        }
      }
    }
  }
  ${FullCategoryNode}
`;

export const CategoryNode = gql`
  fragment CategoryNode on Category {
    id
    name
    slug
    categoryImage {
      categoryDescription
      categoryImage {
        mediaItemUrl
      }
    }
    # hack to understand a category locale
    posts(first: 1) {
      nodes {
        id
        locale {
          id
          locale
        }
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query Categories(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: RootQueryToCategoryConnectionWhereArgs
  ) {
    categories(
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
    ) {
      nodes {
        ...CategoryNode
      }
    }
  }
  ${CategoryNode}
`;

export const GET_ALL_CATEGORY_SLUGS = gql`
  query GetAllCategorySlugs($after: String, $before: String, $first: Int) {
    categories(after: $after, before: $before, first: $first) {
      nodes {
        id
        name
        slug

        # hack to understand a category locale
        posts(first: 1) {
          nodes {
            id
            locale {
              id
              locale
            }
          }
        }
      }
      pageInfo {
        startCursor
        hasNextPage
      }
    }
  }
`;

export const GET_ALL_POSTS_SLUGS = gql`
  query GetAllPostsSlugs($after: String, $before: String, $first: Int) {
    posts(
      after: $after
      before: $before
      first: $first
      where: { orderby: { field: MODIFIED, order: DESC } }
    ) {
      nodes {
        id
        slug
        locale {
          locale
        }
        translations {
          href
          locale
        }
      }
      pageInfo {
        endCursor
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_ALL_PAGES_SLUGS = gql`
  query GetAllPagesSlugs($after: String, $before: String, $first: Int) {
    pages(after: $after, before: $before, first: $first) {
      nodes {
        id
        slug
        locale {
          locale
        }
      }
      pageInfo {
        startCursor
        hasNextPage
      }
    }
  }
`;
