import React, { useCallback, useMemo, useState } from 'react';

/**
 * Accordion - provides a context to child collapse components to ensure that
 * only one Collapse is open at a given time, and injects an "itemKey" prop
 * to allow for open-state tracking.
 */

export type AccordionContextState = {
    openItemKey: number | string | null;
    toggleOpenItem: (key: number | string) => void;
};

export const AccordionContext = React.createContext<AccordionContextState>({
    openItemKey: null,
    toggleOpenItem: () => undefined,
});

export interface AccordionProps {
    children: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({ children }: AccordionProps) => {
    const [openItemKey, setOpenItem] = useState<number | string | null>(null);

    const toggleOpenItem = useCallback(
        (key: number | string) => {
            if (key === openItemKey) {
                setOpenItem(null);
                return;
            }

            setOpenItem(key);
        },
        [openItemKey, setOpenItem],
    );

    const keyedChildren = useMemo(
        () =>
            React.Children.map(children, (child, index) => {
                if (!React.isValidElement(child)) {
                    return;
                }
                return React.cloneElement(child, { itemKey: index });
            }),
        [children],
    );

    return (
        <AccordionContext.Provider value={{ openItemKey, toggleOpenItem }}>{keyedChildren}</AccordionContext.Provider>
    );
};

Accordion.displayName = 'Accordion';
