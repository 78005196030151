import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHelpFilled = ({ size, title = 'HelpFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 3a9 9 0 110 18 9 9 0 010-18zm0 12.857a1.286 1.286 0 100 2.572 1.286 1.286 0 000-2.572zm0-10.286a3.536 3.536 0 00-3.536 3.536.964.964 0 001.929 0c0-.887.72-1.607 1.607-1.607 1.044 0 1.607.51 1.607 1.607 0 .532-.195.807-.936 1.42l-.095.079c-1.083.895-1.54 1.526-1.54 2.68a.964.964 0 101.928 0c0-.407.167-.637.84-1.194l.096-.079c1.146-.947 1.636-1.638 1.636-2.906 0-2.208-1.468-3.536-3.536-3.536z"
        />
    </svg>
);

export default withIcon(SvgHelpFilled);
