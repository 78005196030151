import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { Button } from 'ui';

import styles from './SubscriptionForm.module.scss';

interface SubscriptionFormProps {
  className?: string;
  formType?: string;
}

export const SubscriptionForm: FC<SubscriptionFormProps> = ({
  className,
  formType = 'subscription',
}) => {
  const [email, setEmail] = useState<string>('');
  const [successMessageVisibility, setSuccessMessageVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    if (successMessageVisibility) {
      setTimeout(() => {
        setEmail('');
        setSuccessMessageVisibility(false);
      }, 3000);
    }
  }, [successMessageVisibility]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSuccessMessageVisibility(true);
  };

  const SuccessMessage = () => {
    if (!successMessageVisibility) return null;

    return (
      <span className={styles.successMessage}>
        Thanks for signing up to the LANDR newsletter!
      </span>
    );
  };

  return (
    <form className={cn(styles.root, className)} onSubmit={handleFormSubmit}>
      <div className={cn(styles.inputContainer)}>
        <input
          className={cn(styles.input)}
          type="email"
          name="email"
          placeholder={successMessageVisibility ? '' : 'Your email address'}
          onChange={handleInputChange}
          value={email}
          required
        />
        <SuccessMessage />
      </div>
      <input type="text" name={formType} hidden />
      <Button className={styles.button}>Subscribe</Button>
    </form>
  );
};
