import styled from '../../utils/styled';
import { FontSize, Contrast } from '../../types/tokens';
import { fontSize, lineHeight, color as getColor } from '../../utils';
import { Box, SystemProps } from '../../primitives/Box';

export type TextProps = Omit<SystemProps, 'color' | 'size'> & {
    size?: FontSize;
    contrast?: Contrast;
    nbOfLines?: number;
};

export const Text = styled(Box)<TextProps>`
    color: ${({ contrast }) => (contrast ? getColor(`text.${contrast}`) : 'inherit')};
    font-size: ${({ size }) => size && fontSize(size)};
    line-height: ${({ size }) => size && lineHeight(size)};
`;

Text.displayName = 'Text';

Text.defaultProps = {
    size: 'md',
    as: 'span',
};
