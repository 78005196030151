import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCrownOutlined = ({ size, title = 'CrownOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M20 6l-4 4-4-4-4 4-4-4v11a1 1 0 001 1h14a1 1 0 001-1V6z"
        />
    </svg>
);

export default withIcon(SvgCrownOutlined);
