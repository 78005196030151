import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgWarningFilled = ({ size, title = 'WarningFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 21a9 9 0 110-18 9 9 0 010 18zm0-4a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm-1-9.01v4.02a1 1 0 001 .99c.556 0 1-.444 1-.99V7.99A1 1 0 0012 7c-.556 0-1 .444-1 .99z"
        />
    </svg>
);

export default withIcon(SvgWarningFilled);
