import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHelpOutlined = ({ size, title = 'HelpOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M20.5 12.25a8.25 8.25 0 11-16.501-.001 8.25 8.25 0 0116.501.001z"
            />
            <circle cx={12.25} cy={16.5} r={1.25} fill="currentColor" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M10.25 9.667c0-.581.634-1.917 2.001-1.917s2.01.772 2.001 1.917c-.008 1.145-.872 1.54-1.264 1.815-.393.274-.737.851-.737 1.38v.638"
            />
        </g>
    </svg>
);

export default withIcon(SvgHelpOutlined);
