export const getValueFromPosition = (position: number, min: number, max: number, width: number) => {
    const ratio = position / width;

    let result = (max - min) * ratio + min;

    if (result > max) {
        result = max;
    } else if (result < min) {
        result = min;
    }

    return Math.round(result);
};

export const getPositionFromValue = (value: number, min: number, max: number, width: number) => {
    let interval = max - min;
    if (interval <= 0) {
        interval = 1;
    }

    const ratio = width / interval;

    let result = value;

    if (result > max) {
        result = max;
    } else if (result < min) {
        result = min;
    }

    result -= min;

    return Math.round(result * ratio);
};
