import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCloseOutlined = ({ size, title = 'CloseOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 12l-6.25 6.25L12 12l6.25 6.25L12 12zm0 0L5.75 5.75 12 12l6.25-6.25L12 12z"
            />
        </g>
    </svg>
);

export default withIcon(SvgCloseOutlined);
