import React from 'react';
import { forwardRef } from '../../system';
import { StackProps } from './types';
import * as Styled from './styled';

export const Stack = forwardRef<StackProps, 'div'>((props, ref) => {
    const { children, orientation = 'vertical', gutter = 'md', ...rest } = props;

    return (
        <Styled.Stack ref={ref} orientation={orientation} gutter={gutter} {...rest}>
            {children}
        </Styled.Stack>
    );
});

Stack.displayName = 'Stack';
