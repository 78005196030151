import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVolumeFilled = ({ size, title = 'VolumeFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M13.724 6.053a.5.5 0 00-.524.047L9.333 9H7.5A1.5 1.5 0 006 10.5v3A1.5 1.5 0 007.5 15h1.833l3.867 2.9a.5.5 0 00.8-.4v-11a.5.5 0 00-.276-.447zm2.753 2.572a.5.5 0 00-.663.75 3.5 3.5 0 010 5.25.5.5 0 00.663.75 4.5 4.5 0 000-6.75z"
        />
    </svg>
);

export default withIcon(SvgVolumeFilled);
