import styled from '../../utils/styled';
import { palette, themeMode } from '../../utils';
import { Box } from '../../primitives/Box';
import { ActiveRangeRailProps, SliderProps } from './types';

export const Handle = styled(Box)<{ radius: number; isDisabled: boolean }>`
    background-color: ${({ isDisabled }) => (isDisabled ? palette('neutral.400') : palette('primary.500'))};
    position: absolute;
    cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
    ${({ radius }) =>
        `
        width: ${radius * 2}px;
        height: ${radius * 2}px;
        border-radius: ${radius * 2}px;
        top: ${-radius}px;
        left: ${-radius}px;
    `};
    ${({ isDisabled }) => isDisabled && `pointer-events: none;`};
`;

export const Draggable = styled.div<{ position: number; radius: number; isDragging: boolean }>`
    transform: ${({ position, radius }) => `translateX(${position - radius / 2}px)`};
    position: absolute;
`;

export const Rail = styled(Box)<{ isDisabled: boolean }>`
    width: 100%;
    height: 2px;
    background-color: ${({ isDisabled }) =>
        isDisabled
            ? palette('neutral.400')
            : themeMode({ light: palette('neutral.300'), dark: palette('neutral.600') })};
`;

export const ActiveRangeRail = styled(Box)<ActiveRangeRailProps>`
    position: absolute;
    width: ${({ range }) => `${range.max - range.min}px`};
    height: 2px;
    background-color: ${({ isDisabled }) => (isDisabled ? palette('neutral.400') : palette('primary.500'))};
    transform: ${({ range, handleRadius }) => `translateX(${range.min - handleRadius}px)`};
`;

export const Slider = styled(Box)<SliderProps>`
    display: flex;
    flex-direction: column;
`;
