import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSpotifyFilled = ({ size, title = 'SpotifyFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="currentColor" fillRule="evenodd">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
                fillRule="nonzero"
                d="M17.323 10.978c-2.9-1.722-7.686-1.88-10.455-1.04a.842.842 0 11-.489-1.611c3.18-.965 8.464-.779 11.804 1.204a.842.842 0 01-.86 1.447zm-.094 2.552a.702.702 0 01-.965.231c-2.42-1.487-6.107-1.917-8.969-1.049a.703.703 0 01-.407-1.342c3.268-.992 7.331-.512 10.11 1.195.33.204.434.636.23.965zm-1.102 2.45a.56.56 0 01-.771.187c-2.114-1.291-4.774-1.583-7.906-.868a.56.56 0 11-.25-1.093c3.428-.784 6.369-.446 8.741 1.003a.56.56 0 01.186.772zM12 3a9 9 0 100 18 9 9 0 100-18z"
            />
        </g>
    </svg>
);

export default withIcon(SvgSpotifyFilled);
