import React, { useContext, useRef, useState } from 'react';
import { Notification, StyledNotification, NotificationProps } from '../../components/Notification';
import styled from '../../utils/styled';
import { zIndex } from '../../utils/theme';
import { spacing } from '../../utils';
import { Box } from '../../primitives/Box';
import { useBreakpoint } from '../useBreakpoint/useBreakpoint';
import { Portal } from '../../primitives/Portal';

export interface ToastConfigType extends Omit<NotificationProps, 'kind'> {
    content: React.ReactNode;
    timeout?: number;
    id: string;
}

type ToastMap = Map<string, ToastConfigType>;

interface ToastContextType {
    addToast: (toast: ToastConfigType) => void;
    closeToast: (id: string) => void;
}

const ToastContext = React.createContext<ToastContextType>({
    addToast: () => undefined,
    closeToast: () => undefined,
});

interface ToastWrapperProps {
    shouldHideToasts?: boolean;
    isMobile: boolean;
}

const ToastWrapper = styled(Box)<ToastWrapperProps>`
    display: flex;
    pointer-events: none;
    position: fixed;
    width: 100%;
    z-index: ${zIndex('alert')};
    opacity: ${({ shouldHideToasts }) => (shouldHideToasts ? 0 : 1)};
    transition: opacity 300ms ease-in-out, bottom 300ms ease-in-out;
    flex-direction: column;
    align-items: ${({ isMobile }: any) => (isMobile ? 'center' : 'flex-end')};

    ${StyledNotification} {
        margin-bottom: ${({ isMobile }: any) => (isMobile ? spacing('xs') : spacing('md'))};
        margin-right: ${({ isMobile }: any) => (isMobile ? 0 : spacing('md'))};
        pointer-events: all;
    }
`;

export type ToastProviderProps = {
    toastBottomMargin?: number;
    shouldHideToasts?: boolean;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({
    toastBottomMargin = 0,
    shouldHideToasts = false,
    children,
}) => {
    const [toastMap, setToastMap] = useState<ToastMap>(new Map());
    const toastCounter = useRef(0);
    const isMobile = useBreakpoint('md');

    const incrementCounter = (): void => {
        toastCounter.current += 1;
    };

    const closeToast = (key: string): void => {
        const toast = toastMap.get(key);

        toast?.onClose?.();

        toastMap.delete(key);

        setToastMap(new Map(toastMap));
    };

    const addToast = (toast: ToastConfigType): void => {
        incrementCounter();

        const { current } = toastCounter;

        toastMap.set(toast.id || current.toString(), {
            ...toast,
            timeout: toast.timeout,
        });

        setToastMap(new Map(toastMap));
    };

    return (
        <ToastContext.Provider value={{ addToast, closeToast }}>
            <Portal zIndex={2000}>
                <ToastWrapper
                    shouldHideToasts={shouldHideToasts}
                    isMobile={isMobile}
                    right={0}
                    bottom={toastBottomMargin}
                >
                    {[...toastMap].map(([key, { content, ...rest }]) => (
                        <Notification
                            key={key}
                            kind="persistent"
                            onClose={(): void => {
                                closeToast(key);
                            }}
                            {...rest}
                        >
                            {content}
                        </Notification>
                    ))}
                </ToastWrapper>
            </Portal>
            {children}
        </ToastContext.Provider>
    );
};

export const useToast = (): {
    addToast: (toast: ToastConfigType) => void;
    closeToast: (id: string) => void;
} => useContext<ToastContextType>(ToastContext);
