import React from 'react';

export function getContentHeight(contentRef: React.RefObject<HTMLDivElement>): number {
    let height = 0;

    if (contentRef !== null && contentRef.current) {
        height = contentRef.current.offsetHeight;
    }

    return height;
}
