import React, { ChangeEvent, ComponentPropsWithoutRef, ReactNode, Ref } from 'react';
import { useControlled } from '../../../hooks';
import { Box, BoxProps, VisuallyHidden } from '../../../primitives';
import { forwardRef } from '../../../system';

export type CheckboxRootProps = BoxProps & {
    children?: ReactNode;
    /** Whether or not the checkbox is checked */
    isChecked?: boolean;
    /** Whether or not the checkbox is partially selected */
    isIndeterminate?: boolean;
    /** Whether or not the checkbox is invalid */
    isInvalid?: boolean;
    /** Whether or not the checkbox is valid */
    isValid?: boolean;
    /** Whether or not the checkbox is disabled */
    isDisabled?: boolean;
    /** Props to be applied to the wrapper */
    wrapperProps?: ComponentPropsWithoutRef<'label'>;
    /** Ref to be applied to the wrapper */
    wrapperRef?: Ref<HTMLLabelElement>;
};

const Root = forwardRef<CheckboxRootProps, 'input'>((props, ref) => {
    const {
        children,
        as = 'input',
        value,
        name,
        id,
        isChecked: isCheckedProp,
        isIndeterminate,
        isDisabled,
        isInvalid,
        onChange,
        onClick,
        className,
        sx,
        wrapperProps,
        wrapperRef,
        ...rest
    } = props;

    const [isChecked, setIsChecked] = useControlled({ value: isCheckedProp, defaultValue: false });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange?.(event);
        setIsChecked(event.currentTarget.checked);
    };

    const handleWrapperClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => event.stopPropagation();

    return (
        <Box
            as="label"
            ref={wrapperRef}
            className={className}
            data-state={isChecked || isIndeterminate ? 'checked' : 'unchecked'}
            data-invalid={isInvalid}
            data-disabled={isDisabled}
            onClick={handleWrapperClick}
            sx={sx}
            {...wrapperProps}
        >
            <VisuallyHidden
                as={as}
                ref={ref}
                type="checkbox"
                id={id}
                name={name}
                value={value}
                onChange={handleChange}
                onClick={onClick}
                checked={isChecked}
                disabled={isDisabled}
                aria-invalid={isInvalid}
                aria-checked={isIndeterminate ? 'mixed' : isChecked}
                {...rest}
            />
            {children}
        </Box>
    );
});

type CheckboxControlProps = {
    children?: ReactNode;
};

const Control = forwardRef<CheckboxControlProps, 'div'>((props, ref) => {
    const { as = 'div', ...rest } = props;

    return <Box ref={ref} as={as} {...rest} />;
});

type CheckboxLabelProps = {
    children?: ReactNode;
};

const Label = forwardRef<CheckboxLabelProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Box ref={ref} {...rest}>
            {children}
        </Box>
    );
});

type CheckboxDescriptionProps = {
    children?: ReactNode;
};

const Description = forwardRef<CheckboxDescriptionProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Box ref={ref} {...rest}>
            {children}
        </Box>
    );
});

export const CheckboxPrimitive = Object.assign({}, { Root, Control, Label, Description });
