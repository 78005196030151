import { useEffect } from 'react';
import { AbstractSegmentServiceV2, AnalyticsLanguage } from '@landr/core';

interface ParsedUrlQuery {
  [key: string]: string | string[] | undefined;
}

export interface LocationProps {
  pathname: string;
  query: ParsedUrlQuery;
}

export interface UseAnalyticsPageTrackingProps {
  analytics: AbstractSegmentServiceV2;
  location: LocationProps;
  locale: AnalyticsLanguage;
  isRouterReady: boolean;
}

export const useAnalyticsPageTracking = ({
  analytics,
  location: { pathname, query },
  locale,
  isRouterReady,
}: UseAnalyticsPageTrackingProps): void => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY === undefined) {
      throw new Error('SEGMENT_WRITE_KEY environment variable was not set.');
    }

    analytics.load(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY);
  }, [analytics]);

  useEffect(() => {
    if (isRouterReady) {
      analytics.page(locale);
    }
  }, [pathname, query, locale, analytics, isRouterReady]);
};
