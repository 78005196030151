import React, { useState, useRef, FC } from 'react';
import cn from 'classnames';

import styles from './VolumeSlider.module.scss';

type VolumeSliderProps = {
  onVolumeChange: (time: number) => void;
  volume: number;
};

export const VolumeSlider: FC<VolumeSliderProps> = ({
  onVolumeChange,
  volume,
}) => {
  const volumeRef = useRef<HTMLInputElement | null>(null);

  const [grabbing, setGrabbing] = useState<boolean>(false);

  const calcVolumeValue = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): number => {
    const volumeBar = volumeRef.current;

    if (volumeBar) {
      const position =
        (e.pageX +
          1 -
          (volumeBar.getBoundingClientRect().x ||
            volumeBar.getBoundingClientRect().left)) /
        volumeBar.getClientRects()[0].width;

      const result = ((position / 100) * 100).toFixed(2);

      return Number(result);
    } else {
      return 0;
    }
  };

  const updateFillClasses = (value: number) => {
    const divList = volumeRef.current?.querySelectorAll('div');

    divList?.forEach((item, index) => {
      item.classList.remove('fill1');
      item.classList.remove('fill2');
      item.classList.remove('fill3');

      if (!value) {
        return;
      }

      if (index === 0) {
        if (value <= 0.25) {
          if (value <= 0.15) {
            item.classList.add('fill1');
          } else {
            item.classList.add('fill2');
          }
        } else {
          item.classList.add('fill3');
        }
      }

      if (index === 1) {
        if (value > 0.25 && value <= 0.5) {
          if (value <= 0.35) {
            item.classList.add('fill1');
          } else {
            item.classList.add('fill2');
          }
        } else if (value > 0.5) {
          item.classList.add('fill3');
        }
      }

      if (index === 2) {
        if (value > 0.6 && value <= 0.75) {
          if (value <= 0.65) {
            item.classList.add('fill1');
          } else {
            item.classList.add('fill2');
          }
        } else if (value > 0.75) {
          item.classList.add('fill3');
        }
      }

      if (index === 3) {
        if (value > 0.75 && value < 1) {
          if (value <= 0.85) {
            item.classList.add('fill1');
          } else {
            item.classList.add('fill2');
          }
        } else if (value > 0.85) {
          item.classList.add('fill3');
        }
      }
    });

    volumeRef.current?.setAttribute('aria-valuenow', `${value}`);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (grabbing) {
      const value = calcVolumeValue(e);

      updateFillClasses(value);
      onVolumeChange(value);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const value = calcVolumeValue(e);

    onVolumeChange(value);
    updateFillClasses(value);
  };

  const handleMouseUp = () => {
    setGrabbing(false);
  };

  const handleMouseDown = () => {
    setGrabbing(true);
  };

  return (
    <div
      ref={volumeRef}
      className={cn(styles.root, {
        [styles.grabbing]: grabbing,
      })}
      role="slider"
      title="Volume"
      aria-valuemin={0}
      aria-valuemax={1}
      aria-valuenow={volume}
      aria-valuetext="seek audio bar"
      tabIndex={0}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="fill3" />
      <div className="fill3" />
      <div className="fill3" />
      <div className="fill3" />
    </div>
  );
};
