import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProjectsBlue = ({ size, title = 'ProjectsBlue', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#435261"
            d="M35.25 21.75c0-5.376-5.72-9.75-12.75-9.75S9.75 16.374 9.75 21.75s5.72 9.75 12.75 9.75c.84 0 1.658-.066 2.453-.185l6.212 3.105a.744.744 0 00.73-.032.752.752 0 00.355-.638v-5.73c1.869-1.697 3-3.883 3-6.27z"
        />
        <path
            fill="#142739"
            d="M16.5 1.5C7.815 1.5.75 7.22.75 14.25c0 2.702 1.036 5.279 3 7.47v7.53a.752.752 0 001.085.671l6.998-3.498c1.526.383 3.094.577 4.667.577 8.685 0 15.75-5.72 15.75-12.75S25.185 1.5 16.5 1.5z"
        />
        <path
            fill="#3C6ED7"
            d="M24.29 15.673l-5.615 4.078-4.13-5.577a3.363 3.363 0 014.653-4.779c.372.276.682.625.912 1.027a3.365 3.365 0 114.18 5.248v.003z"
        />
        <path
            fill="white"
            d="M9.77 8.91l.615 7.448-1.017.155L7.735 9.22l2.034-.31zm.678 11.276a1.35 1.35 0 10-.407-2.67 1.35 1.35 0 00.407 2.67z"
        />
    </svg>
);

export default withIcon(SvgProjectsBlue);
