import React from 'react';
import { getClonedChild } from '../../utils';
import { Text, TextProps } from '../Text';
import { Heading, HeadingProps } from '../Heading';
import { Button, ButtonProps } from '../Button';
import { forwardRef } from '../../system';
import styled from '../../utils/styled';
import * as Styled from './styled';
import { BannerProps, BannerImageProps, BannerSourceProps } from './types';

const Banner = forwardRef<BannerProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;
    const childrenArray = React.Children.toArray(children);

    const title = getClonedChild(childrenArray, 'Banner.Title');
    const content = getClonedChild(childrenArray, 'Banner.Content');
    const action = getClonedChild(childrenArray, 'Banner.Action');
    const image = getClonedChild(childrenArray, 'Banner.Image');

    return (
        <Styled.Banner ref={ref}>
            {image}
            <Styled.Overlay {...rest}>
                <Styled.TextWrapper>
                    {title}
                    {content}
                </Styled.TextWrapper>
                {action}
            </Styled.Overlay>
        </Styled.Banner>
    );
});

Banner.displayName = 'Banner';

const Image = ({ src, alt, children }: BannerImageProps) => (
    <picture>
        {children}
        <Styled.Image alt={alt} src={src} />
    </picture>
);

Image.displayName = 'Banner.Image';

const Source = ({ srcSet, media }: BannerSourceProps) => <source srcSet={srcSet} media={media} />;

Source.displayName = 'Banner.Source';

const Title = styled(Heading)<HeadingProps>``;

Title.defaultProps = {
    as: 'h1',
    size: 'md',
    contrast: 'base',
    fontWeight: 'bold',
};

Title.displayName = 'Banner.Title';

const Content = styled(Text)<TextProps>``;

Content.defaultProps = {
    size: 'md',
};

Content.displayName = 'Banner.Content';

const Action = forwardRef<ButtonProps, 'button'>(({ children, ...props }, ref) => (
    <Button ref={ref} size="md" {...props}>
        {children}
    </Button>
));

Action.displayName = 'Banner.Action';

export default Object.assign(Banner, { Title, Content, Action, Image, Source });
