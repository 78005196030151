import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCommentsFilled = ({ size, title = 'CommentsFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            stroke="currentColor"
            strokeWidth={1.5}
            d="M4 5.244A1.25 1.25 0 015.24 4h14.02c.685 0 1.24.57 1.24 1.244v10.012c0 .687-.562 1.244-1.244 1.244H15.25l-5 4v-4h-5c-.69 0-1.25-.57-1.25-1.244V5.244z"
        />
    </svg>
);

export default withIcon(SvgCommentsFilled);
