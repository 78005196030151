import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProductSamplesOutlined = ({ size, title = 'ProductSamplesOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M20 11.893h-1c-2.16 0-2.16-3.946-3-3.946-.84 0-2.275 12.053-3.5 12.053S10.24 4 9 4c-1.24 0-1 8.107-3 8.107H4"
        />
    </svg>
);

export default withIcon(SvgProductSamplesOutlined);
