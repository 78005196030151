import React from 'react';
import { useFormControl } from '../FormControl';
import { forwardRef } from '../../system';
import { getSystemProps, omitSystemProps } from '../../system/styled-system';
import { mergeRefs, warn } from '../../utils';
import { InputProps } from './types';
import * as Styled from './styled';
import { InputAddon } from './InputAddon';

export const Input = forwardRef<InputProps, 'input'>((props, ref) => {
    const {
        className,
        shape = 'default',
        size = 'md',
        variant = 'outlined',
        isInvalid,
        isValid,
        isRequired,
        isDisabled,
        isReadOnly,
        type = 'text',
        autoComplete = 'off',
        addonLeft,
        addonRight,
        value,
        wrapperRef: wrapperRefProp = null,
        wrapperProps,
        defaultValue,
        isMultiline,
        inputRef: inputRefProp = null,
        sx,
        ...rest
    } = omitSystemProps(props);

    const { color, ...systemProps } = getSystemProps(props);

    const formControl = useFormControl({
        isInvalid,
        isValid,
        isRequired,
        isDisabled,
        isReadOnly,
    });

    warn({
        condition: !!inputRefProp,
        message: 'The inputRef prop is deprecated and will be removed in v1, use ref directly',
    });

    const inputRef = mergeRefs(inputRefProp, ref);

    return (
        <Styled.Wrapper
            className={className}
            ref={wrapperRefProp}
            size={size}
            shape={shape}
            variant={variant}
            isDisabled={formControl.isDisabled}
            isInvalid={formControl.isInvalid}
            isValid={formControl.isValid}
            isRequired={formControl.isRequired}
            isReadOnly={formControl.isReadOnly}
            isMultiline={isMultiline}
            sx={sx}
            {...systemProps}
            {...wrapperProps}
        >
            {!!React.Children.count(addonLeft) &&
                React.Children.map(addonLeft, (addon) =>
                    isMultiline ? addon : <InputAddon placement="left">{addon}</InputAddon>,
                )}
            <Styled.Input
                ref={inputRef}
                type={type}
                value={value}
                autoComplete={autoComplete}
                variant={variant}
                isDisabled={formControl.isDisabled}
                disabled={formControl.isDisabled}
                readOnly={formControl.isReadOnly}
                required={formControl.isRequired}
                data-invalid={formControl.isInvalid}
                defaultValue={defaultValue}
                isMultiline={isMultiline}
                size={size}
                {...rest}
            />
            {!!React.Children.count(addonRight) &&
                React.Children.map(addonRight, (addon) =>
                    isMultiline ? addon : <InputAddon placement="right">{addon}</InputAddon>,
                )}
        </Styled.Wrapper>
    );
});

Input.displayName = 'Input';
