import { createContext, ReactNode } from 'react';

interface TableContextValues {
    stickyHeader?: boolean;
    isRuled?: boolean;
    listenKeyEvents: boolean;
    onRowChange?: (rowIndex: number, event?: KeyboardEvent | MouseEvent) => void;
    setBodyNode: (node: ReactNode) => void;
    tableBodyNode?: HTMLElement;
    focusedRowIndex: number;
    setFocusedRowIndex: (index: number) => void;
    dispatchRowChangedEvent: (newRowIndex: number, didKeyboardNav: boolean) => void;
}

export const TableContext = createContext<TableContextValues>({
    dispatchRowChangedEvent: () => undefined,
    focusedRowIndex: -1,
    listenKeyEvents: false,
    setBodyNode: () => undefined,
    setFocusedRowIndex: (index: number) => undefined,
});
