import { default as Blog } from './Blog';
import { default as ChromaticDark } from './ChromaticDark';
import { default as Chromatic } from './Chromatic';
import { default as CommunityChannels } from './CommunityChannels';
import { default as Courses } from './Courses';
import { default as Creator } from './Creator';
import { default as Distribution } from './Distribution';
import { default as Insider } from './Insider';
import { default as LandrFx } from './LandrFx';
import { default as Mastering } from './Mastering';
import { default as NetworkPro } from './NetworkPro';
import { default as NetworkServices } from './NetworkServices';
import { default as Network } from './Network';
import { default as Plugins } from './Plugins';
import { default as ProjectsBlue } from './ProjectsBlue';
import { default as ProjectsRed } from './ProjectsRed';
import { default as SamplesDark } from './SamplesDark';
import { default as Samples } from './Samples';
import { default as SessionsDark } from './SessionsDark';
import { default as Sessions } from './Sessions';
import { default as Tutorials } from './Tutorials';
import { default as Vocalign } from './Vocalign';

export const ProductIcon = {
    Blog,
    ChromaticDark,
    Chromatic,
    CommunityChannels,
    Courses,
    Creator,
    Distribution,
    Insider,
    LandrFx,
    Mastering,
    NetworkPro,
    NetworkServices,
    Network,
    Plugins,
    ProjectsBlue,
    ProjectsRed,
    SamplesDark,
    Samples,
    SessionsDark,
    Sessions,
    Tutorials,
    Vocalign,
};
