import css from '@styled-system/css';
import styled from 'styled-components';
import { variant, themeMode } from '../../utils/theme';
import { Button } from '../Button';
import { ToggleButtonProps } from './types';

const sizeStyles = variant({
    prop: 'size',
    variants: {
        xs: { fontSize: 'sm' },
        sm: { fontSize: 'md' },
        md: { fontSize: 'md' },
        lg: { fontSize: 'lg' },
    },
});

const baseStyles = (props) =>
    css({
        color: themeMode({ light: 'neutral.900', dark: 'neutral.50' })(props),
        bg: 'transparent',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.700' })(props),
        '&:hover': {
            bg: themeMode({ light: 'neutral.200', dark: 'neutral.800' })(props),
            color: themeMode({ light: 'neutral.900', dark: 'neutral.50' })(props),
        },
        '&:focus-visible': {
            bg: themeMode({ light: 'neutral.200', dark: 'neutral.800' })(props),
            color: themeMode({ light: 'neutral.900', dark: 'neutral.50' })(props),
        },
        '&[aria-pressed="true"]': {
            color: themeMode({ light: 'neutral.50', dark: 'neutral.900' })(props),
            bg: themeMode({ light: 'neutral.900', dark: 'neutral.50' })(props),
            borderColor: themeMode({ light: 'neutral.1000', dark: 'neutral.0' })(props),
        },
        '&:disabled': {
            color: themeMode({ light: 'neutral.400', dark: 'neutral.700' })(props),
            bg: 'transparent',
            borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.700' })(props),
        },
        '&[aria-pressed="true"]:disabled': {
            color: themeMode({ light: 'neutral.400', dark: 'neutral.500' })(props),
            bg: themeMode({ light: 'neutral.800', dark: 'neutral.200' })(props),
            borderColor: themeMode({ light: 'neutral.1000', dark: 'neutral.0' })(props),
        },
    });

const ToggleButton = styled(Button)<ToggleButtonProps>`
    ${baseStyles};
    ${sizeStyles};
`;

const Styled = { ToggleButton };

export { Styled };
