import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgOrderOutlined = ({ size, title = 'OrderOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <g transform="translate(1 1.143)">
                <rect width={15} height={4} x={8} rx={0.5} />
                <rect width={15} height={4} x={8} y={7} rx={0.5} />
                <rect width={15} height={4} x={8} y={14} strokeLinecap="round" strokeLinejoin="round" rx={0.5} />
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 16C-.839 14-1.806 5 4 2m0 0v4" />
            </g>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 3.143H1" />
        </g>
    </svg>
);

export default withIcon(SvgOrderOutlined);
