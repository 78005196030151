import React, { isValidElement } from 'react';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { Text } from '../Text';
import { forwardRef } from '../../system';
import {
    InputGroupAddonProps,
    InputGroupIconProps,
    InputGroupButtonProps,
    InputGroupIconButtonProps,
    InputGroupTextProps,
} from './types';

export const InputGroupAddon: React.FC<InputGroupAddonProps> = ({ children }) => <>{children}</>;

const addonSpacingProps = (placement): { ml: string } | { mr: string } =>
    placement === 'left' ? { ml: 'sm' } : { mr: 'sm' };

export const InputGroupIcon: React.FC<InputGroupIconProps> = ({ placement, children, ...props }) => {
    const spacingProps = addonSpacingProps(placement);
    if (!isValidElement(children)) {
        return <></>;
    }
    return React.cloneElement(children, { ...spacingProps, ...props, ...children.props });
};

InputGroupIcon.displayName = 'InputGroup.Addon';

export const InputGroupButton = forwardRef<InputGroupButtonProps, 'button'>((props, ref) => (
    <Button ref={ref} {...props} />
));

InputGroupButton.displayName = 'InputGroup.Addon';

export const InputGroupIconButton = forwardRef<InputGroupIconButtonProps, 'button'>((props, ref) => (
    <IconButton ref={ref} {...props} />
));

InputGroupIconButton.displayName = 'InputGroup.Addon';

export const InputGroupText: React.FC<InputGroupTextProps> = ({ placement, ...props }) => {
    const spacingProps = addonSpacingProps(placement);
    return <Text {...spacingProps} {...props} />;
};

InputGroupText.displayName = 'InputGroup.Addon';
