import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgNetworkOutlined = ({ size, title = 'NetworkOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M9.761 6.517L6.176 10.59 9.76 6.517zm7.434 1.451l-4.77-2.052 4.77 2.052zm.34 1.827l-6.57 6.66 6.57-6.66zm.989.5l-.721 6.778.72-6.777zM6.89 11.609l10.22-2.592-10.22 2.591z"
            />
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M5.25 13.688a1.688 1.688 0 110-3.376 1.688 1.688 0 010 3.376zm13.5-3.376a1.688 1.688 0 110-3.375 1.688 1.688 0 010 3.375zm-7.875-3.374a1.687 1.687 0 110-3.375 1.687 1.687 0 010 3.374zM9.75 19.313a1.688 1.688 0 110-3.376 1.688 1.688 0 010 3.376zm7.875 1.125a1.688 1.688 0 110-3.376 1.688 1.688 0 010 3.375z"
            />
        </g>
    </svg>
);

export default withIcon(SvgNetworkOutlined);
