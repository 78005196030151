import React, { Fragment, useMemo, useRef, useState, useEffect, useCallback, SelectHTMLAttributes } from 'react';
import { useSelect } from 'downshift';
import { useFormControl } from '../FormControl';
import { List } from '../List';
import { Scrollable } from '../Scrollable';
import { Input } from '../Input';
import { PopoverBase } from '../PopoverBase';
import { forwardRef } from '../../system';
import { getSystemProps, omitSystemProps } from '../../system/styled-system';
import { SelectProps, SelectOption, SelectGroupOption, SelectGroupOptions } from './types';
import { getGroups, getOptionsByGroup } from './utils';
import { SelectInputComponent } from './SelectInputComponent';
import { DefaultOptionComponent } from './DefaultOptionComponent';
import { DefaultValueComponent } from './DefaultValueComponent';

function isValueDefined(value: SelectHTMLAttributes<unknown>['value']) {
    return typeof value !== 'undefined';
}

const Select = forwardRef<SelectProps, 'button'>((props, ref) => {
    const {
        options,
        shape = 'default',
        size = 'md',
        variant = 'outlined',
        isInvalid,
        isValid,
        isRequired,
        isDisabled,
        isReadOnly,
        defaultValue,
        placeholder,
        groupBy,
        onChange,
        valueComponent: ValueComponent = DefaultValueComponent,
        optionComponent: OptionComponent = DefaultOptionComponent,
        popoverProps,
        disablePortal,
        usePortal = false,
        value,
        ...rest
    } = omitSystemProps(props);

    const { color, width = '100%', ...systemProps } = getSystemProps(props);

    const anchorRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLElement>(null);

    const formControl = useFormControl({
        isInvalid,
        isValid,
        isRequired,
        isDisabled,
        isReadOnly,
    });

    const groups = useMemo(() => getGroups(groupBy, options), [options, groupBy]);

    const optionsWithIndexes = useMemo(
        () =>
            options.map((item: SelectOption, index) => ({
                ...item,
                index,
            })),
        [options],
    );

    const groupedOptions = useMemo(
        () => getOptionsByGroup(groupBy, groups, optionsWithIndexes),
        [groupBy, groups, optionsWithIndexes],
    );

    // Use our own state for `selectedItem` to force update when `optionsWithIndexes` is updated
    const getSelectedItemFromValue = useCallback(
        (value) => {
            return optionsWithIndexes.find((option) => option.value === value) || null;
        },
        [optionsWithIndexes],
    );
    const updateToDefaultItem = useCallback(() => {
        const defaultSelectedItem = getSelectedItemFromValue(defaultValue);
        setSelectedItem(defaultSelectedItem);
        if (onChange && defaultSelectedItem) {
            onChange(defaultSelectedItem);
        }
    }, [defaultValue, getSelectedItemFromValue, onChange]);
    const [selectedItem, setSelectedItem] = useState(() => {
        if (isValueDefined(value)) {
            return null;
        }

        return getSelectedItemFromValue(defaultValue);
    });

    useEffect(() => {
        if (isValueDefined(value)) {
            const newSelectedItem = getSelectedItemFromValue(value);
            if (!newSelectedItem || !newSelectedItem.disabled) {
                setSelectedItem(newSelectedItem);
            } else {
                updateToDefaultItem();
            }
        }
    }, [value, getSelectedItemFromValue, updateToDefaultItem]);

    const { isOpen, getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex } = useSelect({
        items: optionsWithIndexes,
        selectedItem,
        onSelectedItemChange: ({ selectedItem }) => {
            if (!selectedItem) {
                return;
            }
            setSelectedItem(selectedItem);
            if (onChange) {
                onChange(selectedItem);
            }
        },
    });

    const [anchorWidth, setAnchorWidth] = useState<number>();

    useEffect(() => {
        if (!selectedItem) {
            return;
        }
        const newSelectedItemByIndex = optionsWithIndexes[selectedItem.index];
        const newSelectedItemByValue = getSelectedItemFromValue(value);
        if (isValueDefined(value) && newSelectedItemByIndex !== newSelectedItemByValue) {
            // skip, another useEffect will be triggered when items are the same
            return;
        }
        if (newSelectedItemByIndex !== selectedItem) {
            // options have changed and we need to reset selectedItem
            // a use case is when the `text` property changes
            if (!newSelectedItemByIndex.disabled) {
                setSelectedItem(newSelectedItemByIndex);
            }
            // otherwise, options have changed, and previously selected option is disabled
            // in this case, fallback to default item
            else {
                updateToDefaultItem();
            }
        }
    }, [optionsWithIndexes, selectedItem, value, updateToDefaultItem, getSelectedItemFromValue]);

    useEffect(() => {
        // Recalculate the width of the popper based on the anchorRef size
        // This only works if we close the popper when resizing
        if (!anchorRef.current) {
            return;
        }
        const currentSelectWidth = anchorRef.current.clientWidth;
        setAnchorWidth(currentSelectWidth);
    }, [isOpen]);

    return (
        <>
            <Input
                ref={ref}
                wrapperRef={anchorRef}
                shape={shape}
                size={size}
                variant={variant}
                isInvalid={formControl.isInvalid}
                isDisabled={formControl.isDisabled}
                isReadOnly={formControl.isReadOnly}
                isRequired={formControl.isRequired}
                {...getToggleButtonProps()}
                {...rest}
                width={width}
                {...systemProps}
                as={SelectInputComponent}
            >
                {selectedItem ? <ValueComponent selectedItem={selectedItem} /> : placeholder}
            </Input>
            <PopoverBase
                minWidth={anchorWidth}
                anchorRef={anchorRef}
                isOpen={!isDisabled && isOpen}
                {...popoverProps}
                offset={[0, 4]}
                placement="bottom"
                px={0}
                py={0}
                disablePortal={disablePortal}
                usePortal={usePortal}
            >
                <Scrollable>
                    {/* Remove as any when Downshift types are up to date with suppressRefError */}
                    <List {...(getMenuProps as any)({ ref: menuRef }, { suppressRefError: true })}>
                        {groupedOptions.map((groupData: SelectGroupOptions, index) => (
                            <Fragment key={index}>
                                {groupData.group && <List.Subheader>{groupData.group}</List.Subheader>}
                                {groupData.options.map((option: SelectGroupOption) => (
                                    <List.Item
                                        key={option.index}
                                        isSelected={option.index === highlightedIndex}
                                        disabled={option.disabled}
                                        {...getItemProps({
                                            item: option,
                                            index: option.index,
                                        })}
                                    >
                                        <OptionComponent option={option} />
                                    </List.Item>
                                ))}
                            </Fragment>
                        ))}
                    </List>
                </Scrollable>
            </PopoverBase>
        </>
    );
});

Select.displayName = 'Select';

export default Object.assign(Select, {});
