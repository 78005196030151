import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHeadphonesOutlined = ({ size, title = 'HeadphonesOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <g
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                transform="translate(4 4)"
            >
                <path d="M.5 12.5v-7a5 5 0 015-5h5a5 5 0 015 5v7" />
                <rect width={3} height={7} x={2.5} y={8.5} rx={1.5} />
                <rect width={3} height={7} x={10.5} y={8.5} rx={1.5} />
            </g>
        </g>
    </svg>
);

export default withIcon(SvgHeadphonesOutlined);
