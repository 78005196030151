import styled from '../../utils/styled';
import { Box } from '../Box';

export const Center = styled(Box)`
    display: flex;
    width: 100%;
    height: 100%;
    place-items: center;
    place-content: center;
`;
