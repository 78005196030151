import React from 'react';
import { forwardRef } from '../../system';
import { TilesProps } from './types';
import * as Styled from './styled';

export const Tiles = forwardRef<TilesProps, 'div'>((props, ref) => {
    const { children, columns = 3, gutter = 'md', direction = 'horizontal', rows = 5, ...rest } = props;

    return (
        <Styled.Tiles ref={ref} columns={columns} gutter={gutter} direction={direction} rows={rows} {...rest}>
            {children}
        </Styled.Tiles>
    );
});
