import { FC } from 'react';
import { FeatureWrapper } from 'components/Common';
import { DoubleLessonItem } from './DoubleLessonItem';
import { MappedPost } from '../IndividualCard';

import styles from './DoubleLesson.module.scss';

interface DoubleLessonProps {
  firstPost: {
    post: MappedPost;
    isTransparent?: boolean;
  };
  secondPost: {
    post: MappedPost;
    isTransparent?: boolean;
  };
}

export const DoubleLesson: FC<DoubleLessonProps> = ({
  firstPost,
  secondPost,
}) => {
  const { post: firstPostData, isTransparent: firstPostTransparent } =
    firstPost;
  const { post: secondPostData, isTransparent: secondPostTransparent } =
    secondPost;

  return (
    <FeatureWrapper tag="div">
      <div className={styles.root}>
        <DoubleLessonItem
          post={firstPostData}
          isTransparent={firstPostTransparent}
        />
        <DoubleLessonItem
          post={secondPostData}
          isTransparent={secondPostTransparent}
        />
      </div>
    </FeatureWrapper>
  );
};
