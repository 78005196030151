import { Center, UiState } from '@landr/maestro';

export const ErrorState = () => {
  return (
    <Center>
      <UiState variant="error" mb="lg">
        <UiState.Content>Oops something went wrong.</UiState.Content>
      </UiState>
    </Center>
  );
};
