import React from 'react';
import { forwardRef } from '../../system';
import { NavItemProps } from './types';
import * as Styled from './styled';

export const NavItem = forwardRef<NavItemProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;
    return (
        <Styled.NavItem ref={ref} {...rest}>
            {children}
        </Styled.NavItem>
    );
});

NavItem.displayName = 'Nav.Item';
