import { palette, spacing, radius } from '../../utils';
import styled from '../../utils/styled';
import { FloatingActionMenuProps } from './types';

export const FloatingActionMenu = styled.div<FloatingActionMenuProps>`
    display: inline-flex;
    align-items: center;
    height: 60px;
    border-radius: ${radius('round')};
    background-color: ${palette('surface')};
    padding: 0 ${spacing('sm')};
    color: ${palette('neutral.900')};
`;
