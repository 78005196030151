import React, { useEffect, useRef, useCallback, useState } from 'react';
import { createPopper, Options as PopperOptions, Instance } from '@popperjs/core';
import styled from 'styled-components';
import { color, ColorProps } from 'styled-system';
import deepmerge from 'deepmerge';
import { TransitionProps } from 'react-transition-group/Transition';
import { Portal } from '../Portal';
import { Box } from '../Box';
import { useOnClickOutside } from '../../hooks';
import { mergeRefs } from '../../utils/refs';
import { forwardRef } from '../../system';
import { warn } from '../../utils';
import { TransitionBase } from '../TransitionBase';
import { PopperProps } from './types';
import * as modifiers from './modifiers';

export interface PopperArrowProps extends ColorProps {
    size?: number;
}

export const Arrow = styled('span').attrs(() => ({
    'data-popper-arrow': true,
    role: 'presentation',
}))<PopperArrowProps>`
    --popper-arrow-size: ${({ size = 8 }): string => `${size}px`};

    visibility: hidden;

    &,
    &::before {
        position: absolute;
        width: var(--popper-arrow-size, 8px);
        height: var(--popper-arrow-size, 8px);
        background-color: inherit;
    }

    &::before {
        inset: 0;
        visibility: visible;
        content: '';
        transform: rotate(45deg);
        ${color};
    }

    [data-popper-placement^='top'] & {
        bottom: calc(var(--popper-arrow-size) / -2);
    }

    [data-popper-placement^='bottom'] & {
        top: calc(var(--popper-arrow-size) / -2);
    }

    [data-popper-placement^='left'] & {
        right: calc(var(--popper-arrow-size) / -2);
    }

    [data-popper-placement^='right'] & {
        left: calc(var(--popper-arrow-size) / -2);
    }
`;

const defaultPopperOptions: PopperOptions = {
    placement: 'auto',
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 8],
            },
        },
    ],
    strategy: 'absolute',
};

const PopperRoot = forwardRef<PopperProps, 'div'>((props, forwardedRef) => {
    const {
        children,
        anchorRef,
        triggerRef,
        isOpen,
        onClose,
        onOpen,
        matchWidth,
        popperOptions: popperOptionsProp = {},
        transitionComponent: TransitionComponent = TransitionBase,
        disablePortal,
        usePortal = true,
        onClickOutside,
        isLazy = true,
        keepMounted = false,
        zIndex = 1,
        ...rest
    } = props;

    warn({
        condition: disablePortal !== undefined,
        message: 'disablePortal prop is deprecated and will be removed in v1, use usePortal instead',
    });

    const popupRef = useRef<HTMLElement | null>(null);
    const popperRef = useRef<Instance | null>(null);

    const [isMounted, setIsMounted] = useState(false);
    const [hasBeenOpened, setHasBeenOpened] = useState(false);

    const popperOptions = deepmerge(
        {
            ...defaultPopperOptions,
            ...{
                modifiers: [{ ...modifiers.matchWidth, enabled: !!matchWidth }],
            },
        },
        popperOptionsProp,
    );

    const handleOpen = useCallback(() => {
        if (!anchorRef.current || !popupRef.current || (isLazy && !isOpen)) {
            return;
        }

        if (popperRef.current) {
            popperRef.current.destroy();
            popperRef.current = null;
        }

        const popper = createPopper(anchorRef.current, popupRef.current, popperOptions);
        popperRef.current = popper;

        popperRef.current.forceUpdate();

        setHasBeenOpened(true);

        onOpen?.();
    }, [anchorRef, isLazy, isOpen, popperOptions, onOpen]);

    const handleClose = useCallback((): void => {
        if (!popperRef.current) {
            return;
        }

        popperRef.current.destroy();
        popperRef.current = null;

        onClose?.();
    }, [onClose]);

    const handleOutsideClick = (event: MouseEvent): void => {
        if (!isOpen) {
            return;
        }
        if (onClickOutside) {
            onClickOutside(event);
        }
    };

    // Function triggered by Transition
    const handleEnter = (): void => {
        setIsMounted(true);
    };

    // Function triggered by Transition
    const handleExited = (): void => {
        setIsMounted(false);
        handleClose();
    };

    useEffect(() => {
        if (popperRef.current) {
            popperRef.current.update();
        }
    });

    useEffect(() => {
        handleOpen();
    }, [handleOpen]);

    useEffect(() => {
        return (): void => {
            handleClose();
        };
    }, [handleClose]);

    useOnClickOutside([anchorRef, popupRef, ...(triggerRef ? [triggerRef] : [])], handleOutsideClick);

    const transitionComponentProps: Partial<TransitionProps> = {
        in: isOpen,
        onEnter: handleEnter,
        onExited: handleExited,
    };

    if (isLazy) {
        if (!isOpen && !isMounted) {
            // Prevent the Portal to be rendered
            if (!keepMounted || (keepMounted && !hasBeenOpened)) {
                return null;
            }
        }
    }

    return (
        <Portal isDisabled={disablePortal || !usePortal} zIndex={zIndex}>
            <Box
                ref={mergeRefs(popupRef, forwardedRef)}
                sx={{
                    position: popperOptions.strategy,
                    visibility: !isMounted ? 'hidden' : 'visible',
                    zIndex,
                }}
                {...rest}
            >
                <TransitionComponent {...transitionComponentProps}>{children}</TransitionComponent>
            </Box>
        </Portal>
    );
});

export const Popper = Object.assign(PopperRoot, { Arrow });
