import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgYoutubeFilled = ({ size, title = 'YoutubeFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="currentColor" fillRule="evenodd">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
                fillRule="nonzero"
                d="M19.911 8.052c.291 1.265.289 2.646.289 3.948s-.001 2.683-.292 3.947c-.205.889-.931 1.544-1.805 1.642-2.07.23-4.167.232-6.254.23-2.086.002-4.183 0-6.253-.23-.875-.098-1.6-.754-1.805-1.642C3.5 14.683 3.5 13.302 3.5 12s.003-2.683.294-3.948C4 7.164 4.724 6.51 5.6 6.412c2.07-.232 4.167-.233 6.254-.232 2.086-.001 4.182 0 6.253.231.874.098 1.6.753 1.805 1.641zM9.701 14.81l5.411-2.806L9.7 9.18v5.63z"
            />
        </g>
    </svg>
);

export default withIcon(SvgYoutubeFilled);
