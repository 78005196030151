import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgAlbumOutlined = ({ size, title = 'AlbumOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <rect width={16.5} height={16.5} x={3.75} y={3.75} rx={1.25} />
            <circle cx={12} cy={12} r={1.5} />
            <circle cx={12} cy={12} r={5.75} />
        </g>
    </svg>
);

export default withIcon(SvgAlbumOutlined);
