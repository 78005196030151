import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgUploadOutlined = ({ size, title = 'UploadOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
            <path d="M19.25 3.75H4.75M12 8.25v12" />
            <path strokeLinejoin="round" d="M16.495 12.497L11.998 8l-4.497 4.497" />
        </g>
    </svg>
);

export default withIcon(SvgUploadOutlined);
