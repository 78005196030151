import React from 'react';
import { nanoid } from 'nanoid/non-secure';
import { forwardRef } from '../../system';
import { omitSystemProps, getSystemProps } from '../../system/styled-system';
import { Stack } from '../Stack';
import { RadioProps } from './types';
import * as Styled from './styled';

const Radio = forwardRef<RadioProps, 'input'>((props, ref) => {
    const {
        id: idProp,
        as = 'input',
        name,
        value,
        label,
        description,
        isChecked,
        isDisabled,
        isInvalid,
        onChange,
        hideLabel = false,
        truncate,
        defaultChecked,
        size = 'md',
        wrapperProps,
        wrapperRef,
        ...rest
    } = omitSystemProps(props);

    const { color, ...systemProps } = getSystemProps(props);

    const id = idProp || `radio-${nanoid()}`;

    return (
        <Styled.Radio
            ref={ref}
            forwardedAs={as}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            isChecked={isChecked}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            wrapperProps={{ ...wrapperProps, ...systemProps }}
            {...rest}
        >
            <Styled.Control size={size} />
            <Stack gutter="xxs" ml="xs">
                {!hideLabel && (
                    <Styled.Label size={size} isDisabled={isDisabled} nbOfLines={truncate ? 1 : undefined}>
                        {label}
                    </Styled.Label>
                )}
                {!!description && <Styled.Description>{description}</Styled.Description>}
            </Stack>
        </Styled.Radio>
    );
});

Radio.displayName = 'Radio';

export default Object.assign(Radio, {
    Root: Styled.Radio,
    Control: Styled.Control,
    Label: Styled.Label,
    Description: Styled.Description,
});
