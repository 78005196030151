import { useRef, useCallback, useEffect } from 'react';

function useDebouncedCallback<T extends (...args: any[]) => any>(callback: T, delay: number): T {
    const debouncedFunction = useRef<T>(callback);
    debouncedFunction.current = callback;

    const functionTimeoutHandler = useRef<null | number>(null);
    const isComponentUnmounted = useRef<boolean>(false);

    useEffect(
        () => () => {
            isComponentUnmounted.current = true;
        },
        [],
    );

    const debouncedCallback = useCallback(
        (...args) => {
            clearTimeout(functionTimeoutHandler.current as number);

            functionTimeoutHandler.current = window.setTimeout(() => {
                if (!isComponentUnmounted.current) {
                    debouncedFunction.current(...args);
                }
            }, delay);
        },
        [delay],
    );

    return debouncedCallback as T;
}

export { useDebouncedCallback };
