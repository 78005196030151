import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgGlobeOutlined = ({ size, title = 'GlobeOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12 19c1.657 0 3-3.134 3-7s-1.343-7-3-7-3 3.134-3 7 1.343 7 3 7zm-7-7h14"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12 19a7 7 0 100-14 7 7 0 000 14z"
        />
    </svg>
);

export default withIcon(SvgGlobeOutlined);
