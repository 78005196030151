import React, { Ref } from 'react';
import { isFunction } from './assertions';

/**
 * Assigns a value to a ref function or object
 *
 * @param ref
 * @param value
 */
export const assignRef = <T>(ref: Ref<T>, value: T | null) => {
    if (!ref) {
        return;
    }

    if (isFunction(ref)) {
        ref(value);
        return;
    }

    try {
        (ref as React.MutableRefObject<T | null>).current = value;
    } catch (error) {
        throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
    }
};

/**
 * Combine multiple React refs into a single ref function.
 * This is used mostly when you need to allow consumers forward refs to
 * internal components
 *
 * @param refs refs to assign the value to
 *
 * @example
 *
 * ```tsx
 *  <div ref={mergeRefs(ref1, ref2, ref3)}></div>
 * ```
 */
export const mergeRefs =
    <T>(...refs: Ref<T>[]): React.RefCallback<T> =>
    (value: T | null) => {
        refs.forEach((ref) => assignRef(ref, value));
    };
