import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgAndroidFilled = ({ size, title = 'AndroidFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g clipPath="url(#android-filled_svg__android-filled_svg__clip0_2_14294)">
            <path
                fill="currentColor"
                d="M16.582 10.25l1.505-2.52a.495.495 0 00-.212-.673.536.536 0 00-.375-.04.52.52 0 00-.305.214l-1.538 2.567A9.645 9.645 0 0012 9.08c-1.257 0-2.5.245-3.657.72L6.805 7.23a.54.54 0 00-.712-.158.496.496 0 00-.18.658l1.505 2.519a8.704 8.704 0 00-3.075 2.844A8.359 8.359 0 003 17h18a8.36 8.36 0 00-1.343-3.906 8.704 8.704 0 00-3.075-2.844zm-8.673 4.571a1.04 1.04 0 01-.723-.29.975.975 0 01-.3-.7c0-.263.108-.515.3-.7a1.04 1.04 0 01.723-.29c.271 0 .531.104.723.29.192.185.3.437.3.7a.975.975 0 01-.3.7 1.04 1.04 0 01-.723.29zm8.182 0a1.04 1.04 0 01-.723-.29.975.975 0 01-.3-.7c0-.263.108-.515.3-.7a1.04 1.04 0 01.723-.29c.271 0 .531.104.723.29.192.185.3.437.3.7a.975.975 0 01-.3.7 1.04 1.04 0 01-.723.29z"
            />
        </g>
        <defs>
            <clipPath id="android-filled_svg__android-filled_svg__clip0_2_14294">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default withIcon(SvgAndroidFilled);
