import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLockOutlined = ({ size, title = 'LockOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M4.75 9.991c0-.685.55-1.241 1.249-1.241H18c.69 0 1.249.56 1.249 1.241v9.118A1.24 1.24 0 0118 20.35H6c-.69 0-1.249-.56-1.249-1.241V9.99z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M16.25 8a4.25 4.25 0 10-8.5 0M12 14v3"
            />
            <circle cx={12} cy={13.5} r={1.5} fill="currentColor" />
        </g>
    </svg>
);

export default withIcon(SvgLockOutlined);
