import React from 'react';
import { forwardRef } from '../../system';
import { CollapseContentProps } from './types';
import * as Styled from './styled';

export const Content = forwardRef<CollapseContentProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;
    return (
        <Styled.CollapseContent ref={ref} {...rest}>
            {children}
        </Styled.CollapseContent>
    );
});

Content.displayName = 'Collapse.Content';
