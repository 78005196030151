import { useEffect } from 'react';

export const useOnClickOutside = (
    refs: React.RefObject<any> | React.RefObject<any>[],
    handler = (event: MouseEvent): void => void 0,
): void => {
    useEffect(() => {
        const refArray = Array.isArray(refs) ? refs : [refs];

        const listener = (event: MouseEvent): void => {
            const goAhead = refArray.reduce((_goAhead, ref) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return false;
                }
                return _goAhead;
            }, true);

            if (!goAhead) {
                return;
            }

            event.stopPropagation();
            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return (): void => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [refs, handler]);
};
