import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHeadphonesAddOutlined = ({ size, title = 'HeadphonesAddOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <g stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} transform="translate(3.5 3)">
                <path d="M.5 12.5v-7a5 5 0 015-5h5a5 5 0 015 5v5" />
                <rect width={3} height={7} x={2.5} y={8.5} rx={1.5} />
                <path d="M10.5 14v-4a1.5 1.5 0 013 0v.5" />
            </g>
            <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M19 16v6m-3-3h6" />
        </g>
    </svg>
);

export default withIcon(SvgHeadphonesAddOutlined);
