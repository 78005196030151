import React from 'react';
import { createPortal } from 'react-dom';
import { Box, BoxProps } from '../Box';
import { isBrowser, useEnhancedEffect } from '../../utils';
import { forwardRef } from '../../system';

export interface PortalProps extends BoxProps {
    /** When true, content will not be rendered outside the DOM */
    isDisabled?: boolean;
    /** Ref element where the portal should be rendered */
    containerRef?: React.RefObject<HTMLElement>;
}

export const Portal = forwardRef<PortalProps, 'div'>((props, ref) => {
    const { children, isDisabled = false, containerRef, ...rest } = props;
    const hostElement = containerRef?.current ?? (isBrowser ? document?.body : undefined);
    const [, forceUpdate] = React.useState({});

    useEnhancedEffect(() => {
        forceUpdate({});
    }, []);

    if (isDisabled) {
        return <>{children}</>;
    }

    if (hostElement) {
        return createPortal(
            <Box ref={ref} {...rest}>
                {children}
            </Box>,
            hostElement,
        );
    }

    return null;
});

Portal.defaultProps = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
};
