import React from 'react';
import { useDisclosure } from '../../hooks';
import { Box } from '../../primitives/Box';
import { IconButton } from '../IconButton';
import { Drawer } from '../Drawer';
import { MenuInvertedOutlined } from '../../assets/icons';
import { MobileNavBarProps } from './types';
import { NavBarContext } from './context';

export const NavBarDrawer: React.FC<MobileNavBarProps> = ({ brand, menu, menuLabel }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <NavBarContext.Provider value={{ closePanel: onClose }}>
            <Box display="flex" alignItems="center">
                <IconButton
                    label={menuLabel}
                    icon={MenuInvertedOutlined}
                    kind="minimal"
                    variant="subtle"
                    onClick={onOpen}
                    size="sm"
                    isRounded
                    hideTooltip
                    mr="xxs"
                />
                <Drawer side="left" isVisible={isOpen} onClose={onClose}>
                    <Drawer.Content>
                        <Box mb="lg">{brand}</Box>
                        {menu}
                    </Drawer.Content>
                </Drawer>
            </Box>
        </NavBarContext.Provider>
    );
};
