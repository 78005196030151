import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgRedditFilled = ({ size, title = 'RedditFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M20 11.867a1.935 1.935 0 00-1.933-1.934c-.467 0-.867.134-1.2.4-1.2-.733-2.667-1.2-4.267-1.266l.8-2.6L15.667 7a1.6 1.6 0 001.6 1.467c.866 0 1.6-.734 1.6-1.6 0-.867-.734-1.6-1.6-1.6-.6 0-1.134.333-1.4.866l-2.667-.6a.453.453 0 00-.533.267l-1 3.267c-1.667.066-3.334.466-4.534 1.266-.333-.266-.733-.4-1.2-.4A1.935 1.935 0 004 11.867c0 .666.333 1.266.8 1.6v.466c0 1.334.8 2.534 2.2 3.467 1.333.867 3.133 1.333 5 1.333s3.667-.466 5-1.333c1.4-.933 2.2-2.133 2.2-3.467v-.4c.467-.4.8-1 .8-1.666zm-2.667-5.734c.4 0 .734.334.734.734a.74.74 0 01-.734.733.74.74 0 01-.733-.733c0-.4.333-.734.733-.734zm-8.8 6.934c0-.6.534-1.067 1.067-1.067.6 0 1.067.533 1.067 1.067 0 .533-.467 1.066-1.067 1.066-.533 0-1.067-.466-1.067-1.066zm6.134 3.133c-.534.533-1.4.8-2.6.8-1.2 0-2.067-.267-2.6-.8-.2-.2-.2-.467 0-.6.2-.2.466-.2.6 0 .4.4 1.066.6 2 .6.933 0 1.6-.2 2-.6.2-.2.466-.2.6 0 .133.2.133.467 0 .6zm-.267-2.067c-.6 0-1.133-.466-1.133-1.066 0-.6.533-1.067 1.133-1.067s1.067.533 1.067 1.067c0 .533-.467 1.066-1.067 1.066z"
        />
    </svg>
);

export default withIcon(SvgRedditFilled);
