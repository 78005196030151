import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCaretRightOutlined = ({ size, title = 'CaretRightOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9.75 18.25l6-6.25-6-6.25"
        />
    </svg>
);

export default withIcon(SvgCaretRightOutlined);
