import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMailOutlined = ({ size, title = 'MailOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path strokeLinejoin="round" d="M3.25 6l7.5 7.5h3l7.5-7.5" />
            <path d="M21.25 18l-6-6m-12 6l6-6" />
            <rect width={18.5} height={12} x={3} y={6} rx={1.25} />
        </g>
    </svg>
);

export default withIcon(SvgMailOutlined);
