import { Box, Group } from '../../../primitives';
import styled from '../../../utils/styled';
import { spacing, radius } from '../../../utils';
import { NavigationElementPosition } from '../types';
import { Spacing } from '../../../themes/types';

const GroupWrapper = styled(Group)<{ dotSize: Spacing; dotPosition: NavigationElementPosition }>`
    line-height: 1;
    ${({ theme, dotSize }) => dotSize && `height: ${theme.spacing(dotSize)};`}
    ${({ theme, dotPosition, dotSize }) =>
        dotSize && dotPosition === 'inside' && `margin-top: -${theme.spacing(dotSize)};`}
`;

const GroupItem = styled(Group.Item)`
    position: relative;
    line-height: 1;
    height: 100%;
    width: ${spacing('md')};
`;

const DotWrapper = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
`;

const AbsoluteBox = styled(Box)`
    position: absolute;
    top: 0;
    left: 0;
    top: calc(50% - ${spacing('md')} / 2);
    line-height: 1;
    width: ${spacing('md')};
    height: ${spacing('md')};
`;

const Circle = styled(Box)`
    width: 100%;
    height: 100%;
    border-radius: ${radius('round')};
`;

const Styled = {
    GroupWrapper,
    GroupItem,
    DotWrapper,
    AbsoluteBox,
    Circle,
};

export default Styled;
