import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCouponOutlined = ({ size, title = 'CouponOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M9.5 9.5h5m-5 5h5m3-2a2 2 0 012-2v-4a1 1 0 00-1-1h-13a1 1 0 00-1 1V10a2 2 0 110 4v3.5a1 1 0 001 1h13a1 1 0 001-1v-3a2 2 0 01-2-2z"
        />
    </svg>
);

export default withIcon(SvgCouponOutlined);
