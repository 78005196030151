import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMusicNote = ({ size, title = 'MusicNote', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <circle cx={16.5} cy={15.5} r={1.75} />
            <path strokeLinecap="square" d="M18.25 14.8V8.153" />
            <circle cx={6.5} cy={17.5} r={1.75} />
            <path strokeLinecap="square" d="M8.25 16.75V9.815" />
            <path strokeLinejoin="round" d="M8.25 5.899L18.25 4v4.747l-10 1.899z" />
        </g>
    </svg>
);

export default withIcon(SvgMusicNote);
