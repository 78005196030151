import React, { isValidElement } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionProps, TransitionStatus } from 'react-transition-group/Transition';
import { CSSObject } from 'styled-components';

type TransitionStyles = Partial<Record<TransitionStatus, CSSObject>>;

export interface TransitionBaseProps extends Omit<TransitionProps, 'timeout'> {
    timeout?: number | { appear?: number | undefined; enter?: number | undefined; exit?: number | undefined };
    transitionStyles: TransitionStyles;
    transitionProperties?: string | string[];
}

const defaultTransitionStyles = {
    entering: {},
    entered: {},
    exiting: {},
    exited: {},
    unmounted: {},
};

const TransitionBase: React.FC<TransitionBaseProps> = ({
    children,
    in: inProp,
    timeout = 0,
    transitionStyles = defaultTransitionStyles,
    transitionProperties = 'opacity',
    ...props
}) => {
    const child = React.Children.only(children);

    if (!isValidElement(child)) {
        return <>{child}</>;
    }

    const transitionPropertiesArray =
        typeof transitionProperties === 'string' ? [transitionProperties] : transitionProperties;

    return (
        <Transition appear in={inProp} timeout={timeout} {...props}>
            {(state: TransitionStatus, childProps: typeof child.props): JSX.Element =>
                React.cloneElement(child, {
                    style: {
                        visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
                        transition: transitionPropertiesArray.map((property) => `${property} ${timeout}ms ease-in-out`),
                        ...transitionStyles[state],
                        ...child.props.style,
                    },
                    ...childProps,
                })
            }
        </Transition>
    );
};

export { TransitionBase };
