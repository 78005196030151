import React from 'react';
import { useFormControl } from '../FormControl';
import { forwardRef } from '../../system';
import { TextareaProps } from './types';
import * as Styled from './styled';

export const Textarea = forwardRef<TextareaProps, 'textarea'>((props, ref) => {
    const {
        variant = 'default',
        size = 'md',
        isInvalid,
        isValid,
        isRequired,
        isDisabled,
        isReadOnly,
        resize = 'none',
        ...rest
    } = props;

    const formControl = useFormControl({
        isInvalid,
        isValid,
        isRequired,
        isDisabled,
        isReadOnly,
    });

    return (
        <Styled.Textarea
            variant={variant}
            size={size}
            isDisabled={formControl.isDisabled}
            isInvalid={formControl.isInvalid}
            isValid={formControl.isValid}
            isRequired={formControl.isRequired}
            isReadOnly={formControl.isReadOnly}
            ref={ref}
            disabled={formControl.isDisabled}
            readOnly={formControl.isReadOnly}
            required={formControl.isRequired}
            resize={resize}
            {...rest}
        />
    );
});

Textarea.displayName = 'Textarea';
