import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

/**
 * Cross-browser polyfill to support
 * scrollIntoView options and smooth scrolling
 * API: https://github.com/stipsan/scroll-into-view-if-needed
 */

export let scrollIntoView;
if (typeof document !== 'undefined') {
    scrollIntoView = 'scrollBehavior' in document.documentElement.style ? scrollIntoViewIfNeeded : smoothScrollIntoView;
}
