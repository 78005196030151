import React from 'react';
import { Group } from '../../../primitives/Group';
import { PaginationButtonProps } from '../types';
import * as Styled from '../styled';

const PaginationButton = ({
    buttonComponent: ButtonComponent = Styled.PaginationPageButton,
    buttonSize,
    shape,
    isDisabled,
    isSelected,
    onClick,
    page,
    itemType,
    children,
}: PaginationButtonProps) => (
    <Group.Item>
        <Styled.PaginationPageButton
            as={ButtonComponent}
            onClick={onClick}
            buttonSize={buttonSize}
            shape={shape}
            isDisabled={isDisabled}
            variant="subtle"
            page={page}
            itemType={itemType}
            aria-current={isSelected ? true : undefined}
        >
            {children}
        </Styled.PaginationPageButton>
    </Group.Item>
);

export default PaginationButton;
