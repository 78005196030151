import { useCallback } from 'react';

/**
 * Returns `callback` wrapped in async function.
 * Make sure, that you don't pass throttled function as a callback to
 * react synthetic event handler, e.g. :
 *
 * ```
 * const throttledFunc = useThrottle((e) => {doSmthWith(e);}, 200);
 * <input onChange={throttledFunc} />
 * ```
 * Code above won't work, because [react's synthetic events don't work with async callbacks](https://reactjs.org/docs/events.html#event-pooling).
 *
 * Instead, you should have used this:
 *
 * ```
 * const throttledFunc = useThrottle((e) => {doSmthWith(e);}, 200);
 * function onInputChange(e) {
 *  throttledFunc(e.target.value)
 * }
 * <input onChange={throttledFunc} />
 * ```
 * Or, if you really need other event's props than `value`,
 * just use event.persist(), as descrived in link above.
 *
 * @param callback function to call after provided delay
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export function useThrottle<T extends Function>(callback: T, delay: number): T {
    const throttledCallback: unknown = useCallback(
        (...args: any) => {
            let throttle;

            if (throttle) {
                clearTimeout(throttle);
            }

            throttle = setTimeout(() => {
                callback(...args);
                throttle = undefined;
            }, delay);
        },
        [callback, delay],
    );

    return throttledCallback as T;
}
