import React from 'react';
import { Image } from '../Image';
import * as Styled from './styled';
import { FeatureCardMediaProps } from './types';

export const Media: React.FC<FeatureCardMediaProps> = ({
    src,
    fallbackSrc,
    alt,
    width = 24,
    height = 24,
}: FeatureCardMediaProps) => (
    <Styled.FeatureCardMedia>
        <Image src={src} fallbackSrc={fallbackSrc} width={width} height={height} alt={alt} />
    </Styled.FeatureCardMedia>
);

Media.displayName = 'FeatureCard.Media';
