import React, { ChangeEvent, Children } from 'react';
import { forwardRef } from '../../../system';
import { Group } from '../../../primitives/Group';
import { useControlled, useId } from '../../../hooks';
import { RadioGroupProvider } from './RadioGroupContext';
import { RadioGroupProps } from './types';

const RadioGroup = forwardRef<RadioGroupProps, 'div'>((props, ref) => {
    const {
        children,
        name,
        isInline,
        value,
        defaultValue,
        onChange,
        isDisabled,
        size,
        gutter = 'xxs',
        ...rest
    } = props;

    const childrenArray = Children.toArray(children);

    const [internalValue, setInternalValue] = useControlled({ value, defaultValue, onChange });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event.currentTarget.value);
    };

    const groupContext = { value: internalValue, name: useId(name), onChange: handleChange, isDisabled, size };

    return (
        <RadioGroupProvider value={groupContext}>
            <Group
                ref={ref}
                direction={isInline ? 'horizontal' : 'vertical'}
                gutter={gutter}
                role="radiogroup"
                {...rest}
            >
                {childrenArray.map((child, index) => (
                    <Group.Item key={`radio-group-item-${index}`}>{child}</Group.Item>
                ))}
            </Group>
        </RadioGroupProvider>
    );
});

RadioGroup.displayName = 'RadioGroup';

export default Object.assign(RadioGroup, {});
