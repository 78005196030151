import React, { useContext, useRef, useCallback, useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { border } from 'styled-system';
import { Box } from '../../primitives/Box';
import { mergeRefs, palette, radius, themeMode } from '../../utils';
import { TableRowProps, StyledTableRowProps } from './types';
import { Cell } from './TableCell';
import { TableContext } from './TableContext';
import { useTableRowEventListener } from './Table.hooks';

const Row = styled(Box)<StyledTableRowProps>`
    display: table-row;
    color: inherit;
    outline: 0;
    vertical-align: middle;

    ${({ hasFocus, isFocus }) =>
        !!hasFocus &&
        !!isFocus &&
        css`
            ${Cell} {
                position: relative;

                &::after {
                    content: ' ';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background-color: ${themeMode({
                        light: css`
                            ${palette('neutral.100')};
                        `,
                        dark: css`
                            ${palette('neutral.900')};
                        `,
                    })};
                }

                &:first-of-type::after {
                    width: calc(100% + 12px);
                    right: 0;
                    left: auto;
                    border-top-left-radius: ${radius('base')};
                    border-bottom-left-radius: ${radius('base')};
                }

                &:last-of-type::after {
                    width: calc(100% + 12px);
                    border-top-right-radius: ${radius('base')};
                    border-bottom-right-radius: ${radius('base')};
                }
            }
        `};

    ${({ isRuled }) =>
        isRuled &&
        css`
            border-bottom: ${themeMode({
                light: css`
                    1px solid ${palette('neutral.200')};
                `,
                dark: css`
                    1px solid ${palette('neutral.800')};
                `,
            })};
        `};

    ${({ hasHover }) =>
        !!hasHover &&
        css`
            ${Cell} {
                position: relative;

                &::after {
                    content: ' ';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }

                &:first-of-type::after {
                    width: calc(100% + 12px);
                    right: 0;
                    left: auto;
                    border-top-left-radius: ${radius('base')};
                    border-bottom-left-radius: ${radius('base')};
                }

                &:last-of-type::after {
                    width: calc(100% + 12px);
                    border-top-right-radius: ${radius('base')};
                    border-bottom-right-radius: ${radius('base')};
                }
            }

            &:hover > ${Cell} {
                &::after {
                    background-color: ${themeMode({
                        light: css`
                            ${palette('neutral.100')};
                        `,
                        dark: css`
                            ${palette('neutral.900')};
                        `,
                    })};
                }
            }
        `};

    ${({ isSelected }) =>
        !!isSelected &&
        css`
            ${Cell} {
                position: relative;
                &::after {
                    content: ' ';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    background-color: ${themeMode({
                        light: css`
                            ${palette('neutral.100')};
                        `,
                        dark: css`
                            ${palette('neutral.900')};
                        `,
                    })};
                }
                &:first-of-type::after {
                    width: calc(100% + 12px);
                    right: 0;
                    left: auto;
                    border-radius: 0;
                }

                &:last-of-type::after {
                    width: calc(100% + 12px);
                    border-radius: 0;
                }
            }
        `};

    /* System Props */
    ${border}
`;

const TableRow: React.FC<TableRowProps> = forwardRef(
    ({ children, rowIndex = -1, tabIndex = -1, ...props }, forwardedRef) => {
        // CONTEXT
        const { isRuled, dispatchRowChangedEvent, onRowChange, focusedRowIndex } = useContext(TableContext);
        // REF
        const rowRef = useRef<HTMLDivElement>(null);
        const [isFocus, setIsFocus] = useState(rowIndex === focusedRowIndex);

        const ref = mergeRefs(forwardedRef, rowRef);

        // EFFECT
        useTableRowEventListener(rowIndex, ref, isFocus, setIsFocus);

        // CALLBACK
        const handleOnClick = useCallback(
            (event: MouseEvent) => {
                dispatchRowChangedEvent(rowIndex, false);

                onRowChange && onRowChange(rowIndex, event);
            },
            [dispatchRowChangedEvent, onRowChange, rowIndex],
        );

        return (
            <Row onClick={handleOnClick} ref={ref} isRuled={isRuled} isFocus={isFocus} tabIndex={tabIndex} {...props}>
                {children}
            </Row>
        );
    },
);

export { TableRow };
