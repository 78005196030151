import React, { Children, useEffect, cloneElement, useContext } from 'react';
import { useBreakpoint } from '../../hooks';
import { Drawer } from '../Drawer';
import { PopoverBase, usePopover } from '../PopoverBase';
import { isFunction } from '../../utils';
import { PopoverProps, PopoverTriggerProps, PopoverContentProps } from './types';
import { PopoverContext } from './PopoverContext';

const Popover = ({
    children,
    triggerEvent = 'click',
    hasArrow,
    isOpen: isOpenProp,
    onClose,
    onOpen,
    triggerRef,
    placement,
    closeOnBlur = true,
    closeDelay = 200,
    ...props
}: PopoverProps) => {
    const { isOpen, open, close, getTriggerProps, getPopoverProps } = usePopover({
        triggerEvent: triggerEvent,
        onOpen,
        onClose,
        hasArrow,
        triggerRef,
        placement,
        closeOnBlur: closeOnBlur,
        closeDelay,
    });

    useEffect(() => {
        if (typeof isOpenProp !== 'boolean') {
            return;
        }

        if (isOpenProp) {
            open();
        }

        if (!isOpenProp) {
            close();
        }
    }, [close, isOpenProp, open]);

    return (
        <PopoverContext.Provider value={{ isOpen, open, close, getTriggerProps, getPopoverProps, rest: props }}>
            {isFunction(children) ? children({ isOpen, close }) : children}
        </PopoverContext.Provider>
    );
};

Popover.displayName = ' Popover';

const Trigger: React.FC<PopoverTriggerProps> = ({ children }) => {
    const child: any = Children.only(children);
    const { getTriggerProps } = useContext(PopoverContext);
    return cloneElement(child, getTriggerProps(child.props, child.ref));
};

Trigger.displayName = 'Popover.Trigger';

const Content: React.FC<PopoverContentProps> = ({ children, drawerBreakpoint = 'md', ...props }) => {
    const isDrawer = useBreakpoint(drawerBreakpoint);
    const { isOpen, getPopoverProps, close, rest } = useContext(PopoverContext);

    return (
        <>
            {isDrawer ? (
                <Drawer isVisible={isOpen} onClose={close} {...rest}>
                    <Drawer.Content {...props} maxWidth="100%">
                        {children}
                    </Drawer.Content>
                </Drawer>
            ) : (
                <PopoverBase {...getPopoverProps()} onOpen={getPopoverProps().open} {...rest} {...props}>
                    {children}
                </PopoverBase>
            )}
        </>
    );
};

Content.displayName = 'Popover.Content';

export default Object.assign(Popover, { Trigger, Content });
