import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMinimizeOutlined = ({ size, title = 'MinimizeOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
            <path d="M20 15.048v3.737c0 .671-.55 1.215-1.203 1.215H5.203A1.21 1.21 0 014 18.797V5.203C4 4.538 4.55 4 5.215 4h3.737" />
            <path d="M16.571 13.524H11.62a1.129 1.129 0 01-1.143-1.143v-4.95m9.522-3.43l-8.38 8.38" />
        </g>
    </svg>
);

export default withIcon(SvgMinimizeOutlined);
