import React, { useState, useCallback, useEffect } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { Portal } from '../../primitives/Portal';
import { Overlay } from '../Overlay';
import { Fade } from '../Fade';
import { ColorMode } from '../MaestroThemeProvider';
import { forwardRef } from '../../system';
import { canUseDOM, useEnhancedEffect } from '../../utils';
import { ModalProps } from './types';
import * as Styled from './styled';
import { ModalContext } from './ModalContext';
import { ModalFooter, ModalContinueButton, ModalCancelButton } from './ModalFooter';
import { ModalHeader, ModalCloseButton, ModalTitle, ModalBackButton } from './ModalHeader';
import { ModalContent } from './ModalContent';
import { ModalSection } from './ModalSection';
import { useModal } from './useModal';

// TODO: [DS-345] - Implement focus trap

const TRANSITION_DURATION = 300;

const ModalComponent = forwardRef<ModalProps, 'div'>((props, ref) => {
    const {
        children,
        variant = 'base',
        size = 'md',
        isCentered = true,
        isOpen = false,
        withOverlay = true,
        onOpen,
        onClose = (): void => undefined,
        showCloseButton = true,
        closeOnOverlayClick = true,
        isDismissible = true,
        theme: themeMode = 'light',
        scrollBehavior = 'inside',
        transitionComponent: TransitionComponent = Fade,
        initialFocusRef = null,
        zIndex = 1000,
        ...rest
    } = props;

    // This is to disable the Portal once the Modal is not open, without this the transition are not working when exiting
    const [isMounted, setIsMounted] = useState(false);
    const handleEnter = (): void => onOpen?.();
    const handleExited = (): void => setIsMounted(false);
    const handleEntered = useCallback(() => {
        initialFocusRef?.current?.focus?.();
    }, [initialFocusRef]);
    const modal = useModal({ isOpen, onClose, closeOnOverlayClick, isDismissible });

    const context = { ...modal, onClose, showCloseButton, variant, size, scrollBehavior, isCentered };

    useEffect(() => {
        if (isOpen) {
            setIsMounted(true);
        }
    }, [isOpen]);

    const [viewportHeight, setViewportHeight] = useState<string | undefined>(undefined);

    useEnhancedEffect(() => {
        // The following handler is to get the actual height of the viewport.
        // On iOS, the keyboard will cover the modal when opened.
        // The hidden content won't be scrollable because it's not considered an overflow
        if (!canUseDOM || !window?.visualViewport || scrollBehavior === 'outside') return;

        function viewportHandler(event: Event) {
            const viewport = event.target as VisualViewport;
            setViewportHeight(`${Math.floor(viewport.height)}px`);
        }

        window.visualViewport.addEventListener('resize', viewportHandler);
        return () => {
            window?.visualViewport?.removeEventListener('resize', viewportHandler);
        };
    }, [scrollBehavior]);

    return (
        <ModalContext.Provider value={context}>
            {isMounted && (
                <Portal zIndex={zIndex}>
                    <ColorMode mode={themeMode}>
                        {withOverlay && <Overlay isVisible={isOpen} timeout={TRANSITION_DURATION} zIndex={0} />}
                        <RemoveScroll>
                            <TransitionComponent
                                in={isOpen}
                                timeout={TRANSITION_DURATION}
                                onEnter={handleEnter}
                                onExited={handleExited}
                                onEntered={handleEntered}
                            >
                                <Styled.Wrapper
                                    ref={ref}
                                    scrollBehavior={scrollBehavior}
                                    isCentered={isCentered}
                                    style={{
                                        maxHeight: viewportHeight,
                                    }}
                                    {...context.getModalWrapperProps()}
                                >
                                    <Styled.Modal
                                        size={context.size}
                                        scrollBehavior={context.scrollBehavior}
                                        {...context.getModalProps(rest)}
                                    >
                                        {children}
                                    </Styled.Modal>
                                </Styled.Wrapper>
                            </TransitionComponent>
                        </RemoveScroll>
                    </ColorMode>
                </Portal>
            )}
        </ModalContext.Provider>
    );
});

export const Modal = Object.assign(ModalComponent, {
    Header: ModalHeader,
    Content: ModalContent,
    Section: ModalSection,
    Media: ModalSection,
    Footer: ModalFooter,
    CloseButton: ModalCloseButton,
    Title: ModalTitle,
    BackButton: ModalBackButton,
    ContinueButton: ModalContinueButton,
    CancelButton: ModalCancelButton,
});

Modal.displayName = 'Modal';
