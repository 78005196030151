import { MutableRefObject, useEffect, useRef } from 'react';

export const useIsDestroyed = (): MutableRefObject<boolean> => {
    const isDestroyed = useRef(false);

    useEffect(() => {
        return () => {
            isDestroyed.current = true;
        };
    }, []);

    return isDestroyed;
};
