import React from 'react';
import { space } from 'styled-system';
import { Box } from '../../primitives/Box';
import { CaretDownOutlined, CaretUpOutlined } from '../../assets/icons';
import { border, color, palette, spacing, themeMode } from '../../utils';
import styled from '../../utils/styled';
import { IconProps } from '../withIcon';
import { CollapseVariant } from './types';

type CollapseIconProps = {
    isOpen: boolean;
    hasTitleHover: boolean;
    hasTitleClick: boolean;
    variant: CollapseVariant;
    onClick?: () => void;
} & IconProps;

export const SanitizedIcon: React.FC<CollapseIconProps> = ({
    variant,
    isOpen,
    hasTitleHover,
    hasTitleClick,
    ...rest
}: CollapseIconProps) => {
    const Icon = variant === 'default' ? CaretDownOutlined : CaretUpOutlined;

    return <Icon {...rest} />;
};

export const CollapseIcon = styled(SanitizedIcon)<CollapseIconProps>`
    color: ${({ hasTitleHover }): ReturnType<typeof themeMode> =>
        hasTitleHover
            ? themeMode({
                  dark: palette('neutral.50'),
                  light: palette('neutral.900'),
              })
            : themeMode({
                  dark: palette('neutral.400'),
                  light: palette('neutral.500'),
              })};

    &:hover {
        color: ${({ hasTitleClick }): ReturnType<typeof themeMode> =>
            hasTitleClick
                ? themeMode({
                      dark: palette('neutral.400'),
                      light: palette('neutral.500'),
                  })
                : themeMode({
                      dark: palette('neutral.50'),
                      light: palette('neutral.900'),
                  })};
    }

    flex-shrink: 0;
    align-self: flex-start;
    transform: ${({ isOpen }): string => (isOpen ? `rotate(-180deg)` : 'rotate(0)')};
    transition: transform 0.25s ease-out;
    ${({ hasTitleHover }): string | false => !hasTitleHover && `cursor: pointer;`}
`;

export const Collapse = styled.div<{ hasTitleHover: boolean }>`
    border: ${({ hasTitleHover }): ReturnType<typeof border> => (hasTitleHover ? border('hover') : border('subtle'))};
    background: ${color('background.base')};

    & + & {
        margin-top: ${spacing('lg')};
    }

    width: 100%;
`;

export const CollapseTitle = styled(Box)<{ hasTitleHover?: boolean; isOpen: boolean }>`
    display: flex;
    color: ${color('text.base')};
    overflow: hidden;
    min-height: 68px;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &:focus {
        outline: 0;
    }

    padding: ${spacing('lg')};
    box-sizing: border-box;

    ${({ hasTitleHover }): string => (hasTitleHover ? `&:hover {cursor: pointer;}` : ``)}

    ${space}
`;

export const CollapseTrigger = styled(Box)`
    display: flex;
    align-items: center;
    line-height: 0;
`;

export const CollapseTitleContent = styled(Box)<{ isOpen: boolean }>`
    width: 100%;
`;

export const CollapseContentWrapper = styled.div<{ isOpen: boolean; contentHeight: number }>`
    transition: height 0.25s ease-out, opacity 0.25s ease-out;
    overflow: hidden;
    height: ${({ isOpen, contentHeight }): string => (isOpen ? `${contentHeight}px` : '0')};
    opacity: ${({ isOpen }): string => (isOpen ? `1` : '0')};
`;

export const CollapseContent = styled(Box)`
    color: ${color('text.base')};
    padding: ${spacing('lg')};
    padding-top: 0;
`;
