import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFileTextOutlined = ({ size, title = 'FileTextOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g stroke="currentColor" strokeWidth={1.5}>
            <path d="M19 3.75H5c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25z" />
            <path strokeLinecap="round" d="M7.5 7.5h9m-9 3h9m-9 3h9m-9 3h5" />
        </g>
    </svg>
);

export default withIcon(SvgFileTextOutlined);
