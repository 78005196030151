import React from 'react';
import { forwardRef } from '../../system';
import { GroupProps } from './types';
import { Styled } from './styled';

const GroupComponent = forwardRef<GroupProps, 'div'>(
    ({ children, direction = 'horizontal', gutter = 'md', ...props }, ref) => {
        return (
            <Styled.Group ref={ref} direction={direction} gutter={gutter} {...props}>
                {children}
            </Styled.Group>
        );
    },
);

GroupComponent.displayName = 'Group';

export const Group = Object.assign(GroupComponent, { Item: Styled.Item });
