import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgKeyboardOutlined = ({ size, title = 'KeyboardOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.826 14.696v-2.783m2.783 2.783v-2.783m2.782 2.783v-2.783m2.783 2.783v-2.783m-11.826 0h15.304m0-5.565H4.348v11.13h15.304V6.348zM16.87 9.13H12m-4.87 0h1.392"
        />
    </svg>
);

export default withIcon(SvgKeyboardOutlined);
