import React, { useMemo, useState, useCallback } from 'react';
import { TableProps } from './types';
import { Styled } from './styled';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { TableFoot } from './TableFoot';
import { TableRow } from './TableRow';
import { TableCell } from './TableCell';
import { TableContext } from './TableContext';
import { TABLE_EVENTS } from './Table.constants';

export interface TableSubComponents {
    Head: typeof TableHead;
    Body: typeof TableBody;
    Foot: typeof TableFoot;
    Row: typeof TableRow;
    Cell: typeof TableCell;
}

type Props = TableProps & React.HTMLAttributes<HTMLDivElement>;

const Table: React.FC<Props> & TableSubComponents = ({
    children,
    listenKeyEvents = false,
    stickyHeader = false,
    isRuled = false,
    onRowChange,
    focusedRowIndex,
    setFocusedRowIndex,
    ...props
}) => {
    const [internalFocusedRowIndex, setInternalFocusedRowIndex] = useState(-1);

    const [tableBodyNode, setBodyNode] = useState<HTMLElement>();
    const dispatchRowChangedEvent = useCallback(
        (newRowIndex: number, didKeyboardNav: boolean) => {
            tableBodyNode?.dispatchEvent(
                new CustomEvent(TABLE_EVENTS.ROW_CHANGED, {
                    detail: {
                        newRowIndex,
                        didKeyboardNav,
                    },
                }),
            );
        },
        [tableBodyNode],
    );

    const tableContext = useMemo(() => {
        const useFocusedRowIndexStateOverrides = setFocusedRowIndex && focusedRowIndex !== undefined;

        return {
            dispatchRowChangedEvent,
            focusedRowIndex: useFocusedRowIndexStateOverrides ? focusedRowIndex : internalFocusedRowIndex,
            isRuled,
            listenKeyEvents,
            onRowChange,
            setBodyNode,
            setFocusedRowIndex: useFocusedRowIndexStateOverrides ? setFocusedRowIndex : setInternalFocusedRowIndex,
            stickyHeader,
            tableBodyNode,
        };
    }, [
        dispatchRowChangedEvent,
        internalFocusedRowIndex,
        setInternalFocusedRowIndex,
        focusedRowIndex,
        setFocusedRowIndex,
        isRuled,
        listenKeyEvents,
        onRowChange,
        setBodyNode,
        stickyHeader,
        tableBodyNode,
    ]);

    return (
        <TableContext.Provider value={tableContext}>
            <Styled.Table {...props}>{children}</Styled.Table>
        </TableContext.Provider>
    );
};

Table.displayName = 'Table';

Table.Head = TableHead;
Table.Body = TableBody;
Table.Foot = TableFoot;
Table.Row = TableRow;
Table.Cell = TableCell;

const DoczTableProps: React.FC<TableProps> = () => <></>;

export { Table, DoczTableProps };
