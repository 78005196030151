import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { GET_LESSONS_POST } from 'api/lessons/queries';
import {
  LessonsPostQuery,
  LessonsPostQueryVariables,
  LessonsPostIdType,
} from 'types/generated/graphql';
import { computePostsData } from 'helpers/posts';
import { Cards } from 'ui';

export interface LessonCarouselProps {
  title?: string;
  subtitle?: string;
  lessonId: string;
}

export const LessonCarousel: FC<LessonCarouselProps> = ({
  title,
  subtitle,
  lessonId,
}) => {
  const { locale } = useRouter();

  const { data } = useQuery<LessonsPostQuery, LessonsPostQueryVariables>(
    GET_LESSONS_POST,
    {
      variables: {
        id: lessonId,
        idType: LessonsPostIdType.DatabaseId,
        lessonPostsFirst: 20,
        lessonPostsWhere: {
          wpmlLanguage: locale,
        },
      },
      skip: !lessonId,
      errorPolicy: 'all',
    }
  );

  const lessons = computePostsData(data?.lessonsPost?.posts?.nodes);

  if (!lessonId) {
    return null;
  }

  return (
    <Cards
      posts={lessons}
      title={title}
      subtitle={subtitle}
      withCardsSubtitle
    />
  );
};
