import React, { useMemo, useState, useCallback } from 'react';
import { Text } from '../Text';
import { StarRatingProps } from './types';
import * as Styled from './styled';

type Props = StarRatingProps & React.HTMLAttributes<HTMLDivElement>;

export const StarRating: React.FC<Props> = ({
    currentRating = 0,
    maxRating = 5,
    isActive = false,
    onClick,
    type = 'default',
    size = 'md',
    variant = 'default',
    ...rest
}: Props) => {
    const [hoveredElIndex, hover] = useState(-1);

    const onClickHandler = useCallback(
        (rating: number) => (): void => {
            if (isActive) {
                onClick && onClick(rating);
            }
        },
        [isActive, onClick],
    );

    const onHoverHandler = useCallback(
        (index: number) => (): void => {
            if (isActive) {
                hover(index);
            }
        },
        [hover, isActive],
    );

    // need this because font size should be smaller than icon size
    const textSize = useMemo(() => {
        switch (size) {
            case 'sm':
                return 'xs';
            case 'md':
                return 'sm';
            case 'lg':
                return 'md';
            default:
                return 'sm';
        }
    }, [size]);

    // render default rating
    const defaultRating = useMemo(
        () =>
            Array.from({ length: maxRating }).map((_val, index) => {
                if (hoveredElIndex === -1) {
                    // render filled star
                    if (index + 1 <= currentRating) {
                        return (
                            <Styled.StarFilled
                                variant={variant}
                                size={size}
                                key={index}
                                onMouseEnter={onHoverHandler(index)}
                                onClick={onClickHandler(index + 1)}
                                onTouchStart={onClickHandler(index + 1)}
                            />
                        );
                    }
                    // render half-star
                    if (Number(`${index}.26`) <= currentRating && Number(`${index}.75`) >= currentRating) {
                        return (
                            <Styled.StarHalfFilled
                                variant={variant}
                                size={size}
                                key={index}
                                onMouseEnter={onHoverHandler(index)}
                                onClick={onClickHandler(index + 1)}
                                onTouchStart={onClickHandler(index + 1)}
                            />
                        );
                    }
                } else {
                    // render filled star. for hover event only
                    if (hoveredElIndex >= index) {
                        return (
                            <Styled.StarFilled
                                variant={variant}
                                size={size}
                                key={index}
                                onMouseEnter={onHoverHandler(index)}
                                onMouseLeave={onHoverHandler(-1)}
                                onClick={onClickHandler(index + 1)}
                                onTouchStart={onClickHandler(index + 1)}
                            />
                        );
                    }
                }
                // render empty star
                return (
                    <Styled.StarOutlined
                        variant={variant}
                        size={size}
                        key={index}
                        onMouseEnter={onHoverHandler(index)}
                        onClick={onClickHandler(index + 1)}
                        onTouchStart={onClickHandler(index + 1)}
                    />
                );
            }),
        [maxRating, hoveredElIndex, variant, size, onHoverHandler, currentRating, onClickHandler],
    );

    // render numeric rating
    const numericRating = useMemo(
        () => (
            <>
                <Text size={textSize} fontWeight="bold">
                    {currentRating}
                </Text>
                <Styled.StarFilled size={size} variant={variant} />
            </>
        ),
        [currentRating, variant, size, textSize],
    );

    return (
        <Styled.StarRating isActive={isActive} variant={variant} type={type} {...rest}>
            {type === 'numeric' ? numericRating : defaultRating}
        </Styled.StarRating>
    );
};

StarRating.displayName = 'StarRating';
