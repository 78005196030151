import { maestroComponent } from '../../utils';
import { Box } from '../Box';
import styled from '../../utils/styled';
import { HiddenProps } from './types';

const StyledHidden = styled(Box).attrs(({ isVisible }: HiddenProps) => ({
    hidden: !isVisible,
}))<HiddenProps>``;

export const Hidden = maestroComponent<HiddenProps>('Hidden', StyledHidden);

Hidden.defaultProps = {
    isVisible: false,
};
