const INSPIRATION_SLUGS = [
  'inspiration',
  'songwriting-inspiration',
  'inspiration-fr',
  'ispirazione',
  'インスピレーション',
  'inspiracao',
  'inspiracion',
];
const BEAT_MAKING_SLUGS = [
  'beat-making',
  'beatmaking',
  'beat-making-de',
  'creatore-di-beat',
  'ビートメイキング',
  'criacao-de-batidas',
  'creador-de-beats',
];
const MIXING_MASTERING_SLUGS = [
  'mixing-mastering',
  'mixage-mastering',
  'abmischung-mastering',
  'mix-mastering',
  'ミキシングとマスタリング',
  'mixagem-masterizacao',
  'mezcla-masterizacion',
];
const RECORDING_SLUGS = [
  'music-recording',
  'enregistrement',
  'aufzeichnung',
  'registrazione',
  '録音',
  'gravacao',
  'grabacion',
];
const MUSIC_THEORY_SLUGS = [
  'music-theory',
  'theorie-musicale',
  'musiktheorie',
  'teoria-musicale',
  '音楽理論',
  'teoria-musical',
  'teoria-musical',
];
const MUSIC_DISTRIBUTION_SLUGS = [
  'music-distribution',
  'distribution-musicale',
  'distribution',
  'distribucion-musical',
  '音楽配信',
  'distribuicao',
  'distribucion-musical',
];
const MUSIC_GEAR_SLUGS = [
  'music-gear',
  'materiel-de-musique',
  'musik-ausrustung',
  'strumenti-musicali',
  '音楽機器',
  'equipamento-musical',
  'equipo-de-creacion-musical',
];
const MUSIC_PROMOTION_SLUGS = [
  'music-promotion',
  'promotion-musicale',
  'musik-promotion',
  'promozione-musicale',
  '音楽プロモーション',
  'promocao-musica',
  'promocion-musical',
];

export const CATEGORY_SLUGS = [
  ...INSPIRATION_SLUGS,
  ...BEAT_MAKING_SLUGS,
  ...MIXING_MASTERING_SLUGS,
  ...RECORDING_SLUGS,
  ...MUSIC_THEORY_SLUGS,
  ...MUSIC_DISTRIBUTION_SLUGS,
  ...MUSIC_GEAR_SLUGS,
  ...MUSIC_PROMOTION_SLUGS,
];
