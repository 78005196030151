import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCameraOutlined = ({ size, title = 'CameraOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path strokeLinecap="round" d="M7.08 9.08h2.34" />
            <path
                strokeLinecap="square"
                d="M15.25 10.25v-3.5c0-.644-.523-1.167-1.167-1.167H4.75c-.644 0-1.167.523-1.167 1.167v10.5c0 .644.523 1.167 1.167 1.167h9.333c.644 0 1.167-.523 1.167-1.167v-3.5l5.833 2.333V7.917L15.25 10.25z"
            />
        </g>
    </svg>
);

export default withIcon(SvgCameraOutlined);
