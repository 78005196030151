import css from '@styled-system/css';
import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { themeMode } from '../../utils/theme';
import { Text, TextProps } from '../Text';
import { TooltipProps } from './types';

type StyledProps = Pick<TooltipProps, 'isInverted'>;

export const Tooltip = styled(Box)<StyledProps>(
    {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        textAlign: 'center',
        minWidth: '50px',
    },
    (props) =>
        css({
            borderRadius: 'sm',
            py: 'xxs',
            px: 'sm',
            color: themeMode({
                light: props.isInverted ? 'neutral.1000' : 'neutral.0',
                dark: props.isInverted ? 'neutral.0' : 'neutral.1000',
            })(props),
            backgroundColor: themeMode({
                light: props.isInverted ? 'neutral.0' : 'neutral.1000',
                dark: props.isInverted ? 'neutral.1000' : 'neutral.0',
            })(props),
        }),
);

export const Content = styled(Text)<TextProps>({
    width: 'max-content',
    wordBreak: 'break-word',
});
