import { useState, useCallback } from 'react';
import { callAllHandlers } from '../../utils';

export interface UseDisclosureProps {
    defaultIsOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

export interface UseDisclosure {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    getTriggerProps: (props?: any) => Record<string, unknown>;
    getDisclosureProps: (props?: any) => Record<string, unknown>;
}

export const useDisclosure = (props: UseDisclosureProps = {}): UseDisclosure => {
    const { defaultIsOpen = false, onOpen: onOpenProp, onClose: onCloseProp } = props;

    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const onOpen = useCallback(() => {
        setIsOpen(true);
        onOpenProp?.();
    }, [onOpenProp]);

    const onClose = useCallback(() => {
        setIsOpen(false);
        onCloseProp?.();
    }, [onCloseProp]);

    const onToggle = useCallback(() => {
        isOpen ? onClose() : onOpen();
    }, [isOpen, onClose, onOpen]);

    const getTriggerProps = useCallback(
        (props: any = {}): Record<string, unknown> => ({
            ...props,
            'aria-expanded': isOpen,
            onClick: callAllHandlers(props.onClick, onOpen),
        }),
        [isOpen, onOpen],
    );

    const getDisclosureProps = (props: any = {}): Record<string, unknown> => ({ ...props });

    return { isOpen, onOpen, onClose, onToggle, getTriggerProps, getDisclosureProps };
};
