import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLockFilled = ({ size, title = 'LockFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M5.999 8A1.99 1.99 0 004 9.991v9.118c0 1.098.898 1.991 1.999 1.991H18a1.99 1.99 0 002-1.991V9.99A1.998 1.998 0 0018.001 8H6zm7.501 5.5a1.5 1.5 0 01-.75 1.3V17a.75.75 0 01-1.5 0v-2.2a1.5 1.5 0 112.25-1.3z"
            clipRule="evenodd"
        />
        <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M16.25 8a4.25 4.25 0 00-8.5 0" />
    </svg>
);

export default withIcon(SvgLockFilled);
