import React from 'react';
import { SpaceProps } from 'styled-system';
import styled from '../../utils/styled';
import { Box } from '../Box';
import { Spacing } from '../../themes';
import { spacing } from '../../utils/theme';

export interface GridProps {
    /**
     * Width of a grid column in px.
     */
    columnWidth: number;
    /**
     * Gap between rows and columns
     * @default md
     */
    gutter?: Spacing;
}

type Props = GridProps & SpaceProps & React.HTMLAttributes<HTMLDivElement>;

export const Grid: React.FC<Props> = styled(Box)<GridProps>`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${({ columnWidth }): number => columnWidth}px);
    gap: ${({ gutter = 'md' }) => spacing(gutter)};
    > div,
    > a {
        margin: 0;
    }
`;

Grid.displayName = 'Grid';
