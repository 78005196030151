import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLinkedinFilled = ({ size, title = 'LinkedinFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M8.155 19H5.197v-8.842h2.958V19zM6.676 8.438a1.73 1.73 0 01-1.558-1.095 1.714 1.714 0 01.405-1.856A1.727 1.727 0 018.45 6.719a1.746 1.746 0 01-.532 1.224c-.332.322-.78.5-1.243.495zM19 19h-2.958v-4.657c0-1.395-.591-1.896-1.36-1.896a1.718 1.718 0 00-1.513 1.163c-.072.214-.1.44-.085.664a.646.646 0 000 .138V19h-2.957v-8.842h2.859v1.277a3.062 3.062 0 012.662-1.376c1.528 0 3.313.845 3.313 3.596L19 19z"
        />
    </svg>
);

export default withIcon(SvgLinkedinFilled);
