import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSessions = ({ size, title = 'Sessions', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#435261"
            d="M43.553 13.105l-12 6A1 1 0 0031 20v8a1 1 0 00.553.895l12 6A1 1 0 0044 35V13a1 1 0 00-.447.105z"
        />
        <path fill="#2C3C4D" d="M31 39H3a2 2 0 01-2-2V11a2 2 0 012-2h28a2 2 0 012 2v26a2 2 0 01-2 2z" />
        <path fill="#EA5B70" d="M7 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z" />
        <path fill="#435261" d="M28 16h-4a1 1 0 110-2h4a1 1 0 110 2z" />
        <path fill="#2C3C4D" d="M46 13h-2v22h2a1 1 0 001-1V14a1 1 0 00-1-1z" />
        <path fill="#435261" d="M28 21H6a1 1 0 00-1 1v13a1 1 0 001 1h22a1 1 0 001-1V22a1 1 0 00-1-1z" />
    </svg>
);

export default withIcon(SvgSessions);
