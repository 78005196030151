import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPlaylistOutlined = ({ size, title = 'PlaylistOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path d="M9.5 13l3 3-3 3" />
            <path d="M12.5 16h-4a4 4 0 01-4-4h0a4 4 0 014-4h4m3 4h4-4zm0 4h4-4zm0-8h4-4z" />
        </g>
    </svg>
);

export default withIcon(SvgPlaylistOutlined);
