import React from 'react';
import { runIfFn } from '../../utils';
import { Flex } from '../Flex';
import { useNavBarContext } from './NavBarContext';

export const NavBarSubMenu: React.FC = ({ children }) => {
    const { isMobile } = useNavBarContext();

    return (
        <Flex alignItems="center" height="100%">
            {runIfFn(children, { isMobile })}
        </Flex>
    );
};
NavBarSubMenu.displayName = 'NavBar.SubMenu';
