type EmbeddedYoutubePlayer = {
  videoId: string;
};

export const EmbeddedYoutubePlayer: React.FC<EmbeddedYoutubePlayer> = ({
  videoId,
}) => {
  return (
    <div style={{ width: '100%', paddingTop: '56.3%', position: 'relative' }}>
      <iframe
        data-testid="youtube-iframe"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          border: 0,
          width: '100%',
          height: '100%',
        }}
        allowFullScreen
        allow="autoplay"
        src={`https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0`}
        loading="lazy"
      ></iframe>
    </div>
  );
};
