import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useLockBodyScroll = (toggle, targetRef): void => {
    useEffect(() => {
        const targetElement = targetRef.current;
        if (!toggle || !targetElement) {
            return;
        }
        disableBodyScroll(targetElement);
        return (): void => enableBodyScroll(targetElement);
    }, [targetRef, toggle]);
};
