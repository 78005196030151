/** Calculates the number of pages. */
export const numberOfPagesFromItems = (items: number, itemsPerPage: number): number => {
    return Math.ceil(items / itemsPerPage);
};

/** Returns an array of all the pages in the range */
export const getPagesRange = (from: number, to: number): number[] =>
    Array.from(Array(to + 1 - from).keys()).map((i) => from + i);

/**
 * Determines if the page should change based on the
 * expected next page and the number of pages.
 */
export const changePageIfAppropriate = (
    nextPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageChanged?: (nextPage: number) => void,
): void => {
    const pageCount = numberOfPagesFromItems(totalItems, itemsPerPage);
    const nextPageExists = nextPage > 0 && nextPage <= pageCount;
    if (nextPageExists && typeof onPageChanged === 'function') {
        onPageChanged(nextPage);
    }
};
