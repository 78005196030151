import React from 'react';
import { color, space, compose, border, variant, height } from 'styled-system';
import styled, { CSSObject } from 'styled-components';
import { Heading, HeadingProps } from '../Heading';
import { DismissButtonProps, DismissButton } from '../DismissButton';
import { IconButton, IconButtonProps } from '../IconButton';
import { ArrowBackOutlined } from '../../assets/icons';
import { spacing, color as colorToken, themeMode, radius } from '../../utils';
import { forwardRef } from '../../system';
import { Box } from '../../primitives/Box';
import { Flex } from '../Flex';
import { ModalHeaderProps, ModalProps, ModalSize } from './types';
import { useModalContext } from './ModalContext';

const StyledModalCloseButton = styled(DismissButton)<DismissButtonProps & { modalSize: ModalSize }>`
    transform: translateX(${(props): string | number => spacing(props.modalSize === 'sm' ? 'xs' : 'md')(props)});

    ${({ theme }): CSSObject => theme.breakpoint('sm')`
        transform: translateX(${spacing('xs')});
    `}
`;

export const ModalCloseButton = forwardRef<DismissButtonProps, 'button'>(
    ({ kind = 'solid', variant = 'subtle', size = 'sm', ...props }, ref) => {
        const { onClose, size: modalSize } = useModalContext();
        return (
            <StyledModalCloseButton
                ref={ref}
                onClick={onClose}
                kind={kind}
                variant={variant}
                size={size}
                modalSize={modalSize}
                isRounded
                {...props}
            />
        );
    },
);

ModalCloseButton.displayName = 'Modal.CloseButton';

const StyledModalBackButton = styled(IconButton)<DismissButtonProps & { modalSize: ModalSize }>`
    transform: translateX(-${(props): string | number => spacing(props.modalSize === 'sm' ? 'xs' : 'md')(props)});

    ${({ theme }): CSSObject => theme.breakpoint('sm')`
        transform: translateX(-${spacing('xs')});
    `}
`;

export const ModalBackButton = forwardRef<IconButtonProps, 'button'>(
    ({ kind = 'solid', variant = 'subtle', size = 'sm', ...props }, ref) => {
        const { size: modalSize } = useModalContext();
        return (
            <StyledModalBackButton
                ref={ref}
                kind={kind}
                variant={variant}
                size={size}
                icon={ArrowBackOutlined}
                modalSize={modalSize}
                hideTooltip
                isRounded
                {...props}
            />
        );
    },
);

ModalBackButton.displayName = 'Modal.BackButton';

export const ModalTitle = forwardRef<HeadingProps, 'h2'>((props, ref) => (
    <Heading ref={ref} size="xl" m={0} nbOfLines={1} {...props} />
));

ModalTitle.displayName = 'Modal.Title';

const styles = variant({
    prop: 'size',
    variants: {
        sm: {
            px: [null, 'md', 'lg'],
            py: [null, 'md', 'lg'],
            pb: 'sm',
        },
        md: {
            px: [null, 'md', 'xxl'],
            py: [null, 'md', 'xl'],
            pb: 'sm',
        },
        lg: {
            px: [null, 'md', 'xxl'],
            pt: [null, 'md', 'xl'],
            pb: 'sm',
        },
    },
});

const StyledModalHeader = styled('header')<ModalHeaderProps & Pick<ModalProps, 'size'>>`
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    overflow: hidden;
    background-color: ${themeMode({
        dark: colorToken('neutral.900'),
        light: colorToken('neutral.0'),
    })};
    border-top-left-radius: ${radius('base')};
    border-top-right-radius: ${radius('base')};
    ${styles};
    ${compose(space, color, border, height)};
`;

export const ModalHeader = forwardRef<ModalHeaderProps, 'div'>(({ children, ...props }, ref) => {
    const { isDismissible, size } = useModalContext();

    return (
        <StyledModalHeader ref={ref} size={size} {...props}>
            <Flex flex="1 0 100%">
                <Flex flex="1 1 auto">{children}</Flex>
                {isDismissible && (
                    <Box flex="0 0 auto" ml="auto">
                        <ModalCloseButton />
                    </Box>
                )}
            </Flex>
        </StyledModalHeader>
    );
});

ModalHeader.displayName = 'Modal.Header';
