import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgEmojiOutlined = ({ size, title = 'EmojiOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M7.66 13.582A4.62 4.62 0 0012 16.637c2.002 0 3.692-1.277 4.341-3.055"
            />
            <path fill="currentColor" d="M10 10a1 1 0 11-2 0 1 1 0 012 0m6 0a1 1 0 11-2 0 1 1 0 012 0" />
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M20.25 12a8.25 8.25 0 11-16.501-.001A8.25 8.25 0 0120.25 12z"
            />
        </g>
    </svg>
);

export default withIcon(SvgEmojiOutlined);
