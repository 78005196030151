import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgClockOutlined = ({ size, title = 'ClockOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M13.5 8L11 12.33l3.897 2.25"
        />
        <path stroke="currentColor" strokeWidth={1.5} d="M12 20.5a8.5 8.5 0 100-17 8.5 8.5 0 000 17z" />
    </svg>
);

export default withIcon(SvgClockOutlined);
