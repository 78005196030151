import { IconButton, IconButtonProps } from '../IconButton';
import styled from '../../utils/styled';

export const Action = styled(IconButton).attrs(() => ({
    label: '',
    variant: 'subtle',
    isRounded: true,
    size: 'sm',
    hideTooltip: true,
}))<IconButtonProps>``;

Action.displayName = 'FeatureCard.Action';
