import React from 'react';
import { InfoFilled } from '../../assets/icons';
import { forwardRef } from '../../system';
import { MessageProps, MessageVariant } from './types';
import * as Styled from './styled';

const getCtaVariant = (variant: MessageVariant) => {
    switch (variant) {
        case 'default':
        case 'contrast':
        case 'subtle':
            return 'primary';
        default:
            return variant;
    }
};

export const Message = forwardRef<MessageProps, 'div'>((props, ref) => {
    const { children, icon: Icon, title, variant = 'default', kind = 'default', actions, ...rest } = props;

    return (
        <Styled.Message variant={variant} kind={kind} ref={ref} {...rest}>
            <Styled.IconWrapper as={Icon || InfoFilled} variant={variant} />
            <Styled.Wrapper>
                {title && <Styled.Title>{title}</Styled.Title>}
                {children}
                {actions && (
                    <Styled.ActionGroup>
                        {actions &&
                            actions.map((action, i) => (
                                <Styled.Cta key={i} onClick={action.onClick} variant={getCtaVariant(variant)}>
                                    {action.cta}
                                </Styled.Cta>
                            ))}
                    </Styled.ActionGroup>
                )}
            </Styled.Wrapper>
        </Styled.Message>
    );
});
