import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCourses = ({ size, title = 'Courses', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#435261"
            d="M29.25 7.5h-27A2.25 2.25 0 000 9.75v21A2.25 2.25 0 002.25 33h27a2.25 2.25 0 002.25-2.25v-21a2.25 2.25 0 00-2.25-2.25z"
        />
        <path
            fill="#142739"
            d="M33.75 4h-27A2.25 2.25 0 004.5 6.25v21a2.25 2.25 0 002.25 2.25h27A2.25 2.25 0 0036 27.25v-21A2.25 2.25 0 0033.75 4z"
        />
        <path fill="#3C6ED7" d="M31.75 10.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
        <path
            fill="#F9FAFB"
            d="M23.826 17.087a.597.597 0 01-.168.168l-5.464 3.643a.608.608 0 01-.944-.505v-7.286a.607.607 0 01.944-.505l5.464 3.643a.608.608 0 01.168.842z"
        />
    </svg>
);

export default withIcon(SvgCourses);
