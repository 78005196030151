import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFolderMusic = ({ size, title = 'FolderMusic', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path
                strokeLinecap="square"
                d="M19.8 20c.663 0 1.2-.53 1.2-1.185V6.37h-9.6L9.6 4H3v14.815C3 19.469 3.537 20 4.2 20h15.6z"
            />
            <path d="M10.18 16.38V11m5.39 4.18V11" />
            <path
                strokeLinecap="square"
                d="M10.184 9.201v1.794h5.383V9.201zm-1.196 5.981a1.196 1.196 0 110 2.392 1.196 1.196 0 010-2.392zm5.382-1.196a1.196 1.196 0 110 2.392 1.196 1.196 0 010-2.392z"
            />
        </g>
    </svg>
);

export default withIcon(SvgFolderMusic);
