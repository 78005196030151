import React from 'react';
import { forwardRef } from '../../system';
import { Nav } from '../Nav';
import { useBreakpoint } from '../../hooks';
import { ProductHeaderNavProps } from './types';
import { MOBILE_BREAKPOINT } from './styled';

export const ProductHeaderNav = forwardRef<ProductHeaderNavProps, 'nav'>((props, ref) => {
    const { children, useOverflow = true, as = 'nav', ...rest } = props;
    const isMobile = useBreakpoint(MOBILE_BREAKPOINT);

    return (
        <Nav as={as} isVertical={isMobile} ref={ref} {...rest}>
            {useOverflow ? <Nav.Overflow>{children}</Nav.Overflow> : children}
        </Nav>
    );
});
ProductHeaderNav.displayName = 'ProductHeader.Nav';
