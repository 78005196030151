import { gql } from '@apollo/client';

const MenuItem = gql`
  fragment MenuItem on MenuItem {
    id
    label
    path
    title
    target
    menu {
      node {
        id
        name
      }
    }
  }
`;

export const GET_MENU_ITEMS = gql`
  query MenuItems(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: RootQueryToMenuItemConnectionWhereArgs
  ) {
    menuItems(
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
    ) {
      nodes {
        ...MenuItem
      }
    }
  }
  ${MenuItem}
`;

export const GET_FOOTER_MENUS = gql`
  query FooterMenu(
    $firstWhere: RootQueryToMenuItemConnectionWhereArgs
    $secondWhere: RootQueryToMenuItemConnectionWhereArgs
    $thirdWhere: RootQueryToMenuItemConnectionWhereArgs
  ) {
    footer1: menuItems(where: $firstWhere) {
      nodes {
        ...MenuItem
      }
    }
    footer2: menuItems(where: $secondWhere) {
      nodes {
        ...MenuItem
      }
    }
    footer3: menuItems(where: $thirdWhere) {
      nodes {
        ...MenuItem
      }
    }
  }
  ${MenuItem}
`;
