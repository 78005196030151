import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFiltersOutlined = ({ size, title = 'FiltersOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15.5 7.5h4-4zm-11 0h2-2zm4 9h-4 4zm11 0h-2 2z"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth={1.5}
                d="M14.5 19.5a3 3 0 110-6 3 3 0 010 6zm-5-9a3 3 0 110-6 3 3 0 010 6z"
            />
        </g>
    </svg>
);

export default withIcon(SvgFiltersOutlined);
