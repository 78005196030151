import styled from '../../utils/styled';
import { MaestroStyledComponent } from '../../utils';
import { Box, BoxProps } from '../../primitives/Box';
import { TruncateByLinesProps } from './types';

const TruncateByLines: MaestroStyledComponent<TruncateByLinesProps> & BoxProps = styled(Box)<TruncateByLinesProps>`
    display: -webkit-box;
    -webkit-line-clamp: ${({ limit }) => limit};
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

interface StyledTruncateByLines {
    TruncateByLines: typeof TruncateByLines;
}

export const Styled: StyledTruncateByLines = {
    TruncateByLines,
};
