const getSuggestedCurrentSlide = (container: HTMLDivElement): number => {
    const wrapperOffset = container.getBoundingClientRect().left;
    const children = Array.from(container.children);
    const childrenDistance: { [key: number]: number } = children.reduce((a, x, i) => {
        a[i] = Math.abs(wrapperOffset - x.getBoundingClientRect().left);
        return a;
    }, {});
    const closestChildDistance = Math.min(...Object.values(childrenDistance));

    const [suggestedCurrentSlide] = Object.keys(childrenDistance).filter(
        (k) => childrenDistance[k] === closestChildDistance,
    );
    const slideNumber = parseInt(suggestedCurrentSlide, 10);

    return slideNumber;
};

export default getSuggestedCurrentSlide;
