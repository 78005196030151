import { Button, ButtonProps } from '../.././Button';
import styled from '../../../utils/styled';
import { palette, fontSize } from '../../../utils';
import { Group } from '../../../primitives/Group';
import { Text } from '../../../components/Text';
import { CSSProps } from '../../../primitives/Box';

export const StyledToggleFilterPopupButton = styled(Button)<ButtonProps & CSSProps & { hasSelected: boolean }>`
    color: ${({ hasSelected }) => (hasSelected ? palette('neutral.0') : palette('neutral.400'))};
    :hover {
        color: ${palette('neutral.0')};
    }
`;

export const StyledGroupItem = styled(Group.Item)``;

export const MobileToggleResponsiveMenuButtonWrapper = styled.div`
    display: flex;
`;

export const MobileAppliedFiltersListItem = styled.button`
    font-size: ${fontSize('md')};
    color: ${palette('neutral.400')};
    display: flex;
    max-width: 60%;
    padding: 0;
    align-items: center;
    outline: none;
    svg {
        flex-shrink: 0;
    }
`;

export const MobileAppliedFiltersListItemText = styled(Text)`
    display: block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
