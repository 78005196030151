import styled from '../../utils/styled';
import { spacing, palette, themeMode, color, variant } from '../../utils/theme';
import { StyledCheckboxLabelProps, StyledCheckboxControlProps, StyledCheckboxProps } from './types';
import { CheckboxPrimitive } from './CheckboxPrimitive';

const sizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            width: spacing(4),
            height: spacing(4),
        },
        md: {
            width: spacing(4),
            height: spacing(4),
        },
        lg: {
            width: spacing(6),
            height: spacing(6),
        },
    },
});

export const Control = styled(CheckboxPrimitive.Control)<StyledCheckboxControlProps>`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    transition: opacity 200ms ease-in;

    svg {
        opacity: 0;
        color: ${themeMode({ light: palette('neutral.50'), dark: palette('neutral.900') })};
        transition: all 200ms ease-in;
    }

    border: 1px solid ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};
    color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') })};

    ${sizeStyles};
`;

Control.displayName = 'Checkbox.Control';

const labelSizeStyles = variant({
    prop: 'size',
    variants: {
        sm: { fontSize: 'sm' },
        md: { fontSize: 'md' },
        lg: { fontSize: 'lg' },
    },
});

export const Label = styled(CheckboxPrimitive.Label)<StyledCheckboxLabelProps>`
    margin-left: ${spacing('xs')};
    user-select: none;
    color: ${color('text.base')};
    color: ${({ isDisabled }) =>
        isDisabled ? themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') }) : 'text.base'};

    ${labelSizeStyles};
`;

Label.displayName = 'Checkbox.Label';

export const Checkbox = styled(CheckboxPrimitive.Root)<StyledCheckboxProps>`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: ${spacing('xs')};

    &[data-state='checked']:not([data-disabled='true']) > div:first-of-type {
        background-color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') })};
        border-color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') })};

        &:hover {
            background-color: ${themeMode({
                light: palette('neutral.700'),
                dark: palette('neutral.200'),
            })};
            border-color: ${themeMode({
                light: palette('neutral.700'),
                dark: palette('neutral.200'),
            })};
        }

        svg {
            opacity: 1;
        }
    }

    &[data-disabled='true'][data-state='checked'] > div:first-of-type {
        background-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};
        border-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};

        &:hover {
            background-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};
            border-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};
        }

        svg {
            opacity: 1;
        }
    }

    &[data-disabled='true'] > div:first-of-type {
        border-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};

        &:hover {
            border-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};
        }
    }

    &[data-invalid='true'] > div:first-of-type {
        border-color: ${palette('danger.500')};
    }

    & > div:first-of-type {
        &:hover {
            border-color: ${themeMode({
                light: palette('neutral.700'),
                dark: palette('neutral.200'),
            })};
        }
    }

    &:hover {
        cursor: pointer;
    }

    &[data-disabled='true'] {
        &:hover {
            cursor: not-allowed;
        }
    }
`;

Checkbox.displayName = 'Checkbox';
