import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFlagOutlined = ({ size, title = 'FlagOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M5 14.7s.9-.9 3.6-.9 4.5 1.8 7.2 1.8 3.6-.9 3.6-.9V3.9s-.9.9-3.6.9S11.3 3 8.6 3 5 3.9 5 3.9v10.8zM5 21v-6.3"
        />
    </svg>
);

export default withIcon(SvgFlagOutlined);
