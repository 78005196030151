import { createContext } from 'react';
import { PopoverContextOptions } from './types';

export const PopoverContext = createContext<PopoverContextOptions>({
    isOpen: false,
    open: () => void 0,
    close: () => void 0,
    getTriggerProps: () => void 0,
    getPopoverProps: () => void 0,
    rest: {},
});
