import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgRemoveFilled = ({ size, title = 'RemoveFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 3a9 9 0 110 18 9 9 0 010-18zM9.618 8.569a.75.75 0 00-.977 1.133L10.94 12l-2.299 2.298-.072.084a.75.75 0 001.133.977L12 13.06l2.298 2.299.084.072a.75.75 0 00.977-1.133L13.06 12l2.299-2.298.072-.084a.75.75 0 00-1.133-.977L12 10.94 9.702 8.641z"
        />
    </svg>
);

export default withIcon(SvgRemoveFilled);
