import { FC } from 'react';
import { useGetPosts } from 'hooks/useGetPosts';
import { OneUpStandardLesson, OneUpVideoLesson } from './components';

interface OneUpLessonProps {
  categoryId?: number | null;
  author: number | null;
  postId?: string | null;
}

export const OneUpLesson: FC<OneUpLessonProps> = ({
  categoryId,
  author,
  postId,
}) => {
  const posts = useGetPosts({
    count: 1,
    author,
    categoryId,
    postIds: postId ? [postId] : null,
  });

  const lesson = posts[0];

  if (!lesson) {
    return null;
  }

  if (lesson.video) {
    return <OneUpVideoLesson post={lesson} />;
  }

  return <OneUpStandardLesson post={lesson} />;
};
