import { ChangeEvent, useState, useEffect, KeyboardEventHandler } from 'react';
import { useRouter } from 'next/router';

import { SEARCH_KEY } from '../../../constants/queryParams';
import SearchLoupe from '../../../public/icons/search-loupe.svg';
import styles from './SearchForm.module.scss';

export const SearchForm = () => {
  const { push, pathname, query, isReady } = useRouter();
  const searchKeyFromParams = (query?.[SEARCH_KEY] as string) || '';

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (isReady) {
      setSearchText(searchKeyFromParams);
    }
  }, [isReady, searchKeyFromParams]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleOnKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (searchText && event.key === 'Enter') {
      push(
        {
          pathname,
          query: {
            [SEARCH_KEY]: searchText,
          },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  return (
    <div className={styles.root}>
      <SearchLoupe
        className={styles.icon}
        aria-hidden="true"
        role="presentation"
        focusable="false"
      />
      <input
        className={styles.input}
        type="search"
        placeholder="Search..."
        onChange={handleInputChange}
        onKeyPress={handleOnKeyPress}
        value={searchText}
        required
      />
    </div>
  );
};
