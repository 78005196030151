import React, { FC } from 'react';
import { DropdownMenu } from '../DropdownMenu';
import { CaretDownOutlined } from '../../assets/icons';
import { Box } from '../../primitives/Box';
import { Flex } from '../Flex';
import { forwardRef } from '../../system';
import { NavLink } from './NavLink';
import { NavDropdownProps, NavDropdownTriggerProps, NavDropdownContentProps, NavDropdownItemProps } from './types';

export const NavDropdown: FC<NavDropdownProps> = (props) => {
    const { children, placement = 'bottom-start', triggerEvent, usePortal = false, isLazy = false, ...rest } = props;

    return (
        <Flex {...rest}>
            <DropdownMenu placement={placement} usePortal={usePortal} isLazy={isLazy} triggerEvent={triggerEvent}>
                {children}
            </DropdownMenu>
        </Flex>
    );
};

NavDropdown.displayName = 'Nav.Dropdown';

export const NavDropdownTrigger: FC<NavDropdownTriggerProps> = (props) => {
    const { children, hasCaret, onClick, ...rest } = props;

    return (
        <DropdownMenu.Trigger {...rest}>
            <NavLink as="button" onClick={onClick} style={{ cursor: 'pointer' }}>
                {children}
                {hasCaret && (
                    <Box as="span" display="inline-flex" ml="xxs">
                        <CaretDownOutlined size="sm" />
                    </Box>
                )}
            </NavLink>
        </DropdownMenu.Trigger>
    );
};

NavDropdownTrigger.displayName = 'Nav.DropdownTrigger';

export const NavDropdownContent = forwardRef<NavDropdownContentProps, 'div'>((props, ref) => {
    const { children, maxWidth = '360px', ...rest } = props;

    return (
        <DropdownMenu.List ref={ref} maxWidth={maxWidth} {...rest}>
            {children}
        </DropdownMenu.List>
    );
});

NavDropdownContent.displayName = 'Nav.DropdownContent';

export const NavDropdownItem = forwardRef<NavDropdownItemProps, 'a'>((props, ref) => {
    const { as = 'a', ...rest } = props;
    return <DropdownMenu.Item as={as} ref={ref} {...rest} />;
});

NavDropdownItem.displayName = 'Nav.DropdownItem';
