import { useState, useCallback, useEffect } from 'react';
import { DragProps } from './types';

type UseDragProps = Omit<DragProps, 'radius'>;

type UseDrag = {
    currentPosition: number;
    isDragging: boolean;
    handleMouseDown: (event: React.MouseEvent) => void;
    handleTouchStart: (event: React.TouchEvent) => void;
};

export const useDrag = ({
    position,
    minPosition,
    maxPosition,
    onChange,
    onChangeEnd,
    isDisabled,
    defaultPosition,
}: UseDragProps): UseDrag => {
    const [isDragging, setIsDragging] = useState(false);
    const [currentPosition, setCurrentPosition] = useState(position || defaultPosition);
    const [initialOffset, setInitialOffset] = useState(0);
    const [initialClientPosition, setInitialClientPosition] = useState(0);

    useEffect(() => {
        setCurrentPosition(position || defaultPosition);
    }, [position, defaultPosition, setCurrentPosition]);

    const handleMouseMove = useCallback(
        (event) => {
            if (isDragging) {
                let position;
                if (event.touches) {
                    const touch = event.touches[0];
                    if (touch) {
                        position = touch.clientX;
                    }
                } else {
                    position = event.clientX;
                }

                const currentPosition = initialOffset + position - initialClientPosition;

                if (currentPosition >= minPosition && currentPosition <= maxPosition) {
                    onChange?.(currentPosition);
                    setCurrentPosition(currentPosition);
                }
            }
        },
        [isDragging, onChange, initialOffset, maxPosition, minPosition, initialClientPosition],
    );

    const handleDragEnd = useCallback(() => {
        if (isDragging) {
            setIsDragging(false);
            onChangeEnd?.(currentPosition);
        }
    }, [isDragging, currentPosition, onChangeEnd]);

    const handleMouseDown = (event: React.MouseEvent) => {
        if (isDisabled) return;

        event.preventDefault();
        event.stopPropagation();

        setIsDragging(true);
        setInitialClientPosition(event.clientX);
        setInitialOffset(currentPosition);
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        if (isDisabled) return;

        setIsDragging(true);
        if (event.touches) {
            const touch = event.touches[0];
            if (touch) {
                setInitialClientPosition(touch.clientX);
            }
        }

        setInitialOffset(currentPosition);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleMouseMove);
        window.addEventListener('mouseup', handleDragEnd);
        window.addEventListener('touchend', handleDragEnd);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('mouseup', handleDragEnd);
            window.removeEventListener('touchend', handleDragEnd);
        };
    }, [handleMouseMove, handleDragEnd]);

    return {
        currentPosition,
        isDragging,
        handleMouseDown,
        handleTouchStart,
    };
};
