import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgTutorials = ({ size, title = 'Tutorials', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="white"
            d="M4.5 18.75h-3a.75.75 0 110-1.5h3a.75.75 0 110 1.5zm3.954-9.546a.748.748 0 01-.53-.22l-2.121-2.12a.75.75 0 111.06-1.06l2.121 2.121a.75.75 0 01-.53 1.28zM18 5.25a.75.75 0 01-.75-.75v-3a.75.75 0 111.5 0v3a.75.75 0 01-.75.75zm9.546 3.954a.75.75 0 01-.53-1.28l2.12-2.122a.75.75 0 111.061 1.06l-2.12 2.123a.747.747 0 01-.531.22zM34.5 18.75h-3a.75.75 0 110-1.5h3a.75.75 0 110 1.5z"
        />
        <path
            fill="#142739"
            d="M21.75 30.75h-7.5v-3.759c-4.003-1.67-6.506-5.85-5.913-10.29.637-4.776 4.84-8.449 9.659-8.451 5.378-.002 9.754 4.373 9.754 9.75a9.764 9.764 0 01-6 8.991v3.759z"
        />
        <path fill="#435261" d="M18 35.25a3.75 3.75 0 01-3.75-3.75v-2.25h7.5v2.25A3.75 3.75 0 0118 35.25z" />
        <path fill="#2C3C4D" d="M21.75 29.25h-7.5v1.5h7.5v-1.5z" />
        <path
            stroke="#435261"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M24 19a6.98 6.98 0 00-2-4.899 7.016 7.016 0 00-2.108-1.478"
        />
    </svg>
);

export default withIcon(SvgTutorials);
