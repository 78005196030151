import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgBellOutlinedOff = ({ size, title = 'BellOutlinedOff', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M7 10.248A5.253 5.253 0 0112.25 5c2.9 0 5.25 2.347 5.25 5.248v7.002H7v-7.002zm2.25 7.002a3 3 0 006 0"
            />
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M14.25 5c-.294-1.162-1.081-2-2.006-2-.913 0-1.69.864-1.994 2"
            />
            <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M19.5 17.25H5" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M5.25 18.99L19.24 5"
            />
        </g>
    </svg>
);

export default withIcon(SvgBellOutlinedOff);
