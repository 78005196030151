import React from 'react';
import { DefaultTheme } from '../../themes';
import { ThemeProvider } from '../../utils/styled';

export type ColorModeOptions = 'light' | 'dark' | 'invert';

export const setTheme =
    (modeOption: ColorModeOptions) =>
    (theme?: DefaultTheme): DefaultTheme => {
        if (!theme || (theme && !theme.mode)) {
            throw new Error('The `ColorMode` component expected a theme with a mode property but none was provided.');
        }

        let { mode } = theme;

        switch (modeOption) {
            case 'light':
                mode = 'light';
                break;
            case 'dark':
                mode = 'dark';
                break;
            case 'invert':
                if (mode === 'dark') {
                    mode = 'light';
                } else {
                    mode = 'dark';
                }
                break;
            default:
                break;
        }

        return { ...theme, mode };
    };

export interface ColorModeProps {
    /** The color mode to be set */
    mode: ColorModeOptions;
    children: React.ReactNode;
}

/**
 * Force children components to use a specific color mode
 */
export const ColorMode = ({ mode, children }: ColorModeProps) => {
    return <ThemeProvider theme={setTheme(mode)}>{children}</ThemeProvider>;
};
