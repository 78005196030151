import React, { useCallback, useState } from 'react';
import { mergeRefs } from '../../utils';

interface UseClickableProps<T> {
    ref?: React.Ref<T>;
    onClick?: React.MouseEventHandler;
    shouldClickOnEnter?: boolean;
    tabIndex?: number;
    isDisabled?: boolean;
    isFocusable?: boolean;
}

export const useClickable = <T>({
    ref: htmlRef = null,
    onClick,
    shouldClickOnEnter,
    tabIndex,
    isDisabled = false,
    isFocusable = true,
}: UseClickableProps<T>) => {
    const [isButton, setIsButton] = useState(true);

    const refCallback = useCallback((node) => {
        if (node?.tagName !== 'BUTTON') {
            setIsButton(false);
        }
    }, []);

    const _tabIndex = isButton ? tabIndex : tabIndex || 0;
    const trulyDisabled = isDisabled && !isFocusable;

    const handleClick = React.useCallback(
        (event: React.MouseEvent) => {
            if (isDisabled) {
                event.stopPropagation();
                event.preventDefault();

                return;
            }

            const self = event.currentTarget as HTMLElement;

            isFocusable && self.focus();
            onClick?.(event);
        },
        [isFocusable, isDisabled, onClick],
    );

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent) => {
            if (isDisabled || event.defaultPrevented || event.metaKey) {
                return;
            }

            const shouldClick = shouldClickOnEnter && event.key === 'Enter';

            if (!isButton && shouldClick) {
                event.preventDefault();
                const self = event.currentTarget as HTMLElement;

                self.click();
            }
        },
        [isDisabled, isButton, shouldClickOnEnter],
    );

    const ref = mergeRefs(htmlRef, refCallback);

    const buttonAttrs: Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> = {
        type: 'button',
        disabled: trulyDisabled,
    };

    const nonButtonAttrs: Partial<React.HTMLAttributes<HTMLElement>> = {
        role: 'button',
        tabIndex: trulyDisabled ? undefined : _tabIndex,
    };

    if (!onClick) {
        return {
            'aria-disabled': isDisabled,
        };
    }

    return {
        ref,
        'aria-disabled': isDisabled,
        onClick: handleClick,
        onKeyDown: handleKeyDown,
        ...(isButton ? buttonAttrs : nonButtonAttrs),
    };
};
