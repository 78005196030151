import { GetPageDataReturnData } from '../../lib/posts';
import dynamic from 'next/dynamic';

import styles from './Page.module.scss';
import { PostsGrid } from './PostsGrid';

const DynamicErrorState = dynamic(() =>
  import('../Common/ErrorState/ErrorState').then((module) => module.ErrorState)
);

const DynamicEmptyState = dynamic(() =>
  import('../Common/EmptyState/EmptyState').then((module) => module.EmptyState)
);

export type PageProps = GetPageDataReturnData & { pageUrlPrefix?: string };

export const Page = ({
  error,
  totalAmountOfPosts,
  posts,
  page,
  pageUrlPrefix,
}: PageProps) => (
  <div className={styles.root}>
    <main className={styles.main}>
      {error ? (
        <DynamicErrorState />
      ) : !posts?.length ? (
        <DynamicEmptyState />
      ) : (
        <PostsGrid
          posts={posts}
          page={page}
          pageUrlPrefix={pageUrlPrefix}
          totalAmountOfPosts={totalAmountOfPosts}
        />
      )}
    </main>
  </div>
);
