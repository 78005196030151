import React, { useEffect, useRef } from 'react';
import { spacing, themeMode, variant, fontWeight } from '../../utils';
import styled, { css } from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { Text } from '../Text';
import { CheckCircleFilled, CloseOutlined, InfoFilled } from '../../assets/icons';
import { Progress } from '../Progress';
import { NotificationProps } from './types';

const iconVariantStyles = variant({
    prop: 'variant',
    variants: {
        success: {
            color: 'primary.500',
        },
        danger: {
            color: 'danger.500',
        },
        progress: {
            color: 'currentColor',
        },
    },
});

const StyledIcon = styled(Box)<NotificationProps>`
    display: flex;
    align-items: center;
    font-size: 0;
    padding: 0;
    margin-right: ${({ kind }) => (kind === 'persistent' ? spacing('md') : spacing('lg'))};
    ${iconVariantStyles};
`;

const ctaVariantStyles = variant({
    prop: 'variant',
    variants: {
        success: {
            color: 'currentColor',
        },
        danger: {
            color: 'currentColor',
        },
        progress: {
            color: 'currentColor',
        },
    },
});
// TODO: [DS-995] Replace with LinkButton/IconButton after Button refactor
const StyledCta = styled('button').attrs(() => ({ size: 'sm' }))<NotificationProps>`
    display: flex;
    align-items: center;
    height: auto;
    margin-left: auto;
    padding: 0;
    border: none;
    outline: none;
    font-weight: ${fontWeight('bold')};
    cursor: pointer;
    ${ctaVariantStyles};
`;

const StyledContent = styled(Text)<NotificationProps>`
    margin-right: ${spacing('lg')};
    ${({ kind }) =>
        kind === 'persistent' &&
        css`
            overflow: hidden;
            flex-shrink: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
        `}
`;

const kindStyles = variant({
    prop: 'kind',
    variants: {
        temporary: {
            color: 'neutral.900',
            backgroundColor: 'neutral.0',
        },
        persistent: {
            color: 'neutral.50',
            backgroundColor: themeMode({ light: 'neutral.900', dark: 'neutral.800' }),
            maxWidth: '320px',
        },
    },
});

export const StyledNotification = styled<any>(
    ({ variant, kind, title, cta, onClose, children, timeout, icon: CustomIcon, ...props }) => {
        const onCloseRef = useRef(onClose);
        // We have to stash onClose in a ref so that when the timeout finishes it calls
        // the lastest onClose value, not the value when the component was initialized
        onCloseRef.current = onClose;

        useEffect(() => {
            let timeoutId: null | ReturnType<typeof setTimeout> = null;

            if (kind === 'temporary' && typeof timeout === 'number') {
                timeoutId = setTimeout(() => {
                    onCloseRef.current();
                }, timeout);
            }

            return (): void => {
                if (typeof timeoutId === 'number') {
                    clearTimeout(timeoutId);
                }
            };
        }, [timeout, kind]);

        const createIconFromVariant = (variant) => {
            switch (variant) {
                case 'progress':
                    return <Progress />;
                case 'success':
                    return <CheckCircleFilled />;
                case 'danger':
                    return <InfoFilled />;
                default:
                    return;
            }
        };

        const createVariant = (variant) => {
            // TODO: [DS-738] Add a param to the useNotification hook where we can include a list of props to pass to the <Box> component.
            return (
                <Box data-e2e-id="toast" role="alert" {...props}>
                    <StyledIcon variant={variant} kind={kind}>
                        {CustomIcon ? <CustomIcon /> : createIconFromVariant(variant)}
                    </StyledIcon>
                    <StyledContent kind={kind} title={title}>
                        {children}
                    </StyledContent>
                    {cta ? (
                        <StyledCta variant={variant} kind={kind} onClick={onClose}>
                            {cta}
                        </StyledCta>
                    ) : (
                        <StyledCta variant={variant} kind={kind} onClick={onClose}>
                            <CloseOutlined size="sm" />
                        </StyledCta>
                    )}
                </Box>
            );
        };

        return createVariant(variant);
    },
)<NotificationProps>`
    display: flex;
    align-items: center;
    padding: ${spacing('md')} ${spacing('lg')};
    border-radius: 4px;
    height: 60px;
    box-shadow: 0 2px 4px 0 rgba(12, 27, 43, 0.06), 0 3px 4px 0 rgba(12, 27, 43, 0.06),
        0 1px 5px 0 rgba(12, 27, 43, 0.12);

    ${kindStyles};
`;
