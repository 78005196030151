import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgUserMessageOutlined = ({ size, title = 'UserMessageOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M15 19.5v-1.583c0-2.106-3.998-3.167-6-3.167-2.003 0-6 1.06-6 3.167V19.5"
        />
        <path stroke="currentColor" strokeWidth={1.5} d="M9 11.5A3.25 3.25 0 109 5a3.25 3.25 0 000 6.5z" />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M14.249 5.285A.862.862 0 0114.888 5h5.74c.48 0 .869.396.869.868v3.8c0 .48-.395.868-.87.868h-1.395s-1.905 2.193-3.245 2.416c.26-.931.458-2.416.458-2.416h-1.997"
        />
    </svg>
);

export default withIcon(SvgUserMessageOutlined);
