import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMoneyOutlined = ({ size, title = 'MoneyOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <circle cx={8.5} cy={9} r={8.5} transform="translate(3.5 3)" />
            <path d="M12 6.5v1.65m0 7.7v1.65m2.25-7.184c0-.93-1.007-1.616-2.25-1.616s-2.25.437-2.25 1.616C9.75 11.496 10.757 12 12 12m-2.25 1.684c0 .93 1.007 1.616 2.25 1.616s2.25-.42 2.25-1.616C14.25 12.487 13.243 12 12 12" />
        </g>
    </svg>
);

export default withIcon(SvgMoneyOutlined);
