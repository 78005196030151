import React from 'react';
import { ThemeProvider } from '../../utils/styled';
import { ResponsiveProvider } from '../../utils/responsive';
import { defaultTheme, DefaultTheme } from '../../themes';
import { ExperimentalProvider, Experiments } from './ExperimentalProvider';

export type ThemeProviderProps = {
    theme?: DefaultTheme;
    mode?: DefaultTheme['mode'];
    experiments?: Experiments;
};

export const MaestroThemeProvider: React.FC<ThemeProviderProps> = ({
    theme = defaultTheme,
    mode = 'dark',
    experiments,
    children,
}) => {
    return (
        <ResponsiveProvider theme={theme}>
            <ExperimentalProvider experiments={experiments}>
                <ThemeProvider theme={{ ...theme, mode }}>{children}</ThemeProvider>
            </ExperimentalProvider>
        </ResponsiveProvider>
    );
};

MaestroThemeProvider.displayName = 'Maestro.ThemeProvider';
