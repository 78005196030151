import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProductNetworkOutlined = ({ size, title = 'ProductNetworkOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.5 18v-1.333c0-1.774-3.331-2.667-5-2.667h0c-1.669 0-5 .893-5 2.667V18M21 18v-1.333C21 14.893 17.669 14 16 14c-.534 0-1.24.092-1.95.274"
            />
            <circle cx={7.5} cy={8.75} r={2.75} />
            <circle cx={16.25} cy={8.75} r={2.75} />
        </g>
    </svg>
);

export default withIcon(SvgProductNetworkOutlined);
