import { FilterOption, FindDataByCodeResult, UpdateFilterOption } from './storybook-types';

/**
 * Finds "Filter option" (name and code) in "FilterOptions" by filter option code.
 * @param filterOptions array of filter options (with name and code properties)
 * @param filterType Type of selected filter type ("Genre","Type",etc)
 * @param code selected filter code
 */
export const findDataByCode = ({ filterOptions, filterType, code }: FilterOption): FindDataByCodeResult | undefined => {
    let result: FindDataByCodeResult | undefined = undefined;
    filterOptions.forEach((option) => {
        if (option.subItems && option.subItems.length !== 0) {
            const founded = option.subItems.find((e) => e.code === code);
            if (founded) {
                result = {
                    filterType,
                    option: founded,
                };
            }
        }

        if (option.code === code) {
            result = {
                filterType,
                option: option,
            };
        }
    });

    return result;
};

/**
 * Updates "appliedFilters"
 * @param appliedFilters oldAppliedFilters from useState
 * @param setAppliedFilters setAppliedFilters from useState
 * @param result {filterType, {name,code}}
 */
export const updateAppliedFilters = ({ appliedFilters, setAppliedFilters, result }: UpdateFilterOption) => {
    const appliedFiltersKeys = Object.keys(appliedFilters);

    // If old "appliedFilters" not empty
    if (appliedFiltersKeys.length !== 0) {
        const newAppliedFilters = {};

        // result filter type already exists in old "appliedFilters" ("Genres" or "Type")
        if (appliedFiltersKeys.find((e) => e === result.filterType)) {
            appliedFiltersKeys.forEach((filterType) => {
                if (filterType === result.filterType) {
                    const presentObject = Object.keys(appliedFilters[filterType]).find((e) => e === result.option.code);
                    if (!presentObject) {
                        Object.assign(newAppliedFilters, {
                            [filterType]: {
                                ...appliedFilters[filterType],
                                [result.option.code]: {
                                    name: result.option.name || '',
                                    code: result.option.code || '',
                                },
                            },
                        });
                    }
                } else {
                    Object.assign(newAppliedFilters, {
                        [filterType]: {
                            ...appliedFilters[filterType],
                        },
                    });
                }
            });
        } else {
            Object.assign(newAppliedFilters, {
                ...appliedFilters,
                [result.filterType]: {
                    [result.option.code]: {
                        name: result.option.name,
                        code: result.option.code,
                    },
                },
            });
        }

        setAppliedFilters(newAppliedFilters);
    } else {
        setAppliedFilters({
            [result.filterType]: {
                [result.option.code]: {
                    name: result.option.name,
                    code: result.option.code,
                },
            },
        });
    }
};
