import { css } from 'styled-components';
import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { fontWeight, palette, spacing, themeMode, variant, radius, color } from '../../utils';
import { IconProps } from './../withIcon/withIcon';
import { BadgeProps, PinnedBadgeProps } from './types';

const variantKindStyles = variant({
    prop: 'variant',
    compoundProp: 'kind',
    variants: {
        default: {
            color: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
            base: {
                backgroundColor: themeMode({ light: 'neutral.200', dark: 'neutral.700' }),
            },
            subtle: {
                backgroundColor: themeMode({ light: palette('neutral.200', 0.24), dark: palette('neutral.700', 0.48) }),
            },
        },
        inverted: {
            color: themeMode({ light: 'neutral.50', dark: 'neutral.900' }),
            base: {
                backgroundColor: themeMode({ light: 'neutral.1000', dark: 'neutral.0' }),
            },
            subtle: {
                color: 'neutral.900',
                backgroundColor: themeMode({ light: palette('neutral.1000', 0.24), dark: palette('neutral.0', 0.48) }),
            },
        },
        primary: {
            base: {
                color: 'neutral.900',
                backgroundColor: 'primary.500',
            },
            subtle: {
                color: themeMode({ light: 'primary.700', dark: 'primary.300' }),
                backgroundColor: palette('primary.500', 0.24),
            },
        },
        secondary: {
            base: {
                color: 'neutral.50',
                backgroundColor: 'secondary.500',
            },
            subtle: {
                color: 'secondary.300',
                backgroundColor: palette('secondary.500', 0.24),
            },
        },
        danger: {
            base: {
                color: 'neutral.50',
                backgroundColor: 'danger.500',
            },
            subtle: {
                color: 'danger.300',
                backgroundColor: palette('danger.500', 0.24),
            },
        },
        warning: {
            base: {
                color: 'neutral.900',
                backgroundColor: 'warning.500',
            },
            subtle: {
                color: themeMode({ light: 'warning.700', dark: 'warning.300' }),
                backgroundColor: palette('warning.500', 0.24),
            },
        },
        success: {
            base: {
                color: 'neutral.50',
                backgroundColor: 'success.500',
            },
            subtle: {
                color: themeMode({ light: 'success.700', dark: 'success.300' }),
                backgroundColor: palette('success.500', 0.24),
            },
        },
        bronze: {
            base: {
                color: 'neutral.50',
                backgroundColor: 'bronze.500',
            },
            subtle: {
                color: themeMode({ light: 'bronze.700', dark: 'bronze.300' }),
                backgroundColor: palette('bronze.500', 0.24),
            },
        },
        silver: {
            base: {
                color: 'neutral.50',
                backgroundColor: 'silver.500',
            },
            subtle: {
                color: themeMode({ light: 'silver.700', dark: 'silver.300' }),
                backgroundColor: palette('silver.500', 0.24),
            },
        },
        gold: {
            base: {
                color: 'neutral.50',
                backgroundColor: 'gold.500',
            },
            subtle: {
                color: themeMode({ light: 'gold.700', dark: 'gold.300' }),
                backgroundColor: palette('gold.500', 0.24),
            },
        },
        platinum: {
            base: {
                color: 'neutral.900',
                background: `linear-gradient(
                    315deg,
                    #adb7bf 0%,
                    #dfe3e9 50%,
                    #6c7782 100%
                )`,
            },
            subtle: {
                color: themeMode({ light: 'platinum.600', dark: 'platinum.50' }),
                background: `linear-gradient(
                    315deg,
                    #adb7bf66 0%,
                    #dfe3e966 50%,
                    #6c778266 100%
                )`,
            },
        },
    },
});

const sizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            textTransform: 'uppercase',
            height: '16px',
        },
        md: {
            height: '24px',
        },
    },
});

const contentSizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            py: '0',
            px: 'xs',
            fontSize: 'xs',
            /* Adjust line-height to match wrapper size */
            lineHeight: '16px',

            svg: {
                marginRight: 'xxs',
            },
        },
        md: {
            py: '0',
            px: 'xs',
            fontSize: 'sm',
            lineHeight: 1,

            svg: {
                marginRight: 'xxs',
            },
        },
    },
});

export const Badge = styled(Box)<BadgeProps & PinnedBadgeProps>`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    min-width: auto;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    font-weight: ${fontWeight('bold')};
    border-radius: ${({ isRounded }) => (isRounded ? radius('round') : radius('base'))};
    cursor: ${({ onClick }): string => (onClick ? 'pointer' : 'inherit')};

    ${variantKindStyles};
    ${sizeStyles};

    ${({ isPinned }) =>
        isPinned &&
        css`
            box-shadow: 0px 0px 0px 2px ${color('background.base')};
        `};
`;

export const Icon = styled(Box)<IconProps>``;

export const Content = styled(Box).attrs(() => ({ as: 'span' }))<{ size: BadgeProps['size'] }>`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    ${contentSizeStyles};
`;

export const Dot = styled(Box)<BadgeProps & PinnedBadgeProps>`
    width: ${spacing('sm')};
    height: ${spacing('sm')};

    ${variantKindStyles};
    border-radius: ${radius('round')};

    ${({ isPinned }) =>
        isPinned &&
        css`
            box-shadow: 0px 0px 0px 2px ${color('background.base')};
        `};
`;
