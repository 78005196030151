import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgNoteOutlined = ({ size, title = 'NoteOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <g
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                transform="translate(6 4)"
            >
                <path d="M5.844 1.719s5.242 0 6.875 4.812c-2.521-1.49-6.875-1.375-6.875-1.375M5.84.34v12.38" />
                <circle cx={3.09} cy={12.72} r={2.75} />
            </g>
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);

export default withIcon(SvgNoteOutlined);
