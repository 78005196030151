import { rgba } from 'polished';
import css, { CSSObject } from '@styled-system/css';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { isFunction } from '../utils/assertions';
import { Spacing, SpacingOptions } from '../themes/types';
import { Borders, ZIndex, Breakpoint } from '../types/tokens';

// Native get function - get value in an object depending on the path
export const get = (
    obj: Record<string, any>,
    path: string,
    defaultValue: any = null,
): string | ((props: Record<any, any>) => string) => {
    const result = String.prototype.split
        .call(path, /[,[\].]+?/)
        .filter(Boolean)
        .reduce((res: [], key: string) => (res !== null && res !== undefined ? res[key] : res), obj);
    return result === undefined || result === obj ? defaultValue : result;
};

// If value is a function, run it before returning the value
const themeGet = (props: Record<string, any>, path: string): string => {
    const value = get(props.theme, path);
    return isFunction(value) ? value(props) : value;
};

export const stripUnit = (str: string): number => parseFloat(str);

// Get any tokens in the theme - to be used if no other function match the case
export const token =
    (path: string) =>
    (props): string =>
        themeGet(props, path);

export const color =
    (path: string, opacity = 1) =>
    (props): string =>
        rgba(themeGet(props, `colors.${path}`), opacity);

export const opacity =
    (path: string) =>
    (props): string =>
        themeGet(props, `opacities.${path}`);

export const palette = color;

export const baseUnit =
    (multiplier: number) =>
    ({ theme }): string =>
        theme.baseUnit(multiplier);

export const spacing =
    (size: Spacing, options?: SpacingOptions) =>
    ({ theme }): string | number =>
        theme.spacing(size, options);

export const fontSize =
    (size: string) =>
    (props): string =>
        themeGet(props, `fontSizes.${size}`);

export const lineHeight =
    (size: string) =>
    (props): string =>
        themeGet(props, `lineHeights.${size}`);

export const fontWeight =
    (weight: string) =>
    (props): string =>
        themeGet(props, `fontWeights.${weight}`);

export const radius =
    (radius: string) =>
    (props): string =>
        themeGet(props, `radii.${radius}`);

export const shadow =
    (shadow: string) =>
    (props): string =>
        themeGet(props, `shadows.${shadow}`);

export const component =
    (path: string): any =>
    (props): any =>
        themeGet(props, `components.${path}`);

export const border =
    (type: Borders) =>
    (props): string =>
        themeGet(props, `borders.${type}`);

export const zIndex =
    (zIndex: ZIndex) =>
    (props): number => {
        return Number(themeGet(props, `zIndices.${zIndex}`));
    };

export const breakpoint =
    (breakpoint: Breakpoint) =>
    ({ theme }): string =>
        theme.breakpoint(breakpoint);

const getThemeModeValue = (props: { theme: { mode: any } }, values: Record<string, any>): string => {
    const mode = props && props.theme && props.theme.mode;
    const themeValue = typeof mode === 'function' ? mode(values) : values[mode];

    return typeof themeValue === 'function' ? themeValue(props) : themeValue;
};

export const themeMode =
    (values: Record<string, any>) =>
    (props: any): string =>
        getThemeModeValue(props, values);

export interface VariantConfig {
    prop: string;
    compoundProp?: string;
    variants: Record<string, Record<string, any>>;
}

export interface Props {
    theme: Record<string, any>;
}

/**
 * Recursive function to parse a style object to be handle by the css function from styled-system
 * If the value is a function, e.g. themeMode(), the function is executed
 * @param styles
 * @returns a CSSobject with only string as values
 */
const parseStyles =
    (styles) =>
    (props: Props): CSSObject | undefined => {
        if (!styles) {
            return;
        }
        return Object.entries(styles).reduce((acc, [key, value]) => {
            if (typeof value === 'object') {
                // Check if the key exists as a prop and if it's a boolean
                if (key in props && typeof props[key] === 'boolean' && !!props[key]) {
                    acc = { ...acc, ...parseStyles(value)(props) };
                    return acc;
                }

                acc[key] = parseStyles(value)(props);
                return acc;
            }
            acc[key] = typeof value === 'function' ? value(props) : value;
            return acc;
        }, {});
    };

/**
 * Helper function that receives variant styles, executes style functions as needed, and extracts style values from the theme
 * @param props
 * @param variantValues
 * @param theme
 * @returns a CSSObject
 */
const extractStyles = (props: Props, variantValues, theme): CSSObject => {
    const variantStyles = typeof variantValues === 'function' ? variantValues(props) : variantValues;

    const flattenedValues = parseStyles(variantStyles)(props);

    return css(flattenedValues)(theme);
};

/**
 * Get style variations based on the prop value.
 * @param config Accepts an object with the name of the prop and the variants
 * @returns a CSSObject
 * ```
 * const componentVariants = variant({
 *     prop: 'size',
 *     variants: {
 *         sm: {
 *             padding: 'sm',
 *         },
 *         lg: {
 *             padding: 'lg',
 *         }
 *     }
 * })
 *
 * const Component = styled(Box)(componentVariants);
 * ```
 */

// Since the styled-system function doesn't execute function inside the theme we need to do it manually
//  TODO: [DS-447] add own implementation of css function

export const variant =
    ({ prop, compoundProp, variants }: VariantConfig) =>
    (props: Props): Record<any, any> => {
        const { theme } = props;
        let compiledStyles = {};

        const propValue = props[prop];
        const variantValues = variants?.[propValue];

        const extractedStyles = extractStyles(props, variantValues, theme);

        compiledStyles = { ...compiledStyles, ...extractedStyles };

        if (compoundProp) {
            const compoundPropValue = props[compoundProp];
            const variantValues = variants?.[propValue]?.[compoundPropValue];

            const extractedStyles = extractStyles(props, variantValues, theme);

            compiledStyles = { ...compiledStyles, ...extractedStyles };
        }

        const finalStyles = Object.entries(compiledStyles).reduce((acc, [key, value]) => {
            const x = isFunction(value) ? value(props) : value;
            acc[key] = x;

            return acc;
        }, {});

        const finalCompileStyles = css(finalStyles)(theme);

        return finalCompileStyles;
    };

export const useStyles = ({ styles, props }) => {
    const themeContext = useContext(ThemeContext);
    return styles({ theme: themeContext, ...props });
};
