import { FC } from 'react';
import { Position } from 'components/Common';
import { BasicImage } from 'ui';
import { Button } from '@landr/maestro';

import styles from './Advertorial.module.scss';
import { determineTextColor } from 'utils';

export interface AdvertorialProps {
  title: string;
  subtitle: string;
  description: string;
  linkLabel: string;
  link: string;
  background: string;
  ctaColor: string;
  textColor?: string;
  image: string;
}

export const Advertorial: FC<AdvertorialProps> = ({
  title,
  subtitle,
  description,
  linkLabel,
  link,
  background,
  ctaColor,
  textColor,
  image,
}) => {
  const Subtitle = () => {
    if (!subtitle) {
      return null;
    }

    return (
      <>
        <br />
        <p
          className={styles.subtitle}
          style={{ color: textColor }}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></p>
      </>
    );
  };

  return (
    <Position position="inline">
      <a className={styles.link} href={link} target="_blank" rel="noreferrer">
        <div className={styles.root} style={{ background, color: textColor }}>
          <div className={styles.body}>
            <span
              className={styles.title}
              style={{ color: textColor }}
              dangerouslySetInnerHTML={{ __html: title }}
            ></span>
            <Subtitle />
            <p
              className={styles.description}
              style={{ color: textColor }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            <div className={styles.cta}>
              <Button
                as="span"
                size="sm"
                variant="subtle"
                sx={{
                  px: 'xl',
                  backgroundColor: ctaColor,
                  color: determineTextColor(ctaColor),
                  '&:hover': { backgroundColor: ctaColor },
                }}
              >
                {linkLabel}
              </Button>
            </div>
          </div>
          <div className={styles.imageBg}>
            <BasicImage src={image} alt={title} />
          </div>
        </div>
      </a>
    </Position>
  );
};
