import { FC } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { CATEGORY } from 'constants/routes';
import { FeatureWrapper } from 'components/Common';
import { Label, BasicImage, YouTubePlayer, MappedPost } from 'ui';
import styles from './WideLesson.module.scss';
interface WideLessonProps {
  post: MappedPost;
  isTransparent?: boolean;
}
export const WideLesson: FC<WideLessonProps> = ({ post, isTransparent }) => {
  const { title, slug, categorySlug, categoryName, accentColor, image, video } =
    post;
  const accentStyle = accentColor ? { backgroundColor: accentColor } : {};

  return (
    <FeatureWrapper>
      <Link href={`/${slug}`} className={styles.root} style={accentStyle}>
        <div className={styles.details}>
          <Label
            label={categoryName}
            href={`${CATEGORY}/${categorySlug}`}
            accentColour="white"
            inlineAccentColour={accentColor}
            className={styles.label}
          />
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
        </div>
        <div
          className={cn(styles.mediaRoot, {
            [styles.fullSize]: isTransparent,
          })}
        >
          <div className={styles.mediaWrapper}>
            <div className={styles.mediaItem}>
              {video && <YouTubePlayer image={image} link={video} />}
              {!video && image && <BasicImage src={image} alt={title} />}
            </div>
          </div>
        </div>
      </Link>
    </FeatureWrapper>
  );
};
