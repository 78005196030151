/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';

/**
 * The useUpdateEffect is a wrapper around useEffect but only calls the effect when the component
 * updates, but not when first mounted.
 */

export const useUpdateEffect: typeof useEffect = (effect, deps) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) {
            effect();
        } else {
            mounted.current = true;
        }
    }, deps);
};
