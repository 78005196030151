import React, { PropsWithChildren, forwardRef } from 'react';
import { LinkProps } from './types';
import { Styled } from './styled';

type Props = LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const Link: React.FC<Props> = forwardRef(
    (
        { size = 'md', as = 'a', children, ...props }: PropsWithChildren<LinkProps>,
        ref: React.RefObject<HTMLElement>,
    ) => (
        <Styled.Link size={size} as={as} ref={ref} {...props}>
            {children}
        </Styled.Link>
    ),
);

Link.displayName = 'Link';
