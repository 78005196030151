import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPianoOutlined = ({ size, title = 'PianoOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path stroke="currentColor" d="M4.5 7A1.5 1.5 0 016 5.5h3.5v14H6A1.5 1.5 0 014.5 18V7z" />
        <path stroke="currentColor" d="M4.5 7A1.5 1.5 0 016 5.5h3.5v13H6A1.5 1.5 0 014.5 17V7zm5-1.5h5v14h-5z" />
        <path stroke="currentColor" d="M9.5 5.5h5v13h-5zm5 0H18A1.5 1.5 0 0119.5 7v11a1.5 1.5 0 01-1.5 1.5h-3.5v-14z" />
        <path stroke="currentColor" d="M14.5 5.5H18A1.5 1.5 0 0119.5 7v10a1.5 1.5 0 01-1.5 1.5h-3.5v-13z" />
        <rect width={3} height={7} x={8} y={5} fill="currentColor" rx={1.5} />
        <rect width={3} height={7} x={13} y={5} fill="currentColor" rx={1.5} />
    </svg>
);

export default withIcon(SvgPianoOutlined);
