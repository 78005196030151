import styled from 'styled-components';
import { Box } from '../../primitives/Box';
import { spacing } from '../../utils';

export const MOBILE_BREAKPOINT = 'md';

export const Wrapper = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    padding: ${spacing('xxl')} 0;
`;

export const Name = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: ${spacing('xxl')};
    padding: ${spacing('lg')} 0;
`;

export const Content = styled(Box)`
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;

    ${({ theme }) => theme.breakpoint('md')`
        justify-content: flex-end;
    `}
`;

export const Nav = styled(Box)`
    flex: 1 1 auto;
    min-width: 0;
`;

export const Addon = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    padding-left: ${spacing('xl')};
`;
