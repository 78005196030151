import React, { FC } from 'react';
import { useBreakpoint, useDisclosure } from '../../hooks';
import { Drawer } from '../Drawer';
import { Button } from '../Button';
import { Box } from '../../primitives/Box';
import { runIfFn } from '../../utils';
import { ProductHeaderCollapseProps } from './types';
import { MOBILE_BREAKPOINT } from './styled';
import * as Styled from './styled';

export const ProductHeaderCollapse: FC<ProductHeaderCollapseProps> = (props) => {
    const { children, triggerLabel, ...rest } = props;
    const isMobile = useBreakpoint(MOBILE_BREAKPOINT);
    const { isOpen, onOpen, onClose } = useDisclosure();

    return isMobile ? (
        <Styled.Content>
            <Button variant="ghost" kind="link" onClick={onOpen} isDropdown>
                {triggerLabel}
            </Button>
            <Drawer isVisible={isOpen} onClose={onClose} {...rest}>
                <Box py="xs">{runIfFn(children, { isOpen, onClose })}</Box>
            </Drawer>
        </Styled.Content>
    ) : (
        <Styled.Content>{runIfFn(children, { isOpen, onClose })}</Styled.Content>
    );
};
ProductHeaderCollapse.displayName = 'ProductHeader.Collapse';
