import React, { useState, useEffect, useMemo, useCallback, ComponentPropsWithoutRef } from 'react';
import Downshift, { GetInputPropsOptions } from 'downshift';
import { JSXElementConstructorWithDisplayName, themeMode, runIfFn, palette, shadow, warn } from '../../utils';
import { Box } from '../../primitives/Box';
import { List } from '../List';
import styled from '../../utils/styled';
import { AutocompleteProps, AutocompleteItemDefaultComponent } from './types';
import { Styled } from './styled';

const defaultGetItemsLabel = (item) => `${item}`;

const DefaultComponent: React.FC<AutocompleteItemDefaultComponent> = ({
    getItemsLabel = defaultGetItemsLabel,
    children,
}) => <>{getItemsLabel(children)}</>;

const DefaultNoResultsComponent: React.FC<AutocompleteItemDefaultComponent> = ({ children }) => (
    <Box p="md">{children}</Box>
);

type Props = AutocompleteProps & Omit<ComponentPropsWithoutRef<'input'>, keyof AutocompleteProps>;

const Popover = styled(Box)`
    background-color: ${themeMode({
        light: palette('neutral.0'),
        dark: palette('neutral.800'),
    })};
    box-shadow: ${shadow('3')};
    overflow-y: auto;
    width: 100%;
    margin: 0;
    border-top: 0;
    position: absolute;
    z-index: 1000;
    list-style: none;
    padding: 0;
    left: 0;
    border-radius: 0;
`;

const getGroups = (groupBy: Props['groupBy'], items: Props['items']) =>
    groupBy ? Array.from(new Set(items.map((item) => groupBy(item)))) : [''];

const getItemFromItemWithIndex = (item) => item.item;

const getItemsByGroup = (groupBy: Props['groupBy'], groups: (string | number | undefined)[], items: Props['items']) =>
    groups.map((group) => ({
        group,
        items: groupBy ? items.filter((item) => groupBy(getItemFromItemWithIndex(item)) === group) : items,
    }));

export const Autocomplete: React.FC<Props> = ({
    children,
    id,
    items = [],
    onChange,
    initialSelectedItem = [],
    initialInputValue = '',
    singleValueComponent,
    itemComponent: ItemComponent = DefaultComponent,
    noResultsComponent: NoResultsComponent = DefaultNoResultsComponent,
    staticBottomComponent,
    openOnFocus = false,
    groupBy,
    getItemsLabel = defaultGetItemsLabel,
    downshiftProps,
    menuProps,
    ...props
}) => {
    warn({
        condition: true,
        message: 'The Autocomplete component will be deprecated in a future version. Use ComboBox or MultiSelect.',
    });

    const groups = useMemo(() => getGroups(groupBy, items), [items, groupBy]);
    const itemsWithIndexes = useMemo(
        () =>
            items.map((item, index) => ({
                item,
                index: groups.length + index,
            })),
        [items, groups.length],
    );

    const itemsByGroup = useMemo(
        () => getItemsByGroup(groupBy, groups, itemsWithIndexes),
        [groupBy, groups, itemsWithIndexes],
    );

    const [inputItems, setInputItems] = useState(itemsByGroup);
    const [selectedItems, setSelectedItems] = useState<string | string[]>(initialSelectedItem);
    const [inputValue, setInputValue] = useState<string>(initialInputValue);
    const [isSingleInput, setIsSingleInput] = useState<boolean>();
    const [isSingleValueComponentVisible, setIsSingleValueComponentVisible] = useState(false);

    const filterItems = useCallback(
        (inputValue): void => {
            setInputItems(() =>
                itemsByGroup.reduce((items, groupData) => {
                    const filteredItems = groupData.items.filter(
                        (i) =>
                            typeof getItemsLabel(getItemFromItemWithIndex(i)) === 'string' &&
                            getItemsLabel(getItemFromItemWithIndex(i)).toLowerCase().includes(inputValue.toLowerCase()),
                    );
                    if (filteredItems.length !== 0) {
                        return [
                            ...items,
                            {
                                group: groupData.group,
                                items: filteredItems,
                            },
                        ];
                    }

                    return items;
                }, []),
            );
        },
        [getItemsLabel, itemsByGroup],
    );

    useEffect(() => {
        filterItems(inputValue);
    }, [filterItems, inputValue, itemsByGroup]);

    useEffect(() => {
        if (initialSelectedItem.length !== 0 || initialInputValue || singleValueComponent) {
            setIsSingleValueComponentVisible(true);
        }
    }, [initialSelectedItem, initialInputValue, singleValueComponent]);

    useEffect(() => {
        if (onChange) {
            onChange(selectedItems);
        }
    }, [onChange, selectedItems]);

    useEffect(() => {
        const child = React.Children.only(children);

        if (!React.isValidElement(child)) {
            return;
        }

        const { displayName } = child.type as JSXElementConstructorWithDisplayName;

        if (displayName === 'Input') {
            setIsSingleInput(true);
        } else if (displayName === 'ChipInput') {
            setIsSingleInput(false);
        }
    }, [children]);

    const addSelectedItem = (item: string): void => {
        if (!item) {
            return;
        }

        if (isSingleInput) {
            setSelectedItems(item);
        } else {
            setSelectedItems([...selectedItems, item]);
            setInputValue('');
        }
    };

    const removeItem = (item: string): void => {
        if (!Array.isArray(selectedItems)) {
            return;
        }
        setSelectedItems(() => selectedItems.filter((i) => i !== item));
        setInputValue('');
    };

    const handleChange = (selectedItem): void => {
        addSelectedItem(selectedItem);
        if (singleValueComponent) {
            setIsSingleValueComponentVisible(true);
        }
    };

    const handleInputValueChange = (inputValue = ''): void => {
        setInputValue(inputValue);
        filterItems(inputValue);
    };

    const renderInputChild = (inputProps: GetInputPropsOptions): JSX.Element => {
        const child = React.Children.only(children);

        if (!React.isValidElement(child)) {
            return <></>;
        }

        const handleOnRemove = (item: string): void => {
            removeItem(item);
            child.props.onRemove?.(item);
        };

        const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
            if (singleValueComponent) {
                setIsSingleValueComponentVisible(false);
            }
            if (openOnFocus) {
                inputProps.onFocus?.(e);
            }
            child.props.onFocus?.(e);
        };

        const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            if (inputProps.value && singleValueComponent) {
                setIsSingleValueComponentVisible(true);
            }
            inputProps.onBlur?.(e);
        };

        const handleInputRef = (node: HTMLInputElement | null) => {
            if (!node) {
                return;
            }
            if (!!inputValue && singleValueComponent && isSingleValueComponentVisible) {
                node.style.opacity = '0';
            } else {
                node.style.opacity = '1';
            }
        };

        const { displayName } = child.type as JSXElementConstructorWithDisplayName;

        if (displayName === 'Input') {
            return (
                <>
                    {!!inputProps.value && isSingleValueComponentVisible && (
                        <Styled.SingleValueComponentContainer>
                            {runIfFn(singleValueComponent, { value: inputProps.value })}
                        </Styled.SingleValueComponentContainer>
                    )}
                    {React.cloneElement(child, {
                        ...child.props,
                        ...inputProps,
                        ref: handleInputRef,
                        onFocus: handleOnFocus,
                        onBlur: handleOnBlur,
                    })}
                </>
            );
        }

        if (displayName === 'ChipInput') {
            return React.cloneElement(child, {
                getItemsLabel,
                ...child.props,
                ...inputProps,
                onFocus: handleOnFocus,
                ...(!downshiftProps?.selectedItem ? { values: selectedItems } : {}),
                onRemove: handleOnRemove,
            });
        }

        return <></>;
    };

    return (
        <Downshift
            onChange={handleChange}
            onInputValueChange={handleInputValueChange}
            initialSelectedItem={initialSelectedItem}
            initialInputValue={initialInputValue}
            inputValue={inputValue}
            inputId={id}
            itemToString={getItemsLabel}
            {...downshiftProps}
        >
            {({
                isOpen,
                highlightedIndex,
                getRootProps,
                getMenuProps,
                getItemProps,
                getInputProps,
                openMenu,
                closeMenu,
            }): JSX.Element => (
                <Styled.Autocomplete {...getRootProps()} {...props}>
                    {renderInputChild({ ...getInputProps() })}
                    <Popover {...getMenuProps()}>
                        {isOpen && (
                            <>
                                <Box maxHeight={menuProps?.maxHeight || 180} overflow="auto">
                                    {inputItems.length === 0 ? (
                                        <NoResultsComponent>No results match this query</NoResultsComponent>
                                    ) : (
                                        <List>
                                            {inputItems.map((groupData: any, index) => (
                                                <React.Fragment key={index}>
                                                    {groupData.group && (
                                                        <List.Subheader
                                                            {...getItemProps({
                                                                item: null,
                                                                index,
                                                            })}
                                                        >
                                                            {groupData.group}
                                                        </List.Subheader>
                                                    )}
                                                    {groupData.items.map((item) => (
                                                        <List.Item
                                                            key={item.index}
                                                            isSelected={item.index === highlightedIndex}
                                                            {...getItemProps({
                                                                item: getItemFromItemWithIndex(item),
                                                                index: item.index,
                                                            })}
                                                        >
                                                            <ItemComponent getItemsLabel={getItemsLabel}>
                                                                {getItemFromItemWithIndex(item)}
                                                            </ItemComponent>
                                                        </List.Item>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    )}
                                </Box>

                                {staticBottomComponent && (
                                    <div
                                        {...getItemProps({
                                            item: null,
                                            index: -1,
                                        })}
                                    >
                                        <Styled.Divider />
                                        {runIfFn(staticBottomComponent, {
                                            inputValue,
                                            closeMenu,
                                        })}
                                    </div>
                                )}
                            </>
                        )}
                    </Popover>
                </Styled.Autocomplete>
            )}
        </Downshift>
    );
};

Autocomplete.displayName = 'Autocomplete';
