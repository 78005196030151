import React from 'react';
import {
  Box,
  Carousel,
  css,
  spacing,
  styled,
  useBreakpoint,
} from '@landr/maestro';

export const StyledCarouselContainer = styled(Box)<{
  isMobile: boolean;
}>`
  margin-top: ${spacing('lg')};

  ${({ isMobile }) => {
    return (
      isMobile &&
      css`
        margin-left: -${spacing('xxl')};
      `
    );
  }};

  // This overrides global "span". This can be removed if all with be written using only maestro
  span {
    display: flex;
  }
`;

StyledCarouselContainer.displayName = 'StyledCarouselContainer';

export const CarouselContainer: React.FC<{ items: React.ReactElement[] }> = ({
  items,
}) => {
  const isMobile = useBreakpoint('md');

  return (
    <StyledCarouselContainer isMobile={isMobile}>
      <Carousel
        items={items}
        lazyloading={false}
        slidesPerPage={{
          xs: 1,
          sm: 1,
          ms: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
          default: 3,
        }}
        itemsOffset={isMobile ? 'xxl' : 'none'}
        gutter="xl"
      />
    </StyledCarouselContainer>
  );
};
