import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVideoRecordingOutlined = ({ size, title = 'VideoRecordingOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M16.25 7.26a.637.637 0 00-.136-.408c-.075-.09-.14-.102-.166-.102H4.052c-.03 0-.092.013-.167.103a.645.645 0 00-.135.407v9.48c0 .179.062.321.135.409.073.086.137.101.17.101H15.94c.034 0 .1-.015.174-.105a.638.638 0 00.136-.405V7.26zm1.5 1.78V7.26c0-.983-.701-2.01-1.802-2.01H4.052c-1.12 0-1.802 1.05-1.802 2.01v9.48c0 .983.695 2.01 1.806 2.01H15.94c1.12 0 1.81-1.044 1.81-2.01v-1.78l3.068.768A.75.75 0 0021.75 15V9a.75.75 0 00-.932-.728l-3.068.767zm0 1.546v2.828l2.5.625V9.961l-2.5.625zM10.75 9a.75.75 0 00-1.5 0v2.333H7a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.167H13a.75.75 0 000-1.5h-2.25V9z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgVideoRecordingOutlined);
