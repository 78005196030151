import { maestroComponent } from '../../utils';
import { NotificationProps } from './types';
import { StyledNotification } from './styled';

export const Notification = maestroComponent<NotificationProps>('Notification', StyledNotification);

Notification.defaultProps = {
    variant: 'success',
    kind: 'temporary',
};
