import React from 'react';
import { Input } from '../Input';
import { CaretDownOutlined } from '../../assets/icons';
import { forwardRef } from '../../system';
import { SelectNativeProps } from './types';
import * as Styled from './styled';

export const SelectNative = forwardRef<SelectNativeProps, 'select'>((props, ref) => {
    const { options, placeholder, defaultValue = '', ...rest } = props;
    return (
        <Input
            as="select"
            ref={ref}
            addonRight={
                <Styled.SelectCaret>
                    <CaretDownOutlined />
                </Styled.SelectCaret>
            }
            defaultValue={defaultValue}
            {...rest}
        >
            {placeholder && (
                <option key="select-placeholder" value="" disabled>
                    {placeholder}
                </option>
            )}
            {options.map((option, index) => (
                <option key={`select-option-${index}`} value={option.value} disabled={option.disabled}>
                    {option.text}
                </option>
            ))}
        </Input>
    );
});

SelectNative.displayName = 'SelectNative';
