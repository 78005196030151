import React from 'react';
import { createGlobalStyle, css } from '../../utils/styled';
import { color, fontWeight, useEnhancedEffect } from '../../utils';

const GlobalStyleCSS = css`
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    :root {
        /* This variable is used by old webapp to adapt its layout */
        --navbar-height: 64px;
    }

    :focus:not(:focus-visible) {
        outline: 0;
    }

    html {
        /* stylelint-disable */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        font-size: ${({ theme }) => theme.baseFontSize};
        box-sizing: border-box;
    }

    body {
        color: ${color('text.base')};
        background-color: ${color('background.base')};
        font-family: ${({ theme }) => theme.fontFamily.base};
        line-height: ${({ theme }) => theme.lineHeights.base};
        font-size: ${({ theme }) => theme.baseFontSize};
        font-weight: ${fontWeight('regular')};
    }

    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    figure {
        margin: 0;
        padding: 0;
    }

    /* Remove margin and padding */
    ul,
    ol,
    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    /* Inherit typography settings */
    input,
    textarea,
    select,
    button {
        font: inherit;
        letter-spacing: inherit;
    }

    a {
        color: ${color('text.base')};
    }

    /* Remove extra styles for button */
    button {
        color: inherit;
        border-radius: 0;
        border: none;
        background-color: transparent;
    }

    /* For JS interaction - Prevent the child of a button to be targeted */
    button * {
        pointer-events: none;
    }

    /* Default table styles */
    table {
        width: 100%;
    }

    [data-color-mode='light'] {
        color: ${color('neutral.900')};
        background-color: ${color('neutral.0')};

        a {
            color: ${color('neutral.900')};
        }
    }

    [data-color-mode='dark'] {
        color: ${color('neutral.50')};
        background-color: ${color('neutral.1000')};

        a {
            color: ${color('neutral.50')};
        }
    }
`;

const InternalGlobalStyle = createGlobalStyle`
    ${GlobalStyleCSS};
`;

const insertCssImports = (): void => {
    if (document.getElementById('global-css-imports')) {
        return;
    }
    const head = document.head || document.getElementsByTagName('head')[0];
    const styleElement = document.createElement('style');
    styleElement.id = 'global-css-imports';

    head.insertBefore(styleElement, head.firstChild);

    styleElement.appendChild(
        document.createTextNode(`
            @import 'https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css';
            @import 'https://cdn.landr.com/fonts/Sailec/style.css';\n`),
    );
};

export const GlobalStyle: React.FC = () => {
    useEnhancedEffect(insertCssImports, []);
    return <InternalGlobalStyle />;
};
