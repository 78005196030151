import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgReplaceOutlined = ({ size, title = 'ReplaceOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M5.75 15.5h12.5m-3-10l3 3-3 3m-6.5 7l-3-3 3-3m-3-4h12.5"
        />
    </svg>
);

export default withIcon(SvgReplaceOutlined);
