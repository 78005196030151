import { IframeHTMLAttributes } from 'react';

export type IframeProps = IframeHTMLAttributes<HTMLIFrameElement>;

export const IframeComponent: React.FC<IframeProps> = ({
  src,
  width,
  height,
  allow,
  allowFullScreen,
  style,
}) => {
  const finalStyle = { display: 'block', margin: '10px auto', ...style };
  return (
    <iframe
      src={src}
      width={width ?? '100%'}
      height={height ?? '100%'}
      frameBorder="0"
      style={finalStyle}
      allow={allow ?? 'autoplay; encrypted-media'}
      allowFullScreen={allowFullScreen}
      loading="lazy"
    />
  );
};
