import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSessionsDark = ({ size, title = 'SessionsDark', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#435261"
            d="M32.665 9.83l-9 4.5a.75.75 0 00-.415.67v6c0 .284.16.544.415.67l9 4.5a.75.75 0 00.335.08V9.75a.75.75 0 00-.335.08z"
        />
        <path
            fill="#142739"
            d="M23.25 29.25h-21a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h21a1.5 1.5 0 011.5 1.5v19.5a1.5 1.5 0 01-1.5 1.5z"
        />
        <path
            fill="#EA5B70"
            d="M5.25 12.75c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z"
        />
        <path fill="#435261" d="M21 12h-3a.75.75 0 110-1.5h3a.75.75 0 110 1.5z" />
        <path fill="#142739" d="M34.5 9.75H33v16.5h1.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75z" />
        <path
            fill="#435261"
            d="M21 15.75H4.5a.75.75 0 00-.75.75v9.75c0 .414.336.75.75.75H21a.75.75 0 00.75-.75V16.5a.75.75 0 00-.75-.75z"
        />
    </svg>
);

export default withIcon(SvgSessionsDark);
