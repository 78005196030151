import styled from 'styled-components';
import { spacing, radius, variant, themeMode, palette } from '../../utils';
import { Box, BoxProps } from '../../primitives/Box';
import { InfoFilled as InfoFilledIcon } from '../../assets/icons';
import { Text, TextProps } from '../Text';
import { Checkbox } from '../Checkbox';
import { ButtonGroupProps } from '../ButtonGroup/types';
import { ButtonGroup } from '../ButtonGroup';
import { AssetCardProps } from './types';

const assetCardSizes = variant({
    prop: 'size',
    variants: {
        sm: {
            height: '48px',
        },
        md: {
            height: '64px',
        },
        lg: {
            height: '80px',
        },
    },
});

const assetCardVariants = variant({
    prop: 'variant',
    variants: {
        outline: {
            backgroundColor: 'transparent',
            borderColor: themeMode({
                dark: palette('neutral.700'),
                light: palette('neutral.200'),
            }),
        },
        solid: {
            backgroundColor: themeMode({
                dark: palette('neutral.800'),
                light: palette('neutral.100'),
            }),
        },
        filled: {
            backgroundColor: themeMode({
                light: palette('neutral.50'),
                dark: palette('neutral.900'),
            }),
            '&:hover': {
                backgroundColor: themeMode({
                    light: palette('neutral.100'),
                    dark: palette('neutral.800'),
                }),
            },
        },
        ghost: {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: themeMode({
                    light: palette('neutral.100'),
                    dark: palette('neutral.800'),
                }),
            },
        },
    },
});

export const Card = styled(Box)<Partial<AssetCardProps>>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid transparent;
    border-radius: ${radius('base')};
    border-color: ${({ error }) => (error ? palette('danger.500') : 'transparent')};
    color: ${({ isDisabled }) =>
        isDisabled
            ? palette('neutral.400')
            : themeMode({
                  light: `${palette('neutral.900')}`,
              })};
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
    padding-left: ${spacing('xs')};
    padding-right: ${spacing('md')};
    padding-top: ${spacing('sm')};
    padding-bottom: ${spacing('sm')};
    ${assetCardSizes};
    ${assetCardVariants};
`;

export const InfoIcon = styled(InfoFilledIcon)<{ error: string }>`
    margin: 0 ${spacing('sm')};
    color: ${({ error }) => error && palette('danger.500')};
`;

export const Media = styled(Box)<BoxProps>`
    width: 36px;
    display: flex;
    justify-content: center;
    margin-left: ${spacing('xs')};
`;

Media.displayName = 'AssetCard.Media';

export const Title = styled(Text)<TextProps>``;

Title.displayName = 'AssetCard.Title';

Title.defaultProps = {
    nbOfLines: 1,
};

export const Description = styled(Text).attrs({ contrast: 'subtle', size: 'sm' })<TextProps>``;

Description.displayName = 'AssetCard.Description';

export const Content = styled(Box)<BoxProps>`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 480px;
    overflow: hidden;
    margin-right: auto;
    margin-left: ${spacing('xs')};
`;

Content.displayName = 'AssetCard.Content';

export const Actions = styled(ButtonGroup).attrs({ spacing: 'xs' })<ButtonGroupProps>``;

Actions.displayName = 'AssetCard.Actions';

export const Container = styled(Box)<BoxProps>`
    display: flex;
`;

export const LeftAction = styled(Box)``;

LeftAction.displayName = 'AssetCard.LeftAction';

export const StyledCheckbox = styled(Checkbox)`
    padding: 0;
`;
