import styled from '../../../utils/styled';
import { Group } from '../../../primitives/Group';
import { FiltersOutlined } from '../../../assets/icons';
import { Drawer } from '../../Drawer';
import { palette } from '../../../utils';

export const StyledFilters = styled(Group)`
    line-height: 1;
    color: ${palette('neutral.400')};
`;

export const FiltersIcon = styled(FiltersOutlined)`
    color: ${palette('neutral.400')};
`;

export const StyledDrawerFooter = styled(Drawer.Footer)`
    border-top: solid 1px ${palette('neutral.400')};
    display: flex;
    justify-content: space-between;
`;
