import { FC } from 'react';
import Link from 'next/link';
import { CategoryNodeFragment } from 'types/generated/graphql';
import { CATEGORY } from 'constants/routes';
import { BasicImage } from 'ui';
import styles from './SubjectCard.module.scss';
interface SubjectCardProps
  extends Pick<CategoryNodeFragment, 'slug' | 'name' | 'categoryImage'> {}
export const SubjectCard: FC<SubjectCardProps> = ({
  slug,
  name,
  categoryImage,
}) => {
  const description = categoryImage?.categoryDescription;
  const image =
    categoryImage?.categoryImage?.mediaItemUrl ||
    '/temporary/category-example-1.png';

  return (
    <Link href={`${CATEGORY}/${slug}`} className={styles.root}>
      <div className={styles.imageWrapper}>
        <BasicImage src={image} alt={name || ''} objectFit="contain" />
      </div>
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: name || '' }}
      ></p>
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description || '' }}
      ></p>
    </Link>
  );
};
