import styled from '../../utils/styled';
import { Box } from '../../primitives';
import { variant } from '../../utils';
import { NotificationBarProps } from './types';

const variantStyles = variant({
    prop: 'variant',
    variants: {
        informational: {
            color: 'neutral.0',
            backgroundColor: 'secondary.500',
        },
        success: {
            color: 'neutral.900',
            backgroundColor: 'primary.500',
        },
        warning: {
            color: 'neutral.1000',
            backgroundColor: 'warning.500',
        },
        error: {
            color: 'neutral.0',
            backgroundColor: 'danger.500',
        },
    },
});

export const Wrapper = styled(Box)<NotificationBarProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;

    ${({ theme }) => theme.breakpoint('sm')`
       justify-content: flex-start;
    `}

    ${variantStyles};

    a {
        color: currentColor;
        text-decoration: underline;
    }
`;
