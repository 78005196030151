import React from 'react';
import { Image } from '../Image';
import * as Styled from './styled';
import { NavBarBrandProps } from './types';

export const NavBarBrand: React.FC<NavBarBrandProps> = ({ src, width, height, href, onClick, children }) => (
    <Styled.Brand as={href ? 'a' : 'div'} href={href || ''} onClick={onClick}>
        {src && <Image src={src} alt="logo" width={width} height={height} />}
        {children && children}
    </Styled.Brand>
);
NavBarBrand.displayName = 'NavBar.Brand';
