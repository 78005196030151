import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgKeyOutlined = ({ size, title = 'KeyOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.79 11.696L20 3.486m-2.29 2.289L20 8.065l-2.29 2.29-2.29-2.29m-2.29 6.87a4.58 4.58 0 10-9.16 0 4.58 4.58 0 009.16 0z"
        />
    </svg>
);

export default withIcon(SvgKeyOutlined);
