import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSliderOutlined = ({ size, title = 'SliderOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.33} transform="rotate(90 12 12)">
            <path strokeLinecap="round" d="M17.554 11.113v-6.45m.004 14.674v-4.23" />
            <rect width={3.56} height={3.56} x={15.78} y={11.33} rx={1.78} transform="rotate(90 17.556 13.111)" />
            <path strokeLinecap="round" d="M12 19.333v-9.11m0-3.608V4.667" />
            <rect width={3.56} height={3.56} x={10.22} y={6.67} rx={1.78} transform="rotate(90 12 8.444)" />
            <path strokeLinecap="round" d="M6.444 19.333V18m0-4.444v-8.89" />
            <rect width={3.56} height={3.56} x={4.67} y={14} rx={1.78} transform="rotate(90 6.444 15.778)" />
        </g>
    </svg>
);

export default withIcon(SvgSliderOutlined);
