import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPlusOutlined = ({ size, title = 'PlusOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 12v7.5V12H4.5 12zm0 0V4.5 12h7.5H12z"
            />
        </g>
    </svg>
);

export default withIcon(SvgPlusOutlined);
