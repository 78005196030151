import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { MaestroStyledComponent } from '../../utils/componentHelpers';
import { color, radius, themeMode, variant, palette } from '../../utils';
import { TextareaProps } from './types';

const sizeStyles = variant({
    prop: 'size',
    variants: {
        sm: { fontSize: 'sm', py: 'lg', px: 'lg' },
        md: { fontSize: 'md', py: 'md', px: 'md' },
        lg: { fontSize: 'lg', py: 'lg', px: 'lg' },
    },
});

const variantStyles = variant({
    prop: 'variant',
    variants: {
        default: {
            background: themeMode({ dark: 'transparent', light: 'neutral.0' }),
            color: themeMode({ dark: 'neutral.50', light: 'neutral.900' }),
            border: '1px solid',
            borderColor: themeMode({ dark: 'neutral.600', light: 'neutral.300' }),
            '&::placeholder': {
                color: themeMode({ dark: 'neutral.500', light: 'neutral.400' }),
            },
            '&:hover': { borderColor: themeMode({ dark: 'neutral.400', light: 'neutral.500' }) },
            '&:focus': { borderColor: themeMode({ dark: 'neutral.400', light: 'neutral.500' }) },
            '&:disabled': {
                cursor: 'not-allowed',
                backgroundColor: themeMode({ dark: 'neutral.800', light: 'neutral.100' }),
                color: themeMode({ dark: 'neutral.500', light: 'neutral.400' }),
                borderColor: themeMode({ dark: 'neutral.800', light: 'neutral.100' }),
            },
        },
        ghost: {
            background: 'transparent',
            color: themeMode({ dark: 'neutral.50', light: 'neutral.900' }),
            border: '1px solid',
            borderColor: 'transparent',
            '&::placeholder': {
                color: themeMode({ dark: 'neutral.400', light: 'neutral.500' }),
            },
            '&:hover': {
                '&::placeholder': {
                    color: themeMode({ dark: 'neutral.50', light: 'neutral.900' }),
                },
            },
            '&:disabled': {
                cursor: 'not-allowed',
                color: themeMode({ dark: 'neutral.500', light: 'neutral.400' }),
            },
        },
    },
});

export const Textarea: MaestroStyledComponent<TextareaProps> = styled(Box).attrs(() => ({
    as: 'textarea',
}))<TextareaProps>`
    z-index: 1;
    position: relative;
    line-height: normal;
    width: 100%;
    color: inherit;
    background-color: transparent;
    border: none;
    appearance: none;
    transition: border-color 200ms ease-in, box-shadow 200ms ease-in;
    border-radius: ${radius('sm')};
    &::placeholder {
        transition: color 200ms ease-in;
    }
    &:focus {
        outline: none;
    }

    ${variantStyles};
    border-color: ${({ isInvalid }) => isInvalid && palette('danger.500')};

    ${sizeStyles};
    ${({ resize }) => resize && `resize: ${resize}`};

    &:focus {
        box-shadow: ${themeMode({ dark: color('neutral.50'), light: color('neutral.900') })} 0px 0px 0px 1px;
    }
`;
