import React from 'react';
export const BrandLogo = () => (
    <svg width="75" height="60" viewBox="0 0 78 60" xmlns="http://www.w3.org/2000/svg">
        <path
            fill="currentColor"
            d="M30.5 61C14 61 .5 47.5.5 31S14 1 30.5 1s30 13.5 30 30-13.4 30-30 30zm0-58.5C14.8 2.5 2 15.3 2 31s12.8 28.5 28.5 28.5S59 46.7 59 31 46.3 2.5 30.5 2.5z"
        />
        <path
            fill="currentColor"
            d="M45.4 61c-16.5 0-30-13.5-30-30s13.5-30 30-30 30 13.5 30 30-13.4 30-30 30zm0-58.5C29.7 2.5 16.9 15.3 16.9 31s12.8 28.5 28.5 28.5S73.9 46.7 73.9 31 61.1 2.5 45.4 2.5z"
        />
        <path
            fill="currentColor"
            d="M27.8 35.2l-.8-1.9h-3.7l-.8 1.9H21l3.5-8.4h1.1l3.5 8.4h-1.3zm-1.3-3.1l-1.4-3.3-1.4 3.3h2.8zM40.6 35.2l-4.1-5.9v5.9h-1.3v-8.4h1.1l4.1 5.9v-5.9h1.3v8.4h-1.1zM51.6 26.8c2.3 0 4.1 1.5 4.1 4.2 0 2.6-1.8 4.2-4.1 4.2h-3.3v-8.4h3.3zm-2 7.2h2c1.6 0 2.8-1 2.8-3s-1.1-3-2.7-3h-2v6z"
        />
        <path
            fill="currentColor"
            d="M12.5 34v1.2H7.2v-8.4h1.3V34h4zM67.2 32h-1.8v3.2h-1.3v-8.4h3.4c1.5 0 2.6.9 2.6 2.6 0 1.2-.6 2-1.4 2.4l1.9 3.4H69L67.2 32zm.1-1.2c.8 0 1.4-.5 1.4-1.4 0-.9-.6-1.4-1.4-1.4h-1.9v2.8h1.9z"
        />
    </svg>
);
