import { ChangeEvent, createContext, useContext } from 'react';

export type RadioGroupContextValue = {
    value?: string;
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    name?: string;
    isDisabled?: boolean;
};

const RadioGroupContext = createContext<RadioGroupContextValue | null>(null);

export const RadioGroupProvider = RadioGroupContext.Provider;

export const useRadioGroupContext = () => useContext(RadioGroupContext);
