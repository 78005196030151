import styled from 'styled-components';
import { themeMode, color, variant, radius, fontWeight } from '../../utils';
import { Box } from '../../primitives/Box';
import { ButtonLoaderProps, StyledButtonProps } from './types';

const activeState = '&:is(:hover, [aria-expanded="true"], [aria-checked="true"], [aria-current="true"])';

const sizeStyles = variant({
    prop: 'size',
    variants: {
        xs: { fontSize: 'sm', lineHeight: 1.23, height: '24px', minWidth: '24px', px: 'sm' },
        sm: { fontSize: 'md', lineHeight: 1.33, height: '36px', minWidth: '36px', px: 'sm' },
        md: { fontSize: 'md', lineHeight: 1.6, height: '48px', minWidth: '48px', px: 'md' },
        lg: { fontSize: 'lg', lineHeight: 1.6, height: '64px', minWidth: '64px', px: 'lg' },
    },
});

const iconSizeStyles = variant({
    prop: 'size',
    variants: {
        xs: { marginRight: '4px', marginLeft: '-4px' },
        sm: { marginRight: '4px', marginLeft: '-4px' },
        md: { marginRight: '8px', marginLeft: '-4px' },
        lg: { marginRight: '8px', marginLeft: '-4px' },
    },
});

const iconRightSizeStyles = variant({
    prop: 'size',
    variants: {
        xs: { marginRight: '-4px', marginLeft: '4px' },
        sm: { marginRight: '-4px', marginLeft: '4px' },
        md: { marginRight: '-8px', marginLeft: '4px' },
        lg: { marginRight: '-8px', marginLeft: '4px' },
    },
});

const kindStyles = variant({
    prop: 'kind',
    variants: {
        solid: {
            '&[aria-disabled="true"]': {
                color: themeMode({ light: 'neutral.400', dark: 'neutral.600' }),
                backgroundColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
            },
        },
        minimal: {
            backgroundColor: 'transparent',
            '&[aria-disabled="true"]': { color: themeMode({ light: 'neutral.400', dark: 'neutral.600' }) },
        },
        link: {
            backgroundColor: 'transparent',
            border: 'none',
            height: 'initial',
            minWidth: 'initial',
            padding: 0,
            [activeState]: {
                textDecoration: 'underline',
            },
            '&[aria-disabled="true"]': { color: themeMode({ light: 'neutral.400', dark: 'neutral.500' }) },
        },
    },
});

const variantKindStyles = variant({
    prop: 'variant',
    compoundProp: 'kind',
    variants: {
        primary: {
            solid: {
                color: 'neutral.900',
                backgroundColor: 'primary.500',
                [activeState]: {
                    backgroundColor: 'primary.700',
                },
            },
            minimal: {
                color: 'primary.500',
                [activeState]: {
                    color: 'neutral.50',
                    backgroundColor: 'primary.700',
                },
            },
            link: {
                color: 'primary.500',
                [activeState]: {
                    color: 'primary.700',
                },
            },
        },
        secondary: {
            solid: {
                color: themeMode({ light: 'neutral.50', dark: 'neutral.1000' }),
                backgroundColor: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
                [activeState]: {
                    backgroundColor: themeMode({ light: 'neutral.700', dark: 'neutral.200' }),
                },
            },
            minimal: {
                color: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
                [activeState]: {
                    color: themeMode({ light: 'neutral.50', dark: 'neutral.1000' }),
                    backgroundColor: themeMode({ light: 'neutral.700', dark: 'neutral.200' }),
                },
            },
            link: {
                color: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
                [activeState]: {
                    color: themeMode({ light: 'neutral.700', dark: 'neutral.200' }),
                },
            },
        },
        danger: {
            solid: {
                color: 'neutral.50',
                backgroundColor: 'danger.500',
                [activeState]: {
                    backgroundColor: 'danger.700',
                },
            },
            minimal: {
                color: 'danger.500',
                [activeState]: {
                    color: 'neutral.50',
                    backgroundColor: 'danger.700',
                },
            },
            link: {
                color: 'danger.500',
                [activeState]: {
                    color: 'danger.700',
                },
            },
        },
        warning: {
            solid: {
                color: 'neutral.900',
                backgroundColor: 'warning.500',
                [activeState]: {
                    backgroundColor: 'warning.700',
                },
            },
            minimal: {
                color: 'warning.500',
                [activeState]: {
                    color: 'neutral.50',
                    backgroundColor: 'warning.700',
                },
            },
            link: {
                color: 'warning.500',
                [activeState]: {
                    color: 'warning.700',
                },
            },
        },
        success: {
            solid: {
                color: 'neutral.50',
                backgroundColor: 'success.500',
                [activeState]: {
                    backgroundColor: 'success.700',
                },
            },
            minimal: {
                color: 'success.500',
                [activeState]: {
                    color: 'neutral.50',
                    backgroundColor: 'success.700',
                },
            },
            link: {
                color: 'success.500',
                [activeState]: {
                    color: 'success.700',
                },
            },
        },
        subtle: {
            solid: {
                color: themeMode({ light: 'neutral.1000', dark: 'neutral.50' }),
                backgroundColor: themeMode({ light: 'neutral.0', dark: 'neutral.1000' }),
                [activeState]: {
                    backgroundColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
                },
            },
            minimal: {
                color: themeMode({ light: 'neutral.1000', dark: 'neutral.50' }),
                [activeState]: {
                    backgroundColor: themeMode({ light: 'neutral.0', dark: 'neutral.1000' }),
                },
            },
            link: {
                color: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
                [activeState]: {
                    color: themeMode({ light: 'neutral.700', dark: 'neutral.200' }),
                },
            },
        },
        accent: {
            solid: {
                color: 'neutral.50',
                backgroundColor: 'secondary.500',
                [activeState]: {
                    backgroundColor: 'secondary.700',
                },
            },
            minimal: {
                color: 'secondary.500',
                [activeState]: {
                    color: 'neutral.50',
                    backgroundColor: 'secondary.700',
                },
            },
            link: {
                color: 'secondary.500',
                [activeState]: {
                    color: 'secondary.700',
                },
            },
        },
        ghost: {
            solid: {
                color: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
                backgroundColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
                [activeState]: {
                    backgroundColor: themeMode({ light: 'neutral.300', dark: 'neutral.600' }),
                },
            },
            minimal: {
                color: themeMode({ light: 'neutral.500', dark: 'neutral.400' }),
                [activeState]: {
                    color: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
                    backgroundColor: themeMode({ light: 'neutral.300', dark: 'neutral.600' }),
                },
            },
            link: {
                color: themeMode({ light: 'neutral.500', dark: 'neutral.400' }),
                [activeState]: {
                    color: themeMode({ light: 'neutral.900', dark: 'neutral.50' }),
                },
            },
        },
        facebook: {
            solid: {
                color: 'neutral.50',
                backgroundColor: 'facebook.500',
                [activeState]: {
                    backgroundColor: 'facebook.700',
                },
            },
            minimal: {
                color: 'facebook.500',
                [activeState]: {
                    color: 'neutral.50',
                    backgroundColor: 'facebook.700',
                },
            },
            link: {
                color: 'facebook.500',
                [activeState]: {
                    color: 'facebook.700',
                },
            },
        },
        twitter: {
            solid: {
                color: 'neutral.50',
                backgroundColor: 'twitter.500',
                [activeState]: {
                    backgroundColor: 'twitter.700',
                },
            },
            minimal: {
                color: 'twitter.500',
                [activeState]: {
                    color: 'neutral.50',
                    backgroundColor: 'twitter.700',
                },
            },
            link: {
                color: 'twitter.500',
                [activeState]: {
                    color: 'twitter.700',
                },
            },
        },
    },
});

export const ButtonText = styled(Box).attrs(() => ({ as: 'span' }))``;

ButtonText.displayName = 'Styled.ButtonText';

export const ButtonLoader = styled(Box)<ButtonLoaderProps>(({ state }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
    transition: 'opacity 300ms ease-in-out',
    opacity: state === 'entered' ? 1 : 0,
    transform: 'translate3d(0, 0, 0)',
    svg: {
        width: '1em',
        height: '1em',
    },
}));

ButtonLoader.displayName = 'Button.Loader';

export const ButtonIcon = styled(Box)``;

ButtonIcon.displayName = 'Button.Icon';

export const ButtonIconRight = styled(Box)``;

ButtonIconRight.displayName = 'Button.IconRight';

export const Button = styled(Box)<StyledButtonProps>`
    appearance: none;
    display: ${({ isFull }: StyledButtonProps): string => (isFull ? 'flex' : 'inline-flex')};
    flex-direction: row;
    justify-content: ${({ isDropdown }: StyledButtonProps): string => (isDropdown ? 'space-between' : 'center')};
    align-items: center;
    position: relative;
    min-width: 5ch;
    width: ${({ isFull }: StyledButtonProps): string => (isFull ? '100%' : 'auto')};
    border: none;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
    font-weight: ${fontWeight('bold')};
    padding: 0;
    text-decoration: none;
    background-color: transparent;
    user-select: none;
    transition: all 300ms ease-in-out;
    border-radius: ${({ shape }) => (shape === 'rounded' ? radius('round') : radius('sm'))};

    ${sizeStyles};
    ${kindStyles};
    ${variantKindStyles};

    &[aria-pressed='true'],
    &:hover {
        cursor: pointer;
    }

    &:disabled,
    &[aria-disabled='true'] {
        cursor: default;
        pointer-events: none;
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:focus-visible {
        box-shadow: ${themeMode({ dark: color('neutral.50'), light: color('neutral.900') })} 0px 0px 0px 2px;
    }

    ${ButtonText} {
        display: flex;
        transition: opacity 300ms ease-in-out;
        opacity: ${({ isLoading }): number => (isLoading ? 0 : 1)};
    }

    ${ButtonIcon},
    ${ButtonIconRight} {
        transition: opacity 300ms ease-in-out;
        opacity: ${({ isLoading }): number => (isLoading ? 0 : 1)};
    }

    ${ButtonIcon} {
        ${iconSizeStyles};
    }

    ${ButtonIconRight} {
        ${iconRightSizeStyles};
    }
`;

Button.displayName = 'Styled.Button';
