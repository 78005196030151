import { Box } from '../../../../primitives';
import { color, radius, shadow, spacing, themeMode } from '../../../../utils';
import styled from '../../../../utils/styled';

export const Dropdown = styled(Box)`
    background-color: ${themeMode({
        light: color('neutral.0'),
        dark: color('neutral.800'),
    })};
    display: flex;
    flex-direction: column;
    max-height: 192px;
    overflow: hidden;
    z-index: 1000;
    margin: ${spacing('xs')} auto;
    box-shadow: ${shadow('3')};
    border-radius: ${radius('base')};
`;
