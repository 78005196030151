import React, { Children, forwardRef } from 'react';
import { CaretRightOutlined } from '../../assets/icons';
import * as Styled from './styled';
import { BreadcrumbItemProps, BreadcrumbProps } from './types';

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = (props) => {
    const { children, ...rest } = props;
    return <Styled.Item {...rest}>{children}</Styled.Item>;
};

const Component = forwardRef<HTMLDivElement, BreadcrumbProps>((props, ref) => {
    const { children, separator = <CaretRightOutlined />, ...rest } = props;

    const validChildren = Children.toArray(children).filter((child) =>
        React.isValidElement(child),
    ) as React.ReactElement[];

    const lastIndex = validChildren.length - 1;

    const clones = validChildren.reduce((acc, child, index) => {
        if (index === lastIndex) {
            acc.push(child);
            return acc;
        }
        acc.push(child, <Styled.Separator key={`breadcrumb-separator-${index}`}>{separator}</Styled.Separator>);
        return acc;
    }, [] as React.ReactElement[]);

    return (
        <Styled.Breadcrumb ref={ref} aria-label="breadcrumb" {...rest}>
            <Styled.Wrapper>{clones}</Styled.Wrapper>
        </Styled.Breadcrumb>
    );
});

Component.displayName = 'Breadcrumb';

export const Breadcrumb = Object.assign(Component, { Item: BreadcrumbItem, Link: Styled.Link });
