import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSuccessOutlined = ({ size, title = 'SuccessOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <circle cx={12} cy={12} r={8.25} />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 9l-3.598 6L9 12.6" />
        </g>
    </svg>
);

export default withIcon(SvgSuccessOutlined);
