import React from 'react';
import { forwardRef } from '../../system';
import { Box } from '../../primitives';
import { SliderProps, DragProps } from './types';
import * as Styled from './styled';
import { useSlider } from './useSlider';
import { useDrag } from './useDrag';
const DragHandle = ({
    position,
    radius,
    onChange,
    onChangeEnd,
    minPosition,
    maxPosition,
    defaultPosition,
    isDisabled,
    ...props
}: DragProps) => {
    const { currentPosition, isDragging, handleMouseDown, handleTouchStart } = useDrag({
        position,
        minPosition,
        maxPosition,
        defaultPosition,
        onChange,
        onChangeEnd,
        isDisabled,
    });

    return (
        <Styled.Draggable
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            radius={radius}
            position={currentPosition}
            isDragging={isDragging}
            {...props}
        >
            <Styled.Handle radius={radius} isDisabled={isDisabled} />
        </Styled.Draggable>
    );
};

export const Slider = forwardRef<SliderProps, 'div'>((props, ref) => {
    const {
        children,
        width = 300,
        onChange,
        onChangeEnd,
        min,
        max,
        hasRange = false,
        handleRadius = 10,
        isDisabled = false,
        defaultValue,
        value,
        as,
        ...rest
    } = props;

    const {
        positions,
        minPosition,
        maxPosition,
        updateMinFromPosition,
        updateMaxFromPosition,
        onMinChangeEnd,
        onMaxChangeEnd,
    } = useSlider(props);

    return (
        <Styled.Slider min={min} max={max} width={width} ref={ref} forwardedAs={as} {...rest}>
            <Box display="flex" width="100%">
                <Styled.Rail isDisabled={isDisabled} />
                {hasRange && (
                    <Styled.ActiveRangeRail
                        handleRadius={handleRadius}
                        range={{ min: positions.min, max: positions.max }}
                        isDisabled={isDisabled}
                    />
                )}
                <DragHandle
                    position={positions.min}
                    radius={handleRadius}
                    onChange={updateMinFromPosition}
                    onChangeEnd={onMinChangeEnd}
                    minPosition={minPosition}
                    maxPosition={hasRange ? positions.max : maxPosition}
                    defaultPosition={minPosition}
                    isDisabled={isDisabled}
                />
                {hasRange && (
                    <DragHandle
                        position={positions.max}
                        radius={handleRadius}
                        onChange={updateMaxFromPosition}
                        onChangeEnd={onMaxChangeEnd}
                        minPosition={positions.min}
                        maxPosition={maxPosition}
                        defaultPosition={maxPosition}
                        isDisabled={isDisabled}
                    />
                )}
            </Box>
        </Styled.Slider>
    );
});

Slider.displayName = 'Slider';
