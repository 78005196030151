import React from 'react';
import styled from '../../utils/styled';
import { MaestroStyledComponent, fontWeight } from '../../utils';
import { Text } from '../Text';
import { Box, BoxProps } from '../../primitives';
import { BrandLogo } from './BrandLogo';
import { StrokeLogo } from './StrokeLogo';

export type VariantType = 'brand';

export interface LogoProps extends BoxProps {
    /** The label to append to the logo */
    label?: string;
    variant?: VariantType;
}

type Props = LogoProps & React.HTMLAttributes<HTMLDivElement>;

const StyledProductName = styled(Text)`
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.3075em;
    font-weight: ${fontWeight('bold')};
    text-transform: uppercase;
    margin-left: 1em;
    user-select: none;
`;

const StyledLogo: MaestroStyledComponent<LogoProps> = styled(Box)<LogoProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Logo: React.FC<Props> = ({ label, variant, color, ...props }: LogoProps) => (
    <StyledLogo color={color} {...props}>
        {variant !== 'brand' ? (
            <>
                <StrokeLogo />
                {!!label && <StyledProductName>{label}</StyledProductName>}
            </>
        ) : (
            <BrandLogo />
        )}
    </StyledLogo>
);

Logo.displayName = 'Logo';
