import React from 'react';
import { forwardRef } from '../../system';
import { Text } from '../Text';
import { Badge } from '../Badge';
import { ProductHeaderNameProps } from './types';
import * as Styled from './styled';

export const ProductHeaderName = forwardRef<ProductHeaderNameProps, 'h1'>((props, ref) => {
    const { children, badgeContent, badgeProps, ...rest } = props;
    return (
        <Styled.Name ref={ref} {...rest}>
            <Text as="div" size="lg" fontWeight="extrabold" letterSpacing="-0.4px" mb="0">
                {children}
            </Text>
            {!!badgeContent && <Badge content={badgeContent} ml="xs" size="sm" {...badgeProps} />}
        </Styled.Name>
    );
});
ProductHeaderName.displayName = 'ProductHeader.Name';
