import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHandOutlined = ({ size, title = 'HandOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            stroke="currentColor"
            strokeLinecap="square"
            strokeMiterlimit={10}
            strokeWidth={1.5}
            clipPath="url(#hand-outlined_svg__hand-outlined_svg__clip0_2125_170574)"
        >
            <path d="M15.927 8.227l.514-.514c.273-.273.423-.646.417-1.038a1.523 1.523 0 00-.448-1.05 1.523 1.523 0 00-1.051-.449 1.433 1.433 0 00-1.038.417L9.424 10.49a.71.71 0 01-.303.18.736.736 0 01-.356.013.763.763 0 01-.545-.442L7.395 8.37a1.367 1.367 0 00-.492-.585 1.342 1.342 0 00-.724-.227v0a1.272 1.272 0 00-.89.34 1.266 1.266 0 00-.398.863l-.186 3.597a6.735 6.735 0 00.452 2.786 6.92 6.92 0 001.557 2.384l1.016 1.016a6.852 6.852 0 004.728 2.017 6.449 6.449 0 004.67-1.876l4.372-4.37c.204-.205.317-.486.312-.78a1.143 1.143 0 00-.336-.787 1.142 1.142 0 00-.788-.336 1.075 1.075 0 00-.778.312l-.258.257" />
            <path d="M12.841 11.313l4.114-4.115a1.433 1.433 0 011.038-.416c.392.006.77.167 1.05.448.282.281.443.66.449 1.05a1.43 1.43 0 01-.417 1.038l-4.114 4.115m3.086-3.086l.514-.514a1.433 1.433 0 011.038-.417c.391.006.77.167 1.05.448v0c.281.281.443.66.449 1.051.006.392-.144.765-.417 1.038l-3.6 3.6" />
        </g>
        <defs>
            <clipPath id="hand-outlined_svg__hand-outlined_svg__clip0_2125_170574">
                <path fill="currentColor" d="M0 0h24v24H0z" transform="translate(.5)" />
            </clipPath>
        </defs>
    </svg>
);

export default withIcon(SvgHandOutlined);
