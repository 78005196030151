import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCdPauseFilled = ({ size, title = 'CdPauseFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <circle cx={12} cy={12} r={2.25} />
            <path
                strokeLinecap="round"
                d="M16.25 20.254V16m3 4.254V16M12 20.25h0c-4.556 0-8.25-3.703-8.25-8.25h0c0-4.556 3.703-8.25 8.25-8.25h0c4.556 0 8.25 3.703 8.25 8.25h0"
            />
        </g>
    </svg>
);

export default withIcon(SvgCdPauseFilled);
