import { useEffect } from 'react';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import { ApolloProvider } from '@apollo/client';
// Lib
import { useApollo } from 'lib/apolloClient';
import { analytics } from 'utils/analytics';
import { useAnalyticsPageTracking } from 'hooks/useAnalyticsPageTracking';
import { AnalyticsLanguage } from '@landr/core';
import NextNProgress from 'nextjs-progressbar';

import 'styles/globals.scss';
import { LayoutData, getLayoutData } from '../lib/layoutData';
import { runOneSignal } from '../utils/oneSignal';
import { LayoutProvider } from 'components';
import Script from 'next/script';

interface CustomAppProps extends AppProps {
  layoutData: LayoutData;
}

const CustomApp = ({
  Component,
  pageProps,
  router,
  layoutData,
}: CustomAppProps) => {
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const root = document.getElementById('__next');

    if (root) {
      const scrollbar = window.innerWidth - root.clientWidth;

      root.setAttribute('style', `--scroll-bar: ${scrollbar}px`);
    }

    // Register for push notification only once on app load
    runOneSignal();
  }, []);

  useAnalyticsPageTracking({
    analytics,
    location: { pathname: router.asPath, query: router.query },
    // this is fine, as the languages defined in `next.config.js` match those in AnalyticsLanguage
    locale: router.locale as AnalyticsLanguage,
    isRouterReady: router.isReady,
  });

  return (
    <>
      <Script
        strategy="lazyOnload"
        src="https://2d0802317383405eba422e5b3e6a7776.js.ubembed.com"
      />
      <Script id="customer-io-forms" strategy="lazyOnload">
        {`(function() {
                var t = document.createElement('script'),
                    s = document.getElementsByTagName('script')[0];
                t.async = true;
                t.id    = 'cio-forms-handler';
                t.setAttribute('data-site-id', '${process.env.NEXT_PUBLIC_CUSTOMER_IO_KEY}');
                t.setAttribute('data-base-url', 'https://customerioforms.com');
            
                t.src = 'https://customerioforms.com/assets/forms.js';
            
                s.parentNode.insertBefore(t, s);
              })();
              `}
      </Script>
      <LayoutProvider value={layoutData}>
        <ApolloProvider client={apolloClient}>
          <NextNProgress />
          <Component {...pageProps} />
        </ApolloProvider>
      </LayoutProvider>
    </>
  );
};

CustomApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & { layoutData: LayoutData }> => {
  const layoutData = await getLayoutData(context);
  const ctx = await App.getInitialProps(context);

  return { ...ctx, layoutData };
};

export default CustomApp;
