import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMonitorOffOutlined = ({ size, title = 'MonitorOffOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M19.5 9.451V15.5h-6.742m-4.511 0H4.5v-10h15m-12 14h9m3-14l-15 14"
        />
    </svg>
);

export default withIcon(SvgMonitorOffOutlined);
