import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { GET_POSTS } from 'api/posts/queries';
import { PostsQuery, PostsQueryVariables } from 'types/generated/graphql';
import { computePostsData } from 'helpers/posts';
import { MappedPost } from 'ui';

type UseGetPostsOptions = {
  count: number;
  categoryId?: number | null;
  author?: number | null;
  postIds?: string[] | null;
};

export const useGetPosts = ({
  count = 1,
  author,
  categoryId,
  postIds,
}: UseGetPostsOptions): MappedPost[] => {
  const { locale } = useRouter();

  const { data } = useQuery<PostsQuery, PostsQueryVariables>(GET_POSTS, {
    variables: {
      first: count,
      where: {
        ...(author ? { author } : {}),
        ...(categoryId ? { categoryId } : {}),
        ...(postIds ? { in: postIds } : {}),
        wpmlLanguage: locale,
      },
    },
    errorPolicy: 'all',
  });

  const posts = computePostsData(data?.posts?.nodes);

  return posts;
};
