import React from 'react';
import { forwardRef } from '../../system';
import { MinusOutlined, CheckOutlined } from '../../assets/icons';
import { CheckboxProps } from './types';
import * as Styled from './styled';

type Props = CheckboxProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;

export const Checkbox = forwardRef<Props, 'input'>((props, ref: React.Ref<HTMLInputElement>) => {
    const {
        id,
        as = 'input',
        name,
        value,
        label,
        isChecked,
        isDisabled,
        isInvalid,
        isIndeterminate,
        hideLabel,
        truncate,
        size = 'md',
        ...rest
    } = props;

    return (
        <Styled.Checkbox
            ref={ref}
            forwardedAs={as}
            id={id}
            name={name}
            value={value}
            isChecked={isChecked}
            isIndeterminate={isIndeterminate}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            {...rest}
        >
            <Styled.Control size={size}>{isIndeterminate ? <MinusOutlined /> : <CheckOutlined />}</Styled.Control>
            {!hideLabel && (
                <Styled.Label size={size} truncate={truncate} isDisabled={isDisabled}>
                    {label}
                </Styled.Label>
            )}
        </Styled.Checkbox>
    );
});

Checkbox.displayName = 'Checkbox';
