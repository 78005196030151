import { useState, useRef, useEffect, MutableRefObject } from 'react';

export function useHover(hideOnScroll: boolean): [MutableRefObject<any>, boolean] {
    const [value, setValue] = useState(false);

    const isTouch = useRef(false);
    const holdTimeOut = useRef(0);
    const ref = useRef<HTMLElement>(null);

    const handleMouseEnter = (): void => {
        // Do not update value if after handleTouchStart
        if (!isTouch.current) {
            setValue(true);
        }
        // Reset for the next event
        isTouch.current = false;
    };
    const handleMouseLeave = (): void => {
        setValue(false);
        clearTimeout(holdTimeOut.current);
    };

    const handleTouchStart = (): void => {
        isTouch.current = true;
        clearTimeout(holdTimeOut.current);
        holdTimeOut.current = window.setTimeout(() => {
            setValue(true);
            isTouch.current = false;
        }, 350);
    };
    const handleTouchEnd = (): void => {
        setValue(false);
        clearTimeout(holdTimeOut.current);
    };

    const handleScroll = (): void => {
        setValue(false);
        clearTimeout(holdTimeOut.current);
    };

    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('mouseenter', handleMouseEnter);
            node.addEventListener('mouseleave', handleMouseLeave);
            node.addEventListener('touchstart', handleTouchStart);
            node.addEventListener('touchend', handleTouchEnd);
            hideOnScroll && document.addEventListener('scroll', handleScroll, { capture: true });
        }
        return (): void => {
            clearTimeout(holdTimeOut.current);
            node?.removeEventListener('mouseenter', handleMouseEnter);
            node?.removeEventListener('mouseleave', handleMouseLeave);
            node?.removeEventListener('touchstart', handleTouchStart);
            node?.removeEventListener('touchend', handleTouchEnd);
            document.removeEventListener('scroll', handleScroll);
        };
    }, [ref, hideOnScroll]);

    return [ref, value];
}
