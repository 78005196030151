import React from 'react';
import styled from 'styled-components';
import { variant, themeMode, color, radius } from '../../utils';
import { Box } from '../../primitives/Box';
import { forwardRef } from '../../system';
import { ModalContentProps, ModalProps } from './types';
import { useModalContext } from './ModalContext';

const modalScrollBehavior = variant({
    prop: 'scrollBehavior',
    variants: {
        inside: { overflow: 'auto' },
    },
});

const StyledModalContent = styled(Box)<ModalContentProps & { scrollBehavior: ModalProps['scrollBehavior'] }>`
    flex: 1 1 auto;
    background-color: ${themeMode({
        dark: color('neutral.900'),
        light: color('neutral.0'),
    })};

    ${modalScrollBehavior};

    &:first-child {
        border-top-left-radius: ${radius('base')};
        border-top-right-radius: ${radius('base')};
    }

    &:last-child {
        border-bottom-left-radius: ${radius('base')};
        border-bottom-right-radius: ${radius('base')};
    }
`;

export const ModalContent = forwardRef<ModalContentProps, 'div'>(({ children, ...props }, ref) => {
    const { scrollBehavior } = useModalContext();
    return (
        <StyledModalContent ref={ref} scrollBehavior={scrollBehavior} {...props}>
            {children}
        </StyledModalContent>
    );
});

ModalContent.displayName = 'Modal.Content';
