import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgNetworkServices = ({ size, title = 'NetworkServices', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#142739"
            d="M34.533 24.937a1.602 1.602 0 00-1.645-.39l-10.264 3.436h-4.482l-4.482-1.501 6.392-1.751a2.031 2.031 0 001.397-1.367 2.043 2.043 0 00-.395-1.917 2.022 2.022 0 00-1.823-.696l-8.558 1.228-3.582 1.415a10.541 10.541 0 01-3.872.737H2a1 1 0 00-1 1v6.49a1 1 0 001 1h4.059c.85 0 1.698.103 2.523.307l7.318 1.804 7.47-1.5L34.148 27.5a1.616 1.616 0 00.386-2.564z"
        />
        <circle cx={18} cy={10} r={6} fill="#32CBCB" />
        <path
            fill="#435261"
            d="M8.91 9.984c0 2.383.962 4.668 2.672 6.352a9.192 9.192 0 006.45 2.632 9.19 9.19 0 006.45-2.632 8.914 8.914 0 002.672-6.352 8.914 8.914 0 00-2.671-6.353A9.192 9.192 0 0018.033 1a9.19 9.19 0 00-6.45 2.631A8.914 8.914 0 008.91 9.984zM21.75 8.21h-2.027a.932.932 0 00-1.014-.777h-1.352a.932.932 0 00-1.014.777.933.933 0 001.014.776h1.352a2.964 2.964 0 012.1.768c.57.52.908 1.24.94 2.003a2.834 2.834 0 01-.816 1.883 2.92 2.92 0 01-1.886.86v.807h-2.028V14.5a2.919 2.919 0 01-1.886-.86 2.834 2.834 0 01-.817-1.883h2.027a.932.932 0 001.014.778h1.352a.932.932 0 001.014-.778.933.933 0 00-1.014-.775h-1.352a2.964 2.964 0 01-2.1-.769 2.876 2.876 0 01-.94-2.003 2.834 2.834 0 01.816-1.882 2.92 2.92 0 011.886-.86V4.66h2.028v.808a2.919 2.919 0 011.886.86c.499.506.79 1.177.817 1.882z"
        />
    </svg>
);

export default withIcon(SvgNetworkServices);
