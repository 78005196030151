import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPauseFilled = ({ size, title = 'PauseFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8 6a2 2 0 012 2v8a2 2 0 11-4 0V8a2 2 0 012-2zm8 0a2 2 0 012 2v8a2 2 0 11-4 0V8a2 2 0 012-2z"
        />
    </svg>
);

export default withIcon(SvgPauseFilled);
