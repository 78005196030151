import React from 'react';
import { findChildWithDisplayName } from '../../utils';
import { Tooltip } from '../';
import { forwardRef } from '../../system';
import { Box } from '../../primitives';
import { AssetCardProps } from './types';
import * as Styled from './styled';

const AssetCardComponent = forwardRef<AssetCardProps, 'div'>((props, ref) => {
    const {
        icon: IconComponent,
        title,
        size = 'md',
        isDisabled = false,
        error,
        children,
        variant = 'filled',
        isSelectable = false,
        isSelected = false,
        onSelect,
        ...rest
    } = props;
    const actions = findChildWithDisplayName(children, Styled.Actions.displayName);
    const content = findChildWithDisplayName(children, Styled.Content.displayName);
    const media = findChildWithDisplayName(children, Styled.Media.displayName);
    const leftAction = findChildWithDisplayName(children, Styled.LeftAction.displayName);

    const handleSelect = () => {
        onSelect?.();
    };

    return (
        <Styled.Card size={size} error={error} isDisabled={isDisabled} variant={variant} {...rest} ref={ref}>
            {leftAction && <Styled.Container>{leftAction}</Styled.Container>}
            {isSelectable && (
                <Styled.StyledCheckbox label="Select" hideLabel isChecked={isSelected} onChange={handleSelect} />
            )}
            <Styled.Container alignItems="center" ml="xxs">
                {IconComponent && <IconComponent />}
                {media}
            </Styled.Container>
            {content || <AssetCard.Content>{title && <Styled.Title>{title}</Styled.Title>}</AssetCard.Content>}
            {!isDisabled && (
                <Styled.Container alignItems="center" ml="auto">
                    {error && (
                        <Tooltip position="top" content={error}>
                            <Box display="flex" alignItems="center">
                                <Styled.InfoIcon error={error} />
                            </Box>
                        </Tooltip>
                    )}
                    {actions}
                </Styled.Container>
            )}
        </Styled.Card>
    );
});

AssetCardComponent.displayName = 'AssetCard';

export const AssetCard = Object.assign(AssetCardComponent, {
    Content: Styled.Content,
    Media: Styled.Media,
    Actions: Styled.Actions,
    Title: Styled.Title,
    Description: Styled.Description,
    LeftAction: Styled.LeftAction,
});
