import { SelectProps, SelectGroupOptions, SelectGroupOption } from './types';

export const getGroups = (groupBy: SelectProps['groupBy'], options: SelectProps['options']) =>
    groupBy ? Array.from(new Set(options.map((item) => groupBy(item)))) : [''];

export const getOptionsByGroup = (
    groupBy: SelectProps['groupBy'],
    groups: (string | number | undefined)[],
    options: SelectGroupOption[],
): SelectGroupOptions[] =>
    groups.map((group) => ({
        group,
        options: groupBy ? options.filter((item) => groupBy(item) === group) : options,
    }));
