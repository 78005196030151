import React, {
    useState,
    useEffect,
    FC,
    ReactNode,
    Children,
    KeyboardEvent,
    useRef,
    useCallback,
    useMemo,
} from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import { callAllHandlers, maestroComponent, useEnhancedEffect } from '../../utils';
import { Box, ConditionalWrapper } from '../../primitives';
import { Nav } from '../Nav';
import { Badge } from '../Badge';
import { Fade } from '../Fade';
import { ColorMode } from '../MaestroThemeProvider';
import { CaretLeftOutlined, CaretRightOutlined } from '../../assets/icons';
import { useExperiment } from '../MaestroThemeProvider/ExperimentalProvider';
import { TabsPaneProps, TabsProps } from './types';
import * as Styled from './styled';

const Pane = maestroComponent<TabsPaneProps>('Tabs.Pane', Styled.Pane);

const Tabs: FC<TabsProps> = ({
    children,
    selectedTab,
    onSelectTab,
    size = 'md',
    isLazy = true,
    variant = 'tab',
    align = 'left',
    allowScrollToTab = true,
    ...props
}) => {
    const getPanes = (children: ReactNode): any[] => Children.toArray(children);

    const panes = useMemo(() => getPanes(children) || [], [children]);

    const tabsContent: ReactNode = useMemo(() => [], []);

    const defaultSelectedTab = selectedTab ? selectedTab : panes[0]?.props.name;

    const [activeTab, setActiveTab] = useState<string>();

    const changeTabPane = (paneName: string): void => {
        if (activeTab !== paneName) {
            typeof onSelectTab === 'function' ? onSelectTab(paneName) : setActiveTab(paneName);
        }
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLAnchorElement>, currentIndex: number): void => {
        if (!(e.key === 'ArrowLeft' || e.key === 'ArrowRight')) {
            return;
        }

        const firstTabIndex = 0;
        const lastTabIndex = panes.length - 1;

        let nextPosition = e.key === 'ArrowLeft' ? currentIndex - 1 : currentIndex + 1;

        if (nextPosition > lastTabIndex) {
            nextPosition = firstTabIndex;
        }

        if (nextPosition < firstTabIndex) {
            nextPosition = lastTabIndex;
        }

        const nextPane = panes[nextPosition];

        changeTabPane(nextPane.props.name);
        tabsContent[nextPosition].focus();
    };

    // Overflow scroll behavior
    // Display navigation button when the tabs are overflowing the tablist

    const tabsScrollerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (direction: 'forward' | 'backward') => {
        if (!tabsScrollerRef.current) return;

        const isForward = direction === 'forward';

        const element = tabsScrollerRef.current;

        element.scrollBy({
            left: (isForward ? element.offsetWidth : -element.offsetWidth) * 0.5,
            behavior: 'smooth',
        });
    };

    const [isTabsArrowEnabled] = useExperiment('tabsArrow');
    const [isScrollable, setIsScrollable] = useState(false);
    const [showScrollForward, setShowScrollForward] = useState(false);
    const [showScrollBackward, setShowScrollBackward] = useState(false);

    // Check if the tabs are overflowing
    useEnhancedEffect(() => {
        if (!tabsScrollerRef.current) return;
        const element = tabsScrollerRef.current;

        const update = () => {
            if (element.scrollWidth > element.clientWidth) {
                setIsScrollable(true);
            } else {
                setIsScrollable(false);
            }
        };

        // Force a first update
        update();

        window.addEventListener('resize', update);

        return () => {
            window.removeEventListener('resize', update);
        };
    }, []);

    const [leftMaskOpacity, setLeftMaskOpacity] = useState(1);
    const [rightMaskOpacity, setRightMaskOpacity] = useState(1);

    const controlsStyles = {
        '--left-mask-opacity': leftMaskOpacity,
        '--right-mask-opacity': rightMaskOpacity,
    } as React.CSSProperties;

    // Define the visibility of the controls
    useEffect(() => {
        setLeftMaskOpacity(showScrollBackward ? 0 : 1);
        setRightMaskOpacity(showScrollForward ? 0 : 1);
    }, [showScrollBackward, showScrollForward]);

    const onScroll = useCallback(
        (event) => {
            if (!event || !isTabsArrowEnabled) return;

            event.target?.scrollLeft <= 0 ? setShowScrollBackward(false) : setShowScrollBackward(true);

            event.target.scrollLeft >= event.target.scrollWidth - event.target.offsetWidth
                ? setShowScrollForward(false)
                : setShowScrollForward(true);
        },
        [isTabsArrowEnabled],
    );

    // Add listener on scroll if isScrollable
    useEffect(() => {
        if (!tabsScrollerRef.current) return;

        if (!isScrollable || !isTabsArrowEnabled) {
            setShowScrollBackward(false);
            setShowScrollForward(false);
        } else {
            setShowScrollForward(true);
        }

        const element = tabsScrollerRef.current;

        element.addEventListener('scroll', onScroll);

        return () => {
            element.removeEventListener('scroll', onScroll);
        };
    }, [isScrollable, isTabsArrowEnabled, onScroll]);

    // Scroll to selected tab
    const scrollToTab = useCallback(
        (selectedTab: string) => {
            const activeTabIndex = panes.findIndex((pane) => pane.props.name === selectedTab);
            const activeTabRef = tabsContent[activeTabIndex];
            if (allowScrollToTab && activeTabRef) {
                scrollIntoView(activeTabRef, {
                    scrollMode: 'if-needed',
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest',
                    boundary: tabsScrollerRef.current,
                });
            }
        },
        [allowScrollToTab, panes, tabsContent],
    );

    useEffect(() => {
        if (!activeTab) {
            setActiveTab(defaultSelectedTab);
            scrollToTab(defaultSelectedTab);
            return;
        }

        if (!selectedTab) {
            return;
        }

        if (activeTab !== selectedTab) {
            setActiveTab(selectedTab);
            scrollToTab(selectedTab);
        }
    }, [defaultSelectedTab, scrollToTab, activeTab, selectedTab]);

    return (
        <Box width="100%" {...props}>
            <Styled.Wrapper>
                <Fade in={showScrollBackward} timeout={150} mountOnEnter>
                    <Styled.ScrollButton
                        direction="backward"
                        disabled={!showScrollBackward}
                        onClick={() => handleScroll('backward')}
                    >
                        <CaretLeftOutlined />
                    </Styled.ScrollButton>
                </Fade>
                <Styled.ScrollContainer style={controlsStyles} ref={tabsScrollerRef}>
                    <Styled.Tablist variant={variant} role="tablist" size={size} align={align}>
                        {panes.map((pane, paneIndex) => {
                            const isActivePane = activeTab === pane.props.name;
                            const hasBadge = !!pane?.props?.badgeContent;
                            const handleTabClick = () => changeTabPane(pane.props.name);

                            return (
                                <Nav.Item key={paneIndex}>
                                    <Nav.Link
                                        role="tab"
                                        ref={(ref) => (tabsContent[paneIndex] = ref)}
                                        tabIndex={isActivePane ? 0 : -1}
                                        aria-current={isActivePane ? true : undefined}
                                        icon={pane?.props?.icon}
                                        hasBadge={hasBadge}
                                        onClick={callAllHandlers(handleTabClick)}
                                        onKeyDown={(event: KeyboardEvent<HTMLAnchorElement>): void =>
                                            handleKeyPress(event, paneIndex)
                                        }
                                    >
                                        {pane.props.label}
                                        {hasBadge && (
                                            <ConditionalWrapper
                                                condition={isActivePane && variant === 'pill'}
                                                wrapper={(children): React.ReactNode => (
                                                    <ColorMode mode="invert">{children}</ColorMode>
                                                )}
                                            >
                                                <Badge
                                                    variant={pane?.props?.badgeVariant || 'default'}
                                                    content={pane.props.badgeContent}
                                                    isRounded
                                                    kind="subtle"
                                                    ml="xs"
                                                />
                                            </ConditionalWrapper>
                                        )}
                                    </Nav.Link>
                                </Nav.Item>
                            );
                        })}
                    </Styled.Tablist>
                </Styled.ScrollContainer>
                <Fade in={showScrollForward} timeout={150} mountOnEnter>
                    <Styled.ScrollButton
                        direction="forward"
                        disabled={!showScrollForward}
                        onClick={() => handleScroll('forward')}
                    >
                        <CaretRightOutlined />
                    </Styled.ScrollButton>
                </Fade>
            </Styled.Wrapper>
            {isLazy && panes.find((pane) => activeTab === pane.props.name)}
            {!isLazy &&
                panes.map((pane, index) => (
                    <Box key={index} hidden={activeTab === pane.props.name ? false : true}>
                        {pane}
                    </Box>
                ))}
        </Box>
    );
};

Tabs.displayName = 'Tabs';

export default Object.assign(Tabs, { Pane: Pane });
