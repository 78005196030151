import React from 'react';
import { forwardRef } from '../../system';
import { mergeRefs } from '../../utils';
import { TruncateProps } from './types';
import * as Styled from './Truncate.styled';
import { useTruncate } from './useTruncate';

export const Truncate = forwardRef<TruncateProps, 'div'>((props, ref) => {
    const { children, as = 'div', mode = 'end', ellipsis, nbOfLines: nbOfLinesProp = 1, ...rest } = props;

    const nbOfLines = mode === 'middle' ? null : nbOfLinesProp;

    const { containerRef, isReady } = useTruncate({ mode, ellipsis });

    const title = typeof children === 'string' ? children : null;

    return (
        <Styled.Truncate
            as={as}
            ref={mergeRefs(ref, containerRef)}
            title={title}
            mode={mode}
            nbOfLines={nbOfLines}
            {...rest}
        >
            <span
                style={{
                    opacity: isReady ? 1 : 0,
                }}
            >
                {children}
            </span>
        </Styled.Truncate>
    );
});

Truncate.displayName = 'Truncate';
