import React, { FC, useCallback, useEffect, useRef } from 'react';

import styles from './SeekSlider.module.scss';

type SeekSliderProps = {
  onSeekChange: (time: number) => void;
  duration: number | null;
  time: number;
};

export const SeekSlider: FC<SeekSliderProps> = ({
  onSeekChange,
  duration,
  time,
}) => {
  const seekInputRef = useRef<HTMLInputElement | null>(null);

  const seekValue = (duration ? time / (duration / 100) : 0).toFixed(5);

  const setSeekProperty = useCallback((value: number | string) => {
    seekInputRef.current?.style.setProperty('--seek-before-width', `${value}%`);
  }, []);

  useEffect(() => {
    setSeekProperty(seekValue);

    if (seekInputRef.current) {
      seekInputRef.current.value = `${seekValue}`;
    }
  }, [seekValue, setSeekProperty]);

  const handleSeekInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const percentageValue = (Number(e.target.value) / 100) * 100;

      setSeekProperty(percentageValue);

      if (duration) {
        const time = (duration / 100) * percentageValue;

        onSeekChange(time);
      }
    },
    [duration, onSeekChange, setSeekProperty]
  );

  return (
    <input
      aria-label="seek slider"
      type="range"
      min="0"
      step="0.1"
      max="100"
      onChange={handleSeekInputChange}
      className={styles.root}
      ref={seekInputRef}
    />
  );
};
