import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgWarningOutlined = ({ size, title = 'WarningOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 17a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm1-9a1 1 0 10-2 0v4a1 1 0 102 0V8z"
        />
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M20.5 12.25a8.25 8.25 0 11-16.501-.001 8.25 8.25 0 0116.501.001z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgWarningOutlined);
