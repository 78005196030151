/**
 * Check if argument is a function
 *
 * @param {unknown} value
 * @returns {value is Function}
 */

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function => typeof value === 'function';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __TEST__ = process.env.NODE_ENV === 'test';
