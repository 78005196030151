import styled from '../../utils/styled';
import { spacing, palette, variant, themeMode, color, fontSize, lineHeight } from '../../utils/theme';
import { RadioProps } from './types';
import { RadioPrimitive } from './RadioPrimitive';

type StyledRadioControlProps = Partial<RadioProps>;
type StyledRadioLabelProps = Partial<RadioProps>;
type StyledRadioProps = Partial<RadioProps>;

const sizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            width: spacing(4),
            height: spacing(4),
        },
        md: {
            width: spacing(4),
            height: spacing(4),
        },
        lg: {
            width: spacing(6),
            height: spacing(6),
        },
    },
});

export const Control = styled(RadioPrimitive.Control)<StyledRadioControlProps>`
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 100em;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    transition: opacity 200ms ease-in;
    border: 1px solid ${themeMode({ light: palette('neutral.500'), dark: palette('neutral.400') })};
    color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') })};
    ${sizeStyles};
`;

Control.displayName = 'Radio.Control';

const labelSizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            fontSize: 'sm',
            lineHeight: 'xs',
        },
        md: {
            fontSize: 'base',
            lineHeight: 'xs',
        },
        lg: {
            fontSize: 'lg',
            lineHeight: 'md',
        },
    },
});

export const Label = styled(RadioPrimitive.Label)<StyledRadioLabelProps>`
    color: ${({ isDisabled }) => color(isDisabled ? 'text.muted' : 'text.base')};
    user-select: none;
    ${labelSizeStyles};
`;

Label.displayName = 'Radio.Label';

export const Description = styled(RadioPrimitive.Description)`
    font-size: ${fontSize('sm')};
    line-height: ${lineHeight('xs')};
    color: ${color('text.subtle')};
`;

Description.displayName = 'Radio.Description';

export const Radio = styled(RadioPrimitive.Root)<StyledRadioProps>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: ${spacing('xs')};

    &[data-state='checked'] > div:first-of-type {
        &:before {
            content: '';
            width: 50%;
            height: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            border-radius: 100em;
            background-color: ${themeMode({ light: palette('neutral.50'), dark: palette('neutral.900') })};
        }
    }

    &[data-state='checked']:not([data-disabled='true']) > div:first-of-type {
        background-color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') })};
        border-color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') })};

        &:hover {
            background-color: ${themeMode({
                light: palette('neutral.700'),
                dark: palette('neutral.200'),
            })};
        }
    }

    &[data-disabled='true'][data-state='checked'] > div:first-of-type {
        background-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};

        &:hover {
            background-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};
        }
    }

    &[data-disabled='true'] > div:first-of-type {
        border-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.500') })};
    }

    &[data-invalid='true'] > div:first-of-type {
        border-color: ${palette('danger.500')};
    }

    &:hover {
        cursor: pointer;
    }

    &[data-disabled='true'] {
        &:hover {
            cursor: not-allowed;
        }
    }
`;

Radio.displayName = 'Radio';
