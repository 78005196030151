import { FC } from 'react';
import cn from 'classnames';

import styles from './Position.module.scss';

export interface PositionProps {
  position: 'inline' | 'large' | 'left-side-bar' | 'right-side-bar';
}

export const Position: FC<PositionProps> = ({ position, children }) => {
  return (
    <div
      className={cn(styles.root, {
        [styles[`position-${position}`]]: position,
      })}
    >
      {children}
    </div>
  );
};
