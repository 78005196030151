import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { AppLayoutProps } from './AppLayout';

export const AppLayout = styled(Box)<AppLayoutProps>`
    position: relative;
    z-index: 0;
    height: 100%;
    max-height: 100%;
    /* stylelint-disable */
    max-height: -webkit-fill-available;
    width: 100%;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto auto minmax(0, 1fr);
    grid-template-areas: 'banner banner' 'header header' 'sidebar main';
`;

export const Banner = styled(Box)`
    grid-area: banner;
`;

export const Header = styled(Box).attrs({ as: 'header' })`
    grid-area: header;
`;

export const Sidebar = styled(Box).attrs({ as: 'aside' })`
    grid-area: sidebar;
`;

export const Main = styled(Box).attrs({ as: 'main' })`
    grid-area: main;
    overflow: hidden;
    display: flex;

    & > * {
        flex-grow: 1;
    }
`;
