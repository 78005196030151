import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgUpDownOutlined = ({ size, title = 'UpDownOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M6.75 8.493l5.247-5.243L17.25 8.5m0 7.007l-5.247 5.243L6.75 15.5"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);

export default withIcon(SvgUpDownOutlined);
