import { PostNodeFragment } from 'types/generated/graphql';
import { MappedPost } from 'ui';

export const computePostsData = (
  posts?: Array<PostNodeFragment | null> | null
): MappedPost[] => {
  if (posts) {
    const postsData = posts.map((item) => ({
      title: item?.title || '',
      slug: item?.slug || '',
      categoryName: item?.categories?.nodes?.[0]?.name || '',
      categorySlug: item?.categories?.nodes?.[0]?.slug || '',
      video:
        (item?.postFormat === 'video' && item?.postFormatVideo?.videoId) || '',
      image: item?.featuredImage?.node?.sourceUrl || '',
    }));

    return postsData;
  }

  return [];
};
