import { useCallback, useRef, useState } from 'react';
import { useDebouncedCallback } from '../../hooks';
import { useEnhancedEffect } from '../../utils';
import { UseTruncateProps } from './types';

export const middleEllipsis = (parentNode: HTMLElement, childNode: HTMLElement, ellipsis = '...'): void => {
    const childWidth = childNode.offsetWidth;
    const containerWidth = parentNode.offsetWidth;
    const txtWidth = childNode.offsetWidth; // Use childNode for text width
    const targetWidth = childWidth > txtWidth ? childWidth : txtWidth;

    const ellipsisLength = ellipsis.length + 2;

    if (targetWidth > containerWidth) {
        const str = childNode.textContent || '';
        const txtChars = str.length;
        const avgLetterSize = txtWidth / txtChars;
        const canFit = (containerWidth - (targetWidth - txtWidth)) / avgLetterSize;
        const delEachSide = (txtChars - canFit + ellipsisLength) / 2;
        const endLeft = Math.floor(txtChars / 2 - delEachSide);
        const startRight = Math.ceil(txtChars / 2 + delEachSide);

        childNode.setAttribute('data-original', str);
        childNode.textContent = `${str.substring(0, endLeft)}${ellipsis}${str.substring(startRight)}`;
    }
};

export const useTruncate = (props: UseTruncateProps) => {
    const { mode, ellipsis } = props;

    const containerRef = useRef<HTMLElement>();
    const [isReady, setIsReady] = useState(false);

    const truncateText = useCallback(() => {
        const container = containerRef.current;

        if (mode !== 'middle') {
            // When mode is not "middle", allow rendering immediately
            setIsReady(true);
            return;
        }

        if (!container) return;

        const childNode = container?.childNodes[0];

        if (childNode instanceof HTMLElement) {
            if (container?.hasAttribute('title')) {
                // Reset the content to the original text
                childNode.textContent = container?.getAttribute('title');
            }
            middleEllipsis(container, childNode, ellipsis);
            setIsReady(true); // Set isReady to true after truncation
        }
    }, [ellipsis, mode]);

    const debouncedTruncateText = useDebouncedCallback(truncateText, 0);

    useEnhancedEffect(() => {
        if (!containerRef?.current) return;

        // Initially, prevent rendering
        setIsReady(false);

        const resizeObserver = new ResizeObserver(debouncedTruncateText);
        resizeObserver.observe(containerRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [truncateText]);

    return { containerRef, isReady };
};
