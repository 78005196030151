import { FontWeight, FontSize, Breakpoint } from '../types/tokens';
import { stripUnit, themeMode, ZIndicesMapType } from '../utils';
import { css } from '../utils/styled';
import { Breakpoints, Spacing, SpacingOptions } from './types';

const baseFontSize = '15px';

// Container sizes <= 'md' are identical to breakpoint sizes; beyond that, they follow the formula: breakpoint - (2 * 32px);
const containers = {
    xs: '320px',
    sm: '480px',
    ms: '600px',
    md: '768px',
    ml: '960px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1330px',
    jb: '1440px',
};

// Base Unit
const BASE_UNIT = 4;
const BASE_UNIT_REM = 4 / stripUnit(baseFontSize);
const baseUnit = (multiplier: number): string => `${multiplier * BASE_UNIT}px`;

const space = {
    none: baseUnit(0),
    xxs: baseUnit(1),
    xs: baseUnit(2),
    sm: baseUnit(3),
    md: baseUnit(4),
    lg: baseUnit(5),
    xl: baseUnit(6),
    xxl: baseUnit(9),
    jb: baseUnit(12),
    mg: baseUnit(18),
};

const spacing = (amount: Spacing, options?: SpacingOptions): string | number => {
    const unitless = options && options.unitless;

    if (amount in space) {
        return unitless ? stripUnit(space[amount]) : space[amount];
    }

    if (amount === 'auto') {
        return amount;
    }

    const value = amount as number;
    return unitless ? stripUnit(baseUnit(value)) : baseUnit(value);
};

// Font Family
const fontFamily = {
    base: `'Sailec', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
};

// Font Sizes
const fontSizes: { [key in FontSize]: string } = {
    xs: '0.7333334rem',
    sm: '0.8666667rem',
    base: '1rem',
    md: '1rem',
    lg: '1.2rem',
    xl: '1.6rem',
    xxl: '2rem',
    xxxl: '2.4rem',
    jb: '3.2rem',
    mg: '4rem',
};

// Font weights
const fontWeights: { [key in FontWeight]: number } = {
    light: 300,
    regular: 400,
    bold: 700,
    extrabold: 900,
};

// Line heights
const lineHeights: { [key in FontSize]: string } = {
    xs: baseUnit(4),
    sm: baseUnit(5),
    base: baseUnit(6),
    md: baseUnit(6),
    lg: baseUnit(7),
    xl: baseUnit(9),
    xxl: baseUnit(10),
    xxxl: baseUnit(13),
    jb: baseUnit(15),
    mg: baseUnit(16),
};

// Breakpoints
const breakpoints = [
    `${Breakpoints.xs}px`,
    `${Breakpoints.sm}px`,
    `${Breakpoints.ms}px`,
    `${Breakpoints.md}px`,
    `${Breakpoints.ml}px`,
    `${Breakpoints.lg}px`,
    `${Breakpoints.xl}px`,
    `${Breakpoints.xxl}px`,
    `${Breakpoints.jb}px`,
];

breakpoints['xs'] = breakpoints[0];
breakpoints['sm'] = breakpoints[1];
breakpoints['ms'] = breakpoints[2];
breakpoints['md'] = breakpoints[3];
breakpoints['ml'] = breakpoints[4];
breakpoints['lg'] = breakpoints[5];
breakpoints['xl'] = breakpoints[6];
breakpoints['xxl'] = breakpoints[7];
breakpoints['jb'] = breakpoints[8];

// Breakpoint Function
const breakpoint =
    (breakpoint: Breakpoint = 'md', direction: 'down' | 'up' = 'down') =>
    (literals: TemplateStringsArray, ...args: any[]): any =>
        css`
            @media (${direction === 'down' ? 'max' : 'min'}-width: ${breakpoints[breakpoint]}) {
                ${css(literals, ...args)}
            }
        `;

const radii = {
    sm: '2px',
    base: '4px',
    lg: '8px',
    round: '100rem',
};

const palette = {
    primary: {
        300: '#84dfdf',
        400: '#5ad5d5',
        500: '#32cbcb',
        600: '#2db6b6',
        700: '#28a2a2',
    },
    secondary: {
        300: '#3c6ed7',
        400: '#245dd2',
        500: '#0c4bce',
        600: '#0a43b9',
        700: '#093ca4',
    },
    neutral: {
        0: '#ffffff',
        50: '#f9fafb',
        100: '#f4f6f8',
        200: '#dfe3e9',
        300: '#c5cdd5',
        400: '#adb7bf',
        500: '#6c7782',
        600: '#5a6774',
        700: '#435261',
        800: '#2c3c4d',
        900: '#142739',
        1000: '#0c1b2b',
    },
    danger: {
        300: '#ea5b70',
        400: '#e7435c',
        500: '#e11534',
        600: '#ca122e',
        700: '#b41029',
    },
    warning: {
        300: '#f6e07f',
        400: '#f3d44c',
        500: '#eec200',
        600: '#eab102',
        700: '#dba602',
    },
    success: {
        300: '#84dfdf',
        400: '#5ad5d5',
        500: '#32cbcb',
        600: '#2db6b6',
        700: '#28a2a2',
    },
    facebook: { 500: '#4267b2', 700: '#35528e' },
    twitter: { 500: '#00aced', 700: '#008abe' },
    bronze: { 300: '#BF8A59', 400: '#A66F40', 500: '#875733', 600: '#5E3F22', 700: '#3A2912' },
    silver: { 300: '#B7BDC8', 400: '#9CA4AF', 500: '#7D8794', 600: '#68707E', 700: '#505663' },
    gold: { 300: '#BFB58B', 400: '#AD9E6C', 500: '#A3905B', 600: '#74683F', 700: '#534D2C' },
    platinum: {
        0: '#ffffff',
        50: '#f9fafb',
        100: '#f4f6f8',
        200: '#dfe3e9',
        300: '#c5cdd5',
        400: '#adb7bf',
        500: '#6c7782',
        600: '#5a6774',
        700: '#435261',
        800: '#2c3c4d',
        900: '#142739',
        1000: '#0c1b2b',
    },
};

const borders = {
    base: themeMode({
        light: `1px solid ${palette.neutral[300]}`,
        dark: `1px solid ${palette.neutral[600]}`,
    }),
    hover: themeMode({
        light: `1px solid ${palette.neutral[500]}`,
        dark: `1px solid ${palette.neutral[400]}`,
    }),
    subtle: themeMode({
        light: `1px solid ${palette.neutral[200]}`,
        dark: `1px solid ${palette.neutral[700]}`,
    }),
};

const colors = {
    text: {
        base: themeMode({
            light: palette.neutral[900],
            dark: palette.neutral[50],
        }),
        // @deprecated: use `accent` instead
        high: themeMode({
            light: palette.primary[500],
            dark: palette.primary[500],
        }),
        accent: themeMode({
            light: palette.primary[500],
            dark: palette.primary[500],
        }),
        // @deprecated: use `subtle` instead
        low: themeMode({
            light: palette.neutral[500],
            dark: palette.neutral[400],
        }),
        subtle: themeMode({
            light: palette.neutral[500],
            dark: palette.neutral[400],
        }),
        muted: themeMode({
            light: palette.neutral[400],
            dark: palette.neutral[500],
        }),
        inverted: themeMode({
            light: palette.neutral[0],
            dark: palette.neutral[1000],
        }),
    },
    background: {
        base: themeMode({
            light: palette.neutral[0],
            dark: palette.neutral[1000],
        }),
        high: palette.danger[500],
        low: palette.danger[500],
    },
    border: {
        base: palette.neutral[200],
        high: palette.danger[500],
        low: palette.danger[500],
    },
    surface: themeMode({
        light: palette.neutral[0],
        dark: palette.neutral[800],
    }),
    ...palette,
};

const shadows = {
    0: 'none',
    1: '0 2px 4px 0 rgba(0, 0, 0, .04), 0 3px 4px 0 rgba(0, 0, 0, .04), 0 1px 5px 0 rgba(0, 0, 0, .08)',
    2: '0 6px 10px 0 rgba(0, 0, 0, .04), 0 1px 18px 0 rgba(0, 0, 0, .04), 0 3px 5px 0 rgba(0, 0, 0, .08)',
    3: '0 12px 17px 2px rgba(0, 0, 0, .04), 0 5px 22px 4px rgba(0, 0, 0, .04), 0 7px 8px 0 rgba(0, 0, 0, .08)',
};

const components = {};

const modes = ['light', 'dark'];
const defaultMode = modes[1];

const zIndices: ZIndicesMapType = {
    absoluteTop: 2147483638,
    alert: 4000,
    modal: 3000,
    overlay: 2000,
    subOverlay: 1000,
    base: 0,
};

const opacities = {
    invisible: 0.0,
    transparent: 0.16,
    shadow: 0.32,
    subtle: 0.48,
    translucent: 0.64,
    overlay: 0.8,
    opaque: 1,
};

export const defaultTheme = {
    BASE_UNIT,
    BASE_UNIT_REM,
    baseUnit,
    containers,
    spacing,
    space,
    palette, // TODO: [DS-997] remove this once Samples is updated to new tokens
    colors,
    fontFamily,
    fontSizes,
    fontWeights,
    baseFontSize,
    lineHeights,
    breakpoints,
    breakpoint,
    borders,
    radii,
    shadows,
    components,
    modes,
    defaultMode,
    zIndices,
    opacities,
};

type ThemeWithMode = {
    mode?: 'light' | 'dark';
};

export type DefaultTheme = typeof defaultTheme & ThemeWithMode;
