import React from 'react';
import { isFunction } from '../../utils';
import { CloseOutlined, SearchOutlined } from '../../assets/icons';
import { forwardRef } from '../../system';
import { Input } from '../Input';
import { InputGroup } from '../InputGroup';
import { SearchInputProps } from './types';

export const SearchInput = forwardRef<SearchInputProps, 'input'>((props, ref) => {
    const { type = 'search', shape, onReset, size, ...rest } = props;

    const shouldShowReset = !!props.value && isFunction(onReset);

    return (
        <InputGroup>
            {props.value ? (
                <InputGroup.IconButton
                    label="Search"
                    placement="left"
                    variant="subtle"
                    kind="minimal"
                    type="submit"
                    size={size}
                    icon={SearchOutlined}
                    isRounded={shape === 'rounded'}
                />
            ) : (
                <InputGroup.Icon placement="left">
                    <SearchOutlined />
                </InputGroup.Icon>
            )}
            <Input ref={ref} type={type} shape={shape} size={size} {...rest} />
            {shouldShowReset && (
                <InputGroup.IconButton
                    label="Clear"
                    placement="right"
                    variant="subtle"
                    kind="minimal"
                    size={size}
                    icon={CloseOutlined}
                    isRounded={shape === 'rounded'}
                    onClick={onReset}
                />
            )}
        </InputGroup>
    );
});

SearchInput.displayName = 'SearchInput';
