import { CSSObject } from 'styled-components';
import styled, { css } from '../../utils/styled';
import { Box } from '../../primitives';
import { color, themeMode, variant } from './../../utils/theme';
import { InputProps, StyledInputProps } from './types';

export const InputDefaultStyles: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 25%',
    zIndex: 1,
    position: 'relative',
    lineHeight: 'normal',
    width: '100%',
    minWidth: '75px',
    color: 'inherit',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    appearance: 'none',
    filter: 'none', // removes yellow autocomplete text color from Firefox
    '&:focus': {
        outline: 'none',
    },
    [`&::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration`]: {
        // clears the 'X' from Chrome
        display: 'none',
    },
    [`&:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active`]: {
        // removes the blue background with autocomplete on Chrome by delaying the transition
        transition: 'background-color 99999s ease-in-out 99999s, color 99999s ease-in-out 99999s',
    },
};

export const Input = styled(Box)<InputProps>`
    ${InputDefaultStyles};
    ${({ isMultiline, size }) => isMultiline && !!size && inputSizes};
    &:disabled {
        color: ${color('neutral.400')};
        -webkit-text-fill-color: ${color('neutral.400')};
    }
    @media screen and (pointer: coarse) {
        font-size: 16px;
    }
`;

Input.defaultProps = { as: 'input' };

Input.displayName = 'Styled.Input';

export const Addon = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flex: '0 1 auto',
    overflow: 'hidden',
    zIndex: 2,
});

export const inputSizes = variant({
    prop: 'size',
    compoundProp: 'shape',
    variants: {
        sm: {
            fontSize: 'sm',
            height: '36px',
            minHeight: '36px',
            [`& > ${Input}`]: {
                px: 'sm',
            },
            rounded: {
                [`& > ${Input}`]: {
                    px: 'md',
                },
            },
        },
        md: {
            fontSize: 'md',
            height: '48px', // TODO: [DS-994] find a way to make height work with tokens
            minHeight: '48px',
            [`& > ${Input}`]: {
                px: 'md',
            },
            rounded: {
                [`& > ${Input}`]: {
                    px: 'lg',
                },
            },
        },
        lg: {
            fontSize: 'lg',
            height: '64px', // TODO: [DS-994] find a way to make height work with tokens
            minHeight: '64px',
            [`& > ${Input}`]: {
                px: 'lg',
            },
            rounded: {
                [`& > ${Input}`]: {
                    px: 'xl',
                },
            },
        },
    },
});

export const inputShapes = variant({
    prop: 'shape',
    variants: {
        default: {
            borderRadius: 'sm',
        },
        rounded: {
            borderRadius: 'round',
        },
    },
});

export const inputVariants = variant({
    prop: 'variant',
    variants: {
        outlined: themeMode({
            dark: {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'neutral.600',
                backgroundColor: 'transparent',
                color: 'neutral.50',
                position: 'relative',

                'input::placeholder': {
                    color: 'neutral.400',
                },

                '&:hover': {
                    borderColor: 'neutral.400',
                },

                '&:focus-within': {
                    borderColor: 'neutral.50',
                },

                isInvalid: {
                    borderColor: 'danger.500',
                },

                isDisabled: {
                    color: 'neutral.400',
                    borderColor: 'transparent',
                    backgroundColor: 'neutral.800',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
            light: {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'neutral.300',
                backgroundColor: 'neutral.0',
                color: 'neutral.900',

                'input::placeholder': {
                    color: 'neutral.400',
                },

                '&:hover': {
                    borderColor: 'neutral.600',
                },

                '&:focus-within': {
                    borderColor: 'neutral.900',
                },

                isInvalid: {
                    borderColor: 'danger.500',
                },

                isDisabled: {
                    color: 'neutral.400',
                    borderColor: 'transparent',
                    backgroundColor: 'neutral.100',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
        }),
        filled: themeMode({
            dark: {
                backgroundColor: 'neutral.800',
                color: 'neutral.50',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'transparent',

                'input::placeholder': {
                    color: 'neutral.400',
                },

                isInvalid: {
                    borderColor: 'danger.500',
                },

                isDisabled: {
                    color: 'neutral.400',
                    borderColor: 'transparent',
                    backgroundColor: 'neutral.800',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
            light: {
                backgroundColor: 'neutral.100',
                color: 'neutral.900',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'transparent',

                'input::placeholder': {
                    color: 'neutral.400',
                },

                isInvalid: {
                    borderColor: 'danger.500',
                },

                isDisabled: {
                    color: 'neutral.400',
                    borderColor: 'transparent',
                    backgroundColor: 'neutral.100',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
        }),
        ghost: themeMode({
            dark: {
                backgroundColor: 'transparent',
                border: '1px solid transparent',
                color: 'neutral.50',
                padding: 0,

                'input::placeholder': {
                    color: 'neutral.400',
                },

                isInvalid: {
                    borderBottomColor: 'danger.500',
                },
                isDisabled: {
                    color: 'neutral.400',
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
            light: {
                backgroundColor: 'transparent',
                border: '1px solid transparent',
                color: 'neutral.900',
                padding: 0,

                'input::placeholder': {
                    color: 'neutral.500',
                },

                isInvalid: {
                    color: 'danger.500',
                },

                isDisabled: {
                    color: 'neutral.400',
                    borderColor: 'transparent',
                    '&:hover': {
                        borderColor: 'transparent',
                    },
                },
            },
        }),
    },
});

export const Wrapper = styled(Box)<StyledInputProps>(
    {
        display: 'flex',
        justifyItems: 'center',
        alignItem: 'center',
        position: 'relative',
        transition: 'border-color 200ms ease-in, box-shadow 200ms ease-in',
    },
    css`
        &:focus-within {
            box-shadow: ${themeMode({ dark: color('neutral.50'), light: color('neutral.900') })} 0px 0px 0px 1px;
        }
    `,
    inputSizes,
    inputShapes,
    inputVariants,
    ({ isMultiline }) =>
        isMultiline && {
            alignItems: 'center',
            flexWrap: 'wrap',
            minHeight: '48px',
            height: 'auto',
        },
);

Wrapper.displayName = 'Styled.InputWrapper';
