import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgEditOutlined = ({ size, title = 'EditOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path d="M16.43 3.711a1 1 0 011.42-.004l2.12 2.122a.997.997 0 01-.004 1.418L9.024 18.189l-4.256.72.72-4.256L16.432 3.711z" />
            <path strokeLinecap="square" d="M15.34 6.34l2 2m-11 7l2 2" />
        </g>
    </svg>
);

export default withIcon(SvgEditOutlined);
