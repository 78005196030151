import styled, { css } from '../../utils/styled';
import { MaestroStyledComponent, palette, color, fontSize, spacing, fontWeight } from '../../utils';
import { Box } from '../../primitives/Box';
import { LabelProps, StyledValidationMessageProps, HelpTextProps, FormControlProps } from './types';

export const Label: MaestroStyledComponent<LabelProps> = styled(Box).attrs(() => ({ as: 'label' }))<LabelProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: ${fontWeight('bold')};
    margin-bottom: ${spacing('xs')};
    color: ${color(`text.base`)};
`;

export const Description: MaestroStyledComponent = styled(Box)`
    margin-bottom: ${spacing('xs')};
    margin-top: -${spacing('xs')};
`;

export const ValidationMessage: MaestroStyledComponent<StyledValidationMessageProps> = styled(
    Box,
)<StyledValidationMessageProps>`
    font-size: ${fontSize('sm')};
    margin-top: ${spacing('xs')};
    margin-bottom: ${spacing('xs')};

    ${({ isInvalid }): any =>
        isInvalid &&
        css`
            color: ${palette('danger.500')};
        `}

    ${({ isValid }): any =>
        isValid &&
        css`
            color: ${palette('success.500')};
        `}
`;

export const HelpText: MaestroStyledComponent<HelpTextProps> = styled(Box)<HelpTextProps>`
    color: ${color('text.low')};
    font-size: ${fontSize('sm')};
    margin-top: ${spacing('xs')};
    margin-bottom: ${spacing('xs')};
`;

export const FormControl: MaestroStyledComponent<FormControlProps> = styled(Box)<FormControlProps>`
    width: 100%;

    & + & {
        margin-top: ${spacing('sm')};
    }
`;
