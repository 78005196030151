import React from 'react';
import { IconButton } from '../IconButton';
import { CloseOutlined } from '../../assets/icons';
import { forwardRef } from '../../system';
import { DismissButtonProps } from './types';

export const DismissButton = forwardRef<DismissButtonProps, 'button'>(
    ({ label = '', hideTooltip = true, ...props }, ref) => (
        <IconButton ref={ref} icon={CloseOutlined} label={label} hideTooltip={hideTooltip} {...props} />
    ),
);

DismissButton.displayName = 'DismissButton';
