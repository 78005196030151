import parse from 'html-react-parser';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { getOptions } from './helper';

interface ParagraphProps {
  innerHtml: string;
}

export const Paragraph: FC<ParagraphProps> = ({ innerHtml }) => {
  const { asPath } = useRouter();

  return <>{parse(innerHtml, getOptions(asPath))}</>;
};
