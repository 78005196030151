import styled from 'styled-components';
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Box } from '../../primitives/Box';
import { useOnClickOutside } from '../../hooks';
import { TableSemanticContext } from './TableSemanticContext';
import { TableBodyProps } from './types';
import { TableContext } from './TableContext';

const Body = styled(Box)<TableBodyProps>`
    display: table-row-group;
`;

const TableBody: React.FC<TableBodyProps> = ({ children, rowsCount, ...props }) => {
    // CONTEXT
    const { setBodyNode, dispatchRowChangedEvent, onRowChange, listenKeyEvents, focusedRowIndex } =
        useContext(TableContext);

    // REF
    const tableRef = useRef<HTMLElement | null>(null);

    // STATE
    const [childrenCount, setChildrenCount] = useState(rowsCount || 0);

    // CALLBACK
    const handleRowChange = useCallback(
        (newRowIndex: number, event?: MouseEvent | KeyboardEvent): void => {
            event?.preventDefault();

            dispatchRowChangedEvent(newRowIndex, event?.type === 'keydown');

            onRowChange && onRowChange(newRowIndex, event);
        },
        [onRowChange, dispatchRowChangedEvent],
    );

    const handleKeyDown = useCallback(
        (event: KeyboardEvent): void => {
            let newRowIndex = -1;
            switch (event.key) {
                case 'ArrowDown':
                    // Increment focusedRowIndex
                    newRowIndex = Math.min(focusedRowIndex + 1, childrenCount);
                    handleRowChange(newRowIndex, event);
                    break;
                case 'ArrowUp':
                    // Decrement focusedRowIndex
                    newRowIndex = Math.max(focusedRowIndex - 1, -1);
                    handleRowChange(newRowIndex, event);
                    break;
                default:
                    // Do nothing
                    break;
            }
        },
        [childrenCount, focusedRowIndex, handleRowChange],
    );

    const handleClickOutside = useCallback(() => {
        // Reset selection when user click outside of the table
        handleRowChange(-1);
    }, [handleRowChange]);

    // EFFECT
    useEffect(() => {
        if (children) {
            setChildrenCount(rowsCount || React.Children.count(children));
        }
    }, [children, rowsCount]);

    useEffect(() => {
        if (listenKeyEvents) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return (): void => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [listenKeyEvents, handleKeyDown]);

    useOnClickOutside(tableRef, handleClickOutside);

    useEffect(() => {
        setBodyNode(tableRef.current);
    }, [setBodyNode]);

    return (
        <TableSemanticContext.Provider value={{ location: 'body' }}>
            <Body ref={tableRef} {...props}>
                {children}
            </Body>
        </TableSemanticContext.Provider>
    );
};

export { TableBody };
