import { useQuery } from '@apollo/client';
import { GET_REDIRECTS } from 'api/redirects/queries';
import { GetRedirectsQuery } from 'types/generated/graphql';

export const useGetRedirects = () => {
  const { data } = useQuery<GetRedirectsQuery>(GET_REDIRECTS, {
    errorPolicy: 'all',
  });

  return data;
};
