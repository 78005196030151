import React from 'react';
import { runIfFn } from '../../utils';
import { NavBarMenuProps } from './types';
import { useNavBarContext } from './NavBarContext';
import * as Styled from './styled';

export const NavBarMenu: React.FC<NavBarMenuProps> = ({ justify, children }) => {
    const { isMobile } = useNavBarContext();

    return isMobile ? (
        runIfFn(children, { isMobile })
    ) : (
        <Styled.Menu justify={justify}>{runIfFn(children, { isMobile })}</Styled.Menu>
    );
};

NavBarMenu.displayName = 'NavBar.Menu';
