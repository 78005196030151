import styled, { css } from '../../utils/styled';
import { MaestroStyledComponent, variant, themeMode } from '../../utils';
import {
    StarFilled as StarFilledIcon,
    StarOutlined as StarOutlinedIcon,
    StarHalfFilled as StarHalfFilledIcon,
} from '../../assets/icons';
import { StarRatingProps, StyledIconProps } from './types';

const filledStyles = variant({
    prop: 'variant',
    variants: {
        default: {
            path: themeMode({
                dark: { fill: 'neutral.50', stroke: 'neutral.50' },
                light: { fill: 'neutral.900', stroke: 'neutral.900' },
            }),
        },
        ghost: {
            path: themeMode({
                dark: { fill: 'neutral.700', stroke: 'neutral.700' },
                light: { fill: 'neutral.200', stroke: 'neutral.200' },
            }),
        },
    },
});

const outlinedStyles = variant({
    prop: 'variant',
    variants: {
        default: {
            path: themeMode({
                dark: { stroke: 'neutral.50' },
                light: { stroke: 'neutral.900' },
            }),
        },
        ghost: {
            path: themeMode({
                dark: { stroke: 'neutral.700' },
                light: { stroke: 'neutral.200' },
            }),
        },
    },
});

const halfFilledStyles = variant({
    prop: 'variant',
    variants: {
        default: {
            'path:first-of-type': themeMode({
                dark: { stroke: 'neutral.50' },
                light: { stroke: 'neutral.900' },
            }),
            'path:last-of-type': themeMode({
                dark: { fill: 'neutral.50' },
                light: { fill: 'neutral.900' },
            }),
        },
        ghost: {
            'path:first-of-type': themeMode({
                dark: { stroke: 'neutral.700' },
                light: { stroke: 'neutral.200' },
            }),
            'path:last-of-type': themeMode({
                dark: { fill: 'neutral.700' },
                light: { fill: 'neutral.200' },
            }),
        },
    },
});

export const StarFilled = styled(StarFilledIcon)<StyledIconProps>`
    ${filledStyles}
`;

export const StarOutlined = styled(StarOutlinedIcon)<StyledIconProps>`
    ${outlinedStyles}
`;

export const StarHalfFilled = styled(StarHalfFilledIcon)<StyledIconProps>`
    ${halfFilledStyles}
`;

export const StarRating: MaestroStyledComponent<StarRatingProps> = styled.div<StarRatingProps>`
    color: ${themeMode({ dark: 'neutral.50', light: 'neutral.900' })};
    ${({ type }) =>
        type === 'numeric' &&
        css`
            display: flex;
            align-items: flex-end;
        `};
    svg {
        ${({ isActive }) => `cursor: ${isActive ? 'pointer' : 'default'}`}
    }
`;
