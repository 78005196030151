import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgAddReleaseOutlined = ({ size, title = 'AddReleaseOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <circle cx={12.25} cy={12.25} r={2.25} />
            <path
                strokeLinecap="round"
                d="M12.25 20.5h0C7.694 20.5 4 16.797 4 12.25h0C4 7.694 7.703 4 12.25 4h0c4.556 0 8.25 3.703 8.25 8.25h0m-3 8.25v-6m-3 3h6"
            />
        </g>
    </svg>
);

export default withIcon(SvgAddReleaseOutlined);
