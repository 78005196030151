import React, { JSXElementConstructor } from 'react';

/**
 * Validate if a React element is of the accepted type
 * @param element
 * The node to validate
 * @param type
 * The component that should be check against
 */

export const isComponentType = (
    element: React.ReactElement,
    type: JSXElementConstructor<any> | JSXElementConstructor<any>[],
): boolean => element.type === type;
