import { useCallback, useMemo } from 'react';
import { useControlled } from '../../hooks';
import { getPositionFromValue, getValueFromPosition } from './helpers';
import { SliderProps, Range } from './types';

type UseSliderProps = SliderProps;

type UseSlider = {
    positions: Range;
    updateMinFromPosition: (position: number) => void;
    updateMaxFromPosition: (position: number) => void;
    onMinChangeEnd: (position: number) => void;
    onMaxChangeEnd: (position: number) => void;
    minPosition: number;
    maxPosition: number;
};

export const useSlider = (props: UseSliderProps): UseSlider => {
    const { min, max, width = 300, onChange, onChangeEnd, defaultValue, value } = props;
    const [currentValue, updateValue] = useControlled({
        defaultValue: defaultValue ?? { min, max },
        value: value,
        onChange,
    });

    const positions = useMemo(
        () => ({
            min: getPositionFromValue(currentValue?.min ?? min, min, max, width),
            max: getPositionFromValue(currentValue?.max ?? max, min, max, width),
        }),
        [currentValue, min, max, width],
    );

    const updateMinFromPosition = useCallback(
        (position: number) => {
            const minPosition = getValueFromPosition(position, min, max, width);
            updateValue({ min: minPosition, max: currentValue?.max ?? max });
        },
        [min, max, currentValue, updateValue, width],
    );

    const updateMaxFromPosition = useCallback(
        (position: number) => {
            const maxPosition = getValueFromPosition(position, min, max, width);
            updateValue({ min: currentValue?.min ?? min, max: maxPosition });
        },
        [min, max, currentValue, updateValue, width],
    );

    const onMinChangeEnd = useCallback(
        (position: number) => {
            onChangeEnd?.({ min: getValueFromPosition(position, min, max, width), max: currentValue?.max ?? max });
        },
        [currentValue, min, max, width, onChangeEnd],
    );

    const onMaxChangeEnd = useCallback(
        (position: number) => {
            onChangeEnd?.({ min: currentValue?.min ?? min, max: getValueFromPosition(position, min, max, width) });
        },
        [currentValue, min, max, width, onChangeEnd],
    );

    const minPosition = useMemo(() => getPositionFromValue(min, min, max, width), [min, max, width]);
    const maxPosition = useMemo(() => getPositionFromValue(max, min, max, width), [min, max, width]);

    return {
        positions,
        minPosition,
        maxPosition,
        updateMinFromPosition,
        updateMaxFromPosition,
        onMinChangeEnd,
        onMaxChangeEnd,
    };
};
