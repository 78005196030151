import React, { JSXElementConstructor } from 'react';
import { flattenChildren } from '../flattenChildren';
import { isComponentType } from '../isComponentType';

type Props = Record<string, any>;

/**
 * Filter children to only returns accepted component type
 * @param children
 * React children to filter
 * @param type
 * The component or array of components that should be check against
 */

export const filterChildrenByType = <T extends Props>(
    children: React.ReactNode,
    type: JSXElementConstructor<T> | JSXElementConstructor<T>[],
): React.ReactElement<T>[] =>
    (flattenChildren(children) as React.ReactElement[]).filter((item) =>
        Array.isArray(type) ? type.some((component) => component === item.type) : isComponentType(item, type),
    );
