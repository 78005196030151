import React, { useRef } from 'react';
import { MeatballsFilled } from '../../assets/icons';
import { flattenChildren } from '../../utils';
import { useNavContext } from './NavContext';
import { useOverflowNav } from './useOverflowNav';
import { NavDropdown, NavDropdownTrigger, NavDropdownContent, NavDropdownItem } from './NavDropdown';
import * as Styled from './styled';

type NavOverflowProps = {
    children?: React.ReactNode;
};

export const NavOverflow = (props: NavOverflowProps) => {
    const { children } = props;
    const navRef = useRef<HTMLDivElement>(null);
    const overflowItems = useOverflowNav({ containerRef: navRef });
    const childrenArray = flattenChildren(children).map(
        (child, index) =>
            React.isValidElement(child) && React.cloneElement(child, { 'data-item-id': `maestro-nav-item-${index}` }),
    );

    const { isVertical, variant } = useNavContext();
    return (
        <>
            {childrenArray.map(
                (child, index) =>
                    React.isValidElement(child) &&
                    !overflowItems.includes(child.props['data-item-id']) &&
                    React.cloneElement(child),
            )}
            {!!overflowItems.length && (
                <NavDropdown placement="bottom-end">
                    <NavDropdownTrigger>
                        <MeatballsFilled />
                    </NavDropdownTrigger>
                    <NavDropdownContent>
                        {React.Children.map(
                            childrenArray,
                            (child) =>
                                React.isValidElement(child) &&
                                overflowItems.includes(child.props['data-item-id']) && (
                                    <NavDropdownItem {...child.props} />
                                ),
                        )}
                    </NavDropdownContent>
                </NavDropdown>
            )}
            {!isVertical && (
                <Styled.NavList
                    variant={variant}
                    isVertical={isVertical}
                    ref={navRef}
                    style={{
                        overflow: 'hidden',
                        visibility: 'hidden',
                        pointerEvents: 'none',
                        inset: '0',
                        width: '100%',
                        position: 'absolute',
                        zIndex: '-1',
                    }}
                    aria-hidden="true"
                >
                    {childrenArray.map(
                        (child) =>
                            React.isValidElement(child) &&
                            React.cloneElement(child, {
                                tabIndex: -1,
                                as: 'div',
                            }),
                    )}
                </Styled.NavList>
            )}
        </>
    );
};
