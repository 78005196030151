import React, { useRef, useState, useEffect } from 'react';
import { useUpdateEffect } from '../../hooks';
import { getClonedChild, isBrowser, useEnhancedEffect } from '../../utils';
import { BaseCollapseProps } from './types';
import * as Styled from './styled';
import { getContentHeight } from './helpers';

// BaseCollapse - relies on props to provide open state and toggle controls

export const BaseCollapse: React.FC<BaseCollapseProps> = ({
    children,
    isOpen,
    onOpen,
    onClose,
    onToggle,
    variant = 'default',
    className,
    hasTitleClick = true,
    ...props
}) => {
    const [hasTitleHover, setHasTitleHover] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const [dimensions, setDimensions] = useState({
        height: isBrowser ? window.innerHeight : 0,
        width: isBrowser ? window.innerWidth : 0,
    });

    const contentRef = useRef<HTMLDivElement>(null);

    const childrenArray = children ? React.Children.toArray(children) : [];

    const title = getClonedChild(childrenArray, 'Collapse.Title', {
        variant: variant,
        hasTitleHover: hasTitleHover,
        isOpen: isOpen,
        ...props,
    });

    const trigger = getClonedChild(childrenArray, 'Collapse.Trigger', {
        onClick: onToggle,
        ...props,
    });

    const content = getClonedChild(childrenArray, 'Collapse.Content', {
        ref: contentRef,
    });

    useEffect(() => {
        const handleResize = (): void => {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useUpdateEffect(() => {
        isOpen ? onOpen?.() : onClose?.();
    }, [isOpen, onClose, onOpen]);

    // Content height is passed to the content wrapper for smooth opening
    // animations.
    useEnhancedEffect(() => {
        setContentHeight(getContentHeight(contentRef));
    }, [content, dimensions]);

    // We want hover styling only when the title section is hovered, not the
    // content section.
    const activateTitleHover = (): void => setHasTitleHover(true);
    const deactivateTitleHover = (): void => setHasTitleHover(false);

    return (
        <Styled.Collapse hasTitleHover={hasTitleHover} className={className}>
            <Styled.CollapseTitle
                aria-expanded={isOpen}
                onClick={hasTitleClick ? onToggle : undefined}
                onMouseEnter={hasTitleClick ? activateTitleHover : undefined}
                onMouseLeave={hasTitleClick ? deactivateTitleHover : undefined}
                hasTitleHover={hasTitleClick}
                isOpen={isOpen}
                {...props}
            >
                {title}
                {trigger ? (
                    trigger
                ) : (
                    <Styled.CollapseIcon
                        isOpen={isOpen}
                        hasTitleHover={hasTitleHover}
                        hasTitleClick={hasTitleClick}
                        variant={variant}
                        size="md"
                        onClick={!hasTitleClick ? onToggle : undefined}
                    />
                )}
            </Styled.CollapseTitle>
            <Styled.CollapseContentWrapper isOpen={isOpen} contentHeight={contentHeight}>
                {content}
            </Styled.CollapseContentWrapper>
        </Styled.Collapse>
    );
};
