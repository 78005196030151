import React from 'react';
import { Box, ConditionalWrapper } from '../../primitives';
import { Flex } from '../Flex';
import { List } from '../List';
import { Popover, PopoverProps } from '../Popover';
import { Avatar } from '../Avatar';
import { Tooltip } from '../Tooltip';
import { Button, ButtonProps } from '../Button';
import { Scrollable } from '../Scrollable';
import { forwardRef } from '../../system';
import { filterChildrenByType, runIfFn } from '../../utils';
import { AvatarGroupProps, CompoundAvatarProps } from './types';
import * as Styled from './styled';

const AvatarComponent = forwardRef<CompoundAvatarProps, 'div'>((props, ref) => {
    const {
        src,
        fallback,
        size,
        alt,
        name,
        wrapper: WrapperComponent = React.Fragment,
        wrapperProps,
        hasTooltip = true,
        ...rest
    } = props;
    return (
        <Styled.AvatarWrapper ref={ref} size={size} {...rest}>
            <WrapperComponent {...wrapperProps}>
                <ConditionalWrapper
                    condition={hasTooltip}
                    wrapper={(children): React.ReactNode => (
                        <Tooltip position="top" content={name ? name : fallback ? fallback : ''}>
                            {children}
                        </Tooltip>
                    )}
                >
                    <Styled.Avatar src={src} fallback={fallback} size={size} alt={alt} />
                </ConditionalWrapper>
            </WrapperComponent>
        </Styled.AvatarWrapper>
    );
});

AvatarComponent.displayName = 'AvatarGroup.Avatar';

const popoverPropsDefault: Partial<PopoverProps> = {
    placement: 'bottom-end',
    minWidth: '240px',
    maxWidth: '360px',
    maxHeight: '360px',
    overflow: 'hidden',
    display: 'flex',
};

const AvatarGroupComponent = forwardRef<AvatarGroupProps, 'div'>((props, ref) => {
    const {
        children,
        size = 'md',
        limit = 4,
        total,
        hasPopover = false,
        onTriggerClick,
        popoverProps: popoverPropsProp = {},
        popoverContent,
        ...rest
    } = props;

    const avatars = filterChildrenByType(children, AvatarComponent);

    const visibleAvatars = avatars
        .slice(0, limit)
        .map((child, index) => React.cloneElement(child, { size, key: `avatar-${index}`, zIndex: limit - index }));

    const extraAvatars = avatars.slice(limit).map((child, index) => {
        const { name, ...rest } = child.props;
        return (
            <List.Item key={`list-item-${index}`}>
                <List.ItemIcon>
                    <Avatar {...rest} />
                </List.ItemIcon>
                {name}
            </List.Item>
        );
    });

    const nbOfExtraAvatars = avatars.length > limit ? avatars.length - limit : 0;
    const truncatedAvatars = total
        ? Math.max(nbOfExtraAvatars, total - Math.min(avatars.length, limit))
        : nbOfExtraAvatars;

    const popoverProps = {
        ...popoverPropsDefault,
        ...popoverPropsProp,
    };

    return (
        <Flex ref={ref} {...rest}>
            {visibleAvatars}
            {!!truncatedAvatars && (
                <Box ml="xxs">
                    {hasPopover ? (
                        <Popover {...popoverProps}>
                            {({ close }) => (
                                <>
                                    <Popover.Trigger>
                                        <Button
                                            variant="ghost"
                                            size={size as ButtonProps['size']}
                                            isRounded
                                        >{`+${truncatedAvatars}`}</Button>
                                    </Popover.Trigger>
                                    <Popover.Content p={0}>
                                        {popoverContent ? (
                                            runIfFn(popoverContent, { close })
                                        ) : (
                                            <Scrollable>
                                                <List py="xs">{extraAvatars}</List>
                                            </Scrollable>
                                        )}
                                    </Popover.Content>
                                </>
                            )}
                        </Popover>
                    ) : (
                        <Button
                            as="span"
                            variant="ghost"
                            size={size as ButtonProps['size']}
                            isRounded
                            onClick={onTriggerClick}
                        >{`+${truncatedAvatars}`}</Button>
                    )}
                </Box>
            )}
        </Flex>
    );
});

AvatarGroupComponent.displayName = 'AvatarGroup';

export const AvatarGroup = Object.assign(AvatarGroupComponent, { Avatar: AvatarComponent });
