import { FC } from 'react';
import parse, { HTMLReactParserOptions, domToReact } from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import { logger } from 'utils';
import { EventIds } from 'lib/EventIds';

import styles from './HotTip.module.scss';

interface HotTipProps {
  title?: string;
  content: string;
}

export const options: HTMLReactParserOptions = {
  replace: (domElement) => {
    const domNode = domElement as Element;

    if (!domNode) {
      return null;
    }

    try {
      if (domNode.name === 'p' && !domNode.children.length) {
        return <></>;
      }
    } catch (error) {
      logger.error(
        'Failed to parse HotTip content',
        EventIds.ParseError,
        error
      );
    }

    try {
      if (domNode.name === 'a') {
        return (
          // without this fix -> href="”https://www.landr.com/”"
          <a
            {...domNode.attribs}
            href={domNode.attribs.href?.replaceAll('”', '')}
          >
            {domToReact(domNode.children)}
          </a>
        );
      }
    } catch (error) {
      logger.error(
        'Failed to parse HotTip <a> content',
        EventIds.ParseError,
        error
      );
    }

    return domNode;
  },
};

export const HotTip: FC<HotTipProps> = ({ title, content }) => {
  const Title = () => {
    if (!title) return null;

    return (
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      ></p>
    );
  };

  return (
    <div className={styles.root}>
      <Title />
      <div className={styles.content}>{parse(content, options)}</div>
    </div>
  );
};
