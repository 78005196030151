import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVolumeMuteFilled = ({ size, title = 'VolumeMuteFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M17.264 5.467a.75.75 0 011.056 1.066L5.528 19.196a.75.75 0 01-1.056-1.066L7.634 15H7.5a1.5 1.5 0 01-1.493-1.356L6 13.5v-3a1.5 1.5 0 011.356-1.493L7.5 9h1.833L13.2 6.1a.5.5 0 01.791.307L14 6.5v2.197zM14 12.808V17.5a.5.5 0 01-.732.443L13.2 17.9l-2.471-1.853L14 12.808z"
        />
    </svg>
);

export default withIcon(SvgVolumeMuteFilled);
