import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSearchOutlined = ({ size, title = 'SearchOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path d="M16.75 10.25a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0h0z" />
            <path strokeLinecap="round" d="M20.25 20.25L15 15" />
        </g>
    </svg>
);

export default withIcon(SvgSearchOutlined);
