import OneSignal from 'react-onesignal';
import { EventIds } from '../lib';
import { logger } from './log';

const log = logger.getInstance('OneSignal');

// Please note that in order to work, OneSignal also needs a worker file
// that is available at /public/OneSignalSDKWorker.js
// See https://documentation.onesignal.com/docs/web-push-custom-code-setup for more details
export async function runOneSignal() {
  try {
    const appId = process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID;
    if (!appId) {
      throw new Error('Missing OneSignal appId');
    }

    await OneSignal.init({
      appId,
      promptOptions: {
        slidedown: {
          enabled: true,
          autoPrompt: true,
          timeDelay: 30,
          pageViews: 1,
        },
      },
    });
  } catch (e) {
    log.error(
      'Failed to register to web push',
      EventIds.OneSignalRegisterError,
      e
    );
  }
}
