import React, { useState, useEffect } from 'react';
import { TLengthStyledSystem } from 'styled-system';
import { Pin, ConditionalWrapper } from '../../primitives';
import { BadgeProps, PinnedBadgeProps } from './types';
import * as Styled from './styled';

type Props = BadgeProps & React.HTMLAttributes<HTMLDivElement>;

const BadgeElement: React.FC<Props & PinnedBadgeProps> = ({
    content: contentProp,
    max = 99,
    icon: IconComponent,
    variant = 'default',
    size = 'md',
    isRounded = false,
    kind = 'base',
    showZero = false,
    isDot = false,
    isPinned = false,
    ...props
}) => {
    const [content, setContent] = useState(contentProp);

    useEffect(() => {
        switch (typeof contentProp) {
            case 'number':
                setContent(contentProp > max ? `${max}+` : contentProp);
                break;

            default:
                setContent(contentProp);
                break;
        }
    }, [contentProp, max]);

    // Hide the component if the value is 0
    if (!showZero && typeof content === 'number' && content <= 0) {
        return <></>;
    }

    // Return a dot if there's no content, icon or if isDot.
    if ((contentProp === 'undefined' && !IconComponent) || isDot) {
        return <Styled.Dot variant={variant} kind={kind} isPinned={isPinned} />;
    }

    return (
        <Styled.Badge variant={variant} size={size} kind={kind} isRounded={isRounded} isPinned={isPinned} {...props}>
            <ConditionalWrapper
                condition={content}
                wrapper={(children: React.ReactNode): JSX.Element => (
                    <Styled.Content size={size}>{children}</Styled.Content>
                )}
            >
                {!!IconComponent && <IconComponent size="xs" />}
                {content}
            </ConditionalWrapper>
        </Styled.Badge>
    );
};

const PinnedBadge: React.FC<Props> = ({ children, anchorShape = 'square', pinPlacement = 'top-right', ...props }) => {
    const offset: Record<string, [TLengthStyledSystem, TLengthStyledSystem]> = {
        square: [0, 0],
        circle: ['14%', '14%'],
    };

    return (
        <Pin pinOffset={offset[anchorShape]} component={<BadgeElement isPinned {...props} />} placement={pinPlacement}>
            {children}
        </Pin>
    );
};

export const Badge: React.FC<Props> = ({ children, ...props }) =>
    children ? <PinnedBadge {...props}>{children}</PinnedBadge> : <BadgeElement {...props} />;
