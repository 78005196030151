import { FC, PropsWithChildren } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { LESSON_NAVIGATION } from 'constants/queryParams';
import { CATEGORY } from 'constants/routes';
import { useBreakpoints, useHasMounted } from 'hooks';
import { YouTubePlayer, Label, BasicImage } from 'ui';

import styles from './IndividualCard.module.scss';
import { getRedirectDestination } from '../../utils/getRedirectDestination';

export type MappedPost = {
  id?: number;
  title: string;
  slug: string;
  categoryName: string;
  categorySlug: string;
  video?: string;
  image: string;
  accentColor?: string;
};

export interface IndividualCardProps {
  post: MappedPost;
  subtitle?: string;
  isLesson?: boolean;
  imageSizes?: string;
}

const DefaultAccentColor = '#8445D4';

export const IndividualCard: FC<IndividualCardProps> = ({
  post: { title, slug, categorySlug, categoryName, accentColor, image, video },
  subtitle,
  isLesson,
  imageSizes,
}) => {
  const hasMounted = useHasMounted();
  const { isBreakpointXXL } = useBreakpoints();

  const isLinkWithQuery = hasMounted && isLesson && isBreakpointXXL;

  const IndividualCardVideo = () => {
    if (!image) return null;

    if (video) {
      return <YouTubePlayer image={image} link={video} />;
    } else {
      return (
        <BasicImage
          src={image}
          alt={title}
          draggable={false}
          sizes={imageSizes}
        />
      );
    }
  };

  const IndividualCardSubtitle = () => {
    if (!subtitle) return null;

    return (
      <span
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></span>
    );
  };

  const LinkWrapper = ({ children }: PropsWithChildren<any>) => {
    const { modifiedUrl, target } = getRedirectDestination(`/${slug}`);

    if (modifiedUrl) {
      return (
        /*
        Link component will rewrite ASCII to UTf-8, so some URL will not be valid
        Example:
         https://app.landr.com/.../?utm_campaign=
         will be converted to
          https://app.landr.com/.../%3Futm_campaign=
         */
        <a className={styles.link} href={modifiedUrl} target={target}>
          {children}
        </a>
      );
    } else {
      return (
        <Link
          href={{
            pathname: `/${slug}`,
            ...(isLinkWithQuery ? { query: { [LESSON_NAVIGATION]: 1 } } : {}),
          }}
          className={styles.link}
        >
          {children}
        </Link>
      );
    }
  };

  return (
    <article
      className={cn(styles.root, {
        [styles.withBackground]: subtitle,
      })}
    >
      <LinkWrapper>
        <div
          className={styles.mediaWrapper}
          style={{ backgroundColor: accentColor || DefaultAccentColor }}
        >
          <div className={styles.mediaItem}>
            <IndividualCardVideo />
            <Label
              label={categoryName}
              href={`${CATEGORY}/${categorySlug}`}
              accentColour="white"
              className={styles.label}
            />
          </div>
        </div>
        <div
          className={cn(styles.linkWrapper, {
            [styles.withBackground]: subtitle,
          })}
        >
          <IndividualCardSubtitle />
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </LinkWrapper>
    </article>
  );
};
