import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPlayFilled = ({ size, title = 'PlayFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8 15.991c0 1.11.768 1.548 1.719.978l6.56-3.937c.949-.57.95-1.492 0-2.063l-6.56-3.937C8.769 6.462 8 6.902 8 8.009v7.982z"
        />
    </svg>
);

export default withIcon(SvgPlayFilled);
