import React, { MouseEvent, isValidElement, useRef } from 'react';
import { PositionProps } from 'styled-system';
import { SystemStyleObject } from '@styled-system/css';
import styled from '../../utils/styled';
import { maestroComponent, palette } from '../../utils';
import { Fade } from '../Fade';
import { Box, BoxProps } from '../../primitives/Box';

type BackdropProps = BoxProps;

const Backdrop = styled(Box)<BackdropProps>`
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100vh;
    max-width: 100vw;
    max-height: 100%;
    max-height: 100dvh;
    background-color: ${palette('neutral.1000', 0.8)};
`;

const Content = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
`;

export interface OverlayProps {
    /** If `true`, show the overlay */
    isVisible: boolean;
    /** Callback function when the overlay is clicked */
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    /** CSS property for the overlay position */
    position?: PositionProps['position'];
    /** CSS property for the overlay z-index */
    zIndex?: PositionProps['zIndex'];
    /** Delay for the transition */
    timeout?: number;
    /** Transition component to use */
    transitionComponent?: React.ComponentType<any>;
    sx?: SystemStyleObject;
}

const OverlayComponent: React.FC<OverlayProps> = ({
    children,
    isVisible = false,
    onClick,
    position = 'fixed',
    zIndex = 'overlay',
    timeout = 300,
    transitionComponent: TransitionComponent = Fade,
    sx,
    ...props
}) => {
    const backdropElement = useRef<HTMLDivElement>(null);

    const handleBackdropClick = (e: MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();
        const clickWasOnBackdrop = backdropElement && backdropElement.current === e.target;

        if (clickWasOnBackdrop) {
            onClick?.(e);
        }
    };

    return (
        <TransitionComponent in={isVisible} timeout={timeout} appear mountOnEnter unmountOnExit>
            <Backdrop
                ref={backdropElement}
                onClick={handleBackdropClick}
                position={position}
                zIndex={zIndex as any}
                sx={sx}
            >
                {isValidElement(children) && <Content {...props}>{children}</Content>}
            </Backdrop>
        </TransitionComponent>
    );
};

export const Overlay = maestroComponent<OverlayProps>('Overlay', OverlayComponent);
