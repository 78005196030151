import { FC } from 'react';
import { PostAuthorNodeFragment } from 'types';
import { BasicImage } from 'ui';
import Link from 'next/link';
import { ShareLinks } from 'components';

import styles from './AuthorBio.module.scss';

interface AuthorBioProps
  extends Pick<
    PostAuthorNodeFragment,
    'name' | 'description' | 'firstName' | 'slug'
  > {
  avatarURL?: string | null;
}

export const AuthorBio: FC<AuthorBioProps> = ({
  name,
  firstName,
  description,
  avatarURL,
  slug,
}) => {
  const authorName = name || firstName;

  const authorURL = `/author/${slug}`;

  const AvatarImage = () => {
    if (!avatarURL) {
      return null;
    }

    return (
      <BasicImage
        src={avatarURL}
        layout="fixed"
        objectFit="cover"
        alt={authorName || 'author'}
        objectPosition="center"
        height="120"
        width="120"
      />
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.shareArticle}>
        <ShareLinks />
      </div>
      <div className={styles.authorBioWrapper}>
        <div className={styles.avatarWrapper}>
          <AvatarImage />
        </div>
        <div className={styles.entry}>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description || '' }}
          />
          <Link href={authorURL} legacyBehavior>
            <a className={styles.authorName}>@{name}</a>
          </Link>
        </div>
      </div>
    </div>
  );
};
