import { gql } from '@apollo/client';

export const PostAdditionalFieldsFragment = gql`
  fragment PostAdditionalFieldsFragment on Post {
    postAdditionalFields {
      accentColor
      minutesToRead
      lessonNavigation {
        title
        visibility
      }
    }
  }
`;

export const PostNode = gql`
  fragment PostNode on Post {
    id
    slug
    postFormat
    title(format: RENDERED)
    categories {
      nodes {
        id
        slug
        name
      }
    }
    featuredImage {
      node {
        altText
        id
        sourceUrl(size: LARGE)
      }
    }
    postFormatVideo {
      videoId
    }
    locale {
      id
      locale
    }
  }
`;

export const GET_POSTS = gql`
  query Posts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: RootQueryToPostConnectionWhereArgs
  ) {
    posts(
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
    ) {
      nodes {
        ...PostNode
        ...PostAdditionalFieldsFragment
      }
    }
  }
  ${PostNode}
  ${PostAdditionalFieldsFragment}
`;

export const SEARCH_POSTS = gql`
  query SearchPosts(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $where: RootQueryToPostConnectionWhereArgs
  ) {
    posts(
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
    ) {
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        total
      }
      nodes {
        id
        slug
        postFormat
        title(format: RENDERED)
        categories {
          nodes {
            name
            slug
          }
        }
        featuredImage {
          node {
            altText
            id
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        postFormatVideo {
          videoId
        }
        locale {
          id
          locale
        }
        ...PostAdditionalFieldsFragment
      }
    }
  }
  ${PostAdditionalFieldsFragment}
`;

export const PostAuthorNode = gql`
  fragment PostAuthorNode on User {
    id
    databaseId
    avatar {
      url
    }
    name
    lastName
    firstName
    slug
    username
    description
    seo {
      fullHead
      canonical
    }
  }
`;

export const PostFragment = gql`
  fragment PostFragment on Post {
    title(format: RENDERED)
    date
    modified
    postFormat
    seo {
      fullHead
    }
    featuredImage {
      node {
        altText
        id
        sourceUrl(size: LARGE)
      }
    }
    postFormatVideo {
      videoId
    }
    locale {
      id
      locale
    }
    translations {
      href
      id
      locale
    }
    blocks {
      id
      attributes {
        name
        value
      }
      innerHtml
      tagName
      type
    }
    categories {
      nodes {
        id
        databaseId
        name
        slug
      }
    }
    author {
      node {
        ...PostAuthorNode
      }
    }
    lessonsPosts {
      nodes {
        id
        name
        posts(first: $lessonPostsPostsFirst, where: $lessonPostsPostsWhere) {
          nodes {
            id
            title
            slug
            lessonsAdditionalFields {
              order
            }
            locale {
              id
              locale
            }
          }
        }
      }
    }
  }
  ${PostAuthorNode}
`;

export const GET_POST = gql`
  query Post(
    $asPreview: Boolean
    $id: ID!
    $idType: PostIdType
    $lessonPostsPostsFirst: Int
    $lessonPostsPostsWhere: LessonsPostToPostConnectionWhereArgs
  ) {
    post(asPreview: $asPreview, id: $id, idType: $idType) {
      id
      ...PostFragment
      ...PostAdditionalFieldsFragment
      translated {
        ...PostFragment
      }
    }
  }
  ${PostFragment}
  ${PostAdditionalFieldsFragment}
`;

export const GET_PREVIEW_POST = gql`
  query GetPreviewPost($asPreview: Boolean, $id: ID!, $idType: PostIdType) {
    post(asPreview: $asPreview, id: $id, idType: $idType) {
      id
      databaseId
      slug
    }
  }
`;

export const PostNodeByUriFragment = gql`
  fragment PostNodeByUri on Post {
    id
    translated {
      id
      locale {
        locale
      }
    }
  }
`;

export const GET_NODE_BY_URI = gql`
  query GetNodeByUri($uri: String!) {
    nodeByUri(uri: $uri) {
      ...PostNodeByUri
    }
  }
  ${PostNodeByUriFragment}
`;
