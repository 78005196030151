import React, { useState, createContext, useContext } from 'react';
import { useKeyPressWithCallback } from '../../hooks';
import { BoxProps, Portal, Level, ConditionalWrapper } from '../../primitives';
import { Overlay } from '../Overlay';
import { DismissButton } from '../DismissButton';
import { Slide } from '../Slide';
import { useEnhancedEffect } from '../../utils';
import { DrawerProps, DrawerHeaderProps } from './types';
import * as Styled from './styled';

const DrawerContext = createContext({ onClose: (): void => void 0, isDismissable: true });

const Header: React.FC<DrawerHeaderProps> = ({ children, showCloseButton = true }) => {
    const { onClose, isDismissable } = useContext(DrawerContext);
    return (
        <Styled.Header>
            <ConditionalWrapper
                condition={isDismissable && showCloseButton}
                wrapper={(children: React.ReactNode): JSX.Element => (
                    <Level>
                        <Level.Left>{children}</Level.Left>
                        <Level.Right>
                            <DismissButton variant="subtle" kind="minimal" onClick={onClose} />
                        </Level.Right>
                    </Level>
                )}
            >
                {children}
            </ConditionalWrapper>
        </Styled.Header>
    );
};

const Footer: React.FC<BoxProps> = ({ children, ...props }) => <Styled.Footer {...props}>{children}</Styled.Footer>;

const Content: React.FC<BoxProps> = ({ children, ...props }) => <Styled.Content {...props}>{children}</Styled.Content>;

export interface DrawerComposition {
    Header: typeof Header;
    Footer: typeof Footer;
    Content: typeof Content;
}

const Drawer: React.FC<DrawerProps> & DrawerComposition = ({
    children,
    side = 'bottom',
    isFull = false,
    isVisible = false,
    onClose = (): void => void 0,
    isDismissable = true,
    zIndex = 1000,
    ...props
}) => {
    const [isExited, setIsExited] = useState(true);

    const handleClose = (): void => {
        isDismissable && onClose?.();
    };

    const handleEnter = (): void => {
        setIsExited(false);
    };

    const handleExited = (): void => {
        setIsExited(true);
    };

    useKeyPressWithCallback('Escape', handleClose);

    // Prevent the content behind to scroll
    // useLockBodyScroll was breaking the scroll inside the modal content
    // @TODO: [DS-453] Investigate why useLockBodyScroll is breaking the scroll and if we really need a ref passed to the hook
    useEnhancedEffect(() => {
        if (!isVisible) {
            return;
        }
        // Get original body overflow
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return (): void => {
            document.body.style.overflow = originalStyle;
        };
    }, [isVisible]);

    // Prevent the Portal to be rendered
    if (!isVisible && isExited) {
        return null;
    }

    return (
        <DrawerContext.Provider value={{ onClose, isDismissable }}>
            <Portal zIndex={zIndex}>
                <Overlay onClick={handleClose} isVisible={isVisible} position="fixed" />
                <Slide
                    in={isVisible}
                    timeout={Styled.TRANSITION_DURATION}
                    onEnter={handleEnter}
                    onExited={handleExited}
                    mountOnEnter
                    side={side}
                >
                    <Styled.Drawer side={side} isFull={isFull} aria-hidden={!isVisible} {...props}>
                        {children}
                    </Styled.Drawer>
                </Slide>
            </Portal>
        </DrawerContext.Provider>
    );
};

Drawer.Header = Header;
Drawer.Footer = Footer;
Drawer.Content = Content;

export { Drawer };
