import React from 'react';
import { ButtonProps, Button, ButtonVariant } from '../Button';
import { maestroComponent } from '../../utils';
import { useBreakpoint } from '../../hooks';
import { CheckCircleFilled, WarningFilled, InfoFilled } from '../../assets/icons';
import { Group } from '../../primitives';
import { ColorMode } from '../..';
import { Text } from '../Text';
import { DismissButton } from '../DismissButton';
import { NotificationBarVariant, NotificationBarProps } from './types';
import * as Styled from './styled';

function notificationBarIcon(variant: NotificationBarVariant, icon: any) {
    if (typeof icon !== 'undefined' && !icon) {
        return false;
    }

    if (typeof icon === 'undefined') {
        switch (variant) {
            case 'informational':
                return () => <InfoFilled />;
            case 'success':
                return () => <CheckCircleFilled />;
            case 'warning':
                return () => <WarningFilled />;
            case 'error':
                return () => <WarningFilled />;
            default:
                return false;
        }
    }

    return icon;
}

function notificationBarActionProps(variant: NotificationBarVariant) {
    let defaultProps: ButtonProps = { size: 'sm' };
    switch (variant) {
        case 'informational':
        case 'error':
            defaultProps = { ...defaultProps, variant: 'subtle' };
            break;
        case 'success':
        case 'warning':
            defaultProps = { ...defaultProps, variant: 'secondary' };
            break;
        default:
    }

    return defaultProps;
}

const getNotificationBarDismissButtonVariant = (variant: NotificationBarVariant): ButtonVariant | undefined => {
    switch (variant) {
        case 'informational':
            return 'accent';
        case 'success':
            return 'primary';
        case 'warning':
            return 'warning';
        case 'error':
            return 'danger';
        default:
            return;
    }
};

const NotificationBarComponent: React.FC<NotificationBarProps> = ({
    children,
    variant,
    icon,
    action,
    onDismiss,
    dismissLabel = 'Dismiss',
}) => {
    const Icon = notificationBarIcon(variant, icon);
    const defaultActionProps = notificationBarActionProps(variant);
    const dismissVariant = getNotificationBarDismissButtonVariant(variant);
    const isMobile = useBreakpoint('sm');
    const gutter = isMobile ? 'xs' : 'md';

    const handleDismiss = () => {
        onDismiss?.();
    };

    return (
        <Styled.Wrapper p="md" variant={variant}>
            <Group
                sx={{ ml: isMobile ? 0 : onDismiss ? 'auto' : 0 }}
                direction="horizontal"
                alignItems={isMobile ? (!action && !onDismiss ? 'center' : 'flex-start') : 'center'}
                gutter={gutter}
            >
                {Icon && (
                    <Group.Item display="flex">
                        <Icon />
                    </Group.Item>
                )}
                <Group.Item>
                    <Group
                        direction={isMobile ? 'vertical' : 'horizontal'}
                        alignItems={isMobile ? 'stretch' : 'center'}
                        justifyContent="center"
                        gutter={!action && !onDismiss ? 'none' : gutter}
                    >
                        <Group.Item>
                            <Text>{children}</Text>
                        </Group.Item>
                        <Group.Item>
                            <Group gutter="xs">
                                {action && (
                                    <Group.Item>
                                        <ColorMode mode="light">
                                            <Button isRounded {...defaultActionProps} {...action} />
                                        </ColorMode>
                                    </Group.Item>
                                )}
                                {isMobile && !!onDismiss && (
                                    <Group.Item>
                                        <Button size="sm" onClick={handleDismiss} variant={dismissVariant} isRounded>
                                            {dismissLabel}
                                        </Button>
                                    </Group.Item>
                                )}
                            </Group>
                        </Group.Item>
                    </Group>
                </Group.Item>
            </Group>
            {!isMobile && !!onDismiss && (
                <DismissButton
                    sx={{ ml: 'auto', alignSelf: 'center' }}
                    size="sm"
                    onClick={handleDismiss}
                    variant={dismissVariant}
                    isRounded
                />
            )}
        </Styled.Wrapper>
    );
};

export const NotificationBar: React.FC<NotificationBarProps> = maestroComponent<NotificationBarProps>(
    'NotificationBar',
    NotificationBarComponent,
);
