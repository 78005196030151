import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSharingOutlined = ({ size, title = 'SharingOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M18.06 11.47l-5.302-5.303v3.181c-3.896 0-7.425 4.3-7.425 8.485 1.979-3.204 3.508-4.242 7.425-4.242v3.182l5.303-5.303z"
        />
    </svg>
);

export default withIcon(SvgSharingOutlined);
