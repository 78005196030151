import { NB_POSTS_PER_PAGE } from 'constants/posts';

export const hasPreviousPage = (page: number): boolean => {
  return page > 1;
};

export const hasNextPage = (
  page: number,
  totalAmountOfPosts: number
): boolean => page < Math.ceil(totalAmountOfPosts / NB_POSTS_PER_PAGE);

/**
 * This function sanitizes the SEO head for category pages.
 * It replaces the canonical URL with the paginated URL and adds
 * the prev and next links to the head if necessary.
 * @param canonicalUrl The base canonical URL
 * @param fullHead The full SEO head returned by the CMS
 * @param page The current page
 * @param totalAmountOfPosts The total amount of posts in the category
 * @returns The sanitized SEO head with the canonical url of the current page, the prev and next links
 */

export const sanitizeCategorySeoHead = (
  canonicalUrl: string | null | undefined,
  fullHead: string | null | undefined,
  page: string,
  totalAmountOfPosts: number
): string | null => {
  if (!fullHead) return null;
  if (!canonicalUrl) return fullHead;

  const currentPage = parseInt(page);
  const updatedCanonicalUrl = `${canonicalUrl}page/${currentPage}/`;
  let sanitizedSeoHead = fullHead.replace(
    new RegExp(canonicalUrl, 'g'),
    updatedCanonicalUrl
  );

  if (hasPreviousPage(currentPage)) {
    sanitizedSeoHead += `<link rel="prev" href="${canonicalUrl}page/${
      currentPage - 1
    }/">`;
  }

  if (hasNextPage(currentPage, totalAmountOfPosts)) {
    sanitizedSeoHead += `<link rel="next" href="${canonicalUrl}page/${
      currentPage + 1
    }/">`;
  }

  return sanitizedSeoHead;
};
