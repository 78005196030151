import React, { useContext } from 'react';
import {
  YouTubeVideosService,
  IYouTubeVideosService,
} from './YouTubeVideosService';

export interface YouTubeVideosContextProps {
  youTubeVideosService: IYouTubeVideosService;
}

export const YouTubeVideosContext =
  React.createContext<YouTubeVideosContextProps>({
    youTubeVideosService: new YouTubeVideosService(),
  });

export const useYouTubeVideosService = () => {
  const context = useContext<YouTubeVideosContextProps>(YouTubeVideosContext);
  if (context === undefined) {
    // It's not a mistake to have no YouTubeVideosContext, it's only used for SSR
    return undefined;
  }

  return context?.youTubeVideosService;
};
