import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLeaveOutlined = ({ size, title = 'LeaveOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
            <path d="M11 12h8" />
            <path strokeLinejoin="round" d="M16.75 15.5l3.5-3.5-3.5-3.5" />
            <path d="M20.25 18.03v.98c0 .685-.568 1.24-1.24 1.24H4.99c-.685 0-1.24-.568-1.24-1.24V4.99c0-.685.568-1.24 1.24-1.24h14.02c.685 0 1.24.552 1.24 1.248v1.034" />
        </g>
    </svg>
);

export default withIcon(SvgLeaveOutlined);
