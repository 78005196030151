import { useCombobox, UseComboboxStateChange } from 'downshift';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Input } from '../../../Input';
import { IconButton } from '../../../IconButton';
import { CaretDownOutlined, CloseOutlined } from '../../../../assets/icons';
import { AutocompleteDropdownProvider, Dropdown, Item, Items, NoResults } from '../shared';
import { forwardRef } from '../../../../system';
import { mergeRefs, runIfFn } from '../../../../utils';
import { useControlled } from '../../../../hooks';
import { ComboBoxProps } from './types';

const defaultGetItemsLabel = (item) => item;

export const ComboBox = forwardRef<ComboBoxProps, 'input'>((props, ref) => {
    const {
        items = [],
        getItemsLabel = defaultGetItemsLabel,
        shape,
        placeholder,
        initialSelectedItem = '',
        selectedItem: selectedItemProp,
        onChange,
        inputValue: inputValueProp,
        onInputValueChange,
        onReset,
        itemComponent = Item,
        noResultsComponent: NoResultsComponent = NoResults,
        noResultsLabel = 'No results match this query',
        bottomComponent,
        groupBy,
        getDisabledOption,
        addonLeft,
        valueComponent,
        onStateChange,
        onOpen,
        onClose,
        isLoading,
        loadingComponent,
        isDisabled,
        dropdownProps,
        blurOnSelect,
        ...rest
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [comboBoxRef, setComboBoxRef] = useState(null);

    const [internalValue, setInternalValue] = useControlled({
        value: inputValueProp,
        defaultValue: initialSelectedItem,
        onChange: onInputValueChange,
    });

    const [internalSelectedItem, setInternalSelectedItem] = useControlled({
        value: selectedItemProp,
        defaultValue: initialSelectedItem,
        onChange: onChange,
    });

    const sortedOptions = useMemo(
        () => (groupBy ? items.sort((a, b) => -groupBy(b).localeCompare(groupBy(a))) : items),
        [groupBy, items],
    );

    const [inputItems, setInputItems] = useState(sortedOptions);

    useEffect(() => {
        setInputItems(sortedOptions);
    }, [sortedOptions]);

    const getFilteredItems = useCallback(
        (inputValue?: string) => {
            setInputItems(
                sortedOptions.filter((item) => getItemsLabel(item).toLowerCase().startsWith(inputValue?.toLowerCase())),
            );
        },
        [sortedOptions, getItemsLabel],
    );

    const handleInputValueChange = ({ inputValue }: UseComboboxStateChange<any>) => {
        setInternalValue(inputValue);
        getFilteredItems(inputValue);
    };

    const handleIsOpenChange = ({ isOpen }: UseComboboxStateChange<any>) => {
        switch (isOpen) {
            case true:
                onOpen?.();
                break;
            case false:
                onClose?.();
                break;
        }
    };

    const handleSelectedItemChange = ({ selectedItem }: UseComboboxStateChange<any>) => {
        setInternalSelectedItem?.(selectedItem);
        if (blurOnSelect) {
            inputRef.current?.blur();
        }
    };

    const {
        isOpen,
        inputValue,
        getInputProps,
        getItemProps,
        getMenuProps,
        getToggleButtonProps,
        highlightedIndex,
        openMenu,
        closeMenu,
        toggleMenu,
        selectedItem,
        reset,
    } = useCombobox({
        items: inputItems,
        inputValue: internalValue,
        onStateChange,
        onInputValueChange: handleInputValueChange,
        itemToString: getItemsLabel,
        onIsOpenChange: handleIsOpenChange,
        selectedItem: internalSelectedItem,
        onSelectedItemChange: handleSelectedItemChange,
    });

    const handleReset = (event) => {
        event.stopPropagation();

        onReset?.();
        reset();
    };

    const handleOpenMenu = () => {
        if (isDisabled) {
            return;
        } else {
            openMenu();
        }
    };

    return (
        <AutocompleteDropdownProvider
            value={{ isOpen, items: inputItems, inputValue, closeMenu, groupBy, getDisabledOption }}
        >
            <Input
                placeholder={placeholder}
                shape={shape}
                isDisabled={isDisabled}
                addonLeft={[addonLeft, !!selectedItem && runIfFn(valueComponent, selectedItem)]}
                wrapperProps={{ ref: mergeRefs(ref, setComboBoxRef), onClick: handleOpenMenu }}
                addonRight={[
                    inputValue && (
                        <IconButton
                            key="clear-button"
                            size="sm"
                            variant="subtle"
                            isRounded={shape === 'rounded'}
                            label=""
                            hideTooltip
                            icon={CloseOutlined}
                            mr="xxs"
                            onClick={handleReset}
                        />
                    ),
                    <IconButton
                        isDisabled={isDisabled}
                        key="toggle-button"
                        size="sm"
                        variant="subtle"
                        isRounded={shape === 'rounded'}
                        label=""
                        hideTooltip
                        icon={CaretDownOutlined}
                        mr="xxs"
                        {...getToggleButtonProps({ onClick: toggleMenu })}
                    />,
                ]}
                {...getInputProps({
                    onFocus: () => {
                        if (!isOpen) {
                            handleOpenMenu();
                        }
                    },
                })}
                {...rest}
            />

            <Dropdown anchorRef={comboBoxRef} maxHeight={dropdownProps?.maxHeight}>
                <Items
                    itemComponent={itemComponent}
                    itemProps={getItemProps}
                    getItemsLabel={getItemsLabel}
                    noResultsComponent={NoResultsComponent}
                    noResultsLabel={noResultsLabel}
                    highlightedIndex={highlightedIndex}
                    isLoading={isLoading}
                    loadingComponent={loadingComponent}
                    bottomComponent={bottomComponent}
                    {...getMenuProps()}
                />
            </Dropdown>
        </AutocompleteDropdownProvider>
    );
});
