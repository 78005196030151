import { Dispatch, SetStateAction, useCallback } from 'react';
import { useState } from 'react';
import { warn } from '../../utils';

export interface UseControlledProps<T> {
    /** The value to use for the controlled component */
    value?: T;
    /** Default value to use for the uncontrolled component */
    defaultValue?: T;
    /** Function to call when the value changes in a controlled component */
    onChange?: (value: T) => void;
}

type UseControlled<T> = [T | undefined, Dispatch<SetStateAction<T>>];

export const useControlled = <T,>(props: UseControlledProps<T> = {}): UseControlled<T> => {
    const { value: valueProp, defaultValue, onChange } = props;
    const isControlled = valueProp !== undefined;

    warn({
        condition: !isControlled && defaultValue === undefined,
        message: 'useControlled: You need to add a default value when using an uncontrolled component',
    });

    const [valueState, setValue] = useState(defaultValue);
    const value = isControlled ? valueProp : valueState;

    const updateValue = useCallback(
        (value: T) => {
            if (!isControlled) {
                setValue(value);
            }
            onChange?.(value);
        },
        [isControlled, onChange],
    );

    return [value, updateValue];
};
