import styled from '../../utils/styled';
import { MaestroStyledComponent, spacing } from '../../utils';
import { Box, BoxProps } from '../../primitives/Box';
import * as AssetCardStyledComponents from '../AssetCard/styled';
import { AssetCardGroupProps } from './types';

const AssetCardGroup: MaestroStyledComponent<AssetCardGroupProps> = styled(Box)<BoxProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${AssetCardStyledComponents.Card}:not(:last-child) {
        margin-bottom: ${spacing('xs')};
    }
`;

interface StyledAssetCardGroup {
    AssetCardGroup: typeof AssetCardGroup;
}

export const Styled: StyledAssetCardGroup = {
    AssetCardGroup,
};
