import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCogOutlined = ({ size, title = 'CogOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinejoin="round" strokeWidth={1.5}>
            <path d="M18.47 11.44l-.258-1.169.774-1.115c.315-.454.301-1.169-.042-1.612l-.712-.918c-.338-.437-1.024-.615-1.53-.397l-1.216.524-1.07-.53-.35-1.267C13.921 4.428 13.358 4 12.803 4h-1.107c-.552 0-1.118.429-1.263.956l-.35 1.267-1.07.53-1.216-.524c-.507-.218-1.187-.047-1.53.397l-.712.918c-.338.437-.353 1.165-.042 1.613l.774 1.115-.258 1.169-1.157.652c-.481.272-.776.926-.657 1.462l.262 1.18c.119.535.66.993 1.208 1.023l1.317.072.726.934-.23 1.366c-.092.542.235 1.18.736 1.429l.997.494c.498.247 1.198.104 1.553-.308l.885-1.026h1.163l.885 1.025c.36.417 1.052.556 1.552.308l.998-.494c.497-.247.827-.888.736-1.429l-.23-1.365.726-.935 1.317-.072c.548-.03 1.089-.487 1.208-1.023l.261-1.18c.119-.535-.176-1.19-.657-1.462l-1.157-.652z" />
            <path d="M15 12.25a2.75 2.75 0 11-5.5 0 2.75 2.75 0 015.5 0z" />
        </g>
    </svg>
);

export default withIcon(SvgCogOutlined);
