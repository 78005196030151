import { gql } from '@apollo/client';

export const GET_ALS_HEADER_SETTINGS = gql`
  query AlsHeaderSettings {
    alsHeaderSettings {
      headerSettingsPageFields {
        fieldGroupName
        loginLink
        loginLabel
        navigationSlideHeadline
        navigationSlideSignOff
        searchBar
        siteLink
        siteTitle
        footerHeading
        headerLinks {
          linkGroupTitle
          pluginsTitle
          pluginsLink
          samplesTitle
          samplesLink
          masteringTitle
          masteringLink
          distributionTitle
          distributionLink
          collaborationTitle
          collaborationLink
        }
        footerLinks {
          copyrightNotice
          privacyPolicyTitle
          privacyPolicyLink
          termsTitle
          termsLink
        }
        french {
          fieldGroupName
          loginLink
          loginLabel
          navigationSlideHeadline
          navigationSlideSignOff
          searchBar
          siteLink
          siteTitle
          footerHeading
          headerLinks {
            linkGroupTitle
            pluginsTitle
            pluginsLink
            samplesTitle
            samplesLink
            masteringTitle
            masteringLink
            distributionTitle
            distributionLink
            collaborationTitle
            collaborationLink
          }
          footerLinks {
            copyrightNotice
            privacyPolicyTitle
            privacyPolicyLink
            termsTitle
            termsLink
          }
        }
        german {
          fieldGroupName
          loginLink
          loginLabel
          navigationSlideHeadline
          navigationSlideSignOff
          searchBar
          siteLink
          siteTitle
          footerHeading
          headerLinks {
            linkGroupTitle
            pluginsTitle
            pluginsLink
            samplesTitle
            samplesLink
            masteringTitle
            masteringLink
            distributionTitle
            distributionLink
            collaborationTitle
            collaborationLink
          }
          footerLinks {
            copyrightNotice
            privacyPolicyTitle
            privacyPolicyLink
            termsTitle
            termsLink
          }
        }
        italian {
          fieldGroupName
          loginLink
          loginLabel
          navigationSlideHeadline
          navigationSlideSignOff
          searchBar
          siteLink
          siteTitle
          footerHeading
          headerLinks {
            linkGroupTitle
            pluginsTitle
            pluginsLink
            samplesTitle
            samplesLink
            masteringTitle
            masteringLink
            distributionTitle
            distributionLink
            collaborationTitle
            collaborationLink
          }
          footerLinks {
            copyrightNotice
            privacyPolicyTitle
            privacyPolicyLink
            termsTitle
            termsLink
          }
        }
        japanese {
          fieldGroupName
          loginLink
          loginLabel
          navigationSlideHeadline
          navigationSlideSignOff
          searchBar
          siteLink
          siteTitle
          footerHeading
          headerLinks {
            linkGroupTitle
            pluginsTitle
            pluginsLink
            samplesTitle
            samplesLink
            masteringTitle
            masteringLink
            distributionTitle
            distributionLink
            collaborationTitle
            collaborationLink
          }
          footerLinks {
            copyrightNotice
            privacyPolicyTitle
            privacyPolicyLink
            termsTitle
            termsLink
          }
        }
        portuguese {
          fieldGroupName
          loginLink
          loginLabel
          navigationSlideHeadline
          navigationSlideSignOff
          searchBar
          siteLink
          siteTitle
          footerHeading
          headerLinks {
            linkGroupTitle
            pluginsTitle
            pluginsLink
            samplesTitle
            samplesLink
            masteringTitle
            masteringLink
            distributionTitle
            distributionLink
            collaborationTitle
            collaborationLink
          }
          footerLinks {
            copyrightNotice
            privacyPolicyTitle
            privacyPolicyLink
            termsTitle
            termsLink
          }
        }
        spanish {
          fieldGroupName
          loginLink
          loginLabel
          navigationSlideHeadline
          navigationSlideSignOff
          searchBar
          siteLink
          siteTitle
          footerHeading
          headerLinks {
            linkGroupTitle
            pluginsTitle
            pluginsLink
            samplesTitle
            samplesLink
            masteringTitle
            masteringLink
            distributionTitle
            distributionLink
            collaborationTitle
            collaborationLink
          }
          footerLinks {
            copyrightNotice
            privacyPolicyTitle
            privacyPolicyLink
            termsTitle
            termsLink
          }
        }
      }
    }
  }
`;
