import he from 'he';
import { Attribute, BlockNameEnum } from 'types/generated/graphql';
import { ProductFeatureType } from 'types/feature';
import { Position } from 'components/Common';
import {
  PostCards,
  Paragraph,
  LessonCarousel,
  Advertorial,
  SignOff,
  InlineCTA,
  ProductFeatureBoxed,
  ProductFeature,
  GeneralSubscription,
  InlineSubscription,
  Subjects,
  DoubleLesson,
  WideLesson,
  OneUpLesson,
  AuthorBio,
  HotTip,
  Quote,
  AudioPlayer,
  YouTubePlayer,
  Image,
  MappedPost,
  SocialEmbedded,
} from 'ui';
import { CategoryBlock, PostProps } from '../components';
import { urlRewrite } from '../utils/urlRewrite';

export enum ShortCodeTypeEnum {
  video_module = 'video-module',
  audio_module = 'audio-module',
  image_module = 'image-module',
  quotes_module = 'quotes-module',
  hottip_module = 'hottip-module',
  author_bio = 'author-bio',
  secondary_author_module = 'secondary-author-module',
  product_feature = 'product-feature',
  advertorial = 'advertorial',
  sign_off = 'sign-off',
  subscription = 'subscription',
  general_subscription = 'general_subscription',
  '3_up' = '3_up',
  '2_up' = '2_up',
  one_up_lesson = 'one_up_lesson',
  static_1_2_3_up = 'static_1_2_3_up',
  wide = 'wide',
  double = 'double',
  subject_teasers = 'subject_teasers',
  lessons_carousel = 'lessons_carousel',
  social_embedded = 'social-embedded',
}

function extractTextContentFromHtml(innerHtml: string): string {
  // Taken from https://ourcodeworld.com/articles/read/376/how-to-strip-html-from-a-string-extract-only-text-content-in-javascript
  return innerHtml.replace(/<[^>]+>/g, '');
}

const getHeadingId = (innerHtml: string) => {
  const textContent = extractTextContentFromHtml(innerHtml);
  // Only include letters and numbers in id
  return textContent.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

const getPosition = (template: string): any => {
  if (template === 'inline') {
    return 'inline';
  } else if (template === 'wide') {
    return 'large';
  } else if (template === 'left') {
    return 'left-side-bar';
  } else if (template === 'right') {
    return 'right-side-bar';
  }
};

const computePostItem = (
  attributes?: Array<Pick<Attribute, 'name' | 'value'> | null> | null,
  index?: number
) => {
  const getKey = (name: string) => {
    return `${name}${index ? index : ''}`;
  };

  const format =
    attributes?.find((item) => item?.name === getKey('format'))?.value || '';
  const lessonImage =
    attributes?.find((item) => item?.name === getKey('lesson_image'))?.value ||
    '';
  const categorySlug =
    attributes?.find((item) => item?.name === getKey('cat_slug'))?.value || '';
  const categoryName =
    attributes?.find((item) => item?.name === getKey('cat_name'))?.value || '';
  const lessonName =
    attributes?.find((item) => item?.name === getKey('lesson_name'))?.value ||
    '';
  const lessonSlug =
    attributes?.find((item) => item?.name === getKey('lesson_slug'))?.value ||
    '';
  const lessonVideo =
    attributes?.find((item) => item?.name === getKey('lesson_video'))?.value ||
    '';
  const accentColor =
    attributes?.find((item) => item?.name === getKey('accent_color'))?.value ||
    '';

  const isVideoFormat = format === 'video';

  return {
    title: lessonName,
    slug: lessonSlug,
    categoryName: categoryName,
    categorySlug: categorySlug,
    video: isVideoFormat ? lessonVideo : undefined,
    image: lessonImage,
    accentColor,
  };
};

const renderShortCodes = (
  shortCode: CategoryBlock,
  post?: PostProps['postData']
): JSX.Element | null => {
  const shortCodeType = shortCode?.attributes?.find(
    (item) => item?.name === 'type'
  )?.value;
  const attributes = shortCode?.attributes;
  const template: any =
    attributes?.find((item) => item?.name === 'template')?.value || '';
  const position = getPosition(template);

  switch (shortCodeType) {
    case ShortCodeTypeEnum.video_module: {
      const src = he.decode(
        attributes?.find((item) => item?.name === 'src')?.value || ''
      );
      const cover =
        attributes?.find((item) => item?.name === 'cover')?.value || '';
      const caption =
        attributes?.find((item) => item?.name === 'caption')?.value || '';
      const embedded =
        attributes?.find((item) => item?.name === 'embedded')?.value || 'true';

      if (src) {
        return (
          <Position position={position}>
            <YouTubePlayer
              image={cover}
              link={src}
              caption={caption}
              playOnClick={true}
              isEmbedded={embedded === 'true'}
            />
          </Position>
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.audio_module: {
      const src = he.decode(
        attributes?.find((item) => item?.name === 'src')?.value || ''
      );
      const caption =
        attributes?.find((item) => item?.name === 'caption')?.value || '';
      const cover =
        attributes?.find((item) => item?.name === 'cover')?.value || '';

      if (src) {
        return (
          <Position position={position}>
            <AudioPlayer src={src} image={cover} caption={caption} />
          </Position>
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.image_module: {
      const src = he.decode(
        attributes?.find((item) => item?.name === 'src')?.value || ''
      );
      const imageHref = he.decode(
        attributes?.find((item) => item?.name === 'href')?.value || ''
      );
      const imageHrefTarget =
        attributes?.find((item) => item?.name === 'target')?.value || '';
      const caption =
        attributes?.find((item) => item?.name === 'caption')?.value || '';

      if (src) {
        return (
          <Position position={position}>
            <Image
              imageHref={imageHref}
              imageHrefTarget={imageHrefTarget}
              src={src}
              alt={caption}
              caption={caption}
            />
          </Position>
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.quotes_module: {
      const content =
        attributes?.find((item) => item?.name === 'content')?.value || '';
      const isSidebarPosition =
        position === 'left-side-bar' || position === 'right-side-bar';

      if (content) {
        return (
          <Position position={position}>
            <Quote content={content} sidebarPosition={isSidebarPosition} />
          </Position>
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.hottip_module: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const content =
        attributes?.find((item) => item?.name === 'content')?.value || '';
      const innerHtml = shortCode?.innerHtml || '';

      return (
        <Position position={position}>
          <HotTip title={title} content={content || innerHtml} />
        </Position>
      );
    }
    case ShortCodeTypeEnum.author_bio: {
      if (post?.author?.node) {
        const node = post?.author?.node;

        return (
          <AuthorBio
            name={node?.name}
            firstName={node?.firstName}
            description={node?.description}
            avatarURL={node?.avatar?.url}
            slug={node?.slug}
          />
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.secondary_author_module: {
      const name =
        attributes?.find((item) => item?.name === 'name')?.value || '';
      const firstName =
        attributes?.find((item) => item?.name === 'first_name')?.value || '';
      const description =
        attributes?.find((item) => item?.name === 'description')?.value || '';
      const avatarUrl =
        attributes?.find((item) => item?.name === 'avatar_url')?.value || '';

      return (
        <AuthorBio
          name={name}
          firstName={firstName}
          description={description}
          avatarURL={avatarUrl}
        />
      );
    }
    case ShortCodeTypeEnum.product_feature: {
      // available templates: inline/simple_inline/boxed/left/right
      const featureType =
        (attributes?.find((item) => item?.name === 'feature_type')
          ?.value as ProductFeatureType) || 'product_feature';
      const cover =
        attributes?.find((item) => item?.name === 'cover')?.value || '';
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const subtitle =
        attributes?.find((item) => item?.name === 'subtitle')?.value || '';
      const buttonText =
        attributes?.find((item) => item?.name === 'btn_text')?.value || '';
      const buttonLink = he.decode(
        attributes?.find((item) => item?.name === 'btn_link')?.value || ''
      );
      const audioUrl =
        attributes?.find((item) => item?.name === 'audio_url')?.value || '';

      if (template === 'simple_inline') {
        return (
          <InlineCTA title={title} linkLabel={buttonText} link={buttonLink} />
        );
      } else if (template === 'boxed' && cover) {
        return (
          <ProductFeatureBoxed
            featureType={featureType}
            title={title}
            subtitle={subtitle}
            linkLabel={buttonText}
            link={buttonLink}
            image={cover}
            audio={audioUrl}
          />
        );
      } else if (cover) {
        return (
          <ProductFeature
            featureType={featureType}
            title={title}
            linkLabel={buttonText}
            link={buttonLink}
            image={cover}
            audio={audioUrl}
            position={position}
          />
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.advertorial: {
      const cover =
        attributes?.find((item) => item?.name === 'cover')?.value || '';
      const content =
        attributes?.find((item) => item?.name === 'content')?.value || '';
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const subtitle =
        attributes?.find((item) => item?.name === 'subtitle')?.value || '';
      const buttonText =
        attributes?.find((item) => item?.name === 'btn_text')?.value || '';
      const buttonLink = he.decode(
        attributes?.find((item) => item?.name === 'btn_link')?.value || ''
      );
      const backgroundColor =
        attributes?.find((item) => item?.name === 'background_color')?.value ||
        '';
      const ctaColor =
        attributes?.find((item) => item?.name === 'cta_color')?.value || '';

      if (cover) {
        return (
          <Advertorial
            title={title}
            subtitle={subtitle}
            description={content}
            linkLabel={buttonText}
            link={buttonLink}
            background={backgroundColor}
            ctaColor={ctaColor}
            image={cover}
          />
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.sign_off: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const content =
        attributes?.find((item) => item?.name === 'content')?.value || '';

      if (content) {
        return <SignOff title={title} text={content} />;
      }

      return null;
    }
    case ShortCodeTypeEnum.subscription: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const subtitle =
        attributes?.find((item) => item?.name === 'subtitle')?.value || '';
      const isBoxed = template === 'boxed';

      return (
        <InlineSubscription title={title} subtitle={subtitle} boxed={isBoxed} />
      );
    }
    case ShortCodeTypeEnum.general_subscription: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const subtitle =
        attributes?.find((item) => item?.name === 'subtitle')?.value || '';
      const lessonImage =
        attributes?.find((item) => item?.name === 'lesson_image')?.value || '';
      const lessonName =
        attributes?.find((item) => item?.name === 'lesson_name')?.value || '';
      const lessonSlug =
        attributes?.find((item) => item?.name === 'lesson_slug')?.value || '';
      const accentColor =
        attributes?.find((item) => item?.name === 'accent_color')?.value || '';

      const post = {
        title: lessonName,
        slug: lessonSlug,
        image: lessonImage,
      };

      if (title && lessonName) {
        return (
          <GeneralSubscription
            title={title}
            description={subtitle}
            post={post}
            background={accentColor}
          />
        );
      }

      return null;
    }
    case ShortCodeTypeEnum['3_up']: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const author =
        attributes?.find((item) => item?.name === 'author')?.value || null;
      const categoryId =
        attributes?.find((item) => item?.name === 'categoryid')?.value || null;

      return (
        <PostCards
          title={title}
          postsCount={3}
          author={Number(author)}
          categoryId={Number(categoryId)}
        />
      );
    }
    case ShortCodeTypeEnum['2_up']: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const author =
        attributes?.find((item) => item?.name === 'author')?.value || null;
      const categoryId =
        attributes?.find((item) => item?.name === 'categoryid')?.value || null;

      return (
        <PostCards
          title={title}
          postsCount={2}
          author={Number(author)}
          categoryId={Number(categoryId)}
        />
      );
    }
    case ShortCodeTypeEnum.one_up_lesson: {
      const author =
        attributes?.find((item) => item?.name === 'author')?.value || null;
      const categoryId =
        attributes?.find((item) => item?.name === 'categoryid')?.value || null;

      return (
        <OneUpLesson author={Number(author)} categoryId={Number(categoryId)} />
      );
    }
    case ShortCodeTypeEnum.static_1_2_3_up: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const post1Id =
        attributes?.find((item) => item?.name === 'post_1_id')?.value || null;
      const post2Id =
        attributes?.find((item) => item?.name === 'post_2_id')?.value || null;
      const post3Id =
        attributes?.find((item) => item?.name === 'post_3_id')?.value || null;

      if (!post2Id && !post3Id) {
        return <OneUpLesson author={null} postId={post1Id} />;
      }

      const postIds = [post1Id, post2Id, post3Id].filter(
        (id) => !!id
      ) as string[];
      return (
        <PostCards
          title={title}
          postsCount={post3Id ? 3 : 2}
          postIds={postIds}
        />
      );
    }
    case ShortCodeTypeEnum.wide: {
      const format =
        attributes?.find((item) => item?.name === 'format')?.value || '';
      const imageType =
        attributes?.find((item) => item?.name === 'image_type')?.value || '';
      const lessonImage =
        attributes?.find((item) => item?.name === 'lesson_image')?.value || '';
      const categorySlug =
        attributes?.find((item) => item?.name === 'cat_slug')?.value || '';
      const categoryName =
        attributes?.find((item) => item?.name === 'cat_name')?.value || '';
      const lessonName =
        attributes?.find((item) => item?.name === 'lesson_name')?.value || '';
      const lessonSlug =
        attributes?.find((item) => item?.name === 'lesson_slug')?.value || '';
      const lessonVideo =
        attributes?.find((item) => item?.name === 'lesson_video')?.value || '';
      const accentColor =
        attributes?.find((item) => item?.name === 'accent_color')?.value || '';

      const isTransparent = imageType === 'transparent_image';
      const isVideoFormat = format === 'video';

      const post = {
        title: lessonName,
        slug: lessonSlug,
        categoryName: categoryName,
        categorySlug: categorySlug,
        video: isVideoFormat ? lessonVideo : undefined,
        image: lessonImage,
        accentColor: accentColor,
      };

      if (post.slug) {
        return <WideLesson post={post} isTransparent={isTransparent} />;
      }

      return null;
    }
    case ShortCodeTypeEnum.double: {
      const imageType =
        attributes?.find((item) => item?.name === 'imageType')?.value || '';
      const imageTypeSecond =
        attributes?.find((item) => item?.name === 'imageType2')?.value || '';

      const isTransparent = imageType === 'transparent_image';
      const isTransparentSecond = imageTypeSecond === 'transparent_image';

      const posts: MappedPost[] = [
        computePostItem(attributes),
        computePostItem(attributes, 2),
      ];

      if (posts.length === 2) {
        return (
          <DoubleLesson
            firstPost={{ post: posts[0], isTransparent: isTransparent }}
            secondPost={{ post: posts[1], isTransparent: isTransparentSecond }}
          />
        );
      }

      return null;
    }
    case ShortCodeTypeEnum.subject_teasers: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const subtitle =
        attributes?.find((item) => item?.name === 'subtitle')?.value || '';

      return <Subjects title={title} subtitle={subtitle} />;
    }
    case ShortCodeTypeEnum.lessons_carousel: {
      const title =
        attributes?.find((item) => item?.name === 'title')?.value || '';
      const subtitle =
        attributes?.find((item) => item?.name === 'subtitle')?.value || '';
      const lessonId =
        attributes?.find((item) => item?.name === 'lessonid')?.value || '';

      return (
        <LessonCarousel lessonId={lessonId} title={title} subtitle={subtitle} />
      );
    }
    case ShortCodeTypeEnum.social_embedded: {
      const url = attributes?.find((item) => item?.name === 'url')?.value;
      const width = attributes?.find((item) => item?.name === 'width')?.value;
      const height = attributes?.find((item) => item?.name === 'height')?.value;

      if (!url) {
        return null;
      }

      return (
        <SocialEmbedded
          url={url}
          width={width || undefined}
          height={height || undefined}
        />
      );
    }

    default: {
      return null;
    }
  }
};

const innerHtmlTag = (block: CategoryBlock): JSX.Element | null => {
  const { tagName, attributes } = block || {};
  let { innerHtml } = block || {};
  const src = attributes?.find((item) => item?.name === 'src')?.value;
  const alt = attributes?.find((item) => item?.name === 'alt')?.value;

  if (innerHtml) {
    innerHtml = urlRewrite(innerHtml) as string;
  }

  // 'p' as a kludge to accept any tag key
  const Tag = `${tagName}` as 'p';

  // to save old post images
  if (tagName === 'img' && src) {
    return (
      <Position position="inline">
        <Image src={src} alt={alt || src} />
      </Position>
    );
  }

  if (innerHtml?.includes('AdButler')) {
    return null;
  }

  // fix blockquote render
  if (tagName == 'p' && innerHtml?.includes('blockquote')) {
    const excludeBlockquote = innerHtml.replace(/<\/?blockquote>/g, '');
    return (
      <blockquote dangerouslySetInnerHTML={{ __html: excludeBlockquote }} />
    );
  }

  if (tagName == 'p' && innerHtml) {
    return <Paragraph innerHtml={`<p>${innerHtml}</p>`} />;
  }

  if (
    ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tagName || '') &&
    innerHtml
  ) {
    return (
      <Tag
        id={getHeadingId(innerHtml)}
        dangerouslySetInnerHTML={{ __html: innerHtml }}
      />
    );
  }

  if (tagName && innerHtml) {
    return <Tag dangerouslySetInnerHTML={{ __html: innerHtml }} />;
  }

  return null;
};

export const renderBlocks = (
  block: CategoryBlock,
  post?: PostProps['postData']
): JSX.Element | null => {
  try {
    switch (block?.type) {
      case BlockNameEnum.ShortcodeAls: {
        return renderShortCodes(block, post);
      }
      case BlockNameEnum.ShortcodeVideo: {
        const mp4 =
          block?.attributes?.find((item) => item?.name === 'mp4')?.value || '';

        if (mp4) {
          return (
            <Position position="inline">
              <video src={urlRewrite(mp4) as string} controls playsInline />
            </Position>
          );
        }

        return null;
      }
      default: {
        return innerHtmlTag(block);
      }
    }
  } catch (error) {
    return null;
  }
};
