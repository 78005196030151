import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgInsider = ({ size, title = 'Insider', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#2C3C4D"
            d="M45 11H3a2 2 0 00-2 2v32a2 2 0 002 2h42a2 2 0 002-2V13a2 2 0 00-2-2zm-16 8H19a2 2 0 010-4h10a2 2 0 110 4z"
        />
        <path
            fill="#5A6774"
            d="M40 30H28a1 1 0 110-2h12a1 1 0 010 2zm-5 6h-7a1 1 0 110-2h7a1 1 0 010 2zM24 1c-2.757 0-5 2.243-5 5v10a1 1 0 001 1h8a1 1 0 001-1V6c0-2.757-2.243-5-5-5zm0 7a2 2 0 110-4 2 2 0 010 4z"
        />
        <path
            fill="#F6E07F"
            d="M14 24.342l2.163 4.383 4.837.703-3.5 3.412.826 4.817L14 35.383l-4.326 2.274.826-4.817L7 29.428l4.837-.703L14 24.342z"
        />
    </svg>
);

export default withIcon(SvgInsider);
