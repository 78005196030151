export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AcfFieldGroup = {
  fieldGroupName?: Maybe<Scalars['String']>;
};

export type AlsHeaderSettings = {
  __typename?: 'AlsHeaderSettings';
  headerSettingsPageFields?: Maybe<AlsHeaderSettingsHeadersettingspagefields>;
  pageSlug?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
};

export type AlsHeaderSettingsHeadersettingspagefields = AcfFieldGroup & {
  __typename?: 'AlsHeaderSettings_Headersettingspagefields';
  fieldGroupName?: Maybe<Scalars['String']>;
  footerHeading?: Maybe<Scalars['String']>;
  footerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsFooterLinks>;
  french?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsFrench>;
  german?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsGerman>;
  headerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsHeaderLinks>;
  italian?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsItalian>;
  japanese?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsJapanese>;
  loginLabel?: Maybe<Scalars['String']>;
  loginLink?: Maybe<Scalars['String']>;
  navigationSlideHeadline?: Maybe<Scalars['String']>;
  navigationSlideSignOff?: Maybe<Scalars['String']>;
  portuguese?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsPortuguese>;
  searchBar?: Maybe<Scalars['String']>;
  siteLink?: Maybe<Scalars['String']>;
  siteTitle?: Maybe<Scalars['String']>;
  spanish?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsSpanish>;
};

export type AlsHeaderSettingsHeadersettingspagefieldsFooterLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_FooterLinks';
    copyrightNotice?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    privacyPolicyLink?: Maybe<Scalars['String']>;
    privacyPolicyTitle?: Maybe<Scalars['String']>;
    termsLink?: Maybe<Scalars['String']>;
    termsTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsFrench = AcfFieldGroup & {
  __typename?: 'AlsHeaderSettings_Headersettingspagefields_French';
  fieldGroupName?: Maybe<Scalars['String']>;
  footerHeading?: Maybe<Scalars['String']>;
  footerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsFrenchFooterLinks>;
  headerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsFrenchHeaderLinks>;
  loginLabel?: Maybe<Scalars['String']>;
  loginLink?: Maybe<Scalars['String']>;
  navigationSlideHeadline?: Maybe<Scalars['String']>;
  navigationSlideSignOff?: Maybe<Scalars['String']>;
  searchBar?: Maybe<Scalars['String']>;
  siteLink?: Maybe<Scalars['String']>;
  siteTitle?: Maybe<Scalars['String']>;
};

export type AlsHeaderSettingsHeadersettingspagefieldsFrenchFooterLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_French_FooterLinks';
    copyrightNotice?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    privacyPolicyLink?: Maybe<Scalars['String']>;
    privacyPolicyTitle?: Maybe<Scalars['String']>;
    termsLink?: Maybe<Scalars['String']>;
    termsTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsFrenchHeaderLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_French_HeaderLinks';
    collaborationLink?: Maybe<Scalars['String']>;
    collaborationTitle?: Maybe<Scalars['String']>;
    distributionLink?: Maybe<Scalars['String']>;
    distributionTitle?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    linkGroupTitle?: Maybe<Scalars['String']>;
    masteringLink?: Maybe<Scalars['String']>;
    masteringTitle?: Maybe<Scalars['String']>;
    pluginsLink?: Maybe<Scalars['String']>;
    pluginsTitle?: Maybe<Scalars['String']>;
    samplesLink?: Maybe<Scalars['String']>;
    samplesTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsGerman = AcfFieldGroup & {
  __typename?: 'AlsHeaderSettings_Headersettingspagefields_German';
  fieldGroupName?: Maybe<Scalars['String']>;
  footerHeading?: Maybe<Scalars['String']>;
  footerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsGermanFooterLinks>;
  headerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsGermanHeaderLinks>;
  loginLabel?: Maybe<Scalars['String']>;
  loginLink?: Maybe<Scalars['String']>;
  navigationSlideHeadline?: Maybe<Scalars['String']>;
  navigationSlideSignOff?: Maybe<Scalars['String']>;
  searchBar?: Maybe<Scalars['String']>;
  siteLink?: Maybe<Scalars['String']>;
  siteTitle?: Maybe<Scalars['String']>;
};

export type AlsHeaderSettingsHeadersettingspagefieldsGermanFooterLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_German_FooterLinks';
    copyrightNotice?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    privacyPolicyLink?: Maybe<Scalars['String']>;
    privacyPolicyTitle?: Maybe<Scalars['String']>;
    termsLink?: Maybe<Scalars['String']>;
    termsTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsGermanHeaderLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_German_HeaderLinks';
    collaborationLink?: Maybe<Scalars['String']>;
    collaborationTitle?: Maybe<Scalars['String']>;
    distributionLink?: Maybe<Scalars['String']>;
    distributionTitle?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    linkGroupTitle?: Maybe<Scalars['String']>;
    masteringLink?: Maybe<Scalars['String']>;
    masteringTitle?: Maybe<Scalars['String']>;
    pluginsLink?: Maybe<Scalars['String']>;
    pluginsTitle?: Maybe<Scalars['String']>;
    samplesLink?: Maybe<Scalars['String']>;
    samplesTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsHeaderLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_HeaderLinks';
    collaborationLink?: Maybe<Scalars['String']>;
    collaborationTitle?: Maybe<Scalars['String']>;
    distributionLink?: Maybe<Scalars['String']>;
    distributionTitle?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    linkGroupTitle?: Maybe<Scalars['String']>;
    masteringLink?: Maybe<Scalars['String']>;
    masteringTitle?: Maybe<Scalars['String']>;
    pluginsLink?: Maybe<Scalars['String']>;
    pluginsTitle?: Maybe<Scalars['String']>;
    samplesLink?: Maybe<Scalars['String']>;
    samplesTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsItalian = AcfFieldGroup & {
  __typename?: 'AlsHeaderSettings_Headersettingspagefields_Italian';
  fieldGroupName?: Maybe<Scalars['String']>;
  footerHeading?: Maybe<Scalars['String']>;
  footerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsItalianFooterLinks>;
  headerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsItalianHeaderLinks>;
  loginLabel?: Maybe<Scalars['String']>;
  loginLink?: Maybe<Scalars['String']>;
  navigationSlideHeadline?: Maybe<Scalars['String']>;
  navigationSlideSignOff?: Maybe<Scalars['String']>;
  searchBar?: Maybe<Scalars['String']>;
  siteLink?: Maybe<Scalars['String']>;
  siteTitle?: Maybe<Scalars['String']>;
};

export type AlsHeaderSettingsHeadersettingspagefieldsItalianFooterLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Italian_FooterLinks';
    copyrightNotice?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    privacyPolicyLink?: Maybe<Scalars['String']>;
    privacyPolicyTitle?: Maybe<Scalars['String']>;
    termsLink?: Maybe<Scalars['String']>;
    termsTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsItalianHeaderLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Italian_HeaderLinks';
    collaborationLink?: Maybe<Scalars['String']>;
    collaborationTitle?: Maybe<Scalars['String']>;
    distributionLink?: Maybe<Scalars['String']>;
    distributionTitle?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    linkGroupTitle?: Maybe<Scalars['String']>;
    masteringLink?: Maybe<Scalars['String']>;
    masteringTitle?: Maybe<Scalars['String']>;
    pluginsLink?: Maybe<Scalars['String']>;
    pluginsTitle?: Maybe<Scalars['String']>;
    samplesLink?: Maybe<Scalars['String']>;
    samplesTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsJapanese =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Japanese';
    fieldGroupName?: Maybe<Scalars['String']>;
    footerHeading?: Maybe<Scalars['String']>;
    footerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsJapaneseFooterLinks>;
    headerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsJapaneseHeaderLinks>;
    loginLabel?: Maybe<Scalars['String']>;
    loginLink?: Maybe<Scalars['String']>;
    navigationSlideHeadline?: Maybe<Scalars['String']>;
    navigationSlideSignOff?: Maybe<Scalars['String']>;
    searchBar?: Maybe<Scalars['String']>;
    siteLink?: Maybe<Scalars['String']>;
    siteTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsJapaneseFooterLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Japanese_FooterLinks';
    copyrightNotice?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    privacyPolicyLink?: Maybe<Scalars['String']>;
    privacyPolicyTitle?: Maybe<Scalars['String']>;
    termsLink?: Maybe<Scalars['String']>;
    termsTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsJapaneseHeaderLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Japanese_HeaderLinks';
    collaborationLink?: Maybe<Scalars['String']>;
    collaborationTitle?: Maybe<Scalars['String']>;
    distributionLink?: Maybe<Scalars['String']>;
    distributionTitle?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    linkGroupTitle?: Maybe<Scalars['String']>;
    masteringLink?: Maybe<Scalars['String']>;
    masteringTitle?: Maybe<Scalars['String']>;
    pluginsLink?: Maybe<Scalars['String']>;
    pluginsTitle?: Maybe<Scalars['String']>;
    samplesLink?: Maybe<Scalars['String']>;
    samplesTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsPortuguese =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Portuguese';
    fieldGroupName?: Maybe<Scalars['String']>;
    footerHeading?: Maybe<Scalars['String']>;
    footerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsPortugueseFooterLinks>;
    headerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsPortugueseHeaderLinks>;
    loginLabel?: Maybe<Scalars['String']>;
    loginLink?: Maybe<Scalars['String']>;
    navigationSlideHeadline?: Maybe<Scalars['String']>;
    navigationSlideSignOff?: Maybe<Scalars['String']>;
    searchBar?: Maybe<Scalars['String']>;
    siteLink?: Maybe<Scalars['String']>;
    siteTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsPortugueseFooterLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Portuguese_FooterLinks';
    copyrightNotice?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    privacyPolicyLink?: Maybe<Scalars['String']>;
    privacyPolicyTitle?: Maybe<Scalars['String']>;
    termsLink?: Maybe<Scalars['String']>;
    termsTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsPortugueseHeaderLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Portuguese_HeaderLinks';
    collaborationLink?: Maybe<Scalars['String']>;
    collaborationTitle?: Maybe<Scalars['String']>;
    distributionLink?: Maybe<Scalars['String']>;
    distributionTitle?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    linkGroupTitle?: Maybe<Scalars['String']>;
    masteringLink?: Maybe<Scalars['String']>;
    masteringTitle?: Maybe<Scalars['String']>;
    pluginsLink?: Maybe<Scalars['String']>;
    pluginsTitle?: Maybe<Scalars['String']>;
    samplesLink?: Maybe<Scalars['String']>;
    samplesTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsSpanish = AcfFieldGroup & {
  __typename?: 'AlsHeaderSettings_Headersettingspagefields_Spanish';
  fieldGroupName?: Maybe<Scalars['String']>;
  footerHeading?: Maybe<Scalars['String']>;
  footerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsSpanishFooterLinks>;
  headerLinks?: Maybe<AlsHeaderSettingsHeadersettingspagefieldsSpanishHeaderLinks>;
  loginLabel?: Maybe<Scalars['String']>;
  loginLink?: Maybe<Scalars['String']>;
  navigationSlideHeadline?: Maybe<Scalars['String']>;
  navigationSlideSignOff?: Maybe<Scalars['String']>;
  searchBar?: Maybe<Scalars['String']>;
  siteLink?: Maybe<Scalars['String']>;
  siteTitle?: Maybe<Scalars['String']>;
};

export type AlsHeaderSettingsHeadersettingspagefieldsSpanishFooterLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Spanish_FooterLinks';
    copyrightNotice?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    privacyPolicyLink?: Maybe<Scalars['String']>;
    privacyPolicyTitle?: Maybe<Scalars['String']>;
    termsLink?: Maybe<Scalars['String']>;
    termsTitle?: Maybe<Scalars['String']>;
  };

export type AlsHeaderSettingsHeadersettingspagefieldsSpanishHeaderLinks =
  AcfFieldGroup & {
    __typename?: 'AlsHeaderSettings_Headersettingspagefields_Spanish_HeaderLinks';
    collaborationLink?: Maybe<Scalars['String']>;
    collaborationTitle?: Maybe<Scalars['String']>;
    distributionLink?: Maybe<Scalars['String']>;
    distributionTitle?: Maybe<Scalars['String']>;
    fieldGroupName?: Maybe<Scalars['String']>;
    linkGroupTitle?: Maybe<Scalars['String']>;
    masteringLink?: Maybe<Scalars['String']>;
    masteringTitle?: Maybe<Scalars['String']>;
    pluginsLink?: Maybe<Scalars['String']>;
    pluginsTitle?: Maybe<Scalars['String']>;
    samplesLink?: Maybe<Scalars['String']>;
    samplesTitle?: Maybe<Scalars['String']>;
  };

export type Attribute = {
  __typename?: 'Attribute';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Avatar = {
  __typename?: 'Avatar';
  default?: Maybe<Scalars['String']>;
  extraAttr?: Maybe<Scalars['String']>;
  forceDefault?: Maybe<Scalars['Boolean']>;
  foundAvatar?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<Scalars['String']>;
  scheme?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export enum AvatarRatingEnum {
  G = 'G',
  Pg = 'PG',
  R = 'R',
  X = 'X',
}

export type Block = {
  __typename?: 'Block';
  attributes?: Maybe<Array<Maybe<Attribute>>>;
  connections?: Maybe<Array<Maybe<PostObjectUnion>>>;
  id?: Maybe<Scalars['ID']>;
  innerHtml?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  type?: Maybe<BlockNameEnum>;
};

export enum BlockNameEnum {
  CoreArchives = 'CORE_ARCHIVES',
  CoreAudio = 'CORE_AUDIO',
  CoreBlock = 'CORE_BLOCK',
  CoreButton = 'CORE_BUTTON',
  CoreCategories = 'CORE_CATEGORIES',
  CoreCode = 'CORE_CODE',
  CoreColumn = 'CORE_COLUMN',
  CoreColumns = 'CORE_COLUMNS',
  CoreCoverImage = 'CORE_COVER_IMAGE',
  CoreEmbed = 'CORE_EMBED',
  CoreEmbedAnimoto = 'CORE_EMBED_ANIMOTO',
  CoreEmbedCloudup = 'CORE_EMBED_CLOUDUP',
  CoreEmbedCollegehumor = 'CORE_EMBED_COLLEGEHUMOR',
  CoreEmbedDailymotion = 'CORE_EMBED_DAILYMOTION',
  CoreEmbedFacebook = 'CORE_EMBED_FACEBOOK',
  CoreEmbedFlickr = 'CORE_EMBED_FLICKR',
  CoreEmbedFunnyordie = 'CORE_EMBED_FUNNYORDIE',
  CoreEmbedHulu = 'CORE_EMBED_HULU',
  CoreEmbedImgur = 'CORE_EMBED_IMGUR',
  CoreEmbedInstagram = 'CORE_EMBED_INSTAGRAM',
  CoreEmbedIssuu = 'CORE_EMBED_ISSUU',
  CoreEmbedKickstarter = 'CORE_EMBED_KICKSTARTER',
  CoreEmbedMeetupCom = 'CORE_EMBED_MEETUP_COM',
  CoreEmbedMixcloud = 'CORE_EMBED_MIXCLOUD',
  CoreEmbedPhotobucket = 'CORE_EMBED_PHOTOBUCKET',
  CoreEmbedPolldaddy = 'CORE_EMBED_POLLDADDY',
  CoreEmbedReddit = 'CORE_EMBED_REDDIT',
  CoreEmbedReverbnation = 'CORE_EMBED_REVERBNATION',
  CoreEmbedScreencast = 'CORE_EMBED_SCREENCAST',
  CoreEmbedScribd = 'CORE_EMBED_SCRIBD',
  CoreEmbedSlideshare = 'CORE_EMBED_SLIDESHARE',
  CoreEmbedSmugmug = 'CORE_EMBED_SMUGMUG',
  CoreEmbedSoundcloud = 'CORE_EMBED_SOUNDCLOUD',
  CoreEmbedSpeaker = 'CORE_EMBED_SPEAKER',
  CoreEmbedSpotify = 'CORE_EMBED_SPOTIFY',
  CoreEmbedTed = 'CORE_EMBED_TED',
  CoreEmbedTumblr = 'CORE_EMBED_TUMBLR',
  CoreEmbedTwitter = 'CORE_EMBED_TWITTER',
  CoreEmbedVideopress = 'CORE_EMBED_VIDEOPRESS',
  CoreEmbedVimeo = 'CORE_EMBED_VIMEO',
  CoreEmbedWordpress = 'CORE_EMBED_WORDPRESS',
  CoreEmbedWordpressTv = 'CORE_EMBED_WORDPRESS_TV',
  CoreEmbedYoutube = 'CORE_EMBED_YOUTUBE',
  CoreFile = 'CORE_FILE',
  CoreFreeform = 'CORE_FREEFORM',
  CoreGallery = 'CORE_GALLERY',
  CoreHeading = 'CORE_HEADING',
  CoreHtml = 'CORE_HTML',
  CoreImage = 'CORE_IMAGE',
  CoreLatestComments = 'CORE_LATEST_COMMENTS',
  CoreLatestPosts = 'CORE_LATEST_POSTS',
  CoreList = 'CORE_LIST',
  CoreMore = 'CORE_MORE',
  CoreNextpage = 'CORE_NEXTPAGE',
  CoreParagraph = 'CORE_PARAGRAPH',
  CorePreformatted = 'CORE_PREFORMATTED',
  CorePullquote = 'CORE_PULLQUOTE',
  CoreQuote = 'CORE_QUOTE',
  CoreSeparator = 'CORE_SEPARATOR',
  CoreShortcode = 'CORE_SHORTCODE',
  CoreSpacer = 'CORE_SPACER',
  CoreSubhead = 'CORE_SUBHEAD',
  CoreTable = 'CORE_TABLE',
  CoreTextColumns = 'CORE_TEXT_COLUMNS',
  CoreVerse = 'CORE_VERSE',
  CoreVideo = 'CORE_VIDEO',
  EmbedAnimotoComOembedsCreate = 'EMBED_ANIMOTO_COM_OEMBEDS_CREATE',
  EmbedApiAnghamiComRestV1OembedView = 'EMBED_API_ANGHAMI_COM_REST_V1_OEMBED_VIEW',
  EmbedApiCrowdsignalComOembed = 'EMBED_API_CROWDSIGNAL_COM_OEMBED',
  EmbedApiImgurComOembed = 'EMBED_API_IMGUR_COM_OEMBED',
  EmbedApiScreencastComExternalOembed = 'EMBED_API_SCREENCAST_COM_EXTERNAL_OEMBED',
  EmbedApiSmugmugComServicesOembed = 'EMBED_API_SMUGMUG_COM_SERVICES_OEMBED_',
  EmbedAppMixcloudComOembed = 'EMBED_APP_MIXCLOUD_COM_OEMBED_',
  EmbedAudio = 'EMBED_AUDIO',
  EmbedCloudupComOembed = 'EMBED_CLOUDUP_COM_OEMBED',
  EmbedEmbedSpotifyComOembed = 'EMBED_EMBED_SPOTIFY_COM_OEMBED_',
  EmbedIssuuComOembedWp = 'EMBED_ISSUU_COM_OEMBED_WP',
  EmbedPcaStOembedJson = 'EMBED_PCA_ST_OEMBED_JSON',
  EmbedPublicApiWordpressComOembedForHttps_3A_2F_2FblogApiDevLandrCom = 'EMBED_PUBLIC_API_WORDPRESS_COM_OEMBED__FOR_HTTPS_3A_2F_2FBLOG_API_DEV_LANDR_COM',
  EmbedPublishTwitterComOembed = 'EMBED_PUBLISH_TWITTER_COM_OEMBED',
  EmbedReadAmazonCnKpApiOembed = 'EMBED_READ_AMAZON_CN_KP_API_OEMBED',
  EmbedReadAmazonComAuKpApiOembed = 'EMBED_READ_AMAZON_COM_AU_KP_API_OEMBED',
  EmbedReadAmazonComKpApiOembed = 'EMBED_READ_AMAZON_COM_KP_API_OEMBED',
  EmbedReadAmazonCoUkKpApiOembed = 'EMBED_READ_AMAZON_CO_UK_KP_API_OEMBED',
  EmbedReadAmazonInKpApiOembed = 'EMBED_READ_AMAZON_IN_KP_API_OEMBED',
  EmbedSoundcloudComOembed = 'EMBED_SOUNDCLOUD_COM_OEMBED',
  EmbedSpeakerdeckComOembedFormat = 'EMBED_SPEAKERDECK_COM_OEMBED__FORMAT_',
  EmbedVideo = 'EMBED_VIDEO',
  EmbedVimeoComApiOembedFormat = 'EMBED_VIMEO_COM_API_OEMBED__FORMAT_',
  EmbedWordpressTvOembed = 'EMBED_WORDPRESS_TV_OEMBED_',
  EmbedWwwDailymotionComServicesOembed = 'EMBED_WWW_DAILYMOTION_COM_SERVICES_OEMBED',
  EmbedWwwFlickrComServicesOembed = 'EMBED_WWW_FLICKR_COM_SERVICES_OEMBED_',
  EmbedWwwKickstarterComServicesOembed = 'EMBED_WWW_KICKSTARTER_COM_SERVICES_OEMBED',
  EmbedWwwPinterestComOembedJson = 'EMBED_WWW_PINTEREST_COM_OEMBED_JSON',
  EmbedWwwRedditComOembed = 'EMBED_WWW_REDDIT_COM_OEMBED',
  EmbedWwwReverbnationComOembed = 'EMBED_WWW_REVERBNATION_COM_OEMBED',
  EmbedWwwScribdComServicesOembed = 'EMBED_WWW_SCRIBD_COM_SERVICES_OEMBED',
  EmbedWwwSlideshareNetApiOembed_2 = 'EMBED_WWW_SLIDESHARE_NET_API_OEMBED_2',
  EmbedWwwSomeecardsComV2Oembed = 'EMBED_WWW_SOMEECARDS_COM_V2_OEMBED_',
  EmbedWwwTedComServicesV1OembedFormat = 'EMBED_WWW_TED_COM_SERVICES_V1_OEMBED__FORMAT_',
  EmbedWwwTiktokComOembed = 'EMBED_WWW_TIKTOK_COM_OEMBED',
  EmbedWwwTumblrComOembed_1_0 = 'EMBED_WWW_TUMBLR_COM_OEMBED_1_0',
  EmbedWwwWolframcloudComOembed = 'EMBED_WWW_WOLFRAMCLOUD_COM_OEMBED',
  EmbedWwwYoutubeComOembed = 'EMBED_WWW_YOUTUBE_COM_OEMBED',
  EmbedYoutubeEmbedUrl = 'EMBED_YOUTUBE_EMBED_URL',
  Figure = 'FIGURE',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  Hr = 'HR',
  Img = 'IMG',
  Ol = 'OL',
  P = 'P',
  Pre = 'PRE',
  SafeComment = 'SAFE_COMMENT',
  Script = 'SCRIPT',
  ShortcodeAcf = 'SHORTCODE_ACF',
  ShortcodeAdbutler = 'SHORTCODE_ADBUTLER',
  ShortcodeAdbutlerhb = 'SHORTCODE_ADBUTLERHB',
  ShortcodeAls = 'SHORTCODE_ALS',
  ShortcodeAudio = 'SHORTCODE_AUDIO',
  ShortcodeCaption = 'SHORTCODE_CAPTION',
  ShortcodeEmbed = 'SHORTCODE_EMBED',
  ShortcodeFooterChildthemeLink = 'SHORTCODE_FOOTER_CHILDTHEME_LINK',
  ShortcodeFooterCopyright = 'SHORTCODE_FOOTER_COPYRIGHT',
  ShortcodeFooterGenesisLink = 'SHORTCODE_FOOTER_GENESIS_LINK',
  ShortcodeFooterHomeLink = 'SHORTCODE_FOOTER_HOME_LINK',
  ShortcodeFooterLoginout = 'SHORTCODE_FOOTER_LOGINOUT',
  ShortcodeFooterSiteTitle = 'SHORTCODE_FOOTER_SITE_TITLE',
  ShortcodeFooterStudiopressLink = 'SHORTCODE_FOOTER_STUDIOPRESS_LINK',
  ShortcodeFooterWordpressLink = 'SHORTCODE_FOOTER_WORDPRESS_LINK',
  ShortcodeGallery = 'SHORTCODE_GALLERY',
  ShortcodeIframe = 'SHORTCODE_IFRAME',
  ShortcodeLandrIframe = 'SHORTCODE_LANDR_IFRAME',
  ShortcodeLandrImage = 'SHORTCODE_LANDR_IMAGE',
  ShortcodePlaylist = 'SHORTCODE_PLAYLIST',
  ShortcodePostAuthor = 'SHORTCODE_POST_AUTHOR',
  ShortcodePostAuthorLink = 'SHORTCODE_POST_AUTHOR_LINK',
  ShortcodePostAuthorPostsLink = 'SHORTCODE_POST_AUTHOR_POSTS_LINK',
  ShortcodePostCategories = 'SHORTCODE_POST_CATEGORIES',
  ShortcodePostComments = 'SHORTCODE_POST_COMMENTS',
  ShortcodePostDate = 'SHORTCODE_POST_DATE',
  ShortcodePostEdit = 'SHORTCODE_POST_EDIT',
  ShortcodePostModifiedDate = 'SHORTCODE_POST_MODIFIED_DATE',
  ShortcodePostModifiedTime = 'SHORTCODE_POST_MODIFIED_TIME',
  ShortcodePostTags = 'SHORTCODE_POST_TAGS',
  ShortcodePostTerms = 'SHORTCODE_POST_TERMS',
  ShortcodePostTime = 'SHORTCODE_POST_TIME',
  ShortcodeVideo = 'SHORTCODE_VIDEO',
  ShortcodeWpmlLanguageFormField = 'SHORTCODE_WPML_LANGUAGE_FORM_FIELD',
  ShortcodeWpmlLanguageSelectorFooter = 'SHORTCODE_WPML_LANGUAGE_SELECTOR_FOOTER',
  ShortcodeWpmlLanguageSelectorWidget = 'SHORTCODE_WPML_LANGUAGE_SELECTOR_WIDGET',
  ShortcodeWpmlLanguageSwitcher = 'SHORTCODE_WPML_LANGUAGE_SWITCHER',
  ShortcodeWpmlString = 'SHORTCODE_WPML_STRING',
  ShortcodeWpseoBreadcrumb = 'SHORTCODE_WPSEO_BREADCRUMB',
  ShortcodeWpCaption = 'SHORTCODE_WP_CAPTION',
  Table = 'TABLE',
  Ul = 'UL',
}

export type Category = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Category';
    ancestors?: Maybe<CategoryToAncestorsCategoryConnection>;
    blocks?: Maybe<Array<Maybe<Block>>>;
    categoryDescription?: Maybe<Scalars['String']>;
    /** @deprecated Deprecated in favor of databaseId */
    categoryId?: Maybe<Scalars['Int']>;
    categoryImage?: Maybe<CategoryCategoryimage>;
    children?: Maybe<CategoryToCategoryConnection>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    contentNodes?: Maybe<CategoryToContentNodeConnection>;
    count?: Maybe<Scalars['Int']>;
    databaseId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    link?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    parent?: Maybe<CategoryToParentCategoryConnectionEdge>;
    parentDatabaseId?: Maybe<Scalars['Int']>;
    parentId?: Maybe<Scalars['ID']>;
    posts?: Maybe<CategoryToPostConnection>;
    seo?: Maybe<TaxonomySeo>;
    slug?: Maybe<Scalars['String']>;
    taxonomy?: Maybe<CategoryToTaxonomyConnectionEdge>;
    taxonomyName?: Maybe<Scalars['String']>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    termGroupId?: Maybe<Scalars['Int']>;
    termTaxonomyId?: Maybe<Scalars['Int']>;
    uri?: Maybe<Scalars['String']>;
  };

export type CategoryAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryToCategoryConnectionWhereArgs>;
};

export type CategoryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryToContentNodeConnectionWhereArgs>;
};

export type CategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CategoryPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryToPostConnectionWhereArgs>;
};

export type CategoryConnection = {
  edges: Array<CategoryConnectionEdge>;
  nodes: Array<Category>;
  pageInfo: CategoryConnectionPageInfo;
};

export type CategoryConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Category;
};

export type CategoryConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum CategoryIdType {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  Uri = 'URI',
}

export type CategoryToAncestorsCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'CategoryToAncestorsCategoryConnection';
    edges: Array<CategoryToAncestorsCategoryConnectionEdge>;
    nodes: Array<Category>;
    pageInfo: CategoryToAncestorsCategoryConnectionPageInfo;
  };

export type CategoryToAncestorsCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'CategoryToAncestorsCategoryConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Category;
  };

export type CategoryToAncestorsCategoryConnectionPageInfo =
  CategoryConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'CategoryToAncestorsCategoryConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type CategoryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'CategoryToCategoryConnection';
    edges: Array<CategoryToCategoryConnectionEdge>;
    nodes: Array<Category>;
    pageInfo: CategoryToCategoryConnectionPageInfo;
  };

export type CategoryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'CategoryToCategoryConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Category;
  };

export type CategoryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToCategoryConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type CategoryToCategoryConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type CategoryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'CategoryToContentNodeConnection';
    edges: Array<CategoryToContentNodeConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: CategoryToContentNodeConnectionPageInfo;
  };

export type CategoryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'CategoryToContentNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type CategoryToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'CategoryToContentNodeConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type CategoryToContentNodeConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type CategoryToParentCategoryConnectionEdge = CategoryConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CategoryToParentCategoryConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Category;
  };

export type CategoryToPostConnection = Connection &
  PostConnection & {
    __typename?: 'CategoryToPostConnection';
    edges: Array<CategoryToPostConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: CategoryToPostConnectionPageInfo;
  };

export type CategoryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'CategoryToPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type CategoryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'CategoryToPostConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type CategoryToPostConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  tag?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type CategoryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'CategoryToTaxonomyConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Taxonomy;
  };

export type CategoryCategoryimage = AcfFieldGroup & {
  __typename?: 'Category_Categoryimage';
  categoryDescription?: Maybe<Scalars['String']>;
  categoryImage?: Maybe<MediaItem>;
  categoryImageHeader?: Maybe<MediaItem>;
  fieldGroupName?: Maybe<Scalars['String']>;
};

export type Comment = DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: 'Comment';
    agent?: Maybe<Scalars['String']>;
    /** @deprecated Deprecated in favor of the `status` field */
    approved?: Maybe<Scalars['Boolean']>;
    author?: Maybe<CommentToCommenterConnectionEdge>;
    authorIp?: Maybe<Scalars['String']>;
    /** @deprecated Deprecated in favor of databaseId */
    commentId?: Maybe<Scalars['Int']>;
    commentedOn?: Maybe<CommentToContentNodeConnectionEdge>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    content?: Maybe<Scalars['String']>;
    databaseId: Scalars['Int'];
    date?: Maybe<Scalars['String']>;
    dateGmt?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    karma?: Maybe<Scalars['Int']>;
    link?: Maybe<Scalars['String']>;
    parent?: Maybe<CommentToParentCommentConnectionEdge>;
    parentDatabaseId?: Maybe<Scalars['Int']>;
    parentId?: Maybe<Scalars['ID']>;
    replies?: Maybe<CommentToCommentConnection>;
    status?: Maybe<CommentStatusEnum>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    type?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
  };

export type CommentContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type CommentParentArgs = {
  where?: InputMaybe<CommentToParentCommentConnectionWhereArgs>;
};

export type CommentRepliesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentToCommentConnectionWhereArgs>;
};

export type CommentAuthor = Commenter &
  DatabaseIdentifier &
  Node & {
    __typename?: 'CommentAuthor';
    avatar?: Maybe<Avatar>;
    databaseId: Scalars['Int'];
    email?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
  };

export type CommentAuthorAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']>;
};

export type CommentConnection = {
  edges: Array<CommentConnectionEdge>;
  nodes: Array<Comment>;
  pageInfo: CommentConnectionPageInfo;
};

export type CommentConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Comment;
};

export type CommentConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum CommentNodeIdTypeEnum {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
}

export enum CommentStatusEnum {
  Approve = 'APPROVE',
  Hold = 'HOLD',
  Spam = 'SPAM',
  Trash = 'TRASH',
}

export type CommentToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'CommentToCommentConnection';
    edges: Array<CommentToCommentConnectionEdge>;
    nodes: Array<Comment>;
    pageInfo: CommentToCommentConnectionPageInfo;
  };

export type CommentToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'CommentToCommentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Comment;
  };

export type CommentToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'CommentToCommentConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type CommentToCommentConnectionWhereArgs = {
  authorEmail?: InputMaybe<Scalars['String']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorUrl?: InputMaybe<Scalars['String']>;
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentType?: InputMaybe<Scalars['String']>;
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentName?: InputMaybe<Scalars['String']>;
  contentParent?: InputMaybe<Scalars['Int']>;
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  karma?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  parent?: InputMaybe<Scalars['Int']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type CommentToCommenterConnectionEdge = CommenterConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToCommenterConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Commenter;
  };

export type CommentToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToContentNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type CommentToParentCommentConnectionEdge = CommentConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'CommentToParentCommentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Comment;
  };

export type CommentToParentCommentConnectionWhereArgs = {
  authorEmail?: InputMaybe<Scalars['String']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorUrl?: InputMaybe<Scalars['String']>;
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentType?: InputMaybe<Scalars['String']>;
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentName?: InputMaybe<Scalars['String']>;
  contentParent?: InputMaybe<Scalars['Int']>;
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  karma?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  parent?: InputMaybe<Scalars['Int']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Commenter = {
  avatar?: Maybe<Avatar>;
  databaseId: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CommenterConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Commenter;
};

export enum CommentsConnectionOrderbyEnum {
  CommentAgent = 'COMMENT_AGENT',
  CommentApproved = 'COMMENT_APPROVED',
  CommentAuthor = 'COMMENT_AUTHOR',
  CommentAuthorEmail = 'COMMENT_AUTHOR_EMAIL',
  CommentAuthorIp = 'COMMENT_AUTHOR_IP',
  CommentAuthorUrl = 'COMMENT_AUTHOR_URL',
  CommentContent = 'COMMENT_CONTENT',
  CommentDate = 'COMMENT_DATE',
  CommentDateGmt = 'COMMENT_DATE_GMT',
  CommentId = 'COMMENT_ID',
  CommentIn = 'COMMENT_IN',
  CommentKarma = 'COMMENT_KARMA',
  CommentParent = 'COMMENT_PARENT',
  CommentPostId = 'COMMENT_POST_ID',
  CommentType = 'COMMENT_TYPE',
  UserId = 'USER_ID',
}

export type ConditionalTags = {
  __typename?: 'ConditionalTags';
  /** @deprecated Deprecated in favor of using Next.js pages */
  isArchive?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isAttachment?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isAuthor?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isCategory?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isDate?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isDay?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isFrontPage?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isHome?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isMonth?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isMultiAuthor?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isPage?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isPageTemplate?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isPostTypeArchive?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isPreview?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isPrivacyPolicy?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isSearch?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isSingle?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isSingular?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isSticky?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isTag?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isTax?: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  isYear?: Maybe<Scalars['Boolean']>;
};

export type Connection = {
  edges: Array<Edge>;
  nodes: Array<Node>;
  pageInfo: PageInfo;
};

export type ContentNode = {
  /** @deprecated Deprecated in favor of using Next.js pages */
  conditionalTags?: Maybe<ConditionalTags>;
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  contentTypeName: Scalars['String'];
  databaseId: Scalars['Int'];
  date?: Maybe<Scalars['String']>;
  dateGmt?: Maybe<Scalars['String']>;
  desiredSlug?: Maybe<Scalars['String']>;
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  enclosure?: Maybe<Scalars['String']>;
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isComment: Scalars['Boolean'];
  isContentNode: Scalars['Boolean'];
  isFrontPage: Scalars['Boolean'];
  isPostsPage: Scalars['Boolean'];
  isPreview?: Maybe<Scalars['Boolean']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isTermNode: Scalars['Boolean'];
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  link?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['String']>;
  modifiedGmt?: Maybe<Scalars['String']>;
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  previewRevisionId?: Maybe<Scalars['ID']>;
  seo?: Maybe<PostTypeSeo>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Scalars['String']>;
};

export type ContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ContentNodeConnection = {
  edges: Array<ContentNodeConnectionEdge>;
  nodes: Array<ContentNode>;
  pageInfo: ContentNodeConnectionPageInfo;
};

export type ContentNodeConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: ContentNode;
};

export type ContentNodeConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum ContentNodeIdTypeEnum {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Uri = 'URI',
}

export type ContentNodeToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: 'ContentNodeToContentTypeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentType;
  };

export type ContentNodeToEditLastConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'ContentNodeToEditLastConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: User;
  };

export type ContentNodeToEditLockConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'ContentNodeToEditLockConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    lockTimestamp?: Maybe<Scalars['String']>;
    node: User;
  };

export type ContentNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'ContentNodeToEnqueuedScriptConnection';
    edges: Array<ContentNodeToEnqueuedScriptConnectionEdge>;
    nodes: Array<EnqueuedScript>;
    pageInfo: ContentNodeToEnqueuedScriptConnectionPageInfo;
  };

export type ContentNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'ContentNodeToEnqueuedScriptConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedScript;
  };

export type ContentNodeToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ContentNodeToEnqueuedScriptConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type ContentNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'ContentNodeToEnqueuedStylesheetConnection';
    edges: Array<ContentNodeToEnqueuedStylesheetConnectionEdge>;
    nodes: Array<EnqueuedStylesheet>;
    pageInfo: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  };

export type ContentNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'ContentNodeToEnqueuedStylesheetConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedStylesheet;
  };

export type ContentNodeToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ContentNodeToEnqueuedStylesheetConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type ContentTemplate = {
  templateName?: Maybe<Scalars['String']>;
};

export type ContentType = Node &
  UniformResourceIdentifiable & {
    __typename?: 'ContentType';
    canExport?: Maybe<Scalars['Boolean']>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    connectedTaxonomies?: Maybe<ContentTypeToTaxonomyConnection>;
    contentNodes?: Maybe<ContentTypeToContentNodeConnection>;
    deleteWithUser?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    excludeFromSearch?: Maybe<Scalars['Boolean']>;
    graphqlPluralName?: Maybe<Scalars['String']>;
    graphqlSingleName?: Maybe<Scalars['String']>;
    hasArchive?: Maybe<Scalars['Boolean']>;
    hierarchical?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    label?: Maybe<Scalars['String']>;
    labels?: Maybe<PostTypeLabelDetails>;
    menuIcon?: Maybe<Scalars['String']>;
    menuPosition?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    public?: Maybe<Scalars['Boolean']>;
    publiclyQueryable?: Maybe<Scalars['Boolean']>;
    restBase?: Maybe<Scalars['String']>;
    restControllerClass?: Maybe<Scalars['String']>;
    showInAdminBar?: Maybe<Scalars['Boolean']>;
    showInGraphql?: Maybe<Scalars['Boolean']>;
    showInMenu?: Maybe<Scalars['Boolean']>;
    showInNavMenus?: Maybe<Scalars['Boolean']>;
    showInRest?: Maybe<Scalars['Boolean']>;
    showUi?: Maybe<Scalars['Boolean']>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    uri?: Maybe<Scalars['String']>;
  };

export type ContentTypeConnectedTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ContentTypeContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgs>;
};

export type ContentTypeConnection = {
  edges: Array<ContentTypeConnectionEdge>;
  nodes: Array<ContentType>;
  pageInfo: ContentTypeConnectionPageInfo;
};

export type ContentTypeConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: ContentType;
};

export type ContentTypeConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum ContentTypeEnum {
  Attachment = 'ATTACHMENT',
  Page = 'PAGE',
  Post = 'POST',
}

export enum ContentTypeIdTypeEnum {
  Id = 'ID',
  Name = 'NAME',
}

export type ContentTypeToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'ContentTypeToContentNodeConnection';
    edges: Array<ContentTypeToContentNodeConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: ContentTypeToContentNodeConnectionPageInfo;
  };

export type ContentTypeToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'ContentTypeToContentNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type ContentTypeToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'ContentTypeToContentNodeConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type ContentTypeToContentNodeConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type ContentTypeToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: 'ContentTypeToTaxonomyConnection';
    edges: Array<ContentTypeToTaxonomyConnectionEdge>;
    nodes: Array<Taxonomy>;
    pageInfo: ContentTypeToTaxonomyConnectionPageInfo;
  };

export type ContentTypeToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: 'ContentTypeToTaxonomyConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Taxonomy;
  };

export type ContentTypeToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'ContentTypeToTaxonomyConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export enum ContentTypesOfCategoryEnum {
  Post = 'POST',
}

export enum ContentTypesOfLessonsPostEnum {
  Post = 'POST',
}

export enum ContentTypesOfPostFormatEnum {
  Post = 'POST',
}

export enum ContentTypesOfTagEnum {
  Post = 'POST',
}

export type CreateCategoryInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CreateCategoryPayload = {
  __typename?: 'CreateCategoryPayload';
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCommentInput = {
  approved?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  authorEmail?: InputMaybe<Scalars['String']>;
  authorUrl?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentOn?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<CommentStatusEnum>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateLessonsPostInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CreateLessonsPostPayload = {
  __typename?: 'CreateLessonsPostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  lessonsPost?: Maybe<LessonsPost>;
};

export type CreateMediaItemInput = {
  altText?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<Scalars['ID']>;
  caption?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentStatus?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  dateGmt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<MimeTypeEnum>;
  parentId?: InputMaybe<Scalars['ID']>;
  pingStatus?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MediaItemStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateMediaItemPayload = {
  __typename?: 'CreateMediaItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mediaItem?: Maybe<MediaItem>;
};

export type CreatePageInput = {
  authorId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentStatus?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  menuOrder?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreatePagePayload = {
  __typename?: 'CreatePagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
};

export type CreatePostFormatInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
};

export type CreatePostFormatPayload = {
  __typename?: 'CreatePostFormatPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  postFormat?: Maybe<PostFormat>;
};

export type CreatePostInput = {
  authorId?: InputMaybe<Scalars['ID']>;
  categories?: InputMaybe<PostCategoriesInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentStatus?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  lessonsPosts?: InputMaybe<PostLessonsPostsInput>;
  menuOrder?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  pingStatus?: InputMaybe<Scalars['String']>;
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postFormats?: InputMaybe<PostPostFormatsInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PostStatusEnum>;
  tags?: InputMaybe<PostTagsInput>;
  title?: InputMaybe<Scalars['String']>;
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

export type CreateTagInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
};

export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type CreateUserInput = {
  aim?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  jabber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  nicename?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  registered?: InputMaybe<Scalars['String']>;
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  richEditing?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  username: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
  yim?: InputMaybe<Scalars['String']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type DatabaseIdentifier = {
  databaseId: Scalars['Int'];
};

export type DateInput = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type DateQueryInput = {
  after?: InputMaybe<DateInput>;
  before?: InputMaybe<DateInput>;
  column?: InputMaybe<PostObjectsConnectionDateColumnEnum>;
  compare?: InputMaybe<Scalars['String']>;
  day?: InputMaybe<Scalars['Int']>;
  hour?: InputMaybe<Scalars['Int']>;
  inclusive?: InputMaybe<Scalars['Boolean']>;
  minute?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  relation?: InputMaybe<RelationEnum>;
  second?: InputMaybe<Scalars['Int']>;
  week?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type DefaultTemplate = ContentTemplate & {
  __typename?: 'DefaultTemplate';
  templateName?: Maybe<Scalars['String']>;
};

export type DeleteCategoryInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteCategoryPayload = {
  __typename?: 'DeleteCategoryPayload';
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
};

export type DeleteCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  deletedId?: Maybe<Scalars['ID']>;
};

export type DeleteLessonsPostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteLessonsPostPayload = {
  __typename?: 'DeleteLessonsPostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  lessonsPost?: Maybe<LessonsPost>;
};

export type DeleteMediaItemInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type DeleteMediaItemPayload = {
  __typename?: 'DeleteMediaItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  mediaItem?: Maybe<MediaItem>;
};

export type DeletePageInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
};

export type DeletePagePayload = {
  __typename?: 'DeletePagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  page?: Maybe<Page>;
};

export type DeletePostFormatInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeletePostFormatPayload = {
  __typename?: 'DeletePostFormatPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  postFormat?: Maybe<PostFormat>;
};

export type DeletePostInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  forceDelete?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
};

export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  post?: Maybe<Post>;
};

export type DeleteTagInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  tag?: Maybe<Tag>;
};

export type DeleteUserInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  reassignId?: InputMaybe<Scalars['ID']>;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deletedId?: Maybe<Scalars['ID']>;
  user?: Maybe<User>;
};

export type DiscussionSettings = {
  __typename?: 'DiscussionSettings';
  defaultCommentStatus?: Maybe<Scalars['String']>;
  defaultPingStatus?: Maybe<Scalars['String']>;
};

export type Edge = {
  cursor?: Maybe<Scalars['String']>;
  node: Node;
};

export type EnqueuedAsset = {
  after?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use `EnqueuedAsset.media` instead. */
  args?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Array<Maybe<Scalars['String']>>>;
  conditional?: Maybe<Scalars['String']>;
  dependencies?: Maybe<Array<Maybe<EnqueuedAsset>>>;
  /** @deprecated Use `EnqueuedScript.extraData` instead. */
  extra?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  src?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type EnqueuedScript = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedScript';
    after?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** @deprecated Use `EnqueuedAsset.media` instead. */
    args?: Maybe<Scalars['Boolean']>;
    before?: Maybe<Array<Maybe<Scalars['String']>>>;
    conditional?: Maybe<Scalars['String']>;
    dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
    /** @deprecated Use `EnqueuedScript.extraData` instead. */
    extra?: Maybe<Scalars['String']>;
    extraData?: Maybe<Scalars['String']>;
    handle?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    src?: Maybe<Scalars['String']>;
    strategy?: Maybe<ScriptLoadingStrategyEnum>;
    version?: Maybe<Scalars['String']>;
  };

export type EnqueuedScriptConnection = {
  edges: Array<EnqueuedScriptConnectionEdge>;
  nodes: Array<EnqueuedScript>;
  pageInfo: EnqueuedScriptConnectionPageInfo;
};

export type EnqueuedScriptConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: EnqueuedScript;
};

export type EnqueuedScriptConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type EnqueuedStylesheet = EnqueuedAsset &
  Node & {
    __typename?: 'EnqueuedStylesheet';
    after?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** @deprecated Use `EnqueuedAsset.media` instead. */
    args?: Maybe<Scalars['Boolean']>;
    before?: Maybe<Array<Maybe<Scalars['String']>>>;
    conditional?: Maybe<Scalars['String']>;
    dependencies?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
    /** @deprecated Use `EnqueuedScript.extraData` instead. */
    extra?: Maybe<Scalars['String']>;
    handle?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isRtl?: Maybe<Scalars['Boolean']>;
    media?: Maybe<Scalars['String']>;
    path?: Maybe<Scalars['String']>;
    rel?: Maybe<Scalars['String']>;
    src?: Maybe<Scalars['String']>;
    suffix?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    version?: Maybe<Scalars['String']>;
  };

export type EnqueuedStylesheetConnection = {
  edges: Array<EnqueuedStylesheetConnectionEdge>;
  nodes: Array<EnqueuedStylesheet>;
  pageInfo: EnqueuedStylesheetConnectionPageInfo;
};

export type EnqueuedStylesheetConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: EnqueuedStylesheet;
};

export type EnqueuedStylesheetConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type GeneralSettings = {
  __typename?: 'GeneralSettings';
  dateFormat?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  startOfWeek?: Maybe<Scalars['Int']>;
  timeFormat?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type GenerateAuthorizationCodeInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type GenerateAuthorizationCodePayload = {
  __typename?: 'GenerateAuthorizationCodePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export enum GlobalStylesheetTypesEnum {
  BaseLayoutStyles = 'BASE_LAYOUT_STYLES',
  Presets = 'PRESETS',
  Styles = 'STYLES',
  Variables = 'VARIABLES',
}

export type HierarchicalContentNode = {
  ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /** @deprecated Deprecated in favor of using Next.js pages */
  conditionalTags?: Maybe<ConditionalTags>;
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  contentTypeName: Scalars['String'];
  databaseId: Scalars['Int'];
  date?: Maybe<Scalars['String']>;
  dateGmt?: Maybe<Scalars['String']>;
  desiredSlug?: Maybe<Scalars['String']>;
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  enclosure?: Maybe<Scalars['String']>;
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  guid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isComment: Scalars['Boolean'];
  isContentNode: Scalars['Boolean'];
  isFrontPage: Scalars['Boolean'];
  isPostsPage: Scalars['Boolean'];
  isPreview?: Maybe<Scalars['Boolean']>;
  isRestricted?: Maybe<Scalars['Boolean']>;
  isTermNode: Scalars['Boolean'];
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  link?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['String']>;
  modifiedGmt?: Maybe<Scalars['String']>;
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  parentDatabaseId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  previewRevisionId?: Maybe<Scalars['ID']>;
  seo?: Maybe<PostTypeSeo>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  template?: Maybe<ContentTemplate>;
  templates?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Scalars['String']>;
};

export type HierarchicalContentNodeAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

export type HierarchicalContentNodeChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

export type HierarchicalContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type HierarchicalContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type HierarchicalContentNodeToContentNodeAncestorsConnection =
  Connection &
    ContentNodeConnection & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnection';
      edges: Array<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>;
      nodes: Array<ContentNode>;
      pageInfo: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
    };

export type HierarchicalContentNodeToContentNodeAncestorsConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionEdge';
      cursor?: Maybe<Scalars['String']>;
      node: ContentNode;
    };

export type HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type HierarchicalContentNodeToContentNodeChildrenConnection =
  Connection &
    ContentNodeConnection & {
      __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnection';
      edges: Array<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>;
      nodes: Array<ContentNode>;
      pageInfo: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
    };

export type HierarchicalContentNodeToContentNodeChildrenConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionEdge';
      cursor?: Maybe<Scalars['String']>;
      node: ContentNode;
    };

export type HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type HierarchicalContentNodeToParentContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge &
    OneToOneConnection & {
      __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
      cursor?: Maybe<Scalars['String']>;
      node: ContentNode;
    };

export type HierarchicalNode = {
  databaseId: Scalars['Int'];
  id: Scalars['ID'];
  parentDatabaseId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
};

export type HierarchicalTermNode = {
  /** @deprecated Deprecated in favor of using Next.js pages */
  conditionalTags?: Maybe<ConditionalTags>;
  count?: Maybe<Scalars['Int']>;
  databaseId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  id: Scalars['ID'];
  isComment: Scalars['Boolean'];
  isContentNode: Scalars['Boolean'];
  isFrontPage: Scalars['Boolean'];
  isPostsPage: Scalars['Boolean'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  isTermNode: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentDatabaseId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  taxonomyName?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<Maybe<Scalars['String']>>>;
  termGroupId?: Maybe<Scalars['Int']>;
  termTaxonomyId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type HierarchicalTermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type HierarchicalTermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LanguageInfo = {
  __typename?: 'LanguageInfo';
  code?: Maybe<Scalars['String']>;
  default_locale?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_hidden?: Maybe<Scalars['Boolean']>;
  native_name?: Maybe<Scalars['String']>;
  translated_name?: Maybe<Scalars['String']>;
};

export type LessonsPost = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'LessonsPost';
    ancestors?: Maybe<LessonsPostToAncestorsLessonsPostConnection>;
    children?: Maybe<LessonsPostToLessonsPostConnection>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    contentNodes?: Maybe<LessonsPostToContentNodeConnection>;
    count?: Maybe<Scalars['Int']>;
    databaseId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    /** @deprecated Deprecated in favor of databaseId */
    lessonsPostId?: Maybe<Scalars['Int']>;
    link?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    parent?: Maybe<LessonsPostToParentLessonsPostConnectionEdge>;
    parentDatabaseId?: Maybe<Scalars['Int']>;
    parentId?: Maybe<Scalars['ID']>;
    posts?: Maybe<LessonsPostToPostConnection>;
    seo?: Maybe<TaxonomySeo>;
    slug?: Maybe<Scalars['String']>;
    taxonomy?: Maybe<LessonsPostToTaxonomyConnectionEdge>;
    taxonomyName?: Maybe<Scalars['String']>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    termGroupId?: Maybe<Scalars['Int']>;
    termTaxonomyId?: Maybe<Scalars['Int']>;
    uri?: Maybe<Scalars['String']>;
  };

export type LessonsPostAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LessonsPostChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonsPostToLessonsPostConnectionWhereArgs>;
};

export type LessonsPostContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonsPostToContentNodeConnectionWhereArgs>;
};

export type LessonsPostEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LessonsPostEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LessonsPostPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LessonsPostToPostConnectionWhereArgs>;
};

export type LessonsPostConnection = {
  edges: Array<LessonsPostConnectionEdge>;
  nodes: Array<LessonsPost>;
  pageInfo: LessonsPostConnectionPageInfo;
};

export type LessonsPostConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: LessonsPost;
};

export type LessonsPostConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum LessonsPostIdType {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  Uri = 'URI',
}

export type LessonsPostToAncestorsLessonsPostConnection = Connection &
  LessonsPostConnection & {
    __typename?: 'LessonsPostToAncestorsLessonsPostConnection';
    edges: Array<LessonsPostToAncestorsLessonsPostConnectionEdge>;
    nodes: Array<LessonsPost>;
    pageInfo: LessonsPostToAncestorsLessonsPostConnectionPageInfo;
  };

export type LessonsPostToAncestorsLessonsPostConnectionEdge = Edge &
  LessonsPostConnectionEdge & {
    __typename?: 'LessonsPostToAncestorsLessonsPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: LessonsPost;
  };

export type LessonsPostToAncestorsLessonsPostConnectionPageInfo =
  LessonsPostConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'LessonsPostToAncestorsLessonsPostConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type LessonsPostToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'LessonsPostToContentNodeConnection';
    edges: Array<LessonsPostToContentNodeConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: LessonsPostToContentNodeConnectionPageInfo;
  };

export type LessonsPostToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'LessonsPostToContentNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type LessonsPostToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'LessonsPostToContentNodeConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type LessonsPostToContentNodeConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfLessonsPostEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type LessonsPostToLessonsPostConnection = Connection &
  LessonsPostConnection & {
    __typename?: 'LessonsPostToLessonsPostConnection';
    edges: Array<LessonsPostToLessonsPostConnectionEdge>;
    nodes: Array<LessonsPost>;
    pageInfo: LessonsPostToLessonsPostConnectionPageInfo;
  };

export type LessonsPostToLessonsPostConnectionEdge = Edge &
  LessonsPostConnectionEdge & {
    __typename?: 'LessonsPostToLessonsPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: LessonsPost;
  };

export type LessonsPostToLessonsPostConnectionPageInfo =
  LessonsPostConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'LessonsPostToLessonsPostConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type LessonsPostToLessonsPostConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type LessonsPostToParentLessonsPostConnectionEdge = Edge &
  LessonsPostConnectionEdge &
  OneToOneConnection & {
    __typename?: 'LessonsPostToParentLessonsPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: LessonsPost;
  };

export type LessonsPostToPostConnection = Connection &
  PostConnection & {
    __typename?: 'LessonsPostToPostConnection';
    edges: Array<LessonsPostToPostConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: LessonsPostToPostConnectionPageInfo;
  };

export type LessonsPostToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'LessonsPostToPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type LessonsPostToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'LessonsPostToPostConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type LessonsPostToPostConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  tag?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
};

export type LessonsPostToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'LessonsPostToTaxonomyConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Taxonomy;
  };

export type Locale = {
  __typename?: 'Locale';
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  authToken?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type MediaDetails = {
  __typename?: 'MediaDetails';
  file?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  meta?: Maybe<MediaItemMeta>;
  sizes?: Maybe<Array<Maybe<MediaSize>>>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaDetailsSizesArgs = {
  exclude?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
  include?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
};

export type MediaItem = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: 'MediaItem';
    altText?: Maybe<Scalars['String']>;
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    authorDatabaseId?: Maybe<Scalars['Int']>;
    authorId?: Maybe<Scalars['ID']>;
    caption?: Maybe<Scalars['String']>;
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    commentCount?: Maybe<Scalars['Int']>;
    commentStatus?: Maybe<Scalars['String']>;
    comments?: Maybe<MediaItemToCommentConnection>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    contentTypeName: Scalars['String'];
    databaseId: Scalars['Int'];
    date?: Maybe<Scalars['String']>;
    dateGmt?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    desiredSlug?: Maybe<Scalars['String']>;
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    enclosure?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    fileSize?: Maybe<Scalars['Int']>;
    guid?: Maybe<Scalars['String']>;
    hasPassword?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isPreview?: Maybe<Scalars['Boolean']>;
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    link?: Maybe<Scalars['String']>;
    locale?: Maybe<Locale>;
    localizedWpmlUrl?: Maybe<Scalars['String']>;
    mediaDetails?: Maybe<MediaDetails>;
    /** @deprecated Deprecated in favor of the databaseId field */
    mediaItemId: Scalars['Int'];
    mediaItemUrl?: Maybe<Scalars['String']>;
    mediaType?: Maybe<Scalars['String']>;
    mimeType?: Maybe<Scalars['String']>;
    modified?: Maybe<Scalars['String']>;
    modifiedGmt?: Maybe<Scalars['String']>;
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    parentDatabaseId?: Maybe<Scalars['Int']>;
    parentId?: Maybe<Scalars['ID']>;
    password?: Maybe<Scalars['String']>;
    previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
    previewRevisionId?: Maybe<Scalars['ID']>;
    seo?: Maybe<PostTypeSeo>;
    sizes?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
    sourceUrl?: Maybe<Scalars['String']>;
    srcSet?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    template?: Maybe<ContentTemplate>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    title?: Maybe<Scalars['String']>;
    translated?: Maybe<Array<Maybe<MediaItem>>>;
    translations?: Maybe<Array<Maybe<Translation>>>;
    uri?: Maybe<Scalars['String']>;
  };

export type MediaItemAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

export type MediaItemCaptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type MediaItemChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

export type MediaItemCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MediaItemToCommentConnectionWhereArgs>;
};

export type MediaItemDescriptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type MediaItemEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MediaItemEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MediaItemFileSizeArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

export type MediaItemSizesArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

export type MediaItemSourceUrlArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

export type MediaItemSrcSetArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

export type MediaItemTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type MediaItemConnection = {
  edges: Array<MediaItemConnectionEdge>;
  nodes: Array<MediaItem>;
  pageInfo: MediaItemConnectionPageInfo;
};

export type MediaItemConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: MediaItem;
};

export type MediaItemConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum MediaItemIdType {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Slug = 'SLUG',
  SourceUrl = 'SOURCE_URL',
  Uri = 'URI',
}

export type MediaItemMeta = {
  __typename?: 'MediaItemMeta';
  aperture?: Maybe<Scalars['Float']>;
  camera?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  createdTimestamp?: Maybe<Scalars['Int']>;
  credit?: Maybe<Scalars['String']>;
  focalLength?: Maybe<Scalars['Float']>;
  iso?: Maybe<Scalars['Int']>;
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  orientation?: Maybe<Scalars['String']>;
  shutterSpeed?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export enum MediaItemSizeEnum {
  LandrLightboxImage = 'LANDR_LIGHTBOX_IMAGE',
  LandrSinglePostFeatured = 'LANDR_SINGLE_POST_FEATURED',
  Large = 'LARGE',
  LoopDefaultImage = 'LOOP_DEFAULT_IMAGE',
  Medium = 'MEDIUM',
  MediumLarge = 'MEDIUM_LARGE',
  ShortcodeFeatured = 'SHORTCODE_FEATURED',
  SinglePostGallery = 'SINGLE_POST_GALLERY',
  Thumbnail = 'THUMBNAIL',
  '1536X1536' = '_1536X1536',
  '2048X2048' = '_2048X2048',
}

export enum MediaItemStatusEnum {
  AutoDraft = 'AUTO_DRAFT',
  Inherit = 'INHERIT',
  Private = 'PRIVATE',
  Trash = 'TRASH',
}

export type MediaItemToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'MediaItemToCommentConnection';
    edges: Array<MediaItemToCommentConnectionEdge>;
    nodes: Array<Comment>;
    pageInfo: MediaItemToCommentConnectionPageInfo;
  };

export type MediaItemToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'MediaItemToCommentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Comment;
  };

export type MediaItemToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MediaItemToCommentConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type MediaItemToCommentConnectionWhereArgs = {
  authorEmail?: InputMaybe<Scalars['String']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorUrl?: InputMaybe<Scalars['String']>;
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentType?: InputMaybe<Scalars['String']>;
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentName?: InputMaybe<Scalars['String']>;
  contentParent?: InputMaybe<Scalars['Int']>;
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  karma?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  parent?: InputMaybe<Scalars['Int']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type MediaSize = {
  __typename?: 'MediaSize';
  file?: Maybe<Scalars['String']>;
  fileSize?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
};

export type Menu = DatabaseIdentifier &
  Node & {
    __typename?: 'Menu';
    count?: Maybe<Scalars['Int']>;
    databaseId: Scalars['Int'];
    id: Scalars['ID'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    language?: Maybe<Scalars['String']>;
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
    /** @deprecated Deprecated in favor of the databaseId field */
    menuId?: Maybe<Scalars['Int']>;
    menuItems?: Maybe<MenuToMenuItemConnection>;
    name?: Maybe<Scalars['String']>;
    slug?: Maybe<Scalars['String']>;
  };

export type MenuMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuToMenuItemConnectionWhereArgs>;
};

export type MenuConnection = {
  edges: Array<MenuConnectionEdge>;
  nodes: Array<Menu>;
  pageInfo: MenuConnectionPageInfo;
};

export type MenuConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Menu;
};

export type MenuConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type MenuItem = DatabaseIdentifier &
  Node & {
    __typename?: 'MenuItem';
    childItems?: Maybe<MenuItemToMenuItemConnection>;
    connectedNode?: Maybe<MenuItemToMenuItemLinkableConnectionEdge>;
    /** @deprecated Deprecated in favor of the connectedNode field */
    connectedObject?: Maybe<MenuItemObjectUnion>;
    cssClasses?: Maybe<Array<Maybe<Scalars['String']>>>;
    databaseId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    label?: Maybe<Scalars['String']>;
    linkRelationship?: Maybe<Scalars['String']>;
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
    menu?: Maybe<MenuItemToMenuConnectionEdge>;
    /** @deprecated Deprecated in favor of the databaseId field */
    menuItemId?: Maybe<Scalars['Int']>;
    order?: Maybe<Scalars['Int']>;
    parentDatabaseId?: Maybe<Scalars['Int']>;
    parentId?: Maybe<Scalars['ID']>;
    path?: Maybe<Scalars['String']>;
    target?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    uri?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
  };

export type MenuItemChildItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MenuItemToMenuItemConnectionWhereArgs>;
};

export type MenuItemConnection = {
  edges: Array<MenuItemConnectionEdge>;
  nodes: Array<MenuItem>;
  pageInfo: MenuItemConnectionPageInfo;
};

export type MenuItemConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: MenuItem;
};

export type MenuItemConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type MenuItemLinkable = {
  /** @deprecated Deprecated in favor of using Next.js pages */
  conditionalTags?: Maybe<ConditionalTags>;
  databaseId: Scalars['Int'];
  id: Scalars['ID'];
  isComment: Scalars['Boolean'];
  isContentNode: Scalars['Boolean'];
  isFrontPage: Scalars['Boolean'];
  isPostsPage: Scalars['Boolean'];
  isTermNode: Scalars['Boolean'];
  templates?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Scalars['String']>;
};

export type MenuItemLinkableConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: MenuItemLinkable;
};

export enum MenuItemNodeIdTypeEnum {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
}

export type MenuItemObjectUnion =
  | Category
  | LessonsPost
  | Page
  | Post
  | PostFormat
  | Tag;

export type MenuItemToMenuConnectionEdge = Edge &
  MenuConnectionEdge &
  OneToOneConnection & {
    __typename?: 'MenuItemToMenuConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Menu;
  };

export type MenuItemToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'MenuItemToMenuItemConnection';
    edges: Array<MenuItemToMenuItemConnectionEdge>;
    nodes: Array<MenuItem>;
    pageInfo: MenuItemToMenuItemConnectionPageInfo;
  };

export type MenuItemToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'MenuItemToMenuItemConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: MenuItem;
  };

export type MenuItemToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MenuItemToMenuItemConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type MenuItemToMenuItemConnectionWhereArgs = {
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<MenuLocationEnum>;
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
};

export type MenuItemToMenuItemLinkableConnectionEdge = Edge &
  MenuItemLinkableConnectionEdge &
  OneToOneConnection & {
    __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: MenuItemLinkable;
  };

export enum MenuLocationEnum {
  AlsFooter_1 = 'ALS_FOOTER_1',
  AlsFooter_2 = 'ALS_FOOTER_2',
  AlsFooter_3 = 'ALS_FOOTER_3',
  Footer = 'FOOTER',
  Primary = 'PRIMARY',
}

export enum MenuNodeIdTypeEnum {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Location = 'LOCATION',
  Name = 'NAME',
  Slug = 'SLUG',
}

export type MenuToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'MenuToMenuItemConnection';
    edges: Array<MenuToMenuItemConnectionEdge>;
    nodes: Array<MenuItem>;
    pageInfo: MenuToMenuItemConnectionPageInfo;
  };

export type MenuToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'MenuToMenuItemConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: MenuItem;
  };

export type MenuToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'MenuToMenuItemConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type MenuToMenuItemConnectionWhereArgs = {
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<MenuLocationEnum>;
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
};

export enum MimeTypeEnum {
  ApplicationJava = 'APPLICATION_JAVA',
  ApplicationMsword = 'APPLICATION_MSWORD',
  ApplicationOctetStream = 'APPLICATION_OCTET_STREAM',
  ApplicationOnenote = 'APPLICATION_ONENOTE',
  ApplicationOxps = 'APPLICATION_OXPS',
  ApplicationPdf = 'APPLICATION_PDF',
  ApplicationRar = 'APPLICATION_RAR',
  ApplicationRtf = 'APPLICATION_RTF',
  ApplicationTtafXml = 'APPLICATION_TTAF_XML',
  ApplicationVndAppleKeynote = 'APPLICATION_VND_APPLE_KEYNOTE',
  ApplicationVndAppleNumbers = 'APPLICATION_VND_APPLE_NUMBERS',
  ApplicationVndApplePages = 'APPLICATION_VND_APPLE_PAGES',
  ApplicationVndMsAccess = 'APPLICATION_VND_MS_ACCESS',
  ApplicationVndMsExcel = 'APPLICATION_VND_MS_EXCEL',
  ApplicationVndMsExcelAddinMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12',
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  ApplicationVndMsExcelSheetMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  ApplicationVndMsExcelTemplateMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12',
  ApplicationVndMsPowerpoint = 'APPLICATION_VND_MS_POWERPOINT',
  ApplicationVndMsPowerpointAddinMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12',
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  ApplicationVndMsPowerpointSlideMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12',
  ApplicationVndMsPowerpointTemplateMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12',
  ApplicationVndMsProject = 'APPLICATION_VND_MS_PROJECT',
  ApplicationVndMsWordDocumentMacroenabled_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  ApplicationVndMsWordTemplateMacroenabled_12 = 'APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12',
  ApplicationVndMsWrite = 'APPLICATION_VND_MS_WRITE',
  ApplicationVndMsXpsdocument = 'APPLICATION_VND_MS_XPSDOCUMENT',
  ApplicationVndOasisOpendocumentChart = 'APPLICATION_VND_OASIS_OPENDOCUMENT_CHART',
  ApplicationVndOasisOpendocumentDatabase = 'APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE',
  ApplicationVndOasisOpendocumentFormula = 'APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA',
  ApplicationVndOasisOpendocumentGraphics = 'APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS',
  ApplicationVndOasisOpendocumentPresentation = 'APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION',
  ApplicationVndOasisOpendocumentSpreadsheet = 'APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET',
  ApplicationVndOasisOpendocumentText = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlide = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE',
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE',
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE',
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE',
  ApplicationWordperfect = 'APPLICATION_WORDPERFECT',
  ApplicationX_7ZCompressed = 'APPLICATION_X_7Z_COMPRESSED',
  ApplicationXGzip = 'APPLICATION_X_GZIP',
  ApplicationXTar = 'APPLICATION_X_TAR',
  ApplicationZip = 'APPLICATION_ZIP',
  AudioAac = 'AUDIO_AAC',
  AudioFlac = 'AUDIO_FLAC',
  AudioMidi = 'AUDIO_MIDI',
  AudioMpeg = 'AUDIO_MPEG',
  AudioOgg = 'AUDIO_OGG',
  AudioWav = 'AUDIO_WAV',
  AudioXMatroska = 'AUDIO_X_MATROSKA',
  AudioXMsWax = 'AUDIO_X_MS_WAX',
  AudioXMsWma = 'AUDIO_X_MS_WMA',
  AudioXRealaudio = 'AUDIO_X_REALAUDIO',
  ImageAvif = 'IMAGE_AVIF',
  ImageBmp = 'IMAGE_BMP',
  ImageGif = 'IMAGE_GIF',
  ImageHeic = 'IMAGE_HEIC',
  ImageJpeg = 'IMAGE_JPEG',
  ImagePng = 'IMAGE_PNG',
  ImageTiff = 'IMAGE_TIFF',
  ImageWebp = 'IMAGE_WEBP',
  ImageXIcon = 'IMAGE_X_ICON',
  TextCalendar = 'TEXT_CALENDAR',
  TextCss = 'TEXT_CSS',
  TextCsv = 'TEXT_CSV',
  TextPlain = 'TEXT_PLAIN',
  TextRichtext = 'TEXT_RICHTEXT',
  TextTabSeparatedValues = 'TEXT_TAB_SEPARATED_VALUES',
  TextVtt = 'TEXT_VTT',
  Video_3Gpp = 'VIDEO_3GPP',
  Video_3Gpp2 = 'VIDEO_3GPP2',
  VideoAvi = 'VIDEO_AVI',
  VideoDivx = 'VIDEO_DIVX',
  VideoMp4 = 'VIDEO_MP4',
  VideoMpeg = 'VIDEO_MPEG',
  VideoOgg = 'VIDEO_OGG',
  VideoQuicktime = 'VIDEO_QUICKTIME',
  VideoWebm = 'VIDEO_WEBM',
  VideoXFlv = 'VIDEO_X_FLV',
  VideoXMatroska = 'VIDEO_X_MATROSKA',
  VideoXMsAsf = 'VIDEO_X_MS_ASF',
  VideoXMsWm = 'VIDEO_X_MS_WM',
  VideoXMsWmv = 'VIDEO_X_MS_WMV',
  VideoXMsWmx = 'VIDEO_X_MS_WMX',
}

export type Node = {
  id: Scalars['ID'];
};

export type NodeWithAuthor = {
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  authorDatabaseId?: Maybe<Scalars['Int']>;
  authorId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type NodeWithAuthorToUserConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: 'NodeWithAuthorToUserConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: User;
  };

export type NodeWithComments = {
  commentCount?: Maybe<Scalars['Int']>;
  commentStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type NodeWithContentEditor = {
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type NodeWithContentEditorContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type NodeWithExcerpt = {
  excerpt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type NodeWithExcerptExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type NodeWithFeaturedImage = {
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  featuredImageDatabaseId?: Maybe<Scalars['Int']>;
  featuredImageId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type NodeWithFeaturedImageToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge &
  OneToOneConnection & {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: MediaItem;
  };

export type NodeWithPageAttributes = {
  id: Scalars['ID'];
  menuOrder?: Maybe<Scalars['Int']>;
};

export type NodeWithRevisions = {
  id: Scalars['ID'];
  isRevision?: Maybe<Scalars['Boolean']>;
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
};

export type NodeWithRevisionsToContentNodeConnectionEdge =
  ContentNodeConnectionEdge &
    Edge &
    OneToOneConnection & {
      __typename?: 'NodeWithRevisionsToContentNodeConnectionEdge';
      cursor?: Maybe<Scalars['String']>;
      node: ContentNode;
    };

export type NodeWithTemplate = {
  id: Scalars['ID'];
  template?: Maybe<ContentTemplate>;
};

export type NodeWithTitle = {
  id: Scalars['ID'];
  seo?: Maybe<PostTypeSeo>;
  title?: Maybe<Scalars['String']>;
};

export type NodeWithTitleTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type NodeWithTrackbacks = {
  id: Scalars['ID'];
  pingStatus?: Maybe<Scalars['String']>;
  pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OneToOneConnection = {
  cursor?: Maybe<Scalars['String']>;
  node: Node;
};

export enum OrderEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Page = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Page';
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    authorDatabaseId?: Maybe<Scalars['Int']>;
    authorId?: Maybe<Scalars['ID']>;
    blocks?: Maybe<Array<Maybe<Block>>>;
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    commentCount?: Maybe<Scalars['Int']>;
    commentStatus?: Maybe<Scalars['String']>;
    comments?: Maybe<PageToCommentConnection>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    content?: Maybe<Scalars['String']>;
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    contentTypeName: Scalars['String'];
    databaseId: Scalars['Int'];
    date?: Maybe<Scalars['String']>;
    dateGmt?: Maybe<Scalars['String']>;
    desiredSlug?: Maybe<Scalars['String']>;
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    enclosure?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    featuredImageDatabaseId?: Maybe<Scalars['Int']>;
    featuredImageId?: Maybe<Scalars['ID']>;
    guid?: Maybe<Scalars['String']>;
    hasPassword?: Maybe<Scalars['Boolean']>;
    homepageFirstBlock?: Maybe<PageHomepagefirstblock>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isPreview?: Maybe<Scalars['Boolean']>;
    isPrivacyPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isRevision?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    link?: Maybe<Scalars['String']>;
    locale?: Maybe<Locale>;
    localizedWpmlUrl?: Maybe<Scalars['String']>;
    menuOrder?: Maybe<Scalars['Int']>;
    modified?: Maybe<Scalars['String']>;
    modifiedGmt?: Maybe<Scalars['String']>;
    notFoundAdditionalFields?: Maybe<PageNotfoundadditionalfields>;
    /** @deprecated Deprecated in favor of the databaseId field */
    pageId: Scalars['Int'];
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    parentDatabaseId?: Maybe<Scalars['Int']>;
    parentId?: Maybe<Scalars['ID']>;
    password?: Maybe<Scalars['String']>;
    preview?: Maybe<PageToPreviewConnectionEdge>;
    previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
    previewRevisionId?: Maybe<Scalars['ID']>;
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    revisions?: Maybe<PageToRevisionConnection>;
    seo?: Maybe<PostTypeSeo>;
    slug?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    template?: Maybe<ContentTemplate>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    title?: Maybe<Scalars['String']>;
    translated?: Maybe<Array<Maybe<Page>>>;
    translations?: Maybe<Array<Maybe<Translation>>>;
    uri?: Maybe<Scalars['String']>;
  };

export type PageAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

export type PageChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

export type PageCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageToCommentConnectionWhereArgs>;
};

export type PageContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type PageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PageRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageToRevisionConnectionWhereArgs>;
};

export type PageTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type PageConnection = {
  edges: Array<PageConnectionEdge>;
  nodes: Array<Page>;
  pageInfo: PageConnectionPageInfo;
};

export type PageConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Page;
};

export type PageConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum PageIdType {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Uri = 'URI',
}

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PageToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'PageToCommentConnection';
    edges: Array<PageToCommentConnectionEdge>;
    nodes: Array<Comment>;
    pageInfo: PageToCommentConnectionPageInfo;
  };

export type PageToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'PageToCommentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Comment;
  };

export type PageToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PageToCommentConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PageToCommentConnectionWhereArgs = {
  authorEmail?: InputMaybe<Scalars['String']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorUrl?: InputMaybe<Scalars['String']>;
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentType?: InputMaybe<Scalars['String']>;
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentName?: InputMaybe<Scalars['String']>;
  contentParent?: InputMaybe<Scalars['Int']>;
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  karma?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  parent?: InputMaybe<Scalars['Int']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type PageToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PageConnectionEdge & {
    __typename?: 'PageToPreviewConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Page;
  };

export type PageToRevisionConnection = Connection &
  PageConnection & {
    __typename?: 'PageToRevisionConnection';
    edges: Array<PageToRevisionConnectionEdge>;
    nodes: Array<Page>;
    pageInfo: PageToRevisionConnectionPageInfo;
  };

export type PageToRevisionConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'PageToRevisionConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Page;
  };

export type PageToRevisionConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PageToRevisionConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PageToRevisionConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type PageHomepagefirstblock = AcfFieldGroup & {
  __typename?: 'Page_Homepagefirstblock';
  accentColor?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  image1?: Maybe<MediaItem>;
  image2?: Maybe<MediaItem>;
  image3?: Maybe<MediaItem>;
  image4?: Maybe<MediaItem>;
  title?: Maybe<Scalars['String']>;
};

export type PageNotfoundadditionalfields = AcfFieldGroup & {
  __typename?: 'Page_Notfoundadditionalfields';
  fieldGroupName?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Plugin = Node & {
  __typename?: 'Plugin';
  author?: Maybe<Scalars['String']>;
  authorUri?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  pluginUri?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type PluginConnection = {
  edges: Array<PluginConnectionEdge>;
  nodes: Array<Plugin>;
  pageInfo: PluginConnectionPageInfo;
};

export type PluginConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Plugin;
};

export type PluginConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum PluginStatusEnum {
  Active = 'ACTIVE',
  DropIn = 'DROP_IN',
  Inactive = 'INACTIVE',
  MustUse = 'MUST_USE',
  Paused = 'PAUSED',
  RecentlyActive = 'RECENTLY_ACTIVE',
  Upgrade = 'UPGRADE',
}

export type Post = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  NodeWithTrackbacks &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: 'Post';
    /** @deprecated This content type is not hierarchical and typically will not have ancestors */
    ancestors?: Maybe<PostToPostConnection>;
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    authorDatabaseId?: Maybe<Scalars['Int']>;
    authorId?: Maybe<Scalars['ID']>;
    blocks?: Maybe<Array<Maybe<Block>>>;
    categories?: Maybe<PostToCategoryConnection>;
    commentCount?: Maybe<Scalars['Int']>;
    commentStatus?: Maybe<Scalars['String']>;
    comments?: Maybe<PostToCommentConnection>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    content?: Maybe<Scalars['String']>;
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    contentTypeName: Scalars['String'];
    databaseId: Scalars['Int'];
    date?: Maybe<Scalars['String']>;
    dateGmt?: Maybe<Scalars['String']>;
    desiredSlug?: Maybe<Scalars['String']>;
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    enclosure?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    excerpt?: Maybe<Scalars['String']>;
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    featuredImageDatabaseId?: Maybe<Scalars['Int']>;
    featuredImageId?: Maybe<Scalars['ID']>;
    guid?: Maybe<Scalars['String']>;
    hasPassword?: Maybe<Scalars['Boolean']>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isPreview?: Maybe<Scalars['Boolean']>;
    isRestricted?: Maybe<Scalars['Boolean']>;
    isRevision?: Maybe<Scalars['Boolean']>;
    isSticky: Scalars['Boolean'];
    isTermNode: Scalars['Boolean'];
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    lessonsAdditionalFields?: Maybe<PostLessonsadditionalfields>;
    lessonsPosts?: Maybe<PostToLessonsPostConnection>;
    link?: Maybe<Scalars['String']>;
    locale?: Maybe<Locale>;
    localizedWpmlUrl?: Maybe<Scalars['String']>;
    modified?: Maybe<Scalars['String']>;
    modifiedGmt?: Maybe<Scalars['String']>;
    /** @deprecated This content type is not hierarchical and typically will not have a parent */
    parent?: Maybe<PostToParentConnectionEdge>;
    password?: Maybe<Scalars['String']>;
    pingStatus?: Maybe<Scalars['String']>;
    pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
    postAdditionalFields?: Maybe<PostPostadditionalfields>;
    postFormat?: Maybe<Scalars['String']>;
    postFormatVideo?: Maybe<PostPostformatvideo>;
    postFormats?: Maybe<PostToPostFormatConnection>;
    /** @deprecated Deprecated in favor of the databaseId field */
    postId: Scalars['Int'];
    postOptions?: Maybe<PostPostoptions>;
    preview?: Maybe<PostToPreviewConnectionEdge>;
    previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
    previewRevisionId?: Maybe<Scalars['ID']>;
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    revisions?: Maybe<PostToRevisionConnection>;
    seo?: Maybe<PostTypeSeo>;
    slug?: Maybe<Scalars['String']>;
    status?: Maybe<Scalars['String']>;
    tags?: Maybe<PostToTagConnection>;
    template?: Maybe<ContentTemplate>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    terms?: Maybe<PostToTermNodeConnection>;
    title?: Maybe<Scalars['String']>;
    toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
    translated?: Maybe<Array<Maybe<Post>>>;
    translations?: Maybe<Array<Maybe<Translation>>>;
    uri?: Maybe<Scalars['String']>;
  };

export type PostAncestorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PostCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToCategoryConnectionWhereArgs>;
};

export type PostCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToCommentConnectionWhereArgs>;
};

export type PostContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type PostEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PostEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PostExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type PostLessonsPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToLessonsPostConnectionWhereArgs>;
};

export type PostPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToPostFormatConnectionWhereArgs>;
};

export type PostRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToRevisionConnectionWhereArgs>;
};

export type PostTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToTagConnectionWhereArgs>;
};

export type PostTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostToTermNodeConnectionWhereArgs>;
};

export type PostTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

export type PostCategoriesInput = {
  append?: InputMaybe<Scalars['Boolean']>;
  nodes?: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>;
};

export type PostCategoriesNodeInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type PostConnection = {
  edges: Array<PostConnectionEdge>;
  nodes: Array<Post>;
  pageInfo: PostConnectionPageInfo;
};

export type PostConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Post;
};

export type PostConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type PostFormat = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'PostFormat';
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    contentNodes?: Maybe<PostFormatToContentNodeConnection>;
    count?: Maybe<Scalars['Int']>;
    databaseId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    link?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    /** @deprecated Deprecated in favor of databaseId */
    postFormatId?: Maybe<Scalars['Int']>;
    posts?: Maybe<PostFormatToPostConnection>;
    seo?: Maybe<TaxonomySeo>;
    slug?: Maybe<Scalars['String']>;
    taxonomy?: Maybe<PostFormatToTaxonomyConnectionEdge>;
    taxonomyName?: Maybe<Scalars['String']>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    termGroupId?: Maybe<Scalars['Int']>;
    termTaxonomyId?: Maybe<Scalars['Int']>;
    uri?: Maybe<Scalars['String']>;
  };

export type PostFormatContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostFormatToContentNodeConnectionWhereArgs>;
};

export type PostFormatEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PostFormatEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type PostFormatPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PostFormatToPostConnectionWhereArgs>;
};

export type PostFormatConnection = {
  edges: Array<PostFormatConnectionEdge>;
  nodes: Array<PostFormat>;
  pageInfo: PostFormatConnectionPageInfo;
};

export type PostFormatConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: PostFormat;
};

export type PostFormatConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum PostFormatIdType {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  Uri = 'URI',
}

export type PostFormatToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'PostFormatToContentNodeConnection';
    edges: Array<PostFormatToContentNodeConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: PostFormatToContentNodeConnectionPageInfo;
  };

export type PostFormatToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'PostFormatToContentNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type PostFormatToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'PostFormatToContentNodeConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type PostFormatToContentNodeConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type PostFormatToPostConnection = Connection &
  PostConnection & {
    __typename?: 'PostFormatToPostConnection';
    edges: Array<PostFormatToPostConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: PostFormatToPostConnectionPageInfo;
  };

export type PostFormatToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'PostFormatToPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type PostFormatToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostFormatToPostConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostFormatToPostConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  tag?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type PostFormatToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'PostFormatToTaxonomyConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Taxonomy;
  };

export enum PostIdType {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Slug = 'SLUG',
  Uri = 'URI',
}

export type PostLessonsPostsInput = {
  append?: InputMaybe<Scalars['Boolean']>;
  nodes?: InputMaybe<Array<InputMaybe<PostLessonsPostsNodeInput>>>;
};

export type PostLessonsPostsNodeInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum PostObjectFieldFormatEnum {
  Raw = 'RAW',
  Rendered = 'RENDERED',
}

export type PostObjectUnion = MediaItem | Page | Post;

export enum PostObjectsConnectionDateColumnEnum {
  Date = 'DATE',
  Modified = 'MODIFIED',
}

export enum PostObjectsConnectionOrderbyEnum {
  Author = 'AUTHOR',
  CommentCount = 'COMMENT_COUNT',
  Date = 'DATE',
  In = 'IN',
  MenuOrder = 'MENU_ORDER',
  Modified = 'MODIFIED',
  NameIn = 'NAME_IN',
  Parent = 'PARENT',
  Slug = 'SLUG',
  Title = 'TITLE',
}

export type PostObjectsConnectionOrderbyInput = {
  field: PostObjectsConnectionOrderbyEnum;
  order: OrderEnum;
};

export type PostPostFormatsInput = {
  append?: InputMaybe<Scalars['Boolean']>;
  nodes?: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>;
};

export type PostPostFormatsNodeInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum PostStatusEnum {
  AcfDisabled = 'ACF_DISABLED',
  AutoDraft = 'AUTO_DRAFT',
  Draft = 'DRAFT',
  Future = 'FUTURE',
  Inherit = 'INHERIT',
  Pending = 'PENDING',
  Private = 'PRIVATE',
  Publish = 'PUBLISH',
  RequestCompleted = 'REQUEST_COMPLETED',
  RequestConfirmed = 'REQUEST_CONFIRMED',
  RequestFailed = 'REQUEST_FAILED',
  RequestPending = 'REQUEST_PENDING',
  Trash = 'TRASH',
}

export type PostTagsInput = {
  append?: InputMaybe<Scalars['Boolean']>;
  nodes?: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>;
};

export type PostTagsNodeInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type PostToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'PostToCategoryConnection';
    edges: Array<PostToCategoryConnectionEdge>;
    nodes: Array<Category>;
    pageInfo: PostToCategoryConnectionPageInfo;
  };

export type PostToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'PostToCategoryConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    isPrimary?: Maybe<Scalars['Boolean']>;
    node: Category;
  };

export type PostToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PostToCategoryConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostToCategoryConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type PostToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'PostToCommentConnection';
    edges: Array<PostToCommentConnectionEdge>;
    nodes: Array<Comment>;
    pageInfo: PostToCommentConnectionPageInfo;
  };

export type PostToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'PostToCommentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Comment;
  };

export type PostToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'PostToCommentConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostToCommentConnectionWhereArgs = {
  authorEmail?: InputMaybe<Scalars['String']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorUrl?: InputMaybe<Scalars['String']>;
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentType?: InputMaybe<Scalars['String']>;
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentName?: InputMaybe<Scalars['String']>;
  contentParent?: InputMaybe<Scalars['Int']>;
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  karma?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  parent?: InputMaybe<Scalars['Int']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type PostToLessonsPostConnection = Connection &
  LessonsPostConnection & {
    __typename?: 'PostToLessonsPostConnection';
    edges: Array<PostToLessonsPostConnectionEdge>;
    nodes: Array<LessonsPost>;
    pageInfo: PostToLessonsPostConnectionPageInfo;
  };

export type PostToLessonsPostConnectionEdge = Edge &
  LessonsPostConnectionEdge & {
    __typename?: 'PostToLessonsPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    isPrimary?: Maybe<Scalars['Boolean']>;
    node: LessonsPost;
  };

export type PostToLessonsPostConnectionPageInfo =
  LessonsPostConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'PostToLessonsPostConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type PostToLessonsPostConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
};

export type PostToParentConnectionEdge = Edge &
  OneToOneConnection &
  PostConnectionEdge & {
    __typename?: 'PostToParentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    /** @deprecated This content type is not hierarchical and typically will not have a parent */
    node: Post;
  };

export type PostToPostConnection = Connection &
  PostConnection & {
    __typename?: 'PostToPostConnection';
    edges: Array<PostToPostConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: PostToPostConnectionPageInfo;
  };

export type PostToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'PostToPostConnectionEdge';
    /** @deprecated This content type is not hierarchical and typically will not have ancestors */
    cursor?: Maybe<Scalars['String']>;
    /** @deprecated This content type is not hierarchical and typically will not have ancestors */
    node: Post;
  };

export type PostToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToPostConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: 'PostToPostFormatConnection';
    edges: Array<PostToPostFormatConnectionEdge>;
    nodes: Array<PostFormat>;
    pageInfo: PostToPostFormatConnectionPageInfo;
  };

export type PostToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: 'PostToPostFormatConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    isPrimary?: Maybe<Scalars['Boolean']>;
    node: PostFormat;
  };

export type PostToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToPostFormatConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostToPostFormatConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type PostToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PostConnectionEdge & {
    __typename?: 'PostToPreviewConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type PostToRevisionConnection = Connection &
  PostConnection & {
    __typename?: 'PostToRevisionConnection';
    edges: Array<PostToRevisionConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: PostToRevisionConnectionPageInfo;
  };

export type PostToRevisionConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'PostToRevisionConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type PostToRevisionConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToRevisionConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostToRevisionConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  tag?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type PostToTagConnection = Connection &
  TagConnection & {
    __typename?: 'PostToTagConnection';
    edges: Array<PostToTagConnectionEdge>;
    nodes: Array<Tag>;
    pageInfo: PostToTagConnectionPageInfo;
  };

export type PostToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: 'PostToTagConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    isPrimary?: Maybe<Scalars['Boolean']>;
    node: Tag;
  };

export type PostToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToTagConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostToTagConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type PostToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'PostToTermNodeConnection';
    edges: Array<PostToTermNodeConnectionEdge>;
    nodes: Array<TermNode>;
    pageInfo: PostToTermNodeConnectionPageInfo;
  };

export type PostToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'PostToTermNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: TermNode;
  };

export type PostToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'PostToTermNodeConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type PostToTermNodeConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type PostTypeLabelDetails = {
  __typename?: 'PostTypeLabelDetails';
  addNew?: Maybe<Scalars['String']>;
  addNewItem?: Maybe<Scalars['String']>;
  allItems?: Maybe<Scalars['String']>;
  archives?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['String']>;
  editItem?: Maybe<Scalars['String']>;
  featuredImage?: Maybe<Scalars['String']>;
  filterItemsList?: Maybe<Scalars['String']>;
  insertIntoItem?: Maybe<Scalars['String']>;
  itemsList?: Maybe<Scalars['String']>;
  itemsListNavigation?: Maybe<Scalars['String']>;
  menuName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newItem?: Maybe<Scalars['String']>;
  notFound?: Maybe<Scalars['String']>;
  notFoundInTrash?: Maybe<Scalars['String']>;
  parentItemColon?: Maybe<Scalars['String']>;
  removeFeaturedImage?: Maybe<Scalars['String']>;
  searchItems?: Maybe<Scalars['String']>;
  setFeaturedImage?: Maybe<Scalars['String']>;
  singularName?: Maybe<Scalars['String']>;
  uploadedToThisItem?: Maybe<Scalars['String']>;
  useFeaturedImage?: Maybe<Scalars['String']>;
  viewItem?: Maybe<Scalars['String']>;
  viewItems?: Maybe<Scalars['String']>;
};

export type PostTypeSeo = {
  __typename?: 'PostTypeSEO';
  breadcrumbs?: Maybe<Array<Maybe<SeoPostTypeBreadcrumbs>>>;
  canonical?: Maybe<Scalars['String']>;
  cornerstone?: Maybe<Scalars['Boolean']>;
  focuskw?: Maybe<Scalars['String']>;
  fullHead?: Maybe<Scalars['String']>;
  metaDesc?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaRobotsNofollow?: Maybe<Scalars['String']>;
  metaRobotsNoindex?: Maybe<Scalars['String']>;
  opengraphAuthor?: Maybe<Scalars['String']>;
  opengraphDescription?: Maybe<Scalars['String']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<Scalars['String']>;
  opengraphPublishedTime?: Maybe<Scalars['String']>;
  opengraphPublisher?: Maybe<Scalars['String']>;
  opengraphSiteName?: Maybe<Scalars['String']>;
  opengraphTitle?: Maybe<Scalars['String']>;
  opengraphType?: Maybe<Scalars['String']>;
  opengraphUrl?: Maybe<Scalars['String']>;
  readingTime?: Maybe<Scalars['Float']>;
  schema?: Maybe<SeoPostTypeSchema>;
  title?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

export type PostLessonsadditionalfields = AcfFieldGroup & {
  __typename?: 'Post_Lessonsadditionalfields';
  fieldGroupName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
};

export type PostPostadditionalfields = AcfFieldGroup & {
  __typename?: 'Post_Postadditionalfields';
  accentColor?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  lessonNavigation?: Maybe<PostPostadditionalfieldsLessonNavigation>;
  minutesToRead?: Maybe<Scalars['String']>;
};

export type PostPostadditionalfieldsLessonNavigation = AcfFieldGroup & {
  __typename?: 'Post_Postadditionalfields_LessonNavigation';
  fieldGroupName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  visibility?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PostPostformatvideo = AcfFieldGroup & {
  __typename?: 'Post_Postformatvideo';
  fieldGroupName?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['String']>;
};

export type PostPostoptions = AcfFieldGroup & {
  __typename?: 'Post_Postoptions';
  afterContentSidebarColor?: Maybe<Scalars['String']>;
  afterContentSidebarImage?: Maybe<MediaItem>;
  customYoullLikeThatToo?: Maybe<Scalars['Boolean']>;
  feedExcerpt?: Maybe<Scalars['String']>;
  feedTitle?: Maybe<Scalars['String']>;
  fieldGroupName?: Maybe<Scalars['String']>;
  postBackgroundColor?: Maybe<Scalars['String']>;
  postTitleColor?: Maybe<Scalars['String']>;
  youllLikeThatToo?: Maybe<Array<Maybe<PostPostoptionsYoullLikeThatToo>>>;
};

export type PostPostoptionsYoullLikeThatToo = AcfFieldGroup & {
  __typename?: 'Post_Postoptions_youllLikeThatToo';
  fieldGroupName?: Maybe<Scalars['String']>;
  post?: Maybe<PostPostoptionsYoullLikeThatTooPost>;
};

export type PostPostoptionsYoullLikeThatTooPost = Post;

export type Previewable = {
  isPreview?: Maybe<Scalars['Boolean']>;
  previewRevisionDatabaseId?: Maybe<Scalars['Int']>;
  previewRevisionId?: Maybe<Scalars['ID']>;
};

export type ReadingSettings = {
  __typename?: 'ReadingSettings';
  pageForPosts?: Maybe<Scalars['Int']>;
  pageOnFront?: Maybe<Scalars['Int']>;
  postsPerPage?: Maybe<Scalars['Int']>;
  showOnFront?: Maybe<Scalars['String']>;
};

export type RedirectionRedirect = {
  __typename?: 'RedirectionRedirect';
  code?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  matchType?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type RedirectionRedirects = {
  __typename?: 'RedirectionRedirects';
  redirects?: Maybe<Array<Maybe<RedirectionRedirect>>>;
};

export type RefreshJwtAuthTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  jwtRefreshToken: Scalars['String'];
};

export type RefreshJwtAuthTokenPayload = {
  __typename?: 'RefreshJwtAuthTokenPayload';
  authToken?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  aim?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  jabber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  nicename?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  registered?: InputMaybe<Scalars['String']>;
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  richEditing?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
  yim?: InputMaybe<Scalars['String']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum RelationEnum {
  And = 'AND',
  Or = 'OR',
}

export type ResetUserPasswordInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  login?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type RestoreCommentInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type RestoreCommentPayload = {
  __typename?: 'RestoreCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  restoredId?: Maybe<Scalars['ID']>;
};

export type RootMutation = {
  __typename?: 'RootMutation';
  createCategory?: Maybe<CreateCategoryPayload>;
  createComment?: Maybe<CreateCommentPayload>;
  createLessonsPost?: Maybe<CreateLessonsPostPayload>;
  createMediaItem?: Maybe<CreateMediaItemPayload>;
  createPage?: Maybe<CreatePagePayload>;
  createPost?: Maybe<CreatePostPayload>;
  createPostFormat?: Maybe<CreatePostFormatPayload>;
  createTag?: Maybe<CreateTagPayload>;
  createUser?: Maybe<CreateUserPayload>;
  deleteCategory?: Maybe<DeleteCategoryPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  deleteLessonsPost?: Maybe<DeleteLessonsPostPayload>;
  deleteMediaItem?: Maybe<DeleteMediaItemPayload>;
  deletePage?: Maybe<DeletePagePayload>;
  deletePost?: Maybe<DeletePostPayload>;
  deletePostFormat?: Maybe<DeletePostFormatPayload>;
  deleteTag?: Maybe<DeleteTagPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  generateAuthorizationCode?: Maybe<GenerateAuthorizationCodePayload>;
  increaseCount?: Maybe<Scalars['Int']>;
  login?: Maybe<LoginPayload>;
  refreshJwtAuthToken?: Maybe<RefreshJwtAuthTokenPayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  restoreComment?: Maybe<RestoreCommentPayload>;
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailPayload>;
  updateCategory?: Maybe<UpdateCategoryPayload>;
  updateComment?: Maybe<UpdateCommentPayload>;
  updateLessonsPost?: Maybe<UpdateLessonsPostPayload>;
  updateMediaItem?: Maybe<UpdateMediaItemPayload>;
  updatePage?: Maybe<UpdatePagePayload>;
  updatePost?: Maybe<UpdatePostPayload>;
  updatePostFormat?: Maybe<UpdatePostFormatPayload>;
  updateSettings?: Maybe<UpdateSettingsPayload>;
  updateTag?: Maybe<UpdateTagPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
};

export type RootMutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type RootMutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type RootMutationCreateLessonsPostArgs = {
  input: CreateLessonsPostInput;
};

export type RootMutationCreateMediaItemArgs = {
  input: CreateMediaItemInput;
};

export type RootMutationCreatePageArgs = {
  input: CreatePageInput;
};

export type RootMutationCreatePostArgs = {
  input: CreatePostInput;
};

export type RootMutationCreatePostFormatArgs = {
  input: CreatePostFormatInput;
};

export type RootMutationCreateTagArgs = {
  input: CreateTagInput;
};

export type RootMutationCreateUserArgs = {
  input: CreateUserInput;
};

export type RootMutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};

export type RootMutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type RootMutationDeleteLessonsPostArgs = {
  input: DeleteLessonsPostInput;
};

export type RootMutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput;
};

export type RootMutationDeletePageArgs = {
  input: DeletePageInput;
};

export type RootMutationDeletePostArgs = {
  input: DeletePostInput;
};

export type RootMutationDeletePostFormatArgs = {
  input: DeletePostFormatInput;
};

export type RootMutationDeleteTagArgs = {
  input: DeleteTagInput;
};

export type RootMutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type RootMutationGenerateAuthorizationCodeArgs = {
  input: GenerateAuthorizationCodeInput;
};

export type RootMutationIncreaseCountArgs = {
  count?: InputMaybe<Scalars['Int']>;
};

export type RootMutationLoginArgs = {
  input: LoginInput;
};

export type RootMutationRefreshJwtAuthTokenArgs = {
  input: RefreshJwtAuthTokenInput;
};

export type RootMutationRegisterUserArgs = {
  input: RegisterUserInput;
};

export type RootMutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type RootMutationRestoreCommentArgs = {
  input: RestoreCommentInput;
};

export type RootMutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};

export type RootMutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

export type RootMutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

export type RootMutationUpdateLessonsPostArgs = {
  input: UpdateLessonsPostInput;
};

export type RootMutationUpdateMediaItemArgs = {
  input: UpdateMediaItemInput;
};

export type RootMutationUpdatePageArgs = {
  input: UpdatePageInput;
};

export type RootMutationUpdatePostArgs = {
  input: UpdatePostInput;
};

export type RootMutationUpdatePostFormatArgs = {
  input: UpdatePostFormatInput;
};

export type RootMutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};

export type RootMutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type RootMutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type RootQuery = {
  __typename?: 'RootQuery';
  allSettings?: Maybe<Settings>;
  alsHeaderSettings?: Maybe<AlsHeaderSettings>;
  categories?: Maybe<RootQueryToCategoryConnection>;
  category?: Maybe<Category>;
  comment?: Maybe<Comment>;
  comments?: Maybe<RootQueryToCommentConnection>;
  contentNode?: Maybe<ContentNode>;
  contentNodes?: Maybe<RootQueryToContentNodeConnection>;
  contentType?: Maybe<ContentType>;
  contentTypes?: Maybe<RootQueryToContentTypeConnection>;
  discussionSettings?: Maybe<DiscussionSettings>;
  generalSettings?: Maybe<GeneralSettings>;
  globalStylesheet?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Maybe<LanguageInfo>>>;
  lessonsPost?: Maybe<LessonsPost>;
  lessonsPosts?: Maybe<RootQueryToLessonsPostConnection>;
  locales?: Maybe<Array<Maybe<Scalars['String']>>>;
  mediaItem?: Maybe<MediaItem>;
  /** @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;) */
  mediaItemBy?: Maybe<MediaItem>;
  mediaItems?: Maybe<RootQueryToMediaItemConnection>;
  menu?: Maybe<Menu>;
  menuItem?: Maybe<MenuItem>;
  menuItems?: Maybe<RootQueryToMenuItemConnection>;
  menus?: Maybe<RootQueryToMenuConnection>;
  node?: Maybe<Node>;
  nodeByUri?: Maybe<UniformResourceIdentifiable>;
  page?: Maybe<Page>;
  /** @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;) */
  pageBy?: Maybe<Page>;
  pages?: Maybe<RootQueryToPageConnection>;
  plugin?: Maybe<Plugin>;
  plugins?: Maybe<RootQueryToPluginConnection>;
  post?: Maybe<Post>;
  /** @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;) */
  postBy?: Maybe<Post>;
  postFormat?: Maybe<PostFormat>;
  postFormats?: Maybe<RootQueryToPostFormatConnection>;
  posts?: Maybe<RootQueryToPostConnection>;
  readingSettings?: Maybe<ReadingSettings>;
  redirection?: Maybe<RedirectionRedirects>;
  registeredScripts?: Maybe<RootQueryToEnqueuedScriptConnection>;
  registeredStylesheets?: Maybe<RootQueryToEnqueuedStylesheetConnection>;
  revisions?: Maybe<RootQueryToRevisionsConnection>;
  seo?: Maybe<SeoConfig>;
  tag?: Maybe<Tag>;
  tags?: Maybe<RootQueryToTagConnection>;
  taxonomies?: Maybe<RootQueryToTaxonomyConnection>;
  taxonomy?: Maybe<Taxonomy>;
  termNode?: Maybe<TermNode>;
  terms?: Maybe<RootQueryToTermNodeConnection>;
  theme?: Maybe<Theme>;
  themes?: Maybe<RootQueryToThemeConnection>;
  user?: Maybe<User>;
  userRole?: Maybe<UserRole>;
  userRoles?: Maybe<RootQueryToUserRoleConnection>;
  users?: Maybe<RootQueryToUserConnection>;
  viewer?: Maybe<User>;
  writingSettings?: Maybe<WritingSettings>;
};

export type RootQueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToCategoryConnectionWhereArgs>;
};

export type RootQueryCategoryArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<CategoryIdType>;
};

export type RootQueryCommentArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<CommentNodeIdTypeEnum>;
};

export type RootQueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToCommentConnectionWhereArgs>;
};

export type RootQueryContentNodeArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  contentType?: InputMaybe<ContentTypeEnum>;
  id: Scalars['ID'];
  idType?: InputMaybe<ContentNodeIdTypeEnum>;
};

export type RootQueryContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToContentNodeConnectionWhereArgs>;
};

export type RootQueryContentTypeArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<ContentTypeIdTypeEnum>;
};

export type RootQueryContentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RootQueryGlobalStylesheetArgs = {
  types?: InputMaybe<Array<InputMaybe<GlobalStylesheetTypesEnum>>>;
};

export type RootQueryLessonsPostArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<LessonsPostIdType>;
};

export type RootQueryLessonsPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToLessonsPostConnectionWhereArgs>;
};

export type RootQueryMediaItemArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<MediaItemIdType>;
};

export type RootQueryMediaItemByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  mediaItemId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};

export type RootQueryMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMediaItemConnectionWhereArgs>;
};

export type RootQueryMenuArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<MenuNodeIdTypeEnum>;
};

export type RootQueryMenuItemArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<MenuItemNodeIdTypeEnum>;
};

export type RootQueryMenuItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
};

export type RootQueryMenusArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMenuConnectionWhereArgs>;
};

export type RootQueryNodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type RootQueryNodeByUriArgs = {
  uri: Scalars['String'];
};

export type RootQueryPageArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PageIdType>;
};

export type RootQueryPageByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  pageId?: InputMaybe<Scalars['Int']>;
  uri?: InputMaybe<Scalars['String']>;
};

export type RootQueryPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPageConnectionWhereArgs>;
};

export type RootQueryPluginArgs = {
  id: Scalars['ID'];
};

export type RootQueryPluginsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPluginConnectionWhereArgs>;
};

export type RootQueryPostArgs = {
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PostIdType>;
};

export type RootQueryPostByArgs = {
  id?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  uri?: InputMaybe<Scalars['String']>;
};

export type RootQueryPostFormatArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<PostFormatIdType>;
};

export type RootQueryPostFormatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPostFormatConnectionWhereArgs>;
};

export type RootQueryPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>;
};

export type RootQueryRegisteredScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RootQueryRegisteredStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RootQueryRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToRevisionsConnectionWhereArgs>;
};

export type RootQueryTagArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<TagIdType>;
};

export type RootQueryTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToTagConnectionWhereArgs>;
};

export type RootQueryTaxonomiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RootQueryTaxonomyArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<TaxonomyIdTypeEnum>;
};

export type RootQueryTermNodeArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<TermNodeIdTypeEnum>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
};

export type RootQueryTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToTermNodeConnectionWhereArgs>;
};

export type RootQueryThemeArgs = {
  id: Scalars['ID'];
};

export type RootQueryThemesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RootQueryUserArgs = {
  id: Scalars['ID'];
  idType?: InputMaybe<UserNodeIdTypeEnum>;
};

export type RootQueryUserRoleArgs = {
  id: Scalars['ID'];
};

export type RootQueryUserRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RootQueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToUserConnectionWhereArgs>;
};

export type RootQueryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: 'RootQueryToCategoryConnection';
    edges: Array<RootQueryToCategoryConnectionEdge>;
    nodes: Array<Category>;
    pageInfo: RootQueryToCategoryConnectionPageInfo;
  };

export type RootQueryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCategoryConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Category;
  };

export type RootQueryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCategoryConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToCategoryConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
};

export type RootQueryToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'RootQueryToCommentConnection';
    edges: Array<RootQueryToCommentConnectionEdge>;
    nodes: Array<Comment>;
    pageInfo: RootQueryToCommentConnectionPageInfo;
  };

export type RootQueryToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToCommentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Comment;
  };

export type RootQueryToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToCommentConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToCommentConnectionWhereArgs = {
  authorEmail?: InputMaybe<Scalars['String']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorUrl?: InputMaybe<Scalars['String']>;
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentType?: InputMaybe<Scalars['String']>;
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentName?: InputMaybe<Scalars['String']>;
  contentParent?: InputMaybe<Scalars['Int']>;
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  karma?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  parent?: InputMaybe<Scalars['Int']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
};

export type RootQueryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'RootQueryToContentNodeConnection';
    edges: Array<RootQueryToContentNodeConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: RootQueryToContentNodeConnectionPageInfo;
  };

export type RootQueryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToContentNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type RootQueryToContentNodeConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToContentNodeConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type RootQueryToContentNodeConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type RootQueryToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: 'RootQueryToContentTypeConnection';
    edges: Array<RootQueryToContentTypeConnectionEdge>;
    nodes: Array<ContentType>;
    pageInfo: RootQueryToContentTypeConnectionPageInfo;
  };

export type RootQueryToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToContentTypeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentType;
  };

export type RootQueryToContentTypeConnectionPageInfo =
  ContentTypeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToContentTypeConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type RootQueryToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'RootQueryToEnqueuedScriptConnection';
    edges: Array<RootQueryToEnqueuedScriptConnectionEdge>;
    nodes: Array<EnqueuedScript>;
    pageInfo: RootQueryToEnqueuedScriptConnectionPageInfo;
  };

export type RootQueryToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'RootQueryToEnqueuedScriptConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedScript;
  };

export type RootQueryToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToEnqueuedScriptConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type RootQueryToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'RootQueryToEnqueuedStylesheetConnection';
    edges: Array<RootQueryToEnqueuedStylesheetConnectionEdge>;
    nodes: Array<EnqueuedStylesheet>;
    pageInfo: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  };

export type RootQueryToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'RootQueryToEnqueuedStylesheetConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedStylesheet;
  };

export type RootQueryToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToEnqueuedStylesheetConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type RootQueryToLessonsPostConnection = Connection &
  LessonsPostConnection & {
    __typename?: 'RootQueryToLessonsPostConnection';
    edges: Array<RootQueryToLessonsPostConnectionEdge>;
    nodes: Array<LessonsPost>;
    pageInfo: RootQueryToLessonsPostConnectionPageInfo;
  };

export type RootQueryToLessonsPostConnectionEdge = Edge &
  LessonsPostConnectionEdge & {
    __typename?: 'RootQueryToLessonsPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: LessonsPost;
  };

export type RootQueryToLessonsPostConnectionPageInfo =
  LessonsPostConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToLessonsPostConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type RootQueryToLessonsPostConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
};

export type RootQueryToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: 'RootQueryToMediaItemConnection';
    edges: Array<RootQueryToMediaItemConnectionEdge>;
    nodes: Array<MediaItem>;
    pageInfo: RootQueryToMediaItemConnectionPageInfo;
  };

export type RootQueryToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: 'RootQueryToMediaItemConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: MediaItem;
  };

export type RootQueryToMediaItemConnectionPageInfo =
  MediaItemConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToMediaItemConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type RootQueryToMediaItemConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type RootQueryToMenuConnection = Connection &
  MenuConnection & {
    __typename?: 'RootQueryToMenuConnection';
    edges: Array<RootQueryToMenuConnectionEdge>;
    nodes: Array<Menu>;
    pageInfo: RootQueryToMenuConnectionPageInfo;
  };

export type RootQueryToMenuConnectionEdge = Edge &
  MenuConnectionEdge & {
    __typename?: 'RootQueryToMenuConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Menu;
  };

export type RootQueryToMenuConnectionPageInfo = MenuConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToMenuConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToMenuConnectionWhereArgs = {
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<MenuLocationEnum>;
  slug?: InputMaybe<Scalars['String']>;
};

export type RootQueryToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: 'RootQueryToMenuItemConnection';
    edges: Array<RootQueryToMenuItemConnectionEdge>;
    nodes: Array<MenuItem>;
    pageInfo: RootQueryToMenuItemConnectionPageInfo;
  };

export type RootQueryToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: 'RootQueryToMenuItemConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: MenuItem;
  };

export type RootQueryToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToMenuItemConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToMenuItemConnectionWhereArgs = {
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<MenuLocationEnum>;
  parentDatabaseId?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
};

export type RootQueryToPageConnection = Connection &
  PageConnection & {
    __typename?: 'RootQueryToPageConnection';
    edges: Array<RootQueryToPageConnectionEdge>;
    nodes: Array<Page>;
    pageInfo: RootQueryToPageConnectionPageInfo;
  };

export type RootQueryToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'RootQueryToPageConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Page;
  };

export type RootQueryToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPageConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToPageConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
};

export type RootQueryToPluginConnection = Connection &
  PluginConnection & {
    __typename?: 'RootQueryToPluginConnection';
    edges: Array<RootQueryToPluginConnectionEdge>;
    nodes: Array<Plugin>;
    pageInfo: RootQueryToPluginConnectionPageInfo;
  };

export type RootQueryToPluginConnectionEdge = Edge &
  PluginConnectionEdge & {
    __typename?: 'RootQueryToPluginConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Plugin;
  };

export type RootQueryToPluginConnectionPageInfo = PageInfo &
  PluginConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPluginConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToPluginConnectionWhereArgs = {
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>;
  status?: InputMaybe<PluginStatusEnum>;
};

export type RootQueryToPostConnection = Connection &
  PostConnection & {
    __typename?: 'RootQueryToPostConnection';
    edges: Array<RootQueryToPostConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: RootQueryToPostConnectionPageInfo;
  };

export type RootQueryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'RootQueryToPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type RootQueryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPostConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToPostConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  tag?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
};

export type RootQueryToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: 'RootQueryToPostFormatConnection';
    edges: Array<RootQueryToPostFormatConnectionEdge>;
    nodes: Array<PostFormat>;
    pageInfo: RootQueryToPostFormatConnectionPageInfo;
  };

export type RootQueryToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: 'RootQueryToPostFormatConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: PostFormat;
  };

export type RootQueryToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToPostFormatConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToPostFormatConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'RootQueryToRevisionsConnection';
    edges: Array<RootQueryToRevisionsConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: RootQueryToRevisionsConnectionPageInfo;
  };

export type RootQueryToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'RootQueryToRevisionsConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type RootQueryToRevisionsConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'RootQueryToRevisionsConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type RootQueryToRevisionsConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type RootQueryToTagConnection = Connection &
  TagConnection & {
    __typename?: 'RootQueryToTagConnection';
    edges: Array<RootQueryToTagConnectionEdge>;
    nodes: Array<Tag>;
    pageInfo: RootQueryToTagConnectionPageInfo;
  };

export type RootQueryToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: 'RootQueryToTagConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Tag;
  };

export type RootQueryToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTagConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToTagConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: 'RootQueryToTaxonomyConnection';
    edges: Array<RootQueryToTaxonomyConnectionEdge>;
    nodes: Array<Taxonomy>;
    pageInfo: RootQueryToTaxonomyConnectionPageInfo;
  };

export type RootQueryToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: 'RootQueryToTaxonomyConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Taxonomy;
  };

export type RootQueryToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTaxonomyConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'RootQueryToTermNodeConnection';
    edges: Array<RootQueryToTermNodeConnectionEdge>;
    nodes: Array<TermNode>;
    pageInfo: RootQueryToTermNodeConnectionPageInfo;
  };

export type RootQueryToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'RootQueryToTermNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: TermNode;
  };

export type RootQueryToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToTermNodeConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToTermNodeConnectionWhereArgs = {
  cacheDomain?: InputMaybe<Scalars['String']>;
  childOf?: InputMaybe<Scalars['Int']>;
  childless?: InputMaybe<Scalars['Boolean']>;
  descriptionLike?: InputMaybe<Scalars['String']>;
  exclude?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hideEmpty?: InputMaybe<Scalars['Boolean']>;
  hierarchical?: InputMaybe<Scalars['Boolean']>;
  include?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nameLike?: InputMaybe<Scalars['String']>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  padCounts?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updateTermMetaCache?: InputMaybe<Scalars['Boolean']>;
};

export type RootQueryToThemeConnection = Connection &
  ThemeConnection & {
    __typename?: 'RootQueryToThemeConnection';
    edges: Array<RootQueryToThemeConnectionEdge>;
    nodes: Array<Theme>;
    pageInfo: RootQueryToThemeConnectionPageInfo;
  };

export type RootQueryToThemeConnectionEdge = Edge &
  ThemeConnectionEdge & {
    __typename?: 'RootQueryToThemeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Theme;
  };

export type RootQueryToThemeConnectionPageInfo = PageInfo &
  ThemeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToThemeConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToUserConnection = Connection &
  UserConnection & {
    __typename?: 'RootQueryToUserConnection';
    edges: Array<RootQueryToUserConnectionEdge>;
    nodes: Array<User>;
    pageInfo: RootQueryToUserConnectionPageInfo;
  };

export type RootQueryToUserConnectionEdge = Edge &
  UserConnectionEdge & {
    __typename?: 'RootQueryToUserConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: User;
  };

export type RootQueryToUserConnectionPageInfo = PageInfo &
  UserConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToUserConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type RootQueryToUserConnectionWhereArgs = {
  exclude?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hasPublishedPosts?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  include?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  login?: InputMaybe<Scalars['String']>;
  loginIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loginNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nicename?: InputMaybe<Scalars['String']>;
  nicenameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nicenameNotIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  orderby?: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>;
  role?: InputMaybe<UserRoleEnum>;
  roleIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  roleNotIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  search?: InputMaybe<Scalars['String']>;
  searchColumns?: InputMaybe<
    Array<InputMaybe<UsersConnectionSearchColumnEnum>>
  >;
};

export type RootQueryToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: 'RootQueryToUserRoleConnection';
    edges: Array<RootQueryToUserRoleConnectionEdge>;
    nodes: Array<UserRole>;
    pageInfo: RootQueryToUserRoleConnectionPageInfo;
  };

export type RootQueryToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: 'RootQueryToUserRoleConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: UserRole;
  };

export type RootQueryToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'RootQueryToUserRoleConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type SeoBreadcrumbs = {
  __typename?: 'SEOBreadcrumbs';
  archivePrefix?: Maybe<Scalars['String']>;
  boldLast?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  homeText?: Maybe<Scalars['String']>;
  notFoundText?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  searchPrefix?: Maybe<Scalars['String']>;
  separator?: Maybe<Scalars['String']>;
  showBlogPage?: Maybe<Scalars['Boolean']>;
};

export enum SeoCardType {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
}

export type SeoConfig = {
  __typename?: 'SEOConfig';
  breadcrumbs?: Maybe<SeoBreadcrumbs>;
  contentTypes?: Maybe<SeoContentTypes>;
  meta?: Maybe<SeoGlobalMeta>;
  openGraph?: Maybe<SeoOpenGraph>;
  redirects?: Maybe<Array<Maybe<SeoRedirect>>>;
  schema?: Maybe<SeoSchema>;
  social?: Maybe<SeoSocial>;
  webmaster?: Maybe<SeoWebmaster>;
};

export type SeoContentType = {
  __typename?: 'SEOContentType';
  archive?: Maybe<SeoContentTypeArchive>;
  metaDesc?: Maybe<Scalars['String']>;
  metaRobotsNoindex?: Maybe<Scalars['Boolean']>;
  schema?: Maybe<SeoPageInfoSchema>;
  schemaType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoContentTypeArchive = {
  __typename?: 'SEOContentTypeArchive';
  archiveLink?: Maybe<Scalars['String']>;
  breadcrumbTitle?: Maybe<Scalars['String']>;
  fullHead?: Maybe<Scalars['String']>;
  hasArchive?: Maybe<Scalars['Boolean']>;
  metaDesc?: Maybe<Scalars['String']>;
  metaRobotsFollow?: Maybe<Scalars['String']>;
  metaRobotsIndex?: Maybe<Scalars['String']>;
  metaRobotsNofollow?: Maybe<Scalars['Boolean']>;
  metaRobotsNoindex?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoContentTypes = {
  __typename?: 'SEOContentTypes';
  mediaItem?: Maybe<SeoContentType>;
  page?: Maybe<SeoContentType>;
  post?: Maybe<SeoContentType>;
};

export type SeoGlobalMeta = {
  __typename?: 'SEOGlobalMeta';
  author?: Maybe<SeoGlobalMetaAuthor>;
  config?: Maybe<SeoGlobalMetaConfig>;
  date?: Maybe<SeoGlobalMetaDate>;
  homepage?: Maybe<SeoGlobalMetaHome>;
  notFound?: Maybe<SeoGlobalMeta404>;
};

export type SeoGlobalMeta404 = {
  __typename?: 'SEOGlobalMeta404';
  breadcrumb?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoGlobalMetaAuthor = {
  __typename?: 'SEOGlobalMetaAuthor';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoGlobalMetaConfig = {
  __typename?: 'SEOGlobalMetaConfig';
  separator?: Maybe<Scalars['String']>;
};

export type SeoGlobalMetaDate = {
  __typename?: 'SEOGlobalMetaDate';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoGlobalMetaHome = {
  __typename?: 'SEOGlobalMetaHome';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SeoOpenGraph = {
  __typename?: 'SEOOpenGraph';
  defaultImage?: Maybe<MediaItem>;
  frontPage?: Maybe<SeoOpenGraphFrontPage>;
};

export type SeoOpenGraphFrontPage = {
  __typename?: 'SEOOpenGraphFrontPage';
  description?: Maybe<Scalars['String']>;
  image?: Maybe<MediaItem>;
  title?: Maybe<Scalars['String']>;
};

export type SeoPageInfoSchema = {
  __typename?: 'SEOPageInfoSchema';
  raw?: Maybe<Scalars['String']>;
};

export type SeoPostTypeBreadcrumbs = {
  __typename?: 'SEOPostTypeBreadcrumbs';
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SeoPostTypePageInfo = {
  __typename?: 'SEOPostTypePageInfo';
  schema?: Maybe<SeoPageInfoSchema>;
};

export type SeoPostTypeSchema = {
  __typename?: 'SEOPostTypeSchema';
  articleType?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageType?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['String']>;
};

export type SeoRedirect = {
  __typename?: 'SEORedirect';
  format?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

export type SeoSchema = {
  __typename?: 'SEOSchema';
  companyLogo?: Maybe<MediaItem>;
  companyName?: Maybe<Scalars['String']>;
  companyOrPerson?: Maybe<Scalars['String']>;
  homeUrl?: Maybe<Scalars['String']>;
  inLanguage?: Maybe<Scalars['String']>;
  logo?: Maybe<MediaItem>;
  personLogo?: Maybe<MediaItem>;
  personName?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  siteUrl?: Maybe<Scalars['String']>;
  wordpressSiteName?: Maybe<Scalars['String']>;
};

export type SeoSocial = {
  __typename?: 'SEOSocial';
  facebook?: Maybe<SeoSocialFacebook>;
  instagram?: Maybe<SeoSocialInstagram>;
  linkedIn?: Maybe<SeoSocialLinkedIn>;
  mySpace?: Maybe<SeoSocialMySpace>;
  otherSocials?: Maybe<Array<Maybe<Scalars['String']>>>;
  pinterest?: Maybe<SeoSocialPinterest>;
  twitter?: Maybe<SeoSocialTwitter>;
  wikipedia?: Maybe<SeoSocialWikipedia>;
  youTube?: Maybe<SeoSocialYoutube>;
};

export type SeoSocialFacebook = {
  __typename?: 'SEOSocialFacebook';
  defaultImage?: Maybe<MediaItem>;
  url?: Maybe<Scalars['String']>;
};

export type SeoSocialInstagram = {
  __typename?: 'SEOSocialInstagram';
  url?: Maybe<Scalars['String']>;
};

export type SeoSocialLinkedIn = {
  __typename?: 'SEOSocialLinkedIn';
  url?: Maybe<Scalars['String']>;
};

export type SeoSocialMySpace = {
  __typename?: 'SEOSocialMySpace';
  url?: Maybe<Scalars['String']>;
};

export type SeoSocialPinterest = {
  __typename?: 'SEOSocialPinterest';
  metaTag?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SeoSocialTwitter = {
  __typename?: 'SEOSocialTwitter';
  cardType?: Maybe<SeoCardType>;
  username?: Maybe<Scalars['String']>;
};

export type SeoSocialWikipedia = {
  __typename?: 'SEOSocialWikipedia';
  url?: Maybe<Scalars['String']>;
};

export type SeoSocialYoutube = {
  __typename?: 'SEOSocialYoutube';
  url?: Maybe<Scalars['String']>;
};

export type SeoTaxonomySchema = {
  __typename?: 'SEOTaxonomySchema';
  raw?: Maybe<Scalars['String']>;
};

export type SeoUser = {
  __typename?: 'SEOUser';
  breadcrumbTitle?: Maybe<Scalars['String']>;
  canonical?: Maybe<Scalars['String']>;
  fullHead?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  metaDesc?: Maybe<Scalars['String']>;
  metaRobotsNofollow?: Maybe<Scalars['String']>;
  metaRobotsNoindex?: Maybe<Scalars['String']>;
  opengraphDescription?: Maybe<Scalars['String']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphTitle?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  schema?: Maybe<SeoUserSchema>;
  social?: Maybe<SeoUserSocial>;
  title?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

export type SeoUserSchema = {
  __typename?: 'SEOUserSchema';
  articleType?: Maybe<Array<Maybe<Scalars['String']>>>;
  pageType?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['String']>;
};

export type SeoUserSocial = {
  __typename?: 'SEOUserSocial';
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  mySpace?: Maybe<Scalars['String']>;
  pinterest?: Maybe<Scalars['String']>;
  soundCloud?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  wikipedia?: Maybe<Scalars['String']>;
  youTube?: Maybe<Scalars['String']>;
};

export type SeoWebmaster = {
  __typename?: 'SEOWebmaster';
  baiduVerify?: Maybe<Scalars['String']>;
  googleVerify?: Maybe<Scalars['String']>;
  msVerify?: Maybe<Scalars['String']>;
  yandexVerify?: Maybe<Scalars['String']>;
};

export enum ScriptLoadingStrategyEnum {
  Async = 'ASYNC',
  Defer = 'DEFER',
}

export type SendPasswordResetEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  /** @deprecated This field will be removed in a future version of WPGraphQL */
  user?: Maybe<User>;
};

export type Settings = {
  __typename?: 'Settings';
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars['String']>;
  discussionSettingsDefaultPingStatus?: Maybe<Scalars['String']>;
  generalSettingsDateFormat?: Maybe<Scalars['String']>;
  generalSettingsDescription?: Maybe<Scalars['String']>;
  generalSettingsEmail?: Maybe<Scalars['String']>;
  generalSettingsLanguage?: Maybe<Scalars['String']>;
  generalSettingsStartOfWeek?: Maybe<Scalars['Int']>;
  generalSettingsTimeFormat?: Maybe<Scalars['String']>;
  generalSettingsTimezone?: Maybe<Scalars['String']>;
  generalSettingsTitle?: Maybe<Scalars['String']>;
  generalSettingsUrl?: Maybe<Scalars['String']>;
  readingSettingsPageForPosts?: Maybe<Scalars['Int']>;
  readingSettingsPageOnFront?: Maybe<Scalars['Int']>;
  readingSettingsPostsPerPage?: Maybe<Scalars['Int']>;
  readingSettingsShowOnFront?: Maybe<Scalars['String']>;
  writingSettingsDefaultCategory?: Maybe<Scalars['Int']>;
  writingSettingsDefaultPostFormat?: Maybe<Scalars['String']>;
  writingSettingsUseSmilies?: Maybe<Scalars['Boolean']>;
};

export type Tag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: 'Tag';
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    contentNodes?: Maybe<TagToContentNodeConnection>;
    count?: Maybe<Scalars['Int']>;
    databaseId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    link?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    posts?: Maybe<TagToPostConnection>;
    seo?: Maybe<TaxonomySeo>;
    slug?: Maybe<Scalars['String']>;
    /** @deprecated Deprecated in favor of databaseId */
    tagId?: Maybe<Scalars['Int']>;
    taxonomy?: Maybe<TagToTaxonomyConnectionEdge>;
    taxonomyName?: Maybe<Scalars['String']>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    termGroupId?: Maybe<Scalars['Int']>;
    termTaxonomyId?: Maybe<Scalars['Int']>;
    uri?: Maybe<Scalars['String']>;
  };

export type TagContentNodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TagToContentNodeConnectionWhereArgs>;
};

export type TagEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TagEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TagPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TagToPostConnectionWhereArgs>;
};

export type TagConnection = {
  edges: Array<TagConnectionEdge>;
  nodes: Array<Tag>;
  pageInfo: TagConnectionPageInfo;
};

export type TagConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Tag;
};

export type TagConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum TagIdType {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  Uri = 'URI',
}

export type TagToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'TagToContentNodeConnection';
    edges: Array<TagToContentNodeConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: TagToContentNodeConnectionPageInfo;
  };

export type TagToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'TagToContentNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type TagToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'TagToContentNodeConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type TagToContentNodeConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type TagToPostConnection = Connection &
  PostConnection & {
    __typename?: 'TagToPostConnection';
    edges: Array<TagToPostConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: TagToPostConnectionPageInfo;
  };

export type TagToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'TagToPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type TagToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TagToPostConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type TagToPostConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  tag?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type TagToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: 'TagToTaxonomyConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Taxonomy;
  };

export type Taxonomy = Node & {
  __typename?: 'Taxonomy';
  connectedContentTypes?: Maybe<TaxonomyToContentTypeConnection>;
  connectedTerms?: Maybe<TaxonomyToTermNodeConnection>;
  description?: Maybe<Scalars['String']>;
  graphqlPluralName?: Maybe<Scalars['String']>;
  graphqlSingleName?: Maybe<Scalars['String']>;
  hierarchical?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  restBase?: Maybe<Scalars['String']>;
  restControllerClass?: Maybe<Scalars['String']>;
  showCloud?: Maybe<Scalars['Boolean']>;
  showInAdminColumn?: Maybe<Scalars['Boolean']>;
  showInGraphql?: Maybe<Scalars['Boolean']>;
  showInMenu?: Maybe<Scalars['Boolean']>;
  showInNavMenus?: Maybe<Scalars['Boolean']>;
  showInQuickEdit?: Maybe<Scalars['Boolean']>;
  showInRest?: Maybe<Scalars['Boolean']>;
  showUi?: Maybe<Scalars['Boolean']>;
};

export type TaxonomyConnectedContentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TaxonomyConnectedTermsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TaxonomyConnection = {
  edges: Array<TaxonomyConnectionEdge>;
  nodes: Array<Taxonomy>;
  pageInfo: TaxonomyConnectionPageInfo;
};

export type TaxonomyConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Taxonomy;
};

export type TaxonomyConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum TaxonomyEnum {
  Category = 'CATEGORY',
  Lessonspost = 'LESSONSPOST',
  Postformat = 'POSTFORMAT',
  Tag = 'TAG',
}

export enum TaxonomyIdTypeEnum {
  Id = 'ID',
  Name = 'NAME',
}

export type TaxonomySeo = {
  __typename?: 'TaxonomySEO';
  breadcrumbs?: Maybe<Array<Maybe<SeoPostTypeBreadcrumbs>>>;
  canonical?: Maybe<Scalars['String']>;
  cornerstone?: Maybe<Scalars['Boolean']>;
  focuskw?: Maybe<Scalars['String']>;
  fullHead?: Maybe<Scalars['String']>;
  metaDesc?: Maybe<Scalars['String']>;
  metaKeywords?: Maybe<Scalars['String']>;
  metaRobotsNofollow?: Maybe<Scalars['String']>;
  metaRobotsNoindex?: Maybe<Scalars['String']>;
  opengraphAuthor?: Maybe<Scalars['String']>;
  opengraphDescription?: Maybe<Scalars['String']>;
  opengraphImage?: Maybe<MediaItem>;
  opengraphModifiedTime?: Maybe<Scalars['String']>;
  opengraphPublishedTime?: Maybe<Scalars['String']>;
  opengraphPublisher?: Maybe<Scalars['String']>;
  opengraphSiteName?: Maybe<Scalars['String']>;
  opengraphTitle?: Maybe<Scalars['String']>;
  opengraphType?: Maybe<Scalars['String']>;
  opengraphUrl?: Maybe<Scalars['String']>;
  schema?: Maybe<SeoTaxonomySchema>;
  title?: Maybe<Scalars['String']>;
  twitterDescription?: Maybe<Scalars['String']>;
  twitterImage?: Maybe<MediaItem>;
  twitterTitle?: Maybe<Scalars['String']>;
};

export type TaxonomyToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: 'TaxonomyToContentTypeConnection';
    edges: Array<TaxonomyToContentTypeConnectionEdge>;
    nodes: Array<ContentType>;
    pageInfo: TaxonomyToContentTypeConnectionPageInfo;
  };

export type TaxonomyToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: 'TaxonomyToContentTypeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentType;
  };

export type TaxonomyToContentTypeConnectionPageInfo =
  ContentTypeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TaxonomyToContentTypeConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type TaxonomyToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: 'TaxonomyToTermNodeConnection';
    edges: Array<TaxonomyToTermNodeConnectionEdge>;
    nodes: Array<TermNode>;
    pageInfo: TaxonomyToTermNodeConnectionPageInfo;
  };

export type TaxonomyToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: 'TaxonomyToTermNodeConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: TermNode;
  };

export type TaxonomyToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'TaxonomyToTermNodeConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type TemplateAlternativeLayout = ContentTemplate & {
  __typename?: 'Template_AlternativeLayout';
  templateName?: Maybe<Scalars['String']>;
};

export type TermNode = {
  /** @deprecated Deprecated in favor of using Next.js pages */
  conditionalTags?: Maybe<ConditionalTags>;
  count?: Maybe<Scalars['Int']>;
  databaseId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  id: Scalars['ID'];
  isComment: Scalars['Boolean'];
  isContentNode: Scalars['Boolean'];
  isFrontPage: Scalars['Boolean'];
  isPostsPage: Scalars['Boolean'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  isTermNode: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  taxonomyName?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<Maybe<Scalars['String']>>>;
  termGroupId?: Maybe<Scalars['Int']>;
  termTaxonomyId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type TermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TermNodeConnection = {
  edges: Array<TermNodeConnectionEdge>;
  nodes: Array<TermNode>;
  pageInfo: TermNodeConnectionPageInfo;
};

export type TermNodeConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: TermNode;
};

export type TermNodeConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum TermNodeIdTypeEnum {
  DatabaseId = 'DATABASE_ID',
  Id = 'ID',
  Name = 'NAME',
  Slug = 'SLUG',
  Uri = 'URI',
}

export type TermNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'TermNodeToEnqueuedScriptConnection';
    edges: Array<TermNodeToEnqueuedScriptConnectionEdge>;
    nodes: Array<EnqueuedScript>;
    pageInfo: TermNodeToEnqueuedScriptConnectionPageInfo;
  };

export type TermNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'TermNodeToEnqueuedScriptConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedScript;
  };

export type TermNodeToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TermNodeToEnqueuedScriptConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type TermNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'TermNodeToEnqueuedStylesheetConnection';
    edges: Array<TermNodeToEnqueuedStylesheetConnectionEdge>;
    nodes: Array<EnqueuedStylesheet>;
    pageInfo: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  };

export type TermNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'TermNodeToEnqueuedStylesheetConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedStylesheet;
  };

export type TermNodeToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'TermNodeToEnqueuedStylesheetConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export enum TermObjectsConnectionOrderbyEnum {
  Count = 'COUNT',
  Description = 'DESCRIPTION',
  Name = 'NAME',
  Slug = 'SLUG',
  TermGroup = 'TERM_GROUP',
  TermId = 'TERM_ID',
  TermOrder = 'TERM_ORDER',
}

export type Theme = Node & {
  __typename?: 'Theme';
  author?: Maybe<Scalars['String']>;
  authorUri?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  screenshot?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  themeUri?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type ThemeConnection = {
  edges: Array<ThemeConnectionEdge>;
  nodes: Array<Theme>;
  pageInfo: ThemeConnectionPageInfo;
};

export type ThemeConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Theme;
};

export type ThemeConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type Translation = {
  __typename?: 'Translation';
  href?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  post_title?: Maybe<Scalars['String']>;
};

export type UniformResourceIdentifiable = {
  /** @deprecated Deprecated in favor of using Next.js pages */
  conditionalTags?: Maybe<ConditionalTags>;
  id: Scalars['ID'];
  isComment: Scalars['Boolean'];
  isContentNode: Scalars['Boolean'];
  isFrontPage: Scalars['Boolean'];
  isPostsPage: Scalars['Boolean'];
  isTermNode: Scalars['Boolean'];
  templates?: Maybe<Array<Maybe<Scalars['String']>>>;
  uri?: Maybe<Scalars['String']>;
};

export type UpdateCategoryInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateCategoryPayload = {
  __typename?: 'UpdateCategoryPayload';
  category?: Maybe<Category>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCommentInput = {
  approved?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  authorEmail?: InputMaybe<Scalars['String']>;
  authorUrl?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentOn?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  parent?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<CommentStatusEnum>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateLessonsPostInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateLessonsPostPayload = {
  __typename?: 'UpdateLessonsPostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  lessonsPost?: Maybe<LessonsPost>;
};

export type UpdateMediaItemInput = {
  altText?: InputMaybe<Scalars['String']>;
  authorId?: InputMaybe<Scalars['ID']>;
  caption?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentStatus?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  dateGmt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileType?: InputMaybe<MimeTypeEnum>;
  id: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
  pingStatus?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MediaItemStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateMediaItemPayload = {
  __typename?: 'UpdateMediaItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  mediaItem?: Maybe<MediaItem>;
};

export type UpdatePageInput = {
  authorId?: InputMaybe<Scalars['ID']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentStatus?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  menuOrder?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
  password?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdatePagePayload = {
  __typename?: 'UpdatePagePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  page?: Maybe<Page>;
};

export type UpdatePostFormatInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdatePostFormatPayload = {
  __typename?: 'UpdatePostFormatPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  postFormat?: Maybe<PostFormat>;
};

export type UpdatePostInput = {
  authorId?: InputMaybe<Scalars['ID']>;
  categories?: InputMaybe<PostCategoriesInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  commentStatus?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  excerpt?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  ignoreEditLock?: InputMaybe<Scalars['Boolean']>;
  lessonsPosts?: InputMaybe<PostLessonsPostsInput>;
  menuOrder?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  pingStatus?: InputMaybe<Scalars['String']>;
  pinged?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  postFormats?: InputMaybe<PostPostFormatsInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PostStatusEnum>;
  tags?: InputMaybe<PostTagsInput>;
  title?: InputMaybe<Scalars['String']>;
  toPing?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
};

export type UpdateSettingsInput = {
  clientMutationId?: InputMaybe<Scalars['String']>;
  discussionSettingsDefaultCommentStatus?: InputMaybe<Scalars['String']>;
  discussionSettingsDefaultPingStatus?: InputMaybe<Scalars['String']>;
  generalSettingsDateFormat?: InputMaybe<Scalars['String']>;
  generalSettingsDescription?: InputMaybe<Scalars['String']>;
  generalSettingsEmail?: InputMaybe<Scalars['String']>;
  generalSettingsLanguage?: InputMaybe<Scalars['String']>;
  generalSettingsStartOfWeek?: InputMaybe<Scalars['Int']>;
  generalSettingsTimeFormat?: InputMaybe<Scalars['String']>;
  generalSettingsTimezone?: InputMaybe<Scalars['String']>;
  generalSettingsTitle?: InputMaybe<Scalars['String']>;
  generalSettingsUrl?: InputMaybe<Scalars['String']>;
  readingSettingsPageForPosts?: InputMaybe<Scalars['Int']>;
  readingSettingsPageOnFront?: InputMaybe<Scalars['Int']>;
  readingSettingsPostsPerPage?: InputMaybe<Scalars['Int']>;
  readingSettingsShowOnFront?: InputMaybe<Scalars['String']>;
  writingSettingsDefaultCategory?: InputMaybe<Scalars['Int']>;
  writingSettingsDefaultPostFormat?: InputMaybe<Scalars['String']>;
  writingSettingsUseSmilies?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSettingsPayload = {
  __typename?: 'UpdateSettingsPayload';
  allSettings?: Maybe<Settings>;
  clientMutationId?: Maybe<Scalars['String']>;
  discussionSettings?: Maybe<DiscussionSettings>;
  generalSettings?: Maybe<GeneralSettings>;
  readingSettings?: Maybe<ReadingSettings>;
  writingSettings?: Maybe<WritingSettings>;
};

export type UpdateTagInput = {
  aliasOf?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type UpdateUserInput = {
  aim?: InputMaybe<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jabber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  nicename?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  refreshJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  registered?: InputMaybe<Scalars['String']>;
  revokeJwtUserSecret?: InputMaybe<Scalars['Boolean']>;
  richEditing?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  websiteUrl?: InputMaybe<Scalars['String']>;
  yim?: InputMaybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type User = Commenter &
  DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: 'User';
    avatar?: Maybe<Avatar>;
    capKey?: Maybe<Scalars['String']>;
    capabilities?: Maybe<Array<Maybe<Scalars['String']>>>;
    comments?: Maybe<UserToCommentConnection>;
    /** @deprecated Deprecated in favor of using Next.js pages */
    conditionalTags?: Maybe<ConditionalTags>;
    databaseId: Scalars['Int'];
    description?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    enqueuedScripts?: Maybe<UserToEnqueuedScriptConnection>;
    enqueuedStylesheets?: Maybe<UserToEnqueuedStylesheetConnection>;
    extraCapabilities?: Maybe<Array<Maybe<Scalars['String']>>>;
    firstName?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    isComment: Scalars['Boolean'];
    isContentNode: Scalars['Boolean'];
    isFrontPage: Scalars['Boolean'];
    isJwtAuthSecretRevoked: Scalars['Boolean'];
    isPostsPage: Scalars['Boolean'];
    isRestricted?: Maybe<Scalars['Boolean']>;
    isTermNode: Scalars['Boolean'];
    jwtAuthExpiration?: Maybe<Scalars['String']>;
    jwtAuthToken?: Maybe<Scalars['String']>;
    jwtRefreshToken?: Maybe<Scalars['String']>;
    jwtUserSecret?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    locale?: Maybe<Scalars['String']>;
    mediaItems?: Maybe<UserToMediaItemConnection>;
    name?: Maybe<Scalars['String']>;
    nicename?: Maybe<Scalars['String']>;
    nickname?: Maybe<Scalars['String']>;
    pages?: Maybe<UserToPageConnection>;
    posts?: Maybe<UserToPostConnection>;
    registeredDate?: Maybe<Scalars['String']>;
    revisions?: Maybe<UserToRevisionsConnection>;
    roles?: Maybe<UserToUserRoleConnection>;
    seo?: Maybe<SeoUser>;
    shouldShowAdminToolbar?: Maybe<Scalars['Boolean']>;
    shouldShowFaustToolbar?: Maybe<Scalars['Boolean']>;
    slug?: Maybe<Scalars['String']>;
    templates?: Maybe<Array<Maybe<Scalars['String']>>>;
    uri?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
    /** @deprecated Deprecated in favor of the databaseId field */
    userId?: Maybe<Scalars['Int']>;
    username?: Maybe<Scalars['String']>;
  };

export type UserAvatarArgs = {
  forceDefault?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars['Int']>;
};

export type UserCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToCommentConnectionWhereArgs>;
};

export type UserEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserMediaItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToMediaItemConnectionWhereArgs>;
};

export type UserPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToPageConnectionWhereArgs>;
};

export type UserPostsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToPostConnectionWhereArgs>;
};

export type UserRevisionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserToRevisionsConnectionWhereArgs>;
};

export type UserRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserConnection = {
  edges: Array<UserConnectionEdge>;
  nodes: Array<User>;
  pageInfo: UserConnectionPageInfo;
};

export type UserConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: User;
};

export type UserConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum UserNodeIdTypeEnum {
  DatabaseId = 'DATABASE_ID',
  Email = 'EMAIL',
  Id = 'ID',
  Slug = 'SLUG',
  Uri = 'URI',
  Username = 'USERNAME',
}

export type UserRole = Node & {
  __typename?: 'UserRole';
  capabilities?: Maybe<Array<Maybe<Scalars['String']>>>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRestricted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type UserRoleConnection = {
  edges: Array<UserRoleConnectionEdge>;
  nodes: Array<UserRole>;
  pageInfo: UserRoleConnectionPageInfo;
};

export type UserRoleConnectionEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: UserRole;
};

export type UserRoleConnectionPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export enum UserRoleEnum {
  Administrator = 'ADMINISTRATOR',
  Author = 'AUTHOR',
  Contributor = 'CONTRIBUTOR',
  Editor = 'EDITOR',
  SeoEditor = 'SEO_EDITOR',
  SeoManager = 'SEO_MANAGER',
  Subscriber = 'SUBSCRIBER',
  WebDesigner = 'WEB_DESIGNER',
}

export type UserToCommentConnection = CommentConnection &
  Connection & {
    __typename?: 'UserToCommentConnection';
    edges: Array<UserToCommentConnectionEdge>;
    nodes: Array<Comment>;
    pageInfo: UserToCommentConnectionPageInfo;
  };

export type UserToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: 'UserToCommentConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Comment;
  };

export type UserToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToCommentConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type UserToCommentConnectionWhereArgs = {
  authorEmail?: InputMaybe<Scalars['String']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorUrl?: InputMaybe<Scalars['String']>;
  commentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  commentType?: InputMaybe<Scalars['String']>;
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  commentTypeNotIn?: InputMaybe<Scalars['String']>;
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentId?: InputMaybe<Scalars['ID']>;
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentName?: InputMaybe<Scalars['String']>;
  contentParent?: InputMaybe<Scalars['Int']>;
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  karma?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderEnum>;
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  parent?: InputMaybe<Scalars['Int']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UserToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: 'UserToEnqueuedScriptConnection';
    edges: Array<UserToEnqueuedScriptConnectionEdge>;
    nodes: Array<EnqueuedScript>;
    pageInfo: UserToEnqueuedScriptConnectionPageInfo;
  };

export type UserToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: 'UserToEnqueuedScriptConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedScript;
  };

export type UserToEnqueuedScriptConnectionPageInfo =
  EnqueuedScriptConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'UserToEnqueuedScriptConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type UserToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: 'UserToEnqueuedStylesheetConnection';
    edges: Array<UserToEnqueuedStylesheetConnectionEdge>;
    nodes: Array<EnqueuedStylesheet>;
    pageInfo: UserToEnqueuedStylesheetConnectionPageInfo;
  };

export type UserToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: 'UserToEnqueuedStylesheetConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: EnqueuedStylesheet;
  };

export type UserToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: 'UserToEnqueuedStylesheetConnectionPageInfo';
      endCursor?: Maybe<Scalars['String']>;
      hasNextPage: Scalars['Boolean'];
      hasPreviousPage: Scalars['Boolean'];
      seo?: Maybe<SeoPostTypePageInfo>;
      startCursor?: Maybe<Scalars['String']>;
      total?: Maybe<Scalars['Int']>;
    };

export type UserToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: 'UserToMediaItemConnection';
    edges: Array<UserToMediaItemConnectionEdge>;
    nodes: Array<MediaItem>;
    pageInfo: UserToMediaItemConnectionPageInfo;
  };

export type UserToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: 'UserToMediaItemConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: MediaItem;
  };

export type UserToMediaItemConnectionPageInfo = MediaItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToMediaItemConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type UserToMediaItemConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserToPageConnection = Connection &
  PageConnection & {
    __typename?: 'UserToPageConnection';
    edges: Array<UserToPageConnectionEdge>;
    nodes: Array<Page>;
    pageInfo: UserToPageConnectionPageInfo;
  };

export type UserToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: 'UserToPageConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Page;
  };

export type UserToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToPageConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type UserToPageConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserToPostConnection = Connection &
  PostConnection & {
    __typename?: 'UserToPostConnection';
    edges: Array<UserToPostConnectionEdge>;
    nodes: Array<Post>;
    pageInfo: UserToPostConnectionPageInfo;
  };

export type UserToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: 'UserToPostConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: Post;
  };

export type UserToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToPostConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type UserToPostConnectionWhereArgs = {
  author?: InputMaybe<Scalars['Int']>;
  authorIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  authorName?: InputMaybe<Scalars['String']>;
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categoryName?: InputMaybe<Scalars['String']>;
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  tag?: InputMaybe<Scalars['String']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: 'UserToRevisionsConnection';
    edges: Array<UserToRevisionsConnectionEdge>;
    nodes: Array<ContentNode>;
    pageInfo: UserToRevisionsConnectionPageInfo;
  };

export type UserToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: 'UserToRevisionsConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: ContentNode;
  };

export type UserToRevisionsConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: 'UserToRevisionsConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export type UserToRevisionsConnectionWhereArgs = {
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  dateQuery?: InputMaybe<DateQueryInput>;
  hasPassword?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  mimeType?: InputMaybe<MimeTypeEnum>;
  name?: InputMaybe<Scalars['String']>;
  nameIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  parent?: InputMaybe<Scalars['ID']>;
  parentIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  status?: InputMaybe<PostStatusEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: 'UserToUserRoleConnection';
    edges: Array<UserToUserRoleConnectionEdge>;
    nodes: Array<UserRole>;
    pageInfo: UserToUserRoleConnectionPageInfo;
  };

export type UserToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: 'UserToUserRoleConnectionEdge';
    cursor?: Maybe<Scalars['String']>;
    node: UserRole;
  };

export type UserToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: 'UserToUserRoleConnectionPageInfo';
    endCursor?: Maybe<Scalars['String']>;
    hasNextPage: Scalars['Boolean'];
    hasPreviousPage: Scalars['Boolean'];
    seo?: Maybe<SeoPostTypePageInfo>;
    startCursor?: Maybe<Scalars['String']>;
    total?: Maybe<Scalars['Int']>;
  };

export enum UsersConnectionOrderbyEnum {
  DisplayName = 'DISPLAY_NAME',
  Email = 'EMAIL',
  Login = 'LOGIN',
  LoginIn = 'LOGIN_IN',
  NiceName = 'NICE_NAME',
  NiceNameIn = 'NICE_NAME_IN',
  Registered = 'REGISTERED',
  Url = 'URL',
}

export type UsersConnectionOrderbyInput = {
  field: UsersConnectionOrderbyEnum;
  order?: InputMaybe<OrderEnum>;
};

export enum UsersConnectionSearchColumnEnum {
  Email = 'EMAIL',
  Id = 'ID',
  Login = 'LOGIN',
  Nicename = 'NICENAME',
  Url = 'URL',
}

export type WpPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  seo?: Maybe<SeoPostTypePageInfo>;
  startCursor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type WritingSettings = {
  __typename?: 'WritingSettings';
  defaultCategory?: Maybe<Scalars['Int']>;
  defaultPostFormat?: Maybe<Scalars['String']>;
  useSmilies?: Maybe<Scalars['Boolean']>;
};

export type AllAuthorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
}>;

export type AllAuthorsQuery = {
  __typename?: 'RootQuery';
  users?: {
    __typename?: 'RootQueryToUserConnection';
    edges: Array<{
      __typename?: 'RootQueryToUserConnectionEdge';
      node: {
        __typename?: 'User';
        id: string;
        databaseId: number;
        name?: string | null;
        lastName?: string | null;
        firstName?: string | null;
        slug?: string | null;
        username?: string | null;
        description?: string | null;
        avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
        seo?: {
          __typename?: 'SEOUser';
          fullHead?: string | null;
          canonical?: string | null;
        } | null;
      };
    }>;
  } | null;
};

export type AuthorProfileQueryVariables = Exact<{
  id: Scalars['ID'];
  author: Scalars['String'];
  wpmlLanguage?: InputMaybe<Scalars['String']>;
}>;

export type AuthorProfileQuery = {
  __typename?: 'RootQuery';
  user?: {
    __typename?: 'User';
    id: string;
    databaseId: number;
    name?: string | null;
    lastName?: string | null;
    firstName?: string | null;
    slug?: string | null;
    username?: string | null;
    description?: string | null;
    avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
    seo?: {
      __typename?: 'SEOUser';
      fullHead?: string | null;
      canonical?: string | null;
    } | null;
  } | null;
  posts?: {
    __typename?: 'RootQueryToPostConnection';
    pageInfo: {
      __typename?: 'RootQueryToPostConnectionPageInfo';
      total?: number | null;
    };
  } | null;
};

export type AllPostsByAuthorQueryVariables = Exact<{
  author: Scalars['String'];
  wpmlLanguage?: InputMaybe<Scalars['String']>;
}>;

export type AllPostsByAuthorQuery = {
  __typename?: 'RootQuery';
  posts?: {
    __typename?: 'RootQueryToPostConnection';
    edges: Array<{
      __typename?: 'RootQueryToPostConnectionEdge';
      node: {
        __typename?: 'Post';
        id: string;
        title?: string | null;
        slug?: string | null;
        locale?: { __typename?: 'Locale'; locale?: string | null } | null;
      };
    }>;
  } | null;
};

export type FullCategoryNodeFragment = {
  __typename?: 'Category';
  id: string;
  databaseId: number;
  name?: string | null;
  slug?: string | null;
  seo?: {
    __typename?: 'TaxonomySEO';
    fullHead?: string | null;
    title?: string | null;
    metaDesc?: string | null;
    canonical?: string | null;
  } | null;
  blocks?: Array<{
    __typename?: 'Block';
    id?: string | null;
    innerHtml?: string | null;
    tagName?: string | null;
    type?: BlockNameEnum | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  categoryImage?: {
    __typename?: 'Category_Categoryimage';
    categoryDescription?: string | null;
    categoryImage?: {
      __typename?: 'MediaItem';
      mediaItemUrl?: string | null;
    } | null;
    categoryImageHeader?: {
      __typename?: 'MediaItem';
      mediaItemUrl?: string | null;
    } | null;
  } | null;
};

export type CategoryQueryVariables = Exact<{
  slug: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>;
  wpmlLanguage?: InputMaybe<Scalars['String']>;
}>;

export type CategoryQuery = {
  __typename?: 'RootQuery';
  categories?: {
    __typename?: 'RootQueryToCategoryConnection';
    edges: Array<{
      __typename?: 'RootQueryToCategoryConnectionEdge';
      node: {
        __typename?: 'Category';
        id: string;
        databaseId: number;
        name?: string | null;
        slug?: string | null;
        seo?: {
          __typename?: 'TaxonomySEO';
          fullHead?: string | null;
          title?: string | null;
          metaDesc?: string | null;
          canonical?: string | null;
        } | null;
        blocks?: Array<{
          __typename?: 'Block';
          id?: string | null;
          innerHtml?: string | null;
          tagName?: string | null;
          type?: BlockNameEnum | null;
          attributes?: Array<{
            __typename?: 'Attribute';
            name?: string | null;
            value?: string | null;
          } | null> | null;
        } | null> | null;
        categoryImage?: {
          __typename?: 'Category_Categoryimage';
          categoryDescription?: string | null;
          categoryImage?: {
            __typename?: 'MediaItem';
            mediaItemUrl?: string | null;
          } | null;
          categoryImageHeader?: {
            __typename?: 'MediaItem';
            mediaItemUrl?: string | null;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type CategoryNodeFragment = {
  __typename?: 'Category';
  id: string;
  name?: string | null;
  slug?: string | null;
  categoryImage?: {
    __typename?: 'Category_Categoryimage';
    categoryDescription?: string | null;
    categoryImage?: {
      __typename?: 'MediaItem';
      mediaItemUrl?: string | null;
    } | null;
  } | null;
  posts?: {
    __typename?: 'CategoryToPostConnection';
    nodes: Array<{
      __typename?: 'Post';
      id: string;
      locale?: {
        __typename?: 'Locale';
        id: string;
        locale?: string | null;
      } | null;
    }>;
  } | null;
};

export type CategoriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToCategoryConnectionWhereArgs>;
}>;

export type CategoriesQuery = {
  __typename?: 'RootQuery';
  categories?: {
    __typename?: 'RootQueryToCategoryConnection';
    nodes: Array<{
      __typename?: 'Category';
      id: string;
      name?: string | null;
      slug?: string | null;
      categoryImage?: {
        __typename?: 'Category_Categoryimage';
        categoryDescription?: string | null;
        categoryImage?: {
          __typename?: 'MediaItem';
          mediaItemUrl?: string | null;
        } | null;
      } | null;
      posts?: {
        __typename?: 'CategoryToPostConnection';
        nodes: Array<{
          __typename?: 'Post';
          id: string;
          locale?: {
            __typename?: 'Locale';
            id: string;
            locale?: string | null;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type GetAllCategorySlugsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllCategorySlugsQuery = {
  __typename?: 'RootQuery';
  categories?: {
    __typename?: 'RootQueryToCategoryConnection';
    nodes: Array<{
      __typename?: 'Category';
      id: string;
      name?: string | null;
      slug?: string | null;
      posts?: {
        __typename?: 'CategoryToPostConnection';
        nodes: Array<{
          __typename?: 'Post';
          id: string;
          locale?: {
            __typename?: 'Locale';
            id: string;
            locale?: string | null;
          } | null;
        }>;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToCategoryConnectionPageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAllPostsSlugsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllPostsSlugsQuery = {
  __typename?: 'RootQuery';
  posts?: {
    __typename?: 'RootQueryToPostConnection';
    nodes: Array<{
      __typename?: 'Post';
      id: string;
      slug?: string | null;
      locale?: { __typename?: 'Locale'; locale?: string | null } | null;
      translations?: Array<{
        __typename?: 'Translation';
        href?: string | null;
        locale?: string | null;
      } | null> | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToPostConnectionPageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetAllPagesSlugsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllPagesSlugsQuery = {
  __typename?: 'RootQuery';
  pages?: {
    __typename?: 'RootQueryToPageConnection';
    nodes: Array<{
      __typename?: 'Page';
      id: string;
      slug?: string | null;
      locale?: { __typename?: 'Locale'; locale?: string | null } | null;
    }>;
    pageInfo: {
      __typename?: 'RootQueryToPageConnectionPageInfo';
      startCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type AlsHeaderSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type AlsHeaderSettingsQuery = {
  __typename?: 'RootQuery';
  alsHeaderSettings?: {
    __typename?: 'AlsHeaderSettings';
    headerSettingsPageFields?: {
      __typename?: 'AlsHeaderSettings_Headersettingspagefields';
      fieldGroupName?: string | null;
      loginLink?: string | null;
      loginLabel?: string | null;
      navigationSlideHeadline?: string | null;
      navigationSlideSignOff?: string | null;
      searchBar?: string | null;
      siteLink?: string | null;
      siteTitle?: string | null;
      footerHeading?: string | null;
      headerLinks?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_HeaderLinks';
        linkGroupTitle?: string | null;
        pluginsTitle?: string | null;
        pluginsLink?: string | null;
        samplesTitle?: string | null;
        samplesLink?: string | null;
        masteringTitle?: string | null;
        masteringLink?: string | null;
        distributionTitle?: string | null;
        distributionLink?: string | null;
        collaborationTitle?: string | null;
        collaborationLink?: string | null;
      } | null;
      footerLinks?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_FooterLinks';
        copyrightNotice?: string | null;
        privacyPolicyTitle?: string | null;
        privacyPolicyLink?: string | null;
        termsTitle?: string | null;
        termsLink?: string | null;
      } | null;
      french?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_French';
        fieldGroupName?: string | null;
        loginLink?: string | null;
        loginLabel?: string | null;
        navigationSlideHeadline?: string | null;
        navigationSlideSignOff?: string | null;
        searchBar?: string | null;
        siteLink?: string | null;
        siteTitle?: string | null;
        footerHeading?: string | null;
        headerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_French_HeaderLinks';
          linkGroupTitle?: string | null;
          pluginsTitle?: string | null;
          pluginsLink?: string | null;
          samplesTitle?: string | null;
          samplesLink?: string | null;
          masteringTitle?: string | null;
          masteringLink?: string | null;
          distributionTitle?: string | null;
          distributionLink?: string | null;
          collaborationTitle?: string | null;
          collaborationLink?: string | null;
        } | null;
        footerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_French_FooterLinks';
          copyrightNotice?: string | null;
          privacyPolicyTitle?: string | null;
          privacyPolicyLink?: string | null;
          termsTitle?: string | null;
          termsLink?: string | null;
        } | null;
      } | null;
      german?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_German';
        fieldGroupName?: string | null;
        loginLink?: string | null;
        loginLabel?: string | null;
        navigationSlideHeadline?: string | null;
        navigationSlideSignOff?: string | null;
        searchBar?: string | null;
        siteLink?: string | null;
        siteTitle?: string | null;
        footerHeading?: string | null;
        headerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_German_HeaderLinks';
          linkGroupTitle?: string | null;
          pluginsTitle?: string | null;
          pluginsLink?: string | null;
          samplesTitle?: string | null;
          samplesLink?: string | null;
          masteringTitle?: string | null;
          masteringLink?: string | null;
          distributionTitle?: string | null;
          distributionLink?: string | null;
          collaborationTitle?: string | null;
          collaborationLink?: string | null;
        } | null;
        footerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_German_FooterLinks';
          copyrightNotice?: string | null;
          privacyPolicyTitle?: string | null;
          privacyPolicyLink?: string | null;
          termsTitle?: string | null;
          termsLink?: string | null;
        } | null;
      } | null;
      italian?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_Italian';
        fieldGroupName?: string | null;
        loginLink?: string | null;
        loginLabel?: string | null;
        navigationSlideHeadline?: string | null;
        navigationSlideSignOff?: string | null;
        searchBar?: string | null;
        siteLink?: string | null;
        siteTitle?: string | null;
        footerHeading?: string | null;
        headerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Italian_HeaderLinks';
          linkGroupTitle?: string | null;
          pluginsTitle?: string | null;
          pluginsLink?: string | null;
          samplesTitle?: string | null;
          samplesLink?: string | null;
          masteringTitle?: string | null;
          masteringLink?: string | null;
          distributionTitle?: string | null;
          distributionLink?: string | null;
          collaborationTitle?: string | null;
          collaborationLink?: string | null;
        } | null;
        footerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Italian_FooterLinks';
          copyrightNotice?: string | null;
          privacyPolicyTitle?: string | null;
          privacyPolicyLink?: string | null;
          termsTitle?: string | null;
          termsLink?: string | null;
        } | null;
      } | null;
      japanese?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_Japanese';
        fieldGroupName?: string | null;
        loginLink?: string | null;
        loginLabel?: string | null;
        navigationSlideHeadline?: string | null;
        navigationSlideSignOff?: string | null;
        searchBar?: string | null;
        siteLink?: string | null;
        siteTitle?: string | null;
        footerHeading?: string | null;
        headerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Japanese_HeaderLinks';
          linkGroupTitle?: string | null;
          pluginsTitle?: string | null;
          pluginsLink?: string | null;
          samplesTitle?: string | null;
          samplesLink?: string | null;
          masteringTitle?: string | null;
          masteringLink?: string | null;
          distributionTitle?: string | null;
          distributionLink?: string | null;
          collaborationTitle?: string | null;
          collaborationLink?: string | null;
        } | null;
        footerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Japanese_FooterLinks';
          copyrightNotice?: string | null;
          privacyPolicyTitle?: string | null;
          privacyPolicyLink?: string | null;
          termsTitle?: string | null;
          termsLink?: string | null;
        } | null;
      } | null;
      portuguese?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_Portuguese';
        fieldGroupName?: string | null;
        loginLink?: string | null;
        loginLabel?: string | null;
        navigationSlideHeadline?: string | null;
        navigationSlideSignOff?: string | null;
        searchBar?: string | null;
        siteLink?: string | null;
        siteTitle?: string | null;
        footerHeading?: string | null;
        headerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Portuguese_HeaderLinks';
          linkGroupTitle?: string | null;
          pluginsTitle?: string | null;
          pluginsLink?: string | null;
          samplesTitle?: string | null;
          samplesLink?: string | null;
          masteringTitle?: string | null;
          masteringLink?: string | null;
          distributionTitle?: string | null;
          distributionLink?: string | null;
          collaborationTitle?: string | null;
          collaborationLink?: string | null;
        } | null;
        footerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Portuguese_FooterLinks';
          copyrightNotice?: string | null;
          privacyPolicyTitle?: string | null;
          privacyPolicyLink?: string | null;
          termsTitle?: string | null;
          termsLink?: string | null;
        } | null;
      } | null;
      spanish?: {
        __typename?: 'AlsHeaderSettings_Headersettingspagefields_Spanish';
        fieldGroupName?: string | null;
        loginLink?: string | null;
        loginLabel?: string | null;
        navigationSlideHeadline?: string | null;
        navigationSlideSignOff?: string | null;
        searchBar?: string | null;
        siteLink?: string | null;
        siteTitle?: string | null;
        footerHeading?: string | null;
        headerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Spanish_HeaderLinks';
          linkGroupTitle?: string | null;
          pluginsTitle?: string | null;
          pluginsLink?: string | null;
          samplesTitle?: string | null;
          samplesLink?: string | null;
          masteringTitle?: string | null;
          masteringLink?: string | null;
          distributionTitle?: string | null;
          distributionLink?: string | null;
          collaborationTitle?: string | null;
          collaborationLink?: string | null;
        } | null;
        footerLinks?: {
          __typename?: 'AlsHeaderSettings_Headersettingspagefields_Spanish_FooterLinks';
          copyrightNotice?: string | null;
          privacyPolicyTitle?: string | null;
          privacyPolicyLink?: string | null;
          termsTitle?: string | null;
          termsLink?: string | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type LessonsPostQueryVariables = Exact<{
  id: Scalars['ID'];
  idType?: InputMaybe<LessonsPostIdType>;
  lessonPostsFirst?: InputMaybe<Scalars['Int']>;
  lessonPostsWhere?: InputMaybe<LessonsPostToPostConnectionWhereArgs>;
}>;

export type LessonsPostQuery = {
  __typename?: 'RootQuery';
  lessonsPost?: {
    __typename?: 'LessonsPost';
    id: string;
    slug?: string | null;
    posts?: {
      __typename?: 'LessonsPostToPostConnection';
      nodes: Array<{
        __typename?: 'Post';
        id: string;
        slug?: string | null;
        title?: string | null;
        postFormat?: string | null;
        postFormatVideo?: {
          __typename?: 'Post_Postformatvideo';
          videoId?: string | null;
        } | null;
        categories?: {
          __typename?: 'PostToCategoryConnection';
          nodes: Array<{
            __typename?: 'Category';
            id: string;
            slug?: string | null;
            name?: string | null;
          }>;
        } | null;
        featuredImage?: {
          __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
          node: {
            __typename?: 'MediaItem';
            id: string;
            sourceUrl?: string | null;
            altText?: string | null;
          };
        } | null;
        locale?: {
          __typename?: 'Locale';
          id: string;
          locale?: string | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type MenuItemFragment = {
  __typename?: 'MenuItem';
  id: string;
  label?: string | null;
  path?: string | null;
  title?: string | null;
  target?: string | null;
  menu?: {
    __typename?: 'MenuItemToMenuConnectionEdge';
    node: { __typename?: 'Menu'; id: string; name?: string | null };
  } | null;
};

export type MenuItemsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
}>;

export type MenuItemsQuery = {
  __typename?: 'RootQuery';
  menuItems?: {
    __typename?: 'RootQueryToMenuItemConnection';
    nodes: Array<{
      __typename?: 'MenuItem';
      id: string;
      label?: string | null;
      path?: string | null;
      title?: string | null;
      target?: string | null;
      menu?: {
        __typename?: 'MenuItemToMenuConnectionEdge';
        node: { __typename?: 'Menu'; id: string; name?: string | null };
      } | null;
    }>;
  } | null;
};

export type FooterMenuQueryVariables = Exact<{
  firstWhere?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
  secondWhere?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
  thirdWhere?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
}>;

export type FooterMenuQuery = {
  __typename?: 'RootQuery';
  footer1?: {
    __typename?: 'RootQueryToMenuItemConnection';
    nodes: Array<{
      __typename?: 'MenuItem';
      id: string;
      label?: string | null;
      path?: string | null;
      title?: string | null;
      target?: string | null;
      menu?: {
        __typename?: 'MenuItemToMenuConnectionEdge';
        node: { __typename?: 'Menu'; id: string; name?: string | null };
      } | null;
    }>;
  } | null;
  footer2?: {
    __typename?: 'RootQueryToMenuItemConnection';
    nodes: Array<{
      __typename?: 'MenuItem';
      id: string;
      label?: string | null;
      path?: string | null;
      title?: string | null;
      target?: string | null;
      menu?: {
        __typename?: 'MenuItemToMenuConnectionEdge';
        node: { __typename?: 'Menu'; id: string; name?: string | null };
      } | null;
    }>;
  } | null;
  footer3?: {
    __typename?: 'RootQueryToMenuItemConnection';
    nodes: Array<{
      __typename?: 'MenuItem';
      id: string;
      label?: string | null;
      path?: string | null;
      title?: string | null;
      target?: string | null;
      menu?: {
        __typename?: 'MenuItemToMenuConnectionEdge';
        node: { __typename?: 'Menu'; id: string; name?: string | null };
      } | null;
    }>;
  } | null;
};

export type HomePageFragmentsFragment = {
  __typename?: 'Page';
  id: string;
  title?: string | null;
  slug?: string | null;
  seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
  locale?: { __typename?: 'Locale'; id: string; locale?: string | null } | null;
  translations?: Array<{
    __typename?: 'Translation';
    href?: string | null;
    id: string;
    locale?: string | null;
  } | null> | null;
  blocks?: Array<{
    __typename?: 'Block';
    id?: string | null;
    innerHtml?: string | null;
    tagName?: string | null;
    type?: BlockNameEnum | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  homepageFirstBlock?: {
    __typename?: 'Page_Homepagefirstblock';
    accentColor?: string | null;
    title?: string | null;
    description?: string | null;
    image1?: {
      __typename?: 'MediaItem';
      id: string;
      altText?: string | null;
      mediaItemUrl?: string | null;
    } | null;
    image2?: {
      __typename?: 'MediaItem';
      id: string;
      altText?: string | null;
      mediaItemUrl?: string | null;
    } | null;
    image3?: {
      __typename?: 'MediaItem';
      id: string;
      altText?: string | null;
      mediaItemUrl?: string | null;
    } | null;
    image4?: {
      __typename?: 'MediaItem';
      id: string;
      altText?: string | null;
      mediaItemUrl?: string | null;
    } | null;
  } | null;
};

export type HomePageQueryVariables = Exact<{
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PageIdType>;
}>;

export type HomePageQuery = {
  __typename?: 'RootQuery';
  page?: {
    __typename?: 'Page';
    id: string;
    title?: string | null;
    slug?: string | null;
    translated?: Array<{
      __typename?: 'Page';
      id: string;
      title?: string | null;
      slug?: string | null;
      seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
      locale?: {
        __typename?: 'Locale';
        id: string;
        locale?: string | null;
      } | null;
      translations?: Array<{
        __typename?: 'Translation';
        href?: string | null;
        id: string;
        locale?: string | null;
      } | null> | null;
      blocks?: Array<{
        __typename?: 'Block';
        id?: string | null;
        innerHtml?: string | null;
        tagName?: string | null;
        type?: BlockNameEnum | null;
        attributes?: Array<{
          __typename?: 'Attribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
      homepageFirstBlock?: {
        __typename?: 'Page_Homepagefirstblock';
        accentColor?: string | null;
        title?: string | null;
        description?: string | null;
        image1?: {
          __typename?: 'MediaItem';
          id: string;
          altText?: string | null;
          mediaItemUrl?: string | null;
        } | null;
        image2?: {
          __typename?: 'MediaItem';
          id: string;
          altText?: string | null;
          mediaItemUrl?: string | null;
        } | null;
        image3?: {
          __typename?: 'MediaItem';
          id: string;
          altText?: string | null;
          mediaItemUrl?: string | null;
        } | null;
        image4?: {
          __typename?: 'MediaItem';
          id: string;
          altText?: string | null;
          mediaItemUrl?: string | null;
        } | null;
      } | null;
    } | null> | null;
    seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
    locale?: {
      __typename?: 'Locale';
      id: string;
      locale?: string | null;
    } | null;
    translations?: Array<{
      __typename?: 'Translation';
      href?: string | null;
      id: string;
      locale?: string | null;
    } | null> | null;
    blocks?: Array<{
      __typename?: 'Block';
      id?: string | null;
      innerHtml?: string | null;
      tagName?: string | null;
      type?: BlockNameEnum | null;
      attributes?: Array<{
        __typename?: 'Attribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    homepageFirstBlock?: {
      __typename?: 'Page_Homepagefirstblock';
      accentColor?: string | null;
      title?: string | null;
      description?: string | null;
      image1?: {
        __typename?: 'MediaItem';
        id: string;
        altText?: string | null;
        mediaItemUrl?: string | null;
      } | null;
      image2?: {
        __typename?: 'MediaItem';
        id: string;
        altText?: string | null;
        mediaItemUrl?: string | null;
      } | null;
      image3?: {
        __typename?: 'MediaItem';
        id: string;
        altText?: string | null;
        mediaItemUrl?: string | null;
      } | null;
      image4?: {
        __typename?: 'MediaItem';
        id: string;
        altText?: string | null;
        mediaItemUrl?: string | null;
      } | null;
    } | null;
  } | null;
};

export type NotFoundPageFragmentsFragment = {
  __typename?: 'Page';
  id: string;
  title?: string | null;
  slug?: string | null;
  seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
  locale?: { __typename?: 'Locale'; id: string; locale?: string | null } | null;
  blocks?: Array<{
    __typename?: 'Block';
    id?: string | null;
    innerHtml?: string | null;
    tagName?: string | null;
    type?: BlockNameEnum | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  notFoundAdditionalFields?: {
    __typename?: 'Page_Notfoundadditionalfields';
    title?: string | null;
    subtitle?: string | null;
  } | null;
};

export type PageQueryVariables = Exact<{
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PageIdType>;
}>;

export type PageQuery = {
  __typename?: 'RootQuery';
  page?: {
    __typename?: 'Page';
    id: string;
    title?: string | null;
    slug?: string | null;
    translated?: Array<{
      __typename?: 'Page';
      id: string;
      title?: string | null;
      slug?: string | null;
      seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
      locale?: {
        __typename?: 'Locale';
        id: string;
        locale?: string | null;
      } | null;
      blocks?: Array<{
        __typename?: 'Block';
        id?: string | null;
        innerHtml?: string | null;
        tagName?: string | null;
        type?: BlockNameEnum | null;
        attributes?: Array<{
          __typename?: 'Attribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
      notFoundAdditionalFields?: {
        __typename?: 'Page_Notfoundadditionalfields';
        title?: string | null;
        subtitle?: string | null;
      } | null;
    } | null> | null;
    seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
    locale?: {
      __typename?: 'Locale';
      id: string;
      locale?: string | null;
    } | null;
    blocks?: Array<{
      __typename?: 'Block';
      id?: string | null;
      innerHtml?: string | null;
      tagName?: string | null;
      type?: BlockNameEnum | null;
      attributes?: Array<{
        __typename?: 'Attribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    notFoundAdditionalFields?: {
      __typename?: 'Page_Notfoundadditionalfields';
      title?: string | null;
      subtitle?: string | null;
    } | null;
  } | null;
};

export type GetPreviewPageQueryVariables = Exact<{
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PageIdType>;
}>;

export type GetPreviewPageQuery = {
  __typename?: 'RootQuery';
  page?: {
    __typename?: 'Page';
    id: string;
    databaseId: number;
    slug?: string | null;
  } | null;
};

export type PostAdditionalFieldsFragmentFragment = {
  __typename?: 'Post';
  postAdditionalFields?: {
    __typename?: 'Post_Postadditionalfields';
    accentColor?: string | null;
    minutesToRead?: string | null;
    lessonNavigation?: {
      __typename?: 'Post_Postadditionalfields_LessonNavigation';
      title?: string | null;
      visibility?: Array<string | null> | null;
    } | null;
  } | null;
};

export type PostNodeFragment = {
  __typename?: 'Post';
  id: string;
  slug?: string | null;
  postFormat?: string | null;
  title?: string | null;
  categories?: {
    __typename?: 'PostToCategoryConnection';
    nodes: Array<{
      __typename?: 'Category';
      id: string;
      slug?: string | null;
      name?: string | null;
    }>;
  } | null;
  featuredImage?: {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
    node: {
      __typename?: 'MediaItem';
      altText?: string | null;
      id: string;
      sourceUrl?: string | null;
    };
  } | null;
  postFormatVideo?: {
    __typename?: 'Post_Postformatvideo';
    videoId?: string | null;
  } | null;
  locale?: { __typename?: 'Locale'; id: string; locale?: string | null } | null;
};

export type PostsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>;
}>;

export type PostsQuery = {
  __typename?: 'RootQuery';
  posts?: {
    __typename?: 'RootQueryToPostConnection';
    nodes: Array<{
      __typename?: 'Post';
      id: string;
      slug?: string | null;
      postFormat?: string | null;
      title?: string | null;
      categories?: {
        __typename?: 'PostToCategoryConnection';
        nodes: Array<{
          __typename?: 'Category';
          id: string;
          slug?: string | null;
          name?: string | null;
        }>;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          id: string;
          sourceUrl?: string | null;
        };
      } | null;
      postFormatVideo?: {
        __typename?: 'Post_Postformatvideo';
        videoId?: string | null;
      } | null;
      locale?: {
        __typename?: 'Locale';
        id: string;
        locale?: string | null;
      } | null;
      postAdditionalFields?: {
        __typename?: 'Post_Postadditionalfields';
        accentColor?: string | null;
        minutesToRead?: string | null;
        lessonNavigation?: {
          __typename?: 'Post_Postadditionalfields_LessonNavigation';
          title?: string | null;
          visibility?: Array<string | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type SearchPostsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>;
}>;

export type SearchPostsQuery = {
  __typename?: 'RootQuery';
  posts?: {
    __typename?: 'RootQueryToPostConnection';
    pageInfo: {
      __typename?: 'RootQueryToPostConnectionPageInfo';
      hasNextPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
      total?: number | null;
    };
    nodes: Array<{
      __typename?: 'Post';
      id: string;
      slug?: string | null;
      postFormat?: string | null;
      title?: string | null;
      categories?: {
        __typename?: 'PostToCategoryConnection';
        nodes: Array<{
          __typename?: 'Category';
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          id: string;
          sourceUrl?: string | null;
        };
      } | null;
      postFormatVideo?: {
        __typename?: 'Post_Postformatvideo';
        videoId?: string | null;
      } | null;
      locale?: {
        __typename?: 'Locale';
        id: string;
        locale?: string | null;
      } | null;
      postAdditionalFields?: {
        __typename?: 'Post_Postadditionalfields';
        accentColor?: string | null;
        minutesToRead?: string | null;
        lessonNavigation?: {
          __typename?: 'Post_Postadditionalfields_LessonNavigation';
          title?: string | null;
          visibility?: Array<string | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type PostAuthorNodeFragment = {
  __typename?: 'User';
  id: string;
  databaseId: number;
  name?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  slug?: string | null;
  username?: string | null;
  description?: string | null;
  avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
  seo?: {
    __typename?: 'SEOUser';
    fullHead?: string | null;
    canonical?: string | null;
  } | null;
};

export type PostFragmentFragment = {
  __typename?: 'Post';
  title?: string | null;
  date?: string | null;
  modified?: string | null;
  postFormat?: string | null;
  seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
  featuredImage?: {
    __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
    node: {
      __typename?: 'MediaItem';
      altText?: string | null;
      id: string;
      sourceUrl?: string | null;
    };
  } | null;
  postFormatVideo?: {
    __typename?: 'Post_Postformatvideo';
    videoId?: string | null;
  } | null;
  locale?: { __typename?: 'Locale'; id: string; locale?: string | null } | null;
  translations?: Array<{
    __typename?: 'Translation';
    href?: string | null;
    id: string;
    locale?: string | null;
  } | null> | null;
  blocks?: Array<{
    __typename?: 'Block';
    id?: string | null;
    innerHtml?: string | null;
    tagName?: string | null;
    type?: BlockNameEnum | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name?: string | null;
      value?: string | null;
    } | null> | null;
  } | null> | null;
  categories?: {
    __typename?: 'PostToCategoryConnection';
    nodes: Array<{
      __typename?: 'Category';
      id: string;
      databaseId: number;
      name?: string | null;
      slug?: string | null;
    }>;
  } | null;
  author?: {
    __typename?: 'NodeWithAuthorToUserConnectionEdge';
    node: {
      __typename?: 'User';
      id: string;
      databaseId: number;
      name?: string | null;
      lastName?: string | null;
      firstName?: string | null;
      slug?: string | null;
      username?: string | null;
      description?: string | null;
      avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
      seo?: {
        __typename?: 'SEOUser';
        fullHead?: string | null;
        canonical?: string | null;
      } | null;
    };
  } | null;
  lessonsPosts?: {
    __typename?: 'PostToLessonsPostConnection';
    nodes: Array<{
      __typename?: 'LessonsPost';
      id: string;
      name?: string | null;
      posts?: {
        __typename?: 'LessonsPostToPostConnection';
        nodes: Array<{
          __typename?: 'Post';
          id: string;
          title?: string | null;
          slug?: string | null;
          lessonsAdditionalFields?: {
            __typename?: 'Post_Lessonsadditionalfields';
            order?: number | null;
          } | null;
          locale?: {
            __typename?: 'Locale';
            id: string;
            locale?: string | null;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type PostQueryVariables = Exact<{
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PostIdType>;
  lessonPostsPostsFirst?: InputMaybe<Scalars['Int']>;
  lessonPostsPostsWhere?: InputMaybe<LessonsPostToPostConnectionWhereArgs>;
}>;

export type PostQuery = {
  __typename?: 'RootQuery';
  post?: {
    __typename?: 'Post';
    id: string;
    title?: string | null;
    date?: string | null;
    modified?: string | null;
    postFormat?: string | null;
    translated?: Array<{
      __typename?: 'Post';
      title?: string | null;
      date?: string | null;
      modified?: string | null;
      postFormat?: string | null;
      seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
      featuredImage?: {
        __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
        node: {
          __typename?: 'MediaItem';
          altText?: string | null;
          id: string;
          sourceUrl?: string | null;
        };
      } | null;
      postFormatVideo?: {
        __typename?: 'Post_Postformatvideo';
        videoId?: string | null;
      } | null;
      locale?: {
        __typename?: 'Locale';
        id: string;
        locale?: string | null;
      } | null;
      translations?: Array<{
        __typename?: 'Translation';
        href?: string | null;
        id: string;
        locale?: string | null;
      } | null> | null;
      blocks?: Array<{
        __typename?: 'Block';
        id?: string | null;
        innerHtml?: string | null;
        tagName?: string | null;
        type?: BlockNameEnum | null;
        attributes?: Array<{
          __typename?: 'Attribute';
          name?: string | null;
          value?: string | null;
        } | null> | null;
      } | null> | null;
      categories?: {
        __typename?: 'PostToCategoryConnection';
        nodes: Array<{
          __typename?: 'Category';
          id: string;
          databaseId: number;
          name?: string | null;
          slug?: string | null;
        }>;
      } | null;
      author?: {
        __typename?: 'NodeWithAuthorToUserConnectionEdge';
        node: {
          __typename?: 'User';
          id: string;
          databaseId: number;
          name?: string | null;
          lastName?: string | null;
          firstName?: string | null;
          slug?: string | null;
          username?: string | null;
          description?: string | null;
          avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
          seo?: {
            __typename?: 'SEOUser';
            fullHead?: string | null;
            canonical?: string | null;
          } | null;
        };
      } | null;
      lessonsPosts?: {
        __typename?: 'PostToLessonsPostConnection';
        nodes: Array<{
          __typename?: 'LessonsPost';
          id: string;
          name?: string | null;
          posts?: {
            __typename?: 'LessonsPostToPostConnection';
            nodes: Array<{
              __typename?: 'Post';
              id: string;
              title?: string | null;
              slug?: string | null;
              lessonsAdditionalFields?: {
                __typename?: 'Post_Lessonsadditionalfields';
                order?: number | null;
              } | null;
              locale?: {
                __typename?: 'Locale';
                id: string;
                locale?: string | null;
              } | null;
            }>;
          } | null;
        }>;
      } | null;
    } | null> | null;
    seo?: { __typename?: 'PostTypeSEO'; fullHead?: string | null } | null;
    featuredImage?: {
      __typename?: 'NodeWithFeaturedImageToMediaItemConnectionEdge';
      node: {
        __typename?: 'MediaItem';
        altText?: string | null;
        id: string;
        sourceUrl?: string | null;
      };
    } | null;
    postFormatVideo?: {
      __typename?: 'Post_Postformatvideo';
      videoId?: string | null;
    } | null;
    locale?: {
      __typename?: 'Locale';
      id: string;
      locale?: string | null;
    } | null;
    translations?: Array<{
      __typename?: 'Translation';
      href?: string | null;
      id: string;
      locale?: string | null;
    } | null> | null;
    blocks?: Array<{
      __typename?: 'Block';
      id?: string | null;
      innerHtml?: string | null;
      tagName?: string | null;
      type?: BlockNameEnum | null;
      attributes?: Array<{
        __typename?: 'Attribute';
        name?: string | null;
        value?: string | null;
      } | null> | null;
    } | null> | null;
    categories?: {
      __typename?: 'PostToCategoryConnection';
      nodes: Array<{
        __typename?: 'Category';
        id: string;
        databaseId: number;
        name?: string | null;
        slug?: string | null;
      }>;
    } | null;
    author?: {
      __typename?: 'NodeWithAuthorToUserConnectionEdge';
      node: {
        __typename?: 'User';
        id: string;
        databaseId: number;
        name?: string | null;
        lastName?: string | null;
        firstName?: string | null;
        slug?: string | null;
        username?: string | null;
        description?: string | null;
        avatar?: { __typename?: 'Avatar'; url?: string | null } | null;
        seo?: {
          __typename?: 'SEOUser';
          fullHead?: string | null;
          canonical?: string | null;
        } | null;
      };
    } | null;
    lessonsPosts?: {
      __typename?: 'PostToLessonsPostConnection';
      nodes: Array<{
        __typename?: 'LessonsPost';
        id: string;
        name?: string | null;
        posts?: {
          __typename?: 'LessonsPostToPostConnection';
          nodes: Array<{
            __typename?: 'Post';
            id: string;
            title?: string | null;
            slug?: string | null;
            lessonsAdditionalFields?: {
              __typename?: 'Post_Lessonsadditionalfields';
              order?: number | null;
            } | null;
            locale?: {
              __typename?: 'Locale';
              id: string;
              locale?: string | null;
            } | null;
          }>;
        } | null;
      }>;
    } | null;
    postAdditionalFields?: {
      __typename?: 'Post_Postadditionalfields';
      accentColor?: string | null;
      minutesToRead?: string | null;
      lessonNavigation?: {
        __typename?: 'Post_Postadditionalfields_LessonNavigation';
        title?: string | null;
        visibility?: Array<string | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetPreviewPostQueryVariables = Exact<{
  asPreview?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idType?: InputMaybe<PostIdType>;
}>;

export type GetPreviewPostQuery = {
  __typename?: 'RootQuery';
  post?: {
    __typename?: 'Post';
    id: string;
    databaseId: number;
    slug?: string | null;
  } | null;
};

export type PostNodeByUriFragment = {
  __typename?: 'Post';
  id: string;
  translated?: Array<{
    __typename?: 'Post';
    id: string;
    locale?: { __typename?: 'Locale'; locale?: string | null } | null;
  } | null> | null;
};

export type GetNodeByUriQueryVariables = Exact<{
  uri: Scalars['String'];
}>;

export type GetNodeByUriQuery = {
  __typename?: 'RootQuery';
  nodeByUri?:
    | { __typename?: 'Category' }
    | { __typename?: 'Comment' }
    | { __typename?: 'ContentType' }
    | { __typename?: 'LessonsPost' }
    | { __typename?: 'MediaItem' }
    | { __typename?: 'Page' }
    | {
        __typename?: 'Post';
        id: string;
        translated?: Array<{
          __typename?: 'Post';
          id: string;
          locale?: { __typename?: 'Locale'; locale?: string | null } | null;
        } | null> | null;
      }
    | { __typename?: 'PostFormat' }
    | { __typename?: 'Tag' }
    | { __typename?: 'User' }
    | null;
};

export type GetRedirectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRedirectsQuery = {
  __typename?: 'RootQuery';
  redirection?: {
    __typename?: 'RedirectionRedirects';
    redirects?: Array<{
      __typename?: 'RedirectionRedirect';
      groupId?: number | null;
      groupName?: string | null;
      origin?: string | null;
      target?: string | null;
      type?: string | null;
      matchType?: string | null;
    } | null> | null;
  } | null;
};

export type TotalPostsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>;
}>;

export type TotalPostsQuery = {
  __typename?: 'RootQuery';
  posts?: {
    __typename?: 'RootQueryToPostConnection';
    pageInfo: {
      __typename?: 'RootQueryToPostConnectionPageInfo';
      total?: number | null;
    };
  } | null;
};
