import { FC } from 'react';
import { Position } from 'components/Common';
import { Button } from 'ui';

import styles from './InlineCTA.module.scss';

interface InlineCTAProps {
  title: string;
  linkLabel: string;
  link: string;
}

export const InlineCTA: FC<InlineCTAProps> = ({ title, linkLabel, link }) => {
  return (
    <Position position="inline">
      <div className={styles.root}>
        <div className={styles.topBorder} />
        <div className={styles.body}>
          <p
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
          <a
            className={styles.link}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <Button>{linkLabel}</Button>
          </a>
        </div>
      </div>
    </Position>
  );
};
