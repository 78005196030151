import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import React, { useContext } from 'react';
import { Box } from '../../primitives/Box';
import { color, fontSize, spacing, fontWeight } from '../../utils';
import { ArrowDownOutlined, ArrowUpOutlined, UpDownOutlined } from '../../assets/icons';
import { TableCellProps } from './types';
import { TableContext } from './TableContext';
import { TableSemanticContext } from './TableSemanticContext';

const getVariantStyles = ({ variant, stickyHeader, isHeadCell }) => {
    switch (variant) {
        case 'head':
            return css`
                color: ${color('text.low')};
                font-size: ${fontSize('sm')};
                font-weight: ${isHeadCell ? fontWeight('bold') : fontWeight('regular')};
                ${!!stickyHeader &&
                css`
                    position: sticky;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    background-color: palette('neutral.1000');
                `}
            `;
        case 'foot':
        case 'body':
        default:
            return css``;
    }
};

type StyledTableCell = TableCellProps & {
    variant: string;
    stickyHeader: boolean;
    hasHover: boolean;
    hasFocus: boolean;
    isHeadCell: boolean;
};

export const Cell = styled(Box)<StyledTableCell>`
    display: table-cell;
    padding: ${spacing('sm')} ${spacing('sm')};
    vertical-align: inherit;
    ${getVariantStyles};
    ${space}

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`;

const TableCell: React.FC<TableCellProps> = ({ children, isSorted, sortDirection, isSortable, ...props }) => {
    const { stickyHeader } = useContext(TableContext);
    // Get where the cell is rendered for context styling
    const { location } = useContext(TableSemanticContext);

    const isHeadCell = location === 'head';

    const SortIcon: React.FC = () => {
        if (isSorted) {
            switch (sortDirection) {
                case 'desc':
                    return <ArrowDownOutlined size="xs" />;
                case 'asc':
                default:
                    return <ArrowUpOutlined size="xs" />;
            }
        } else {
            return <UpDownOutlined size="xs" />;
        }
    };

    return (
        <Cell variant={location} stickyHeader={stickyHeader} isHeadCell={isHeadCell} {...props}>
            {isHeadCell ? (
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    {children}
                    {isSortable && (
                        <Box sx={{ display: 'inline-flex', marginX: 'xxs' }}>
                            <SortIcon />
                        </Box>
                    )}
                </Box>
            ) : (
                children
            )}
        </Cell>
    );
};

Cell.defaultProps = { textAlign: 'left' };

export { TableCell };
