import { FC, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useAudio } from 'react-use';
import { useHasMounted } from 'hooks';
import { BasicImage } from 'ui';
import { PlayPauseButton, SeekSlider, VolumeSlider } from './components';

import styles from './AudioPlayer.module.scss';

export interface AudioPlayerProps {
  src: string;
  image?: string;
  caption?: string;
}

interface PlayerControlsProps {
  src: string;
  hasImage: boolean;
}

const PlayerControls: FC<PlayerControlsProps> = ({ src, hasImage }) => {
  const hasMounted = useHasMounted();
  const [audio, state, controls] = useAudio({
    src: hasMounted ? src : '',
  });

  const controlsRef = useRef(controls);

  useEffect(() => {
    controlsRef.current = controls;
  }, [controls]);

  const handlePlay = useCallback(() => {
    controlsRef.current.play();
  }, []);

  const handlePause = useCallback(() => {
    controlsRef.current.pause();
  }, []);

  return (
    <div
      className={cn(styles.player, {
        [styles.withImage]: hasImage,
      })}
      aria-label="Audio Player"
      role="region"
    >
      {audio}
      <PlayPauseButton
        isPlaying={!state.paused}
        onPlay={handlePlay}
        onPause={handlePause}
      />
      <SeekSlider
        onSeekChange={controls.seek}
        duration={state.duration}
        time={state.time}
      />
      <VolumeSlider onVolumeChange={controls.volume} volume={state.volume} />
    </div>
  );
};

export const AudioPlayer: FC<AudioPlayerProps> = ({
  src,
  image,
  caption,
}: AudioPlayerProps) => {
  const PlayerImage = () => {
    if (!image) return null;

    return <BasicImage src={image} alt={caption || 'Audio track image'} />;
  };

  const PlayerCaption = () => {
    if (!caption) return null;

    return (
      <p
        className={styles.caption}
        dangerouslySetInnerHTML={{ __html: caption }}
      ></p>
    );
  };

  return (
    <div className={cn(styles.root)}>
      <PlayerImage />
      <PlayerControls src={src} hasImage={!!image} />
      <PlayerCaption />
    </div>
  );
};
