import {
    compose,
    borders,
    BordersProps,
    color,
    ColorProps,
    flexbox,
    FlexboxProps,
    grid,
    GridProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    shadow,
    ShadowProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
    opacity,
    OpacityProps,
} from 'styled-system';

export type SystemProps = BordersProps &
    ColorProps &
    ShadowProps &
    SpaceProps &
    TypographyProps &
    OpacityProps &
    Omit<LayoutProps, 'size'> &
    FlexboxProps &
    GridProps &
    PositionProps;

export const systemPropsFns = compose(
    borders,
    color,
    flexbox,
    grid,
    layout,
    position,
    shadow,
    space,
    typography,
    opacity,
);

export const allSystemProps = systemPropsFns.propNames;

// TODO: [DS-644] configure layout props to omit size props
const OMITTED_SYSTEM_PROPS = ['size'];

export const systemProps = allSystemProps?.filter((prop) => !OMITTED_SYSTEM_PROPS.includes(prop));

const isSystemProp = (prop: string) => systemProps?.includes(prop);

export const getSystemProps = <T extends Record<string, unknown>>(props: T): Pick<T, keyof SystemProps> =>
    Object.fromEntries(Object.entries(props).filter(([key]) => isSystemProp(key))) as unknown as Pick<
        T,
        keyof SystemProps
    >;

export const omitSystemProps = <T extends Record<string, unknown>>(props: T): Omit<T, keyof SystemProps> =>
    Object.fromEntries(Object.entries(props).filter(([key]) => !isSystemProp(key))) as unknown as Omit<
        T,
        keyof SystemProps
    >;
