import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProductReleasesOutlined = ({
    size,
    title = 'ProductReleasesOutlined',
    ...props
}: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <rect width={4.5} height={4.5} x={9.75} y={9.75} rx={2.25} />
            <rect width={16.5} height={16.5} x={3.75} y={3.75} rx={8.25} />
        </g>
    </svg>
);

export default withIcon(SvgProductReleasesOutlined);
