import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLightningFilled = ({ size, title = 'LightningFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M19.125 8.25H14.34l1.634-5.446a.624.624 0 00-.6-.804h-6.25a.625.625 0 00-.603.464l-2.5 9.375a.626.626 0 00.604.786h4.286l-1.156 8.668a.625.625 0 001.132.44l8.75-12.5a.625.625 0 00-.512-.983z"
            />
        </g>
    </svg>
);

export default withIcon(SvgLightningFilled);
