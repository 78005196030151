import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { radius, spacing, variant, themeMode, palette } from '../../utils/theme';
import { CardProps } from './types';

const sizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            padding: spacing('sm'),
        },
        md: {
            padding: spacing('lg'),
        },
        lg: {
            padding: spacing('xxl'),
        },
    },
});

const footerSizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            paddingTop: spacing('sm'),
        },
        md: {
            paddingTop: spacing('lg'),
        },
        lg: {
            paddingTop: spacing('lg'),
        },
    },
});

const variantStyles = variant({
    prop: 'variant',
    variants: {
        solid: {
            color: 'text.base',
            border: '1px solid transparent',
            backgroundColor: themeMode({ light: 'neutral.100', dark: 'neutral.900' }),
            '&[role="button"]': {
                '&:hover:not([aria-disabled="true"]), &[aria-selected="true"]': {
                    backgroundColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
                },
            },
        },
        outline: {
            backgroundColor: 'transparent',
            border: '1px solid',
            borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.700' }),
            '&[role="button"]': {
                '&:hover:not([aria-disabled="true"])': {
                    backgroundColor: themeMode({ light: 'neutral.100', dark: 'neutral.800' }),
                    '&[aria-selected="true"]': {
                        backgroundColor: themeMode({ light: 'neutral.200', dark: 'neutral.700' }),
                    },
                },
            },
            '&[aria-selected="true"]': {
                backgroundColor: themeMode({ light: 'neutral.200', dark: 'neutral.700' }),
            },
        },
    },
});

export const Header = styled(Box)`
    flex: 1 1 auto;
    padding-bottom: ${spacing('sm')};
`;

Header.displayName = 'Card.Header';

export const Content = styled(Box)`
    flex: 1 1 auto;
`;

Content.displayName = 'Card.Content';

export const Footer = styled(Box)`
    flex: 1 1 auto;
`;

Footer.displayName = 'Card.Footer';

export const Card = styled(Box)<CardProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${sizeStyles};
    ${variantStyles};
    border-radius: ${radius('base')};
    cursor: ${({ onClick }) => onClick && 'pointer'};
    border-color: ${({ isError }) => isError && palette('danger.500')};

    &:focus,
    &:active {
        outline: none;
    }

    &:focus-visible {
        box-shadow: ${themeMode({ dark: palette('neutral.50'), light: palette('neutral.900') })} 0px 0px 0px 2px;
    }

    &[aria-disabled='true'] {
        cursor: default;
        pointer-events: none;
        color: ${themeMode({
            dark: palette('neutral.500'),
            light: palette('neutral.400'),
        })};
    }

    ${Footer} {
        ${footerSizeStyles}
    }
`;
