import { useQuery } from '@apollo/client';
import { GET_NODE_BY_URI, GET_POSTS } from 'api/posts/queries';
import {
  GetNodeByUriQuery,
  GetNodeByUriQueryVariables,
  PostNodeByUriFragment,
  PostNodeFragment,
} from 'types/generated/graphql';

type UseGetNodeByUriOptions = {
  uri: string;
};

export const useGetNodeByUri = ({ uri }: UseGetNodeByUriOptions) => {
  const { data } = useQuery<GetNodeByUriQuery, GetNodeByUriQueryVariables>(
    GET_NODE_BY_URI,
    {
      variables: {
        uri,
      },
      errorPolicy: 'all',
    }
  );

  return data?.nodeByUri as PostNodeByUriFragment;
};
