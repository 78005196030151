import React from 'react';
import { maestroComponent } from '../../utils';
import { Chip } from '../Chip';
import { ChipGroupItemProps, ChipGroupProps } from './types';
import * as Styled from './styled';

// Single Select//

const StyledSingleSelectChip = ({
    isChecked,
    onChange,
    name,
    value,
    groupValue,
    isDisabled,
    isLoading,
    isError,
    isChipsFillGroupWidth,
    direction,
    gutter,
    ...props
}) => {
    const handleChange = () => {
        if (isError || isDisabled || isLoading) {
            return;
        }
        onChange([value]);
    };

    return (
        <Chip
            {...props}
            isChecked={groupValue.includes(value)}
            isDisabled={isDisabled}
            isError={isError}
            isLoading={isLoading}
            onClick={handleChange}
        />
    );
};

// Multiple Select//

const StyledMultipleSelectChip = ({
    groupValue,
    isChecked,
    isDisabled,
    isError,
    isLoading,
    name,
    onChange,
    isChipsFillGroupWidth,
    direction,
    gutter,
    value,
    ...props
}) => {
    const newGroupValue: Array<number | string> = [];
    const handleChange = () => {
        if (isDisabled || isError) {
            return;
        }
        const inputs = groupValue.slice();
        for (const i in inputs) {
            if (!newGroupValue.includes(inputs[i]) && inputs[i] !== value) {
                newGroupValue.push(inputs[i]);
                if (!groupValue.includes(value) && !newGroupValue.includes(value)) {
                    newGroupValue.push(value);
                }
            }
        }
        if (!inputs.length) {
            newGroupValue.push(value);
        }
        groupValue = newGroupValue;
        onChange(groupValue);
    };

    return (
        <Chip
            {...props}
            isDisabled={isDisabled}
            isError={isError}
            isLoading={isLoading}
            isChecked={groupValue.includes(value)}
            onClick={handleChange}
        />
    );
};

// The single chip//

const Item: React.FC<ChipGroupItemProps> = ({
    onChange,
    groupValue,
    value,
    name,
    isMultiSelect,
    isDisabled,
    isError,
    isLoading,
    isChecked,
    tooltipProps,
    isChipsFillGroupWidth,
    direction,
    gutter,
    ...props
}) => {
    const commonProps = {
        name,
        groupValue,
        value,
        onChange,
        isChecked,
        isDisabled,
        isError,
        isLoading,
        tooltipProps,
        isChipsFillGroupWidth,
        direction,
        gutter,
    };

    return isMultiSelect ? (
        <StyledMultipleSelectChip {...commonProps} {...props} />
    ) : (
        <StyledSingleSelectChip {...commonProps} {...props} />
    );
};

const StyledChipGroup: React.FC<ChipGroupProps> = ({
    shape,
    size,
    isMultiSelect,
    value,
    kind,
    isInvertedCheckedKind,
    onChange,
    isChipsFillGroupWidth,
    direction = 'horizontal',
    gutter = 'xxs',
    children,
    ...props
}) => {
    return (
        <Styled.ChipGroup
            isChipsFillGroupWidth={isChipsFillGroupWidth}
            direction={direction}
            gutter={gutter}
            {...props}
        >
            {React.Children.map(children, (item: React.ReactElement<any>) =>
                React.cloneElement(item, {
                    size: size,
                    shape: shape,
                    kind: kind,
                    isInvertedCheckedKind: isInvertedCheckedKind,
                    isDisabled: item.props.isDisabled,
                    isChecked: item.props.isChecked,
                    isLoading: item.props.isLoading,
                    isError: item.props.isError,
                    groupValue: value,
                    onChange: onChange,
                    isMultiSelect: isMultiSelect,
                    isChipsFillGroupWidth: isChipsFillGroupWidth,
                    direction: direction,
                    gutter: gutter,
                }),
            )}
        </Styled.ChipGroup>
    );
};

const compoundComponents = {
    Item,
};

export const ChipGroup = maestroComponent<ChipGroupProps, typeof compoundComponents>(
    'ChipGroup',
    StyledChipGroup,
    compoundComponents,
);
