import { Breakpoint } from '../../types';
import { Box, BoxProps } from '../../primitives/Box';
import { styled } from '../../utils';

export type NegativeWrapperProps = BoxProps & {
    enableBelow?: Breakpoint;
};

export const NegativeWrapper = styled(Box)<NegativeWrapperProps>`
    ${({ enableBelow = 'md', theme }) => theme.breakpoint(enableBelow, 'down')`
        margin-left: calc(var(--maestro-container-padding) * -1);
        margin-right: calc(var(--maestro-container-padding) * -1);
    `};
`;
