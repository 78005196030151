import React, { useContext } from 'react';
import { CollapseProps } from './types';
import { AccordionContext } from './Accordion';
import { Title } from './CollapseTitle';
import { Trigger } from './CollapseTrigger';
import { Content } from './CollapseContent';
import { BaseCollapse } from './BaseCollapse';
import { CollapseWithContext } from './CollapseWithContext';
import { CollapseWithState } from './CollapseWithState';

// Collapse
// the consumer - facing component.Decides whether to use the context
// or state controlled version of the base component.

/**
 * The Collapse has two controller implementations:
 *
 * CollapseWithState uses a stateful hook to control its open/closed state and
 * is useful where the component does not need to be aware of any other
 * Collapses. Will be used if no AccordionContext is present.
 *
 * CollapseWithContext relies on the AccordionContext provider to ensure that
 * only one Collapse in a group is open at any given time.
 */

export const Collapse: React.FC<CollapseProps> = ({ itemKey, isOpen, onToggle, ...rest }) => {
    const context = useContext(AccordionContext);

    if (context && typeof itemKey !== 'undefined') {
        return <CollapseWithContext {...rest} itemKey={itemKey} />;
    }

    if (typeof onToggle === 'function' && typeof isOpen === 'boolean') {
        return <BaseCollapse onToggle={onToggle} isOpen={isOpen} {...rest} />;
    }

    return <CollapseWithState {...rest} />;
};

Collapse.displayName = 'Collapse';

export default Object.assign(Collapse, { Title, Trigger, Content });
