import React from 'react';
import { CollapseTitleProps } from './types';
import * as Styled from './styled';

export const Title: React.FC<CollapseTitleProps> = (props) => {
    const { isOpen, nbOfLines = 2, children, ...rest } = props;
    return (
        <Styled.CollapseTitleContent nbOfLines={isOpen ? 'auto' : nbOfLines} {...rest}>
            {children}
        </Styled.CollapseTitleContent>
    );
};

Title.displayName = 'Collapse.Title';
