import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPhoneOutlined = ({ size, title = 'PhoneOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M16.19 13.905l-2.285 2.286-6.095-6.096 2.285-2.285L6.285 4 4 6.286C4 13.86 10.14 20 17.714 20L20 17.714l-3.81-3.81z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgPhoneOutlined);
