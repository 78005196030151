import React from 'react';
import { forwardRef } from '../../system';
import { ProductHeaderProps } from './types';
import { ProductHeaderName } from './ProductHeaderName';
import { ProductHeaderNav } from './ProductHeaderNav';
import { ProductHeaderAddon } from './ProductHeaderAddon';
import { ProductHeaderCollapse } from './ProductHeaderCollapse';
import * as Styled from './styled';

const ProductHeader = forwardRef<ProductHeaderProps, 'header'>((props, ref) => {
    const { children, ...rest } = props;
    return (
        <Styled.Wrapper ref={ref} {...rest}>
            {children}
        </Styled.Wrapper>
    );
});

ProductHeader.displayName = 'ProductHeader';

export default Object.assign(ProductHeader, {
    Name: ProductHeaderName,
    Nav: ProductHeaderNav,
    Addon: ProductHeaderAddon,
    Collapse: ProductHeaderCollapse,
});
