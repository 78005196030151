export const spotifyParser = (
  url: string
): { isTrack: boolean; slug: string } | undefined => {
  if (!url.includes('https://open.spotify.com/')) {
    return undefined;
  }

  try {
    const pieces = url.split('https://open.spotify.com/');
    const slug = pieces[pieces.length - 1];
    return {
      isTrack: slug.includes('track'),
      slug,
    };
  } catch (e) {
    return undefined;
  }
};

export const instagramParser = (
  url: string
): { baseUrl: string; isReel: boolean; id: string } | undefined => {
  try {
    const regExp =
      /^(https:\/\/.*instagram\.com\/(p|reel)\/([^\/\?\r\n]+))\/?\??.*/;
    const match = url.match(regExp);
    if (!match) {
      return undefined;
    }

    return {
      baseUrl: match[1],
      isReel: match[2] === 'reel',
      id: match[3],
    };
  } catch (e) {
    return undefined;
  }
};

export const tiktokParser = (url: string): { id: string } | undefined => {
  try {
    const regExp =
      /^https:\/\/(?:m|www|vm)?\.?tiktok\.com\/((?:.*\b(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)(\d+))|\w+)/;
    const match = url.match(regExp);
    if (!match) {
      return undefined;
    }

    return {
      id: match[2],
    };
  } catch (e) {
    return undefined;
  }
};

export const youtubeShortParser = (url: string): { id: string } | undefined => {
  try {
    const regExp =
      /^(https:\/\/.*youtube\.com\/shorts\/([^\/\?\r\n]+))\/?\??.*/;
    const match = url.match(regExp);
    if (!match) {
      return undefined;
    }

    return {
      id: match[2],
    };
  } catch (e) {
    return undefined;
  }
};
