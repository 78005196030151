import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCdTimerOutlined = ({ size, title = 'CdTimerOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5} transform="translate(3.8 3.8)">
            <path strokeLinecap="round" d="M5.8 16.1a8.3 8.3 0 1110.7-8.7m-6.3-.3a2.3 2.3 0 10-3.5 2.7" />
            <circle cx={12.5} cy={13.5} r={4} />
            <path strokeLinecap="round" d="M14 14.5l-1.5-1m0 0v-1.8" />
        </g>
    </svg>
);

export default withIcon(SvgCdTimerOutlined);
