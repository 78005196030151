import { ApolloClient } from '@apollo/client';
import { GetStaticPropsContext } from 'next';
import {
  FooterMenuQuery,
  FooterMenuQueryVariables,
  MenuLocationEnum,
} from 'types';
import { GET_FOOTER_MENUS } from 'api/menu/queries';

export async function getFooterMenuItems(
  apolloClient: ApolloClient<unknown>,
  { locale }: GetStaticPropsContext
): Promise<FooterMenuQuery> {
  let { data } = await apolloClient.query<
    FooterMenuQuery,
    FooterMenuQueryVariables
  >({
    query: GET_FOOTER_MENUS,
    variables: {
      firstWhere: {
        location: MenuLocationEnum.AlsFooter_1,
        language: locale,
      },
      secondWhere: {
        location: MenuLocationEnum.AlsFooter_2,
        language: locale,
      },
      thirdWhere: {
        location: MenuLocationEnum.AlsFooter_3,
        language: locale,
      },
    },
    errorPolicy: 'all',
  });

  return data;
}
