import { createContext, MouseEvent, useContext } from 'react';

export type NavBarContextType = {
    closePanel: (event?: MouseEvent) => void;
};

export const NavBarContext = createContext<NavBarContextType>({
    closePanel: () => undefined,
});

export const useNavBarContext = () => {
    return useContext(NavBarContext);
};
