export interface IYouTubeVideosService {
  addVideo(videoId: string): void;
  get hasVideos(): boolean;
  getVideoIds(): string[];
}

export class YouTubeVideosService implements IYouTubeVideosService {
  private _videos: Set<string> = new Set();

  public addVideo(videoId: string): void {
    this._videos.add(videoId);
  }

  public get hasVideos(): boolean {
    return this._videos.size > 0;
  }

  public getVideoIds(): string[] {
    return [...this._videos];
  }
}
