import { FilterDropdownItem, FilterDropdownGroupItem, FilterDropdownGroupItems, FilterDropdownProps } from './types';

export const getGroups = <T>(
    groupBy: FilterDropdownProps<T>['groupBy'],
    items: FilterDropdownProps<T>['items'],
): (FilterDropdownItem<T> | undefined)[] =>
    groupBy ? Array.from(new Set(items.map((item) => groupBy(item)))) : [undefined];

export const getItemsByGroup = <T>(
    groupBy: FilterDropdownProps<T>['groupBy'],
    groups: FilterDropdownItem<T>['group'][],
    items: FilterDropdownGroupItem<T>[],
): FilterDropdownGroupItems<T>[] =>
    groups.map((group) => ({
        group,
        items: groupBy ? items.filter((item) => groupBy(item) === group) : items,
    }));
