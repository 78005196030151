import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgInfoOutlined = ({ size, title = 'InfoOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M20.25 12a8.25 8.25 0 11-16.501-.001A8.25 8.25 0 0120.25 12z"
            />
            <circle cx={12} cy={8.5} r={1.25} fill="currentColor" />
            <rect width={2} height={6} x={11} y={11} fill="currentColor" rx={1} />
        </g>
    </svg>
);

export default withIcon(SvgInfoOutlined);
