import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgArrowForwardFilled = ({ size, title = 'ArrowForwardFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2 12c0 5.523 4.477 10 10 10s10-4.477 10-10S17.523 2 12 2 2 6.477 2 12zm5 0l.007-.102a.75.75 0 01.743-.648l6.688-.001L12.22 9.03l-.073-.084a.75.75 0 011.133-.976l3.5 3.5.073.084a.75.75 0 01-.072.977l-3.5 3.5-.085.072a.75.75 0 01-.976-.072l-.073-.085a.75.75 0 01.073-.976l2.22-2.221-6.69.001-.102-.007A.75.75 0 017 12z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgArrowForwardFilled);
