import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgInfoFilled = ({ size, title = 'InfoFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="none" d="M-1-1h582v402H-1z" />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 3a9 9 0 110 18 9 9 0 010-18zm0 4a1.25 1.25 0 100 2.5A1.25 1.25 0 0012 7zm1 9.01v-4.02a1 1 0 00-1-.99c-.556 0-1 .444-1 .99v4.02a1 1 0 001 .99c.556 0 1-.444 1-.99z"
        />
    </svg>
);

export default withIcon(SvgInfoFilled);
