import React, { FC, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import PlayIcon from '../../../../public/icons/play-audio.svg';
import PauseIcon from '../../../../public/icons/pause-audio.svg';

import styles from './PlayPauseButton.module.scss';

interface PlayPauseButtonProps {
  isPlaying: boolean;
  onPause: () => void;
  onPlay: () => void;
  className?: string;
}

const PlayerIcon: React.FC<{ isPlaying: boolean }> = ({ isPlaying }) => {
  if (isPlaying) {
    return <PauseIcon className={styles.icon} />;
  } else {
    return <PlayIcon className={styles.icon} />;
  }
};

export const PlayPauseButton: FC<PlayPauseButtonProps> = ({
  isPlaying,
  onPause,
  onPlay,
  className,
}) => {
  const handlePlayPauseButtonClick = useCallback(() => {
    if (isPlaying) {
      onPause();
    } else {
      onPlay();
    }
  }, [isPlaying, onPlay, onPause]);

  const title = isPlaying ? 'Pause' : 'Play';

  return (
    <button
      className={cn(styles.root, className)}
      type="button"
      onClick={handlePlayPauseButtonClick}
      aria-label={title}
      title={title}
    >
      <PlayerIcon isPlaying={isPlaying} />
    </button>
  );
};

PlayPauseButton.displayName = 'PlayPauseButton';
