import React, { cloneElement, Children, isValidElement, createContext, MouseEvent } from 'react';
import { css } from '@styled-system/css';
import styled, { CSSObject } from 'styled-components';
import { Box } from '../../primitives/Box';
import { forwardRef } from '../../system';
import { ToggleButtonGroupProps, ToggleButtonGroupContextOptions } from './types';

const buttonsStyles = (isStacked: boolean | undefined): CSSObject => {
    if (isStacked) {
        return {
            'button:not(:first-child)': {
                marginTop: -1,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            },
            'button:not(:last-child)': {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        };
    }
    return {
        'button:not(:first-child)': {
            marginLeft: -1,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        'button:not(:last-child)': {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    };
};

const StyledToggleButtonGroup = styled(Box)<Omit<ToggleButtonGroupProps, 'onChange' | 'value'>>((props) =>
    css({
        width: props.isFull ? '100%' : 'auto',
        display: 'flex',
        flexDirection: props.isStacked ? 'column' : 'row',
        button: {
            flex: '1 1 auto',
        },
        ...buttonsStyles(props.isStacked),
    }),
);

export const ToggleButtonGroupContext = createContext<ToggleButtonGroupContextOptions>({ value: undefined });

const ToggleButtonGroup = forwardRef<ToggleButtonGroupProps, 'div'>(
    (
        { children, onChange, value, size, isRounded = false, isFull = false, isStacked = false, isDisabled, ...props },
        ref,
    ) => {
        const handleChange = (event: MouseEvent<HTMLButtonElement>, value: any): void => {
            onChange?.(event, value);
        };

        return (
            <ToggleButtonGroupContext.Provider value={{ value }}>
                <StyledToggleButtonGroup ref={ref} isFull={isFull} isStacked={isStacked} {...props}>
                    {Children.map(children, (child) => {
                        if (!isValidElement(child)) {
                            return null;
                        }
                        return cloneElement(child, {
                            size: child.props.size || size,
                            isRounded: child.props.isRounded || isRounded,
                            isDisabled: child.props.isDisabled || isDisabled,
                            onChange: handleChange,
                        });
                    })}
                </StyledToggleButtonGroup>
            </ToggleButtonGroupContext.Provider>
        );
    },
);

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

export { ToggleButtonGroup };
