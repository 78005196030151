import { changePageIfAppropriate, getPagesRange, numberOfPagesFromItems } from './helpers';

const usePagination = ({
    currentPage,
    isTruncated,
    items,
    itemsPerPage,
    pagesPerSlice,
    onPageChanged,
}: {
    currentPage: number;
    isTruncated?: boolean;
    items: number;
    itemsPerPage: number;
    pagesPerSlice: number;
    onPageChanged?: (nextPage: number) => void;
}) => {
    const goToPage = (pageNumber: number) => changePageIfAppropriate(pageNumber, items, itemsPerPage, onPageChanged);

    const numberOfPages = numberOfPagesFromItems(items, itemsPerPage);
    const shouldTruncate = isTruncated && numberOfPages > pagesPerSlice;
    const truncationOffset = shouldTruncate ? 2 : 0;
    const _pagesPerSlice = shouldTruncate ? pagesPerSlice - truncationOffset : pagesPerSlice;

    let rangeFirstPage: number;
    let rangeLastPage: number;
    let hasEndRange = false;

    if (numberOfPages <= _pagesPerSlice) {
        rangeFirstPage = 1;
        rangeLastPage = numberOfPages;
    } else {
        // Get the pages before and after the currentPage
        const pagesBeforeCurrentPage = Math.floor(_pagesPerSlice / 2);
        const pagesAfterCurrentPage = Math.ceil(_pagesPerSlice / 2) - 1;

        if (currentPage <= pagesBeforeCurrentPage) {
            // currentPage is in the start range
            rangeFirstPage = 1;
            rangeLastPage = _pagesPerSlice;
            hasEndRange = false;
        } else if (currentPage + pagesAfterCurrentPage + truncationOffset >= numberOfPages) {
            // currentPage is in the end range
            rangeFirstPage = numberOfPages - _pagesPerSlice + 1;
            rangeLastPage = numberOfPages;
            hasEndRange = true;
        } else {
            rangeFirstPage = currentPage - pagesBeforeCurrentPage;
            rangeLastPage = currentPage + pagesAfterCurrentPage;
        }
    }

    const pagesRange = getPagesRange(rangeFirstPage, rangeLastPage);
    const startRange = hasEndRange ? [1] : pagesRange;
    const endRange = hasEndRange ? pagesRange : [numberOfPages];

    return { numberOfPages, pagesRange, shouldTruncate, startRange, endRange, goToPage };
};

export default usePagination;
