import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgRemoveOutlined = ({ size, title = 'RemoveOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <circle cx={12} cy={12} r={8.25} />
            <path strokeLinecap="round" d="M14.83 14.83L9.17 9.17m0 5.66l5.66-5.66" />
        </g>
    </svg>
);

export default withIcon(SvgRemoveOutlined);
