import React from 'react';
import { forwardRef } from '../../system';
import { InfoFilled, CheckCircleFilled, WarningFilled, RemoveFilled } from '../../assets/icons';
import { filterChildrenByType } from '../../utils';
import { ButtonGroup } from '../ButtonGroup';
import { ButtonProps } from '../Button';
import { AlertProps } from './types';
import * as Styled from './styled';

const iconMap = {
    default: <InfoFilled />,
    success: <CheckCircleFilled />,
    warning: <WarningFilled />,
    danger: <RemoveFilled />,
    info: <InfoFilled />,
};

const actionPaletteMap: Record<keyof AlertProps['palette'], keyof ButtonProps['variant']> = {
    default: 'primary',
    success: 'primary',
    warning: 'warning',
    danger: 'danger',
    info: 'accent',
};

const AlertBase = forwardRef<AlertProps, 'div'>((props, ref) => {
    const { children, variant = 'solid', palette = 'default', icon = iconMap[palette], ...rest } = props;

    const content = filterChildrenByType(children, [Styled.Content, Styled.Title]);
    const actions = filterChildrenByType(children, Styled.Action);

    return (
        <Styled.Alert ref={ref} variant={variant} palette={palette} role="alert" {...rest}>
            {icon && <Styled.Icon palette={palette}>{icon}</Styled.Icon>}
            {content}
            {!!actions && (
                <Styled.ActionGroup>
                    <ButtonGroup variant={actionPaletteMap[palette]}>{actions}</ButtonGroup>
                </Styled.ActionGroup>
            )}
        </Styled.Alert>
    );
});

AlertBase.displayName = 'Alert';

export const Alert = Object.assign(AlertBase, { Title: Styled.Title, Content: Styled.Content, Action: Styled.Action });
