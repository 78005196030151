import { Media } from '@landr/maestro';
import { PostsGrid } from 'components/Page/PostsGrid';
import Image from 'next/image';
import styles from './AuthorProfile.module.scss';
import dynamic from 'next/dynamic';
import { AuthorProfileQuery } from 'types';
import { MappedPost } from 'ui';

const DynamicErrorState = dynamic(() =>
  import('../Common/ErrorState/ErrorState').then((module) => module.ErrorState)
);

const DynamicEmptyState = dynamic(() =>
  import('../Common/EmptyState/EmptyState').then((module) => module.EmptyState)
);

interface AuthorProfileProps {
  user: AuthorProfileQuery['user'] | null;
  posts: MappedPost[];
  totalAmountOfPosts: number;
  page: string;
  error: {} | null;
}

export const AuthorProfile = (props: AuthorProfileProps) => {
  const { user, posts, totalAmountOfPosts, page, error } = props;

  return (
    <div className={styles.root}>
      <main className={styles.main}>
        {!!error ? (
          <DynamicErrorState />
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.headerEntry}>
                <Media>
                  <Media.Image>
                    {user?.avatar?.url && (
                      <Image
                        src={user?.avatar?.url}
                        alt={`${user?.name}'s avatar`}
                        width={160}
                        height={160}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                        }}
                      />
                    )}
                  </Media.Image>
                  <Media.Body>
                    <h1 className={styles.title}>{user?.name}</h1>
                    {user?.description && (
                      <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{
                          __html: user?.description,
                        }}
                      ></p>
                    )}
                  </Media.Body>
                </Media>
              </div>
            </div>
            <div className={styles.body}>
              {!posts?.length ? (
                <DynamicEmptyState />
              ) : (
                <PostsGrid
                  posts={posts}
                  totalAmountOfPosts={totalAmountOfPosts}
                  page={page}
                  pageUrlPrefix={`/author/${user?.slug}`}
                />
              )}
            </div>
          </>
        )}
      </main>
    </div>
  );
};
