import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgBentoOutlined = ({ size, title = 'BentoOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="currentColor" transform="translate(4 4)">
            <rect width={4} height={4} rx={2} />
            <rect width={4} height={4} x={6} rx={2} />
            <rect width={4} height={4} x={12} rx={2} />
            <rect width={4} height={4} y={6} rx={2} />
            <rect width={4} height={4} x={6} y={6} rx={2} />
            <rect width={4} height={4} x={12} y={6} rx={2} />
            <rect width={4} height={4} y={12} rx={2} />
            <rect width={4} height={4} x={6} y={12} rx={2} />
            <rect width={4} height={4} x={12} y={12} rx={2} />
        </g>
    </svg>
);

export default withIcon(SvgBentoOutlined);
