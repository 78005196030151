import { createContext, useContext } from 'react';
import { ModalSize, ModalVariant } from './types';

type ModalContextProps = {
    onClose: () => void;
    showCloseButton: boolean;
    variant: ModalVariant;
    size: ModalSize;
    isDismissible: boolean;
    scrollBehavior?: 'inside' | 'outside';
};

export const ModalContext = createContext<ModalContextProps>({
    onClose: (): void => undefined,
    showCloseButton: true,
    variant: 'base',
    size: 'md',
    isDismissible: true,
    scrollBehavior: 'inside',
});

export const useModalContext = (): ModalContextProps => {
    const context = useContext(ModalContext);
    return context;
};
