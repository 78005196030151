import { useRouter } from 'next/router';
import { SEARCH_KEY } from '../../../constants/queryParams';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  SearchPostsQuery,
  SearchPostsQueryVariables,
} from 'types/generated/graphql';
import { SEARCH_POSTS } from '../../../api/posts/queries';

export type SearchPostsQueryNode = NonNullable<
  NonNullable<SearchPostsQuery['posts']>['nodes']
>[0];

export const usePostsSearchQuery = () => {
  const AMOUNT_OF_POSTS_PER_PAGE = 30;

  const { query, locale } = useRouter();
  const searchKeyFromParams = (query?.[SEARCH_KEY] as string) || '';

  const [totalAmountOfFoundPosts, setTotalAmountOfFoundPosts] = useState(0);
  const [amountOfDownloadedPosts, setAmountOfDownloadedPosts] = useState(0);

  const [loadPosts, { loading, data, error, variables }] = useLazyQuery<
    SearchPostsQuery,
    SearchPostsQueryVariables
  >(SEARCH_POSTS, {
    errorPolicy: 'all',
    onCompleted: (data) => {
      setAmountOfDownloadedPosts(
        amountOfDownloadedPosts + (data?.posts?.nodes?.length || 0)
      );
    },
  });

  const total = data?.posts?.pageInfo?.total;
  const hasNextPage = data?.posts?.pageInfo?.hasNextPage;

  useEffect(() => {
    if (total && total > totalAmountOfFoundPosts) {
      setTotalAmountOfFoundPosts(total + (hasNextPage ? 20 : 0));
    }
  }, [total, totalAmountOfFoundPosts, hasNextPage]);

  useEffect(() => {
    const onMount = searchKeyFromParams && !loading && !data;
    const onParamChange = variables?.where?.search !== searchKeyFromParams;

    if (onMount || onParamChange) {
      loadPosts({
        variables: {
          first: AMOUNT_OF_POSTS_PER_PAGE,
          where: {
            wpmlLanguage: locale,
            search: searchKeyFromParams,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyFromParams, locale, loadPosts, loading]);

  const loadMorePosts = () => {
    loadPosts({
      variables: {
        first: AMOUNT_OF_POSTS_PER_PAGE,
        where: {
          wpmlLanguage: locale,
          search: searchKeyFromParams,
        },
        after: data?.posts?.pageInfo?.endCursor,
      },
    });
  };

  return {
    data: data?.posts?.nodes || [],
    total: totalAmountOfFoundPosts,
    hasNextPage: data?.posts?.pageInfo?.hasNextPage,
    amountOfDownloadedPosts,
    loadMore: loadMorePosts,
    error,
    loading,
  };
};
