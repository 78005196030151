import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProjectsRed = ({ size, title = 'ProjectsRed', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#435261"
            d="M47 29c0-7.168-7.626-13-17-13-9.374 0-17 5.832-17 13s7.626 13 17 13a22.01 22.01 0 003.27-.247l8.283 4.141a.992.992 0 00.973-.043c.294-.183.474-.504.474-.851v-7.64c2.492-2.263 4-5.178 4-8.36z"
        />
        <path
            fill="#2C3C4D"
            d="M22 2C10.42 2 1 9.626 1 19c0 3.603 1.381 7.038 4 9.96V39a1.003 1.003 0 001 1c.153 0 .306-.035.447-.105l9.33-4.665c2.035.511 4.126.77 6.223.77 11.58 0 21-7.626 21-17 0-9.374-9.42-17-21-17z"
        />
        <path
            fill="#EA5B70"
            d="M32.386 20.897L24.9 26.334 19.394 18.9a4.488 4.488 0 011.02-6.491 4.486 4.486 0 016.4 1.487 4.487 4.487 0 115.572 6.999v.003z"
        />
        <path
            fill="white"
            d="M13.026 11.88l.82 9.931-1.356.207-2.176-9.726 2.712-.413zm.904 15.035a1.8 1.8 0 10-.542-3.56 1.8 1.8 0 00.542 3.56z"
        />
    </svg>
);

export default withIcon(SvgProjectsRed);
