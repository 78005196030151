import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFolderOutlined = ({ size, title = 'FolderOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.5 8.5l-2-3h-5v11a2 2 0 002 2h11a2 2 0 002-2v-8h-8z"
        />
    </svg>
);

export default withIcon(SvgFolderOutlined);
