import React from 'react';
import { css } from '@styled-system/css';
import styled from '../../utils/styled';
import { spacing, variant } from '../../utils';
import { Box } from '../../primitives/Box';
import { forwardRef } from '../../system';
import { ContainerProps } from './types';

type StyledContainerProps = ContainerProps & {
    responsiveSize: ContainerProps['size'];
};

const containedStyles = css((theme) => ({
    maxWidth: [
        '100%',
        theme.containers.xs,
        theme.containers.sm,
        theme.containers.ms,
        theme.containers.md,
        theme.containers.ml,
        theme.containers.lg,
        theme.containers.xl,
        theme.containers.xxl,
    ],
}));

const sizeVariants = variant({
    prop: 'responsiveSize',
    variants: {
        fluid: {
            maxWidth: '100%',
        },
        contained: containedStyles,
        xs: {
            maxWidth: ({ theme }) => theme.containers.xs,
        },
        sm: {
            maxWidth: ({ theme }) => theme.containers.sm,
        },
        ms: {
            maxWidth: ({ theme }) => theme.containers.ms,
        },
        md: {
            maxWidth: ({ theme }) => theme.containers.md,
        },
        ml: {
            maxWidth: ({ theme }) => theme.containers.ml,
        },
        lg: {
            maxWidth: ({ theme }) => theme.containers.lg,
        },
        xl: {
            maxWidth: ({ theme }) => theme.containers.xl,
        },
        xxl: {
            maxWidth: ({ theme }) => theme.containers.xxl,
        },
    },
});

const StyledContainer = styled(Box)<StyledContainerProps>`
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    /* Default padding - mobile first */
    padding-left: ${spacing('sm')};
    padding-right: ${spacing('sm')};
    --maestro-container-padding: ${spacing('sm')};

    ${({ theme }): string => theme.breakpoint('ms', 'up')`
        padding-left: ${spacing('lg')};
        padding-right: ${spacing('lg')};
        --maestro-container-padding: ${spacing('lg')};
    `};

    ${({ theme }): string => theme.breakpoint('lg', 'up')`
        padding-left: ${spacing('xl')};
        padding-right: ${spacing('xl')};
        --maestro-container-padding: ${spacing('xl')};
    `};

    ${sizeVariants};
`;

export const Container = forwardRef<ContainerProps, 'div'>((props, ref) => {
    const { size = 'xxl', ...rest } = props;

    return <StyledContainer ref={ref} responsiveSize={size} {...rest} />;
});

Container.displayName = 'Container';
