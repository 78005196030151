import { FC, useEffect, useRef } from 'react';
import { useAudio } from 'react-use';
import { useHasMounted } from 'hooks';
import { BasicImage } from 'ui';
import PlayIcon from '../../public/icons/play-audio-sample.svg';
import PauseIcon from '../../public/icons/pause-audio-sample.svg';

import styles from './AudioSample.module.scss';

export interface AudioSampleProps {
  image: string;
  src: string;
}

interface AudioSampleControlsProps {
  src: string;
}

const AudioSampleControls: FC<AudioSampleControlsProps> = ({ src }) => {
  const hasMounted = useHasMounted();

  const [audio, state, controls] = useAudio({
    src: hasMounted ? src : '',
  });
  const controlsRef = useRef(controls);
  const isPlaying = !state.paused;

  useEffect(() => {
    controlsRef.current = controls;
  }, [controls]);

  const handlePlayPauseButtonClick = () => {
    if (isPlaying) {
      controlsRef.current.pause();
    } else {
      controlsRef.current.play();
    }
  };

  const title = isPlaying ? 'Pause' : 'Play';

  const PlayerIcon = () => {
    if (isPlaying) {
      return <PauseIcon className={styles.icon} />;
    } else {
      return <PlayIcon className={styles.icon} />;
    }
  };

  return (
    <>
      {audio}
      <button
        className={styles.button}
        type="button"
        onClick={handlePlayPauseButtonClick}
        aria-label={title}
        title={title}
      >
        <PlayerIcon />
      </button>
    </>
  );
};

export const AudioSample: FC<AudioSampleProps> = ({ image, src }) => {
  return (
    <div className={styles.root}>
      <BasicImage src={image} alt="Audio Sample" />
      <AudioSampleControls src={src} />
    </div>
  );
};
