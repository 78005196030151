import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMastering = ({ size, title = 'Mastering', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="#435261" d="M29 34H19v10h10V34z" />
        <path fill="#2C3C4D" d="M45 3H3a2 2 0 00-2 2v30a2 2 0 002 2h42a2 2 0 002-2V5a2 2 0 00-2-2z" />
        <path fill="#2C3C4D" d="M42 6H6a2 2 0 00-2 2v21a2 2 0 002 2h36a2 2 0 002-2V8a2 2 0 00-2-2z" />
        <path
            stroke="#32CBCB"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M12.709 19.754v-7.872m-3.1 7.872V18.25m12.401 1.504v-4.258m-6.2 4.258v-5.588m3.1 5.588v-2.936m-6.201 1.428v7.872m-3.1-7.872v1.504m12.401-1.504v4.258m-6.2-4.258v5.588m3.1-5.588v2.936m9.799-1.428v-7.872m-3.101 7.872V18.25m12.402 1.504v-4.258m-6.2 4.258v-5.588m3.1 5.588v-2.936m-6.201 1.428v7.872m-3.101-7.872v1.504m12.402-1.504v4.258m-6.2-4.258v5.588m3.1-5.588v2.936"
        />
        <path fill="#2C3C4D" d="M34 42H14v3h20v-3z" />
        <path fill="#5A6774" d="M43 35a1 1 0 100-2 1 1 0 000 2zm-4 0a1 1 0 100-2 1 1 0 000 2z" />
    </svg>
);

export default withIcon(SvgMastering);
