import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProductBlogOutlined = ({ size, title = 'ProductBlogOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path strokeLinecap="round" d="M8.25 8.25h7.5m-7.5 3h5.5" />
            <path
                strokeLinejoin="round"
                d="M3.75 4.994A1.25 1.25 0 014.99 3.75h14.02c.685 0 1.24.57 1.24 1.244v10.012c0 .687-.562 1.244-1.244 1.244H15l-5 4v-4H5c-.69 0-1.25-.57-1.25-1.244V4.994z"
            />
        </g>
    </svg>
);

export default withIcon(SvgProductBlogOutlined);
