import React from 'react';
import { maestroComponent, MaestroComponent } from '../../utils';
import { Box, SXProp } from '../../primitives/Box';

export type IconSizes = 'xs' | 'sm' | 'md' | 'base' | 'lg' | 'xl';

export const iconSizes = {
    xs: 16,
    sm: 20,
    md: 24,
    lg: 32,
    xl: 44,
};

function getSize(size: IconSizes | number | string): any {
    if (typeof size === 'number') {
        return size;
    }

    if (typeof size === 'string' && size.includes('em')) {
        return size;
    }

    switch (size) {
        case 'xs':
            return iconSizes['xs'];
        case 'sm':
            return iconSizes['sm'];
        case 'base':
        case 'md':
            return iconSizes['md'];
        case 'lg':
            return iconSizes['lg'];
        case 'xl':
            return iconSizes['xl'];
        default:
            return iconSizes['md'];
    }
}

export interface IconProps extends SXProp {
    size?: IconSizes | number;
    title?: string;
}

export interface SvgIconProps {
    size: number;
    title?: string;
}

export const withIcon = (
    IconComponent: React.ComponentType<SvgIconProps>,
): MaestroComponent<IconProps, Record<string, any>> => {
    const Wrapper: React.FC<IconProps> = ({ size = 'base', ...props }: IconProps) => (
        <Box as={IconComponent} size={getSize(size)} {...props} />
    );

    return maestroComponent<IconProps>('Icon', Wrapper);
};
