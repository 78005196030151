import { ButtonSize } from './types';

export const getButtonIconSize = (size: ButtonSize): string => {
    switch (size) {
        case 'xs':
            return 'xs';
        case 'sm':
            return 'sm';
        case 'md':
        case 'lg':
        default:
            return 'md';
    }
};
