import React, { Children } from 'react';
import { isFragment } from 'react-is';

type ReactChildArray = ReturnType<typeof Children.toArray>;

/**
 * Returns a flatten array of children. Useful when you need to include the content of a React Fragment.
 */

export const flattenChildren = (children: React.ReactNode): ReactChildArray =>
    Children.toArray(children).reduce<ReactChildArray>((acc: ReactChildArray, child) => {
        if (isFragment(child)) {
            return acc.concat(flattenChildren(child.props.children));
        }

        acc.push(child);

        return acc;
    }, []);
