import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { InputProps } from '../Input';
import { spacing } from '../../utils';

export const Select = styled(Box).attrs(() => ({ as: 'button', type: 'button' }))<InputProps>`
    cursor: pointer;
`;

export const SelectCaret = styled('span')`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 ${spacing('md')};
    display: flex;
    place-items: center;
    pointer-events: none;
`;
