import { FC } from 'react';
import Link from 'next/link';
import { CATEGORY } from 'constants/routes';
import { FeatureWrapper } from 'components/Common';
import { BasicImage, Label, MappedPost } from 'ui';
import styles from './OneUpStandardLesson.module.scss';
interface OneUpStandardLessonProps {
  post: MappedPost;
}
export const OneUpStandardLesson: FC<OneUpStandardLessonProps> = ({ post }) => {
  const { title, slug, categorySlug, categoryName, image } = post;

  return (
    <FeatureWrapper>
      <Link href={`/${slug}`} className={styles.root}>
        <div className={styles.entry}>
          <BasicImage
            src={image || '/temporary/post-cover-example.png'}
            alt={title}
          />
          <div className={styles.overlay} />
          <div className={styles.details}>
            <Label
              label={categoryName}
              href={`${CATEGORY}/${categorySlug}`}
              accentColour="black-pearl"
              background="white"
              className={styles.label}
            />
            <h2
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h2>
          </div>
        </div>
      </Link>
    </FeatureWrapper>
  );
};
