import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCheckCircleFilled = ({ size, title = 'CheckCircleFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1.134-7.197L8.53 12.47a.75.75 0 00-1.06 1.062l3.002 3a.75.75 0 001.167-.134l4.997-8a.75.75 0 10-1.272-.794l-4.498 7.2z"
        />
    </svg>
);

export default withIcon(SvgCheckCircleFilled);
