import React, { useEffect, useState, useMemo } from 'react';
import { Box } from '../../../primitives';
import { Progress } from '../../Progress';
import Styled from './styled';
import { DotNavigationProps, DotProps, DotSelectedProps } from './types';

const DotSelected: React.FC<DotSelectedProps> = ({ onClick, dotIndex, transitionDelay }) => {
    const [progress, setProgress] = useState(0);
    const handleClick = () => {
        onClick(dotIndex);
    };

    useEffect(() => {
        setProgress(transitionDelay ? 1 : 0);
    }, [transitionDelay]);

    return (
        <Styled.DotWrapper as="button" onClick={handleClick} tabIndex={-1}>
            <Styled.AbsoluteBox>
                <Progress size={16} strokeWidth={6} progress={progress} transitionDelay={transitionDelay} />
            </Styled.AbsoluteBox>
        </Styled.DotWrapper>
    );
};

const DotUnSelected: React.FC<DotProps> = ({ onClick, dotIndex }) => {
    const handleClick = () => {
        onClick(dotIndex);
    };

    return (
        <Styled.DotWrapper as="button" onClick={handleClick} tabIndex={-1}>
            <Styled.AbsoluteBox borderRadius="50%" border="4px solid transparent">
                <Styled.Circle opacity={0.35} backgroundColor="neutral.100" />
            </Styled.AbsoluteBox>
        </Styled.DotWrapper>
    );
};

const DotNavigation: React.FC<DotNavigationProps> = ({
    dotPosition,
    dotSize,
    length,
    currentSelection,
    onClick,
    transitionDelay = 0,
    ...props
}) => {
    const MemoizedDots = useMemo(
        () =>
            new Array(length).fill(true).map((_, index) => (
                <Styled.GroupItem key={`$indicator-${index}`}>
                    {currentSelection === index && (
                        <DotSelected onClick={onClick} dotIndex={index} transitionDelay={transitionDelay} />
                    )}
                    {currentSelection !== index && <DotUnSelected onClick={onClick} dotIndex={index} />}
                </Styled.GroupItem>
            )),
        [currentSelection, length, onClick, transitionDelay],
    );

    return (
        <Box>
            <Styled.GroupWrapper
                dotSize={dotSize}
                dotPosition={dotPosition}
                gutter="sm"
                justifyContent="center"
                {...props}
            >
                {MemoizedDots}
            </Styled.GroupWrapper>
        </Box>
    );
};

export default DotNavigation;
