import { useState, useEffect } from 'react';
import { createPopper, Options, Instance } from '@popperjs/core';

const defaultPopperOptions: Partial<Options> = {};

export function usePopper(parent, child, popperProps: Partial<Options>) {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        let popperInstance: Instance;
        setReady(false);
        if (child && parent) {
            popperInstance = createPopper(parent, child, { ...defaultPopperOptions, ...popperProps });
            setReady(true);
        }

        return () => {
            if (popperInstance) {
                popperInstance.destroy();
            }
        };
    }, [child, parent, popperProps]);
    return ready;
}

// TODO: [DS-996] Replace `usePopper` with this workaround when all `usePopper` instances use `useRef` instead of `useState`
export function usePopperWithRefs(triggerRef, contentRef, arrow, hasArrow, popperProps: Partial<Options>) {
    const [ready, setReady] = useState(false);
    useEffect(() => {
        let popperInstance: Instance;
        setReady(false);
        if (triggerRef.current && contentRef.current && ((hasArrow && arrow) || !hasArrow)) {
            popperInstance = createPopper(triggerRef.current, contentRef.current, {
                ...defaultPopperOptions,
                ...popperProps,
            });
            setReady(true);
        }

        return () => {
            if (popperInstance) {
                popperInstance.destroy();
                setReady(false);
            }
        };
    }, [triggerRef, contentRef, popperProps, arrow, hasArrow]);
    return ready;
}
