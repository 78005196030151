import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCreator = ({ size, title = 'Creator', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#142739"
            d="M34.5 26.25h-3v-9c0-7.444-6.056-13.5-13.5-13.5S4.5 9.806 4.5 17.25v9h-3v-9C1.5 8.152 8.902.75 18 .75c9.098 0 16.5 7.402 16.5 16.5v9z"
        />
        <path
            fill="#32CBCB"
            d="M15.826 34a.874.874 0 01-.835-.648l-2.422-8.597-.748 1.547a.867.867 0 01-.777.498H8.87c-.48 0-.87-.403-.87-.9s.39-.9.87-.9h1.636l1.499-3.102a.862.862 0 01.856-.494.878.878 0 01.756.644l2.144 7.608 3.134-12.974A.876.876 0 0119.74 16h.007c.402.003.749.29.84.695l2.496 11.073 1.097-2.27a.867.867 0 01.777-.498h2.174c.48 0 .87.403.87.9s-.39.9-.87.9h-1.636l-1.933 4.002a.868.868 0 01-.88.492.88.88 0 01-.745-.69l-2.226-9.873-3.04 12.587a.876.876 0 01-.827.682h-.017z"
        />
        <path fill="#435261" d="M8.25 18.75a7.5 7.5 0 000 15v-15zm19.5 0a7.5 7.5 0 010 15v-15z" />
        <path
            fill="#142739"
            d="M9 17.25a2.25 2.25 0 00-2.25 2.25V33a2.25 2.25 0 004.5 0V19.5A2.25 2.25 0 009 17.25zm18 0a2.25 2.25 0 012.25 2.25V33a2.25 2.25 0 01-4.5 0V19.5A2.25 2.25 0 0127 17.25z"
        />
    </svg>
);

export default withIcon(SvgCreator);
