import React, { FC } from 'react';
import { FullCategoryNodeFragment } from 'types';

import styles from './CategoryHeader.module.scss';

export type CategoryBlock = NonNullable<FullCategoryNodeFragment['blocks']>[0];

export interface CategoryHeaderProps {
  data: FullCategoryNodeFragment | null;
}

export const CategoryHeader: FC<CategoryHeaderProps> = ({ data, children }) => {
  const coverImage =
    data?.categoryImage?.categoryImageHeader?.mediaItemUrl ||
    '/temporary/category-example-cover-1.png';
  const categoryDescription = data?.categoryImage?.categoryDescription;

  return (
    <div className={styles.root}>
      <main className={styles.main}>
        <div className={styles.header}>
          <div
            className={styles.headerEntry}
            style={{ backgroundImage: `url('${coverImage}')` }}
          >
            <h1 className={styles.title}>{data?.name}</h1>
            <p className={styles.description}>{categoryDescription}</p>
          </div>
        </div>
        {children}
      </main>
    </div>
  );
};
