import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { variant } from '../../utils';
import { Avatar as BaseAvatar } from '../Avatar';
import { CompoundAvatarProps } from './types';

// We need to wrap the Avatar in a Styled Component to reference it in AvatarWrapper
export const Avatar = styled(BaseAvatar)``;

const avatarWrapperMask = variant({
    prop: 'size',
    variants: {
        sm: {
            maskImage: 'radial-gradient(ellipse 120px 100px at -20px center, #0000 30px, #000 30.5px)',
        },
        md: {
            maskImage: 'radial-gradient(ellipse 120px 110px at -20px center, #0000 30px, #000 30.5px)',
        },
        lg: {
            maskImage: 'radial-gradient(ellipse 120px 130px at -20px center, #0000 30px, #000 30.5px)',
        },
    },
});

export const AvatarWrapper = styled(Box)<CompoundAvatarProps>`
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;

    &:hover:not(:disabled) {
        text-decoration: none;
    }

    &:not(:first-child) {
        margin-left: -7px;

        ${Avatar} {
            ${avatarWrapperMask};
        }
    }

    &:hover {
        z-index: 100;

        ${Avatar} {
            mask-image: initial;
        }
    }
`;
