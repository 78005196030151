import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVideoChatOutlined = ({ size, title = 'VideoChatOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17 7.056C17 6.473 16.518 6 15.948 6H4.052A1.06 1.06 0 003 7.056V15c0 .583.477 1.056 1.056 1.056h3.61v3l3.734-3h4.54c.585 0 1.06-.484 1.06-1.056V7.056z"
            clipRule="evenodd"
        />
        <path stroke="currentColor" strokeLinejoin="round" strokeWidth={1.5} d="M17 9l4-1v6l-4-1V9z" />
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M5.75 9.23H9.5" />
    </svg>
);

export default withIcon(SvgVideoChatOutlined);
