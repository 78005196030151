import styled, { css } from '../../utils/styled';
import { palette, themeMode, radius } from '../../utils';
import { Box } from '../../primitives/Box';
import { StateProps } from './types';

export const Wrapper = styled(Box)`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed;
    border-radius: ${radius('base')};
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;

    ${themeMode({
        light: css`
            border-color: ${palette('neutral.400')};
        `,
        dark: css`
            border-color: ${palette('neutral.500')};
        `,
    })};

    ${({ isLoading, isInvalid }: StateProps) =>
        !isInvalid &&
        !isLoading &&
        css`
            &:hover {
                border-color: ${palette('primary.500')};
            }
        `};

    ${({ isInvalid }: StateProps) =>
        isInvalid &&
        css`
            border-color: ${palette('danger.500')};
        `};
`;
