import React from 'react';
import { IframeComponent, IframeProps } from './components/IframeComponent';
import {
  instagramParser,
  spotifyParser,
  tiktokParser,
  youtubeShortParser,
} from './parserUtils';

interface SocialEmbeddedProps {
  url: string;
  width?: string;
  height?: string;
}

const getSocialDefaultProps = (url: string): IframeProps | undefined => {
  // Tiktok
  const tiktokData = tiktokParser(url);
  if (tiktokData?.id) {
    return {
      src: `https://www.tiktok.com/embed/v2/${tiktokData.id}`,
      width: 325,
      height: 706,
      style: { borderRadius: '8px' },
    };
  }

  // Instagram
  const instagramData = instagramParser(url);
  if (instagramData?.baseUrl) {
    return {
      src: `${instagramData.baseUrl}/embed`,
      width: 326,
      height: instagramData.isReel ? 615 : 534,
      style: { borderRadius: '8px' },
    };
  }

  // Youtube short
  const youtubeData = youtubeShortParser(url);
  if (youtubeData?.id) {
    return {
      src: `https://youtube.com/embed/${youtubeData.id}`,
      width: 325,
      height: 582,
      style: { borderRadius: '12px' },
      allow: 'autoplay; clipboard-write; encrypted-media; picture-in-picture',
      allowFullScreen: true,
    };
  }

  // Spotify
  const spotifyData = spotifyParser(url);
  if (spotifyData?.slug) {
    return {
      src: `https://open.spotify.com/embed/${spotifyData.slug}`,
      width: '100%',
      height: spotifyData.isTrack ? 152 : 380,
      style: { borderRadius: '12px' },
      allow:
        'autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture',
      allowFullScreen: true,
    };
  }

  return undefined;
};

export const SocialEmbedded: React.FC<SocialEmbeddedProps> = ({
  url,
  width,
  height,
}) => {
  const defaultProps = getSocialDefaultProps(url);
  if (!defaultProps) {
    return null;
  }

  return (
    <IframeComponent
      src={defaultProps.src}
      width={width ?? defaultProps.width}
      height={height ?? defaultProps.height}
      style={defaultProps.style}
      allow={defaultProps.allow}
      allowFullScreen={defaultProps.allowFullScreen}
    />
  );
};
