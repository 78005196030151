import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSamplesDark = ({ size, title = 'SamplesDark', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#142739"
            d="M30.75 35.25H5.25a1.5 1.5 0 01-1.5-1.5V2.25a1.5 1.5 0 011.5-1.5h18l9 9v24a1.5 1.5 0 01-1.5 1.5z"
        />
        <path fill="#435261" d="M23.25.75v7.5a1.5 1.5 0 001.5 1.5h7.5l-9-9z" />
        <path
            fill="#F9FAFB"
            d="M23.735 14.427a.754.754 0 00-.609-.167l-9 1.5a.75.75 0 00-.626.74v8.388a2.225 2.225 0 00-.75-.138A2.252 2.252 0 0010.5 27c0 1.24 1.009 2.25 2.25 2.25S15 28.24 15 27v-6.115l7.5-1.25v3.753a2.225 2.225 0 00-.75-.138 2.252 2.252 0 00-2.25 2.25c0 1.24 1.009 2.25 2.25 2.25S24 26.74 24 25.5V15c0-.22-.097-.43-.265-.573zM15 19.365v-2.23l7.5-1.25v2.23l-7.5 1.25z"
        />
    </svg>
);

export default withIcon(SvgSamplesDark);
