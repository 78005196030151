import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Box } from '../Box';
import { PinProps } from './types';

const positionStyles = (
    placement: NonNullable<PinProps['placement']>,
    offset: NonNullable<PinProps['pinOffset']>,
): CSSObject => {
    const [offsetX, offsetY] = offset;
    return {
        'top-left': {
            top: offsetY,
            left: offsetX,
            transform: 'scale(1) translate(-50%, -50%)',
            transformOrigin: '0% 0%',
        },
        'top-right': {
            top: offsetY,
            right: offsetX,
            transform: 'scale(1) translate(50%, -50%)',
            transformOrigin: '100% 0%',
        },
        'bottom-left': {
            bottom: offsetY,
            left: offsetX,
            transform: 'scale(1) translate(-50%, 50%)',
            transformOrigin: '0% 100%',
        },
        'bottom-right': {
            bottom: offsetY,
            right: offsetX,
            transform: 'scale(1) translate(50%, 50%)',
            transformOrigin: '100% 100%',
        },
    }[placement];
};

const StyledPinComponent = styled(Box)<Required<Pick<PinProps, 'placement' | 'pinOffset'>>>(
    ({ placement, pinOffset }) => ({
        position: 'absolute',
        ...positionStyles(placement, pinOffset),
    }),
);

const Pin: React.FC<PinProps> = ({ children, component, placement = 'top-right', pinOffset = [0, 0], ...props }) => (
    <Box position="relative" display="inline-flex" {...props}>
        {children}
        <StyledPinComponent placement={placement} pinOffset={pinOffset}>
            {component}
        </StyledPinComponent>
    </Box>
);

export { Pin };
