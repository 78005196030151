/**
 * Locales: ['en', 'fr', 'de', 'ja', 'es', 'pt-br', 'it']
 * You can find this info in next.config.js file
 * @param locale
 */
export const getLocalePath = (locale?: string | null) => {
  switch (locale) {
    case 'en_US':
      return '';
    case 'fr_FR':
      return 'fr/';
    case 'de_DE':
      return 'de/';
    case 'ja':
      return 'ja/';
    case 'es_ES':
      return 'es/';
    case 'pt_BR':
      return 'pt-br/';
    case 'it_IT':
      return 'it/';
    default:
      return '';
  }
};

export type Locale = 'en' | 'fr' | 'de' | 'es' | 'ja' | 'pt-br' | 'it';
export type WPLocale =
  | 'en_US'
  | 'fr_FR'
  | 'de_DE'
  | 'es_ES'
  | 'ja'
  | 'pt_BR'
  | 'it_IT';

export const getLocale = (locale?: string | WPLocale | null): Locale => {
  switch (locale) {
    case 'en_US':
      return 'en';
    case 'fr_FR':
      return 'fr';
    case 'de_DE':
      return 'de';
    case 'ja':
      return 'ja';
    case 'es_ES':
      return 'es';
    case 'pt_BR':
      return 'pt-br';
    case 'it_IT':
      return 'it';
    default:
      return 'en';
  }
};

export const getWPLocale = (locale?: string): WPLocale => {
  switch (locale) {
    case 'fr':
      return 'fr_FR';
    case 'de':
      return 'de_DE';
    case 'ja':
      return 'ja';
    case 'es':
      return 'es_ES';
    case 'pt-br':
      return 'pt_BR';
    case 'it':
      return 'it_IT';
    case 'en':
    default:
      return 'en_US';
  }
};

export const getLocaleHreflang = (locale?: string): string => {
  if (!locale) {
    return 'en';
  }

  switch (locale) {
    case 'en_US':
      return 'en';
    case 'fr_FR':
      return 'fr';
    case 'de_DE':
      return 'de';
    case 'ja':
      return 'ja';
    case 'es_ES':
      return 'es';
    case 'pt_BR':
      return 'pt';
    case 'it_IT':
      return 'it';
    default:
      return locale;
  }
};
