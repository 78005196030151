// Container
export type ContainerSize = 'xs' | 'sm' | 'ms' | 'md' | 'ml' | 'lg' | 'xl' | 'xxl';

// Spacing
type SpacingSize = 'none' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'jb' | 'mg';

export enum Breakpoints {
    xs = 320,
    sm = 480,
    ms = 600,
    md = 768,
    ml = 960,
    lg = 1024,
    xl = 1280,
    xxl = 1330,
    jb = 1440,
}

export type Spacing = SpacingSize | number | 'auto';

/** Defines some additional options for spacing function */
export interface SpacingOptions {
    /**
     * If set true - spacing will return value as number.
     * Exception is 'auto', for this param spacing will return 'auto' even if unitless is true.
     *
     * By default spacing will return value with px suffix.
     */
    unitless?: boolean;
}

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;
