import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgNetworkPro = ({ size, title = 'NetworkPro', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#142739"
            fillRule="evenodd"
            d="M18 17.5l-4.409-1.58c0 .58-.535-.532-.535-.913v-1.3a8.291 8.291 0 001.362-2.843c.367-.298.582-.743.582-1.221V8.07c0-.378-.14-.745-.389-1.03V4.95c.022-.217.108-1.503-.813-2.564C12.999 1.467 11.703 1 9.945 1S6.89 1.466 6.09 2.387c-.92 1.06-.835 2.347-.813 2.564v2.09a1.575 1.575 0 00-.389 1.03v1.572c0 .478.215.923.582 1.221a8.289 8.289 0 001.362 2.844v1.3c0 .38-.205.729-.535.911l-3.47 1.912A3.548 3.548 0 001 20.942V22c0 .5.5 1 1 1h18c.553 0 1.006-.451.963-1.002C20.688 18.42 18.92 17.96 18 17.5z"
            clipRule="evenodd"
        />
        <path fill="#3C6ED7" fillRule="evenodd" d="M19 14.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0z" clipRule="evenodd" />
        <path
            fill="#435261"
            fillRule="evenodd"
            d="M14.5 10.5a4 4 0 100 8 4 4 0 000-8zm-5 4a5 5 0 1110 0 5 5 0 01-10 0z"
            clipRule="evenodd"
        />
        <path
            fill="#435261"
            fillRule="evenodd"
            d="M17.529 17.529a.667.667 0 000 .942l4 4a.667.667 0 10.942-.942l-4-4a.667.667 0 00-.942 0z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgNetworkPro);
