import React, { isValidElement } from 'react';
import { useBreakpoint } from '../../hooks';
import { Flex } from '../Flex';
import { findChildByType } from '../../utils';
import { NavBarProps } from './types';
import { NavBarBrand } from './NavBarBrand';
import { NavBarMenu } from './NavBarMenu';
import { NavBarDrawer } from './NavBarDrawer';
import { NavBarSubMenu } from './NavBarSubMenu';
import * as Styled from './styled';
import { NavBarProvider } from './NavBarContext';

const NavBar = ({ children, justify, menuLabel, collapseBelow = 'lg', ...props }: NavBarProps) => {
    const isMobile = useBreakpoint(collapseBelow);

    const brand = findChildByType(children, NavBarBrand);
    const menuNode = findChildByType(children, NavBarMenu);
    const menu = isValidElement(menuNode) && React.cloneElement(menuNode, { justify });
    const submenu = findChildByType(children, NavBarSubMenu);

    return (
        <NavBarProvider value={{ isMobile }}>
            {isMobile ? (
                <Styled.NavBarDrawer {...props}>
                    <Flex alignItems="center">
                        <NavBarDrawer brand={brand} menu={menu} menuLabel={menuLabel} />
                        {brand}
                    </Flex>
                    {submenu}
                </Styled.NavBarDrawer>
            ) : (
                <Styled.NavBar {...props}>
                    {brand}
                    <Styled.Divider />
                    {menu}
                    {submenu}
                </Styled.NavBar>
            )}
        </NavBarProvider>
    );
};

NavBar.displayName = 'NavBar';

export default Object.assign(NavBar, {
    Brand: NavBarBrand,
    Menu: NavBarMenu,
    SubMenu: NavBarSubMenu,
});
