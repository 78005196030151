import css, { SystemStyleObject } from '@styled-system/css';
import React from 'react';
import {
    BordersProps,
    ColorProps,
    FlexboxProps,
    GridProps,
    LayoutProps,
    PositionProps,
    ShadowProps,
    SpaceProps,
    TypographyProps,
    OpacityProps,
    SizeProps,
} from 'styled-system';
import { systemPropsFns } from '../../system/styled-system';
import { DefaultTheme } from '../../themes';
import styled from '../../utils/styled';

export type SXProp = {
    sx?: SystemStyleObject;
};

export type SystemProps = BordersProps &
    ColorProps &
    ShadowProps &
    SpaceProps &
    TypographyProps &
    OpacityProps &
    Omit<LayoutProps, keyof SizeProps> &
    SXProp;

export type CSSProps = FlexboxProps & GridProps & PositionProps;

export type BoxProps = SystemProps &
    CSSProps & {
        as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
        nbOfLines?: number;
        theme?: DefaultTheme;
    };

const nbOfLines = ({ nbOfLines }: BoxProps): Record<string, any> => {
    if (typeof nbOfLines !== 'number') {
        return {};
    }
    return {
        display: '-webkit-box',
        WebkitLineClamp: nbOfLines || 'unset',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        wordBreak: 'break-word',
    };
};

const sx = (props: BoxProps) => css(props.sx)(props.theme);

const COMMON_PROPS = ['kind', 'variant', 'shape', 'size'];

export const Box = styled('div').withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !COMMON_PROPS.includes(prop) && defaultValidatorFn(prop),
})<BoxProps>`
    && {
        ${nbOfLines};
        ${sx};
        ${systemPropsFns};
    }
`;

Box.displayName = 'Box';
