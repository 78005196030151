import React, { useCallback, useEffect, useReducer, useState } from 'react';

export enum LazyloadActions {
    LOAD_MORE = 'LOAD_MORE',
}

export type LazyloadActionType = { type: LazyloadActions };

// REDUCER
const lazyloadReducer = (state: { loaded: number }, action: LazyloadActionType): { loaded: number } => {
    switch (action.type) {
        case LazyloadActions.LOAD_MORE:
            return { ...state, loaded: state.loaded + 1 };
        default:
            return state;
    }
};

export const useLazyload = (
    observerRef: React.MutableRefObject<HTMLDivElement | null>,
    config?: { firstload?: number; maxObservation?: number },
): {
    init: () => boolean;
    more: () => void;
    loaded: () => number;
} => {
    // STATE
    const [initialized, setInitialized] = useState(false);
    const [observer, setObserver] = useState<IntersectionObserver | undefined>();
    const [maxObservation, setMaxObservation] = useState(0);

    // EFFECT
    useEffect(() => {
        if (config?.maxObservation) {
            setMaxObservation(config.maxObservation);
        }
    }, [config, setMaxObservation]);

    // REDUCER
    const firstload = config?.firstload ? config.firstload : 0;
    const [lazyloadState, lazyloadDispatch] = useReducer(lazyloadReducer, {
        loaded: firstload,
    });

    // Trigger LazyloadActions.LOAD_MORE when target observed
    const scrollObserver = useCallback(
        (node) => {
            if (node === null) {
                return;
            }

            let observationCount = maxObservation;

            return new IntersectionObserver((entries, self) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0) {
                        // Will always observe -1 maxObservation
                        if (observationCount === 0) {
                            self.unobserve(entry.target);
                        } else {
                            lazyloadDispatch({ type: LazyloadActions.LOAD_MORE });
                            observationCount--;
                        }
                    }
                });

                return self;
            });
        },
        [lazyloadDispatch, maxObservation],
    );

    // Initiate the scrollObserver
    useEffect(() => {
        if (initialized && !observer && observerRef?.current) {
            setObserver(scrollObserver(observerRef.current));
        }
    }, [initialized, observerRef, observer, scrollObserver]);

    // Start to observe
    useEffect(() => {
        if (observer && observerRef?.current) {
            observer?.observe(observerRef.current);
        }
        return (): void => {
            observer?.disconnect();
        };
    }, [observerRef, observer]);

    const lazyloadInit = (): boolean => {
        if (!('IntersectionObserver' in window)) {
            return false;
        }
        setInitialized(true);
        return true;
    };

    return {
        init: (): boolean => {
            return lazyloadInit();
        },
        more: (): void => {
            lazyloadDispatch({ type: LazyloadActions.LOAD_MORE });
        },
        loaded: (): number => {
            return lazyloadState.loaded;
        },
    };
};
