import styled, { css } from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { themeMode, variant, spacing, radius, color } from '../../utils';
import { Heading } from '../Heading';
import { AlertProps, AlertTitleProps, AlertContentProps, AlertActionProps } from './types';
import { AlertAction } from './AlertAction';

export const Wrapper = styled(Box)<AlertTitleProps>`
    display: flex;
    flex-direction: column;
`;

const iconVariantStyles = variant({
    prop: 'palette',
    variants: {
        default: {
            color: 'neutral.500',
        },
        success: {
            color: 'primary.500',
        },
        danger: {
            color: 'danger.500',
        },
        warning: {
            color: 'warning.500',
        },
        info: {
            color: 'secondary.500',
        },
    },
});

export const Icon = styled(Box)<AlertTitleProps>`
    display: inline-flex;
    grid-area: icon;
    grid-row: 1 / -1;
    ${iconVariantStyles};
`;

export const Title = styled(Heading)<AlertTitleProps>`
    margin-bottom: 0;
    grid-column: 2;
`;

Title.displayName = 'Alert.Title';

export const Content = styled(Box)<AlertContentProps>`
    grid-column: 2;
    color: ${color('text.base')};
`;

Content.displayName = 'Alert.Content';

export const ActionGroup = styled(Box)<AlertActionProps>`
    grid-column: 2;
`;

export const Action = styled(AlertAction)<AlertActionProps>`
    grid-column: 2;
`;

Action.displayName = 'Alert.Action';

const messageVariants = variant({
    prop: 'palette',
    compoundProp: 'variant',
    variants: {
        default: {
            solid: {
                backgroundColor: themeMode({ light: 'neutral.100', dark: 'neutral.800' }),
            },
            outline: {
                borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
            },
            subtle: {
                backgroundColor: themeMode({ light: 'neutral.50', dark: color('neutral.800', 0.16) }),
            },
            ghost: {},
        },
        success: {
            solid: {
                backgroundColor: themeMode({ light: 'neutral.100', dark: 'neutral.800' }),
            },
            outline: {
                borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
            },
            subtle: {
                backgroundColor: color('success.500', 0.08),
            },
            ghost: {},
        },
        warning: {
            solid: {
                backgroundColor: themeMode({ light: 'neutral.100', dark: 'neutral.800' }),
            },
            outline: {
                borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
            },
            subtle: {
                backgroundColor: color('warning.500', 0.08),
            },
            ghost: {},
        },
        danger: {
            solid: {
                backgroundColor: themeMode({ light: 'neutral.100', dark: 'neutral.800' }),
            },
            outline: {
                borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
            },
            subtle: {
                backgroundColor: color('danger.500', 0.08),
            },
            ghost: {},
        },
        info: {
            solid: {
                backgroundColor: themeMode({ light: 'neutral.100', dark: 'neutral.800' }),
            },
            outline: {
                borderColor: themeMode({ light: 'neutral.200', dark: 'neutral.800' }),
            },
            subtle: {
                backgroundColor: color('secondary.500', 0.08),
            },
            ghost: {},
        },
    },
});

export const Alert = styled(Box)<AlertProps>`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-auto-rows: min-content;
    gap: ${spacing('xs')};
    grid-template-areas: 'icon .';
    padding: ${spacing('lg')};
    border-radius: ${radius('base')};
    border: 1px solid transparent;

    ${({ isInline }) =>
        isInline &&
        css`
            display: flex;
            ${ActionGroup} {
                margin-left: auto;
            }
        `}

    ${messageVariants};
`;
