import React, { FC, Fragment } from 'react';
import { renderBlocks } from 'helpers/renderBlocks';
import { FullCategoryNodeFragment } from 'types';

import { CategoryHeader } from './components/CategoryHeader/CategoryHeader';

export type CategoryBlock = NonNullable<FullCategoryNodeFragment['blocks']>[0];

export interface CategoryProps {
  data: FullCategoryNodeFragment | null;
}

export const Category: FC<CategoryProps> = ({ data }) => {
  return (
    <>
      <CategoryHeader data={data}>
        {data?.blocks?.map((item) => {
          if (item) {
            return <Fragment key={item.id}>{renderBlocks(item)}</Fragment>;
          }

          return null;
        })}
      </CategoryHeader>
    </>
  );
};
