import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMenuInvertedOutlined = ({ size, title = 'MenuInvertedOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={2} d="M17 6H5m14 6H5m12 6H5" />
    </svg>
);

export default withIcon(SvgMenuInvertedOutlined);
