import styled from '../../utils/styled';
import { Spacing } from '../../themes/types';
import { NavigationElementPosition } from './types';

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: block;
`;

type ArrowWrapperDirection = 'left' | 'right';

const ArrowWrapper = styled.div<{ direction: ArrowWrapperDirection, arrowPosition: NavigationElementPosition }>`
    position: absolute;
    top: 0;
    bottom: 0;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s ease, visibility 0s linear 0.25s;
    pointer-events: none;
    &.showing {
        display: flex;
        transition: opacity 0.25s ease, visibility 0s linear;
        pointer-events: auto;
        visibility: visible;
        opacity: 1;
    }
    ${({ direction, arrowPosition }) => {
        const oppositeDirection = direction === 'left' ? 'right' : 'left';
        return `
        ${direction}: ${arrowPosition === 'inside' ? '30px' : '0px'};
        width: 0;
        & > button {
            ${oppositeDirection}: 0;
            transform: translateX(-50%);        
        }
    `;
    }}
`;

const CarouselSlideInner = styled.div<{ offset?: Spacing }>`
    ${({ theme, offset }) => offset && `transform: translateX(${theme.spacing(offset)});`}
    height: 100%;
`;

// TODO: [DS-990] use proper selection styles
const CarouselSlide = styled.div<{ slidesPerPage: number; gutter: Spacing; offset?: Spacing }>`
    flex: 1 0;
    ${({ slidesPerPage, gutter, offset, theme }): string => {
        const offsetString = typeof offset === 'undefined' ? '' : ` + ${theme.spacing(offset)}`;
        const size = `calc((100% / ${slidesPerPage}) - ((${theme.spacing(gutter)} * ${
            slidesPerPage - 1
        })${offsetString}) / ${slidesPerPage})`;
        return `
            min-width: ${size}; 
            max-width: ${size}; 
            margin-right: ${theme.spacing(gutter)};`;
    }}
    &:last-child {
        margin-right: 0;
    }
`;

const ScrollContainer = styled.div<{
    gutter?: Spacing;
    itemsOffset?: Spacing;
    offset?: Spacing;
    isMobile?: boolean;
}>`
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    display: flex;
    white-space: nowrap;
    scrollbar-width: none;
    font-size: 0;
    line-height: 1;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
    }
    ${({ offset, theme }) =>
        typeof offset !== 'undefined'
            ? `
        padding-right: ${theme.spacing(offset)};
        &:after {
            content: ' ';
            min-width: calc(2 * ${theme.spacing(offset)});
        }`
            : ''}
`;

export const Styled = {
    Wrapper,
    ArrowWrapper,
    CarouselSlideInner,
    CarouselSlide,
    ScrollContainer,
};
