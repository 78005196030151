import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgUserOutlined = ({ size, title = 'UserOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M19.25 20.25v-1.833c0-2.439-4.83-3.667-7.25-3.667s-7.25 1.228-7.25 3.667v1.833"
        />
        <path stroke="currentColor" strokeWidth={1.5} d="M12 11.25a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" />
    </svg>
);

export default withIcon(SvgUserOutlined);
