import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgReleasesFilled = ({ size, title = 'ReleasesFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 3c4.777 0 8.705 3.737 8.984 8.473l.012.264L21 12c0 4.777-3.737 8.705-8.473 8.984l-.264.012L12 21c-4.967 0-9-4.036-9-9 0-4.777 3.737-8.705 8.473-8.984l.264-.012L12 3zm0 6.75l-.132.004a2.251 2.251 0 00-.173 4.477l.15.014.133.005.154-.004a2.253 2.253 0 002.098-1.934l.015-.157.005-.133-.004-.154a2.253 2.253 0 00-2.092-2.113L12 9.75z"
        />
    </svg>
);

export default withIcon(SvgReleasesFilled);
