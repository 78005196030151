import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgKebabFilled = ({ size, title = 'KebabFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                d="M12 14a2 2 0 110-4 2 2 0 010 4zm0-7a2 2 0 110-4 2 2 0 010 4zm0 14a2 2 0 110-4 2 2 0 010 4z"
            />
        </g>
    </svg>
);

export default withIcon(SvgKebabFilled);
