import { isFunction, __DEV__, __TEST__ } from './assertions';

/**
 * Call all functions passed as arguments and return if the event as been preventend
 *
 * @param fns list of functions to run
 */

export const callAllHandlers =
    <T extends (event: any) => void>(...fns: (T | undefined)[]) =>
    (event: React.MouseEvent<Element>): void => {
        fns.some((fn) => {
            fn?.(event);
            return event && event.defaultPrevented;
        });
    };

export const runIfFn = (value: any, ...args: any[]) => (isFunction(value) ? value(...args) : value);

/**
 * Helper to warn user when in development mode
 * */

type MessageOptions = {
    condition: boolean;
    message: string;
};

const warnings = new Set();

export const warn = (options: MessageOptions): void => {
    const { condition, message } = options;
    if (!condition || !__DEV__ || __TEST__) {
        return;
    }
    // Generate a key based on the message content
    const key = message.toLowerCase().replace(/([^A-Za-z0-9[\]{}_:-])\s?/g, '');

    if (warnings.has(key)) {
        return;
    }

    warnings.add(key);

    // eslint-disable-next-line no-console
    console.warn('%c Maestro', 'font-weight: bold; font-size: 1.05em;', '\n', message);
};

export const error = (options: MessageOptions): void => {
    const { condition, message } = options;
    if (!condition || !__DEV__) {
        return;
    }
    // Generate a key based on the message content
    const key = message.toLowerCase().replace(/([^A-Za-z0-9[\]{}_:-])\s?/g, '');

    if (warnings.has(key)) {
        return;
    }

    warnings.add(key);

    // eslint-disable-next-line no-console
    console.error('%c Maestro', 'font-weight: bold; font-size: 1.05em;', '\n', message);
};

export const noop = (): void => {
    return;
};
