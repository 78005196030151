import { useMemo } from 'react';
import { GetStaticPropsContext, NextPage } from 'next';
import { NextRouter } from 'next/router';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { apolloErrorHandler, EventIds } from './';
import { logger } from 'utils';
import { YouTubeVideosContext } from 'components/YouTubeVideosContext';
import { YouTubeVideosService } from 'components/YouTubeVideosContext/YouTubeVideosService';
import { VideoData } from 'types';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let graphqlError = false;

const concatPagination = (
  existing: any = {},
  incoming: any = {},
  { args }: any
) => {
  const existingEntities = existing?.nodes || [];
  const incomingEntities = incoming?.nodes || [];
  const result = [...existingEntities, ...incomingEntities];

  if (args && !args.after) {
    return incoming;
  }

  return {
    ...incoming,
    nodes: result,
  };
};

const commonTypePolicy = {
  keyArgs: ['after', 'before', 'first', 'last', 'where'],
  merge: concatPagination,
};

const httpLink = createHttpLink({
  uri: `${process.env.NEXT_PUBLIC_API_HOST}/graphql`,
});

const authLink = setContext((_, { headers, previewToken }) => {
  return {
    headers: {
      ...headers,
      ...(previewToken ? { Authorization: `Bearer ${previewToken}` } : {}),
    },
  };
});

export function createApolloClient() {
  const log = logger.getInstance('ApolloClient');

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // Add `timingLink` after `errorLink` if you need to see performance of each operation
    link: ApolloLink.from([
      onError(apolloErrorHandler(log)),
      authLink.concat(httpLink),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        // Post_Postadditionalfields: {
        //   keyFields: ['accentColor'],
        // },
        Query: {
          fields: {
            posts: commonTypePolicy,
            categories: commonTypePolicy,
            menuItems: commonTypePolicy,
          },
        },
      },
    }),
  });
}

// Reuse the same apolloCLient to improve caching of common queries
const apolloClient: ApolloClient<NormalizedCacheObject> = createApolloClient();

export function initializeApollo(initialState: any = null) {
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    apolloClient.cache.restore(data);
  }

  return apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: any
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);

  return store;
}

interface DataFromTreeToCache<T> {
  onDemandRevalidation?: boolean;
  componentProps: T;
}

export const setDataFromTreeToCache =
  <T extends Record<string, unknown>>(
    Page: NextPage<T>,
    options: DataFromTreeToCache<T>
  ) =>
  async (
    props: GetStaticPropsContext
  ): Promise<{
    props: T;
    revalidate?: number | boolean;
  }> => {
    const { getDataFromTree } = await import('@apollo/client/react/ssr');
    const { RouterContext } = await import(
      'next/dist/shared/lib/router-context.shared-runtime'
    );

    const router = {
      query: props.params,
      locales: props.locales,
      locale: props.locale,
      defaultLocale: props.defaultLocale,
    };

    // Do not reuse the apollo client as we now only fetch data for each page.
    // There is no shared data anymore used by `useQuery`
    const apolloClient = createApolloClient();

    // Create a new instance of YouTubeVideoVideoList for each page
    // it will received the YouTube video ids from the page trigger from the `YouTubePlayer` components
    const youTubeVideosService = new YouTubeVideosService();

    const PrerenderComponent = () => (
      // Add YoutubeSSRProvider here if you need to GET Youtube videos ids
      // with an instance of a YouTubeVideoVideoList
      <ApolloProvider client={apolloClient}>
        <RouterContext.Provider value={router as NextRouter}>
          <YouTubeVideosContext.Provider value={{ youTubeVideosService }}>
            <Page {...options.componentProps} />
          </YouTubeVideosContext.Provider>
        </RouterContext.Provider>
      </ApolloProvider>
    );

    graphqlError = false;

    await getDataFromTree(<PrerenderComponent />);

    if (graphqlError) {
      // Do not throw the error here as it could be an error on one field that is not that important.
      logger.error('[GraphQL error]', EventIds.ApolloError, graphqlError);
    }

    // Skip revalidate if `onDemandRevalidation` is true
    const revalidateOptions = !options.onDemandRevalidation
      ? { revalidate: Number(process.env.REVALIDATE_DELAY) }
      : {};

    if (graphqlError) {
      // Error pages are cached for 1 minute
      revalidateOptions.revalidate = 60;
    }

    const pageProps = addApolloState(apolloClient, {
      props: {},
      ...revalidateOptions,
    });

    // Get YouTubeVideoVideoList instance that was feed from context
    if (youTubeVideosService.hasVideos) {
      const videoData: VideoData = {
        videoIds: youTubeVideosService.getVideoIds(),
        videoSeoMetaTags: [], // Will be feed later by static props
      };
      pageProps.props.videoData = videoData;
    }

    return pageProps;
  };
