import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useIsDestroyed } from '../../hooks';
import { RichTimestampProps } from './types';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

function importDayJSLocale(language: string) {
    const importFunctions: Record<string, () => Promise<{ default: any }>> = {
        en: () => import('dayjs/locale/en.js'),
        fr: () => import('dayjs/locale/fr.js'),
        de: () => import('dayjs/locale/de.js'),
        it: () => import('dayjs/locale/it.js'),
        ja: () => import('dayjs/locale/ja.js'),
        pt: () => import('dayjs/locale/pt.js'),
        es: () => import('dayjs/locale/es.js'),
    };

    const importFunction = importFunctions[language];

    return importFunction ? importFunction() : Promise.reject('Unsupported locale');
}

const useUpdatingTimestamp = ({ createdAt, locale }: { createdAt: string; locale?: string }) => {
    const [timestamp, setTimestamp] = useState<string>(dayjs(createdAt).fromNow());
    const isDestroyed = useIsDestroyed();

    useEffect(() => {
        if (locale) {
            importDayJSLocale(locale)
                .then(() => {
                    if (!isDestroyed.current) {
                        dayjs.locale(locale);
                        // re-set timestamp because changing locale doesn't affect existing instances
                        setTimestamp(dayjs(createdAt).fromNow());
                    }
                })
                .catch(() => {
                    if (!isDestroyed.current) {
                        // set to default. DaysJS don't have "en-us" locale
                        dayjs.locale('en');
                        setTimestamp(dayjs(createdAt).fromNow());
                    }
                });
        }
    }, [createdAt, isDestroyed, locale]);

    useEffect(() => {
        const createdAtDayjs = dayjs(createdAt);
        const diffInSeconds = Math.abs(createdAtDayjs.diff(dayjs(), 'second'));
        const sInHour = 3600;

        let intervalId: ReturnType<typeof setInterval> | undefined = undefined;
        // the timer will update each minute if diff is < 1 hour
        // if diff is bigger than 1 hour there's no need to run interval
        if (diffInSeconds < sInHour) {
            intervalId = setInterval(() => {
                setTimestamp(createdAtDayjs.fromNow());
            }, 60000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [createdAt, timestamp]);

    return { timestamp };
};

export const RichTimestamp: React.FC<RichTimestampProps> = ({
    createdAt,
    checkSamePeriod,
    locale = 'en',
    format = 'll',
}) => {
    const { timestamp } = useUpdatingTimestamp({ createdAt, locale });
    const createdAtDayJs = dayjs(createdAt);
    const fullLocalizedDate = createdAtDayJs.format(format);

    // if user has unsynced time on his device
    // there might be a case when he has createdAt
    // date is in future compare to current date
    if (createdAtDayJs.isAfter(dayjs())) {
        return <>{fullLocalizedDate}</>;
    }

    // if message was sent this day/month etc.
    if (!checkSamePeriod || createdAtDayJs.diff(dayjs(), checkSamePeriod) >= 0) {
        return <>{timestamp}</>;
    }

    return <>{fullLocalizedDate}</>;
};
