import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgAddSongOutlined = ({ size, title = 'AddSongOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5} transform="translate(2.5 2.5)">
            <path
                strokeLinecap="round"
                d="M12.078 18.667H.55a.551.551 0 01-.55-.549V.548C0 .247.25 0 .549 0h17.57c.302 0 .548.25.548.549v12.078M16.47 18.67v-4.4m-2.2 2.2h4.4"
            />
            <circle cx={12.67} cy={12.67} r={1.33} />
            <path strokeLinecap="square" d="M14 12V6.67" />
            <circle cx={4.67} cy={14} r={1.33} />
            <path strokeLinecap="square" d="M6 13.33V8" />
            <path strokeLinejoin="round" d="M6 4.857l8-1.524v3.81L6 8.667z" />
        </g>
    </svg>
);

export default withIcon(SvgAddSongOutlined);
