import React, { useContext, MouseEvent } from 'react';
import { Popover, PopoverContext } from '../Popover';
import { List } from '../List';
import { forwardRef } from '../../system/forwardRef';
import { DropdownMenuItemProps, DropdownMenuTriggerProps, DropdownMenuListProps, DropdownMenuProps } from './types';

const DropdownMenuComponent: React.FC<DropdownMenuProps> = ({ children, ...props }) => (
    <Popover {...props}>{children}</Popover>
);

DropdownMenuComponent.displayName = 'DropdownMenu';

const DropdownMenuTrigger: React.FC<DropdownMenuTriggerProps> = ({ children }) => {
    return <Popover.Trigger>{children}</Popover.Trigger>;
};

const DropdownMenuList = forwardRef<DropdownMenuListProps, 'div'>(({ children, ...rest }, ref) => (
    <Popover.Content px={0} py="xs" {...rest}>
        <List ref={ref}>{children}</List>
    </Popover.Content>
));

const DropdownMenuItem = forwardRef<DropdownMenuItemProps, 'div'>(
    ({ children, onClick, closeOnClick = true, ...props }, ref) => {
        const { close } = useContext(PopoverContext);

        const handleClick = (event: MouseEvent<Element>): void => {
            closeOnClick && close();
            onClick?.(event);
        };

        return (
            <List.Item ref={ref} onClick={handleClick} {...props}>
                {children}
            </List.Item>
        );
    },
);

DropdownMenuItem.displayName = 'DropdownMenu.Item';

export const DropdownMenu = Object.assign(DropdownMenuComponent, {
    Trigger: DropdownMenuTrigger,
    List: DropdownMenuList,
    Item: DropdownMenuItem,
    Icon: List.ItemIcon,
    SecondaryAction: List.ItemSecondaryAction,
    Divider: List.Divider,
});

// TODO: [DS-993] This is for backward compatibility - Remove this in future major version
export const ResponsiveMenu = Object.assign(DropdownMenuComponent, {
    Trigger: DropdownMenuTrigger,
    List: DropdownMenuList,
    Item: DropdownMenuItem,
    Icon: List.ItemIcon,
    SecondaryAction: List.ItemSecondaryAction,
    Divider: List.Divider,
});
