import { ApolloClient } from '@apollo/client';
import { GetStaticPropsContext } from 'next';
import {
  MenuItemsQuery,
  MenuItemsQueryVariables,
  MenuLocationEnum,
} from 'types';
import { GET_MENU_ITEMS } from 'api/menu/queries';

export async function getHeaderMenuItems(
  apolloClient: ApolloClient<unknown>,
  { locale }: GetStaticPropsContext
): Promise<MenuItemsQuery['menuItems']> {
  let { data } = await apolloClient.query<
    MenuItemsQuery,
    MenuItemsQueryVariables
  >({
    query: GET_MENU_ITEMS,
    variables: {
      where: {
        language: locale,
        location: MenuLocationEnum.Primary,
      },
    },
    errorPolicy: 'all',
  });

  return data?.menuItems;
}
