import styled from '../../utils/styled';
import { spacing, palette, fontWeight } from '../../utils';
import { token, themeMode, variant } from '../../utils/theme';
import { Box } from '../../primitives/Box';
import { Text, TextProps } from '../Text';
import { ListProps, ListItemProps, ListItemIconProps, ListItemSecondaryActionProps } from './types';

const sizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            fontSize: 'sm',
            lineHeight: '18px',
            minHeight: '24px',
            py: 'xs',
            px: 'sm',
        },
        md: {
            fontSize: 'md',
            lineHeight: '16px',
            minHeight: '48px',
            py: 'xs',
            px: 'md',
        },
    },
});

export const Item = styled(Box)<ListItemProps>`
    display: flex;
    align-items: center;
    padding: 0 ${spacing('md')};
    font-weight: ${fontWeight('bold')};
    user-select: none;
    cursor: ${({ onClick, disabled }) => onClick && !disabled && 'pointer'};
    text-decoration: none;

    color: ${palette('text.base')};
    background-color: ${({ isSelected }) =>
        isSelected && themeMode({ light: palette('neutral.100'), dark: palette('neutral.700') })};

    ${sizeStyles};

    &:hover {
        color: ${palette('text.base')};
        background-color: ${themeMode({ light: palette('neutral.100'), dark: palette('neutral.700') })};
    }

    &:active {
        color: ${palette('text.base')};
        background-color: ${themeMode({ light: palette('neutral.100'), dark: palette('neutral.600') })};
    }

    &[disabled] {
        pointer-events: none;
        opacity: 0.5;
    }
`;

export const Addon = styled(Box)`
    display: flex;
    align-items: center;
    flex: 0 1 auto;
`;

export const ItemIcon = styled(Box)<ListItemIconProps>`
    display: flex;
    align-items: center;
    margin-right: ${({ size }) => (size === 'md' ? spacing('md') : spacing('sm'))};
`;

export const ItemSecondaryAction = styled(Box)<ListItemSecondaryActionProps>`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

export const List = styled(Box)<ListProps>`
    display: flex;
    flex-direction: column;

    &:focus {
        /* Show outline only with keyboard */
        outline: none;
    }
`;

export const Subheader = styled(Text)<TextProps>`
    padding: ${spacing('xs')} ${spacing('md')};
`;

export const Divider = styled('hr')`
    width: 100%;
    border: 0;
    border-bottom: ${token('borders.subtle')};
    margin-top: ${spacing('xxs')};
    margin-bottom: ${spacing('xxs')};
`;
