import React from 'react';
import { ConditionalWrapper } from '../../primitives';
import { WarningFilled } from '../../assets/icons';
import { Tooltip, TooltipProps } from '../Tooltip';
import { Progress } from '../Progress';
import { Truncate } from '../Truncate';
import { Box } from '../../primitives/Box';
import { warn } from '../../utils';
import { forwardRef } from '../../system';
import { ChipProps } from './types';
import * as Styled from './styled';
import useChip from './useChip';

export const Chip = forwardRef<ChipProps, 'div'>(
    (
        {
            children,
            isChecked = false,
            isDisabled = false,
            isError = false,
            isInvertedCheckedKind,
            isLoading = false,
            kind = 'solid',
            palette = 'default',
            media,
            onDismiss,
            shape = 'rounded',
            size = 'md',
            tooltipProps = null,
            isMultiline = false,
            truncationMode = 'end',
            ...props
        },
        ref,
    ) => {
        warn({
            condition: isInvertedCheckedKind !== undefined,
            message: 'The `isInvertedCheckedKind` prop is deprecated',
        });

        const { MediaComponent, iconSize, handleDismiss, chipKind, showError, showDismiss, showProgress } = useChip({
            isChecked,
            isDisabled,
            isError,
            isLoading,
            kind,
            media,
            onDismiss,
            size,
        });

        return (
            <ConditionalWrapper
                condition={!!tooltipProps}
                wrapper={(children): React.ReactNode => (
                    <Tooltip {...(tooltipProps as TooltipProps)}>{children}</Tooltip>
                )}
            >
                <Styled.Chip
                    ref={ref}
                    kind={chipKind}
                    shape={shape}
                    size={size}
                    isDisabled={isDisabled}
                    isChecked={isChecked}
                    isError={isError}
                    isLoading={isLoading}
                    isMultiline={isMultiline}
                    palette={palette}
                    showDismiss={showDismiss}
                    {...props}
                >
                    {MediaComponent}
                    {showError && (
                        <Box display="flex">
                            <WarningFilled size={iconSize} title="Error" color="danger.500" />
                        </Box>
                    )}
                    {showProgress && <Progress size="md" />}
                    <Styled.Label>
                        <Truncate sx={{ justifyContent: 'center', textAlign: 'center' }} mode={truncationMode}>
                            {children}
                        </Truncate>
                    </Styled.Label>
                    {showDismiss && <Styled.Dismiss onClick={handleDismiss} kind="link" size={iconSize} />}
                </Styled.Chip>
            </ConditionalWrapper>
        );
    },
);

Chip.displayName = 'Chip';
