import React, { FC } from 'react';
import { color, ColorProps, space, SpaceProps, compose } from 'styled-system';
import { DragOutlined } from '../../assets/icons';
import styled from '../../utils/styled';
import { MaestroBaseProps } from '../../utils';
import { IconProps } from '../../components/withIcon';

const StyledDragHandle = styled(DragOutlined)({ cursor: 'move' }, compose(color, space));

export type DragHandleProps = ColorProps & SpaceProps & MaestroBaseProps<IconProps>;

const DragHandle: FC<DragHandleProps> = (props) => <StyledDragHandle {...props} />;

export { DragHandle };
