import { useCallback, useRef, MouseEvent } from 'react';
import { mergeRefs, noop } from '../../utils';
import { useKeyPressWithCallback } from '../../hooks';
import { UseModalProps } from './types';

interface UseModal {
    isOpen: boolean;
    onClose: () => void;
    getModalProps: (props: unknown) => Record<string, unknown>;
    getModalWrapperProps: () => Record<string, unknown>;
    isDismissible: boolean;
}

const useModal = (props: UseModalProps): UseModal => {
    const { isOpen, onClose = noop, isDismissible = true, closeOnOverlayClick } = props;

    const modalRef = useRef<HTMLElement>(null);
    const modalWrapperRef = useRef<HTMLDivElement>(null);

    const mouseDownTarget = useRef<EventTarget | null>(null);

    const handleMouseDown = useCallback((event: MouseEvent) => (mouseDownTarget.current = event.target), []);

    const handleWrapperClick = useCallback(
        (event: MouseEvent<HTMLDivElement>): void => {
            event.stopPropagation();

            if (
                mouseDownTarget.current !== event.target ||
                modalWrapperRef.current !== event.target ||
                !closeOnOverlayClick ||
                !isDismissible
            ) {
                return;
            }

            onClose?.();
        },
        [closeOnOverlayClick, isDismissible, onClose],
    );

    const getModalProps = useCallback(
        (props = {}, ref = null) => ({
            ...props,
            ref: mergeRefs(ref, modalRef),
            role: 'dialog',
            tabIndex: -1,
            'aria-modal': true,
        }),
        [],
    );

    const getModalWrapperProps = useCallback(
        () => ({ ref: modalWrapperRef, onClick: handleWrapperClick, onMouseDown: handleMouseDown }),
        [handleWrapperClick, handleMouseDown],
    );

    useKeyPressWithCallback('Escape', () => isDismissible && onClose?.());

    return { isOpen, onClose, isDismissible, getModalProps, getModalWrapperProps };
};

export { useModal };
