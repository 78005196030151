import React, { useRef, useEffect } from 'react';
import styled from '../../utils/styled';
import { forwardRef } from '../../system';
import { mergeRefs, themeMode } from '../../utils';
import { Box } from '../../primitives';
import { ScrollableProps } from './types';

const StyledScrollable = styled(Box)<ScrollableProps>`
    flex: 1 1 auto;
    overflow: overlay;
    color-scheme: ${themeMode({ light: 'light', dark: 'dark' })};
`;

export const Scrollable = forwardRef<ScrollableProps, 'div'>((props, ref) => {
    const { children, onScroll, ...rest } = props;
    const scrollableNodeRef = useRef<HTMLDivElement>();

    useEffect(() => {
        const scrollableNode = scrollableNodeRef.current;

        if (!scrollableNode) {
            return;
        }

        const handleScroll = (e) => onScroll?.(e);

        scrollableNode.addEventListener('scroll', handleScroll);

        return (): void => {
            if (scrollableNode) {
                scrollableNode.removeEventListener('scroll', handleScroll);
            }
        };
    }, [onScroll, scrollableNodeRef]);

    return (
        <StyledScrollable ref={mergeRefs(ref, scrollableNodeRef)} {...rest}>
            {children}
        </StyledScrollable>
    );
});

Scrollable.displayName = 'Scrollable';
