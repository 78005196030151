import {
  Box,
  FacebookFilled,
  XFilled,
  RedditFilled,
  LinkedinFilled,
  useBreakpoint,
} from '@landr/maestro';
import { ShareLinkButton, ShareSocialLink } from './components';
import {
  getFacebookShareLink,
  getLinkedInShareLink,
  getRedditShareLink,
  getXShareLink,
} from './linkUtils';
import styles from './ShareLinks.module.scss';

// TODO: CORE-11797 Translate title and labels
export const ShareLinks: React.FC = () => {
  const isMobile = useBreakpoint('md');

  if (isMobile) {
    return <ShareLinkButton />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Box className={styles.title}>Share this article:</Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '8px',
          marginLeft: '12px',
        }}
      >
        <ShareLinkButton />
        <ShareSocialLink
          target="_blank"
          icon={FacebookFilled}
          href={getFacebookShareLink()}
          name="Facebook"
          label="Share on Facebook"
        />
        <ShareSocialLink
          target="_blank"
          icon={XFilled}
          href={getXShareLink()}
          name="Twitter"
          label="Share on X"
        />
        <ShareSocialLink
          target="_blank"
          icon={LinkedinFilled}
          href={getLinkedInShareLink()}
          name="LinkedIn"
          label="Share on LinkedIn"
        />
        <ShareSocialLink
          target="_blank"
          icon={RedditFilled}
          href={getRedditShareLink()}
          name="Reddit"
          label="Share on Reddit"
        />
      </Box>
    </Box>
  );
};
