import { themeMode, palette, spacing } from '../../utils';
import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { AutocompleteProps } from './types';

const Autocomplete = styled(Box)<AutocompleteProps>`
    position: relative;
`;

const Divider = styled.div`
    display: none;
    align-items: center;
    justify-content: center;
    color: ${palette('neutral.500')};
    width: 100%;

    &:not(:last-child) {
        display: flex;
    }

    &:after,
    &:before {
        content: ' ';
        flex-grow: 1;
        height: 1px;
        background-color: ${themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') })};
    }
`;

const SingleValueComponentContainer = styled(Box)`
    display: flex;
    align-items: center;
    position: absolute;
    padding: ${spacing('sm')};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`;

interface StyledAutocomplete {
    Autocomplete: typeof Autocomplete;
    Divider: typeof Divider;
    SingleValueComponentContainer: typeof SingleValueComponentContainer;
}

export const Styled: StyledAutocomplete = {
    Autocomplete,
    Divider,
    SingleValueComponentContainer,
};
