import styled from 'styled-components';
import React from 'react';
import { Box } from '../../primitives/Box';
import { TableSemanticContext } from './TableSemanticContext';
import { TableFootProps } from './types';

const Foot = styled(Box)<TableFootProps>`
    display: table-footer-group;
`;

const TableFoot: React.FC<TableFootProps> = ({ children, ...props }) => {
    return (
        <TableSemanticContext.Provider value={{ location: 'foot' }}>
            <Foot {...props}>{children}</Foot>
        </TableSemanticContext.Provider>
    );
};

export { TableFoot };
