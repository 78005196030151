import { useMemo } from 'react';
import { createContext, useContext } from 'react';
import { AutocompleteDropdownContextOptions } from './types';

export const AutocompleteDropdownContext = createContext<AutocompleteDropdownContextOptions>({
    isOpen: false,
    items: [],
});

export const AutocompleteDropdownProvider = AutocompleteDropdownContext.Provider;

export const useAutocompleteDropdown = () => {
    const { items, groupBy, ...context } = useContext(AutocompleteDropdownContext);

    const getGroupedItems = useMemo(() => {
        if (!groupBy) {
            return items;
        }

        const groups = Array.from(new Set(items.map((item) => groupBy(item))));

        const groupedOptions = groups.map((group, groupIndex) => ({
            group,
            index: groupIndex,
            options: items.filter((option) => groupBy(option) === group),
        }));

        return groupedOptions;
    }, [items, groupBy]);

    return { items: getGroupedItems, isGrouped: !!groupBy, ...context };
};
