import React from 'react';
import { nanoid } from 'nanoid/non-secure';
import { forwardRef } from '../../../system';
import { omitSystemProps, getSystemProps } from '../../../system/styled-system';
import { RadioCardProps } from './types';
import { Addon } from './Addon';
import * as Styled from './styled';

const RadioCard = forwardRef<RadioCardProps, 'input'>((props, ref) => {
    const {
        children,
        id: idProp,
        as = 'input',
        name,
        value,
        label,
        description,
        isChecked,
        isDisabled,
        isInvalid,
        onChange,
        defaultChecked,
        size = 'md',
        wrapperProps,
        wrapperRef,
        ...rest
    } = omitSystemProps(props);

    const { color, ...systemProps } = getSystemProps(props);

    const id = idProp || `radiocard-${nanoid()}`;

    return (
        <Styled.Card
            ref={ref}
            forwardedAs={as}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            isChecked={isChecked}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            wrapperProps={{ ...wrapperProps, ...systemProps }}
            {...rest}
        >
            <Styled.Control size={size} />
            <Styled.Label>{label}</Styled.Label>
            {description && <Styled.Description>{description}</Styled.Description>}
            {children}
        </Styled.Card>
    );
});

RadioCard.displayName = 'RadioCard';

export default Object.assign(RadioCard, { Content: Styled.Content, Addon });
