import styled, { css } from '../../utils/styled';
import { variant } from '../../utils';
import { Group, GroupProps } from '../../primitives/Group';
import { Button } from '../Button';
import { PaginationProps, PaginationButtonProps } from './types';

const PAGINATION_SIZE = {
    md: '36px',
    lg: '48px',
};

const sizeStyles = variant({
    prop: 'buttonSize',
    variants: {
        md: {
            minWidth: PAGINATION_SIZE.md,
            height: PAGINATION_SIZE.md,
            fontSize: 'md',
            lineHeight: PAGINATION_SIZE.md,
        },
        lg: {
            minWidth: PAGINATION_SIZE.lg,
            height: PAGINATION_SIZE.lg,
            fontSize: 'lg',
            lineHeight: PAGINATION_SIZE.lg,
        },
    },
});

const shapeStyles = variant({
    prop: 'shape',
    variants: {
        default: {
            borderRadius: 'sm',
        },
        round: {
            borderRadius: 'round',
        },
    },
});

export const PaginationPageButton = styled(Button)<Partial<PaginationButtonProps>>`
    padding: 0;

    ${sizeStyles};
    ${shapeStyles};

    ${({ onClick }) =>
        !onClick &&
        css`
            pointer-events: none;
        `}
`;

export const PaginationComponent = styled(Group)<Partial<GroupProps & PaginationProps>>`
    display: flex;
    align-items: center;
    justify-content: start;
`;
