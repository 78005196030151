import { ApolloClient } from '@apollo/client';
import { AlsHeaderSettingsQuery, AlsHeaderSettingsQueryVariables } from 'types';
import { GET_ALS_HEADER_SETTINGS } from 'api/global/queries';

export async function getAlsHeaderSettings(
  apolloClient: ApolloClient<unknown>
): Promise<AlsHeaderSettingsQuery['alsHeaderSettings']> {
  let { data } = await apolloClient.query<
    AlsHeaderSettingsQuery,
    AlsHeaderSettingsQueryVariables
  >({
    query: GET_ALS_HEADER_SETTINGS,
    errorPolicy: 'all',
  });

  return data.alsHeaderSettings;
}
