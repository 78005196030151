import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgArchiveOutlined = ({ size, title = 'ArchiveOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <rect width={14.5} height={11} x={4.75} y={8.25} rx={1.25} />
            <path strokeLinecap="round" d="M7 4.75h10" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.5 12h5" />
        </g>
    </svg>
);

export default withIcon(SvgArchiveOutlined);
