import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCreditCardFilled = ({ size, title = 'CreditCardFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M22 8.75v-2.5C22 5.5 21.5 5 20.75 5H3.25C2.5 5 2 5.5 2 6.25v2.5h20zm-20 2.5v7.5C2 19.5 2.5 20 3.25 20h17.5c.75 0 1.25-.5 1.25-1.25v-7.5H2zm7.5 5h-5V15h5v1.25zm10 0H17V15h2.5v1.25z"
        />
    </svg>
);

export default withIcon(SvgCreditCardFilled);
