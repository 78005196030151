import React, { Fragment } from 'react';
import { ButtonGroup } from '../ButtonGroup';
import { Text } from '../Text';
import { ColorMode } from '../MaestroThemeProvider';
import * as Styled from './styled';
import { FloatingActionMenuProps } from './types';

export const FloatingActionMenu: React.FC<FloatingActionMenuProps & React.ComponentPropsWithoutRef<'div'>> = ({
    children,
    title,
    actions,
}) => {
    return (
        <ColorMode mode="light">
            <Styled.FloatingActionMenu>
                {children}
                {title && <Text style={{ whiteSpace: 'nowrap' }}>{title}</Text>}
                {actions && (
                    <ButtonGroup spacing="xs" sx={{ marginLeft: 'md' }}>
                        {actions.map((action, i) => (
                            <Fragment key={i}>{action}</Fragment>
                        ))}
                    </ButtonGroup>
                )}
            </Styled.FloatingActionMenu>
        </ColorMode>
    );
};

FloatingActionMenu.displayName = 'FloatingActionMenu';
