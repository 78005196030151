import { FC, Fragment } from 'react';
import { renderBlocks } from 'helpers/renderBlocks';
import { NotFoundPageFragmentsFragment } from 'types';
import styles from './NotFound.module.scss';

export interface NotFoundProps {
  data: NotFoundPageFragmentsFragment | null;
}

export const NotFound: FC<NotFoundProps> = ({ data }) => {
  return (
    <>
      <div className={styles.root}>
        <main className={styles.main}>
          <div className={styles.header}>
            <div className={styles.headerEntry}>
              <h1 className={styles.title}>
                {data?.notFoundAdditionalFields?.title ?? 'Page not found'}
              </h1>
              <p className={styles.description}>
                {data?.notFoundAdditionalFields?.subtitle}
              </p>
            </div>
          </div>

          {data?.blocks?.map((item) => {
            if (item) {
              return <Fragment key={item.id}>{renderBlocks(item)}</Fragment>;
            }

            return null;
          })}
        </main>
      </div>
    </>
  );
};
