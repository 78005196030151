import React, { FC } from 'react';
import styled, { css } from '../../utils/styled';
import { variant, themeMode, palette, fontSize } from '../../utils';
import { BoxProps, Box } from '../../primitives';

const directionStyles = variant({
    prop: 'direction',
    variants: {
        horizontal: {
            width: '100%',
            '&:before, &:after': {
                height: '1px',
            },
        },
        vertical: {
            height: '100%',
            flexDirection: 'column',
            flexGrow: 1,
            '&:before, &:after': {
                width: '1px',
            },
        },
    },
});

const railStyles = css`
    content: ' ';
    flex-grow: 1;
    background-color: ${themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') })};
`;

export type DividerDirection = 'horizontal' | 'vertical';

export type DividerAlign = 'start' | 'center' | 'end';

export interface DividerProps extends BoxProps {
    /** Inner content. Placed in the middle of the component  */
    children?: React.ReactNode;
    /** The shape of the FeatureCard. By default it equals to 'horizontal'  */
    direction?: DividerDirection;
    /** How the content is justified (horizontal) or aligned (vertical) along the Divider */
    align?: DividerAlign;
}

const StyledDivider = styled(Box)<DividerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${themeMode({ light: palette('neutral.500'), dark: palette('neutral.400') })};
    font-size: ${fontSize('sm')};
    line-height: 18px;
    ${({ align }) => {
        switch (align) {
            case 'center':
                return css`
                    &:after,
                    &:before {
                        ${railStyles}
                    }
                `;
            case 'start':
                return css`
                    &:after {
                        ${railStyles}
                    }
                `;
            case 'end':
                return css`
                    &:before {
                        ${railStyles}
                    }
                `;
            default:
                return css``;
        }
    }}
    ${directionStyles};
`;

const StyledDividerContent = styled(Box)<DividerProps>(
    variant({
        prop: 'align',
        compoundProp: 'direction',
        variants: {
            start: {
                horizontal: { pr: 'md' },
                vertical: { pb: 'md' },
            },
            center: {
                horizontal: { px: 'md' },
                vertical: { py: 'md' },
            },
            end: {
                horizontal: { pl: 'md' },
                vertical: { pt: 'md' },
            },
        },
    }),
);

export const Divider: FC<DividerProps> = ({ children, direction = 'horizontal', align = 'center', ...props }) => (
    <StyledDivider direction={direction} align={align} role="separator" {...props}>
        {children && (
            <StyledDividerContent align={align} direction={direction}>
                {children}
            </StyledDividerContent>
        )}
    </StyledDivider>
);

Divider.displayName = 'Divider';
