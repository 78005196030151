import { useState, useEffect, useCallback } from 'react';
import { useIsDestroyed } from '../../hooks';
import { useEnhancedEffect } from '../../utils';
import { ImageProps } from './Image';

type UseImageProps = ImageProps;

type Status = 'loaded' | 'failed' | 'pending' | 'loading';

type UseImage = {
    isLoaded: boolean;
    isFailed: boolean;
    isPending: boolean;
    isLoading: boolean;
};

export const useImage = (props: UseImageProps): UseImage => {
    const { src, crossOrigin, srcSet, sizes, onLoad, onError } = props;
    const [status, setStatus] = useState<Status>('pending');

    const isDestroyed = useIsDestroyed();

    useEffect(() => {
        setStatus(src ? 'loading' : 'pending');
    }, [src]);

    const loadImage = useCallback(() => {
        if (!src) return;

        const image = new Image();

        image.src = src;

        if (crossOrigin) {
            image.crossOrigin = crossOrigin;
        }

        if (srcSet) {
            image.srcset = srcSet;
        }

        if (sizes) {
            image.sizes = sizes;
        }

        image.onload = (event) => {
            if (isDestroyed.current) return;
            setStatus('loaded');
            onLoad?.(event as unknown as React.SyntheticEvent<HTMLImageElement, Event>);
        };

        image.onerror = (event) => {
            if (isDestroyed.current) return;
            setStatus('failed');
            onError?.(event as unknown as React.SyntheticEvent<HTMLImageElement, Event>);
        };
    }, [crossOrigin, isDestroyed, onError, onLoad, sizes, src, srcSet]);

    useEnhancedEffect(() => {
        if (status === 'loading') {
            loadImage();
        }
    }, [status, loadImage]);

    return {
        isLoaded: status === 'loaded',
        isFailed: status === 'failed',
        isPending: status === 'pending',
        isLoading: status === 'loading',
    };
};
