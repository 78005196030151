import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMinusOutlined = ({ size, title = 'MinusOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M4.5 12h15" />
    </svg>
);

export default withIcon(SvgMinusOutlined);
