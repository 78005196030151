import { Fragment, FC } from 'react';
import Link from 'next/link';
import dayjs from 'dayjs';
import cn from 'classnames';
import { renderBlocks } from 'helpers/renderBlocks';
import { CATEGORY } from 'constants/routes';
import { YouTubePlayer, LessonNavigation, Label, BasicImage } from 'ui';
import {
  PostAdditionalFieldsFragmentFragment,
  PostFragmentFragment,
} from 'types';
import { ShareLinks } from 'components/ShareLinks';
import { LessonPostsNodes } from '../../pages/[post]';

import styles from './Post.module.scss';

export interface PostProps {
  postData:
    | (PostFragmentFragment &
        PostAdditionalFieldsFragmentFragment & { id: string })
    | null;
  relatedPosts: LessonPostsNodes | null;
}

export const Post: FC<PostProps> = ({ postData, relatedPosts }) => {
  const {
    title,
    author,
    modified,
    featuredImage,
    categories,
    blocks,
    postAdditionalFields,
    postFormat,
    postFormatVideo,
  } = postData || {};

  const { accentColor, minutesToRead } = postAdditionalFields || {};

  const authorName = author?.node?.name || author?.node?.firstName;
  const authorSlug = `author/${author?.node?.slug}`;
  const metaPublishDate = dayjs(modified as string).format('D MMM YYYY');
  const image =
    featuredImage?.node?.sourceUrl || '/temporary/post-cover-example.png';
  const imageAltText =
    featuredImage?.node?.altText || title || 'featured image';
  const youtubeVideoId = postFormatVideo?.videoId;
  const isVideoPost = postFormat === 'video';
  const accentColorStyle = {
    '--accent-color': accentColor,
  } as React.CSSProperties;
  const isLessonNavigationVisible =
    postData?.postAdditionalFields?.lessonNavigation?.visibility;
  const lessonNavigationTitle =
    postData?.postAdditionalFields?.lessonNavigation?.title;

  if (!postData) {
    return null;
  }

  const PostNodes = () => {
    if (!categories?.nodes) return null;

    return (
      <>
        {categories.nodes.map((item) => {
          return (
            <Label
              type="link"
              key={item?.id}
              href={`${CATEGORY}/${item?.slug}`}
              label={`${item?.name}`}
            />
          );
        })}
      </>
    );
  };

  const PostImageOrVideo = () => {
    if (isVideoPost && youtubeVideoId) {
      return (
        <YouTubePlayer
          link={youtubeVideoId}
          withResponsiveButton
          playOnClick
          isEmbedded
        />
      );
    }

    return (
      <BasicImage
        src={image}
        alt={imageAltText}
        loading="eager"
        draggable="false"
        priority
      />
    );
  };

  const PostReadMinutes = () => {
    if (!minutesToRead) return null;

    return (
      <>
        &nbsp;&nbsp;·&nbsp;&nbsp;
        <span className={styles.metaTimeToRead}>
          {minutesToRead} minute read
        </span>
      </>
    );
  };

  const PostShareArticle = () => {
    return (
      <div className={styles.postShareArticle}>
        <ShareLinks />
      </div>
    );
  };

  const PostLessonNavigation = () => {
    if (!isLessonNavigationVisible) return null;

    return (
      <LessonNavigation
        lessons={relatedPosts}
        currentLessonId={postData.id}
        title={lessonNavigationTitle}
      />
    );
  };

  const PostBlocks = () => {
    if (!blocks) return null;

    return (
      <>
        {blocks.map((block) => {
          if (block) {
            return (
              <Fragment key={block.id}>
                {renderBlocks(block, postData)}
              </Fragment>
            );
          }

          return null;
        })}
      </>
    );
  };

  return (
    <div className={styles.root} style={accentColorStyle}>
      <main className={styles.main}>
        <div className={styles.header}>
          <div className={styles.headerEntry}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.categories}>
              <PostNodes />
            </div>
            <div className={styles.meta}>
              <Link href={authorSlug} legacyBehavior>
                <a className={styles.metaAuthor}>{authorName}</a>
              </Link>
              &nbsp;&nbsp;·&nbsp;&nbsp;
              <span className={styles.metaPublishDate}>{metaPublishDate}</span>
              <PostReadMinutes />
            </div>
          </div>
        </div>

        <div className={styles.postMediaWrapper}>
          <div
            className={cn(styles.postMedia, {
              [styles.video]: isVideoPost,
            })}
          >
            <PostImageOrVideo />
          </div>
        </div>
        <PostShareArticle />
        <PostLessonNavigation />
        <PostBlocks />
      </main>
    </div>
  );
};
