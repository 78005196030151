import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMusicNoteOff = ({ size, title = 'MusicNoteOff', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path d="M10.844 5.719s5.242 0 6.875 4.812c-2.521-1.49-6.875-1.375-6.875-1.375M10.84 4.34v12.38" />
            <circle cx={8.09} cy={16.72} r={2.75} />
            <path d="M18.13 4.39L5 20" />
        </g>
    </svg>
);

export default withIcon(SvgMusicNoteOff);
