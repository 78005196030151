import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgArrowDownOutlined = ({ size, title = 'ArrowDownOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
            <path strokeLinejoin="round" d="M5 12.342L11.995 19 19 12.334" />
            <path d="M12 5.333v13.334" />
        </g>
    </svg>
);

export default withIcon(SvgArrowDownOutlined);
