import { FC } from 'react';
import cn from 'classnames';
import { ProductFeatureType } from 'types/feature';
import { Position } from 'components/Common';
import { AudioSample, BasicImage } from 'ui';

import styles from './ProductFeature.module.scss';

export interface ProductFeatureProps {
  featureType: ProductFeatureType;
  title: string;
  linkLabel: string;
  link: string;
  image: string;
  audio: string;
  position: 'inline' | 'left-side-bar' | 'right-side-bar';
}

export const ProductFeature: FC<ProductFeatureProps> = ({
  featureType,
  title,
  linkLabel,
  link,
  image,
  audio,
  position,
}) => {
  const isSidebarPosition =
    position === 'left-side-bar' || position === 'right-side-bar';

  return (
    <Position position={position}>
      <div
        className={cn(styles.root, {
          [styles.sidebar]: isSidebarPosition,
        })}
      >
        <div className={styles.imageWrapper}>
          {featureType === 'audio_feature' && audio ? (
            <AudioSample src={audio} image={image} />
          ) : (
            <BasicImage src={image} alt="Product image" />
          )}
        </div>
        <div className={styles.body}>
          <p
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
          <a
            className={styles.link}
            href={link}
            target="_blank"
            rel="noreferrer"
            download
          >
            {linkLabel}
          </a>
        </div>
      </div>
    </Position>
  );
};
