import React from 'react';
import { maestroComponent } from '../../utils';
import { CaretRightOutlined, CaretLeftOutlined } from '../../assets/icons';
import { PaginationProps } from './types';
import * as Styled from './styled';
import usePagination from './usePagination';
import Truncated from './components/Truncated';
import PaginationButton from './components/PaginationButton';

export const PaginationComponent: React.FC<PaginationProps> = ({
    items,
    itemsPerPage,
    onPageChanged,
    size = 'md',
    shape = 'default',
    currentPage = 1,
    pagesPerSlice = 7,
    isTruncated = false,
    buttonComponent,
    ...props
}) => {
    const { numberOfPages, pagesRange, shouldTruncate, startRange, endRange, goToPage } = usePagination({
        currentPage,
        isTruncated,
        items,
        itemsPerPage,
        pagesPerSlice,
        onPageChanged,
    });

    return (
        <Styled.PaginationComponent gutter="xs" {...props}>
            <PaginationButton
                buttonComponent={buttonComponent}
                page={currentPage - 1}
                onClick={() => goToPage(currentPage - 1)}
                buttonSize={size}
                shape={shape}
                isDisabled={currentPage <= 1}
                itemType="arrow"
            >
                <CaretLeftOutlined size={size} />
            </PaginationButton>
            {shouldTruncate && (
                <Truncated
                    buttonComponent={buttonComponent}
                    startRange={startRange}
                    endRange={endRange}
                    currentPage={currentPage}
                    buttonSize={size}
                    shape={shape}
                    goToPage={goToPage}
                />
            )}
            {!shouldTruncate &&
                pagesRange.map((page, i) => {
                    return (
                        <PaginationButton
                            buttonComponent={buttonComponent}
                            key={`${i}${page}`}
                            buttonSize={size}
                            shape={shape}
                            page={page}
                            onClick={() => goToPage(page as number)}
                            isSelected={page === currentPage}
                            itemType="number"
                        >
                            {page}
                        </PaginationButton>
                    );
                })}
            <PaginationButton
                buttonComponent={buttonComponent}
                onClick={() => goToPage(currentPage + 1)}
                page={currentPage + 1}
                buttonSize={size}
                shape={shape}
                isDisabled={currentPage >= numberOfPages}
                itemType="arrow"
            >
                <CaretRightOutlined size={size} />
            </PaginationButton>
        </Styled.PaginationComponent>
    );
};

export const Pagination = maestroComponent<PaginationProps>('Pagination', PaginationComponent);
