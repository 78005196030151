import styled from '../../../utils/styled';
import { spacing, palette } from '../../../utils';
import { Button, ButtonProps } from '../../Button';
import { Box, CSSProps } from '../../../primitives/Box';

export const ListItem = styled(Button)<ButtonProps & CSSProps>`
    padding: 0 ${spacing('lg')};
    button {
        flex-shrink: 0;
    }
    .highlight {
        background: ${palette('primary.500', 0.24)};
    }
`;

// TODO: update Button component to handle spacing correctly
export const SubItemsWrapper = styled.div`
    > li > button {
        padding: 0 ${spacing('jb')};
    }
`;

export const ListFilterWrapper = styled.div`
    width: 320px;
    ${({ theme }) => theme.breakpoint('md')`
        width: 100%;
    `}
`;

export const ListItemsScrollContainer = styled(Box)`
    height: 314px;
    overflow: auto;
    ${({ theme }) => theme.breakpoint('md')`
        height: 100%;
    `}
    ${(props) => `
        height: ${props.height};
    `};
`;

export const ListItemsItem = styled.li`
    display: flex;
    justify-content: center;
    cursor: pointer;
    button {
        border-radius: 0;
        div {
            transition: all 0ms;
        }
    }
    :hover {
        background-color: ${palette('neutral.700', 1)};
        button,
        div {
            background-color: ${palette('neutral.700', 1)};
        }
    }
`;

export const CaretDownWrapper = styled(Box)<any>`
    color: ${palette('neutral.400', 1)};
    :hover {
        color: ${palette('neutral.50')};
    }
    ${({ areSubItemsVisible }) =>
        areSubItemsVisible &&
        `
        svg {
            transform: rotate(180deg);
        }
    `}
`;
