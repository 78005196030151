import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgAddToCollectionOutlined = ({
    size,
    title = 'AddToCollectionOutlined',
    ...props
}: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M19 12V8h-8L9 5H4v11a2 2 0 002 2h6.5"
            />
            <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M19 15v6m-3-3h6" />
        </g>
    </svg>
);

export default withIcon(SvgAddToCollectionOutlined);
