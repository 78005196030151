import { useRef, MouseEvent, useCallback, useEffect, HTMLAttributes } from 'react';
import { callAllHandlers, mergeRefs } from '../../utils';
import { useDisclosure } from '../../hooks';
import { PopoverPlacement } from './types';

interface GetTriggerPropsOptions extends HTMLAttributes<HTMLElement> {
    onClick?: (event: MouseEvent) => void;
    onMouseEnter?: (event: MouseEvent) => void;
    onMouseLeave?: (event: MouseEvent) => void;
    ref: React.Ref<any> | React.MutableRefObject<any>;
}

interface GetPopoverPropsOptions {
    anchorRef: React.MutableRefObject<any>;
    isOpen: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    triggerRef?: React.MutableRefObject<any>;
    hasArrow?: boolean;
    placement?: PopoverPlacement;
    onClickOutside?: () => void;
    onMouseEnter?: (event: MouseEvent) => void;
    onMouseLeave?: (event: MouseEvent) => void;
}

export interface UsePopover {
    isOpen: boolean;
    anchorRef: React.MutableRefObject<any>;
    toggle: (event: MouseEvent) => void;
    open: () => void;
    close: () => void;
    getTriggerProps: (props?: any) => GetTriggerPropsOptions;
    getPopoverProps: (props?: any) => GetPopoverPropsOptions;
}

interface UsePopoverProps {
    isOpenDefault?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    triggerEvent?: 'click' | 'hover';
    triggerRef?: React.MutableRefObject<any>;
    hasArrow?: boolean;
    placement?: PopoverPlacement;
    closeOnBlur?: boolean;
    closeDelay?: number;
}

const usePopover = ({
    isOpenDefault = false,
    onOpen: onOpenProp,
    onClose: onCloseProp,
    triggerEvent = 'click',
    triggerRef,
    hasArrow,
    placement,
    closeOnBlur = true,
    closeDelay,
}: UsePopoverProps = {}): UsePopover => {
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
        defaultIsOpen: isOpenDefault,
        onOpen: onOpenProp,
        onClose: onCloseProp,
    });

    const isHoveringRef = useRef(false);
    const closeTimeout = useRef<number>();

    const anchorRef = useRef<HTMLElement>(null);

    useEffect(() => {
        return (): void => {
            if (closeTimeout.current) {
                clearTimeout(closeTimeout.current);
            }
        };
    }, []);

    const getTriggerProps = useCallback(
        (
            { onClick = (): void => void 0, onMouseEnter = (): void => void 0, onMouseLeave = (): void => void 0 } = {},
            forwardedRef = null,
        ): GetTriggerPropsOptions => {
            return {
                role: 'button',
                'aria-haspopup': 'true',
                'aria-expanded': isOpen,
                ...(!triggerRef && {
                    onClick: triggerEvent === 'click' ? callAllHandlers(onClick, onToggle) : onClick,
                    onMouseEnter:
                        triggerEvent === 'hover'
                            ? callAllHandlers(onMouseEnter, () => {
                                  isHoveringRef.current = true;
                                  onOpen();
                              })
                            : onMouseEnter,
                    onMouseLeave:
                        triggerEvent === 'hover'
                            ? callAllHandlers(onMouseLeave, () => {
                                  isHoveringRef.current = false;
                                  closeTimeout.current = window.setTimeout(() => {
                                      if (isHoveringRef.current === false) {
                                          onClose();
                                      }
                                  }, closeDelay);
                              })
                            : onMouseLeave,
                }),
                ref: mergeRefs(forwardedRef, anchorRef),
            };
        },
        [closeDelay, isOpen, onClose, onOpen, onToggle, triggerEvent, triggerRef],
    );

    const getPopoverProps = useCallback(
        ({
            onMouseEnter = (): void => undefined,
            onMouseLeave = (): void => undefined,
        } = {}): GetPopoverPropsOptions => {
            const handleClickOutside = (): void => {
                closeOnBlur && onClose();
            };

            return {
                anchorRef,
                isOpen,
                triggerRef,
                hasArrow,
                placement,
                onClickOutside: handleClickOutside,
                onMouseEnter:
                    triggerEvent === 'hover'
                        ? callAllHandlers(onMouseEnter, () => {
                              isHoveringRef.current = true;
                          })
                        : onMouseEnter,
                onMouseLeave:
                    triggerEvent === 'hover'
                        ? callAllHandlers(onMouseLeave, () => {
                              isHoveringRef.current = false;
                              closeTimeout.current = window.setTimeout(() => {
                                  if (isHoveringRef.current === false) {
                                      onClose();
                                  }
                              }, closeDelay);
                          })
                        : onMouseLeave,
            };
        },
        [closeDelay, closeOnBlur, hasArrow, isOpen, onClose, placement, triggerEvent, triggerRef],
    );

    return { isOpen, anchorRef, toggle: onToggle, open: onOpen, close: onClose, getTriggerProps, getPopoverProps };
};

export { usePopover };
