import styled, { css } from '../../utils/styled';
import { Box, BoxProps } from '../Box';
import { LevelProps } from './types';

export const Item = styled(Box)<BoxProps>`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
`;

Item.displayName = 'Level.Item';

export const Center = styled(Box)<BoxProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: center;
`;

Center.displayName = 'Level.Center';

export const Left = styled(Box)<BoxProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-area: left;
`;

Left.displayName = 'Level.Left';

export const Right = styled(Box)<BoxProps>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-area: right;
`;

Right.displayName = 'Level.Right';

export const Level = styled(Box)<LevelProps>`
    width: 100%;
    ${({ pinCenter, align }) =>
        pinCenter
            ? css`
                  display: grid;
                  grid-template-columns: 1fr auto 1fr;
                  grid-template-areas: 'left center right';
                  column-gap: 2rem;
              `
            : css`
                  display: flex;
                  justify-content: space-between;
                  align-items: ${align};
              `}
`;
