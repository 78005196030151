import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgReplyOutlined = ({ size, title = 'ReplyOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M5.94 11.47l5.302-5.303v3.181c3.896 0 7.425 4.3 7.425 8.485-1.979-3.204-3.508-4.242-7.425-4.242v3.182L5.94 11.47z"
        />
    </svg>
);

export default withIcon(SvgReplyOutlined);
