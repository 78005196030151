import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgTrendingUpOutlined = ({ size, title = 'TrendingUpOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3 17.5l6-6 4 4L21.5 7"
        />
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15.5 7h6v6" />
    </svg>
);

export default withIcon(SvgTrendingUpOutlined);
