import React from 'react';
import { forwardRef } from '../../system';
import { ProductHeaderAddonProps } from './types';
import * as Styled from './styled';

export const ProductHeaderAddon = forwardRef<ProductHeaderAddonProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;
    return (
        <Styled.Addon ref={ref} {...rest}>
            {children}
        </Styled.Addon>
    );
});
ProductHeaderAddon.displayName = 'ProductHeader.Addon';
