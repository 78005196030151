import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHeadphonesCheckedOutlined = ({
    size,
    title = 'HeadphonesCheckedOutlined',
    ...props
}: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M8.104 10.667c1.156 0 2.102.896 2.182 2.03l.006.157v3.834a2.188 2.188 0 01-4.37.156l-.005-.157v-3.833c0-1.208.979-2.187 2.187-2.187zm0 1.5a.688.688 0 00-.68.586l-.007.101v3.834a.687.687 0 001.367.101l.008-.102v-3.833a.687.687 0 00-.688-.687zM14.334 3a5.542 5.542 0 015.537 5.324l.004.218.001 3.609a5.965 5.965 0 00-1.5-.35l-.001-3.26a4.042 4.042 0 00-3.84-4.036l-.202-.005H9.542a4.042 4.042 0 00-4.037 3.84l-.005.202v6.708a.75.75 0 01-1.493.102L4 15.25V8.542a5.542 5.542 0 015.324-5.538L9.542 3h4.791zm1.437 7.667c.814 0 1.525.445 1.901 1.105l.099-.001a5.98 5.98 0 00-4.188 1.703v-.62c0-1.208.98-2.187 2.188-2.187z"
            />
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M17.77 12.77a5 5 0 110 10 5 5 0 010-10zm2.887 1.858a.75.75 0 00-.971.173l-.058.084-2.505 4.176-1.322-1.32-.084-.073a.75.75 0 00-1.049 1.05l.073.083 2.001 2 .084.073a.75.75 0 001.026-.127l.064-.09 2.998-5 .047-.091a.75.75 0 00-.304-.938z"
            />
        </g>
    </svg>
);

export default withIcon(SvgHeadphonesCheckedOutlined);
