import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPriceTagOutlined = ({ size, title = 'PriceTagOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.25 8.25v4l-7.424 7.382c-.867.866-2.323.866-3.19 0l-4.25-4.252c-.867-.867-.867-2.323 0-3.19l7.365-7.44h7.5"
            />
            <circle cx={14} cy={10} r={2.5} />
            <path strokeLinecap="round" d="M20 4l-5 5" />
        </g>
    </svg>
);

export default withIcon(SvgPriceTagOutlined);
