import styled from '../../utils/styled';
import { palette, radius } from '../../utils';
import { Box } from '../../primitives/Box';
import { BannerProps } from './types';

export const Banner = styled(Box)<BannerProps>`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: ${palette('neutral.800')};
    border-radius: ${radius('base')};
    overflow: hidden;
`;

export const Overlay = styled(Box)`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 0;
    > * {
        flex-shrink: 0;
    }
`;

export const TextWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const Image = styled('img')`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
