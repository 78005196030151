import React, { useCallback, useRef, useMemo, useState, useEffect, Fragment } from 'react';
import { useSelect, useMultipleSelection, UseSelectStateChange } from 'downshift';
import { CheckCircleFilled, CheckCircleOutlined } from '../../assets/icons';
import { filterChildrenByType } from '../../utils';
import { Box, Level, ConditionalWrapper } from '../../primitives';
import { DropdownMenu } from '../DropdownMenu';
import { Button } from '../Button';
import { Badge } from '../Badge';
import { List } from '../List';
import { Scrollable } from '../Scrollable';
import { SearchInput } from '../SearchInput';
import { Text } from '../Text';
import { Progress } from '../Progress';
import { Center } from '../../primitives/Center';
import { FilterDropdownProps, FilterDropdownItem, FilterDropdownGroupItems, FilterDropdownGroupItem } from './types';
import { getGroups, getItemsByGroup } from './helpers';
import * as Styled from './styled';

const DefaultLoadingComponent = () => (
    <Center py="md">
        <Progress size="md" />
    </Center>
);

export const FilterDropdownBase = <T,>({
    items,
    label,
    triggerKind = 'solid',
    triggerComponent: TriggerComponent,
    header,
    subheader,
    onSelect,
    onChange,
    selectionType = 'multi',
    selectionStyle = 'checkbox',
    showSelectedItemAsLabel = false,
    initialSelectedItems = [],
    groupBy,
    isSearchable = true,
    searchPlaceholder = 'Search...',
    onSearch,
    onReset,
    helpText,
    hasFooter = false,
    clearLabel = 'Clear all',
    confirmLabel = 'Save',
    onClear,
    onConfirm,
    onOpen,
    onClose,
    isLoading,
    loadingComponent: LoadingComponent = DefaultLoadingComponent,
    children,
    ...rest
}: FilterDropdownProps<T>): JSX.Element => {
    const listRef = useRef<HTMLDivElement>(null);
    const _selectionStyle = selectionType === 'single' ? 'default' : selectionStyle;

    const footer = filterChildrenByType(children, Styled.Footer);

    const [selectedItemValues, setSelectedItemValues] = useState<FilterDropdownItem<T>['value'][] | null>(null);
    const [inputValue, setInputValue] = useState<string>('');

    const filteredInitialSelectedItems: FilterDropdownItem<T>[] = useMemo(() => {
        const filterInitialItems = (items, filter) => {
            const itemKeys = Object.keys(filter);

            return items.filter((item) => {
                return itemKeys.every((key) => {
                    return filter[key] === item[key];
                });
            });
        };

        const filteredItems = initialSelectedItems.map((item) => filterInitialItems(items, item));

        return filteredItems.length
            ? filteredItems.reduce((acc, curr) => {
                  return acc.concat(curr);
              })
            : initialSelectedItems;
    }, [initialSelectedItems, items]);

    const { getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems, reset } = useMultipleSelection({
        initialSelectedItems: filteredInitialSelectedItems,
    });

    const filteredItems = useMemo(() => {
        const itemsWithIndices = items.map((item, index) => ({
            ...item,
            index,
        }));

        if (isSearchable) {
            if (inputValue) {
                return itemsWithIndices.filter((item) =>
                    item.displayValue.toLowerCase().includes(inputValue.toLowerCase()),
                );
            } else {
                return itemsWithIndices;
            }
        } else {
            return itemsWithIndices;
        }
    }, [items, inputValue, isSearchable]);

    const groups = useMemo(() => getGroups(groupBy, items), [items, groupBy]);

    const groupedItems = useMemo(
        () => getItemsByGroup(groupBy, groups, filteredItems),
        [groupBy, groups, filteredItems],
    );

    const isItemSelected = useCallback(
        (item) => {
            return !!selectedItems.find((selectedItem) => selectedItem.id === item.id);
        },
        [selectedItems],
    );

    const addItem = useCallback(
        (selectedItem: FilterDropdownItem<T>) => {
            const itemValues = selectedItems?.map((item) => item.value);
            setSelectedItemValues([...itemValues, selectedItem.value]);
            addSelectedItem(selectedItem);
            onSelect?.(selectedItem.value);
        },
        [addSelectedItem, onSelect, selectedItems],
    );

    const removeItem = useCallback(
        (selectedItem: FilterDropdownItem<T>) => {
            setSelectedItemValues(
                selectedItems.filter((item) => item.id !== selectedItem.id).map((item) => item.value),
            );
            removeSelectedItem(selectedItem);
        },
        [removeSelectedItem, selectedItems],
    );

    const handleClear = useCallback(() => {
        setSelectedItemValues(null);
        selectedItems.forEach((selectedItem) => removeSelectedItem(selectedItem));
        onClear?.();
    }, [removeSelectedItem, selectedItems, onClear]);

    const handleConfirm = useCallback(() => {
        onConfirm?.(selectedItemValues);
    }, [onConfirm, selectedItemValues]);

    const handleSelection = useCallback(
        (selectedItem: FilterDropdownItem<T>) => {
            if (isItemSelected(selectedItem)) {
                removeItem(selectedItem);
            } else if (selectionType === 'single' && selectedItems.length) {
                reset();
                addItem(selectedItem);
            } else {
                addItem(selectedItem);
            }
        },
        [addItem, isItemSelected, removeItem, selectionType, selectedItems, reset],
    );

    const handleInputChange = (inputEvent: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = inputEvent.target;

        setInputValue(value);
        onSearch?.(inputValue);
    };

    const handleReset = () => {
        setInputValue('');
        onReset?.();
    };

    const handleIsOpenChange = ({ isOpen }: UseSelectStateChange<FilterDropdownItem<T>>) => {
        switch (isOpen) {
            case true:
                onOpen?.();
                break;
            case false:
                onClose?.();
                break;
        }
    };

    const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
        items,
        selectedItem: null,
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;
            switch (type) {
                case useSelect.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true,
                        highlightedIndex: state.highlightedIndex,
                    };
                case useSelect.stateChangeTypes.ToggleButtonBlur:
                    return {
                        ...changes,
                        isOpen: false,
                    };
            }
            return { ...changes, isOpen: true };
        },
        onStateChange: ({ type, selectedItem }) => {
            switch (type) {
                case useSelect.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        handleSelection(selectedItem);
                    }
                    break;
                default:
                    break;
            }
        },
        onIsOpenChange: handleIsOpenChange,
    });

    const getSelectionIcon = useCallback(
        (item: FilterDropdownGroupItem<T>) => {
            if (isItemSelected(item)) {
                return <CheckCircleFilled size={22} />;
            } else if (item.index === highlightedIndex) {
                return <CheckCircleOutlined />;
            }

            return undefined;
        },
        [highlightedIndex, isItemSelected],
    );

    const getLabel = useCallback(() => {
        if (selectionType === 'single') {
            if (selectedItems.length) {
                if (showSelectedItemAsLabel) {
                    return selectedItems[0].displayValue;
                } else {
                    return (
                        <>
                            {label}
                            <Badge content={1} variant="inverted" isRounded size="sm" ml="xs" />
                        </>
                    );
                }
            }
        } else if (selectionType === 'multi') {
            if (selectedItems.length) {
                return (
                    <>
                        {label}
                        <Badge
                            content={selectedItems.length.toString(10)}
                            variant="inverted"
                            isRounded
                            size="sm"
                            ml="xs"
                        />
                    </>
                );
            } else {
                return label;
            }
        }

        return label;
    }, [label, selectedItems, selectionType, showSelectedItemAsLabel]);

    useEffect(() => {
        onChange?.(selectedItemValues);
    }, [onChange, selectedItemValues]);

    return (
        <DropdownMenu placement="bottom-start" {...rest} isOpen={isOpen}>
            <DropdownMenu.Trigger>
                {TriggerComponent ? (
                    <TriggerComponent
                        {...getToggleButtonProps(
                            getDropdownProps({ preventKeyAction: isOpen }, { suppressRefError: true }),
                            {
                                suppressRefError: true,
                            },
                        )}
                    />
                ) : (
                    <Button
                        variant="ghost"
                        kind={triggerKind}
                        size="sm"
                        isRounded
                        isDropdown
                        {...getToggleButtonProps(
                            getDropdownProps({ preventKeyAction: isOpen }, { suppressRefError: true }),
                            {
                                suppressRefError: true,
                            },
                        )}
                    >
                        <Box display="flex" alignItems="center">
                            {getLabel()}
                        </Box>
                    </Button>
                )}
            </DropdownMenu.Trigger>
            <DropdownMenu.List {...getMenuProps({ ref: listRef }, { suppressRefError: true })}>
                {isLoading && (
                    <Box flex="1 1 auto" overflowY="auto">
                        <LoadingComponent />
                    </Box>
                )}
                {!isLoading && (
                    <>
                        <Scrollable maxHeight="340px">
                            <Styled.Header>
                                {header && (
                                    <Text size="md" fontWeight="bold" ml="sm">
                                        {header}
                                    </Text>
                                )}
                                {isSearchable && (
                                    <SearchInput
                                        mx="sm"
                                        mb="xs"
                                        mt={header ? 'xs' : 'none'}
                                        placeholder={searchPlaceholder}
                                        onChange={handleInputChange}
                                        onReset={handleReset}
                                        value={inputValue}
                                    />
                                )}
                                {subheader && <List.Subheader>{subheader}</List.Subheader>}
                            </Styled.Header>

                            {groupedItems.map((groupData: FilterDropdownGroupItems<T>, index) => (
                                <Fragment key={index}>
                                    {groupData.group && <List.Subheader>{groupData.group.displayValue}</List.Subheader>}
                                    {groupData.items.map((item: FilterDropdownGroupItem<T>) => (
                                        <Styled.Item
                                            key={item.id}
                                            closeOnClick={selectionType === 'single' ? true : false}
                                            isSelected={_selectionStyle === 'default' ? isItemSelected(item) : false}
                                            disabled={item.isDisabled}
                                            addonLeft={
                                                _selectionStyle === 'checkbox' && (
                                                    <Styled.FilterDropdownCheckbox
                                                        label="Select item"
                                                        hideLabel
                                                        p="none"
                                                        isChecked={!!isItemSelected(item)}
                                                        onChange={() => onChange?.(selectedItemValues)}
                                                    />
                                                )
                                            }
                                            addonRight={_selectionStyle === 'default' && getSelectionIcon(item)}
                                            {...getItemProps({
                                                item,
                                                index: item.index,
                                            })}
                                        >
                                            <ConditionalWrapper condition={item.wrapper} wrapper={item.wrapper}>
                                                {item.displayValue}
                                            </ConditionalWrapper>
                                        </Styled.Item>
                                    ))}
                                </Fragment>
                            ))}
                        </Scrollable>
                        {helpText && (
                            <Box height="52px" mx="sm" mt="xs" mb="xxs">
                                <Text size="sm" contrast="low">
                                    {helpText}
                                </Text>
                            </Box>
                        )}
                        {hasFooter && (
                            <>
                                <DropdownMenu.Divider />
                                <Styled.Footer>
                                    <Level.Left>
                                        <Button variant="ghost" kind="link" onClick={handleClear}>
                                            {clearLabel}
                                        </Button>
                                    </Level.Left>
                                    <Level.Right>
                                        <Button variant="secondary" isRounded size="sm" onClick={handleConfirm}>
                                            {confirmLabel}
                                        </Button>
                                    </Level.Right>
                                </Styled.Footer>
                            </>
                        )}
                        {!!React.Children.count(footer) && (
                            <>
                                <DropdownMenu.Divider />
                                {footer}
                            </>
                        )}
                    </>
                )}
            </DropdownMenu.List>
        </DropdownMenu>
    );
};

FilterDropdownBase.displayName = 'FilterDropdown';

export const FilterDropdown = Object.assign(FilterDropdownBase, { Item: Styled.Item, Footer: Styled.Footer });
