import { useEffect, useState, useRef } from 'react';
import { Timeout } from '../../types';

type UseAutoNavigationProps = {
    /** Props passed to the carousel to enable auto navigation - in milliseconds */
    autoNavigationDelay?: number;
    /** RefObject from the Carousel component */
    carouselContainerRef: React.RefObject<HTMLDivElement>;
    /** State of the current selection from the Carousel component */
    currentSelection: number;
    /** The length of items of the carousel */
    length: number;
    scrollToSlide: (slideNumber: number) => void;
    /** React Dispatch from the Carousel component to update the current selection */
    setCurrentSelection: React.Dispatch<React.SetStateAction<number>>;
    /** React Dispatch from the Carousel component that use to inform the carousel is interacted and we can load lazy items */
    setHasBeenInteracted: React.Dispatch<React.SetStateAction<boolean>>;
};

type UseAutoNavigationReturn = { ariaLive: 'off' | 'polite'; isAutoNavigatingActive: boolean };

const useAutoNavigation = ({
    autoNavigationDelay,
    carouselContainerRef,
    currentSelection,
    length,
    scrollToSlide,
    setCurrentSelection,
    setHasBeenInteracted,
}: UseAutoNavigationProps): UseAutoNavigationReturn => {
    const autoNavigationTimeoutRef = useRef<Timeout | null>(null);
    const [isAutoNavigatingActive, setIsAutoNavigatingActive] = useState(!!autoNavigationDelay);

    const ariaLive = isAutoNavigatingActive ? 'off' : 'polite';

    useEffect(() => {
        setIsAutoNavigatingActive(!!autoNavigationDelay);
    }, [autoNavigationDelay]);

    // Auto navigation
    useEffect(() => {
        const resetAutoNavigationTimeout = () => {
            if (autoNavigationTimeoutRef.current) {
                clearTimeout(autoNavigationTimeoutRef.current);
            }
        };

        resetAutoNavigationTimeout();

        if (isAutoNavigatingActive) {
            autoNavigationTimeoutRef.current = setTimeout(() => {
                const nextSlide = currentSelection === length - 1 ? 0 : currentSelection + 1;

                setCurrentSelection(nextSlide);
                scrollToSlide(nextSlide);
                setHasBeenInteracted(true);
            }, autoNavigationDelay);
        }

        return () => {
            resetAutoNavigationTimeout();
        };
    }, [
        autoNavigationDelay,
        scrollToSlide,
        currentSelection,
        isAutoNavigatingActive,
        length,
        setCurrentSelection,
        setHasBeenInteracted,
    ]);

    // Listen for mouseover/focus events and set hover prop to toggle autoplay
    useEffect(() => {
        const currentCarouselContainer = carouselContainerRef.current;

        const disableAutoNavigation = () => {
            setIsAutoNavigatingActive(false);
        };

        const enableAutoNavigation = () => {
            setIsAutoNavigatingActive(true);
        };

        if (autoNavigationDelay) {
            currentCarouselContainer?.addEventListener('mouseover', disableAutoNavigation);
            currentCarouselContainer?.addEventListener('focusin', disableAutoNavigation);
            currentCarouselContainer?.addEventListener('touchstart', disableAutoNavigation);

            currentCarouselContainer?.addEventListener('mouseleave', enableAutoNavigation);
            currentCarouselContainer?.addEventListener('focusout', enableAutoNavigation);
            currentCarouselContainer?.addEventListener('touchend', enableAutoNavigation);
        }

        return () => {
            currentCarouselContainer?.removeEventListener('mouseover', disableAutoNavigation);
            currentCarouselContainer?.removeEventListener('focusin', disableAutoNavigation);
            currentCarouselContainer?.removeEventListener('touchstart', disableAutoNavigation);

            currentCarouselContainer?.removeEventListener('mouseleave', enableAutoNavigation);
            currentCarouselContainer?.removeEventListener('focusout', enableAutoNavigation);
            currentCarouselContainer?.removeEventListener('touchend', enableAutoNavigation);
        };
    }, [autoNavigationDelay, carouselContainerRef]);

    return { ariaLive, isAutoNavigatingActive };
};

export default useAutoNavigation;
