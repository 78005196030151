import styled from '../../utils/styled';
import { maestroComponent } from '../../utils';
import { Box, BoxProps } from '../Box';

export type ShelfProps = BoxProps;

const StyledShelf = styled(Box)<ShelfProps>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
        'topLeft topCenter topRight'
        'middleLeft middleCenter middleRight'
        'bottomLeft bottomCenter bottomRight';
`;

const ShelfBaseStyles = `
    position: relative;
    display: flex;
`;

const StyledTop = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: topLeft / topLeft / topLeft / topRight;
`;

const Top = maestroComponent<ShelfProps>('Shelf.Top', StyledTop);

const StyledTopLeft = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: topLeft;
    justify-content: flex-start;
    align-items: flex-start;
`;

const TopLeft = maestroComponent<ShelfProps>('Shelf.TopLeft', StyledTopLeft);

const StyledTopCenter = styled<any>(Box)`
    ${ShelfBaseStyles}
    grid-area: topCenter;
    justify-content: center;
    align-items: flex-start;
`;

const TopCenter = maestroComponent<ShelfProps>('Shelf.TopCenter', StyledTopCenter);

const StyledTopRight = styled<any>(Box)`
    ${ShelfBaseStyles}
    grid-area: topRight;
    justify-content: flex-end;
    align-items: flex-start;
`;

const TopRight = maestroComponent<ShelfProps>('Shelf.TopRight', StyledTopRight);

const StyledMiddle = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: middleLeft / middleLeft / middleLeft / middleRight;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Middle = maestroComponent<ShelfProps>('Shelf.Middle', StyledMiddle);

const StyledMiddleLeft = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: middleLeft;
    justify-content: flex-start;
    align-items: center;
`;

const MiddleLeft = maestroComponent<ShelfProps>('Shelf.MiddleLeft', StyledMiddleLeft);

const StyledMiddleCenter = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: middleCenter;
    justify-content: center;
    align-items: center;
`;

const MiddleCenter = maestroComponent<ShelfProps>('Shelf.MiddleCenter', StyledMiddleCenter);

const StyledMiddleRight = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: middleRight;
    justify-content: flex-end;
    align-items: center;
`;

const MiddleRight = maestroComponent<ShelfProps>('Shelf.MiddleRight', StyledMiddleRight);

const StyledLeft = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: topLeft / topLeft / bottomLeft / topLeft;
`;

const Left = maestroComponent<ShelfProps>('Shelf.Left', StyledLeft);

const StyledCenter = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: topCenter / topCenter / bottomCenter / topCenter;
`;

const Center = maestroComponent<ShelfProps>('Shelf.Center', StyledCenter);

const StyledRight = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: topRight / bottomRight / bottomRight / BottomRight;
`;

const Right = maestroComponent<ShelfProps>('Shelf.Right', StyledRight);

const StyledBottom = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: bottomLeft / bottomLeft / bottomLeft / bottomRight;
    justify-content: flex-start;
    align-items: flex-end;
`;

const Bottom = maestroComponent<ShelfProps>('Shelf.Bottom', StyledBottom);

const StyledBottomLeft = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: bottomLeft;
    justify-content: flex-start;
    align-items: flex-end;
`;

const BottomLeft = maestroComponent<ShelfProps>('Shelf.BottomLeft', StyledBottomLeft);

const StyledBottomCenter = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: bottomCenter;
    justify-content: center;
    align-items: flex-end;
`;

const BottomCenter = maestroComponent<ShelfProps>('Shelf.BottomCenter', StyledBottomCenter);

const StyledBottomRight = styled(Box)`
    ${ShelfBaseStyles}
    grid-area: bottomRight;
    justify-content: flex-end;
    align-items: flex-end;
`;

const BottomRight = maestroComponent<ShelfProps>('Shelf.BottomRight', StyledBottomRight);

const compoundComponents = {
    Top,
    TopLeft,
    TopCenter,
    TopRight,
    Middle,
    MiddleLeft,
    MiddleCenter,
    MiddleRight,
    Left,
    Center,
    Right,
    Bottom,
    BottomLeft,
    BottomCenter,
    BottomRight,
};

export const Shelf = maestroComponent<ShelfProps, typeof compoundComponents>('Shelf', StyledShelf, compoundComponents);
