import styled, { keyframes, css } from '../../utils/styled';
import { variant } from '../../utils';
import { ProgressProps, ProgressValue } from './types';

const sizeStyles = variant({
    prop: 'size',
    variants: {
        xs: {
            width: '12px',
            height: '12px',
        },
        sm: {
            width: '18px',
            height: '18px',
        },
        md: {
            width: '30px',
            height: '30px',
        },
        lg: {
            width: '42px',
            height: '42px',
        },
    },
});

const spinKeyframes = keyframes`
    100% {
        transform: rotate(630deg);
    }
`;

const dashLinekeyframes = keyframes`
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0px;
    }
    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px
    }
`;

const spinAnimation = css`
    animation: ${spinKeyframes} 1.4s linear 0s infinite;
`;

const dashLineCircleAnimation = css`
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0px;
    animation: ${dashLinekeyframes} 1.4s ease-in-out 0s infinite;
`;

export const ProgressWrapper = styled.svg<ProgressProps>`
    transform: rotate(270deg);
    ${({ size }) =>
        typeof size === 'number'
            ? css`
                  width: ${size}px;
                  height: ${size}px;
              `
            : sizeStyles};
    ${({ progress }) => progress === 'indeterminate' && spinAnimation}
`;

export const BackCircle = styled.circle`
    opacity: 0.24;
`;

export const ProgressCircle = styled.circle<
    { progress: ProgressValue; transitionDelay: number } & React.SVGProps<SVGCircleElement>
>`
    ${({ progress, transitionDelay }) =>
        progress === 'indeterminate'
            ? dashLineCircleAnimation
            : css`
                  transition: stroke-dashoffset ${transitionDelay}ms ease-in-out;
              `};
`;
