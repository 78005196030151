import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHideOutlined = ({ size, title = 'HideOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4.5 12s3-5.5 7.5-5.5 7.5 5.5 7.5 5.5-3 5.5-7.5 5.5S4.5 12 4.5 12zM9 12a3 3 0 013-3m3 3a3 3 0 01-3 3m-7 4L19 5"
            />
        </g>
    </svg>
);

export default withIcon(SvgHideOutlined);
