import { fontSize, lineHeight, color, MaestroStyledComponent } from '../../utils';
import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { LinkProps } from './types';

const Link: MaestroStyledComponent<LinkProps> = styled(Box)<LinkProps>`
    color: ${({ contrast }: LinkProps): ReturnType<typeof color> | string =>
        contrast ? color(`text.${contrast}`) : 'inherit'};
    font-size: ${({ size }: LinkProps): ReturnType<typeof fontSize> | string => (size ? fontSize(size) : 'inherit')};
    line-height: ${({ size }: LinkProps): ReturnType<typeof fontSize> | string =>
        size ? lineHeight(size) : 'inherit'};
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }

    &:hover,
    &:visited {
        text-decoration: none;
    }
`;

export const Styled = {
    Link,
};
