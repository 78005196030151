import React, { ChangeEvent, ComponentPropsWithoutRef, ReactNode, Ref, useState, useEffect } from 'react';
import { Box, BoxProps, VisuallyHidden } from '../../../primitives';
import { forwardRef } from '../../../system';
import { useRadioGroupContext } from '../RadioGroup/RadioGroupContext';

type RadioRootProps = BoxProps & {
    children?: ReactNode;
    /** Whether or not the radio is checked */
    isChecked?: boolean;
    /** Whether or not the radio is invalid */
    isInvalid?: boolean;
    /** Whether or not the radio is valid */
    isValid?: boolean;
    /** Whether or not the radio is disabled */
    isDisabled?: boolean;
    /** Props to be applied to the wrapper */
    wrapperProps?: ComponentPropsWithoutRef<'label'>;
    /** Ref to be applied to the wrapper */
    wrapperRef?: Ref<HTMLLabelElement>;
};

const Root = forwardRef<RadioRootProps, 'input'>((props, ref) => {
    const {
        children,
        as = 'input',
        value,
        name: nameProp,
        isChecked: isCheckedProp = false,
        isDisabled: isDisabledProp,
        isInvalid,
        onChange,
        className,
        sx,
        wrapperProps,
        wrapperRef,
        ...rest
    } = props;

    const group = useRadioGroupContext();
    const [isChecked, setChecked] = useState(isCheckedProp);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        group?.onChange?.(event);
        onChange?.(event);
        setChecked(event.currentTarget.checked);
    };

    const name = group?.name ?? nameProp;
    const isDisabled = group?.isDisabled ?? isDisabledProp;

    useEffect(() => {
        setChecked(group?.value ? group?.value === value : isCheckedProp);
    }, [group?.value, isCheckedProp, value]);

    return (
        <Box
            as="label"
            ref={wrapperRef}
            className={className}
            data-state={isChecked ? 'checked' : 'unchecked'}
            data-invalid={isInvalid}
            data-disabled={isDisabled}
            sx={sx}
            {...wrapperProps}
        >
            <VisuallyHidden
                as={as}
                ref={ref}
                type="radio"
                name={name}
                value={value}
                checked={isChecked}
                onChange={handleChange}
                disabled={isDisabled}
                {...rest}
            />
            {children}
        </Box>
    );
});

type RadioControlProps = {
    children?: never;
};

const Control = forwardRef<RadioControlProps, 'div'>((props, ref) => {
    const { as = 'div', ...rest } = props;

    return <Box ref={ref} as={as} {...rest} />;
});

type RadioLabelProps = {
    children?: ReactNode;
};

const Label = forwardRef<RadioLabelProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Box ref={ref} {...rest}>
            {children}
        </Box>
    );
});

type RadioDescriptionProps = {
    children?: ReactNode;
};

const Description = forwardRef<RadioDescriptionProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Box ref={ref} {...rest}>
            {children}
        </Box>
    );
});

export const RadioPrimitive = Object.assign({}, { Root, Control, Label, Description });
