import React from 'react';
import { forwardRef } from '../../system';
import { Button } from '../Button';
import { AlertActionProps } from './types';

export const AlertAction = forwardRef<AlertActionProps, 'button'>((props, ref) => {
    const { children, kind = 'link', ...rest } = props;
    return (
        <Button ref={ref} kind={kind} {...rest}>
            {children}
        </Button>
    );
});

AlertAction.displayName = 'Alert.Action';
