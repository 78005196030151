import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Options } from '@popperjs/core';
import { Portal } from '../../../../primitives';
import { forwardRef } from '../../../../system';
import { mergeRefs } from '../../../../utils';
import { Fade } from '../../../Fade';
import { matchWidth } from '../../../../primitives/Popper/modifiers';
import { useAutocompleteDropdown } from './AutocompleteContext';
import * as Styled from './styled';
import { DropdownProps } from './types';

const popperOptions: Partial<Options> = {
    placement: 'bottom-start',
    modifiers: [
        matchWidth,
        {
            name: 'flip',
            options: {
                padding: 8,
            },
        },
    ],
};

export const Dropdown = forwardRef<DropdownProps, 'div'>((props, ref) => {
    const { children, anchorRef, ...rest } = props;
    const [popperElement, setPopperElement] = useState(null);

    const { isOpen } = useAutocompleteDropdown();

    const { styles, attributes, update } = usePopper(anchorRef, popperElement, popperOptions);

    const dropdownRef = mergeRefs(ref, setPopperElement);

    useEffect(() => {
        if (!isOpen) return;
        update?.();
    }, [isOpen, update]);

    return (
        <Portal zIndex={1001}>
            <Fade in={isOpen} timeout={isOpen ? 100 : 0}>
                <Styled.Dropdown
                    ref={dropdownRef}
                    style={{ ...styles.popper, maxHeight: props.maxHeight }}
                    {...attributes.popper}
                    {...rest}
                >
                    {children}
                </Styled.Dropdown>
            </Fade>
        </Portal>
    );
});
