import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgListUnorderedOutlined = ({ size, title = 'ListUnorderedOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M10 13h9v-2h-9v2zm0-7v2h9V6h-9zm0 12h9v-2h-9v2zm-4-5h2v-2H6v2zm0-7v2h2V6H6zm0 12h2v-2H6v2z"
        />
    </svg>
);

export default withIcon(SvgListUnorderedOutlined);
