import styled, { keyframes } from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { palette, variant } from '../../utils/theme';
import { Card } from '../Card';
import { Shelf } from '../../primitives/Shelf';
import { Group } from '../../primitives/Group';
import { FeatureCardProps } from './types';

export const TRANSITION_DURATION = 500;

const openAnimation = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const closeAnimation = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`;
export const FeatureCard = styled(Card)<FeatureCardProps>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const FeatureCardContent = styled(Card.Content)`
    height: 100%;
`;

const featureCardShapeStyles = variant({
    prop: 'shape',
    variants: {
        square: {
            '&:before': {
                paddingTop: '100%',
            },
        },
        rectangle: {
            '&:before': {
                paddingTop: '49.67%',
                minHeight: '150px',
            },
        },
    },
});

export const FeatureCardWrapper = styled(Box)<FeatureCardProps>`
    position: relative;
    width: 100%;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
    }

    ${featureCardShapeStyles};
    ${FeatureCard} {
        background-image: url(${({ backgroundImage }) => backgroundImage});
        background-size: cover;
        background-position: 50% 50%;
    }
`;

export const FeatureCardMedia = styled(Box)`
    background-color: ${palette('neutral.900')};
    box-shadow: 0 2px 4px 0 rgba(20, 39, 57, 0.24);
`;

export const FeatureCardShelf = styled(Shelf)`
    height: 100%;
`;

export const FeatureCardGroup = styled(Group)`
    &.entering {
        animation: ${openAnimation} ${TRANSITION_DURATION}ms ease both;
    }

    &.exiting {
        animation: ${closeAnimation} ${TRANSITION_DURATION}ms ease both;
    }
`;
