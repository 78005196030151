import { FC } from 'react';
import Link from 'next/link';
import { CATEGORY } from 'constants/routes';
import { FeatureWrapper } from 'components/Common';
import { YouTubePlayer, Label, MappedPost } from 'ui';

import styles from './OneUpVideoLesson.module.scss';

interface OneUpVideoLessonProps {
  post: MappedPost;
}

export const OneUpVideoLesson: FC<OneUpVideoLessonProps> = ({ post }) => {
  const {
    title,
    slug,
    categorySlug,
    categoryName,
    accentColor = '#8445D4',
    image,
    video,
  } = post;

  const accentStyle = accentColor ? { backgroundColor: accentColor } : {};

  const OneUpVideoLessonVideo = () => {
    if (!video) return null;

    return <YouTubePlayer image={image} link={video} playButtonSize="large" />;
  };

  return (
    <FeatureWrapper>
      <Link href={`/${slug}`} className={styles.root}>
        <div className={styles.mediaWrapper} style={accentStyle}>
          <div className={styles.mediaItem}>
            <OneUpVideoLessonVideo />
            <Label
              label={categoryName}
              href={`${CATEGORY}/${categorySlug}`}
              accentColour="white"
              className={styles.label}
            />
          </div>
        </div>
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>
      </Link>
    </FeatureWrapper>
  );
};
