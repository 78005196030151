import React, { useContext, MouseEvent } from 'react';
import { forwardRef } from '../../system';
import { ToggleButtonProps } from './types';
import { ToggleButtonGroupContext } from './ToggleButtonGroup';
import { Styled } from './styled';

const isValueSelected = (
    value: string | number | string[] | undefined,
    reference: string | number | string[] | undefined,
): boolean => {
    if (value === 'undefined' || reference === 'undefined') {
        return false;
    }

    if (Array.isArray(reference)) {
        return reference.includes(value as string);
    }

    return value === reference;
};

export const ToggleButton = forwardRef<ToggleButtonProps, 'button'>(
    ({ children, value, size = 'md', onClick, onChange, isSelected, ...props }, ref) => {
        const context = useContext(ToggleButtonGroupContext);

        const handleChange = (event: MouseEvent<HTMLButtonElement>): void => {
            onClick?.(event, value);
            if (event.isDefaultPrevented()) {
                return;
            }

            onChange?.(event, value);
        };

        const isPressed = context.value ? isValueSelected(value, context.value) : isSelected;

        return (
            <Styled.ToggleButton
                ref={ref}
                value={value}
                size={size}
                onClick={handleChange}
                variant="ghost"
                aria-pressed={isPressed ? true : undefined}
                {...props}
            >
                {children}
            </Styled.ToggleButton>
        );
    },
);

ToggleButton.displayName = 'ToggleButton';

export const ToggleIconButton = forwardRef<ToggleButtonProps, 'button'>((props, ref) => (
    <ToggleButton p={0} ref={ref} {...props} />
));

ToggleIconButton.displayName = 'ToggleIconButton';
