import React, { isValidElement } from 'react';
import { filterChildrenByType, findChildByType } from '../../utils';
import { Input } from '../Input';
import { ChipInput } from '../ChipInput';
import { InputGroupProps } from './types';
import {
    InputGroupAddon,
    InputGroupIcon,
    InputGroupIconButton,
    InputGroupButton,
    InputGroupText,
} from './InputGroupAddon';

const InputGroup: React.FC<InputGroupProps> = ({ children, ...props }) => {
    const childrenArray = React.Children.toArray(children);

    const addonClones = filterChildrenByType(childrenArray, [
        InputGroupAddon,
        InputGroupIcon,
        InputGroupIconButton,
        InputGroupButton,
        InputGroupText,
    ]);

    const addonLeft = addonClones.filter((addon) => addon.props.placement === 'left');

    const addonRight = addonClones.filter((addon) => addon.props.placement === 'right');

    const inputElement = findChildByType(childrenArray, [Input, ChipInput]);

    if (!isValidElement(inputElement)) return <></>;

    return React.cloneElement(inputElement, {
        addonLeft,
        addonRight,
        ...props,
    });
};

InputGroup.displayName = 'InputGroup';

export default Object.assign(InputGroup, {
    Addon: InputGroupAddon,
    Icon: InputGroupIcon,
    IconButton: InputGroupIconButton,
    Button: InputGroupButton,
    Text: InputGroupText,
});
