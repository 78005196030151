import { createContext } from 'react';
import { FiltersInternalState, StaticTextInterface } from './types';

export const defaultStaticText: StaticTextInterface = {
    back: 'Back',
    filters: 'Filters',
    clearFilters: 'Clear Filters',
    close: 'Close',
    clearAll: 'Clear All',
};

/**
 * This is just internal context, it doesn't handle filters state
 * it just takes this state from somewhere (redux, context, whatever) and propagate to it's children.
 * This allows us to let users to handle filters state on it's own.
 */
export const FiltersInternalContext = createContext<FiltersInternalState>({
    staticText: defaultStaticText,
});
