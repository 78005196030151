import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgUsersFilled = ({ size, title = 'UsersFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M7.75 14c2.587 0 5.579 1.248 5.743 3.244l.007.173v1.333H2v-1.333C2 15.32 5.089 14 7.75 14zm8.5 0c2.587 0 5.579 1.248 5.743 3.244l.007.173v1.333h-6.5v-1.333l-.007-.173C15.398 16.09 14.36 15.187 13 14.63a8.78 8.78 0 013.249-.63zm-8.5-8a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm8.75 0a3.5 3.5 0 100 7 3.5 3.5 0 000-7z"
        />
    </svg>
);

export default withIcon(SvgUsersFilled);
