import styled from '../../../utils/styled';
import { Box, BoxProps } from '../../../primitives/Box';
import { color, spacing, radius, themeMode } from '../../../utils';
import { Radio } from '../';

export const Control = styled(Radio.Control)((props) => ({
    marginTop: spacing('xxs')(props),
    gridRow: '1/1',
}));

export const Label = styled(Radio.Label)({
    gridRow: '1/1',
    gridColumn: '2/2',
});

export const Description = styled(Radio.Description)({
    gridRow: '2/2',
    gridColumn: '2/-1',
});

export const Addon = styled(Box)({
    display: 'flex',
    gridRow: '1/1',
    gridColumn: '3/-1',
});

export const Content = styled(Box)((props) => ({
    marginTop: spacing('xxs')(props),
    gridRow: '3/3',
    gridColumn: '2/-1',
}));

Content.displayName = 'RadioCard.Content';

export const Card = styled(Radio.Root)<BoxProps>((props) => ({
    userSelect: 'none',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr min-content',
    gridTemplateRows: 'min-content min-content 1fr',
    gap: `0px ${spacing('xs')(props)}`,
    gridAutoFlow: 'column',
    padding: spacing('md')(props),
    borderRadius: radius('base')(props),
    border: `1px solid ${themeMode({ dark: color('neutral.700'), light: color('neutral.300') })(props)}`,
    '&:hover': {
        cursor: 'pointer',
        borderColor: `${themeMode({ dark: color('neutral.50'), light: color('neutral.900') })(props)}`,
    },
    '&[data-state="checked"]': {
        outline: `1px solid ${themeMode({ dark: color('neutral.0'), light: color('neutral.1000') })(props)}`,
        borderColor: `${themeMode({ dark: color('neutral.0'), light: color('neutral.1000') })(props)}`,
    },
    '&[data-disabled="true"]': {
        opacity: 0.5,
        pointerEvents: 'none',
    },
}));
