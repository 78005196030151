import React from 'react';
import { withIcon } from '../../../components/withIcon';

export interface CheckIconProps {
    size: number;
    title: string;
}

const CheckIcon: React.FC<CheckIconProps> = ({ size, title, ...props }: CheckIconProps) => (
    <svg width={size} height={size} viewBox="0 0 24 24" {...props}>
        <title>{title}</title>
        <g fill="currentColor">
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
        </g>
    </svg>
);

export default withIcon(CheckIcon);
