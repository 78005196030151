import React from 'react';
import { Transition } from 'react-transition-group';
import { CaretDownOutlined } from '../../assets/icons';
import { Box, ConditionalWrapper } from '../../primitives';
import { Progress } from '../Progress';
import { forwardRef } from '../../system';
import { warn } from '../../utils';
import * as Styled from './styled';
import { ButtonProps } from './types';
import { getButtonIconSize } from './helpers';

const Button = forwardRef<ButtonProps, 'button'>(
    (
        {
            children,
            as = 'button',
            icon: IconComponent,
            iconRight: IconRightComponent,
            isDisabled = false,
            isDropdown = false,
            isFull = false,
            isLoading = false,
            variant = 'primary',
            kind = 'solid',
            isRounded = false,
            size = 'md',
            type,
            isSelected,
            ...props
        },
        ref,
    ) => {
        const isButton = as === 'button';

        warn({
            condition: Boolean(isSelected),
            message:
                'isSelected prop is deprecated. Components should pass the right aria attribute to enable active styles',
        });

        return (
            <Styled.Button
                ref={ref}
                as={as}
                variant={variant}
                kind={kind}
                size={size}
                isDropdown={isDropdown}
                isFull={isFull}
                isLoading={isLoading}
                shape={isRounded ? 'rounded' : 'default'}
                type={type ?? (isButton ? 'button' : undefined)}
                disabled={isDisabled || isLoading}
                tabIndex={isDisabled || isLoading ? -1 : undefined}
                aria-disabled={isDisabled || isLoading}
                aria-busy={isLoading}
                {...props}
            >
                <ConditionalWrapper
                    condition={isDropdown}
                    wrapper={(children): React.ReactNode => (
                        <>
                            <Box display="flex" />
                            <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">
                                {children}
                            </Box>
                            <Box display="flex">{isDropdown && <Styled.ButtonIconRight as={CaretDownOutlined} />}</Box>
                        </>
                    )}
                >
                    <>
                        {IconComponent && <Styled.ButtonIcon as={IconComponent} size={getButtonIconSize(size)} />}
                        {children && <Styled.ButtonText>{children}</Styled.ButtonText>}
                        {IconRightComponent && (
                            <Styled.ButtonIconRight as={IconRightComponent} size={getButtonIconSize(size)} />
                        )}
                        <Transition in={isLoading} timeout={300} mountOnEnter unmountOnExit>
                            {(state): JSX.Element => (
                                <Styled.ButtonLoader state={state}>
                                    <Progress size="sm" />
                                </Styled.ButtonLoader>
                            )}
                        </Transition>
                    </>
                </ConditionalWrapper>
            </Styled.Button>
        );
    },
);

Button.displayName = 'Button';

export default Object.assign(Button, {});
