import React, { useRef } from 'react';
import styles from '../YouTubePlayer.module.scss';
import { BasicImage } from '../../BasicImage';
import { YouTubePlayerProps } from '../types';
import { PlayVideoButton } from '../../PlayVideoButton';
import { useBreakpoints, useHasMounted } from '../../../hooks';

export type LandrYoutubePlayerProps = Omit<
  YouTubePlayerProps,
  'link' | 'isEmbedded'
> & {
  videoId: string;
};

export const LandrYoutubePlayer: React.FC<LandrYoutubePlayerProps> = ({
  videoId,
  image,
  playOnClick = false,
  onPlayClick,
  playButtonSize,
  withResponsiveButton,
  caption,
}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const hasMounted = useHasMounted();
  const { isBreakpointL } = useBreakpoints();
  const buttonSizeDependsOnBreakpoint = isBreakpointL ? 'large' : 'medium';
  const buttonSize = withResponsiveButton
    ? buttonSizeDependsOnBreakpoint
    : playButtonSize;
  const fullResYouTubePreviewImage = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  const hqResYouTubePreviewImage = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

  const previewImage =
    image && image !== 'undefined' ? image : fullResYouTubePreviewImage;

  const handlePlayButtonClick = () => {
    const videoRef = videoContainerRef.current;
    const iframe = document.createElement('iframe');

    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('allow', 'autoplay');
    iframe.setAttribute(
      'src',
      `https://www.youtube.com/embed/${videoId}?rel=0&amp;autoplay=1&amp;showinfo=0`
    );

    if (videoRef) {
      // set height of the iframe to the preview image height, to force the aspect ratio
      const containerHeight = videoRef.getBoundingClientRect().height;
      iframe.style.height = `${containerHeight}px`;

      // non-react way was deliberately chosen to achieve "autoplay" effect
      videoRef.innerHTML = '';
      videoRef.appendChild(iframe);

      if (onPlayClick) {
        onPlayClick();
      }
    }
  };

  const PlayButton = () => {
    if (!hasMounted) return null;

    return (
      <PlayVideoButton
        onClick={playOnClick ? handlePlayButtonClick : undefined}
        className={styles.playButton}
        size={buttonSize}
      />
    );
  };

  return (
    <div className={styles.player} ref={videoContainerRef}>
      <BasicImage
        src={previewImage}
        fallbackSrc={hqResYouTubePreviewImage}
        alt={caption || 'Preview of youtube video'}
      />
      <PlayButton />
    </div>
  );
};
