import React from 'react';
import { isValidElementType } from 'react-is';
import { forwardRef } from '../../system';
import { NavLinkProps } from './types';
import { useNavContext } from './NavContext';
import * as Styled from './styled';

export const NavLink = forwardRef<NavLinkProps, 'a'>((props, ref) => {
    const { children, as = 'a', icon: IconComponent, isCurrent, ...rest } = props;
    const { size, variant } = useNavContext();
    return (
        <Styled.NavLink
            as={as}
            size={size}
            variant={variant}
            ref={ref}
            aria-current={isCurrent ? true : undefined}
            {...rest}
        >
            {isValidElementType(IconComponent) && <IconComponent aria-hidden="true" focusable="false" mr="xs" />}
            {children}
        </Styled.NavLink>
    );
});

NavLink.displayName = 'Nav.Link';
