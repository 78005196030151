import React from 'react';
import { forwardRef } from '../../system';
import { MediaProps } from './types';
import * as Styled from './styled';

export const Media = forwardRef<MediaProps, 'div'>(
    (
        {
            children,
            gutter = 'md',
            align = 'flex-start',
            centered = null,
            isStacked = false,
            isReversed = false,
            ...props
        },
        ref,
    ) => {
        if (centered !== null) {
            isStacked = centered;
        }
        return (
            <Styled.Media
                ref={ref}
                gutter={gutter}
                align={align}
                centered={centered}
                isStacked={isStacked}
                isReversed={isReversed}
                {...props}
            >
                {children}
            </Styled.Media>
        );
    },
);

Media.displayName = 'Media';

export default Object.assign(Media, {
    Image: Styled.Image,
    Body: Styled.Body,
});
