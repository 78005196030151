import React, { Fragment, isValidElement } from 'react';
import { Omit } from '../themes/types';

export interface EmptyProps {
    // Typescript needs at least one prop otherwise it typing of `onClick` and all react default props won't work
    zFakeProp?: string;
}

/**
 * @deprecated
 * MaestroBaseProps merges Props (base props from component) and React.HTMLProps.
 * But for every prop that enters in conflict, `Props` value prevails
 */
export type MaestroBaseProps<Props> = Props & React.RefAttributes<any> & Omit<React.HTMLAttributes<any>, Props>;

export type MaestroComponent<Props extends Record<string, any>, Compound> = React.FC<MaestroBaseProps<Props>> &
    Compound;

export function maestroComponent<Props = EmptyProps, Compound = Record<string, any>>(
    displayName: string,
    Component: React.ComponentType,
    compoundComponents?: Compound,
): MaestroComponent<Props, Compound> {
    Component.displayName = displayName;

    if (compoundComponents) {
        Component = Object.assign(Component, compoundComponents);
    }

    return Component as any;
}

// MaestroStyledComponent adds forwardedAs prop from styled-components which is not included in the types definition
export type MaestroStyledComponent<Props = Record<string, any>> = React.FC<
    Props & { forwardedAs?: any } & React.RefAttributes<any>
>;

export type JSXElementConstructorWithDisplayName = React.JSXElementConstructor<any> & { displayName?: string };

/**
 * Given a ReactNode, such as from a React Component's `children` prop, finds the
 * first ReactNode with a matching `displayName` including if it is wrapped in fragment.
 * Matching is done with `String.include()`, so if the displayName argument is `Button` it will also
 * match a component with a `displayName` of `Styled.Button`.
 *
 * @export
 * @param {(React.ReactNode | undefined)} children
 * @param {string} [displayName]
 * @return {*}  {(React.ReactNode | undefined)}
 */
export function findChildWithDisplayName(
    children: React.ReactNode | undefined,
    displayName?: string,
): React.ReactNode | undefined {
    const childrenArray = children ? React.Children.toArray(children) : [];
    let result;

    if (children && displayName) {
        for (let i = 0; i < childrenArray.length; i++) {
            const child = childrenArray[i];

            if (!isValidElement(child)) {
                continue;
            }

            const { type } = child;

            if (type === (<Fragment />).type && child.props.children) {
                const deepChild = findChildWithDisplayName(child.props.children, displayName);

                if (deepChild) {
                    result = deepChild;
                    break;
                }
            }

            if (typeof type === 'string') {
                continue;
            }

            // TypeScript will complaing about the lack of `displayName` prop unless
            // we do a type cast.
            if ((type as JSXElementConstructorWithDisplayName)?.displayName?.includes(displayName)) {
                result = child;
                break;
            }
        }
    }

    return result;
}

/**
 * Given a ReactNode, such as from a React Component's `children` prop, finds all
 * ReactNode with a matching `displayName` including if those are wrapped in fragment.
 *
 * @export
 * @param {(React.ReactNode | undefined)} children
 * @param {string} [displayName]
 * @return {*}  {React.ReactNode[]}
 */
export function findAllChildrenWithDisplayName(
    children: React.ReactNode | undefined,
    displayName?: string,
): React.ReactNode[] {
    const childrenArray = children ? React.Children.toArray(children) : [];

    if (children && displayName) {
        const flatten = <T,>(acc, child): T[] => {
            if (Array.isArray(child)) {
                return [...acc, ...child.reduce(flatten)];
            }

            return [...acc, child];
        };

        return childrenArray
            .map((child): React.ReactNode | React.ReactNode[] | undefined => {
                if (!isValidElement(child)) {
                    return null;
                }

                const { type } = child;

                if (type === (<Fragment />).type && child.props.children) {
                    return findAllChildrenWithDisplayName(React.Children.toArray(child.props.children), displayName);
                }

                if (typeof type === 'string') {
                    return null;
                }

                // TypeScript will complaing about the lack of `displayName` prop unless
                // we do a type cast.
                if ((type as JSXElementConstructorWithDisplayName)?.displayName?.includes(displayName)) {
                    return child;
                }

                return null;
            })
            .filter((child) => Boolean(child))
            .reduce<React.ReactNode[]>(flatten, []);
    }

    return [];
}

export const getClonedChild = (
    children: React.ReactNode | undefined,
    displayName: string,
    props?: any,
): React.ReactNode | null => {
    if (displayName && children) {
        const child = findChildWithDisplayName(children, displayName);

        if (isValidElement(child)) {
            return React.cloneElement(child, props && { ...props });
        }
    }

    return null;
};

export const getClonedChildWithMergedProps = (
    children: React.ReactNode | undefined,
    displayName: string,
    props: any,
): React.ReactNode | null => {
    if (displayName && children) {
        const child = findChildWithDisplayName(children, displayName);

        if (isValidElement(child)) {
            return React.cloneElement(child, { ...props, ...child.props });
        }
    }

    return null;
};

export const getDisplayName = (Component: any): string => Component?.type?.displayName;
