import { useEffect, RefObject, useState } from 'react';

export type UseOverflowMenuProps = {
    containerRef: RefObject<Element>;
};

export const useOverflowNav = (props: UseOverflowMenuProps) => {
    const { containerRef } = props;

    const [overflowItems, setOverflowItems] = useState<string[]>([]);

    const handleIntersection: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
            const { itemId } = (entry.target as HTMLElement).dataset;

            if (!itemId) return;

            if (!entry.isIntersecting) {
                setOverflowItems((prevState) => [...prevState, itemId]);
            } else {
                setOverflowItems((prevState) => prevState.filter((item) => item !== itemId));
            }
        });
    };

    useEffect(() => {
        const root = containerRef.current;
        const hasBrowserSupport = !!window.IntersectionObserver;

        if (!hasBrowserSupport || !root) return;

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 1,
            root,
            rootMargin: '0px -48px 0px 0px',
        });

        Array.from(containerRef.current.children).forEach((item: Element) => {
            observer.observe(item);
        });

        return () => observer.disconnect();
    }, [containerRef]);

    return overflowItems;
};
