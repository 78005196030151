import React from 'react';
import { maestroComponent } from '../../utils';
import { Popper } from '../../primitives/Popper';
import { Fade } from '../Fade';
import { PopoverBaseProps } from './types';
import * as Styled from './styled';

const Component: React.FC<PopoverBaseProps> = ({
    children,
    hasArrow = false,
    isOpen = false,
    onClose,
    onOpen,
    placement = 'bottom',
    offset = [0, 16],
    anchorRef,
    triggerRef,
    size = 'auto',
    disablePortal,
    usePortal = true,
    onClickOutside,
    isLazy,
    zIndex = 1000,
    ...props
}) => {
    return (
        <Popper
            onClose={onClose}
            onOpen={onOpen}
            isOpen={isOpen}
            anchorRef={anchorRef}
            triggerRef={triggerRef}
            transitionComponent={Fade}
            popperOptions={{
                placement,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset,
                        },
                    },
                ],
            }}
            disablePortal={disablePortal}
            usePortal={usePortal}
            onClickOutside={onClickOutside}
            zIndex={zIndex}
            isLazy={isLazy}
        >
            <Styled.Container role="dialog" size={size} {...props}>
                {children}
                {hasArrow && <Popper.Arrow />}
            </Styled.Container>
        </Popper>
    );
};

Component.displayName = 'PopoverBase';

export const PopoverBase = maestroComponent<PopoverBaseProps>('PopoverBase', Component);
