import React, { ReactNode, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

type DataItem<T> = {
    id: string;
} & T;

type ChildComponentProps<T> = {
    item: T;
    index: number;
    isDragging: boolean;
};

interface DragAndDropListProps<T> {
    children: (props: ChildComponentProps<T>) => ReactNode;
}

export function useDragAndDrop<T>(
    data?: DataItem<T>[],
    options?: { isDragDisabled?: boolean; onDragEnd?(data: DataItem<T>[]): void },
) {
    const [items, setItems] = useState<DataItem<T>[]>();
    const [isDragDisabled, setIsDragDisabled] = useState(options?.isDragDisabled || false);

    useEffect(() => {
        if (data) {
            setItems(data);
        }
    }, [data]);

    function reorder(items: DataItem<T>[], startIndex: number, endIndex: number) {
        const orderedItems = [...items];
        const [removed] = orderedItems.splice(startIndex, 1);
        orderedItems.splice(endIndex, 0, removed);

        return orderedItems;
    }

    function onDragEnd(result: DropResult) {
        if (!result.destination || result.destination.index === result.source.index || !items) {
            // Do not drag if there is no destination, items, or the source and destination are the same
            return;
        }
        const reorderedItems = reorder(items, result.source.index, result.destination.index);
        setItems([...reorderedItems]);
        options?.onDragEnd?.(reorderedItems);
    }

    const DragAndDropList = ({ children }: DragAndDropListProps<T>) => {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {items?.map((item, idx) => {
                                const draggableId = item.id.toString();

                                return (
                                    <Draggable
                                        index={idx}
                                        draggableId={draggableId}
                                        key={`${draggableId}-${idx}`}
                                        isDragDisabled={isDragDisabled}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                aria-label={`${idx}`}
                                                data-testid={`draggable-item-${draggableId}`}
                                            >
                                                {children({
                                                    item,
                                                    index: idx,
                                                    isDragging: snapshot.isDragging,
                                                })}
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    return {
        DragAndDropList,
        items,
        setItems,
        isDragDisabled,
        setIsDragDisabled,
    };
}
