import React, { useCallback, useState, useEffect, isValidElement, cloneElement } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { Shelf } from '../../primitives';
import { useBreakpoint } from '../../hooks';
import { Group } from '../../primitives/Group';
import { List } from '../List';
import { MeatballsFilled } from '../../assets/icons';
import { maestroComponent } from '../../utils';
import { Popover } from '../Popover';
import * as Styled from './styled';
import { FeatureCardProps } from './types';
import { Media } from './FeatureCardMedia';
import { Action } from './FeatureCardAction';
import { SubAction } from './FeatureCardSubAction';

const FeatureCard: React.FC<FeatureCardProps> = ({
    children,
    shape = 'rectangle',
    badges = [],
    backgroundColor = 'neutral.900',
    actions = [],
    subActions = null,
    media = null,
    isSelected,
    defaultActionCount = 1,
    ...props
}: React.PropsWithChildren<FeatureCardProps>) => {
    const [areActionsVisible, setAreActionsVisible] = useState(false);
    const [areSubActionsVisible, setAreSubActionsVisible] = useState(false);
    const isMobile = useBreakpoint('md');

    const handleOnMouseLeave = useCallback(() => {
        !areSubActionsVisible && setAreActionsVisible(false);
    }, [areSubActionsVisible]);

    const handleSubActionsClickOutside = (): void => {
        !isMobile && setAreActionsVisible(false);
        setAreSubActionsVisible(false);
    };

    useEffect(() => {
        (isSelected || isMobile) && setAreActionsVisible(true);

        return (): void => setAreActionsVisible(false);
    }, [isSelected, isMobile]);

    defaultActionCount = Math.max(0, Math.min(defaultActionCount, actions.length)); // bind action count between 0 and actions.length
    const defaultActions = actions.slice(defaultActionCount);

    return (
        <Styled.FeatureCardWrapper
            {...props}
            shape={shape}
            onMouseEnter={(): void => setAreActionsVisible(true)}
            onMouseLeave={handleOnMouseLeave}
        >
            <Styled.FeatureCard size="sm" backgroundColor={backgroundColor}>
                <Styled.FeatureCardContent>
                    <Styled.FeatureCardShelf>
                        <Shelf.TopLeft>
                            {!!badges.length &&
                                badges.map(
                                    (badge, index) =>
                                        isValidElement(badge) &&
                                        cloneElement(badge, {
                                            key: index,
                                            isRounded: true,
                                            mr: 'xxs',
                                            ...badge.props,
                                        }),
                                )}
                        </Shelf.TopLeft>
                        <Shelf.TopRight>{media}</Shelf.TopRight>
                        <Shelf.Middle>{children}</Shelf.Middle>
                        <Shelf.Bottom>
                            {!!actions.length && (
                                <>
                                    {defaultActionCount > 0 && (
                                        <Group gutter="sm">
                                            {actions.slice(0, defaultActionCount).map(
                                                (action, index): JSX.Element => (
                                                    <Group.Item key={index}>{action}</Group.Item>
                                                ),
                                            )}
                                        </Group>
                                    )}
                                    {(actions.length > 1 || (defaultActionCount === 0 && actions.length > 0)) && (
                                        <Transition
                                            in={areActionsVisible}
                                            timeout={Styled.TRANSITION_DURATION}
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            {(state: TransitionStatus): JSX.Element => (
                                                <Styled.FeatureCardGroup
                                                    gutter="xs"
                                                    ml={defaultActionCount > 0 ? 'xs' : undefined}
                                                    className={state}
                                                >
                                                    {defaultActions.map((action, index) => (
                                                        <Group.Item key={index}>{action}</Group.Item>
                                                    ))}
                                                    {subActions && (
                                                        <Group.Item>
                                                            <Popover
                                                                placement="bottom-start"
                                                                onClose={handleSubActionsClickOutside}
                                                                px={0}
                                                            >
                                                                <Popover.Trigger>
                                                                    <Action
                                                                        icon={MeatballsFilled}
                                                                        aria-haspopup="true"
                                                                        onClick={(): void =>
                                                                            setAreSubActionsVisible(true)
                                                                        }
                                                                    />
                                                                </Popover.Trigger>
                                                                <Popover.Content>
                                                                    {Array.isArray(subActions) ? (
                                                                        <List>
                                                                            {subActions.map((action, index) => (
                                                                                <List.Item key={index}>
                                                                                    {action}
                                                                                </List.Item>
                                                                            ))}
                                                                        </List>
                                                                    ) : (
                                                                        subActions
                                                                    )}
                                                                </Popover.Content>
                                                            </Popover>
                                                        </Group.Item>
                                                    )}
                                                </Styled.FeatureCardGroup>
                                            )}
                                        </Transition>
                                    )}
                                </>
                            )}
                        </Shelf.Bottom>
                    </Styled.FeatureCardShelf>
                </Styled.FeatureCardContent>
            </Styled.FeatureCard>
        </Styled.FeatureCardWrapper>
    );
};

const compoundComponents = {
    Media,
    Action,
    SubAction,
};

export default maestroComponent<FeatureCardProps, typeof compoundComponents>(
    'FeatureCard',
    FeatureCard,
    compoundComponents,
);
