import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgDeliveryOutlined = ({ size, title = 'DeliveryOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M19 13V8c0-.552-.418-1-.933-1H5.933C5.418 7 5 7.448 5 8v11c0 .552.418 1 .933 1H10.6M8 5v2m-3 4.5h14m-6 6.983L16 20l3-4.639M16 5v2"
        />
    </svg>
);

export default withIcon(SvgDeliveryOutlined);
