import React from 'react';
import { Group } from '../../../primitives/Group';
import { TruncatedPaginationProps } from '../types';
import PaginationButton from './PaginationButton';

const Truncated = ({
    buttonComponent,
    startRange,
    endRange,
    currentPage,
    buttonSize,
    shape,
    goToPage,
}: TruncatedPaginationProps) => (
    <>
        {startRange.map((page, i) => {
            return (
                <PaginationButton
                    buttonComponent={buttonComponent}
                    key={`${i}${page}`}
                    isSelected={currentPage === page}
                    buttonSize={buttonSize}
                    shape={shape}
                    page={page}
                    onClick={() => goToPage(page as number)}
                    itemType="number"
                >
                    {page}
                </PaginationButton>
            );
        })}
        <Group.Item>
            <PaginationButton
                buttonComponent={buttonComponent}
                buttonSize={buttonSize}
                shape={shape}
                itemType="ellipsis"
            >
                ...
            </PaginationButton>
        </Group.Item>
        {endRange.map((page, i) => {
            return (
                <PaginationButton
                    buttonComponent={buttonComponent}
                    key={`${i}${page}`}
                    isSelected={currentPage === page}
                    buttonSize={buttonSize}
                    shape={shape}
                    page={page}
                    onClick={() => goToPage(page as number)}
                    itemType="number"
                >
                    {page}
                </PaginationButton>
            );
        })}
    </>
);

export default Truncated;
