import React, { useContext, useRef, useState } from 'react';
import { Notification, StyledNotification, NotificationProps } from '../../components/Notification';
import styled from '../../utils/styled';
import { zIndex } from '../../utils/theme';
import { spacing } from '../../utils';
import { Portal } from '../../primitives/Portal';

export const BASE_NOTIFICATION_TIMEOUT = 3000;

export interface NotificationConfigType extends Omit<NotificationProps, 'kind'> {
    isPersistent?: boolean;
    content: React.ReactNode;
    timeout?: number;
}

export type NotificationsMap = Map<number, NotificationConfigType>;

export interface NotificationContextType {
    addNotification: (notification: NotificationConfigType) => void;
}

export const NotificationContext = React.createContext<NotificationContextType>({
    addNotification: () => undefined,
});

export const NotificationWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column-reverse;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${zIndex('alert')};

    ${StyledNotification} {
        pointer-events: all;
        margin-top: ${spacing('sm')};
    }
`;

export type NotificationProviderProps = {
    baseNotificationTimeout?: number;
    notificationsLimit?: number;
};

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
    baseNotificationTimeout = BASE_NOTIFICATION_TIMEOUT,
    notificationsLimit,
    children,
}) => {
    const [notificationsMap, setNotifications] = useState<NotificationsMap>(new Map());
    const notificationCounter = useRef(0);

    const incrementCounter = (): void => {
        notificationCounter.current += 1;
    };

    const decrementCounter = (): void => {
        notificationCounter.current -= 1;
    };

    const closeNotification = (key: number, shouldNotDecrementCounter = false): void => {
        const notification = notificationsMap.get(key);

        notification && notification.onClose && notification.onClose();

        notificationsMap.delete(key);
        !shouldNotDecrementCounter && decrementCounter();

        setNotifications(new Map(notificationsMap));
    };

    const addNotification = (notification: NotificationConfigType): void => {
        const isReachedNotificationLimit = notificationsLimit && notificationsMap.size === notificationsLimit;

        incrementCounter();

        const { current } = notificationCounter;
        const timeout = notification.timeout || baseNotificationTimeout;

        notificationsMap.set(current, {
            ...notification,
            timeout,
        });

        if (isReachedNotificationLimit) {
            const firstKeyValueOfTheMap = Array.from(notificationsMap.keys())[0];

            closeNotification(firstKeyValueOfTheMap, true);
        }

        setNotifications(new Map(notificationsMap));
    };

    return (
        <NotificationContext.Provider value={{ addNotification }}>
            <Portal zIndex={2000}>
                <NotificationWrapper>
                    {[...notificationsMap].map(([key, { isPersistent, content, onClose, ...rest }]) => (
                        <Notification
                            key={key}
                            kind={isPersistent ? 'persistent' : 'temporary'}
                            onClose={(): void => {
                                closeNotification(key);
                                onClose?.();
                            }}
                            {...rest}
                        >
                            {content}
                        </Notification>
                    ))}
                </NotificationWrapper>
            </Portal>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = (): { notify: (notification: NotificationConfigType) => void } => {
    const notificationContext = useContext<NotificationContextType>(NotificationContext);

    const notify = notificationContext.addNotification;

    return { notify };
};
