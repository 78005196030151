import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgDistribution = ({ size, title = 'Distribution', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#2C3C4D"
            d="M24 1C11.317 1 1 11.318 1 24s10.317 23 23 23 23-10.318 23-23S36.683 1 24 1zm0 28c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"
        />
        <path
            fill="#435261"
            d="M12.362 4.174l9 15.594c.051-.03.093-.063.14-.098.915-.67 1.688-.65 2.498-.67V1c-3.954.015-8.03 1.048-11.638 3.174zm23.276 39.652l-9-15.594c-.051.03-.093.063-.14.098-.915.67-1.688.651-2.498.67v18c3.954-.015 8.03-1.048 11.638-3.174z"
        />
        <path
            fill="#F9FAFB"
            d="M24 32c-4.371 0-7.928-3.589-7.928-8S19.63 16 24 16s7.928 3.589 7.928 8S28.37 32 24 32zm0-13c-2.717 0-4.928 2.243-4.928 5s2.211 5 4.928 5 4.928-2.243 4.928-5-2.211-5-4.928-5z"
        />
        <path fill="#32CBCB" d="M47 39a8 8 0 10-16.001 0A8 8 0 0047 39z" />
        <path
            stroke="#2C3C4D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M41.893 39.125H36h5.893zM39.375 36l3 3.125-3-3.125zm0 6.25l3-3.125-3 3.125z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgDistribution);
