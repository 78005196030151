import { FC, PropsWithChildren } from 'react';

import styles from './FeatureWrapper.module.scss';

export interface FeatureWrapperProps {
  tag?: 'div' | 'article';
}

export const FeatureWrapper = ({
  children,
  tag = 'article',
}: PropsWithChildren<FeatureWrapperProps>) => {
  const WrapperTag = tag as 'div' | 'article';

  return (
    <WrapperTag className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.sizeWrapper}>{children}</div>
      </div>
    </WrapperTag>
  );
};
