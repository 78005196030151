import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLoopOutlined = ({ size, title = 'LoopOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <circle
                cx={7.5}
                cy={16.5}
                r={2}
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12.5 19.5a7 7 0 100-14c-2.636 0-4.828 1.641-6 4"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M6.5 4.5v5h5"
            />
        </g>
    </svg>
);

export default withIcon(SvgLoopOutlined);
