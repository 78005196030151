import { FC } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { CATEGORY } from 'constants/routes';
import { YouTubePlayer, BasicImage, Label, MappedPost } from 'ui';
import styles from './DoubleLessonItem.module.scss';

export interface DoubleLessonItemProps {
  post: MappedPost;
  isTransparent?: boolean;
}

export const DoubleLessonItem: FC<DoubleLessonItemProps> = ({
  post,
  isTransparent,
}) => {
  const { title, slug, categorySlug, categoryName, accentColor, image, video } =
    post;

  const accentStyle = accentColor ? { backgroundColor: accentColor } : {};

  const VideoItem = () => {
    if (!video && image) {
      return <BasicImage src={image} alt={title} />;
    }

    if (video) {
      return <YouTubePlayer image={image} link={video} />;
    }

    return null;
  };

  return (
    <article className={styles.root} style={accentStyle}>
      <Link href={`/${slug}`} className={styles.link}>
        <div className={styles.details}>
          <Label
            label={categoryName}
            href={`${CATEGORY}/${categorySlug}`}
            accentColour="white"
            inlineAccentColour={accentColor}
            className={styles.label}
          />
          <h2
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
        </div>
        <div
          className={cn(styles.mediaRoot, {
            [styles.fullSize]: isTransparent,
          })}
        >
          <div className={styles.mediaWrapper}>
            <div className={styles.mediaItem}>
              <VideoItem />
            </div>
          </div>
        </div>
      </Link>
    </article>
  );
};
