import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgDownloadCircleFilled = ({ size, title = 'DownloadCircleFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm.75-7.56V7.75a.75.75 0 10-1.5 0v6.69l-2.22-2.22a.75.75 0 00-1.06 1.06l3.5 3.5a.75.75 0 001.06 0l3.5-3.5a.75.75 0 10-1.06-1.06l-2.22 2.22z"
            />
        </g>
    </svg>
);

export default withIcon(SvgDownloadCircleFilled);
