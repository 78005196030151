import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPackOutlined = ({ size, title = 'PackOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            transform="translate(4 4)"
        >
            <path d="M9.5.5V13" />
            <circle cx={7} cy={13} r={2.5} />
            <path d="M.5 1.5h6m-6 3h6m-6 3h6m3-6.056A3.484 3.484 0 0012 2.5 3.5 3.5 0 0115.5 6a3.484 3.484 0 01-.051.5A3.482 3.482 0 0013 5.5 3.5 3.5 0 019.5 2v-.556z" />
        </g>
    </svg>
);

export default withIcon(SvgPackOutlined);
