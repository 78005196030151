import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCameraAddFilled = ({ size, title = 'CameraAddFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M12 17H4.25C3.56 17 3 16.368 3 15.667V8.175c0-.702.56-1.27 1.25-1.27h2.813L7.76 5.84c.305-.463.997-.84 1.557-.84h1.74c.556 0 1.246.367 1.557.841l.698 1.064h3.438c.69 0 1.25.568 1.25 1.27V11"
        />
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M12.5 11.75a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
            clipRule="evenodd"
        />
        <path fill="currentColor" stroke="currentColor" d="M15 10a.5.5 0 110-1 .5.5 0 010 1z" />
        <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M18 20v-6m-3 3h6" />
    </svg>
);

export default withIcon(SvgCameraAddFilled);
