import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPreserverOutlined = ({ size, title = 'PreserverOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <g
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                transform="translate(4 4)"
            >
                <circle cx={8} cy={8} r={7.5} />
                <circle cx={8} cy={8} r={3.5} />
                <path d="M10.47 5.53L13.3 2.7m-2.83 7.77l2.83 2.83m-7.77-2.83L2.7 13.3m2.83-7.77L2.7 2.7" />
            </g>
        </g>
    </svg>
);

export default withIcon(SvgPreserverOutlined);
