import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProviderOutlined = ({ size, title = 'ProviderOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <g transform="translate(4 2.5)">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.438 11.396C8.426 11.132 7.406 11 6.646 11 4.428 11 0 12.126 0 14.361v1.68"
                />
                <circle cx={6.53} cy={3.53} r={3.53} />
            </g>
            <g strokeLinecap="round" strokeLinejoin="round" transform="translate(12.5 8.5)">
                <path d="M4.333 0v10.833" />
                <circle cx={2.17} cy={10.83} r={2.17} />
                <path d="M4.333.818a3.02 3.02 0 002.167.915 3.033 3.033 0 013.033 3.034 3.02 3.02 0 01-.044.433 3.018 3.018 0 00-2.122-.867A3.033 3.033 0 014.333 1.3V.818z" />
            </g>
        </g>
    </svg>
);

export default withIcon(SvgProviderOutlined);
