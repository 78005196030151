import React, { cloneElement } from 'react';
import { Button, getButtonIconSize } from '../Button';
import { Tooltip } from '../Tooltip';
import { ConditionalWrapper } from '../../primitives/ConditionalWrapper';
import { forwardRef } from '../../system';
import { IconButtonProps } from './types';

export const IconButton = forwardRef<IconButtonProps, 'button'>(
    ({ label = '', children, as = 'button', hideTooltip = false, icon: Icon, size = 'md', ...props }, ref) => {
        const iconElement = <Icon /> || children;

        return (
            <ConditionalWrapper
                condition={!hideTooltip && !!label}
                wrapper={(children): React.ReactNode => (
                    <Tooltip content={label} position="top" hasArrow>
                        <React.Fragment>{children}</React.Fragment>
                    </Tooltip>
                )}
            >
                <Button p={0} as={as} aria-label={label} ref={ref} size={size} {...props}>
                    {cloneElement(iconElement, { size: getButtonIconSize(size) })}
                </Button>
            </ConditionalWrapper>
        );
    },
);

IconButton.displayName = 'IconButton';
