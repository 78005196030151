import { useToggle } from '../useToggle/useToggle';

export function useVisible(initialState = false): {
    visible: boolean;
    show: () => void;
    hide: () => void;
    toggle: () => void;
} {
    const { isActive: visible, activate: show, deactivate: hide, toggle } = useToggle(initialState);

    return { visible, show, hide, toggle };
}
