import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgNetwork = ({ size, title = 'Network', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#142739"
            d="M30.367 24.097L19.53 18l10.838-6.097a.75.75 0 00-.735-1.306L18.75 16.718V4.5a.75.75 0 10-1.5 0v12.218L6.368 10.597a.75.75 0 00-.735 1.306L16.47 18 5.633 24.097a.75.75 0 00.734 1.306l10.883-6.121V31.5a.75.75 0 101.5 0V19.282l10.883 6.121a.75.75 0 00.736-1.306h-.002z"
        />
        <path fill="#32CBCB" d="M30 15a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" />
        <path fill="#142739" d="M30 25.5a.75.75 0 01-.75-.75v-13.5a.75.75 0 111.5 0v13.5a.75.75 0 01-.75.75z" />
        <path fill="#435261" d="M30 28.5a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" />
        <path
            fill="#142739"
            d="M18 32.25a.75.75 0 01-.368-1.403l12-6.75a.75.75 0 01.735 1.306l-12 6.75a.74.74 0 01-.367.097z"
        />
        <path fill="#32CBCB" d="M18 35.25a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" />
        <path fill="#435261" d="M18 21.75a3.75 3.75 0 110-7.5 3.75 3.75 0 010 7.5z" />
        <path
            fill="#142739"
            d="M18 32.25a.74.74 0 01-.367-.097l-12-6.75a.75.75 0 01.735-1.306l12 6.75A.75.75 0 0118 32.25z"
        />
        <path fill="#435261" d="M6 28.5A3.75 3.75 0 106 21a3.75 3.75 0 000 7.5z" />
        <path fill="#142739" d="M6 25.5a.75.75 0 01-.75-.75v-13.5a.75.75 0 111.5 0v13.5a.75.75 0 01-.75.75z" />
        <path fill="#2DB6B6" d="M6 15a3.75 3.75 0 100-7.5A3.75 3.75 0 006 15z" />
        <path fill="#142739" d="M6 12a.75.75 0 01-.368-1.403l12-6.75a.75.75 0 11.735 1.306l-12 6.75A.74.74 0 016 12z" />
        <path fill="#435261" d="M18 8.25a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" />
        <path
            fill="#142739"
            d="M27.18 8.803l-8.813-4.956a.75.75 0 00-.735 1.306l8.813 4.958a3.72 3.72 0 01.734-1.308z"
        />
    </svg>
);

export default withIcon(SvgNetwork);
