export const homepageUrls = [
  {
    href: 'fr/',
    locale: 'fr_FR',
    id: 'fr',
  },
  {
    href: 'de/',
    locale: 'de_DE',
    id: 'de',
  },
  {
    href: 'it/',
    locale: 'it_IT',
    id: 'it',
  },
  {
    href: 'ja/',
    locale: 'ja',
    id: 'ja',
  },
  {
    href: 'pt-br/',
    locale: 'pt_BR',
    id: 'pt-br',
  },
  {
    href: 'es/',
    locale: 'es_ES',
    id: 'es',
  },
];
