import React from 'react';
import { Group } from '../../primitives/Group';
import { CloseOutlined } from '../../assets/icons';
import { Button, ButtonProps } from '../Button';
import { OnRemoveFilterFunction, NormalizedAppliedFilters } from './types';
import { SelectedFilterItem } from './styled';

interface SelectedFiltersListProps {
    onRemoveFilter: OnRemoveFilterFunction;
    onClearAll: () => void;
    appliedFilters: NormalizedAppliedFilters;
    clearAllText?: string;
    clearAllButtonProps?: ButtonProps;
}

/**
 * Renders selected filters list.
 *
 * @param appliedFilters the main source of selecred filters
 *
 * @interface
 * ```
 * {
 *      field_of_filter: {
 *          code: {
 *              code: 'filter_code',
 *              name: 'Filter Name',
 *          },
 *      },
 * }
 * ```
 * @param clearAllText "text" for "clear all" Button component
 * @param onClearAll function that will handle onClick event on "clear all" Button
 * @param onRemoveFilter function called when user presses
 * X-button on any filter from selected filters list
 * @param clearAllButtonProps Button component props
 */
export const SelectedFiltersList: React.FC<SelectedFiltersListProps> = ({
    clearAllText = 'Clear All',
    appliedFilters,
    onRemoveFilter,
    onClearAll,
    clearAllButtonProps,
}) => {
    const hasSelectedFilters = Object.keys(appliedFilters).length !== 0;

    if (!hasSelectedFilters) {
        return null;
    }

    return (
        <Group.Item>
            <Group direction="horizontal" gutter="xs">
                {Object.keys(appliedFilters).map((filterField) => {
                    return Object.values(appliedFilters[filterField]).map((filter) => {
                        return (
                            <Group.Item key={filter.code}>
                                <SelectedFilterItem
                                    iconRight={() => <CloseOutlined size="sm" />}
                                    size="sm"
                                    isRounded
                                    variant="ghost"
                                    onClick={() => {
                                        onRemoveFilter({ field: filterField, code: filter.code });
                                    }}
                                >
                                    {filter.name}
                                </SelectedFilterItem>
                            </Group.Item>
                        );
                    });
                })}
                <Group.Item>
                    <Button
                        icon={() => <CloseOutlined size="sm" />}
                        size="sm"
                        kind="minimal"
                        variant="secondary"
                        onClick={onClearAll}
                        {...clearAllButtonProps}
                    >
                        {clearAllText}
                    </Button>
                </Group.Item>
            </Group>
        </Group.Item>
    );
};
