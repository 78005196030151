import React, { useState, useEffect, useRef, useMemo, useCallback, cloneElement, useContext } from 'react';
import { useGesture } from 'react-use-gesture';
import { nanoid } from 'nanoid/non-secure';
import { scrollIntoView } from '../../utils';
import styled from '../../utils/styled';
import { Skeleton } from '../Skeleton';
import { ArrowBackOutlined, ArrowForwardOutlined } from '../../assets/icons';
import { useDebouncedCallback } from '../../hooks';
import { ResponsiveContext, ResponsiveState } from '../../utils/responsive';
import { useEnhancedEffect } from '../../utils/useEnhancedEffect';
import { useBreakpoint } from '../../hooks';
import { IconButton } from '../IconButton';
import { getItemsPerPage } from './helpers';
import { Styled } from './styled';
import { CarouselProps, ArrowOptions, ArrowButtonProps, ArrowsVisibilityState } from './types';
import DotNavigation from './components/DotNavigation';
import useAutoNavigation from './useAutoNavigation';
import getSuggestedCurrentSlide from './helpers/getSuggestedCurrentSlide';

const arrows: ArrowOptions[] = [
    { direction: 'left', key: 'ArrowLeft' },
    { direction: 'right', key: 'ArrowRight' },
];

const ArrowButton = styled(IconButton).attrs(({ side }: ArrowButtonProps) => ({
    hideTooltip: true,
    variant: 'secondary',
    kind: 'solid',
    isRounded: true,
    'data-testid': `arrow-${side}`,
    icon: side === 'left' ? ArrowBackOutlined : ArrowForwardOutlined,
}))<ArrowButtonProps>`
    transition: all 0.5s ease;
`;

export const Carousel: React.FC<CarouselProps> = ({
    items,
    slidesPerPage,
    onNavigate,
    gutter,
    arrowOffset,
    arrowPosition = 'outside',
    hideArrows = false,
    itemsOffset,
    keyboardNavigation,
    scrollToSelection,
    selectionEnabled = true,
    initialSelection = 0,
    onSlideSelected,
    lazyloading = true,
    dotPosition,
    dotSize = 'xxl',
    autoNavigationDelay,
    ...props
}) => {
    const carouselContainerRef = useRef<HTMLDivElement>(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const { breakpoints } = useContext<ResponsiveState>(ResponsiveContext);

    // LOAD when interact
    const [hasBeenInteracted, setHasBeenInteracted] = useState(false);

    // Capture first interaction
    useEffect(() => {
        const currentCarouselContainer = carouselContainerRef.current;

        if (!currentCarouselContainer) {
            return;
        }

        function handleBeenInteracted(): void {
            setHasBeenInteracted(true);

            currentCarouselContainer?.removeEventListener('mouseover', handleBeenInteracted);
            currentCarouselContainer?.removeEventListener('touchstart', handleBeenInteracted);
        }

        currentCarouselContainer.addEventListener('mouseover', handleBeenInteracted);
        currentCarouselContainer.addEventListener('touchstart', handleBeenInteracted);

        return (): void => {
            currentCarouselContainer.removeEventListener('mouseover', handleBeenInteracted);
            currentCarouselContainer.removeEventListener('touchstart', handleBeenInteracted);
        };
    }, []);

    const [currentSlide, setCurrentSlide] = useState(0); // Keeps track of which slide is left aligned in carousel
    const [currentSelection, setCurrentSelection] = useState<number>(initialSelection); // Keeps track of which slide is selected

    const [arrowsVisibleState, setArrowsVisibleState] = useState<ArrowsVisibilityState>({
        left: false,
        right: false,
    });

    /**
     * isMobile sets the breakpoint at which
     * - the left and right arrows disappear
     * - we stop keeping track of the currentSlide
     */
    const isMobile = useBreakpoint('md');
    const isBreakPointXXL = useBreakpoint('xxl');

    // Prevent default keyboard scrolling
    useEnhancedEffect(() => {
        const currentScrollContainer = scrollContainerRef.current;

        if (!currentScrollContainer) {
            return;
        }

        function preventKeyboardScroll(e: KeyboardEvent): void {
            // space and arrow keys
            if ([32, 37, 38, 39, 40].includes(e.keyCode)) {
                e.preventDefault();
            }
        }

        currentScrollContainer.addEventListener('keydown', preventKeyboardScroll);

        return (): void => {
            currentScrollContainer.removeEventListener('keydown', preventKeyboardScroll);
        };
    }, []);

    const itemsPerPage = useMemo(
        () => (typeof slidesPerPage === 'number' ? slidesPerPage : getItemsPerPage(slidesPerPage, breakpoints)),
        [breakpoints, slidesPerPage],
    );

    // Prepare Items
    const itemsToRender = useMemo(() => {
        return items.map((item, index) => {
            const isPreloadAllowed = lazyloading && !hasBeenInteracted ? index <= itemsPerPage : true;

            if (!item.key) {
                throw new Error('Each Carousel Slide must have a unique key.');
            }
            return (
                <Styled.CarouselSlide
                    key={item.key as string}
                    gutter={gutter || 0}
                    slidesPerPage={itemsPerPage}
                    data-carousel-slide={index}
                    offset={itemsOffset}
                >
                    {isPreloadAllowed && (
                        <Styled.CarouselSlideInner offset={itemsOffset}>
                            {cloneElement(item, {
                                draggable: false,
                                onMouseDown: (event): void => event.preventDefault(),
                                isSelected: selectionEnabled ? index === currentSelection : undefined,
                                setSelected: selectionEnabled
                                    ? (): void => {
                                          setCurrentSelection(index);
                                      }
                                    : undefined,
                            })}
                        </Styled.CarouselSlideInner>
                    )}
                    {!isPreloadAllowed && (
                        <Styled.CarouselSlideInner offset={itemsOffset}>
                            <Skeleton />
                        </Styled.CarouselSlideInner>
                    )}
                </Styled.CarouselSlide>
            );
        });
    }, [currentSelection, gutter, items, itemsOffset, itemsPerPage, selectionEnabled, hasBeenInteracted, lazyloading]);

    useEffect(() => {
        let left = false,
            right = false;

        if (!isMobile && !hideArrows) {
            left = currentSlide !== 0;
            right = currentSlide < items.length - itemsPerPage;
        }

        setArrowsVisibleState({
            left,
            right,
        });
    }, [currentSlide, isMobile, items.length, itemsPerPage, hideArrows]);

    // Set current slide after the user scrolls
    const handleScroll = useDebouncedCallback((slide: number, setSlide: (slideNumber: number) => void) => {
        if (scrollContainerRef.current) {
            const slideNumber = getSuggestedCurrentSlide(scrollContainerRef.current);

            if (slide !== slideNumber) {
                setSlide(slideNumber);
                setCurrentSlide(slideNumber);
                setCurrentSelection(slideNumber);
                scrollToSlide(slideNumber);
            } else {
                scrollToSlide(slide);
            }
        }
    }, 300);

    const scrollToSlide = useCallback(
        (slideNumber, withScrollIntoView = true) => {
            const currentScrollContainer = scrollContainerRef.current;

            if (!currentScrollContainer) {
                return;
            }

            const slide = currentScrollContainer.querySelector(`[data-carousel-slide="${slideNumber}"]`);

            setCurrentSelection(slideNumber);

            if (onNavigate && currentSlide !== slideNumber) {
                onNavigate(slideNumber);
            }

            if (slide && withScrollIntoView) {
                scrollIntoView(slide, {
                    behavior: 'smooth',
                    inline: 'start',
                    block: 'nearest',
                    boundary: currentScrollContainer,
                });
            }
        },
        [onNavigate, currentSlide],
    );

    const updateSlide = useDebouncedCallback(() => {
        if (scrollContainerRef.current) {
            const slideNumber = getSuggestedCurrentSlide(scrollContainerRef.current);

            scrollToSlide(slideNumber);
        }
    }, 300);

    const handleDotNavigationClick = useCallback(
        (slideNumber, withScrollIntoView = true) => {
            onSlideSelected && onSlideSelected(slideNumber);
            scrollToSlide(slideNumber, withScrollIntoView);
        },
        [onSlideSelected, scrollToSlide],
    );

    useEnhancedEffect(() => {
        window.addEventListener('resize', updateSlide);
        return () => window.removeEventListener('resize', updateSlide);
    }, []);

    const getNextCurrentSlide = useCallback(
        (side, numberToScroll?) => {
            const scrollBy = typeof numberToScroll === 'undefined' ? itemsPerPage : numberToScroll;
            return side === 'ArrowRight'
                ? Math.min(currentSlide + scrollBy, items.length - itemsPerPage)
                : Math.max(currentSlide - scrollBy, 0);
        },
        [currentSlide, items.length, itemsPerPage],
    );

    const changeCurrentSlide = useCallback(
        (side: 'ArrowLeft' | 'ArrowRight', numberToScroll) => {
            setHasBeenInteracted(true);
            const newCurrentSlide = getNextCurrentSlide(side, numberToScroll);
            setCurrentSlide(newCurrentSlide);

            setCurrentSelection(newCurrentSlide);
            scrollToSlide(newCurrentSlide);
        },
        [getNextCurrentSlide, scrollToSlide],
    );

    const autoscrollToSelection = useCallback(
        (newSelection: number) => {
            if (scrollToSelection && newSelection !== null) {
                const isSelectionOffPage = newSelection < currentSlide || newSelection >= currentSlide + itemsPerPage;
                if (isSelectionOffPage) {
                    const pageWithSelection = itemsPerPage * Math.floor(newSelection / itemsPerPage);
                    setCurrentSlide(pageWithSelection);
                    scrollToSlide(pageWithSelection);
                }
            }
        },
        [scrollToSelection, currentSlide, itemsPerPage, scrollToSlide],
    );

    const changeSelection = useCallback(
        (side: 'ArrowLeft' | 'ArrowRight') => {
            if (!selectionEnabled) {
                return;
            }
            let newSelection = currentSelection;
            if (currentSelection === null) {
                newSelection = 0;
            } else if (side === 'ArrowLeft' && currentSelection > 0) {
                newSelection = currentSelection - 1;
            } else if (side === 'ArrowRight' && currentSelection < items.length - 1) {
                newSelection = currentSelection + 1;
            }
            setHasBeenInteracted(true);

            setCurrentSelection(newSelection);
            newSelection && autoscrollToSelection(newSelection);
            onSlideSelected && onSlideSelected(newSelection);
        },
        [autoscrollToSelection, currentSelection, items.length, onSlideSelected, selectionEnabled],
    );

    // Keyboard Navigation
    useEffect((): (() => void | void) => {
        function handleKeyDown(e: KeyboardEvent): void {
            const { key } = e;
            if (key === 'ArrowRight' || key === 'ArrowLeft') {
                if (keyboardNavigation === 'page') {
                    changeCurrentSlide(key, itemsPerPage);
                } else if (typeof keyboardNavigation === 'number') {
                    changeCurrentSlide(key, keyboardNavigation);
                } else if (keyboardNavigation === 'selection') {
                    changeSelection(key);
                }
            }
        }
        if (keyboardNavigation) {
            document.addEventListener('keydown', handleKeyDown);
        }
        return (): void => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [changeCurrentSlide, changeSelection, itemsPerPage, keyboardNavigation]);

    // scroll handler
    useEffect(() => {
        const currentScrollContainer = scrollContainerRef.current;

        if (!currentScrollContainer) {
            return;
        }

        const boundScrollFunction = handleScroll.bind({}, currentSlide, setCurrentSlide);

        currentScrollContainer.addEventListener('scroll', boundScrollFunction);

        return (): void => {
            currentScrollContainer.removeEventListener('scroll', boundScrollFunction);
        };
    }, [itemsPerPage, onNavigate, items, handleScroll, currentSlide, isMobile]);

    // DRAG with mouse
    const momentumID = useRef(0);
    const scrollLeftMomentum = useCallback((velocity: number, movement: number) => {
        let velocityRef = Math.min(velocity, 10);

        function momentumLoop(): void {
            if (scrollContainerRef?.current) {
                const previousScrollLeft = scrollContainerRef.current.scrollLeft;
                scrollContainerRef.current.scrollLeft -= (velocityRef / 10) * movement;
                velocityRef *= 0.95;
                if (previousScrollLeft !== scrollContainerRef.current.scrollLeft && Math.abs(velocityRef) > 0.5) {
                    momentumID.current = requestAnimationFrame(momentumLoop);
                }
            }
        }

        cancelAnimationFrame(momentumID.current);
        momentumID.current = requestAnimationFrame(momentumLoop);
    }, []);

    useEffect(() => {
        return (): void => {
            cancelAnimationFrame(momentumID.current);
        };
    }, []);

    const hasDragged = React.useRef(false);

    const bindGesture = useGesture(
        {
            onDrag: ({ first, last, event, velocity, movement, delta: [mx] }) => {
                if (first) {
                    hasDragged.current = true;
                }

                if (last) {
                    setTimeout(() => {
                        hasDragged.current = false;
                    }, 0);
                }

                if (scrollContainerRef?.current) {
                    // Ignoring touch event, fallback to native behavior
                    if (event?.type === 'mousemove') {
                        scrollContainerRef.current.scrollLeft -= mx;
                    }
                    // Translate velocity and movement into momentum to finish the scroll
                    if (event?.type === 'mouseup') {
                        scrollLeftMomentum(velocity, movement[0]);
                    }
                }
            },
            onScroll: ({ last }) => {
                if (last && scrollContainerRef.current) {
                    const slideNumber = getSuggestedCurrentSlide(scrollContainerRef.current);

                    scrollToSlide(slideNumber, false);
                }
            },
            onClickCapture: (event) => {
                if (hasDragged.current) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            },
        },
        {
            drag: {
                axis: 'x',
                filterTaps: true,
            },
        },
    );

    // Auto Navigation
    const { ariaLive, isAutoNavigatingActive } = useAutoNavigation({
        carouselContainerRef,
        currentSelection,
        autoNavigationDelay,
        length: items.length,
        setCurrentSelection,
        scrollToSlide,
        setHasBeenInteracted,
    });

    const transitionDelay = isAutoNavigatingActive ? autoNavigationDelay : 0;
    const arrowOffsetFromDotSize = dotPosition === 'outside' ? dotSize : undefined;

    return (
        <Styled.Wrapper
            ref={carouselContainerRef}
            role="region"
            aria-live={ariaLive}
            id={`carousel-${nanoid()}`}
            {...props}
        >
            <Styled.ScrollContainer
                ref={scrollContainerRef}
                gutter={gutter}
                offset={itemsOffset}
                isMobile={isMobile}
                {...bindGesture()}
            >
                {itemsToRender}
            </Styled.ScrollContainer>
            {arrows.map(({ direction, key }) => (
                <Styled.ArrowWrapper
                    direction={direction}
                    arrowPosition={arrowPosition}
                    key={`carousel-arrow-${direction}`}
                    className={arrowsVisibleState[direction] ? 'showing' : ''}
                >
                    <ArrowButton
                        label=""
                        hideTooltip
                        size={isBreakPointXXL ? 'sm' : 'md'}
                        side={direction}
                        onClick={(): void => changeCurrentSlide(key, itemsPerPage)}
                        mb={arrowOffset || arrowOffsetFromDotSize}
                    />
                </Styled.ArrowWrapper>
            ))}
            {dotPosition && (
                <DotNavigation
                    dotPosition={dotPosition}
                    dotSize={dotSize}
                    length={items.length}
                    currentSelection={currentSelection}
                    onClick={handleDotNavigationClick}
                    transitionDelay={transitionDelay}
                />
            )}
        </Styled.Wrapper>
    );
};

Carousel.displayName = 'Carousel';
