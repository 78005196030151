import React from 'react';
import { Box } from '../../primitives';
import { SkeletonProps } from './types';
import * as Styled from './styled';

export const Skeleton: React.FC<SkeletonProps> = ({
    variant = 'rectangle',
    width = '100%',
    height = '100%',
    lines = 0,
    ...props
}) =>
    lines ? (
        <Box display="flex" flexDirection="column">
            {Array.from({ length: lines }).map((_val, index) => (
                <Styled.Skeleton
                    {...props}
                    key={index}
                    style={{
                        width,
                        height,
                    }}
                />
            ))}
        </Box>
    ) : (
        <Styled.Skeleton
            {...props}
            variant={variant}
            style={{
                width,
                height,
            }}
        />
    );

Skeleton.displayName = 'Skeleton';
