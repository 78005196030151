import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCameraOffOutlined = ({ size, title = 'CameraOffOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <g stroke="currentColor" strokeWidth={1.5}>
                <path d="M15.13 9.04V7.49c0-.857-.7-1.551-1.565-1.551H4.957c-.865 0-1.566.694-1.566 1.55v9.307c0 .857.701 1.55 1.566 1.55h.782" />
                <path
                    strokeLinecap="square"
                    d="M9.059 18.347h4.506c.865 0 1.565-.694 1.565-1.551v-2.327l5.479 1.551V8.265l-1.926.543"
                />
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.83 4.39L4.17 19.9" />
            </g>
        </g>
    </svg>
);

export default withIcon(SvgCameraOffOutlined);
