import React, { useContext } from 'react';
import { CollapseWithContextProps } from './types';
import { AccordionContext } from './Accordion';
import { BaseCollapse } from './BaseCollapse';

// CollapseWithContext

export const CollapseWithContext: React.FC<CollapseWithContextProps> = ({
    itemKey,
    ...rest
}: CollapseWithContextProps) => {
    const { openItemKey, toggleOpenItem } = useContext(AccordionContext);

    const onToggle = (): void => toggleOpenItem(itemKey);
    const isOpen = openItemKey === itemKey;

    return <BaseCollapse {...rest} isOpen={isOpen} onToggle={onToggle} />;
};
