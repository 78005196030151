import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHeartFilled = ({ size, title = 'HeartFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M12.454 5.512a4.929 4.929 0 017.093 0 5.175 5.175 0 010 7.191l-3.27 3.368-.109.113a9588.11 9588.11 0 01-3.37 3.472l-.049.048c-.44.41-1.13.392-1.546-.04l-6.75-6.967a5.174 5.174 0 010-7.19 4.924 4.924 0 017.093 0l.455.47.453-.465z"
            />
        </g>
    </svg>
);

export default withIcon(SvgHeartFilled);
