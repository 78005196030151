import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgRepeatOutlined = ({ size, title = 'RepeatOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M9.5 4.5l3 3H9A4.5 4.5 0 004.5 12h0A4.5 4.5 0 009 16.5m5.5 3l-3-3H15a4.5 4.5 0 004.5-4.5h0A4.5 4.5 0 0015 7.5"
            />
        </g>
    </svg>
);

export default withIcon(SvgRepeatOutlined);
