import { useState } from 'react';
import { nanoid } from 'nanoid/non-secure';
import { useEnhancedEffect } from '../../utils';

export const useId = (staticId?: string) => {
    const [uuid, setUuid] = useState('');

    useEnhancedEffect(() => {
        setUuid(nanoid());
    }, []);

    return staticId || uuid;
};
