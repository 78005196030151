import { useEffect } from 'react';

export function useKeyPressWithCallback(targetKey: string, cb: () => void = () => undefined): void {
    // Add event listeners
    useEffect(() => {
        // If pressed key is our target key then fire callback
        function downHandler({ key }) {
            if (key === targetKey) {
                cb();
            }
        }

        window.addEventListener('keydown', downHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, [cb, targetKey]); // Empty array ensures that effect is only run on mount and unmount
}
