import React, { ComponentPropsWithRef, useState } from 'react';
import { ConditionalWrapper, VisuallyHidden } from '../../primitives';
import { forwardRef } from '../../system';
import { Tooltip } from '../Tooltip';
import { Styled } from './styled';
import { SwitchProps, SwitchSize } from './types';

type Props = SwitchProps & Omit<ComponentPropsWithRef<'input'>, keyof SwitchProps>;

export const Switch = forwardRef<Props, 'input'>(
    (
        {
            id,
            offLabel,
            onLabel,
            tooltipOnText,
            tooltipOffText,
            size = 'md',
            isOn,
            isDisabled,
            isInvalid,
            onChange,
            onFocus,
            onBlur,
            children,
            ...props
        },
        ref,
    ) => {
        const [isChecked, setIsChecked] = useState();

        const handleOnChange = (e) => {
            setIsChecked(e.target.checked);
            onChange?.(e);
        };

        return (
            <Styled.ComponentWrapper {...props}>
                <ConditionalWrapper
                    condition={tooltipOnText && tooltipOffText}
                    wrapper={(children): React.ReactNode => (
                        <Tooltip
                            content={isChecked ? (tooltipOnText as string) : (tooltipOffText as string)}
                            position="top"
                        >
                            {children}
                        </Tooltip>
                    )}
                >
                    <VisuallyHidden
                        as="input"
                        type="checkbox"
                        id={id}
                        disabled={isDisabled}
                        onChange={handleOnChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        checked={isOn}
                        aria-invalid={isInvalid}
                        aria-checked={isOn}
                        ref={ref}
                    />
                    {offLabel && <Styled.OffLabel size={size as SwitchSize}>{offLabel}</Styled.OffLabel>}
                    <Styled.OuterSwitch size={size as SwitchSize} isDisabled={isDisabled}>
                        <Styled.InnerSwitch size={size as SwitchSize} isDisabled={isDisabled}>
                            <Styled.Handle size={size as SwitchSize} isDisabled={isDisabled} />
                        </Styled.InnerSwitch>
                    </Styled.OuterSwitch>
                    {onLabel && <Styled.OnLabel size={size as SwitchSize}>{onLabel}</Styled.OnLabel>}
                </ConditionalWrapper>
            </Styled.ComponentWrapper>
        );
    },
);

Switch.displayName = 'Switch';
