import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgImageOutlined = ({ size, title = 'ImageOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.395 20.313H4.605a.918.918 0 01-.918-.918V4.605c0-.507.411-.918.918-.918h14.79c.507 0 .918.411.918.918v14.79c0 .612-.306.918-.918.918z"
            />
            <circle cx={10.34} cy={8.49} r={1.85} />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.312 14.586l-4.063-4.433-5.911 5.911-3.695-3.695-2.956 2.956"
            />
        </g>
    </svg>
);

export default withIcon(SvgImageOutlined);
