import React from 'react';

export const truncateReactChildren = (children: React.ReactNode, maxLength: number): [React.ReactNode, number] => {
    if (!children) {
        return [null, maxLength];
    }

    if (typeof children === 'string') {
        const len = children.length;
        maxLength -= len;
        if (maxLength <= 0) {
            children = children.substr(0, children.length + maxLength);
        }

        return [children, maxLength];
    }

    function track(el: any) {
        if (maxLength > 0) {
            const len = el.length;
            maxLength -= len;
            if (maxLength <= 0) {
                el = el.substr(0, el.length + maxLength);
            }
        } else {
            el = undefined;
        }
        return el;
    }

    function walk(children: any, fn: (el: any) => void) {
        let props = { ...children.props };
        if (typeof props.children === 'string') {
            props.children = track(children.props.children);
        } else {
            props.children = children.props.children?.map((child) => {
                if (typeof child === 'object') {
                    child = walk(child, fn);
                } else if (typeof child === 'string') {
                    child = fn(child);
                }

                return child;
            });
        }

        if (!props.children) {
            children = undefined;
            props = undefined;
        }

        if (children && typeof children !== 'string' && props.children && typeof props.children !== 'string') {
            props.children = props.children?.filter((child) => child);
        }

        return children && props ? { ...children, props } : undefined;
    }

    try {
        const upperlevelProps = walk({ ...(children as Record<string, any>) }, track);
        return [{ ...upperlevelProps }, maxLength];
    } catch (er) {
        return [null, maxLength];
    }
};
