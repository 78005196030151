import styled, { keyframes, css } from '../../utils/styled';
import { variant, themeMode, palette } from '../../utils';
import { Box } from '../../primitives';
import { SkeletonProps } from './types';

export const SkeletonGradientAnimation = keyframes`
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
`;

const skeletonVariant = variant({
    prop: 'variant',
    variants: {
        rectangle: {
            borderRadius: 'base',
        },
        round: {
            aspectRatio: '1/1',
            borderRadius: 'round',
        },
    },
});

export const Skeleton = styled(Box)<SkeletonProps>`
    position: relative;
    display: flex;
    overflow: hidden;
    background-color: transparent;

    &::before {
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0%;
        background-size: 400% 400%;
        ${themeMode({
            light: css`
                background-image: linear-gradient(
                    -45deg,
                    ${palette('neutral.200')} 0%,
                    ${palette('platinum.100')} 48%,
                    ${palette('platinum.100')} 52%,
                    ${palette('neutral.200')} 100%
                );
            `,
            dark: css`
                background-image: linear-gradient(
                    -45deg,
                    ${palette('platinum.800')} 0%,
                    ${palette('platinum.700')} 48%,
                    ${palette('platinum.700')} 52%,
                    ${palette('platinum.800')} 100%
                );
            `,
        })};

        animation: ${SkeletonGradientAnimation} 5s ease infinite;
    }

    ${skeletonVariant};
`;
