import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgChromatic = ({ size, title = 'Chromatic', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="#F9FAFB" d="M46 21H2v19h44V21z" />
        <path
            fill="#2C3C4D"
            d="M45 7H3a2 2 0 00-2 2v30a2 2 0 002 2h42a2 2 0 002-2V9a2 2 0 00-2-2zm0 32H3V23h4v10h2V23h4v10h2V23h5v10h2V23h4v10h2V23h5v10h2V23h4v10h2V23h4v16z"
        />
        <path fill="#3C6ED7" d="M40 16H28a1 1 0 010-2h12a1 1 0 010 2zM8 17a2 2 0 110-4 2 2 0 010 4z" />
        <path fill="#5A6774" d="M16 17a2 2 0 110-4 2 2 0 010 4z" />
    </svg>
);

export default withIcon(SvgChromatic);
