import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFilterOutlined = ({ size, title = 'FilterOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="none" d="M-1-1h26v26H-1z" />
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M5.75 7h12.5M7.417 10.333h9.166m-7.5 3.334h5.834M10.75 17h2.5"
        />
    </svg>
);

export default withIcon(SvgFilterOutlined);
