import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMicrophoneOutlined = ({ size, title = 'MicrophoneOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path d="M8.82 8.87h1.47m4.42 0h1.47m-3.68 5.478h0c-2.034 0-3.684-1.752-3.684-3.913v-3.13c0-2.161 1.65-3.914 3.684-3.914h0c2.034 0 3.684 1.753 3.684 3.913v3.13c0 2.162-1.65 3.914-3.684 3.914z" />
            <path d="M19.132 10.435c0 3.89-2.97 7.043-6.632 7.043h0c-3.663 0-6.632-3.153-6.632-7.043M8.82 20.61h7.36m-3.68-3.13v3.13" />
        </g>
    </svg>
);

export default withIcon(SvgMicrophoneOutlined);
