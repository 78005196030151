import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCameraFilled = ({ size, title = 'CameraFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path
                strokeLinecap="round"
                d="M21.143 16.37c0 1.657-1.341 3-2.994 3H4.923c-.825 0-1.494-.61-1.494-1.436V8.878c0-.825.669-1.494 1.494-1.494h3.362l.938-1.407c.307-.46 1.002-.834 1.551-.834h2.493c.55 0 1.242.37 1.551.834l.938 1.407h3.907c.826 0 1.48.669 1.48 1.494v7.493z"
            />
            <path d="M15.429 13.429a3.143 3.143 0 11-6.287 0 3.143 3.143 0 016.287 0z" />
        </g>
    </svg>
);

export default withIcon(SvgCameraFilled);
