import { useRef, useState } from 'react';
import {
  Button,
  LinkFilled,
  Popper,
  Box,
  useDisclosure,
  Fade,
  Text,
  useBreakpoint,
} from '@landr/maestro';

export const ShareLinkButton: React.FC = () => {
  const isMobile = useBreakpoint('md');
  const ref = useRef<HTMLButtonElement>(null);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [label, setLabel] = useState('Share link');

  const openShareOrCopyToClipboard = () => {
    // Open the ios/android share dialog on mobile
    if (navigator.share) {
      navigator.share({
        title: 'LANDR Blog',
        text: 'Check out this LANDR Blog post:',
        url: window.location.href,
      });
    } else {
      setLabel('Copied to clipboard!');
      navigator.clipboard.writeText(window.location.href);
      setTimeout(() => setLabel('Share link'), 2000);
    }
  };

  return (
    <>
      <Popper
        hidden={isMobile}
        isOpen={isOpen}
        anchorRef={ref}
        transitionComponent={Fade}
        popperOptions={{
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
      >
        <Box
          sx={{
            whiteSpace: 'pre',
            backgroundColor: 'white',
            paddingX: 'xs',
            paddingBottom: 'xxs',
            borderRadius: 'sm',
          }}
        >
          <Text size="xs" lineHeight="18px">
            {label}
          </Text>
          <Popper.Arrow />
        </Box>
      </Popper>
      <Button
        data-analytic-value="Share link"
        ref={ref}
        size="sm"
        onClick={openShareOrCopyToClipboard}
        icon={isMobile && LinkFilled}
        onMouseEnter={onToggle}
        onMouseLeave={onClose}
        variant="secondary"
        isRounded={isMobile}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#ffffff',
          borderRadius: isMobile ? undefined : '50%',
          padding: isMobile ? undefined : 0,
        }}
      >
        {isMobile ? <Text>Share this article</Text> : <LinkFilled />}
      </Button>
    </>
  );
};
