/* eslint-disable */
/**
 * !!!DO NOT EDIT THIS FILE!!!
 *
 * The following code is fully generated from the definitions provided in the Analytics AirTable definition.
 * You can regenerate it with "yarn generate:analytics:v2"
 */
import { AbstractSegmentServiceV2, AnalyticsApplication, AnalyticsPlatformName } from '@landr/core';

export class AnalyticsService extends AbstractSegmentServiceV2 {
    constructor(platformNameOverride?: AnalyticsPlatformName) {
        super(
            AnalyticsApplication.Blog,
            typeof window === 'undefined' ? undefined : window,
            platformNameOverride,
        );
    }
}
