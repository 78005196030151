import styled from 'styled-components';
import { Box } from '../../primitives/Box';
import { variant, themeMode, palette, color, spacing } from '../../utils';
import { Popover } from '../Popover';
import { NavProps, NavLinkProps, NavSize } from './types';

const navDirectionStyles = variant({
    prop: 'isVertical',
    variants: {
        false: {
            flexDirection: 'row',
        },
        true: {
            flexDirection: 'column',
            alignItems: 'stretch',
        },
    },
});

const navVariantStyles = variant({
    prop: 'variant',
    variants: {
        tab: {
            '&:after': {
                content: '""',
                position: 'absolute',
                zIndex: 1,
                width: '100%',
                height: '1px',
                left: 0,
                bottom: 0,
                backgroundColor: themeMode({
                    light: palette('neutral.300'),
                    dark: palette('neutral.600'),
                }),
            },
        },
        pill: {
            gap: 'sm',
        },
        default: {
            gap: 'xs',
        },
    },
});

export const Nav = styled(Box)<NavProps>`
    position: relative;
    flex: 1 1 auto;

    display: flex;
    align-items: center;
    margin: 0;

    width: 100%;
    max-width: 100%;
    min-width: 0;

    ${navDirectionStyles};
    ${navVariantStyles};
`;

export const NavList = styled(Box)<NavProps>`
    display: flex;
    align-items: center;
    margin: 0;

    ${navDirectionStyles};
    ${navVariantStyles};
`;

export const NavItem = styled(Box)``;

const navLinkVariants = variant({
    prop: 'variant',
    variants: {
        default: {
            ':not([aria-current="true"])': {
                color: themeMode({
                    light: 'neutral.500',
                    dark: 'neutral.400',
                }),
            },
            fontWeight: 'bold',
            '&:hover': {
                color: themeMode({
                    light: 'neutral.900',
                    dark: 'neutral.50',
                }),
            },
            '&:active, &[aria-current="true"]': {
                color: themeMode({
                    light: 'neutral.900',
                    dark: 'neutral.50',
                }),
            },
        },
        pill: {
            fontWeight: 'bold',
            borderRadius: 'round',
            color: themeMode({
                light: 'neutral.900',
                dark: 'neutral.50',
            }),
            backgroundColor: themeMode({
                light: 'neutral.100',
                dark: 'neutral.800',
            }),
            '&:hover': {
                color: themeMode({
                    light: 'neutral.900',
                    dark: 'neutral.50',
                }),
                backgroundColor: themeMode({
                    light: 'neutral.200',
                    dark: 'neutral.700',
                }),
            },
            '&:active, &[aria-current="true"]': {
                color: themeMode({
                    light: 'neutral.50',
                    dark: 'neutral.900',
                }),
                backgroundColor: themeMode({
                    light: 'neutral.700',
                    dark: 'neutral.200',
                }),
            },
        },
        tab: {
            position: 'relative',
            ':not([aria-current="true"])': {
                color: themeMode({
                    light: 'neutral.500',
                    dark: 'neutral.400',
                }),
            },
            fontWeight: 'bold',
            '&:hover': {
                color: themeMode({
                    light: 'neutral.900',
                    dark: 'neutral.50',
                }),
            },
            '&:focus-visible': {
                outline: 0,
                boxShadow: 'none',
            },
            '&:after': {
                content: '""',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                opacity: 0,
                transition: 'opacity 200ms ease-in',
                borderWidth: '3px',
                borderStyle: 'solid',
                borderColor: `${color('border.base')}`,
                borderTopLeftRadius: 'lg',
                borderTopRightRadius: 'lg',
            },
            '&:focus-visible:after': {
                opacity: 1,
            },
            '&:active, &[aria-current="true"]': {
                zIndex: 2,
                color: themeMode({
                    light: 'neutral.900',
                    dark: 'neutral.50',
                }),
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    width: '100%',
                    height: '3px',
                    bottom: 0,
                    left: 0,
                    backgroundColor: themeMode({
                        light: 'neutral.900',
                        dark: 'neutral.50',
                    }),
                },
            },
        },
    },
});

const navLinkSizes = variant({
    prop: 'size',
    variants: {
        sm: {
            fontSize: 'sm',
            lineHeight: '24px',
            py: 'xxs',
            px: 'xs',
        },
        md: {
            fontSize: 'md',
            lineHeight: '24px',
            py: 'xs',
            px: 'sm',
        },
        lg: {
            fontSize: 'lg',
            lineHeight: '24px',
            py: 'sm',
            px: 'md',
        },
    },
});

const navLinkSizesMobile = variant({
    prop: 'size',
    variants: {
        md: {
            px: 'md',
        },
    },
});

const getBadgePadding = (size: NavSize | undefined) => {
    switch (size) {
        case 'lg':
            return spacing('sm');
        case 'md':
            return spacing('xs');
        case 'sm':
            return spacing('xxs');
        default:
            return undefined;
    }
};

export const NavLink = styled(Box)<NavLinkProps>`
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    z-index: 1;

    ${navLinkVariants};
    ${navLinkSizes};
    padding-right: ${({ hasBadge, size }) => {
        if (hasBadge) {
            return getBadgePadding(size);
        }
        return undefined;
    }};

    /**
     * This is a temporary fix for mobile to mimic the List.Item component.
     * The best would be to know if the menu is vertical and then change the styling of each element
     * TODO: [DS-534] Handle vertical styling based on the isVertical prop
     */
    ${({ theme }) => theme.breakpoint('md')`
       ${navLinkSizesMobile};
       min-height: 48px;
    `}
`;

export const NavDropdownContent = styled(Popover.Content)`
    padding-left: 0;
    padding-right: 0;

    ${NavLink} {
        flex: 1 1 auto;
        padding: ${spacing('xs')} ${spacing('md')};

        &:hover {
            color: ${palette('text.base')};
            background-color: ${themeMode({ light: palette('neutral.100'), dark: palette('neutral.700') })};
        }

        &:active {
            color: ${palette('text.base')};
            background-color: ${themeMode({ light: palette('neutral.100'), dark: palette('neutral.600') })};
        }
    }

    ${NavItem} {
        padding: ${spacing('xs')} ${spacing('md')};
    }
`;
