import { IconButton, Link, LinkProps } from '@landr/maestro';

export const ShareSocialLink: React.FC<LinkProps> = ({
  icon,
  label,
  name,
  ...rest
}) => {
  return (
    <Link {...rest} data-analytic-value={name}>
      <IconButton
        variant="secondary"
        icon={icon}
        size="sm"
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '50%',
        }}
        label={label}
      />
    </Link>
  );
};
