import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgRecordFilled = ({ size, title = 'RecordFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <circle cx={12} cy={12} r={6} fill="currentColor" fillRule="evenodd" />
    </svg>
);

export default withIcon(SvgRecordFilled);
