import { useContext } from 'react';
import { ResponsiveState, ResponsiveContext } from '../../utils/responsive';

/**
 * useDevice
 * Keep in mind that this hook return detection using user agent and vendor. This should be considered
 * unsafe to use other than for layout or non-breaking feature. If you need to target a browser because he
 * doesn't support a specific feature, test for this feature instead.
 */
export const useDevice = () => {
    const state = useContext<ResponsiveState>(ResponsiveContext);

    return state.device;
};
