import styled from '../../utils/styled';
import { palette, spacing, themeMode, radius, color, fontWeight } from '../../utils';
import { Box } from '../../primitives';
import { SwitchProps, SwitchSubComponentProps, SwitchSize } from './types';

export const getSwitchSizeMultiplier = (size: SwitchSize): 1 | 1.5 | 2.25 => {
    switch (size) {
        case 'sm':
            return 1;
        case 'md':
            return 1.5;
        case 'lg':
            return 2.25;
        default:
            return 1.5; // default to 'md'
    }
};

const factor = {
    big: 32,
    small: 16,
    tiny: 2,
};

const ComponentWrapper = styled(Box).attrs(() => ({ as: 'label' }))<SwitchProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
`;

const InnerSwitch = styled(Box)<SwitchSubComponentProps>`
    position: relative;
    width: ${({ size }: SwitchSubComponentProps) => `${factor.big * getSwitchSizeMultiplier(size)}px`};
    height: ${({ size }: SwitchSubComponentProps) => `${factor.small * getSwitchSizeMultiplier(size)}px`};
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: ${radius('round')};
    background-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.700') })};
    transition: 0.2s;

    &:hover {
        background-color: ${themeMode({ light: palette('neutral.400'), dark: palette('neutral.700') })};
        transition: 0s;
    }
`;

const Handle = styled(Box)<SwitchSubComponentProps>`
    display: block;
    position: absolute;
    width: ${({ size }: SwitchSubComponentProps) => `${factor.small * getSwitchSizeMultiplier(size)}px`};
    height: ${({ size }: SwitchSubComponentProps) => `${factor.small * getSwitchSizeMultiplier(size)}px`};
    transition: 0.2s;
    left: 0;
    top: 0;
    padding: ${({ size }: SwitchSubComponentProps) => `${factor.tiny * getSwitchSizeMultiplier(size)}px`};

    &:before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        border-radius: ${radius('round')};
        background-color: ${({ isDisabled }: SwitchSubComponentProps) =>
            isDisabled
                ? themeMode({ light: palette('neutral.200'), dark: palette('neutral.500') })
                : themeMode({ light: palette('neutral.0'), dark: palette('neutral.0') })};
    }
`;

const OuterSwitch = styled(Box)<SwitchSubComponentProps>`
    border-radius: ${radius('round')};

    input[type='checkbox']:focus ~ &&,
    input[type='checkbox']:focus-visible ~ && {
        box-shadow: ${themeMode({ dark: color('neutral.50'), light: color('neutral.900') })} 0px 0px 0px
            ${factor.tiny}px;
    }

    input[type='checkbox']:focus:not(:focus-visible) ~ && {
        box-shadow: none;
    }

    input[type='checkbox']:checked ~ && {
        & > ${InnerSwitch} {
            background-color: ${({ isDisabled }: SwitchSubComponentProps) =>
                isDisabled
                    ? themeMode({ light: palette('neutral.400'), dark: palette('neutral.700') })
                    : themeMode({ light: palette('primary.500'), dark: palette('primary.500') })};
            transition: 0.2s;

            &:hover {
                background-color: ${({ isDisabled }: SwitchSubComponentProps) =>
                    isDisabled
                        ? themeMode({ light: palette('neutral.400'), dark: palette('neutral.700') })
                        : themeMode({ light: palette('primary.500'), dark: palette('primary.500') })};
                transition: 0s;
            }
        }
    }

    input[type='checkbox']:checked ~ && {
        & ${Handle} {
            left: ${({ size }: SwitchSubComponentProps) =>
                `calc(100% - ${factor.small * getSwitchSizeMultiplier(size)}px)`};
        }
    }
`;

const OffLabel = styled(Box)<SwitchSubComponentProps>`
    font-weight: ${fontWeight('bold')};
    margin-right: ${({ size }: SwitchSubComponentProps) => (size === 'lg' ? spacing('md') : spacing('xs'))};
`;

const OnLabel = styled(Box)<SwitchSubComponentProps>`
    font-weight: ${fontWeight('bold')};
    margin-left: ${({ size }: SwitchSubComponentProps) => (size === 'lg' ? spacing('md') : spacing('xs'))};
`;

export const Styled = {
    ComponentWrapper,
    OuterSwitch,
    InnerSwitch,
    OffLabel,
    OnLabel,
    Handle,
};
