import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgShowOutlined = ({ size, title = 'ShowOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <g stroke="currentColor" strokeWidth={1.5} transform="translate(4 6)">
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.5 12C3.764 12 0 6.198 0 6c0-.198 3.764-6 8.5-6C13.236 0 17 5.814 17 6c0 .186-3.764 6-8.5 6z"
                />
                <circle cx={8.5} cy={6} r={2.09} />
            </g>
        </g>
    </svg>
);

export default withIcon(SvgShowOutlined);
