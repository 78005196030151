import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgStarFilled = ({ size, title = 'StarFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12 5L9.842 9.609 5 10.354l3.508 3.587L7.668 19 12 16.609 16.332 19l-.84-5.059L19 10.354l-4.842-.745z"
        />
    </svg>
);

export default withIcon(SvgStarFilled);
