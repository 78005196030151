import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProfileOutlined = ({ size, title = 'ProfileOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <circle cx={12.25} cy={12.25} r={8.25} />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 18.796c-.004-.274-.011-.783-.017-.843-.25-2.326-2.353-3.153-4.91-3.153l-.073.002-.073-.002c-2.557 0-4.66.827-4.91 3.153-.006.06-.012.57-.017.843"
            />
            <circle cx={12.25} cy={9.5} r={2.5} />
        </g>
    </svg>
);

export default withIcon(SvgProfileOutlined);
