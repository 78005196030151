import { useState } from 'react';

export function useToggle(initialState = false): {
    isActive: boolean;
    activate: () => void;
    deactivate: () => void;
    toggle: () => void;
} {
    const [isActive, setActive] = useState(initialState);
    const activate = (): void => setActive(true);
    const deactivate = (): void => setActive(false);
    const toggle = (): void => setActive((visible) => !visible);

    return { isActive, activate, deactivate, toggle };
}
