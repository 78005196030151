import styled from 'styled-components';
import { Box } from '../../primitives/Box';
import { Nav } from '../Nav';
import { spacing, variant } from '../../utils';
import { TabsAlign } from './types';

export const Wrapper = styled(Box)`
    display: flex;
    overflow: hidden;
    position: relative;
`;

export const ScrollContainer = styled(Box)`
    display: flex;
    flex: 1 1 auto;
    overflow-x: auto;
    overflow-y: hidden;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    --left-mask-opacity: 1;
    --right-mask-opacity: 1;

    mask-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, var(--left-mask-opacity)) 0%,
        rgba(0, 0, 0, 1) 15%,
        rgba(0, 0, 0, 1) 85%,
        rgba(0, 0, 0, var(--right-mask-opacity)) 100%
    );
`;

type ScrollButtonProps = {
    direction: 'forward' | 'backward';
};

export const ScrollButton = styled('button')<ScrollButtonProps>`
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    padding: 0;
    ${variant({
        prop: 'direction',
        variants: {
            backward: {
                left: 0,
            },
            forward: {
                right: 0,
            },
        },
    })};

    &:hover {
        cursor: pointer;
    }
`;

export const Tablist = styled(Nav)<{ align: TabsAlign }>`
    flex: 1 1 auto;
    max-width: unset;
    min-width: unset;
    width: auto;

    ${variant({
        prop: 'align',
        variants: {
            left: {
                '& > :last-child': {
                    marginRight: 'auto',
                },
            },
            right: {
                '& > :first-child': {
                    marginLeft: 'auto',
                },
            },
            center: {
                '& > :first-child': {
                    marginLeft: 'auto',
                },
                '& > :last-child': {
                    marginRight: 'auto',
                },
            },
        },
    })};
`;

export const Pane = styled(Box).attrs(() => ({ role: 'tabpanel' }))`
    padding-top: ${spacing('xl')};
`;
