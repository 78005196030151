import React from 'react';
import { BoxProps, Box } from '../Box';
import { TruncateByCharactersProps } from './types';
import { truncateReactChildren } from './helpers';

type Props = TruncateByCharactersProps & BoxProps & React.HTMLAttributes<HTMLElement>;

/**
 *
 * > Use a `Box` with the `nbOfLine` prop
 *
 */

export const TruncateByCharacters: React.FC<Props> = ({
    children,
    limit = Infinity,
    ellipsis = '...',
    display = 'inline',
    ...props
}) => {
    const [content, counter] = truncateReactChildren(children || '', limit);

    return (
        <Box display={display} {...props}>
            {content}
            {counter < 0 && ellipsis}
        </Box>
    );
};
TruncateByCharacters.displayName = 'TruncateByCharacters';
