import React, { Fragment } from 'react';
import styled from 'styled-components';
import { runIfFn, color, spacing } from '../../../../utils';
import { forwardRef } from '../../../../system';
import { List } from '../../../List';
import { Box } from '../../../../primitives/Box';
import { Center } from '../../../../primitives/Center';
import { Progress } from '../../../Progress';
import { useAutocompleteDropdown } from './AutocompleteContext';
import { ItemsProps } from './types';

const BottomComponent = styled(Box)`
    flex: 1 0 auto;
    position: sticky;
    bottom: -${spacing('xs')};
    background-color: ${color('surface')};
    margin-bottom: -${spacing('xs')};
`;

const DefaultLoadingComponent = () => (
    <Center py="md">
        <Progress size="md" />
    </Center>
);

export const Items = forwardRef<ItemsProps, 'ul'>((props, ref) => {
    const {
        itemProps,
        itemComponent,
        noResultsComponent: NoResultsComponent,
        noResultsLabel,
        highlightedIndex,
        getItemsLabel,
        isLoading,
        loadingComponent: LoadingComponent = DefaultLoadingComponent,
        bottomComponent,
        ...rest
    } = props;

    const { items, isOpen, isGrouped, getDisabledOption, hasReachedMaxValues, inputValue, closeMenu } =
        useAutocompleteDropdown();

    if (isLoading) {
        return (
            <Box flex="1 1 auto" overflowY="auto" position="relative" ref={ref} {...rest}>
                <LoadingComponent />
                {bottomComponent && (
                    <BottomComponent>{runIfFn(bottomComponent, { inputValue, closeMenu })}</BottomComponent>
                )}
            </Box>
        );
    }

    if (items.length <= 0 && !isLoading) {
        return (
            <Box flex="1 1 auto" overflowY="auto" py="xs" position="relative" ref={ref} {...rest}>
                <NoResultsComponent>{noResultsLabel}</NoResultsComponent>
                {bottomComponent && (
                    <BottomComponent>{runIfFn(bottomComponent, { inputValue, closeMenu })}</BottomComponent>
                )}
            </Box>
        );
    }

    const renderGroups = () =>
        items.reduce(
            (acc, item, index) => {
                acc.groups.push(
                    <Fragment key={`group-${index}`}>
                        <List.Subheader>{item.group}</List.Subheader>
                        {item.options.map((option) => {
                            const optionIndex = acc.itemIndex++;
                            return (
                                <List.Item
                                    key={`option-${optionIndex}`}
                                    isSelected={highlightedIndex === optionIndex}
                                    isDisabled={getDisabledOption?.(item) || hasReachedMaxValues}
                                    {...itemProps({
                                        item: option,
                                        index: optionIndex,
                                    })}
                                >
                                    {itemComponent({ item: option, getItemsLabel, closeMenu })}
                                </List.Item>
                            );
                        })}
                    </Fragment>,
                );

                return acc;
            },
            { groups: [], itemIndex: 0 },
        ).groups;

    const renderItems = () =>
        items.map((item, index) => (
            <List.Item
                isSelected={highlightedIndex === index}
                key={`option-${index}`}
                isDisabled={getDisabledOption?.(item) || hasReachedMaxValues}
                {...itemProps({ item, index })}
            >
                {itemComponent({ item, getItemsLabel, closeMenu })}
            </List.Item>
        ));

    return (
        <>
            <List as="div" flex="1 1 auto" overflowY="auto" py="xs" position="relative" ref={ref} {...rest}>
                {isOpen && (isGrouped ? renderGroups() : renderItems())}
                {bottomComponent && (
                    <BottomComponent>{runIfFn(bottomComponent, { inputValue, closeMenu })}</BottomComponent>
                )}
            </List>
        </>
    );
});
