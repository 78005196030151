import { ImageProps } from 'next/image';

const ImageRegexp = new RegExp(
  /https?:\/\/blog.landr.com\/([a-z-]+\/|)wp-content\//g
);

/**
 * Some blog posts have a url hardcoded to blog.landr.com where it should be blog-api.landr.com
 * @param url
 */
export function urlRewrite(url: ImageProps['src']): ImageProps['src'] {
  /*
  Sometimes it can be an object
  {
    "src": "/_next/static/media/post-cover-example.448bc592.png",
    "height": 630,
    "width": 1120,
    "blurDataURL": "/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fpost-cover-example.448bc592.png&w=8&q=70",
    "blurWidth": 8,
    "blurHeight": 5
}
   */
  if (typeof url === 'string' || url instanceof String) {
    return url.replaceAll(
      ImageRegexp,
      `${process.env.NEXT_PUBLIC_API_HOST}/wp-content/`
    );
  } else {
    return url;
  }
}
