import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMasterFilled = ({ size, title = 'MasterFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M8.05 19.508V3.764M4.33 19.508V16.5m14.881 3.008v-8.516m-7.44 8.516V8.333m3.72 11.175v-5.871"
        />
    </svg>
);

export default withIcon(SvgMasterFilled);
