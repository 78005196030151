import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { TableProps } from './types';

const Table = styled(Box)<TableProps>`
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    z-index: 0;
`;

interface StyledTable {
    Table: typeof Table;
}

export const Styled: StyledTable = {
    Table,
};
