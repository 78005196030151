import React from 'react';
import { forwardRef } from '../../system';
import { LevelProps } from './types';
import * as Styled from './styled';

const Level = forwardRef<LevelProps, 'div'>((props, ref) => {
    const { children, align = 'center', pinCenter = false, ...rest } = props;
    return (
        <Styled.Level ref={ref} align={align} pinCenter={pinCenter} {...rest}>
            {children}
        </Styled.Level>
    );
});

export default Object.assign(Level, {
    Item: Styled.Item,
    Center: Styled.Center,
    Left: Styled.Left,
    Right: Styled.Right,
});
