import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgGuitarOutlined = ({ size, title = 'GuitarOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.964 16.15a1.454 1.454 0 012.056 0l.514-.514a4.364 4.364 0 00-6.17-6.17l-.515.514a1.454 1.454 0 11-2.056 2.056l-.514.514a4.364 4.364 0 006.17 6.171l.515-.514a1.454 1.454 0 010-2.056zM17.62 4.323l2.057 2.057-2.057 2.057-2.057-2.057 2.057-2.057z"
            clipRule="evenodd"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M16.592 7.408l-4.114 4.114"
        />
        <path
            stroke="currentColor"
            strokeLinecap="square"
            strokeWidth={1.5}
            d="M12.67 12.743a1 1 0 10-1.414-1.414 1 1 0 001.415 1.414z"
        />
        <path fill="currentColor" stroke="currentColor" d="M12.317 11.683a.5.5 0 11-.707.707.5.5 0 01.707-.707z" />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.335 15.636l1.029 1.029"
        />
    </svg>
);

export default withIcon(SvgGuitarOutlined);
