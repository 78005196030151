import React, { useContext } from 'react';
import { useVisible, useBreakpoint } from '../../hooks';
import { Group } from '../../primitives/Group';
import { Drawer } from '../Drawer';
import { Button } from '../Button';
import { Box } from '../../primitives/Box';
import { FiltersInternalContext, defaultStaticText } from './api';
import { StyledFilters, FiltersIcon, StyledDrawerFooter } from './styled';
import { StaticTextInterface } from './types';
import { Filter } from './Filter';

export interface FiltersSubComponents {
    /**
     * Toggle ResponsiveMenu button with certain filter name.
     * Handles toggling logic and corresponds for showing
     * either slide-panel or popover with provided `children`.
     *
     * @param children content of each filter Popover/Drawer.
     * [There are some default components for common use cases](https://maestro.landr.com/components/Filters#listfilter).
     *
     * Note, that the component provides `hideResponsiveMenu` function to it's children.
     * This was made to make it possible to close ResponsiveMenu from inside of the children.
     * Extend `FilterChild` interface with your children props.
     *
     * @param displayName translated visible name of filter on toggle popover button
     * @param appliedFilters applied filters for this certain filter. **Visible for mobiles only**.
     * @param onClearByType callback triggered when user clicks on appliedFilters list **Available for mobiles only**.
     *
     */
    Filter: typeof Filter;
}

export interface FiltersProps {
    /**
     * Function that will be invoked when user clicks on "Clear All"(text by default) button in responsive bottom menu
     */
    onClearAll: () => void;
    /**
     * Displayed Filter Buttons texts for "Apply","Clear all","Go back"
     */
    staticText?: StaticTextInterface;
    /**
     * aria-label property of the <IconButton>
     */
    ariaLabelForIconButton?: string;
    /**
     * Is "Clear all" button visible in responsive bottom menu (in <MobileFiltersList> component)
     */
    isClearAllVisible?: boolean;
}

/**
 * Parent component. Renders a bunch of filters.
 *
 * @param children list of filters wrapped in a Filters.Filter compound component
 * ```
 * <Filters.Filter displayName="Type" appliedFilters={appliedFiltersMock['Type']} onClearByType={() => {}}>
 * ```<FilterUiImplementation />
 * </Filters.Filter>
 * ```
 *
 *  * @param ariaLabelForIconButton aria-label for <IconButton/>
 *  (that will be visible if staticText.filters will not be provided/empty string)
 * @param onClearAll function called when "Clear All" button pressed
 * @param staticText object with static text translations
 * @param isClearAllVisible should "Clear all" button be visible in responsive bottom menu
 * ```
 *   'back': 'Back',
 *   'filters': 'Filters',
 *   'clearFilters': 'Clear Filters',
 *   'close': 'Close',
 * ```
 */
export const Filters: React.FC<FiltersProps> & FiltersSubComponents = ({
    children,
    onClearAll,
    staticText = defaultStaticText,
    ariaLabelForIconButton = 'filter-button',
    isClearAllVisible = true,
}) => {
    const isMobile = useBreakpoint('md');

    return (
        <Group direction="vertical" gutter="xxs">
            <FiltersInternalContext.Provider value={{ staticText }}>
                {isMobile ? (
                    <Group.Item>
                        <MobileFiltersList
                            onClearAll={onClearAll}
                            ariaLabel={ariaLabelForIconButton}
                            isClearAllVisible={isClearAllVisible}
                        >
                            {children}
                        </MobileFiltersList>
                    </Group.Item>
                ) : (
                    <Group.Item>
                        <StyledFilters direction="horizontal" gutter="sm">
                            <Group.Item>
                                <FiltersIcon size="base" title="Filters" />
                            </Group.Item>
                            {children}
                        </StyledFilters>
                    </Group.Item>
                )}
            </FiltersInternalContext.Provider>
        </Group>
    );
};
Filters.Filter = Filter;

interface MobileFiltersListProps {
    children: React.ReactNode;
    onClearAll: () => void;
    ariaLabel: string;
    isClearAllVisible: boolean;
}

/**
 * Drawer onClearAll toggle filters list button for mobile.
 */
const MobileFiltersList: React.FC<MobileFiltersListProps> = ({
    children,
    onClearAll,
    ariaLabel,
    isClearAllVisible,
}) => {
    const { visible, show, hide } = useVisible(false);
    const { staticText } = useContext(FiltersInternalContext);

    return (
        <>
            <Button
                aria-label={ariaLabel}
                onClick={show}
                icon={FiltersIcon}
                isRounded={!!staticText.filters}
                variant="ghost"
                mb={staticText.filters ? 'md' : 0}
            >
                {staticText.filters}
            </Button>
            <Drawer side={'bottom'} isVisible={visible} onClose={hide}>
                <Drawer.Content px={'lg'} py="xs">
                    {children}
                </Drawer.Content>
                <StyledDrawerFooter px="lg" py="sm">
                    <Box>
                        {isClearAllVisible && (
                            <Button variant="secondary" size={'sm'} kind="minimal" onClick={onClearAll}>
                                {staticText.clearFilters}
                            </Button>
                        )}
                    </Box>
                    <Button variant="primary" size={'sm'} kind="minimal" onClick={hide}>
                        {staticText.close}
                    </Button>
                </StyledDrawerFooter>
            </Drawer>
        </>
    );
};
