import styled from '../../utils/styled';
import { Text, TextProps } from '../Text';
import { spacing } from '../../utils';

export type ParagraphProps = TextProps;

export const Paragraph = styled(Text)<ParagraphProps>`
    margin-top: 0;
    margin-bottom: ${spacing('md')};
`;

Paragraph.displayName = 'Paragraph';

Paragraph.defaultProps = {
    as: 'p',
    size: 'md',
    contrast: 'base',
};
