import React, { useCallback } from 'react';
import { Text } from '../Text';
import * as Styled from './styled';
import { PeopleCardProps } from './types';

const PeopleCardFallback = ({ fallback }: { fallback: string }) => (
    <Styled.FallbackWrapper>
        <Text size="jb" fontWeight="bold">
            {fallback}
        </Text>
    </Styled.FallbackWrapper>
);

const PeopleCardImageWrapper: React.FC<PeopleCardProps> = ({ fallback = '', size, src, alt }) => {
    const handleFallback = useCallback(() => <PeopleCardFallback fallback={fallback} />, [fallback]);
    return (
        <Styled.PeopleCardImageWrapper size={size}>
            <Styled.PeopleCardImage src={src!} fallbackHandler={handleFallback} alt={alt} />
        </Styled.PeopleCardImageWrapper>
    );
};

type Props = PeopleCardProps & React.HTMLAttributes<HTMLDivElement>;

export const PeopleCard: React.FC<Props> = ({
    children,
    src,
    alt,
    fallback,
    title,
    description,
    size = 'md',
    ...props
}: PeopleCardProps) => (
    <Styled.PeopleCard {...props}>
        <PeopleCardImageWrapper size={size} fallback={fallback} src={src} alt={alt} />
        {title && <Styled.PeopleCardTitle size="sm">{title}</Styled.PeopleCardTitle>}
        {description && <Styled.PeopleCardDescription size="sm">{description}</Styled.PeopleCardDescription>}
    </Styled.PeopleCard>
);

PeopleCard.displayName = 'PeopleCard';
