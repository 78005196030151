import { FC } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { GET_CATEGORIES } from 'api/category/queries';
import {
  CategoriesQuery,
  CategoriesQueryVariables,
} from 'types/generated/graphql';
import { useGetCurrentLocale } from 'hooks/useGetCurrentLocale';
import { SubjectCard } from './SubjectCard';
import { CATEGORY_SLUGS } from './categorySlugs';

import styles from './Subjects.module.scss';

interface SubjectsProps {
  title?: string;
  subtitle?: string;
}

export const Subjects: FC<SubjectsProps> = ({ title, subtitle }) => {
  const { locale } = useRouter();
  const currentLocale = useGetCurrentLocale();

  const { data } = useQuery<CategoriesQuery, CategoriesQueryVariables>(
    GET_CATEGORIES,
    {
      variables: {
        first: CATEGORY_SLUGS.length,
        where: {
          slug: CATEGORY_SLUGS,
          wpmlLanguage: locale,
        },
      },
      errorPolicy: 'all',
    }
  );

  /**
   * Filter by "currentLocale" as "wpmlLanguage" doesn't work properly
   */
  const subjects =
    data?.categories?.nodes?.filter((item) => {
      return item?.posts?.nodes?.[0]?.locale?.locale === currentLocale;
    }) || [];
  const defaultTitle = title || 'Explore Subjects 👉';
  const defaultSubtitle =
    !title && !subtitle
      ? 'Deep dive on the most pressing subjects in music making'
      : subtitle;

  const SubjectsSubtitle = () => {
    if (!defaultSubtitle) return null;

    return (
      <span
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: defaultSubtitle }}
      ></span>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.listWrapper}>
          <p className={styles.title}>
            {defaultTitle}
            <SubjectsSubtitle />
          </p>

          <div className={styles.cardListWrapper}>
            <div className={styles.cardsList}>
              {subjects.map((item, index) => {
                if (item) {
                  const { slug, name, categoryImage } = item;

                  return (
                    <SubjectCard
                      key={index}
                      slug={slug}
                      name={name}
                      categoryImage={categoryImage}
                    />
                  );
                }

                return null;
              })}
              <div className={styles.overflowSpaceFix} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
