import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgArrowUpOutlined = ({ size, title = 'ArrowUpOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
            <path strokeLinejoin="round" d="M5 11.658L11.996 5 19 11.666" />
            <path d="M12 5.667V19" />
        </g>
    </svg>
);

export default withIcon(SvgArrowUpOutlined);
