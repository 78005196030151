import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { Box } from '../../primitives/Box';
import { forwardRef } from '../../system';
import { ModalSectionProps, ModalProps } from './types';
import { useModalContext } from './ModalContext';

const styles = variant({
    prop: 'size',
    variants: {
        sm: { px: ['sm', 'md', 'lg'], py: ['sm', 'md', 'md'] },
        md: { px: ['sm', 'md', 'xxl'], py: ['sm', 'md', 'lg'] },
        lg: { px: ['sm', 'md', 'xxl'], py: ['sm', 'md', 'lg'] },
    },
});

const StyledModalSection = styled(Box).attrs(() => ({ as: 'section' }))<ModalSectionProps & Pick<ModalProps, 'size'>>`
    ${styles};
`;

export const ModalSection = forwardRef<ModalSectionProps, 'div'>(({ children, ...props }, ref) => {
    const { size } = useModalContext();
    return (
        <StyledModalSection ref={ref} size={size} {...props}>
            {children}
        </StyledModalSection>
    );
});

ModalSection.displayName = 'Modal.Section';
