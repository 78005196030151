import { createApolloClient } from '../apolloClient';
import {
  FooterMenuQuery,
  MenuItemsQuery,
  AlsHeaderSettingsQuery,
} from 'types/generated/graphql';
import { AppContext } from 'next/app';
import { getHeaderMenuItems } from './getHeaderMenuItems';
import { getFooterMenuItems } from './getFooterMenuItems';
import { getAlsHeaderSettings } from './getAlsHeaderSettings';

export interface LayoutData {
  headerMenuItems: MenuItemsQuery['menuItems'];
  footerMenu: FooterMenuQuery;
  alsHeaderSettings: AlsHeaderSettingsQuery['alsHeaderSettings'];
}

// Create new apollo client instead of calling `initializeApolloClient` which returns a shared one.
// We do that to avoid caching all common data in apollo cache, which is then sent to client
const apolloClient = createApolloClient();

export async function getLayoutData({
  router: { locale },
}: AppContext): Promise<LayoutData> {
  const [headerMenuItems, footerMenu, alsHeaderSettings] = await Promise.all([
    getHeaderMenuItems(apolloClient, { locale }),
    getFooterMenuItems(apolloClient, { locale }),
    getAlsHeaderSettings(apolloClient),
  ]);

  return {
    headerMenuItems,
    footerMenu,
    alsHeaderSettings,
  };
}
