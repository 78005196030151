import { FC } from 'react';
import { useGetPosts } from 'hooks/useGetPosts';
import { Cards } from 'ui/Cards';

interface PostCardsProps {
  title?: string;
  postsCount: 2 | 3;
  categoryId?: number | null;
  author?: number | null;
  postIds?: string[] | null;
}

export const PostCards: FC<PostCardsProps> = ({
  title,
  postsCount,
  categoryId,
  author,
  postIds,
}) => {
  const posts = useGetPosts({ count: postsCount, author, categoryId, postIds });

  return <Cards posts={posts} title={title} />;
};
