import styled from 'styled-components';
import { Box } from '../Box';
import { spacing } from '../../utils';
import { GroupItemProps, GroupProps } from './types';

const Item = styled(Box)<GroupItemProps>``;

Item.displayName = 'Group.Item';

const Group = styled(Box)<GroupProps>`
    display: flex;
    flex-direction: ${({ direction }): string => {
        switch (direction) {
            case 'vertical':
                return 'column';
            case 'horizontal-reverse':
                return 'row-reverse';
            case 'vertical-reverse':
                return 'column-reverse';
            default:
                return 'row';
        }
    }};
    margin: 0;

    > ${Item}:not(:last-child) {
        margin-right: ${({ gutter = 'md', direction }) => direction === 'horizontal' && spacing(gutter)};
        margin-bottom: ${({ gutter = 'md', direction }) => direction === 'vertical' && spacing(gutter)};
        margin-left: ${({ gutter = 'md', direction }) => direction === 'horizontal-reverse' && spacing(gutter)};
        margin-top: ${({ gutter = 'md', direction }) => direction === 'vertical-reverse' && spacing(gutter)};
    }
`;

const Styled = {
    Item,
    Group,
};

export { Styled };
