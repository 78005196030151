import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgBassOutlined = ({ size, title = 'BassOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M17.625 8.262a.4.4 0 01-.286-.12l-1.52-1.521a.407.407 0 010-.572l2.884-2.93a.404.404 0 01.572 0l.637.637.932.933a.404.404 0 010 .572l-2.928 2.887a.44.44 0 01-.29.114zM7.477 20.705c-1.12 0-2.269-.463-3.149-1.383-1.69-1.756-1.78-4.527-.2-6.17a3.846 3.846 0 011.69-1.023c.765-.224 1.41-.832 1.72-1.633a3.7 3.7 0 01.779-1.226l.025-.026a1.754 1.754 0 011.87-.432c.654.24 1.12.832 1.204 1.55.025.207.075.415.145.613l.81 1.19c.615.53 1.41.743 2.18.577a.35.35 0 01.334.104.4.4 0 01.11.348c-.13.77-.47 1.46-.995 2.001-.34.354-.735.624-1.18.811a2.711 2.711 0 00-1.569 1.788 4.068 4.068 0 01-.985 1.757 3.934 3.934 0 01-2.789 1.154z"
        />
        <path
            fill="currentColor"
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M6.029 15.22l1.716 1.698L6.03 15.22z"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.067 13.053l5.396-5.34"
        />
    </svg>
);

export default withIcon(SvgBassOutlined);
