import React, { useEffect, useMemo, useState } from 'react';
import { useCombobox, useMultipleSelection, UseComboboxStateChange } from 'downshift';
import { CaretDownOutlined, CloseOutlined } from '../../../../assets/icons';
import { forwardRef } from '../../../../system';
import { Chip } from '../../../Chip';
import { Box } from '../../../../primitives/Box';
import { IconButton } from '../../../IconButton';
import { Input } from '../../../Input';
import { AutocompleteDropdownProvider, Dropdown, Item, Items, NoResults } from '../shared';
import { mergeRefs, runIfFn } from '../../../../utils';
import { MultiSelectProps } from './types';

const defaultGetItemsLabel = (item) => item;

const defaultValueComponent = ({ label, onRemove, ...rest }) => (
    <Box mx="xxs" my="6px">
        <Chip onDismiss={onRemove} {...rest}>
            {label}
        </Chip>
    </Box>
);

export const MultiSelect = forwardRef<MultiSelectProps, 'input'>((props, ref) => {
    const {
        items = [],
        getItemsLabel = defaultGetItemsLabel,
        initialSelectedItems = [],
        shape,
        placeholder,
        onChange,
        onReset,
        itemComponent = Item,
        noResultsComponent: NoResultsComponent = NoResults,
        noResultsLabel = 'No results match this query',
        bottomComponent,
        groupBy,
        getDisabledOption,
        maxValues = -1,
        valueComponent = defaultValueComponent,
        onOpen,
        onClose,
        isLoading,
        isDisabled,
        loadingComponent,
        ...rest
    } = props;

    const sortedOptions = useMemo(
        () => (groupBy ? items.sort((a, b) => -groupBy(b).localeCompare(groupBy(a))) : items),
        [groupBy, items],
    );

    const [inputValue, setInputValue] = useState<string | undefined>('');

    const [comboBoxRef, setComboBoxRef] = useState(null);

    const { addSelectedItem, removeSelectedItem, selectedItems, getSelectedItemProps, getDropdownProps, reset } =
        useMultipleSelection({
            initialSelectedItems,
        });

    const [hasReachedMaxValues, setHasReachedMaxValues] = useState<boolean>(false);

    useEffect(() => {
        if (maxValues <= -1) return;

        setHasReachedMaxValues(selectedItems?.length >= maxValues);
    }, [maxValues, selectedItems]);

    const getFilteredItems = (items) =>
        items.filter(
            (item) =>
                selectedItems.indexOf(item) < 0 &&
                getItemsLabel(item).toLowerCase().startsWith(inputValue?.toLowerCase()),
        );

    const handleIsOpenChange = ({ isOpen }: UseComboboxStateChange<any>) => {
        switch (isOpen) {
            case true:
                onOpen?.();
                break;
            case false:
                onClose?.();
                break;
        }
    };

    const handleReset = () => {
        onReset?.();
        reset();
    };

    useEffect(() => {
        onChange?.(selectedItems);
    }, [onChange, selectedItems]);

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        getToggleButtonProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        toggleMenu,
    } = useCombobox({
        selectedItem: null,
        items: getFilteredItems(sortedOptions),
        inputValue,
        itemToString: getItemsLabel,
        stateReducer: (_state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;

            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        isOpen: true, // keep the menu open after selection.
                    };
            }

            return changes;
        },
        onStateChange: ({ selectedItem, type, inputValue }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(inputValue);
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        setInputValue('');
                        addSelectedItem(selectedItem);
                    }
                    break;
                default:
                    break;
            }
        },
        onIsOpenChange: handleIsOpenChange,
    });

    const handleOpenMenu = () => {
        if (isDisabled) {
            return;
        } else {
            openMenu();
        }
    };

    return (
        <AutocompleteDropdownProvider
            value={{
                isOpen,
                items: getFilteredItems(sortedOptions),
                inputValue,
                groupBy,
                hasReachedMaxValues,
                getDisabledOption,
            }}
        >
            <Input
                isMultiline
                placeholder={placeholder}
                shape={shape}
                isDisabled={isDisabled}
                wrapperProps={{ ref: mergeRefs(ref, setComboBoxRef), onClick: handleOpenMenu }}
                addonLeft={selectedItems.map((selectedItem, index) =>
                    runIfFn(valueComponent, {
                        item: selectedItem,
                        label: getItemsLabel(selectedItem),
                        onRemove: () => removeSelectedItem(selectedItem),
                        key: `selected-item-${index}`,
                        ...getSelectedItemProps({ selectedItem, index }),
                    }),
                )}
                addonRight={[
                    selectedItems.length > 0 && (
                        <IconButton
                            key="clear-button"
                            size="sm"
                            variant="subtle"
                            isRounded={shape === 'rounded'}
                            label=""
                            hideTooltip
                            icon={CloseOutlined}
                            mr="xxs"
                            onClick={handleReset}
                        />
                    ),
                    <IconButton
                        isDisabled={isDisabled}
                        key="toggle-button"
                        size="sm"
                        variant="subtle"
                        isRounded={shape === 'rounded'}
                        label=""
                        hideTooltip
                        icon={CaretDownOutlined}
                        mr="xxs"
                        {...getToggleButtonProps({ onClick: toggleMenu })}
                    />,
                ]}
                {...getInputProps({
                    onFocus: () => {
                        if (!isOpen) {
                            handleOpenMenu();
                        }
                    },
                })}
                {...rest}
            />
            <Dropdown anchorRef={comboBoxRef} {...getDropdownProps()}>
                <Items
                    itemComponent={itemComponent}
                    itemProps={getItemProps}
                    getItemsLabel={getItemsLabel}
                    noResultsComponent={NoResultsComponent}
                    noResultsLabel={noResultsLabel}
                    highlightedIndex={highlightedIndex}
                    isLoading={isLoading}
                    loadingComponent={loadingComponent}
                    bottomComponent={bottomComponent}
                    {...getMenuProps()}
                />
            </Dropdown>
        </AutocompleteDropdownProvider>
    );
});
