import styled from '../../utils/styled';
import { palette, spacing, themeMode, zIndex } from '../../utils';
import { Box, Level } from '../../primitives';
import { DropdownMenu } from '../DropdownMenu';
import { Checkbox } from '../Checkbox';

export const Item = styled(DropdownMenu.Item)`
    padding-left: ${spacing('xs')};
    padding-right: ${spacing('xs')};
`;

export const Footer = styled(Level)`
    padding: 0 ${spacing('sm')};
    bottom: 0px;
    overflow: hidden;
    height: 52px;
    margin-bottom: -${spacing('xxs')};
`;

export const Header = styled(Box)`
    position: sticky;
    top: 0;
    padding-top: ${spacing('xxs')};
    background-color: ${themeMode({ light: palette('neutral.0'), dark: palette('neutral.800') })};
    z-index: ${zIndex('overlay')};
`;

export const FilterDropdownCheckbox = styled(Checkbox)`
    pointer-events: none;
`;
