import React, { FC, useContext } from 'react';
import { LayoutData } from '../../lib/layoutData';

export const LayoutContext = React.createContext<LayoutData>({
  headerMenuItems: null,
  alsHeaderSettings: null,
  footerMenu: {
    footer1: null,
    footer2: null,
    footer3: null,
  },
});

export const useLayoutContext = () => {
  return useContext<LayoutData>(LayoutContext);
};

export interface LayoutProviderProps {
  value: LayoutData;
}

export const LayoutProvider: FC<LayoutProviderProps> = ({
  value,
  children,
}) => {
  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};
