import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgGrandPianoOutlined = ({ size, title = 'GrandPianoOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.2 16.8v-2.4m3.2 2.4v-2.4m3.2 2.4v-2.4m3.2 2.4v-2.4M4 14.4h16"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M20 12.8a4 4 0 00-4-4h-.104a2.935 2.935 0 01-2.674-1.868A4.8 4.8 0 004 8.8V20h16v-7.2z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgGrandPianoOutlined);
