import styled, { css } from '../../utils/styled';
import { palette, spacing } from '../../utils';
import { Box } from '../../primitives/Box';
import { NavBarProps, NavBarMenuProps } from './types';

export const NavBar = styled(Box)<Omit<NavBarProps, 'menuLabel'>>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--navbar-height);
    width: 100%;
    padding: 0 ${spacing('lg')};
    border: none;
    color: ${palette('neutral.0')};
    background-color: ${palette('neutral.900')};
`;

export const Brand = styled(Box)<{ href: string }>`
    display: flex;
    align-items: center;

    ${({ href }) =>
        href &&
        css`
            text-decoration: none;
            transition: color 200ms ease-in;
            color: ${palette('neutral.50')};
            cursor: pointer;

            &:hover {
                color: ${palette('neutral.400')};
            }
        `}
`;

export const Menu = styled(Box).attrs(({ justify }: NavBarMenuProps) => ({
    justifyContent: justify,
}))<NavBarMenuProps>`
    display: flex;
    flex: 1 1 0;
    align-items: center;
    width: 100%;
`;

export const NavBarDrawer = styled(Box)<Omit<NavBarProps, 'menuLabel'>>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--navbar-height);
    width: 100%;
    padding: 0 ${spacing('lg')};
    border: none;
    color: ${palette('neutral.0')};
    background-color: ${palette('neutral.900')};

    ${({ theme }): string => theme.breakpoint('sm')`
        padding: 0 ${spacing('xs')};
    `}
`;

export const Divider = styled.hr`
    border-width: 0px 0px 0px 1px;
    border-image: initial;
    border-color: inherit;
    border-style: solid;
    height: 28px;
    margin: ${spacing('md')};
    color: ${palette('neutral.700')};
`;
