import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFolderFilled = ({ size, title = 'FolderFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M15.349 8.292L10.854 4H3.689C2.579 4 2 4.534 2 5.619v12.986C2 19.681 2.57 20 3.681 20h16.664C21.458 20 22 19.661 22 18.605V8.292h-6.65z"
        />
    </svg>
);

export default withIcon(SvgFolderFilled);
