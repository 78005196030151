export enum EventIds {
  // Global
  RootComponentUnhandledError = 37000,
  ComponentUnhandledError = 37001,
  ApolloInternalServerError = 37004,
  ApolloNetworkError = 37005,
  ApolloError = 37006,
  OneSignalRegisterError = 37007,

  // Component errors
  ParseError = 37100,

  // Revalidate
  RevalidateError = 37200,
  RevalidateWarning = 37201,
  RevalidateSuccess = 37202,

  // Pages fetch error
  PostPageError = 37300,
  CategoryPageError = 37301,
  NotFoundPageError = 37302,
  HomePageError = 37303,
  PostPageYouTubeSeoError = 37304,
  YouTubeSeoExtractorError = 37305,
  AuthorProfileError = 37306,
}
