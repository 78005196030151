import { FC, ComponentPropsWithoutRef } from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  loading,
  disabled,
  className,
  ...rest
}) => {
  const LoadingState = () => {
    if (!loading) return null;

    return (
      <span data-testid="loading-button" className={styles.loaderWrapper}>
        <span className={styles.loader} />
      </span>
    );
  };

  return (
    <button
      className={cn(
        styles.root,
        {
          [styles.loading]: loading,
        },
        className
      )}
      disabled={disabled}
      aria-label={loading ? 'Loading' : undefined}
      {...rest}
    >
      <LoadingState />
      <span
        className={cn(styles.label, {
          [styles.hidden]: loading,
        })}
      >
        {children}
      </span>
    </button>
  );
};
