import { AlsHeaderSettingsHeadersettingspagefields } from 'types/generated/graphql';
import { useGetCurrentLocale } from 'hooks/useGetCurrentLocale';
import { useLayoutContext } from 'components';

type UseGetGlobalSettings =
  | AlsHeaderSettingsHeadersettingspagefields
  | AlsHeaderSettingsHeadersettingspagefields['french']
  | AlsHeaderSettingsHeadersettingspagefields['german']
  | AlsHeaderSettingsHeadersettingspagefields['japanese']
  | AlsHeaderSettingsHeadersettingspagefields['spanish']
  | AlsHeaderSettingsHeadersettingspagefields['portuguese']
  | AlsHeaderSettingsHeadersettingspagefields['italian']
  | undefined;

export const useGetGlobalSettings = (): UseGetGlobalSettings => {
  const currentLocale = useGetCurrentLocale();

  const { alsHeaderSettings } = useLayoutContext();

  const getCurrentLocaleSettings = () => {
    const en_US = alsHeaderSettings?.headerSettingsPageFields;

    switch (currentLocale) {
      case 'fr_FR': {
        return en_US?.french;
      }
      case 'de_DE': {
        return en_US?.german;
      }
      case 'ja': {
        return en_US?.japanese;
      }
      case 'es_ES': {
        return en_US?.spanish;
      }
      case 'pt_BR': {
        return en_US?.portuguese;
      }
      case 'it_IT': {
        return en_US?.italian;
      }
      default: {
        return en_US;
      }
    }
  };

  const settings = getCurrentLocaleSettings();

  return settings;
};
