import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgListMusicNoteOutlined = ({ size, title = 'ListMusicNoteOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M13.5 4.5V17M11 19.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zm-6.5-14h6m-6 3h6m-6 3h6"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M13.5 5.444A3.484 3.484 0 0016 6.5a3.5 3.5 0 013.5 3.5 3.483 3.483 0 01-.051.5A3.482 3.482 0 0017 9.5 3.5 3.5 0 0113.5 6v-.556z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgListMusicNoteOutlined);
