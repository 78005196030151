import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCloudUploadOutlined = ({ size, title = 'CloudUploadOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
            <path d="M12.5 9.25v12" />
            <path strokeLinejoin="round" d="M16 12.5L12.5 9 9 12.5" />
            <path d="M18.859 16.25c2.697 0 4.891-1.46 4.891-4.559 0-2.954-1.96-4.307-4.448-4.307C19.61 4.827 18.555.75 12.5.75c-1.75 0-3.51.622-4.845 1.866a6.204 6.204 0 00-1.85 3.27c-2.59.468-4.555 2.576-4.555 5.121 0 2.888 2.527 5.243 5.625 5.243" />
        </g>
    </svg>
);

export default withIcon(SvgCloudUploadOutlined);
