import React from 'react';
import { ChipKind, ChipSize, IconSize } from './types';

const convertSizeForIcon = (size: ChipSize): IconSize => {
    let iconSize: IconSize = 'sm';

    switch (size) {
        case 'sm':
            iconSize = 'xs';
            break;

        case 'md':
            iconSize = 'xs';
            break;

        case 'lg':
            iconSize = 'sm';
            break;
    }

    return iconSize;
};

const useChip = ({
    isChecked = false,
    isDisabled = false,
    isError = false,
    isLoading = false,
    kind = 'solid',
    media,
    onDismiss,
    size,
}: {
    isChecked?: boolean;
    isDisabled?: boolean;
    isError?: boolean;
    isLoading?: boolean;
    kind?: ChipKind;
    media?: React.ReactNode;
    onDismiss?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    size: ChipSize;
}) => {
    const iconSize = convertSizeForIcon(size);

    let MediaComponent: React.ReactNode;
    if (!!media && React.isValidElement(media)) {
        // TODO: [DS-991] Create tokens for media sizes because for now we rely on the Avatar sizes
        const mediaSize = size === 'sm' ? 'xxs' : 'xs';
        MediaComponent = React.cloneElement(media, { size: mediaSize });
    }

    const handleDismiss = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        onDismiss?.(event);
    };

    let chipKind = kind;
    if (isChecked) {
        chipKind = kind === 'solid' ? 'outline' : 'solid';
    }

    const showError = !!isError;
    const showDismiss = !isDisabled && !!onDismiss;
    const showProgress = !isError && !!isLoading;

    return { iconSize, MediaComponent, handleDismiss, chipKind, showError, showDismiss, showProgress };
};

export default useChip;
