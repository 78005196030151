import styled, { css } from '../../utils/styled';
import { themeMode, radius, variant, palette } from '../../utils';
import { Box } from '../../primitives/Box';
import { AvatarProps } from './types';

const avatarSizes = variant({
    prop: 'size',
    variants: {
        xxs: {
            height: '16px',
            width: '16px',
        },
        xs: {
            height: '24px',
            width: '24px',
        },
        sm: {
            height: '36px',
            width: '36px',
        },
        md: {
            height: '48px',
            width: '48px',
        },
        lg: {
            height: '64px',
            width: '64px',
        },
        xl: {
            height: '80px',
            width: '80px',
        },
        xxl: {
            height: '112px',
            width: '112px',
        },
        jb: {
            height: '176px',
            width: '176px',
        },
        mg: {
            height: '240px',
            width: '240px',
        },
    },
});

export const Avatar = styled(Box)<AvatarProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: ${({ isRound }) => radius(isRound ? 'round' : 'sm')};

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: ${({ isRound }) => radius(isRound ? 'round' : 'sm')};
    }

    ${themeMode({
        dark: css`
            background-color: ${palette('neutral.700')};
            color: ${palette('neutral.50')};
        `,
        light: css`
            background-color: ${palette('neutral.300')};
            color: ${palette('neutral.900')};
        `,
    })};

    ${avatarSizes}
`;
