import styled from '../../../utils/styled';
import { Button } from '../../Button';
import { palette, spacing, fontWeight } from '../../../utils';

export const SelectedFilterItem = styled(Button)`
    /* we need max-width not to break layout on mobiles in case tag name will be too long */
    max-width: 300px;
    -webkit-font-smoothing: antialiased;
    background-color: ${palette('neutral.800')};
    font-weight: ${fontWeight('regular')};
    margin-bottom: ${spacing('md')};
    svg {
        color: ${palette('neutral.400')};
    }
    &:hover {
        background-color: ${palette('neutral.600')};
    }
`;
