import { FC, AnchorHTMLAttributes, useState } from 'react';
import NextLink from 'next/link';
import NextImage, { ImageProps as NextImageProps } from 'next/image';

import styles from './BasicImage.module.scss';
import { urlRewrite } from '../../utils/urlRewrite';

export interface BasicImageProps extends NextImageProps {
  imageHref?: string;
  imageHrefTarget?: string;
  fallbackSrc?: string;
}

interface ImageLinkProps {
  href: string;
  target?: AnchorHTMLAttributes<any>['target'];
}

const ImageLink: FC<ImageLinkProps> = ({ children, href, target }) => {
  if (target && target === '_blank') {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return <NextLink href={href}>{children}</NextLink>;
};

export const BasicImage: FC<BasicImageProps> = ({
  layout = 'fill',
  imageHref,
  imageHrefTarget,
  src,
  fallbackSrc,
  width,
  height,
  alt,
}) => {
  let ImageComponent;
  src = urlRewrite(src);
  const [imgSrc, setImgSrc] = useState(src);
  // kludge to create a responsive image
  // https://github.com/vercel/next.js/discussions/18739#discussioncomment-344932
  if (layout === 'fill') {
    ImageComponent = (
      <div data-testid="fill image" className={styles.root}>
        <NextImage
          className={styles.image}
          alt={alt}
          fill
          width={width}
          height={height}
          src={imgSrc}
          overrideSrc={imgSrc as string}
          onError={() => {
            if (fallbackSrc) {
              setImgSrc(fallbackSrc);
            }
          }}
          style={{
            maxWidth: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </div>
    );
  } else {
    ImageComponent = (
      <NextImage alt={alt} width={width} height={height} src={src} />
    );
  }

  const Wrapper: FC = ({ children }) => {
    return imageHref ? (
      <div className={styles.imageLink}>
        <ImageLink href={imageHref} target={imageHrefTarget}>
          {children}
        </ImageLink>
      </div>
    ) : (
      <>{children}</>
    );
  };

  return <Wrapper>{ImageComponent}</Wrapper>;
};
