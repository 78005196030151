import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgDrumsOutlined = ({ size, title = 'DrumsOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M4 9.455V16c0 1.807 3.582 3.273 8 3.273s8-1.466 8-3.273V9.455"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M19.273 4L9.09 9.09M4.727 4L14.91 9.09M6.182 7.214C4.833 7.799 4 8.586 4 9.454c0 1.808 3.582 3.273 8 3.273s8-1.465 8-3.272c0-.869-.834-1.656-2.182-2.241M12 19.273v-3.637m-5.09-.66v3.548m10.18-3.548v3.548"
        />
    </svg>
);

export default withIcon(SvgDrumsOutlined);
