import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLandrFx = ({ size, title = 'LandrFx', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <rect width={42.67} height={45.33} x={2.67} y={1.33} fill="#142739" rx={6} />
        <circle cx={24} cy={21.33} r={12} fill="#F9FAFB" />
        <path stroke="#142739" strokeLinecap="square" strokeWidth={3} d="M34.667 11.611L28 18.278" />
        <circle cx={37.33} cy={38.67} r={2.67} fill="#435261" />
        <circle cx={10.67} cy={38.67} r={2.67} fill="#3C6ED7" />
    </svg>
);

export default withIcon(SvgLandrFx);
