import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFacebookFilled = ({ size, title = 'FacebookFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="currentColor" fillRule="evenodd">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M15.823 12.79l.427-3.2H13V7.547c0-.927.171-1.558 1.5-1.558h1.848V3.126A22.867 22.867 0 0013.878 3c-2.442 0-4.115 1.492-4.115 4.23v2.36H7v3.2h2.75V21H13v-8.21h2.823z" />
        </g>
    </svg>
);

export default withIcon(SvgFacebookFilled);
