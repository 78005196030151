import { gql } from '@apollo/client';

export const GET_REDIRECTS = gql`
  query GetRedirects {
    redirection {
      redirects {
        groupId
        groupName
        origin
        target
        type
        matchType
      }
    }
  }
`;
