import { FC } from 'react';
import { ProductFeatureType } from 'types/feature';
import { Position } from 'components/Common';
import { Button, AudioSample, BasicImage } from 'ui';

import styles from './ProductFeatureBoxed.module.scss';

export interface ProductFeatureBoxedProps {
  featureType: ProductFeatureType;
  title: string;
  subtitle: string;
  linkLabel: string;
  link: string;
  image: string;
  audio: string;
}

export const ProductFeatureBoxed: FC<ProductFeatureBoxedProps> = ({
  featureType,
  title,
  subtitle,
  linkLabel,
  link,
  image,
  audio,
}) => {
  const ProductFeatureBoxedSubtitle = () => {
    if (!subtitle) return null;

    return (
      <>
        <br />
        <p
          className={styles.subtitle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></p>
      </>
    );
  };

  return (
    <Position position="inline">
      <div className={styles.root}>
        <div className={styles.body}>
          <span
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></span>
          <ProductFeatureBoxedSubtitle />

          <a
            className={styles.link}
            href={link}
            target="_blank"
            rel="noreferrer"
            download
          >
            <Button>{linkLabel}</Button>
          </a>
        </div>

        <div className={styles.imageWrapper}>
          {featureType === 'audio_feature' && audio ? (
            <AudioSample src={audio} image={image} />
          ) : (
            <BasicImage src={image} alt="Product image" />
          )}
        </div>
      </div>
    </Position>
  );
};
