import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCheckCircleOutlined = ({ size, title = 'CheckCircleOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M15 9l-3.748 6L9 12.75"
            vectorEffect="non-scaling-stroke"
        />
        <circle cx={12} cy={12} r={8.25} stroke="currentColor" strokeWidth={1.5} vectorEffect="non-scaling-stroke" />
    </svg>
);

export default withIcon(SvgCheckCircleOutlined);
