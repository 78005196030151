import React, { Children, cloneElement, isValidElement } from 'react';
import { Group } from '../../primitives/Group';
import { ButtonGroupProps } from './types';

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, isStacked, spacing = 'md', sx, ...props }) => {
    return (
        <Group direction={isStacked ? 'vertical' : 'horizontal'} gutter={spacing} sx={sx}>
            {Children.map(children, (child) => {
                if (!isValidElement(child)) {
                    return;
                }
                return <Group.Item>{cloneElement(child, props)}</Group.Item>;
            })}
        </Group>
    );
};
