import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgFileOutlined = ({ size, title = 'FileOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path d="M18.501 8.75H14.5c-.69 0-1.249-.553-1.249-1.24V3.5" />
            <path
                strokeLinejoin="round"
                d="M19.25 8.75v11.257a1.24 1.24 0 01-1.249 1.243H6c-.69 0-1.249-.554-1.249-1.243V3.993c0-.687.55-1.243 1.249-1.243h7.251l6 6z"
            />
        </g>
    </svg>
);

export default withIcon(SvgFileOutlined);
