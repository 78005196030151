import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgProductPluginsOutlined = ({ size, title = 'ProductPluginsOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5}>
            <path d="M15.5 7.5h4m-8-2h4v4h-4a2 2 0 01-2-2h0a2 2 0 012-2z" />
            <path d="M8.5 19.5h8a3 3 0 003-3h0a3 3 0 00-3-3h-9a3 3 0 01-3-3h0a3 3 0 013-3" />
        </g>
    </svg>
);

export default withIcon(SvgProductPluginsOutlined);
