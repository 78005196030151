import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgGiftOutlined = ({ size, title = 'GiftOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M5 11h14.5v8.25c0 .69-.56 1.25-1.25 1.25h-12c-.69 0-1.25-.56-1.25-1.25V11z"
            clipRule="evenodd"
        />
        <path stroke="currentColor" strokeLinejoin="round" strokeWidth={1.5} d="M20.5 7.5H4V11h16.5V7.5z" />
        <path stroke="currentColor" strokeWidth={1.5} d="M12.25 20.75v-16" />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M14.5 7.5a2.25 2.25 0 10-2.25-2.25m0 0A2.25 2.25 0 1010 7.5"
        />
    </svg>
);

export default withIcon(SvgGiftOutlined);
