import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgWindowsFilled = ({ size, title = 'WindowsFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M20 12.438V20l-8.669-1.224v-6.338H20zm-9.457-.084v6.32L4 17.761v-5.407h6.543zm0-6.966v6.301H4V6.283l6.543-.895zM20 4v7.635h-8.669V5.26L20 4z"
        />
    </svg>
);

export default withIcon(SvgWindowsFilled);
