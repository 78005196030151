import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgTrashOutlined = ({ size, title = 'TrashOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinejoin="round" strokeWidth={1.5}>
            <path d="M6 6.75h12v11.508c0 1.1-.902 1.992-2.009 1.992H8.01A1.996 1.996 0 016 18.258V6.75z" />
            <path strokeLinecap="round" d="M15 6.75h5.25-16.5H9a3 3 0 016 0zM14 10v7-7zm-4 0v7-7z" />
        </g>
    </svg>
);

export default withIcon(SvgTrashOutlined);
