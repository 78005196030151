import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPlugins = ({ size, title = 'Plugins', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="#142739" d="M17.625 1.077L3 9.525l15 8.656 15-8.656-14.625-8.448a.75.75 0 00-.75 0z" />
        <path
            fill="#435261"
            d="M14.25 3.212v5.22c0 1.242 1.68 2.25 3.75 2.25 2.07 0 3.75-1.008 3.75-2.25v-5.22h-7.5z"
        />
        <path
            fill="#142739"
            d="M18 5.431c2.071 0 3.75-1.007 3.75-2.25 0-1.242-1.679-2.25-3.75-2.25-2.071 0-3.75 1.008-3.75 2.25 0 1.243 1.679 2.25 3.75 2.25z"
        />
        <path fill="#32CBCB" d="M33 9.525l-15 8.656v17.317l14.61-8.37a.75.75 0 00.376-.65L33 9.524z" />
        <path
            fill="#28A2A2"
            d="M32.865 22.433l-4.547-2.625c-1.076-.62-2.787.33-3.825 2.123-1.037 1.794-1.002 3.75.076 4.373l4.546 2.625 3.75-6.496z"
        />
        <path
            fill="#32CBCB"
            d="M32.939 26.806c1.035-1.793 1.002-3.75-.074-4.372-1.076-.622-2.788.329-3.823 2.122-1.036 1.794-1.003 3.752.073 4.373 1.077.621 2.788-.329 3.824-2.123z"
        />
        <path fill="#435261" d="M18 35.501v-17.32L3 9.525l-.053 16.836a.75.75 0 00.375.652L18 35.5z" />
        <path
            fill="#2C3C4D"
            d="M6.884 28.928l4.547-2.625c1.076-.62 1.109-2.579.075-4.372-1.034-1.794-2.747-2.745-3.825-2.123l-4.547 2.625 3.75 6.495z"
        />
        <path
            fill="#435261"
            d="M6.884 28.928c1.076-.62 1.11-2.579.074-4.372-1.036-1.794-2.748-2.744-3.824-2.123-1.076.622-1.11 2.58-.074 4.373 1.036 1.793 2.748 2.744 3.824 2.122z"
        />
    </svg>
);

export default withIcon(SvgPlugins);
