import React, { PropsWithChildren } from 'react';
import { BoxProps } from '../Box';
import { TruncateByLinesProps } from './types';
import { Styled } from './styled';

type Props = TruncateByLinesProps & BoxProps & React.HTMLAttributes<HTMLElement>;

/**
 *
 * > Use a `Box` with the `nbOfLine` prop
 *
 */

export const TruncateByLines: React.FC<Props> = ({ limit, ...props }: PropsWithChildren<TruncateByLinesProps>) => (
    <Styled.TruncateByLines limit={limit} {...props} />
);

TruncateByLines.displayName = 'TruncateByLines';
