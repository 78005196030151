import styled, { css } from '../../utils/styled';
import { MaestroStyledComponent, palette, spacing, variant, color, baseUnit, themeMode, opacity } from '../../utils';
import { Box } from '../../primitives/Box';
import { DismissButton } from '../DismissButton';
import { ChipProps } from './types';

type StyledChipProps = ChipProps & { showDismiss: boolean };

const paletteStyles = {
    base: variant({
        prop: 'palette',
        compoundProp: 'kind',
        variants: {
            default: {
                outline: {
                    borderColor: themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') }),
                    backgroundColor: 'transparent',
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
                solid: {
                    borderColor: themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.200'),
                        dark: palette('neutral.700'),
                    }),
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
            },
            secondary: {
                outline: {
                    borderColor: themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') }),
                    backgroundColor: 'transparent',
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
                solid: {
                    borderColor: themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                    color: themeMode({
                        light: palette('neutral.50'),
                        dark: palette('neutral.900'),
                    }),
                },
            },
        },
    }),
    hover: variant({
        prop: 'palette',
        compoundProp: 'kind',
        variants: {
            default: {
                outline: {
                    borderColor: themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.100'),
                        dark: palette('neutral.800'),
                    }),
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
                solid: {
                    borderColor: themeMode({ light: palette('neutral.300'), dark: palette('neutral.500') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.300'),
                        dark: palette('neutral.500'),
                    }),
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
            },
            secondary: {
                outline: {
                    borderColor: themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.600'),
                        dark: palette('neutral.300'),
                    }),
                    color: themeMode({
                        light: palette('neutral.50'),
                        dark: palette('neutral.900'),
                    }),
                },
                solid: {
                    borderColor: themeMode({ light: palette('neutral.700'), dark: palette('neutral.200') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.700'),
                        dark: palette('neutral.200'),
                    }),
                    color: themeMode({
                        light: palette('neutral.50'),
                        dark: palette('neutral.900'),
                    }),
                },
            },
        },
    }),
    disabled: variant({
        prop: 'palette',
        compoundProp: 'kind',
        variants: {
            default: {
                outline: {
                    borderColor: themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') }),
                    backgroundColor: 'transparent',
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
                solid: {
                    borderColor: themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.200'),
                        dark: palette('neutral.700'),
                    }),
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
            },
            // secondary have the same style as default when disabled
            secondary: {
                outline: {
                    borderColor: themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') }),
                    backgroundColor: 'transparent',
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
                solid: {
                    borderColor: themeMode({ light: palette('neutral.200'), dark: palette('neutral.700') }),
                    backgroundColor: themeMode({
                        light: palette('neutral.200'),
                        dark: palette('neutral.700'),
                    }),
                    color: themeMode({
                        light: palette('neutral.900'),
                        dark: palette('neutral.50'),
                    }),
                },
            },
        },
    }),
    error: variant({
        prop: 'palette',
        variants: {
            default: {
                borderColor: themeMode({
                    light: palette('danger.500'),
                    dark: palette('danger.500'),
                }),
            },
            secondary: {
                borderColor: themeMode({
                    light: palette('danger.500'),
                    dark: palette('danger.500'),
                }),
            },
        },
    }),
};

const sizeStyles = variant({
    prop: 'size',
    variants: {
        sm: {
            fontSize: 'md',
            height: baseUnit(6),
            py: 0,
            px: 'xxs',
        },
        md: {
            fontSize: 'md',
            height: baseUnit(9),
            py: 0,
            px: 'xs',
        },
        lg: {
            fontSize: 'lg',
            height: baseUnit(12),
            py: 0,
            px: 'sm',
        },
    },
});

const shapeStyles = variant({
    prop: 'shape',
    variants: {
        default: {
            borderRadius: 'sm',
        },
        rounded: {
            borderRadius: 'round',
        },
    },
});

const showDismissStyles = variant({
    prop: 'showDismiss',
    compoundProp: 'size',
    variants: {
        true: {
            sm: { paddingRight: 'xxs' },
        },
    },
});

export const Chip: MaestroStyledComponent<StyledChipProps> = styled(Box).attrs<StyledChipProps>(
    ({ onClick, isDisabled, isChecked, isLoading }) => ({
        'aria-disabled': isDisabled || isLoading,
        disabled: isDisabled || isLoading,
        'aria-pressed': onClick ? isChecked : null,
        'aria-busy': isLoading,
        role: onClick ? 'button' : null,
    }),
)<StyledChipProps>`
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    user-select: none;

    background-color: transparent;
    border: 1px solid transparent;

    color: ${color('text.base')};

    ${paletteStyles.base};
    ${sizeStyles};
    ${shapeStyles};
    ${showDismissStyles};

    ${({ onClick }: StyledChipProps): any =>
        !!onClick &&
        css`
            cursor: pointer;

            &:hover {
                ${paletteStyles.hover};
            }

            &[aria-disabled='true'] {
                pointer-events: none;
                opacity: ${opacity('subtle')};
                ${paletteStyles.disabled};
            }
        `};

    ${({ isError }: StyledChipProps): any =>
        !!isError &&
        css`
            ${paletteStyles.error};
        `};

    ${({ isMultiline }) =>
        isMultiline &&
        css`
            margin: ${spacing('xxs')};
        `}
`;

export const Dismiss = styled(DismissButton)`
    &,
    &:hover {
        transition: none;
        color: currentColor;
        background-color: transparent;
        text-decoration: none;
    }
`;

export const Label = styled(Box)`
    display: flex;
    width: 100%;
    padding: 0 ${spacing('xxs')};
`;
