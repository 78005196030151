import React from 'react';
import * as Styled from './styled';
import { IndicatorProps } from './types';

export const Indicator: React.FC<IndicatorProps> = ({ children, variant = 'primary', size = 'sm', ...props }) => (
    <Styled.Indicator size={size} {...props}>
        <Styled.IndicatorIcon variant={variant} />
        {children}
    </Styled.Indicator>
);

Indicator.displayName = 'Indicator';
