import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCommentsTextFilled = ({ size, title = 'CommentsTextFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            stroke="currentColor"
            d="M7.083 8.458h-.016l-.017.001-.085.006-.017.001-.017.003a1.125 1.125 0 00.152 2.24h4.601l.016-.002.085-.006h.017l.017-.003a1.125 1.125 0 00-.152-2.24H7.083zm0-2.5h-.016l-.017.001-.085.006-.017.001-.017.002a1.125 1.125 0 00.152 2.24h6.284l.085-.007h.017l.016-.003a1.125 1.125 0 00-.152-2.24h-6.25zm8.967-2.75c.635 0 1.158.527 1.158 1.162v8.343c0 .64-.522 1.162-1.161 1.162h-2.945a1 1 0 00-.625.22l-3.654 2.923a.25.25 0 01-.406-.195v-1.948a1 1 0 00-1-1H4.375a1.172 1.172 0 01-1.163-1.068l-.004-.103V4.37c0-.639.527-1.162 1.159-1.162H16.05z"
        />
    </svg>
);

export default withIcon(SvgCommentsTextFilled);
