import React, { useContext, MouseEvent } from 'react';
import { isValidElementType } from 'react-is';
import { maestroComponent } from '../../utils';
import { TextProps } from '../Text';
import { forwardRef } from '../../system';
import { ListProps, ListItemProps, ListItemIconProps, ListItemSecondaryActionProps } from './types';
import * as Styled from './styled';

export const ListContext = React.createContext<ListProps>({ variant: 'default', size: 'md' });

const ListComponent = forwardRef<ListProps, 'div'>(({ children, variant = 'default', size = 'md', ...props }, ref) => (
    <ListContext.Provider value={{ variant, size } as ListProps}>
        <Styled.List as="nav" role="menu" ref={ref} {...props}>
            {children}
        </Styled.List>
    </ListContext.Provider>
));

ListComponent.displayName = 'ListComponent';

const ItemIcon = maestroComponent<ListItemIconProps>('List.ItemIcon', Styled.ItemIcon);

const Item = forwardRef<ListItemProps, 'div'>((props, ref) => {
    const context = useContext(ListContext);

    const { children, onClick, disabled, isDisabled, icon: IconComponent, addonLeft, addonRight, ...rest } = props;

    const handleClick = (event: MouseEvent): void => {
        if (disabled || isDisabled) {
            return;
        }
        onClick?.(event);
    };

    return (
        <Styled.Item
            ref={ref}
            role="menuitem"
            onClick={handleClick}
            disabled={disabled || isDisabled}
            {...rest}
            {...context}
        >
            {isValidElementType(IconComponent) && (
                <ItemIcon size={context.size}>
                    <IconComponent />
                </ItemIcon>
            )}
            {addonLeft &&
                React.Children.map(addonLeft, (addon) => <Styled.Addon marginRight="sm">{addon}</Styled.Addon>)}
            {children}
            {addonRight &&
                React.Children.map(addonRight, (addon) => <Styled.Addon marginLeft="auto">{addon}</Styled.Addon>)}
        </Styled.Item>
    );
});

Item.displayName = 'List.Item';

const Subheader: React.FC<TextProps> = ({ size = 'sm', contrast = 'low', ...props }) => (
    <Styled.Subheader size={size} contrast={contrast} {...props} />
);

const ItemSecondaryAction = maestroComponent<ListItemSecondaryActionProps>(
    'List.ItemSecondaryAction',
    Styled.ItemSecondaryAction,
);

const ListComposition = {
    Item,
    ItemIcon,
    ItemSecondaryAction,
    Subheader,
    Divider: Styled.Divider,
};

export const List = maestroComponent<ListProps, typeof ListComposition>('List', ListComponent, ListComposition);
