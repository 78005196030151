import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { space } from 'styled-system';
import { border, spacing, zIndex, themeMode, palette, color } from '../../utils';
import { Box } from '../../primitives/Box';
import { DrawerSide, StyledDrawerProps } from './types';

export const TRANSITION_DURATION = 300;

/**
 * Get the slide panel position and size based on the side it is on.
 *
 * @param {DrawerSide} side
 * @returns styled-components css
 */
export const getDrawerPosition = (side: DrawerSide = 'bottom') => {
    const horizontalSizes = css`
        width: 80vw;
        height: 100%;
    `;
    const verticalSizes = css`
        max-height: 80vh;
        width: 100%;
        max-width: 100%;
    `;

    return {
        top: css`
            top: 0;
            bottom: auto;
            left: 0;
            ${verticalSizes};
        `,
        bottom: css`
            top: auto;
            bottom: 0;
            left: 0;
            ${verticalSizes};
        `,
        left: css`
            top: 0;
            left: 0;
            ${horizontalSizes};
        `,
        right: css`
            top: 0;
            right: 0;
            ${horizontalSizes};
        `,
    }[side];
};

export const Header = styled(Box)`
    flex: 0 0 auto;

    border-bottom: ${border('base')};
    padding: ${spacing('lg')} ${spacing('xl')};

    ${({ theme }): string => theme.breakpoint('md')`
            padding: ${spacing('sm')} ${spacing('md')};
        `}
`;

export const Footer = styled(Box)`
    flex: 0 0 auto;
    margin-top: auto;

    border-top: ${border('base')};
    padding: ${spacing('lg')} ${spacing('xl')};

    ${({ theme }): string => theme.breakpoint('md')`
            padding: ${spacing('sm')} ${spacing('md')};
        `}
`;

export const Content = styled(Box)`
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    padding: ${spacing('lg')} ${spacing('xl')};

    ${({ theme }): string => theme.breakpoint('md')`
            padding: ${spacing('sm')} ${spacing('md')};
        `}

    ${space};
`;

export const Drawer = styled(Box)<StyledDrawerProps>`
    z-index: ${zIndex('modal')};
    position: fixed;
    overflow: hidden;
    pointer-events: auto;
    display: flex;
    flex-direction: column;

    max-width: 100%;
    max-height: 100%;

    ${({ side, isFull }): FlattenSimpleInterpolation =>
        isFull
            ? css`
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
              `
            : getDrawerPosition(side)};

    background: ${color('surface')};
    color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.0') })};
`;
