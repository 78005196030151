import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCopyOutlined = ({ size, title = 'CopyOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4 4a2 2 0 012-2h8a2 2 0 012 2H6v14a2 2 0 01-2-2V4zm6 4v12h8V8h-8zm0-2h8a2 2 0 012 2v12a2 2 0 01-2 2h-8a2 2 0 01-2-2V8a2 2 0 012-2z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgCopyOutlined);
