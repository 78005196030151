import React from 'react';
import { CaretDownOutlined } from '../../assets/icons';
import { InputProps } from '../Input';
import { forwardRef } from '../../system';
import { Box, Level } from '../../primitives';
import * as Styled from './styled';

export const SelectInputComponent = forwardRef<InputProps, 'button' | 'input'>((props, ref) => {
    const { children, ...rest } = props;

    return (
        <Styled.Select ref={ref} autoComplete="off" role="button" {...rest}>
            <Level>
                <Box>{children}</Box>
                <CaretDownOutlined />
            </Level>
        </Styled.Select>
    );
});
SelectInputComponent.displayName = 'SelectInputComponent';
