import { FC } from 'react';
import { BasicImage } from 'ui';

import styles from './Image.module.scss';

interface ImageProps {
  src: string;
  alt?: string;
  caption?: string;
  imageHref?: string;
  imageHrefTarget?: string;
}

export const Image: FC<ImageProps> = ({
  src,
  alt,
  caption,
  imageHref,
  imageHrefTarget,
}) => {
  const ImageCaption = () => {
    if (!caption) return null;

    return (
      <p
        className={styles.caption}
        dangerouslySetInnerHTML={{ __html: caption }}
      />
    );
  };

  return (
    <div className={styles.root}>
      <BasicImage
        src={src}
        imageHref={imageHref}
        imageHrefTarget={imageHrefTarget}
        alt={alt || caption || 'Image'}
        objectFit="contain"
      />
      <ImageCaption />
    </div>
  );
};
