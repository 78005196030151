import React, { useEffect } from 'react';
import { maestroComponent } from '../../utils';
import * as Styled from './styled';

export type AppLayoutProps = { children: React.ReactNode };

const AppLayoutComponent = ({ children, ...rest }: AppLayoutProps) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return (): void => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return <Styled.AppLayout {...rest}>{children}</Styled.AppLayout>;
};

AppLayoutComponent.displayName = 'AppLayout';

const compoundComponents = {
    Banner: Styled.Banner,
    Header: Styled.Header,
    Main: Styled.Main,
    Sidebar: Styled.Sidebar,
};

export const AppLayout = maestroComponent<AppLayoutProps, typeof compoundComponents>(
    'AppLayout',
    AppLayoutComponent,
    compoundComponents,
);
