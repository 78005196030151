import { FC } from 'react';
import cn from 'classnames';
import { SubscriptionForm, Position } from 'components/Common';

import styles from './InlineSubscription.module.scss';

export interface InlineSubscriptionProps {
  title: string;
  subtitle: string;
  boxed?: boolean;
}

export const InlineSubscription: FC<InlineSubscriptionProps> = ({
  title,
  subtitle,
  boxed,
}) => {
  const formType = boxed ? 'boxed_subscription' : 'inline_subscription';

  return (
    <Position position="inline">
      <div
        className={cn(styles.root, {
          [styles.boxed]: boxed,
        })}
      >
        <div className={styles.topBorder} />
        <div className={styles.body}>
          <p
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></p>
          <p
            className={styles.subtitle}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></p>
        </div>
        <SubscriptionForm formType={formType} />
      </div>
    </Position>
  );
};
