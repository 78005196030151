import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLinkFilled = ({ size, title = 'LinkFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path d="M12.65 5.942a3.753 3.753 0 015.304 0 3.745 3.745 0 010 5.303l-4.522 4.522-2.647-2.647" />
            <path d="M11.245 17.955a3.757 3.757 0 01-5.308.004 3.749 3.749 0 01.005-5.309l4.52-4.52 2.654 2.654" />
        </g>
    </svg>
);

export default withIcon(SvgLinkFilled);
