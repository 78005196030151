import React from 'react';
import styled from '../../utils/styled';
import { MaestroStyledComponent } from '../../utils';
import { Box } from '../../primitives';

export type SiteLayoutTopBarProps = Record<string, any>;
const StyledTopBar: MaestroStyledComponent<SiteLayoutTopBarProps> = styled(Box)<SiteLayoutTopBarProps>`
    flex: 0 0 auto;
`;

export type SiteLayoutHeaderProps = Record<string, any>;
const StyledHeader: MaestroStyledComponent<SiteLayoutHeaderProps> = styled(Box)<SiteLayoutHeaderProps>`
    flex: 0 0 auto;
`;

export type SiteLayoutContentProps = Record<string, any>;
const StyledContent: MaestroStyledComponent<SiteLayoutContentProps> = styled(Box)<SiteLayoutContentProps>`
    flex: 1 1 auto;
`;

export type SiteLayoutFooterProps = Record<string, any>;
const StyledFooter: MaestroStyledComponent<SiteLayoutFooterProps> = styled(Box)<SiteLayoutFooterProps>`
    flex: 0 0 auto;
    margin-top: auto;
`;

export interface SiteLayoutProps {
    /** The children */
    children: React.ReactNode;
}

type Props = SiteLayoutProps & React.HTMLAttributes<HTMLDivElement>;
export interface SiteLayoutSubComponents {
    Header?: any;
    Footer?: any;
    Content?: any;
    TopBar?: any;
}

const StyledSiteLayout: MaestroStyledComponent<SiteLayoutProps> = styled.div<SiteLayoutProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    min-height: 100%;
`;

export const SiteLayout: React.FC<Props> & SiteLayoutSubComponents = ({ children }: SiteLayoutProps) => (
    <StyledSiteLayout>{children}</StyledSiteLayout>
);

SiteLayout.TopBar = StyledTopBar;
SiteLayout.Header = StyledHeader;
SiteLayout.Content = StyledContent;
SiteLayout.Footer = StyledFooter;

SiteLayout.displayName = 'SiteLayout';
