import styled from '../../utils/styled';
import { Box, BoxProps } from '../Box';

type Props = BoxProps & React.AllHTMLAttributes<HTMLElement>;

const VisuallyHidden = styled(Box)<Props>`
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
`;

VisuallyHidden.displayName = 'VisuallyHidden';

export { VisuallyHidden };
