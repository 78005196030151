import redirects from '../redirects.json';

/**
 * Related: https://github.com/vercel/next.js/discussions/26426
 * Bug: https://mixgenius.atlassian.net/browse/CORE-9223
 * @param url
 */
export const getRedirectDestination = (url: string) => {
  const redirectData = redirects.find((e) => e.source === url);

  let modifiedUrl;
  let target: '_self' | '_blank' = '_self';
  if (redirectData) {
    modifiedUrl = redirectData.destination;

    if (!redirectData.destination.includes('blog.landr.com')) {
      target = '_blank';
    }
  }

  return {
    originalUrl: url,
    modifiedUrl,
    target: target,
  };
};
