import { useContext } from 'react';
import { Orientation } from '../../types/tokens';
import { ResponsiveState, ResponsiveContext } from '../../utils/responsive';

/**
 *
 * Check if device orientation equals requested orientation.
 *
 */
export const useOrientation = (orientation: Orientation) => {
    const state = useContext<ResponsiveState>(ResponsiveContext);

    // Return true/false
    return !!state.orientation[orientation];
};
