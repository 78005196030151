import { createContext, useContext } from 'react';
import { FormControlContextProps } from './types';

export const FormControlContext = createContext<FormControlContextProps>({
    isRequired: false,
    isInvalid: false,
    isValid: false,
    isDisabled: false,
    isReadOnly: false,
});

export const useFormControlContext = (): FormControlContextProps => {
    const context = useContext(FormControlContext);
    return context;
};

export const useFormControl = (props: Record<string, any>): Record<string, boolean> => {
    const context = useFormControlContext();
    if (!context) {
        return props;
    }

    const keys = Object.keys(context);
    return keys.reduce((acc, prop) => {
        acc[prop] = props[prop];
        if (context && !props[prop]) {
            acc[prop] = context[prop];
        }
        return acc;
    }, {});
};
