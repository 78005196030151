import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgAppleFilled = ({ size, title = 'AppleFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M18.707 16.24a9.33 9.33 0 01-.894 1.647c-.47.687-.855 1.162-1.151 1.426-.46.433-.952.655-1.48.668-.378 0-.835-.11-1.366-.335-.533-.223-1.023-.333-1.471-.333-.47 0-.974.11-1.513.333-.54.224-.975.341-1.307.353-.506.022-1.01-.207-1.513-.686-.321-.287-.723-.78-1.204-1.476-.516-.745-.94-1.608-1.273-2.592C5.179 14.182 5 13.153 5 12.156c0-1.142.24-2.126.723-2.951a4.296 4.296 0 011.514-1.57 3.998 3.998 0 012.046-.591c.402 0 .928.127 1.583.377.653.251 1.072.379 1.256.379.137 0 .603-.15 1.392-.446.746-.275 1.376-.39 1.892-.344 1.398.115 2.45.68 3.148 1.698-1.25.777-1.87 1.864-1.857 3.26.011 1.087.396 1.991 1.152 2.71.343.332.725.59 1.151.773-.092.274-.19.537-.293.79zM16 2.339c0 .845-.347 1.633-1.039 2.364-.834.867-1.844 1.369-2.939 1.29a2.343 2.343 0 01-.022-.32c0-.811.397-1.68 1.102-2.389.352-.36.8-.658 1.342-.896.542-.235 1.054-.365 1.536-.387.014.113.02.226.02.338z"
        />
    </svg>
);

export default withIcon(SvgAppleFilled);
