import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgLogoOutlined = ({ size, title = 'LogoOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M17.5 12.25a7.25 7.25 0 01-7.25 7.25A7.25 7.25 0 013 12.25 7.25 7.25 0 0110.25 5a7.25 7.25 0 017.25 7.25z"
            clipRule="evenodd"
        />
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M21.5 12.25a7.25 7.25 0 01-7.25 7.25A7.25 7.25 0 017 12.25 7.25 7.25 0 0114.25 5a7.25 7.25 0 017.25 7.25z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgLogoOutlined);
