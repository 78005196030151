import React, { forwardRef, useRef } from 'react';
import { space, SpaceProps } from 'styled-system';
import { maestroComponent } from '../../utils/componentHelpers';
import styled from '../../utils/styled';
import { useLockBodyScroll, useOnClickOutside } from '../../hooks';

export interface DialogProps extends SpaceProps {
    /** Prevents the body from scrolling while the dialog is mounted */
    lockBodyScroll?: boolean;
    /** Function to call when clicking outside the dialog */
    onClickOutside?: () => void;
    children?: React.ReactNode;
}

const StyledDialog: any = styled('div').attrs({ role: 'dialog' })<DialogProps>`
    ${space};
`;

const DialogComponent = forwardRef(({ lockBodyScroll, onClickOutside, ...props }: DialogProps, ref) => {
    const wrappedRef = useRef(ref);

    useLockBodyScroll(lockBodyScroll, wrappedRef);

    if (onClickOutside) {
        useOnClickOutside(wrappedRef, onClickOutside);
    }

    return <StyledDialog {...props} ref={wrappedRef} />;
});

export const Dialog = maestroComponent<DialogProps>('Dialog', DialogComponent);

Dialog.defaultProps = {
    lockBodyScroll: false,
};
