import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgSamples = ({ size, title = 'Samples', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 48 48"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="#2C3C4D" d="M41 47H7a2 2 0 01-2-2V3a2 2 0 012-2h24l12 12v32a2 2 0 01-2 2z" />
        <path fill="#435261" d="M31 1v10a2 2 0 002 2h10L31 1z" />
        <path
            fill="#F9FAFB"
            d="M31.646 19.236a1.005 1.005 0 00-.811-.223l-12 2A1 1 0 0018 22v11.184A2.966 2.966 0 0017 33c-1.655 0-3 1.346-3 3s1.345 3 3 3 3-1.346 3-3v-8.153l10-1.667v5.004A2.966 2.966 0 0029 31c-1.655 0-3 1.346-3 3s1.345 3 3 3 3-1.346 3-3V20a.998.998 0 00-.354-.763zM20 25.82v-2.972l10-1.667v2.972L20 25.82z"
        />
    </svg>
);

export default withIcon(SvgSamples);
