import React from 'react';
import { useToggle } from '../../hooks';
import { CollapseWithStateProps } from './types';
import { BaseCollapse } from './BaseCollapse';

// CollapseWithState

export const CollapseWithState: React.FC<CollapseWithStateProps> = (props: CollapseWithStateProps) => {
    const { isActive, toggle } = useToggle();

    return <BaseCollapse {...props} isOpen={isActive} onToggle={toggle} />;
};
