import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgTwitterFilled = ({ size, title = 'TwitterFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M13.235 10.851L18.7 4.5h-1.295l-4.744 5.515L8.87 4.5H4.5l5.73 8.34L4.5 19.5h1.295l5.01-5.824 4.002 5.824h4.37l-5.942-8.649zm-1.773 2.062l-.581-.83-4.62-6.608h1.99l3.727 5.332.58.83 4.847 6.933h-1.989l-3.954-5.657z"
        />
    </svg>
);

export default withIcon(SvgTwitterFilled);
