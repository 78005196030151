import React, { createContext, useContext } from 'react';

const experimentsList = {
    tabsArrow: false,
};

export type Experiments = typeof experimentsList;

const ExperimentalContext = createContext<Record<string, boolean>>({});

export const useExperimentalContext = () => useContext(ExperimentalContext);

export interface ExperimentalProviderProps {
    children?: React.ReactNode;
    experiments?: Partial<Experiments>;
}

export const ExperimentalProvider = (props: ExperimentalProviderProps) => {
    const { children, experiments: experimentsProp } = props;
    const experiments = { ...experimentsList, ...experimentsProp };
    return <ExperimentalContext.Provider value={experiments}>{children}</ExperimentalContext.Provider>;
};

ExperimentalProvider.displayName = 'Maestro.ExperimentalProvider';

export const useExperiment = (experiment: keyof Experiments) => {
    const experiments = useExperimentalContext();

    const isEnabled = experiment in experiments ? experiments[experiment] : false;

    return [isEnabled];
};
