import { MutableRefObject, Ref, useContext, useEffect } from 'react';
import { TableRowChangedEventType } from './types';
import { TableContext } from './TableContext';
import { TABLE_EVENTS } from './Table.constants';

// Listens for the custom ROW_CHANGED event on the table body and handles
// setting the focused state on the row.
export const useTableRowEventListener = (
    rowIndex: number,
    rowRef: Ref<HTMLDivElement>,
    isFocus: boolean,
    setIsFocus: (isFocuses: boolean) => void,
): void => {
    const { tableBodyNode, setFocusedRowIndex } = useContext(TableContext);

    // EFFECT
    useEffect(() => {
        const listener = (e: TableRowChangedEventType): void => {
            const {
                detail: { newRowIndex, didKeyboardNav },
            } = e;

            // If this is the newly focused row, set the focused state and,
            // if there was a keyboard event, scroll to the row
            if (rowIndex === newRowIndex) {
                setIsFocus(true);

                setFocusedRowIndex(newRowIndex);

                if (didKeyboardNav) {
                    // We use the native scrollIntoView here because the polyfill
                    // causes buggy behaviour. This means that the scrolling on
                    // Safari is not "smooth" but the overall effect still feels
                    // good.
                    (rowRef as MutableRefObject<HTMLDivElement>).current?.scrollIntoView &&
                        (rowRef as MutableRefObject<HTMLDivElement>).current?.scrollIntoView({
                            block: 'center',
                            inline: 'nearest',
                        });
                }

                return;
            }

            // If this is not the newly focused row, clear the focused state
            if (isFocus) {
                setIsFocus(false);
            }
        };

        tableBodyNode?.addEventListener(TABLE_EVENTS.ROW_CHANGED, listener);

        return (): void => tableBodyNode?.removeEventListener(TABLE_EVENTS.ROW_CHANGED, listener);
    }, [tableBodyNode, isFocus, setFocusedRowIndex, setIsFocus, rowIndex, rowRef]);
};
