import { useRef, Fragment, FC } from 'react';
import { HomePageFragmentsFragment } from 'types/generated/graphql';
import { renderBlocks } from 'helpers/renderBlocks';
import { BasicImage } from 'ui';

import styles from './Home.module.scss';

const WelcomeImage: FC<{ src?: string | null; alt: string }> = ({
  src,
  alt,
}) => {
  if (!src) {
    return null;
  }

  return (
    <div className={styles.bgImage}>
      <div className={styles.mediaWrapper}>
        <div className={styles.mediaItem}>
          <BasicImage
            src={src}
            alt={alt}
            objectFit="contain"
            loading="eager"
            draggable="false"
            priority
            sizes="50vw"
          />
        </div>
      </div>
    </div>
  );
};

export interface HomeProps {
  data: Pick<HomePageFragmentsFragment, 'blocks' | 'homepageFirstBlock'> | null;
}

export const Home: FC<HomeProps> = ({ data }) => {
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const { blocks, homepageFirstBlock } = data || {};
  const { accentColor, title, description, image1, image2, image3, image4 } =
    homepageFirstBlock || {};
  const accentColorStyle = {
    '--accent-color': accentColor,
  } as React.CSSProperties;

  const handleScrollButtonClick = () => {
    descriptionRef.current?.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  const HomeDescriptionButton = () => {
    if (!description) return null;

    return (
      <button
        className={styles.scrollBottomButton}
        onClick={handleScrollButtonClick}
      >
        <BasicImage
          src="/icons/scroll-bottom-arrow.svg"
          alt="scroll to bottom"
          layout="fixed"
          loading="eager"
          draggable="false"
          height="35"
          width="65"
        />
      </button>
    );
  };

  const HomeDescription = () => {
    if (!description) return null;

    return (
      <div
        className={styles.description}
        ref={descriptionRef}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  };

  const HomeBlocks = () => {
    if (!blocks) return null;

    return (
      <>
        {blocks.map((item) => {
          if (item) {
            return <Fragment key={item.id}>{renderBlocks(item)}</Fragment>;
          }

          return null;
        })}
      </>
    );
  };

  return (
    <div className={styles.root} style={accentColorStyle}>
      <main className={styles.main}>
        <div className={styles.header}>
          <div className={styles.bgImagesWrapper}>
            <WelcomeImage
              src={image1?.mediaItemUrl}
              alt={image1?.altText || title || ''}
            />
            <WelcomeImage
              src={image2?.mediaItemUrl}
              alt={image2?.altText || title || ''}
            />
            <WelcomeImage
              src={image3?.mediaItemUrl}
              alt={image3?.altText || title || ''}
            />
            <WelcomeImage
              src={image4?.mediaItemUrl}
              alt={image4?.altText || title || ''}
            />
          </div>

          <div className={styles.headerEntry}>
            <h1 className={styles.title}>{title}</h1>
          </div>
          <HomeDescriptionButton />
        </div>
        <HomeDescription />
        <HomeBlocks />
      </main>
    </div>
  );
};
