import React, { useRef } from 'react';
import { maestroComponent } from '../../utils';
import { useClickable } from '../../hooks';
import { CardProps } from './types';
import * as Styled from './styled';

const CardComponent: React.FC<CardProps> = ({
    size = 'md',
    variant = 'solid',
    isError,
    isDisabled,
    isSelected,
    children,
    onClick,
    ...props
}) => {
    const cardRef = useRef<HTMLDivElement>(null);
    const clickableProps = useClickable({ ref: cardRef, isDisabled, onClick, shouldClickOnEnter: true });

    return (
        <Styled.Card
            variant={variant}
            size={size}
            isError={isError}
            aria-selected={isSelected && !isDisabled}
            {...clickableProps}
            {...props}
        >
            {children}
        </Styled.Card>
    );
};

export const Card = maestroComponent<CardProps>('Card', CardComponent, {
    Header: Styled.Header,
    Content: Styled.Content,
    Footer: Styled.Footer,
});
