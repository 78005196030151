import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMagicWandOutlined = ({ size, title = 'MagicWandOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.438 3.563v2.25"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M3.562 18.187l9-9 2.25 2.25-9 9-2.25-2.25z"
            clipRule="evenodd"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M20.438 12.563h-2.25M16.5 7.5l1.688-1.688m-12.375.001L7.5 7.5m9 9l1.688 1.688m-7.875-6.75l2.25 2.25"
        />
    </svg>
);

export default withIcon(SvgMagicWandOutlined);
