import React, { ComponentPropsWithRef, useCallback } from 'react';
import { runIfFn, warn } from '../../utils';
import { Box, BoxProps } from '../../primitives/Box';
import { forwardRef } from '../../system';
import { useImage } from './useImage';

export type ImageProps = {
    /** Image url */
    src?: string;
    /** Image url to load when `src` image couldn't be loaded. */
    fallbackSrc?: string;
    /** @deprecated Method to call when when `src` image couldn't be loaded. */
    fallbackHandler?: React.ReactElement | (() => JSX.Element);
    /** Method to call when when `src` image couldn't be loaded. */
    fallback?: React.ReactElement | (() => JSX.Element);
} & BoxProps &
    ComponentPropsWithRef<'img'>;

export const Image = forwardRef<ImageProps, 'img'>((props, ref) => {
    const { as = 'img', src, srcSet, fallbackSrc, fallbackHandler, fallback, onLoad, onError, ...rest } = props;
    const status = useImage(props);

    warn({
        condition: !!fallbackHandler,
        message: 'The `fallbackHandler` prop is deprecated and will be removed in v1, use `fallback` instead',
    });

    const getFallback = useCallback(() => {
        if (fallback) {
            return runIfFn(fallback);
        }
        if (fallbackHandler && status.isFailed) return runIfFn(fallbackHandler);
        return <Box as={as} ref={ref} src={fallbackSrc} {...rest} />;
    }, [as, fallback, fallbackHandler, fallbackSrc, ref, rest, status.isFailed]);

    if (src) {
        if (status.isFailed) {
            return getFallback();
        }
    } else if (!src) {
        if (status.isFailed || status.isPending) {
            return getFallback();
        }
    }

    return <Box as={as} ref={ref} src={src} srcSet={srcSet} {...rest} />;
});

Image.displayName = 'Image';
Image.defaultProps = {
    draggable: false,
};
