import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cn from 'classnames';
import { AccentColours, CoreColours } from 'types';

import styles from './Label.module.scss';

interface LabelProps {
  href: string;
  label: string;
  accentColour?: AccentColours | CoreColours;
  inlineAccentColour?: string;
  background?: 'white' | 'black';
  type?: 'link' | 'button';
  className?: string;
}

export const Label: React.FC<LabelProps> = ({
  href,
  label,
  accentColour,
  inlineAccentColour,
  background = 'black',
  type = 'button',
  className,
}) => {
  const { push } = useRouter();

  const inlineLabelColor = inlineAccentColour
    ? { color: inlineAccentColour }
    : undefined;

  const classNames = cn(
    styles.root,
    {
      [styles[`bg-${background}`]]: background,
    },
    className
  );

  const handleLabelClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();

    push(href);
  };

  const Wrapper: React.FC = ({ children }) => {
    if (type === 'link') {
      return (
        <Link href={href} className={classNames} title={label}>
          {children}
        </Link>
      );
    }

    return (
      <span className={classNames} onClick={handleLabelClick}>
        {children}
      </span>
    );
  };

  return (
    <Wrapper>
      <span
        className={cn(styles.label, {
          [styles[`accent-color-${accentColour}`]]:
            accentColour && !inlineLabelColor,
        })}
        style={inlineLabelColor}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </Wrapper>
  );
};
