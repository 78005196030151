import styled, { css } from '../../utils/styled';
import { Image } from '../Image';
import { MaestroStyledComponent, palette, spacing } from '../../utils';
import { Text } from '../Text';
import { Box } from '../../primitives/Box';
import { PeopleCardProps } from './types';

export const PeopleCard: MaestroStyledComponent<PeopleCardProps> = styled.div<PeopleCardProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
`;

export const PeopleCardImageWrapper = styled(Box)<PeopleCardProps>`
    width: 220px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    background-color: ${palette('neutral.800')};
    margin-bottom: ${spacing('sm')};
    color: ${palette('neutral.500')};
    &::before {
        content: ' ';
        display: block;
        padding-bottom: 100%;
    }
`;

const position = css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const PeopleCardImage = styled(Image)`
    ${position}
    object-fit: cover;
`;

export const FallbackWrapper = styled.div`
    ${position}
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PeopleCardTitle = styled(Text)`
    color: ${palette('neutral.50')};
`;

export const PeopleCardDescription = styled(Text)`
    color: ${palette('neutral.400')};
`;
