import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVocalign = ({ size, title = 'Vocalign', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#435261"
            d="M3.315 34.065l-1.38-1.38a.75.75 0 01-.094-.947L4.5 27.75l3.75 3.75-3.988 2.659a.75.75 0 01-.947-.094z"
        />
        <path
            fill="#142739"
            d="M8.727 32.266a.748.748 0 01-.53-.22l-4.243-4.243a.75.75 0 01-.065-.986L15.75 12.75l7.5 7.5L9.182 32.112a.75.75 0 01-.455.154z"
        />
        <path
            fill="#E11534"
            d="M10.5 23.25a.75.75 0 01-.53-1.28l2.25-2.25a.75.75 0 111.06 1.06l-2.25 2.25a.748.748 0 01-.53.22z"
        />
        <path
            fill="#435261"
            d="M17.856 18.144c3.801 3.802 9.987 3.802 13.788 0L17.856 4.356c-3.802 3.801-3.802 9.987 0 13.788z"
        />
        <path
            fill="#435261"
            d="M31.644 4.356c-3.801-3.802-9.987-3.802-13.788 0l13.788 13.788c3.802-3.801 3.802-9.987 0-13.788z"
        />
        <path
            fill="#142739"
            d="M31.5 18.75a.748.748 0 01-.53-.22l-13.5-13.5a.75.75 0 111.06-1.06l13.5 13.5a.75.75 0 01-.53 1.28z"
        />
    </svg>
);

export default withIcon(SvgVocalign);
