import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCalendarOutlined = ({ size, title = 'CalendarOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M11 5h2.5" />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M18.5 5h.743c.694 0 1.257.552 1.257 1.242v13.016a1.25 1.25 0 01-1.24 1.242H5.24c-.685 0-1.24-.552-1.24-1.242V6.242C4 5.556 4.568 5 5.257 5H6"
        />
        <path stroke="currentColor" strokeWidth={1.5} d="M4.25 7.5h16" />
        <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M16 5.25V4M8.5 5.25V4" />
        <path
            fill="currentColor"
            stroke="currentColor"
            d="M8.75 10.75a.5.5 0 11-1 0 .5.5 0 011 0zm0 3.25a.5.5 0 11-1 0 .5.5 0 011 0zm4-3.25a.5.5 0 11-1 0 .5.5 0 011 0zm0 3.25a.5.5 0 11-1 0 .5.5 0 011 0zm4-3.25a.5.5 0 11-1 0 .5.5 0 011 0zm0 3.25a.5.5 0 11-1 0 .5.5 0 011 0zm-8 3.25a.5.5 0 11-1 0 .5.5 0 011 0zm4 0a.5.5 0 11-1 0 .5.5 0 011 0zm4 0a.5.5 0 11-1 0 .5.5 0 011 0z"
        />
    </svg>
);

export default withIcon(SvgCalendarOutlined);
