import { Text, TextProps } from '../Text';
import styled from '../../utils/styled';
import { spacing } from '../../utils';

export type HeadingProps = TextProps;

export const Heading = styled(Text)<HeadingProps>`
    margin-top: 0;
    margin-bottom: ${spacing('md')};
`;

Heading.displayName = 'Heading';

Heading.defaultProps = {
    as: 'h2',
    size: 'md',
    contrast: 'base',
    fontWeight: 'bold',
};
