export const getXShareLink = () => {
  return `https://twitter.com/share?ref_src=twsrc%5Etfw&text=Check%20out%20this%20LANDR%20Blog%20post%3A%0A%0A&url=${encodeURIComponent(
    getCurrentUrl()
  )}`;
};

export const getFacebookShareLink = () => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    getCurrentUrl()
  )}&amp;src=sdkpreparse`;
};

export const getLinkedInShareLink = () => {
  return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    getCurrentUrl()
  )}
`;
};

export const getRedditShareLink = () => {
  return `https://reddit.com/submit?url=${encodeURIComponent(
    getCurrentUrl()
  )}&title=Check%20out%20this%20LANDR%20Blog%20post%3A%0A%0A`;
};

const getCurrentUrl = () =>
  typeof window !== 'undefined' ? window.location.href : '';
