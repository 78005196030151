import { Box } from '../../primitives/Box';
import { color, fontWeight, radius, spacing, themeMode } from '../../utils';
import styled from '../../utils/styled';
import { BreadcrumbItemProps, BreadcrumbLinkProps, BreadcrumbProps } from './types';

export const Separator = styled(Box).attrs(() => ({ as: 'li', role: 'presentation', 'aria-hidden': true }))`
    display: inline-flex;
    align-items: center;
    pointer-events: none;
    margin: 0 ${spacing('xs')};
    color: ${themeMode({
        dark: color('neutral.400'),
        light: color('neutral.500'),
    })};
`;

export const Item = styled(Box).attrs(() => ({ as: 'li' }))<BreadcrumbItemProps>`
    display: inline-flex;
    align-items: center;

    overflow: hidden;

    color: ${themeMode({
        dark: color('neutral.400'),
        light: color('neutral.500'),
    })};

    &:hover,
    &:last-child {
        color: ${themeMode({
            dark: color('neutral.50'),
            light: color('neutral.900'),
        })};
    }
`;

export const Link = styled('a').attrs(({ isCurrent }: BreadcrumbLinkProps) => ({
    'aria-current': isCurrent ? 'page' : undefined,
    role: 'link',
}))<BreadcrumbLinkProps>`
    flex: 0 1 auto;
    position: relative;
    text-decoration: none;
    color: inherit;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        color: inherit;
        cursor: pointer;
        text-decoration: underline;
    }

    &[aria-current='page'] {
        cursor: default;
        font-weight: ${fontWeight('bold')};
        color: ${themeMode({
            dark: color('neutral.50'),
            light: color('neutral.900'),
        })};
        text-decoration: none;
        pointer-events: none;
    }

    &:not(:first-child) {
        margin-left: ${spacing('xs')};
    }

    &:focus-visible {
        outline: 0;
        box-shadow: none;
    }

    &:after {
        content: '';
        position: absolute;
        top: -3px;
        left: -6px;
        width: calc(100% + 12px);
        height: calc(100% + 6px);
        opacity: 0;
        transition: opacity 200ms ease-in;
        border-width: 2px;
        border-style: solid;
        border-color: ${color('border.base')};
        border-radius: ${radius('lg')};
    }

    &:focus-visible:after {
        opacity: 1;
    }
`;

export const Wrapper = styled(Box).attrs(() => ({ as: 'ol' }))`
    display: flex;
    margin: 0;
`;

export const Breadcrumb = styled(Box).attrs(() => ({ as: 'nav' }))<BreadcrumbProps>`
    width: 100%;
`;
