import React from 'react';
import { Image } from '../Image';
import { Text } from '../Text';
import { forwardRef } from '../../system';
import { AvatarProps } from './types';
import * as Styled from './styled';

export const Avatar = forwardRef<AvatarProps, 'div'>(
    ({ src, fallback = '', alt, size = 'md', isDimmed = false, isRound = true, srcSet, ...props }, ref) => (
        <Styled.Avatar size={size} ref={ref} isRound={isRound} opacity={isDimmed ? 0.5 : 1} {...props}>
            <Image
                src={src}
                srcSet={srcSet}
                alt={alt}
                fallback={!!fallback && size !== 'xxs' ? <Text fontSize={size}>{fallback}</Text> : <></>}
            />
        </Styled.Avatar>
    ),
);

Avatar.displayName = 'Avatar';
