import React from 'react';
import { maestroComponent } from '../../utils';
import { Box } from '../../primitives/Box';
import { InfoOutlined, SuccessOutlined } from '../../assets/icons';
import { UiStateProps } from './types';
import * as Styled from './styled';

const UiStateCmp: React.FC<UiStateProps> = ({ children, icon, variant = 'empty', align = 'center', ...props }) => {
    const createVariant = (variant) => (
        <Styled.UiState align={align} variant={variant} {...props}>
            <Styled.Icon>{variant}</Styled.Icon>
            {children}
        </Styled.UiState>
    );

    switch (variant) {
        case 'empty':
            return createVariant('');
        case 'error':
            return createVariant(<InfoOutlined size="lg" color="danger.500" />);
        case 'success':
            return createVariant(<SuccessOutlined size="lg" color="primary.500" />);
        default:
            return <Box {...props}>{children}</Box>;
    }
};

const Content = maestroComponent('UiState.Content', Styled.Content);

const compoundComponents = {
    Content,
};

export const UiState = maestroComponent<UiStateProps, typeof compoundComponents>(
    'UiState',
    UiStateCmp,
    compoundComponents,
);
