import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCommunityChannels = ({ size, title = 'CommunityChannels', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g clipPath="url(#community-channels_svg__community-channels_svg__clip0_1402_75)">
            <path
                fill="#142739"
                fillRule="evenodd"
                d="M35.308 29.308v-2.663c0-3.541-4.613-5.325-6.923-5.325-2.31 0-6.924 1.784-6.924 5.325v2.663"
                clipRule="evenodd"
            />
            <path
                fill="#142739"
                d="M28.385 19.988c2.294 0 4.153-1.788 4.153-3.994 0-2.206-1.86-3.994-4.153-3.994-2.294 0-4.154 1.788-4.154 3.994 0 2.206 1.86 3.994 4.154 3.994z"
            />
            <path
                fill="#142739"
                fillRule="evenodd"
                d="M14.538 29.308v-2.663c0-3.541-4.612-5.325-6.923-5.325-2.31 0-6.923 1.784-6.923 5.325v2.663"
                clipRule="evenodd"
            />
            <path
                fill="#142739"
                d="M7.615 19.988c2.294 0 4.154-1.788 4.154-3.994C11.77 13.788 9.91 12 7.615 12c-2.294 0-4.154 1.788-4.154 3.994 0 2.206 1.86 3.994 4.154 3.994z"
            />
            <mask
                id="community-channels_svg__a"
                width={22}
                height={14}
                x={7.35}
                y={22.91}
                fill="black"
                maskUnits="userSpaceOnUse"
            >
                <path fill="white" d="M7.35 22.91h22v14h-22z" />
                <path
                    fillRule="evenodd"
                    d="M26.654 34.5v-3.195c0-4.25-5.766-6.39-8.654-6.39s-8.654 2.14-8.654 6.39V34.5"
                    clipRule="evenodd"
                />
            </mask>
            <path
                fill="#142739"
                fillRule="evenodd"
                d="M26.654 34.5v-3.195c0-4.25-5.766-6.39-8.654-6.39s-8.654 2.14-8.654 6.39V34.5"
                clipRule="evenodd"
            />
            <path
                stroke="#2C3C4D"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3.46}
                d="M26.654 34.5v-3.195c0-4.25-5.766-6.39-8.654-6.39s-8.654 2.14-8.654 6.39V34.5"
                clipRule="evenodd"
                mask="url(#community-channels_svg__a)"
            />
            <path
                fill="#142739"
                stroke="#2C3C4D"
                strokeWidth={1.73}
                d="M18 24.182c3.28 0 6.058-2.47 6.058-5.658 0-3.19-2.779-5.659-6.058-5.659s-6.058 2.47-6.058 5.659c0 3.189 2.779 5.658 6.058 5.658z"
            />
            <path
                fill="#3C6ED7"
                d="M23.822 0c-3.723 0-6.75 2.408-6.75 5.368 0 1.138.443 2.223 1.285 3.146v3.17c0 .11.058.211.153.269a.327.327 0 00.312.014l3-1.473a8.352 8.352 0 002 .243c3.722 0 6.75-2.408 6.75-5.369 0-2.96-3.028-5.368-6.75-5.368z"
            />
            <path
                fill="#F9FAFB"
                d="M27.004 5.577l-2.306 1.751-1.696-2.394a1.508 1.508 0 01-.08-1.668 1.41 1.41 0 01.394-.423 1.323 1.323 0 011.597.038c.152.12.28.27.374.441.14-.135.304-.239.484-.304a1.327 1.327 0 011.103.086c.168.093.316.222.434.377.119.156.206.335.255.528a1.508 1.508 0 01-.166 1.143 1.419 1.419 0 01-.393.424v.001zm-5.97-2.904l.26 3.188-.415.074-.675-3.114.83-.148zm.286 4.833a.58.58 0 00.46-.66.546.546 0 00-.63-.468.58.58 0 00-.46.66c.046.312.33.521.63.468z"
            />
        </g>
        <defs>
            <clipPath id="community-channels_svg__community-channels_svg__clip0_1402_75">
                <path fill="white" d="M0 0h36v36H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default withIcon(SvgCommunityChannels);
