import React, { useRef } from 'react';
import { SpaceProps } from 'styled-system';
import { Box } from '../../primitives/Box';
import { VisuallyHidden, Group } from '../../primitives';
import * as Styled from './styled';
import { DropzoneIconProps, DropzoneProps } from './types';

type Props = DropzoneProps & SpaceProps;

const DropzoneContent: React.FC = ({ children }) => (
    <Group direction="vertical">
        {children && Array.isArray(children) ? (
            children.map((child, index) => <Group.Item key={index}>{child}</Group.Item>)
        ) : (
            <Group.Item>{children}</Group.Item>
        )}
    </Group>
);

const DropzoneIcon: React.FC<DropzoneIconProps> = ({ icon: IconComponent, ...props }) => (
    <Box flex="1" justifyContent="center" display="flex">
        <IconComponent {...props} />
    </Box>
);

export interface DropzoneSubComponents {
    Content: typeof DropzoneContent;
    Icon: typeof DropzoneIcon;
}

export const Dropzone: React.FC<Props> & DropzoneSubComponents = ({
    isLoading,
    isInvalid,
    children,
    wrapperProps,
    inputProps,
}: DropzoneProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClickWithRef = () => {
        if (inputRef && inputRef.current && wrapperProps && wrapperProps.onClick) {
            wrapperProps.onClick(inputRef);
        }
    };

    return (
        <Styled.Wrapper
            {...wrapperProps}
            p="xl"
            onClick={handleClickWithRef}
            isLoading={isLoading}
            isInvalid={isInvalid}
        >
            <VisuallyHidden>
                <input {...inputProps} style={{ display: 'none' }} type="file" ref={inputRef} />
            </VisuallyHidden>
            {children}
        </Styled.Wrapper>
    );
};

Dropzone.Content = DropzoneContent;
Dropzone.Icon = DropzoneIcon;

Dropzone.displayName = 'Dropzone';
