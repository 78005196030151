import { FC } from 'react';
import cn from 'classnames';
import PlayVideoIcon from '../../public/icons/play-video.svg';

import styles from './PlayVideoButton.module.scss';

export type PlayVideoButtonProps = {
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
  className?: string;
};

export const PlayVideoButton: FC<PlayVideoButtonProps> = ({
  size = 'medium',
  onClick,
  className,
}) => {
  const title = 'Play video';

  return (
    <button
      className={cn(styles.root, className, {
        [styles[`size-${size}`]]: size,
      })}
      onClick={onClick}
      type="button"
      aria-label={title}
      title={title}
    >
      <PlayVideoIcon className={styles.icon} />
    </button>
  );
};
