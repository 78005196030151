import { styled, variant } from '../../utils';
import { Box } from '../../primitives/Box';
import { TruncateProps } from './types';

const modeStyles = variant({
    prop: 'mode',
    variants: {
        middle: {
            whiteSpace: 'nowrap',
        },
    },
});

export const Truncate = styled(Box)<TruncateProps>`
    display: inline-flex;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    ${modeStyles};
`;
