import React, { useContext } from 'react';
import { CloseOutlined } from '../../assets/icons';
import { useBreakpoint } from '../../hooks';
import { ResponsiveMenu } from '../DropdownMenu';
import { FiltersInternalContext } from './api';
import {
    StyledGroupItem,
    MobileToggleResponsiveMenuButtonWrapper,
    MobileAppliedFiltersListItem,
    MobileAppliedFiltersListItemText,
    StyledToggleFilterPopupButton,
} from './styled';
import { FilterChild, BaseFilter } from './types';

export interface FilterProps {
    children: React.ReactElement<FilterChild> | React.ReactElement<FilterChild>[];
    displayName: string;
    appliedFilters: {
        [code: string]: BaseFilter;
    };
    onClearByType: () => void;
}

export const Filter: React.FC<FilterProps> = ({ children, displayName, appliedFilters, onClearByType }) => {
    const { staticText } = useContext(FiltersInternalContext);

    const isMobile = useBreakpoint('md');

    const hasAppliedFilters = appliedFilters && Boolean(Object.keys(appliedFilters).length);

    function renderToggleResponsiveMenuButton() {
        return (
            <ResponsiveMenu.Trigger>
                <StyledToggleFilterPopupButton
                    isDropdown={!isMobile}
                    kind="minimal"
                    hasSelected={hasAppliedFilters}
                    justifyContent="flex-start"
                    isFull
                >
                    {displayName}
                </StyledToggleFilterPopupButton>
            </ResponsiveMenu.Trigger>
        );
    }

    return (
        <StyledGroupItem>
            <ResponsiveMenu>
                {!isMobile ? (
                    renderToggleResponsiveMenuButton()
                ) : (
                    // on Mobiles we should show toggle menu button
                    // and list of already applied filters in one row
                    // if this filter has already applied filters, of course
                    <MobileToggleResponsiveMenuButtonWrapper>
                        {renderToggleResponsiveMenuButton()}
                        {hasAppliedFilters && (
                            <MobileAppliedFiltersList onClearByType={onClearByType} appliedFilters={appliedFilters} />
                        )}
                    </MobileToggleResponsiveMenuButtonWrapper>
                )}
                <ResponsiveMenu.List>
                    {isMobile ? (
                        // here we render the list of filters
                        // on mobiles we also need to show back button
                        // to close responsive menu on press
                        <>
                            <ResponsiveMenu.Item>{staticText.back}</ResponsiveMenu.Item>
                            {children}
                        </>
                    ) : (
                        <>{children}</>
                    )}
                </ResponsiveMenu.List>
            </ResponsiveMenu>
        </StyledGroupItem>
    );
};

interface MobileAppliedFiltersListProps {
    onClearByType: () => void;
    appliedFilters: {
        [code: string]: BaseFilter;
    };
}

/**
 * Renders list of applied filters for certain type
 */
const MobileAppliedFiltersList: React.FC<MobileAppliedFiltersListProps> = ({ appliedFilters, onClearByType }) => {
    return (
        <MobileAppliedFiltersListItem onClick={onClearByType}>
            <MobileAppliedFiltersListItemText>
                {Object.values(appliedFilters).map((item, i, arr) => {
                    return arr.length === 1 ? item.name : `${item.name}${i < arr.length - 1 ? ',' : ''} `;
                })}
            </MobileAppliedFiltersListItemText>
            <CloseOutlined />
        </MobileAppliedFiltersListItem>
    );
};
