import { FC } from 'react';
import cn from 'classnames';
import { AccentColours, CoreColours } from 'types';

import styles from './Quote.module.scss';

export interface QuoteProps {
  content: string;
  accentColour?: AccentColours | CoreColours;
  sidebarPosition?: boolean;
}

export const Quote: FC<QuoteProps> = ({
  content,
  sidebarPosition,
  accentColour,
}) => {
  return (
    <div
      className={cn(styles.root, {
        [styles.sidebarPosition]: sidebarPosition,
        [styles[`accent-color-${accentColour}`]]: accentColour,
      })}
    >
      <p
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};
