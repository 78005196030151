import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgArrowForwardOutlined = ({ size, title = 'ArrowForwardOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.286 12H5.5h11.786zM12.25 5.75l6 6.25-6-6.25zm0 12.5l6-6.25-6 6.25z"
            />
        </g>
    </svg>
);

export default withIcon(SvgArrowForwardOutlined);
