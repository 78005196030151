import React from 'react';
import { space, color, border, compose, variant, height } from 'styled-system';
import styled from 'styled-components';
import { color as colorToken, themeMode, radius } from '../../utils';
import { forwardRef } from '../../system';
import { Button, ButtonProps } from '../Button';
import { ModalFooterProps, ModalProps } from './types';
import { useModalContext } from './ModalContext';

const styles = variant({
    prop: 'size',
    variants: {
        sm: { px: [null, 'md', 'lg'], py: [null, 'md', 'md'] },
        md: { px: [null, 'md', 'xxl'], py: [null, 'md', 'lg'] },
        lg: { px: [null, 'md', 'xxl'], py: [null, 'md', 'lg'] },
    },
});

export const ModalContinueButton = forwardRef<ButtonProps, 'button'>(
    ({ kind = 'solid', variant = 'primary', size = 'md', ...props }, ref) => {
        return <Button ref={ref} kind={kind} variant={variant} size={size} {...props} />;
    },
);

ModalContinueButton.displayName = 'Modal.ContinueButton';

export const ModalCancelButton = forwardRef<ButtonProps, 'button'>(
    ({ kind = 'solid', variant = 'subtle', size = 'md', ...props }, ref) => {
        return <Button ref={ref} kind={kind} variant={variant} size={size} {...props} />;
    },
);

ModalCancelButton.displayName = 'Modal.CancelButton';

const StyledModalFooter = styled('footer')<ModalFooterProps & Pick<ModalProps, 'size'>>`
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    min-height: min-content;
    background-color: ${themeMode({
        dark: colorToken('neutral.900'),
        light: colorToken('neutral.0'),
    })};
    border-bottom-left-radius: ${radius('base')};
    border-bottom-right-radius: ${radius('base')};
    ${styles};
    ${compose(space, color, border, height)};
`;

export const ModalFooter = forwardRef<ModalFooterProps, 'div'>(({ children, ...props }, ref) => {
    const { size } = useModalContext();

    return (
        <StyledModalFooter ref={ref} size={size} {...props}>
            {children}
        </StyledModalFooter>
    );
});

ModalFooter.displayName = 'Modal.Footer';
