import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgMoveToFolderOutlined = ({ size, title = 'MoveToFolderOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
            <path
                strokeLinejoin="round"
                d="M3.75 4.994c0-.687.55-1.244 1.25-1.244h5.753c.55 0 1.285.336 1.648.76l2.349 2.74h-11V4.994z"
            />
            <path
                strokeLinecap="round"
                d="M13.99 20.25h5.02c.685 0 1.24-.559 1.24-1.246V8.5c0-.912-.821-1.25-1.25-1.25H4.99c-.685 0-1.24.057-1.24.246v5.508M4 17.25h6"
            />
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 14.25l3 3-3 3" />
        </g>
    </svg>
);

export default withIcon(SvgMoveToFolderOutlined);
