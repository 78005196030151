import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVideoOutlined = ({ size, title = 'VideoOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeWidth={1.5}
                d="M19.911 8.052c-.204-.888-.93-1.543-1.805-1.64-2.07-.232-4.167-.233-6.253-.232-2.087-.001-4.183 0-6.254.231-.874.098-1.6.753-1.805 1.641C3.504 9.317 3.5 10.698 3.5 12s0 2.683.291 3.947c.204.888.93 1.544 1.805 1.642 2.07.23 4.167.232 6.253.23 2.087.002 4.183 0 6.254-.23.874-.098 1.6-.753 1.805-1.642.29-1.264.292-2.645.292-3.947s.002-2.683-.289-3.948z"
            />
            <path fill="currentColor" d="M9.7 14.81V9.18l5.412 2.824L9.7 14.81z" />
        </g>
    </svg>
);

export default withIcon(SvgVideoOutlined);
