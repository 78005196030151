import React from 'react';
import { forwardRef } from '../../system';
import { AspectRatioProps } from './types';
import * as Styled from './styled';

export const AspectRatio = forwardRef<AspectRatioProps, 'div'>((props, ref) => {
    const { children, ratio = 1, ...rest } = props;

    return (
        <Styled.AspectRatio ref={ref} ratio={ratio} {...rest}>
            {children}
        </Styled.AspectRatio>
    );
});

AspectRatio.displayName = 'AspectRatio';
