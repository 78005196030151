import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgAttachmentOutlined = ({ size, title = 'AttachmentOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M19.875 11.147l-6.586 6.587c-1.871 1.87-5.015 1.87-6.886 0h0c-1.87-1.871-1.87-5.015 0-6.886l5.838-5.838c1.347-1.347 3.443-1.347 4.79 0h0c1.347 1.348 1.347 3.443 0 4.79l-5.389 5.314c-.748.749-1.946.749-2.62 0h0c-.748-.748-.748-1.946 0-2.62l4.491-4.49"
        />
    </svg>
);

export default withIcon(SvgAttachmentOutlined);
