import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCommentMusicNoteOutlined = ({
    size,
    title = 'CommentMusicNoteOutlined',
    ...props
}: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
        >
            <path d="M20.5 9.5V4h-7v6.5" />
            <circle cx={12} cy={10.5} r={1.5} />
            <circle cx={19} cy={9.5} r={1.5} />
            <path d="M20.5 14.1v1.156c0 .687-.562 1.244-1.244 1.244H15.25l-5 4v-4h-5c-.69 0-1.25-.57-1.25-1.244V7.244A1.25 1.25 0 015.24 6h5.26" />
        </g>
    </svg>
);

export default withIcon(SvgCommentMusicNoteOutlined);
