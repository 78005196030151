import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVideoOnOutlined = ({ size, title = 'VideoOnOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17 7.26C17 6.564 16.518 6 15.948 6H4.052C3.472 6 3 6.577 3 7.26v9.48c0 .696.477 1.26 1.056 1.26H15.94c.585 0 1.06-.577 1.06-1.26V7.26z"
            clipRule="evenodd"
        />
        <path stroke="currentColor" strokeLinejoin="round" strokeWidth={1.5} d="M17 10l4-1v6l-4-1v-4z" />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M5.75 10.231H9.5"
        />
    </svg>
);

export default withIcon(SvgVideoOnOutlined);
