export const determineTextColor = (hexCode: string) => {
  const rValue = hexCode.slice(1, 3);
  const gValue = hexCode.slice(3, 5);
  const bValue = hexCode.slice(5, 7);

  const rNumber = parseInt(rValue, 16);
  const gNumber = parseInt(gValue, 16);
  const bNumber = parseInt(bValue, 16);

  if (rNumber * 0.299 + gNumber * 0.587 + bNumber * 0.114 > 186) {
    return '#000000';
  } else {
    return '#ffffff';
  }
};
