import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Chip } from '../Chip';
import { Input } from '../Input';
import { Box } from '../../primitives/Box';
import { forwardRef } from '../../system';
import { ChipInputProps, CustomChipProps } from './types';

const ChipWrapper = styled(Box)`
    display: grid;
    grid-gap: xs;
    grid-auto-flow: column;
    overflow-x: scroll;
    margin-left: 2px;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
        display: none;
    }
`;

const defaultChip = forwardRef<CustomChipProps, 'div'>(({ value, ...props }, ref) => {
    return (
        <Chip ref={ref} {...props}>
            {value}
        </Chip>
    );
});

export const ChipInput = forwardRef<ChipInputProps, 'input'>(
    (
        {
            shape,
            size = 'md',
            variant,
            limitChipValues = 0,
            isDisabled,
            isReadOnly: isReadOnlyProp,
            id,
            type,
            autoComplete,
            placeholder,
            wrapperRef: wrapperRefProp = null,
            inputRef = null,
            splitSymbols = ', ',
            regExpForInputText = '^[a-zA-Z ]*$',
            getResult,
            values,
            onChange,
            onKeyDown,
            onFocus,
            onBlur,
            onAdd,
            onRemove,
            addChipOnBlur,
            value = '',
            getItemsLabel = (value) => value,
            chipComponent: ChipComponent = defaultChip,
            addonLeft,
            isMultiline,
            ...props
        },
        ref,
    ) => {
        const [inputValue, setInputValue] = useState(value);
        const [chipArrayValues, setChipArrayValues] = useState<Array<string>>([]);
        const [isReadOnly, setReadOnly] = useState(isReadOnlyProp);

        /**
         * This method converts "splitSymbols" (EX: .,!@# ) to the proper regExp
         * depending on is if it will be used for String.prototype.match() or String.prototype.replace()
         * P.S. In this particular case "useCallback" is needed, because of it's usage in useEffect().
         */
        const createRegExpFromSplitSymbols = useCallback(
            ({ isForStringMatch }) => {
                const symbolsArray = Array.from(splitSymbols);
                const pattern = `[${symbolsArray.join('|')}]`;

                return isForStringMatch ? pattern : new RegExp(pattern);
            },
            [splitSymbols],
        );

        /**
         * This effect is responsible for converting string from the input to "Chips".
         */
        useEffect(() => {
            // If string from the input contains one of the "splitSymbols" (comma, dot, etc)
            if (inputValue.match(createRegExpFromSplitSymbols({ isForStringMatch: true })) && inputValue.length > 1) {
                /**
                 * Remove split symbol from the input with an empty string.
                 * Ex: 'ABC!' -> 'ABC'
                 */
                const replace = inputValue.replace(createRegExpFromSplitSymbols({ isForStringMatch: false }), '');
                const newValues = [...chipArrayValues, replace.trim()];
                setChipArrayValues(newValues);
                setInputValue('');
                if (onAdd) {
                    onAdd(newValues);
                }
            }
        }, [chipArrayValues, createRegExpFromSplitSymbols, inputValue, onAdd]);

        useEffect(() => {
            if (getResult) {
                getResult(chipArrayValues);
            }
        }, [chipArrayValues, getResult]);

        useEffect(() => {
            if (values) {
                setChipArrayValues(values);
                setInputValue('');
            }
        }, [values]);

        useEffect(() => {
            if (limitChipValues === 0) {
                return;
            }
            setReadOnly(chipArrayValues?.length >= limitChipValues);
        }, [chipArrayValues, limitChipValues]);

        /**
         * Set new state's input value only if user entered specific text.
         * If user will enter "," (only comma/one of the split symbols) -> state value will not be set.
         * @param event
         */
        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            const { value } = event.target;
            const regExp = new RegExp(regExpForInputText);

            const isFirstCharIsOneOfSplitSymbols = Array.from(splitSymbols).includes(value[0]);

            if (regExp.test(value) || !isFirstCharIsOneOfSplitSymbols) {
                setInputValue(value);
            }

            if (onChange) {
                onChange(event);
            }
        };

        /**
         * Delete "Chip component" with specific index. If index wasn't provided - the last
         * "Chip component" from the array will be deleted.
         */
        const handleDeleteChip = (chipComponentIndex = -1): void => {
            chipArrayValues.splice(chipComponentIndex, 1);
            const newValues = [...chipArrayValues];
            setChipArrayValues(newValues);
            if (onRemove) {
                onRemove(newValues);
            }
        };

        const handleAddChip = (): void => {
            const newValues = [...chipArrayValues, inputValue];
            setChipArrayValues(newValues);
            setInputValue('');
            if (onAdd) {
                onAdd(newValues);
            }
        };

        const handleKeyDown = (event): void => {
            // BACKSPACE || DELETE button
            if ((event.key === 'Backspace' || event.key === 'Delete') && inputValue === '') {
                handleDeleteChip();
            }

            const regExp = new RegExp(regExpForInputText);

            // Enter button
            if (event.key === 'Enter' && inputValue !== '' && regExp.test(inputValue)) {
                // Prevents the form from being submitted
                event.preventDefault();
                handleAddChip();
            }

            if (onKeyDown) {
                onKeyDown(event);
            }
        };

        const handleFocus = (event): void => {
            if (onFocus) {
                onFocus(event);
            }
        };

        const handleBlur = (event): void => {
            const regExp = new RegExp(regExpForInputText);
            if (addChipOnBlur && inputValue !== '' && regExp.test(inputValue)) {
                handleAddChip();
            }
            if (onBlur) {
                onBlur(event);
            }
        };

        return (
            <Input
                ref={ref}
                inputRef={inputRef}
                wrapperRef={wrapperRefProp}
                id={id}
                placeholder={placeholder}
                type={type}
                size={size}
                shape={shape}
                variant={variant}
                isDisabled={isDisabled}
                isReadOnly={isReadOnly}
                autoComplete={autoComplete}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={inputValue}
                isMultiline={isMultiline}
                {...props}
                addonLeft={[
                    ...[addonLeft],
                    !!chipArrayValues?.length &&
                        (isMultiline ? (
                            <>
                                {chipArrayValues.map((value, index) => (
                                    <ChipComponent
                                        key={`${index}-${value}`}
                                        size={size}
                                        onDismiss={(): void => handleDeleteChip(index)}
                                        value={getItemsLabel(value)}
                                        isDisabled={isDisabled}
                                        isMultiline={isMultiline}
                                    />
                                ))}
                            </>
                        ) : (
                            <ChipWrapper>
                                {chipArrayValues.map((value, index) => (
                                    <ChipComponent
                                        key={`${index}-${value}`}
                                        size={size}
                                        onDismiss={(): void => handleDeleteChip(index)}
                                        value={getItemsLabel(value)}
                                        isDisabled={isDisabled}
                                        sx={{
                                            marginLeft: 'xxs',
                                            p: '0px 6px 0px 10px',
                                        }}
                                    />
                                ))}
                            </ChipWrapper>
                        )),
                ]}
            />
        );
    },
);

ChipInput.displayName = 'ChipInput';
