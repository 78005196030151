import { LogService } from '@landr/core';
import { ErrorResponse } from '@apollo/client/link/error';
import { OperationDefinitionNode } from 'graphql';
import { EventIds } from './EventIds';

const SensitiveOperationVariableProperties = ['currentpassword', 'newpassword'];

const removeSensitiveProperties = (
  object: Record<string, any>,
  sensitiveProperties: string[]
) => {
  return Object.entries(object).reduce(
    (acc: Record<string, any>, [key, value]) => {
      if (!sensitiveProperties.includes(key.toLowerCase())) {
        if (value instanceof Object) {
          acc[key] = removeSensitiveProperties(value, sensitiveProperties);
        } else {
          acc[key] = value;
        }
      }
      return acc;
    },
    {}
  );
};

// Return undefined if the variable parameter contains any of the string listed in SensitiveProperties.
const sanitizeOperationVariables = (variables?: Record<string, any>) => {
  if (variables) {
    try {
      const sanitizedVariables = removeSensitiveProperties(
        variables,
        SensitiveOperationVariableProperties
      );
      return sanitizedVariables;
    } catch (err) {
      // Do nothing
      return variables;
    }
  }
};

enum ApolloErrors {
  InternalServerError = 'INTERNAL_SERVER_ERROR',
}

const getErrorHandler = (
  log: LogService
): (({ graphQLErrors, networkError, operation }: ErrorResponse) => void) => {
  return ({ graphQLErrors, networkError, operation }: ErrorResponse): void => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        const { message, extensions } = error;
        const errorDefinition = `[GraphQL error]: ${message}`;
        const extraArguments = {
          operationType: (
            operation.query.definitions[0] as OperationDefinitionNode
          )?.operation,
          operationName: operation?.operationName,
          operationVariables: sanitizeOperationVariables(operation?.variables),
        };
        if (extensions) {
          switch (extensions.code) {
            case ApolloErrors.InternalServerError:
              log.error(
                errorDefinition,
                EventIds.ApolloInternalServerError,
                error,
                extraArguments
              );
              break;
            default:
              log.error(
                errorDefinition,
                EventIds.ApolloError,
                error,
                extraArguments
              );
              break;
          }
        } else {
          log.error(
            errorDefinition,
            EventIds.ApolloError,
            error,
            extraArguments
          );
        }
      });
    }
    if (networkError) {
      log.warn(
        `[Network error]: ${networkError.message}`,
        EventIds.ApolloNetworkError,
        networkError
      );
    }
  };
};

export const apolloErrorHandler = (log: LogService) => getErrorHandler(log);
