import { BREAKPOINT_L, useBreakpoints } from 'hooks';
import { NB_POSTS_PER_PAGE } from 'constants/posts';
import Link from 'next/link';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { IndividualCard, MappedPost } from 'ui';
import styles from './Page.module.scss';
import cn from 'classnames';

interface SiblingData {
  href: string;
  content: number;
}

const AfterSibling: FC<PagerProps & { loading: boolean }> = ({
  currentPage,
  totalPages,
  siblingAmount,
  loading,
  pageUrlPrefix,
}) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    if (currentPage < totalPages - siblingAmount && !href) {
      const href = `${pageUrlPrefix}/page/${Math.min(
        currentPage + 10,
        totalPages
      )}`;

      setHref(href);
    }
  }, [currentPage, totalPages, siblingAmount, pageUrlPrefix, href]);

  if (!href) {
    return null;
  }

  return (
    <Link href={href}>
      <span
        className={cn(styles.page, {
          [styles.disabled]: loading,
        })}
      >
        ...
      </span>
    </Link>
  );
};

const BeforeSibling: FC<
  Pick<PagerProps, 'currentPage' | 'siblingAmount' | 'pageUrlPrefix'> & {
    loading: boolean;
  }
> = ({ currentPage, siblingAmount, loading, pageUrlPrefix }) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    if (currentPage > siblingAmount && !href) {
      const href = `${pageUrlPrefix}/page/${Math.max(currentPage - 10, 1)}`;

      setHref(href);
    }
  }, [currentPage, siblingAmount, pageUrlPrefix, href]);

  if (!href) {
    return null;
  }

  return (
    <Link href={href}>
      <span
        className={cn(styles.page, {
          [styles.disabled]: loading,
        })}
      >
        ...
      </span>
    </Link>
  );
};

interface PagerProps {
  totalPages: number;
  currentPage: number;
  siblingAmount: number;
  pageUrlPrefix: string;
}

const Pager: FC<PagerProps> = ({
  totalPages,
  currentPage,
  siblingAmount,
  pageUrlPrefix,
}) => {
  const [loading, setLoading] = useState(false);

  const getSiblings = () => {
    const siblings: Array<SiblingData> = [];

    for (let i = -(siblingAmount - 1); i <= siblingAmount - 1; i++) {
      if (currentPage + i < 1 || currentPage + i > totalPages) {
        continue;
      }

      siblings.push({
        href: `${pageUrlPrefix}/page/${currentPage + i}`,
        content: currentPage + i,
      });
    }

    return siblings;
  };

  const handleLinkOnClick = () => {
    setLoading(true);
  };

  const LeftLinkNavigation = ({ children }: PropsWithChildren<any>) => {
    // Can't use <Link> without href
    // TS2741: Property 'href' is missing in type ...
    if (currentPage === 1) {
      return <>{children}</>;
    } else {
      return (
        <Link href={`${pageUrlPrefix}/page/${currentPage - 1}`} legacyBehavior>
          {children}
        </Link>
      );
    }
  };

  return (
    <div className={styles.pager}>
      <LeftLinkNavigation>
        <a
          className={cn(styles.page, {
            [styles.disabled]: currentPage < 2 || loading,
          })}
        >
          &lsaquo;
        </a>
      </LeftLinkNavigation>
      <BeforeSibling
        siblingAmount={siblingAmount}
        currentPage={currentPage}
        loading={loading}
        pageUrlPrefix={pageUrlPrefix}
      />
      <>
        {getSiblings().map(({ href, content }) => {
          return (
            <Link href={href} key={content}>
              <span
                className={cn(styles.page, {
                  [styles.active]: content === currentPage,
                  [styles.disabled]: loading,
                })}
                onClick={handleLinkOnClick}
              >
                {content}
              </span>
            </Link>
          );
        })}
      </>
      <AfterSibling
        siblingAmount={siblingAmount}
        currentPage={currentPage}
        totalPages={totalPages}
        loading={loading}
        pageUrlPrefix={pageUrlPrefix}
      />
      <Link href={`${pageUrlPrefix}/page/${currentPage + 1}`}>
        <span
          className={cn(styles.page, {
            [styles.disabled]: currentPage === totalPages || loading,
          })}
        >
          &rsaquo;
        </span>
      </Link>
    </div>
  );
};

interface PostsGridProps {
  posts: MappedPost[];
  page: string;
  totalAmountOfPosts?: number | null;
  pageUrlPrefix?: string;
}

export const PostsGrid = (props: PostsGridProps) => {
  const { posts, totalAmountOfPosts, page, pageUrlPrefix } = props;
  const { isBreakpointS } = useBreakpoints();

  return (
    <>
      <div>
        <div className={styles.posts}>
          {posts.map((item) => {
            if (item) {
              return (
                <IndividualCard
                  key={item?.id}
                  post={item}
                  imageSizes={`(max-width: ${BREAKPOINT_L}px) 50vw,
                    20vw`}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
      {totalAmountOfPosts && (
        <Pager
          totalPages={Math.ceil(totalAmountOfPosts / NB_POSTS_PER_PAGE)}
          currentPage={parseInt(page, 10)}
          siblingAmount={isBreakpointS ? 3 : 2}
          pageUrlPrefix={pageUrlPrefix ?? ''}
        />
      )}
    </>
  );
};
