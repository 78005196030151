import { CSSProperties, FC } from 'react';
import Link from 'next/link';
import { SubscriptionForm } from 'components/Common';
import { BasicImage } from 'ui';

import styles from './GeneralSubscription.module.scss';

export interface GeneralSubscriptionProps {
  title: string;
  description: string;
  background?: CSSProperties['background'];
  post: {
    title: string;
    slug: string;
    image: string;
  };
}

export const GeneralSubscription: FC<GeneralSubscriptionProps> = ({
  title,
  description,
  background,
  post: { title: postTitle, slug, image },
}) => {
  const GeneralSubscriptionImage = () => {
    if (!image) return null;

    return <BasicImage src={image} alt={postTitle} />;
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.entry} style={background ? { background } : {}}>
          <div className={styles.ctaRoot}>
            <div>
              <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: title }}
              ></p>
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            </div>
            <SubscriptionForm formType="general_subscription" />
          </div>
          <Link href={`/${slug}`} className={styles.teaserRoot}>
            <div className={styles.mediaWrapper}>
              <div className={styles.mediaItem}>
                <GeneralSubscriptionImage />
              </div>
            </div>
            <h2
              className={styles.postTitle}
              dangerouslySetInnerHTML={{ __html: postTitle }}
            ></h2>
          </Link>
        </div>
      </div>
    </div>
  );
};
