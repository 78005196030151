import { useState } from 'react';
import { Center, Flex, GenericSkeleton, Text, UiState } from '@landr/maestro';
import cn from 'classnames';
import { IndividualCard, Button, MappedPost } from 'ui';
import { SearchForm } from './SearchForm';
import { SearchPostsQueryNode, usePostsSearchQuery } from './hooks';
import TemporaryCover from '../../public/temporary/post-cover-example.png';

import styles from './Search.module.scss';

type FilterTypes = 'all' | 'read' | 'watch';

type Filters = {
  key: FilterTypes;
  label: string;
}[];

const FILTERS: Filters = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'read',
    label: 'Read',
  },
  {
    key: 'watch',
    label: 'Watch',
  },
];

export const Search = () => {
  const {
    data,
    loading,
    error,
    total,
    hasNextPage,
    loadMore,
    amountOfDownloadedPosts,
  } = usePostsSearchQuery();

  const [filterBy, setFilterBy] = useState<'read' | 'watch'>();

  const handleFilterButtonClick = async (filterKey: FilterTypes) => {
    const newFilterByValue = filterKey !== 'all' ? filterKey : undefined;
    setFilterBy(newFilterByValue);
  };

  const PostsContent = () => {
    if (loading) {
      return (
        <>
          <Flex
            flexDirection="row"
            width="100%"
            mb="md"
            data-testid="search-skeletons"
          >
            <GenericSkeleton height="120px" marginRight="md" />
            <GenericSkeleton height="120px" marginRight="md" />
            <GenericSkeleton height="120px" />
          </Flex>
          <Flex flexDirection="row" width="100%">
            <GenericSkeleton height="120px" marginRight="md" />
            <GenericSkeleton height="120px" marginRight="md" />
            <GenericSkeleton height="120px" />
          </Flex>
        </>
      );
    }

    if (error) {
      return (
        <Center>
          <UiState variant="error" mb="lg">
            <UiState.Content>Oops something went wrong.</UiState.Content>
          </UiState>
        </Center>
      );
    }

    if (!data.length) {
      return (
        <Center>
          <Text>Nothing found</Text>
        </Center>
      );
    }

    const progressBarWidth = hasNextPage
      ? (amountOfDownloadedPosts * 100) / total
      : 100;

    const mapPost = (item: SearchPostsQueryNode): MappedPost => {
      const image = item?.featuredImage?.node?.sourceUrl || TemporaryCover;
      const isVideoPost = item?.postFormat === 'video';
      const accentColor = item?.postAdditionalFields?.accentColor || '';
      const postVideo = item?.postFormatVideo?.videoId || '';
      const video = isVideoPost ? postVideo : '';

      const categoryName = item?.categories?.nodes?.length
        ? item?.categories?.nodes[0]?.name
        : '';
      const categorySlug = item?.categories?.nodes?.length
        ? item?.categories?.nodes[0]?.slug
        : '';

      return {
        title: item?.title || '',
        slug: item?.slug || '',
        categoryName: categoryName || '',
        categorySlug: categorySlug || '',
        accentColor,
        image: image as string,
        video,
      };
    };

    return (
      <>
        <div className={styles.posts}>
          {data
            ?.filter((e) => {
              if (filterBy === 'watch') {
                return e?.postFormat === 'video';
              }

              if (filterBy === 'read') {
                return e?.postFormat !== 'video';
              }

              return true;
            })
            .map((item) => {
              if (item) {
                return <IndividualCard key={item?.id} post={mapPost(item)} />;
              }
              return null;
            })}
        </div>
        {data.length && (
          <div className={styles.loadRoot}>
            <p className={styles.loadLabel}>
              You’ve viewed {hasNextPage ? amountOfDownloadedPosts : total} of{' '}
              {total} results
            </p>
            <div className={styles.loadProgress}>
              <div
                className={styles.loadProgressBar}
                style={{ width: `${progressBarWidth}%` }}
              />
            </div>
            {hasNextPage && (
              <Button
                className={styles.loadButton}
                onClick={() => {
                  loadMore();
                }}
                loading={loading}
              >
                Load More
              </Button>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className={styles.root}>
      <main className={styles.main}>
        <SearchForm />
        <div className={styles.actionsRow}>
          <span className={styles.resultsLabel}>
            {data.length ? `${data.length} Results` : ''}&nbsp;
          </span>
          <div className={styles.filterButtons}>
            {FILTERS.map((item) => {
              return (
                <button
                  key={item.key}
                  className={cn(styles.filterButton, {
                    [styles.active]: filterBy === item.key,
                  })}
                  onClick={() => handleFilterButtonClick(item.key)}
                >
                  {item.label}
                </button>
              );
            })}
          </div>
        </div>
        <PostsContent />
      </main>
    </div>
  );
};
