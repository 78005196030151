import {
  getAnonymousId,
  ILogglyConfig,
  IStructuredEvent,
  IUnstructuredEvent,
  LogglyService,
  LogLevel,
  LogService,
  ModuleConfig,
  User,
} from '@landr/core';
import packageJson from '../package.json';

type LogOptions = {
  enabled: boolean;
  separator: string;
  serviceKey: string;
  deploymentKind: string;
  version: string;
  application: string;
  console: {
    enabled: boolean;
    minLevel: LogLevel;
  };
  loggly: {
    enabled: boolean;
    apiKey: string;
    minLevel: LogLevel;
    tags: string;
    maxBundleSize: number;
    consecutiveOccurrences: number;
    forcedModule?: ModuleConfig;
  };
};

class Log extends LogService {
  loggly: LogglyService | undefined;
  _userProfile?: User | null;

  constructor(private options: LogOptions) {
    super(
      /* eslint-disable no-console */
      {
        log: console.log,
        debug: console.log, // Console.debug not standard
        info: console.log, // Console.info not standard
        warn: console.warn,
        error: console.error,
      },
      /* eslint-enable no-console */
      {
        enabled: options.enabled,
        console: {
          enabled: options.console.enabled,
          minLevel: options.console.minLevel,
        },
        separator: options.separator,
      }
    );

    this.options = options;
    this.loggly = this.createLogglyService();
    this.callback = this.logCallback.bind(this);
  }

  set userProfile(userProfile: User | null) {
    this._userProfile = userProfile;
  }

  protected logCallback(event: IStructuredEvent | IUnstructuredEvent): void {
    const extraParams = {
      url:
        typeof window !== 'undefined'
          ? (window.top?.location.href as string)
          : '',
      ...this.formatUserProfileToEventParams(),
    };

    this.loggly?.send(event, extraParams);
  }

  private formatUserProfileToEventParams() {
    if (this._userProfile) {
      return {
        userId: this._userProfile.UserId,
        username: this._userProfile.UserFirstName || '',
        anonymousId: '',
      };
    } else {
      return {
        userId: '',
        username: '',
        anonymousId: typeof window !== 'undefined' ? getAnonymousId() : '',
      };
    }
  }

  private createLogglyService(): LogglyService {
    const config: ILogglyConfig = {
      apiKey: this.options.loggly.apiKey,
      enabled: this.options.loggly.enabled,
      minLevel: this.options.loggly.minLevel,
      tags: this.options.loggly.tags,
      maxBundleSize: this.options.loggly.maxBundleSize,
      forcedModule: this.options.loggly.forcedModule ?? {},
    };

    const properties = {
      serviceKey: this.options.serviceKey,
      deploymentKind: this.options.deploymentKind,
      version: this.options.version,
      application: this.options.application,
      consecutiveOccurrences: this.options.loggly.consecutiveOccurrences,
    };
    return new LogglyService(
      config,
      properties,
      typeof window !== 'undefined' ? window : undefined
    );
  }
}

export const logger = new Log({
  enabled: process.env.NEXT_PUBLIC_LOG_ENABLED === 'true',
  separator: process.env.NEXT_PUBLIC_LOG_SEPARATOR as string,
  serviceKey: process.env.NEXT_PUBLIC_LOG_SERVICE_KEY as string,
  deploymentKind: process.env.NEXT_PUBLIC_LOG_DEPLOYMENT_KIND as string,
  version: packageJson.version,
  application: process.env.NEXT_PUBLIC_LOG_APPLICATION_NAME as string,
  console: {
    enabled: process.env.NEXT_PUBLIC_LOG_CONSOLE_ENABLED === 'true',
    minLevel: process.env.NEXT_PUBLIC_LOG_CONSOLE_MINLEVEL as LogLevel,
  },
  loggly: {
    apiKey: process.env.NEXT_PUBLIC_LOG_LOGGLY_API_KEY as string,
    enabled: process.env.NEXT_PUBLIC_LOG_LOGGLY_ENABLED === 'true',
    minLevel: process.env.NEXT_PUBLIC_LOG_LOGGLY_MINLEVEL as LogLevel,
    tags: process.env.NEXT_PUBLIC_LOG_TAGS as string,
    maxBundleSize:
      Number.parseInt(
        process.env.NEXT_PUBLIC_LOG_LOGGLY_MAX_BUNDLE_SIZE as string,
        10
      ) || 100,
    consecutiveOccurrences:
      Number.parseInt(
        process.env.NEXT_PUBLIC_LOG_LOGGLY_CONSECUTIVE_OCCURRENCES as string,
        10
      ) || 0,
  },
});
