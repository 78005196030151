import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgStoreOutlined = ({ size, title = 'StoreOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            clipPath="url(#store-outlined_svg__store-outlined_svg__clip0_3323_5586)"
        >
            <path d="M18 3H6L3 8.4a3 3 0 106 0 3 3 0 106 0 3 3 0 106 0L18 3zm.6 10.8V21H5.4v-7.2" />
            <path d="M10.2 21v-4.8h3.6V21" />
        </g>
        <defs>
            <clipPath id="store-outlined_svg__store-outlined_svg__clip0_3323_5586">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default withIcon(SvgStoreOutlined);
