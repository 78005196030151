import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgInstagramFilled = ({ size, title = 'InstagramFilled', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="currentColor" fillRule="evenodd">
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M18 7.373c0 .62-.504 1.122-1.125 1.122a1.124 1.124 0 01-1.125-1.122 1.124 1.124 0 012.25 0zm-6 .877v.875a2.875 2.875 0 100 5.75 2.875 2.875 0 000-5.75V8.25zm0 0v-.875a4.625 4.625 0 110 9.25 4.625 4.625 0 010-9.25v.875zm-9.005.125a5.375 5.375 0 015.38-5.38h7.24a5.375 5.375 0 015.38 5.38v7.24a5.375 5.375 0 01-5.38 5.38h-7.24a5.375 5.375 0 01-5.38-5.38v-7.24zm1.75 0v7.24c0 2.01 1.622 3.63 3.63 3.63h7.24c2.01 0 3.63-1.622 3.63-3.63v-7.24c0-2.01-1.622-3.63-3.63-3.63h-7.24a3.625 3.625 0 00-3.63 3.63z" />
        </g>
    </svg>
);

export default withIcon(SvgInstagramFilled);
