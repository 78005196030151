import React, { useState, useEffect, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { CaretRightOutlined, CaretLeftOutlined } from '../../assets/icons';
import { Box } from '../../primitives';
import { Text } from '../Text';
import { IconButton } from '../IconButton';
import { CalendarProps } from './types';
import * as Styled from './styled';

export const Calendar: React.FC<CalendarProps> = ({
    activeDate = '',
    onChange,
    minDate,
    maxDate,
    disabledDates,
    disabledDatesRanges,
    ...props
}) => {
    const activeDateOrFallback = useMemo(() => (activeDate ? dayjs(activeDate) : dayjs()), [activeDate]);

    const [displayDate, setDisplayDate] = useState<Dayjs>(activeDateOrFallback);
    const [selectedDate, setSelectedDate] = useState<Dayjs>();
    const [isInitialized, setIsInitialized] = useState(false);

    const setUserDateObjectHandler = (dateObject: Dayjs) => (): void => {
        setSelectedDate(dateObject);
        onChange && onChange(dateObject);
    };

    const isDayDisabled = (date: Dayjs): boolean => {
        if (minDate && date.isBefore(dayjs(minDate), 'day')) {
            return true;
        }

        if (maxDate && date.isAfter(dayjs(maxDate), 'day')) {
            return true;
        }

        if (disabledDates && disabledDates.find((disabledDate) => date.isSame(dayjs(disabledDate), 'day'))) {
            return true;
        }

        if (
            disabledDatesRanges &&
            disabledDatesRanges.find(
                ({ min, max }) => !date.isBefore(dayjs(min), 'day') && !date.isAfter(dayjs(max), 'day'),
            )
        ) {
            return true;
        }

        return false;
    };

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const locale = window.navigator.language.substr(0, 2);
        if (locale) {
            // locale dynamic import.
            import(`dayjs/locale/${locale}`)
                .then(() => {
                    dayjs.locale(locale);
                })
                // Need this to prevent error in console if dayjs doesn't have user locale.
                // it uses english locale by default
                .catch(() => undefined)
                .finally(() => {
                    setDisplayDate(activeDateOrFallback);
                    setIsInitialized(true);
                });
        } else {
            setIsInitialized(true);
        }
    }, [activeDateOrFallback]);

    useEffect(() => {
        setSelectedDate(dayjs(activeDate));
        setDisplayDate(activeDateOrFallback);
    }, [activeDate, activeDateOrFallback]);

    const month = displayDate.month();

    return (
        <Styled.CalendarWrapper {...props} isInitialized={isInitialized}>
            <Styled.CalendarHeader>
                <IconButton
                    kind="link"
                    icon={CaretLeftOutlined}
                    // TODO: [DS-989] It needs to be translated. Check comments here https://mixgenius.atlassian.net/browse/SRVMKT-1267
                    label="Previous month"
                    onClick={(): void => {
                        setDisplayDate(displayDate.set('month', month - 1));
                    }}
                />

                <Text>
                    {displayDate.format('MMMM')} {displayDate.format('YYYY')}
                </Text>
                <IconButton
                    kind="link"
                    icon={CaretRightOutlined}
                    // TODO: [DS-989] It needs to be translated. Check comments here https://mixgenius.atlassian.net/browse/SRVMKT-1267
                    label="Next month"
                    onClick={(): void => {
                        setDisplayDate(displayDate.set('month', month + 1));
                    }}
                />
            </Styled.CalendarHeader>
            <Box p="lg">
                <Styled.CalendarWeeksContainer>
                    {Array.from({ length: 7 }, (_v, index) => (
                        <Text textAlign="center" key={index} size="xs" py="xxs">
                            {displayDate.day(index).format('ddd')}
                        </Text>
                    ))}
                </Styled.CalendarWeeksContainer>
                <Styled.CalendarDatesContainer skipIndex={displayDate.startOf('month').day() + 1}>
                    {Array.from({ length: displayDate.daysInMonth() }, (_v, index) => {
                        const date = displayDate.set('date', index + 1);

                        return (
                            <Styled.CalendarDateButton
                                key={index}
                                type="button"
                                isToday={date.isSame(dayjs(), 'day')}
                                active={!!selectedDate && date.isSame(selectedDate, 'day')}
                                disabled={isDayDisabled(date)}
                                onClick={setUserDateObjectHandler(date)}
                            >
                                <Text size="sm">{index + 1}</Text>
                            </Styled.CalendarDateButton>
                        );
                    })}
                </Styled.CalendarDatesContainer>
            </Box>
        </Styled.CalendarWrapper>
    );
};

Calendar.displayName = 'Calendar';
