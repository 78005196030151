import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVideoOffOutlined = ({ size, title = 'VideoOffOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17 9.58v7.16c0 .683-.475 1.26-1.06 1.26H11m5.5-12H4.052C3.472 6 3 6.577 3 7.26v9.48c0 .696.477 1.26 1.056 1.26H6.5M18.132 4.391L5 20"
        />
        <path stroke="currentColor" strokeLinejoin="round" strokeWidth={1.5} d="M17 10l4-1v6l-4-1v-4z" />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M5.75 10.231H9.5"
        />
    </svg>
);

export default withIcon(SvgVideoOffOutlined);
