import React, { isValidElement, cloneElement, Children, useEffect, useState, useRef } from 'react';
import { isForwardRef } from 'react-is';
import { maestroComponent, mergeRefs } from '../../utils';
import { useHover, useOnClickOutside, useUpdateEffect } from '../../hooks';
import { Popper } from '../../primitives/Popper';
import { Fade } from '../Fade';
import { Box } from '../../primitives/Box';
import * as Styled from './styled';
import { TooltipProps } from './types';

const TooltipComponent: React.FC<TooltipProps> = ({
    hasArrow = true,
    maxWidth = '240px',
    fontSize = 'xs',
    position = 'top',
    isInverted = false,
    hideOnScroll = false,
    children,
    content,
    onOpen,
    onClose,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const [hoverRef, isHovered] = useHover(hideOnScroll);
    const popperRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(popperRef, () => {
        setOpen(false);
    });

    useEffect(() => {
        setOpen(isHovered);
    }, [isHovered]);

    useUpdateEffect(() => {
        open ? onOpen?.() : onClose?.();
        // exclude onOpen and onClose handlers from deps because we want to run them only on open variable change
        /* eslint-disable-next-line  react-hooks/exhaustive-deps */
    }, [open]);

    const childrenProps = {
        ref: mergeRefs(hoverRef, (children as any).ref),
    };

    return (
        <>
            {isValidElement(children) && Children.only(children) && isForwardRef(children) ? (
                cloneElement(children, { ...props, ...childrenProps })
            ) : (
                <Box display="inline-flex" ref={hoverRef}>
                    {isValidElement(children) && cloneElement(children, { ...props })}
                </Box>
            )}
            <Popper
                isOpen={!!content && open}
                ref={popperRef}
                anchorRef={hoverRef}
                transitionComponent={Fade}
                usePortal
                popperOptions={{
                    placement: position,
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 10],
                            },
                        },
                    ],
                }}
                zIndex={2000}
            >
                <Styled.Tooltip isInverted={isInverted} maxWidth={maxWidth}>
                    <Styled.Content size={fontSize}>{content}</Styled.Content>
                    {hasArrow && <Popper.Arrow />}
                </Styled.Tooltip>
            </Popper>
        </>
    );
};

const TooltipAdoptedForMobile: React.FC<TooltipProps> = ({ children, ...props }) => {
    return <TooltipComponent {...props}>{children}</TooltipComponent>;
};

export const Tooltip = maestroComponent<TooltipProps>('Tooltip', TooltipAdoptedForMobile);
