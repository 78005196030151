import { FC } from 'react';
import cn from 'classnames';
import { IndividualCard, MappedPost } from 'ui';
import { CarouselContainer } from './components/CarouselContainer';

import styles from './Cards.module.scss';

interface CardsProps {
  posts: MappedPost[];
  title?: string;
  subtitle?: string;
  withCardsSubtitle?: boolean | ((post: MappedPost, index: number) => string);
}

export const Cards: FC<CardsProps> = ({
  posts,
  title,
  subtitle,
  withCardsSubtitle,
}) => {
  const postsLength = posts.length;
  const isTwoUp = postsLength <= 2;
  const isThreeUp = postsLength === 3;
  const isCarousel = postsLength > 3;

  const cardSubtitle = (
    item: MappedPost,
    index: number
  ): string | undefined => {
    if (typeof withCardsSubtitle === 'function') {
      return withCardsSubtitle(item, index);
    } else if (withCardsSubtitle) {
      return `Lesson ${index + 1} of ${postsLength}`;
    }
  };

  const CardItems = () => {
    const postComponents = posts.map((item, index) => {
      return (
        <IndividualCard
          key={`${item.slug}-${index}`}
          post={item}
          subtitle={cardSubtitle(item, index)}
          isLesson={isCarousel}
        />
      );
    });

    const shouldRenderCarousel = posts.length > 3;

    if (shouldRenderCarousel) {
      return <CarouselContainer items={postComponents} />;
    }

    return (
      <div
        className={cn(styles.cardListWrapper, {
          [styles.withoutHeading]: !title,
          [styles.carouselLayout]: isCarousel,
        })}
      >
        <div
          className={cn(styles.cardsList, {
            [styles.twoUp]: isTwoUp,
            [styles.threeUp]: isThreeUp,
            [styles.carousel]: isCarousel,
          })}
        >
          {postComponents}
        </div>
      </div>
    );
  };

  const CardTitle = () => {
    if (!title) return null;

    return (
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      ></p>
    );
  };

  const CardSubtitle = () => {
    if (!subtitle) return null;

    return (
      <p
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      ></p>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div
          className={cn(styles.listWrapper, {
            [styles.withoutHeading]: !title,
            [styles.carouselLayout]: isCarousel,
          })}
        >
          <CardTitle />
          <CardSubtitle />
          <CardItems />
        </div>
      </div>
    </div>
  );
};
