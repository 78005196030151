import styled from 'styled-components';
import React from 'react';
import { Box } from '../../primitives/Box';
import { TableSemanticContext } from './TableSemanticContext';
import { TableHeadProps } from './types';

const Head = styled(Box)<TableHeadProps>`
    display: table-header-group;
`;

const TableHead: React.FC<TableHeadProps> = ({ children, ...props }) => {
    return (
        <TableSemanticContext.Provider value={{ location: 'head' }}>
            <Head {...props}>{children}</Head>
        </TableSemanticContext.Provider>
    );
};

export { TableHead };
