import React from 'react';
import { TransitionProps } from 'react-transition-group/Transition';
import { TransitionBase } from '../../primitives/TransitionBase';

const generateTransitionStates = (x = 0, y = 0): Record<string, any> => ({
    entering: {
        transform: `translate3d(${x}%, ${y}%, 0)`,
    },
    entered: {
        transform: 'translate3d(0, 0, 0)',
    },
    exiting: {
        transform: `translate3d(${x}%, ${y}%, 0)`,
    },
    exited: {
        transform: `translate3d(${x}%, ${y}%, 0)`,
    },
});

const styles = {
    top: generateTransitionStates(0, -100),
    right: generateTransitionStates(100, 0),
    bottom: generateTransitionStates(0, 100),
    left: generateTransitionStates(-100, 0),
};

export interface SlideProps extends Omit<TransitionProps, 'timeout'> {
    timeout?: number | { appear?: number | undefined; enter?: number | undefined; exit?: number | undefined };
    side?: 'top' | 'right' | 'bottom' | 'left';
}

const Slide: React.FC<SlideProps> = ({ children, in: inProp, timeout = 100, side = 'bottom', ...props }) => (
    <TransitionBase
        appear
        in={inProp}
        timeout={timeout}
        transitionStyles={styles[side]}
        transitionProperties="transform"
        {...props}
    >
        {children}
    </TransitionBase>
);

export { Slide };
