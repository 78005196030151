import { css } from 'styled-components';
import { Box } from '../Box';
import { spacing } from '../../utils';
import styled from '../../utils/styled';
import { MediaProps } from './types';

export const Image = styled(Box)`
    flex: 0 1 auto;
`;

Image.displayName = 'Media.Image';

export const Body = styled(Box)`
    flex: 1 1 auto;
`;

Body.displayName = 'Media.Body';

export const Media = styled(Box)<MediaProps>`
    display: flex;
    flex-direction: ${({ isReversed }) => (isReversed ? 'row-reverse' : 'row')};
    align-items: ${({ align, isStacked }) => !isStacked && align};

    ${({ isStacked, isReversed }) =>
        isStacked &&
        css`
            flex-direction: ${isReversed ? 'column-reverse' : 'column'};
            align-items: center;
        `}

    & & {
        padding-top: ${({ gutter }) => gutter && spacing(gutter)};
    }

    ${Body} {
        /* Not stacked */
        ${({ gutter, isStacked, isReversed }) => {
            if (isStacked) {
                return;
            }

            if (isReversed) {
                return css`
                    &:first-child {
                        margin-left: ${gutter && spacing(gutter)};
                    }

                    &:last-child {
                        margin-right: ${gutter && spacing(gutter)};
                    }
                `;
            } else {
                return css`
                    &:first-child {
                        margin-right: ${gutter && spacing(gutter)};
                    }

                    &:last-child {
                        margin-left: ${gutter && spacing(gutter)};
                    }
                `;
            }
        }}

        /* Stacked */
        ${({ gutter, isStacked, isReversed }) => {
            if (!isStacked) {
                return;
            }

            if (isReversed) {
                return css`
                    &:first-child {
                        margin-top: ${gutter && spacing(gutter)};
                    }

                    &:last-child {
                        margin-bottom: ${gutter && spacing(gutter)};
                    }
                `;
            } else {
                return css`
                    &:first-child {
                        margin-bottom: ${gutter && spacing(gutter)};
                    }

                    &:last-child {
                        margin-top: ${gutter && spacing(gutter)};
                    }
                `;
            }
        }}

        &:only-child {
            margin: 0;
        }

        ${({ isStacked }) =>
            isStacked &&
            css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}
    }
`;
