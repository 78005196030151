import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { AspectRatioProps } from './types';

export const AspectRatio = styled(Box)<AspectRatioProps>`
    position: relative;
    max-width: 100%;

    &::before {
        content: '';
        height: 0;
        display: block;
        padding-bottom: ${({ ratio = 1 }) => (1 / ratio) * 100}%;
    }

    &::after {
        content: '';
        display: table;
        clear: both;
    }

    & > *:not(style) {
        overflow: hidden;
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    & > img,
    & > video {
        object-fit: cover;
    }
`;
