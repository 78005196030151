import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgUserPlusOutlined = ({ size, title = 'UserPlusOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M14.75 19.25v-1.583c0-2.106-3.998-3.167-6-3.167-2.003 0-6 1.06-6 3.167v1.583"
        />
        <path stroke="currentColor" strokeWidth={1.5} d="M9 11.25a3.25 3.25 0 100-6.5 3.25 3.25 0 000 6.5z" />
        <path stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} d="M18.25 15.5V9M15 12.25h6.5" />
    </svg>
);

export default withIcon(SvgUserPlusOutlined);
