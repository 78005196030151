import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgDragOutlined = ({ size, title = 'DragOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.016 12a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm0 5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM11 7a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm5 5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm0 5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm0-10a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
        />
    </svg>
);

export default withIcon(SvgDragOutlined);
