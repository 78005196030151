import React from 'react';
import { TransitionProps } from 'react-transition-group/Transition';
import { TransitionBase } from '../../primitives/TransitionBase';

const styles = {
    entering: {
        opacity: 0,
    },
    entered: {
        opacity: 1,
    },
    exiting: {
        opacity: 0,
    },
    exited: {
        opacity: 0,
    },
};

export interface FadeProps extends Omit<TransitionProps, 'timeout'> {
    timeout?: number | { appear?: number | undefined; enter?: number | undefined; exit?: number | undefined };
}

const Fade: React.FC<FadeProps> = ({ children, in: inProp, timeout = 100, ...props }) => (
    <TransitionBase appear in={inProp} timeout={timeout} transitionStyles={styles} {...props}>
        {children}
    </TransitionBase>
);

export { Fade };
