import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { spacing, variant, themeMode, palette } from '../../utils';
import { IndicatorProps } from './types';

const indicatorVariant = variant({
    prop: 'variant',
    variants: {
        primary: {
            backgroundColor: 'primary.300',
        },
        warning: {
            backgroundColor: 'warning.300',
        },
        danger: {
            backgroundColor: 'danger.300',
        },
    },
});

const indicatorSize = variant({
    prop: 'size',
    variants: {
        sm: {
            fontSize: 'base',
        },
        md: {
            fontSize: 'lg',
        },
    },
});

type StyledIndicatorProps = Pick<IndicatorProps, 'size'>;
type StyledIndicatorIconProps = Pick<IndicatorProps, 'variant'>;

export const Indicator = styled(Box)<StyledIndicatorProps>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    ${indicatorSize};
    color: ${themeMode({
        dark: palette('neutral.50'),
        light: palette('neutral.900'),
    })};
`;

export const IndicatorIcon = styled.div<StyledIndicatorIconProps>`
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin-right: ${spacing(3)};
    ${indicatorVariant};
`;
