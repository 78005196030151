import styled, { css } from '../../utils/styled';
import { palette, themeMode } from '../../utils';
import { Box, BoxProps } from '../../primitives/Box';
import { spacing } from '../../utils';
import { CalendarWrapperProps } from './types';

export const CalendarWrapper = styled(Box)<BoxProps & CalendarWrapperProps>`
    background-color: ${themeMode({
        light: palette('neutral.0'),
        dark: palette('neutral.800'),
    })};
    color: ${themeMode({
        light: palette('neutral.800'),
        dark: palette('neutral.50'),
    })};
    ${({ isInitialized }) => !isInitialized && 'visibility: hidden'}
    user-select: none;
    width: 344px;
`;

export const CalendarDatesContainer = styled(Box)<{ skipIndex: number }>`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    button:first-child {
        grid-column: ${({ skipIndex }) => skipIndex};
    }
`;

export const CalendarDateButton = styled('button')<{ isToday: boolean; active: boolean }>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    place-items: center;
    outline: none;
    cursor: pointer;
    margin: ${spacing('xxs')} ${spacing('xxs')} 0 0;
    &:not(:disabled):hover {
        color: ${palette('neutral.0')};
        background-color: ${palette('primary.500')};
        border-color: ${palette('primary.500')};
    }
    border-color: ${({ isToday }) =>
        isToday &&
        themeMode({
            light: palette('neutral.300'),
            dark: palette('neutral.500'),
        })};
    &[disabled],
    &:disabled {
        color: ${themeMode({
            light: palette('neutral.400'),
            dark: palette('neutral.500'),
        })};
        cursor: default;
    }
    ${({ active }) =>
        active &&
        css`
            color: ${palette('neutral.0')};
            background-color: ${palette('primary.500')};
            border-color: ${palette('primary.500')};
        `}
`;

export const CalendarHeader = styled(Box)<BoxProps>`
    border-bottom: ${themeMode({
        light: `1px solid`,
        dark: `1px solid`,
    })};
    border-bottom-color: ${themeMode({
        light: palette('neutral.200'),
        dark: palette('neutral.700'),
    })};
    padding: ${spacing('lg')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -1px;
`;

export const CalendarWeeksContainer = styled(Box)<BoxProps>`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    color: ${themeMode({
        light: palette('neutral.500'),
        dark: palette('neutral.400'),
    })};
`;
