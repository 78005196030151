import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgHeartOutlined = ({ size, title = 'HeartOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            d="M7.996 4.75c-1.09 0-2.178.427-3.005 1.279a4.426 4.426 0 000 6.146l6.75 6.967a.355.355 0 00.51 0c2.253-2.32 4.505-4.642 6.758-6.961a4.425 4.425 0 000-6.147 4.179 4.179 0 00-6.017 0L12 7.054l-.993-1.025a4.187 4.187 0 00-3.01-1.279"
        />
    </svg>
);

export default withIcon(SvgHeartOutlined);
