import React from 'react';
import { Box } from '../../primitives/Box';
import { Tooltip } from '../Tooltip';
import { Text } from '../Text';
import { InfoOutlined } from '../../assets/icons';
import { VisuallyHidden, ConditionalWrapper } from '../../primitives';
import { forwardRef } from '../../system';
import * as Styled from './styled';
import { FormControlProps, ValidationMessageProps, LabelProps, FormControlDescriptionProps } from './types';
import { useFormControlContext, FormControlContext } from './FormControlContext';

const FormControl: React.FC<FormControlProps> = ({
    isRequired = false,
    isInvalid = false,
    isValid = false,
    isDisabled = false,
    isReadOnly = false,
    children,
    ...props
}) => (
    <FormControlContext.Provider value={{ isRequired, isInvalid, isValid, isDisabled, isReadOnly }}>
        <Styled.FormControl {...props}>{children}</Styled.FormControl>
    </FormControlContext.Provider>
);

FormControl.displayName = 'FormControl';

const Label = forwardRef<LabelProps, 'label'>(({ htmlFor, hint, isHidden, children, ...props }, ref) => {
    const { isRequired } = useFormControlContext();
    return (
        <ConditionalWrapper
            condition={isHidden}
            wrapper={(children): React.ReactNode => (
                <>
                    <VisuallyHidden>{children}</VisuallyHidden>
                </>
            )}
        >
            <>
                <Styled.Label ref={ref} htmlFor={htmlFor} {...props}>
                    {children}
                    {isRequired && <Box as="span">*</Box>}
                    {!!hint && (
                        <Tooltip content="Tooltip" position="top" hasArrow>
                            <InfoOutlined />
                        </Tooltip>
                    )}
                </Styled.Label>
            </>
        </ConditionalWrapper>
    );
});

Label.displayName = 'FormControl.Label';

const Description: React.FC<FormControlDescriptionProps> = ({ children, ...props }) => {
    return (
        <Styled.Description {...props}>
            <Text contrast="low">{children}</Text>
        </Styled.Description>
    );
};

Description.displayName = 'FormControl.Description';

const ValidationMessage: React.FC<ValidationMessageProps> = ({ children }: ValidationMessageProps) => {
    const { isValid, isInvalid } = useFormControlContext();

    return (
        <>
            {(isInvalid || isValid) && (
                <Styled.ValidationMessage isInvalid={isInvalid} isValid={isValid}>
                    {children}
                </Styled.ValidationMessage>
            )}
        </>
    );
};

ValidationMessage.displayName = 'FormControl.ValidationMessage';

export default Object.assign(FormControl, {
    Label,
    ValidationMessage,
    HelpText: Styled.HelpText,
    Description,
});
