import styled, { css } from '../../utils/styled';
import { spacing } from '../../utils';
import { Box } from '../../primitives/Box';
import { TilesProps } from './types';

export const Tiles = styled(Box)<TilesProps>`
    display: grid;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
    grid-auto-rows: 1fr;
    place-items: start;
    gap: ${({ gutter }) => spacing(gutter || 'md')};
    ${({ direction, rows }) => direction === 'vertical' && css`
        grid-auto-flow: column;
        grid-template-rows: repeat(${rows}, 0fr);
    `}
`;
