import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgPencilPaperOutlined = ({ size, title = 'PencilPaperOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M16.637 4.563a.792.792 0 011.123-.003l1.68 1.68a.79.79 0 01-.004 1.123l-8.665 8.666L7.4 16.6l.57-3.37 8.667-8.667z"
            clipRule="evenodd"
        />
        <path
            stroke="currentColor"
            strokeLinecap="square"
            strokeWidth={1.5}
            d="M15.772 7.436l1.584 1.584M9 13l1.584 1.584"
        />
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M18 13.54v5.52c0 .519-.532.94-1.206.94H5.206C4.54 20 4 19.581 4 19.06V6.94C4 6.421 4.532 6 5.206 6h5"
        />
    </svg>
);

export default withIcon(SvgPencilPaperOutlined);
