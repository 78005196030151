import React from 'react';
import { forwardRef } from '../../../system';
import { RadioCardAddonProps } from './types';
import * as Styled from './styled';

export const Addon = forwardRef<RadioCardAddonProps, 'div'>((props, ref) => {
    const { children, ...rest } = props;
    return (
        <Styled.Addon ref={ref} {...rest}>
            {children}
        </Styled.Addon>
    );
});

Addon.displayName = 'RadioCard.Addon';
