import styled from 'styled-components';
import { css } from '@styled-system/css';
import { Box } from '../../primitives/Box';
import { themeMode, variant, color } from '../../utils';
import { PopoverBaseProps } from '.';

const PopoverSizes = variant({
    prop: 'size',
    variants: {
        auto: { width: 'auto', minWidth: '260px' },
        xs: { width: '160px' },
        sm: { width: '240px' },
        md: { width: '360px' },
        lg: { width: '480px' },
    },
});

export const Container = styled(Box)<{ size: PopoverBaseProps['size'] }>(
    (props) =>
        css({
            backgroundColor: color('surface')(props),
            color: themeMode({
                light: 'neutral.900',
                dark: 'neutral.0',
            })(props),
            boxShadow: 3,
            borderRadius: 'base',
            overflow: 'hidden',
            margin: '0 auto',
            maxWidth: '100%',
            py: 'xs',
            px: 'sm',
        }),
    PopoverSizes,
);
