import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgCancelOutlined = ({ size, title = 'CancelOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g stroke="currentColor" strokeWidth={1.5}>
            <path d="M12.5 20.25a8.25 8.25 0 100-16.5 8.25 8.25 0 000 16.5z" />
            <path strokeLinecap="round" d="M18 17.5L7 6.5" />
        </g>
    </svg>
);

export default withIcon(SvgCancelOutlined);
