import React from 'react';
import { forwardRef } from '../../system';
import { Divider } from '../Divider';
import { NavContext } from './NavContext';
import { NavDropdown, NavDropdownContent, NavDropdownItem, NavDropdownTrigger } from './NavDropdown';
import { NavItem } from './NavItem';
import { NavLink } from './NavLink';
import { NavProps } from './types';
import { NavOverflow } from './NavOverflow';
import * as Styled from './styled';

export const NavComponent = forwardRef<NavProps, 'div'>((props, ref) => {
    const { children, size = 'md', variant = 'default', isVertical = false, role, as = 'div', ...rest } = props;

    return (
        <NavContext.Provider value={{ size, variant, isVertical }}>
            <Styled.Nav ref={ref} as={as} role={role} variant={variant} size={size} isVertical={isVertical} {...rest}>
                {children}
            </Styled.Nav>
        </NavContext.Provider>
    );
});

NavComponent.displayName = 'Nav';

export const Nav = Object.assign(NavComponent, {
    Item: NavItem,
    Link: NavLink,
    Dropdown: NavDropdown,
    DropdownTrigger: NavDropdownTrigger,
    DropdownContent: NavDropdownContent,
    DropdownItem: NavDropdownItem,
    DropdownDivider: Divider,
    Overflow: NavOverflow,
});
