import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgQuoteOutlined = ({ size, title = 'QuoteOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth={1.5}
            d="M5 20V4a1 1 0 011-1h12.5a1 1 0 011 1v16a1 1 0 01-1 1H6a1 1 0 01-1-1z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            stroke="currentColor"
            d="M12.78 11.919h0c.942.264 1.493.53 1.81.817.284.258.41.562.41 1.033 0 .353-.139.656-.42.9-.292.253-.758.459-1.414.541l-.438.055V16.5h-.42v-1.235l-.417-.07c-.949-.161-1.58-.63-1.802-1.232h.406c.264.833 1.178 1.287 2.023 1.287.61 0 1.122-.11 1.497-.361.401-.268.6-.67.6-1.114 0-.194-.018-.4-.092-.6a1.27 1.27 0 00-.383-.538c-.325-.275-.824-.46-1.485-.649h0c-.814-.232-1.445-.413-1.885-.686a1.286 1.286 0 01-.442-.418 1.152 1.152 0 01-.15-.615c0-.374.165-.678.463-.926.309-.256.754-.443 1.254-.532l.413-.073V7.5h.42v1.235l.417.07c.888.15 1.436.588 1.632 1.158h-.33a1.493 1.493 0 00-.416-.717c-.357-.34-.875-.496-1.513-.496-1.115 0-1.991.595-1.991 1.512 0 .276.073.529.233.745.153.207.359.345.564.446.313.154.731.268 1.159.384l.297.082z"
        />
    </svg>
);

export default withIcon(SvgQuoteOutlined);
