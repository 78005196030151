import styled from '../../utils/styled';
import { spacing, palette, variant } from '../../utils';
import { Box } from '../../primitives/Box';
import { Text } from '../Text';
import { UiStateProps } from './types';

const variantStyles = variant({
    prop: 'variant',
    variants: {
        empty: {
            color: 'neutral.400',
        },
        success: {
            color: 'primary.500',
        },
        danger: {
            color: 'danger.500',
        },
    },
});

export const Icon = styled(Box)<UiStateProps>`
    font-size: 0;
    padding-bottom: ${spacing('sm')};
`;

export const Content = styled(Text)`
    color: ${palette('neutral.400')};
`;

export const UiState = styled(Box)<UiStateProps>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: ${({ align }) => align};
    ${variantStyles};
`;
