import styled from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import { color as colorToken, zIndex } from '../../utils';
import { Box } from '../../primitives/Box';
import { ModalProps } from './types';

const modalWrapperScrollBehavior = variant({
    prop: 'scrollBehavior',
    variants: {
        inside: {
            overflow: 'hidden',
        },
        outside: {
            overflow: 'auto',
            alignItems: 'flex-start',
        },
    },
});

export const Wrapper = styled(Box)<Partial<ModalProps>>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
    @media screen and (pointer: coarse) {
        align-items: flex-start;
    }
    outline: 0;
    z-index: ${zIndex('modal')};
    ${modalWrapperScrollBehavior};
`;

const styles = css({
    boxShadow: 2,
    // styled-system doesn't support responsive object, must use array for now
    margin: ['sm', 'sm', 'md', 'xl'],
    borderRadius: 'base',
});

const modalSizes = variant({
    prop: 'size',
    variants: {
        sm: {
            width: '360px',
        },
        md: {
            width: '540px',
        },
        lg: {
            width: '920px',
        },
    },
});

const modalFullsize = variant({
    prop: 'scrollBehavior',
    variants: {
        inside: {
            height: '100%',
            maxHeight: [null, 'calc(100% - 16px)', 'calc(100% - 32px)', 'calc(100% - 48px)'],
            width: '100%',
            maxWidth: [null, 'calc(100% - 16px)', 'calc(100% - 32px)', 'calc(100% - 48px)'],
        },
        outside: {
            minHeight: [null, 'calc(100% - 16px)', 'calc(100% - 32px)', 'calc(100% - 48px)'],
            width: '100%',
            maxWidth: [null, 'calc(100% - 16px)', 'calc(100% - 32px)', 'calc(100% - 48px)'],
        },
    },
});

const modalScrollBehavior = variant({
    prop: 'scrollBehavior',
    variants: {
        inside: {
            maxHeight: ['calc(100% - 16px)', 'calc(100% - 32px)', 'calc(100% - 48px)', '90vh'],
        },
        outside: {},
    },
});

export const Modal = styled(Box)<Partial<ModalProps>>`
    position: relative;
    color: ${colorToken('text.base')};
    display: flex;
    flex-direction: column;
    width: 100%;
    outline: none;
    z-index: ${zIndex('modal')};
    ${styles};
    ${(props) => (props.isFull ? modalFullsize : modalSizes)};
    ${modalScrollBehavior};
`;
