import React, { Children, cloneElement, isValidElement } from 'react';
import { forwardRef } from '../../system';
import { DragOutlined } from '../../assets/icons';
import { useDragAndDrop } from '../../hooks';
import { filterChildrenByType } from '../../utils';
import { Box } from '../../primitives';
import { AssetCard } from '../AssetCard';
import { useMountEffect } from '../../hooks/useMountEffect';
import { AssetCardGroupProps } from './types';
import { Styled } from './styled';

// TODO: [DS-1380] convert AssetCardGroup to context

const GroupedAssetCard = (props) => {
    const { item, isDragging } = props;

    return (
        <Box py="xxs">
            <AssetCard {...item} key={item.id} icon={DragOutlined} sx={{ boxShadow: isDragging && '3' }}>
                {item.children}
            </AssetCard>
        </Box>
    );
};

export const AssetCardGroup = forwardRef<AssetCardGroupProps, 'div'>((props, ref) => {
    const { children, isReorderable = false, isSelectable = false, ...rest } = props;

    const childProps = Children.toArray(children).map((child, index) => {
        if (isValidElement(child)) {
            return { id: index, ...child?.props, isSelectable };
        } else {
            return null;
        }
    });

    const { DragAndDropList, setItems } = useDragAndDrop();

    useMountEffect(() => {
        setItems(childProps);
    });

    const selectableAssetCards = filterChildrenByType(children, AssetCard)?.map((card) =>
        cloneElement(card, { isSelectable }),
    );

    return (
        <Styled.AssetCardGroup ref={ref} {...rest}>
            {isReorderable ? (
                <DragAndDropList>{(props) => <GroupedAssetCard {...props} />}</DragAndDropList>
            ) : (
                selectableAssetCards
            )}
        </Styled.AssetCardGroup>
    );
});

AssetCardGroup.displayName = 'AssetCardGroup';
