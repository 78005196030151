import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgChromaticDark = ({ size, title = 'ChromaticDark', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path fill="#F9FAFB" d="M34.5 15.75h-33V30h33V15.75z" />
        <path
            fill="#142739"
            d="M33.75 5.25H2.25a1.5 1.5 0 00-1.5 1.5v22.5a1.5 1.5 0 001.5 1.5h31.5a1.5 1.5 0 001.5-1.5V6.75a1.5 1.5 0 00-1.5-1.5zm0 24H2.25v-12h3v7.5h1.5v-7.5h3v7.5h1.5v-7.5H15v7.5h1.5v-7.5h3v7.5H21v-7.5h3.75v7.5h1.5v-7.5h3v7.5h1.5v-7.5h3v12z"
        />
        <path
            fill="#32CBCB"
            d="M30 12h-9a.75.75 0 110-1.5h9a.75.75 0 110 1.5zm-24 .75a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        />
        <path fill="#5A6774" d="M12 12.75a1.5 1.5 0 110-3 1.5 1.5 0 010 3z" />
    </svg>
);

export default withIcon(SvgChromaticDark);
