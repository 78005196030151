import { spacing, variant } from '../../utils';
import styled from '../../utils/styled';
import { Box } from '../../primitives/Box';
import { ChipGroupProps } from './types';

const chipGroupDirectionStyles = variant({
    prop: 'direction',
    compoundProp: 'isChipsFillGroupWidth',
    variants: {
        horizontal: {
            flexDirection: 'row',
            true: {
                '& > div': {
                    flex: '1 1 auto',
                },
            },
        },
        vertical: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            false: {
                alignItems: 'center',
            },
            true: {
                alignItems: 'stretch',
            },
        },
    },
});

export const ChipGroup = styled(Box)<ChipGroupProps>`
    display: flex;
    ${chipGroupDirectionStyles};

    --gap: ${({ gutter = 'xs' }) => spacing(gutter)};

    margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
    width: calc(100% + var(--gap));

    & > div {
        margin: var(--gap) 0 0 var(--gap);
    }
`;
