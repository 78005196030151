import React, { useState, useEffect } from 'react';
import { Input } from '../Input';
import { CaretDownOutlined } from '../../assets/icons';
import { useThrottle } from '../../hooks';
import { Text } from '../Text';
import { ButtonProps } from '../Button';
import { List } from '../List';
import { Box, BoxProps } from '../../primitives/Box';
import { MaestroBaseProps } from '../../utils';
import {
    ListItem,
    SubItemsWrapper,
    ListFilterWrapper,
    ListItemsScrollContainer,
    ListItemsItem,
    CaretDownWrapper,
} from './styled';
import { ListFilterContentItem, FilterChild } from './types';

interface ListFilterProps extends FilterChild {
    /**
     * Is dropdown list should be displayed with "Search" input component
     */
    withSearch?: boolean;
    /**
     * Filter options that will appear in the dropdown menu and in the responsive design.
     * Objects in the array must contain "name" and "code" properties.
     * For better understanding of this component recommended to visit playground in storybook:
     * https://maestro.landr.com/storybook/index.html?path=/story/components-data-filters--playground
     */
    data: ListFilterContentItem[];
    children?: ({
        data,
    }: {
        data: ListFilterContentItem[];
        searchText: string;
        hideResponsiveMenu?: () => void;
    }) => React.ReactNode;
}

interface ListFilterContentProps {
    /**
     * ```
     * {
     *  name: string;
     *  code: string;
     *  subItems?: {
     *      name: string;
     *      code: string;
     *  }[]
     * }
     * ```
     */
    data: ListFilterContentItem[];
    searchText?: string;
    onApply: (code: string) => void;
    scrollContainerProps?: MaestroBaseProps<BoxProps>;
    hideResponsiveMenu?: () => void;
}

interface RichListFilterItemProps {
    searchText?: string;
    item: ListFilterContentItem;
    onApply: (code: string) => void;
}

interface ListFilterItemProps extends ButtonProps {
    item: ListFilterContentItem;
    onApply: (code: string) => void;
    searchText?: string;
    onCaretDownPress?: () => void;
    areSubItemsVisible?: boolean;
}

interface ListFilterSubComponents {
    /**
     * Convenient wrapper, for the most common use-cases of ListFilter.
     * Basically, using this component you probably won't need other SubComponents.
     * Just wrap this one in function, pass to props values from this function and that's it.
     *
     * @param data array of items to show in list.
     * @param onApply function to call when click on filter
     * @param searchText text from search input
     * @param scrollContainerProps style props for scroll container
     * @param hideResponsiveMenu function to close parent ResponsiveMenu
     */
    Content: React.FC<ListFilterContentProps>;
    /**
     * UI presentation of ListFilterItem.
     * Highlights a part of item.name that matches to a `searchString`.
     *
     * @param searchText text from search input
     */
    Item: React.FC<ListFilterItemProps>;
}
/**
 * Component handles internal search through available filters, represented in `data` prop
 * Accepts children as a function, and pass filtered `data` array to children.
 *
 * The most common usage hierarchy looks like this:
 * ```
 * <Filters.Filter>
 *   <ListFilter>
 *     ({ data }) =>
 *       <ListFilter.ListFilterContent data={data}>
 * ```
 *
 * @param withSearch show search field or not, optional, `true` by default
 * @param data is array of items to show in list. Supports only 2 levels of nesting: items -> sub-items, not items -> sub-items -> sub-items...
 * @param children function, that accepts filtered by search string filters list.
 */
export const ListFilter: React.FC<ListFilterProps> & ListFilterSubComponents = ({
    withSearch = true,
    data,
    children,
    hideResponsiveMenu,
}) => {
    const [filteredData, setFilteredData] = useState<typeof data>(data);
    const [searchText, setSearchText] = useState('');

    function handleSearch(searchText: string) {
        if (!searchText) {
            setSearchText('');
            setFilteredData(data);
            return;
        }
        const lowercasedSearchText = searchText.toLowerCase();

        const results = data.reduce((acc: ListFilterContentItem[], item) => {
            const filteresSubItems = item.subItems
                ? item.subItems.filter((subItem) => {
                      return subItem.name.toLowerCase().indexOf(lowercasedSearchText) !== -1;
                  })
                : [];

            if (item.name.toLowerCase().indexOf(lowercasedSearchText) !== -1) {
                acc.push({
                    ...item,
                    subItems: filteresSubItems,
                });
            } else if (filteresSubItems.length) {
                acc.push({
                    ...item,
                    subItems: filteresSubItems,
                });
            }

            return acc;
        }, []);

        setSearchText(lowercasedSearchText);
        setFilteredData(results);
    }

    const runThrottledSearch = useThrottle((searchText: string) => {
        handleSearch(searchText);
    }, 200);

    function onSearch(e: React.ChangeEvent<HTMLInputElement>) {
        runThrottledSearch(e.target.value);
    }

    return (
        <ListFilterWrapper>
            {withSearch && (
                <Box mx="lg" mb="lg" pt="xs">
                    <Input placeholder="Search" onChange={onSearch}>
                        {/* TODO: Implement InputGroup here */}
                        {/* <Input.After>
                            <SearchOutlined />
                        </Input.After> */}
                    </Input>
                </Box>
            )}
            {children && children({ data: filteredData, searchText, hideResponsiveMenu })}
        </ListFilterWrapper>
    );
};

const ListFilterContent: React.FC<ListFilterContentProps> = ({
    data,
    onApply,
    searchText,
    scrollContainerProps,
    hideResponsiveMenu,
}) => {
    function handleApply(code: string) {
        hideResponsiveMenu && hideResponsiveMenu();
        onApply(code);
    }

    return (
        <ListItemsScrollContainer {...scrollContainerProps}>
            <List>
                {data.map((item) => {
                    return (
                        <RichListFilterItem key={item.code} searchText={searchText} item={item} onApply={handleApply} />
                    );
                })}
            </List>
        </ListItemsScrollContainer>
    );
};

/**
 * Handles sub-list's open/close logic.
 */
const RichListFilterItem: React.FC<RichListFilterItemProps> = ({ item, onApply, searchText }) => {
    const [areSubItemsVisible, setVisible] = useState(Boolean(searchText));

    useEffect(() => {
        setVisible(Boolean(searchText));
    }, [searchText]);

    return (
        <>
            <ListFilterItem
                item={item}
                onApply={onApply}
                onCaretDownPress={() => setVisible(!areSubItemsVisible)}
                searchText={searchText}
                areSubItemsVisible={areSubItemsVisible}
            />
            {areSubItemsVisible && item.subItems && item.subItems.length !== 0 && (
                <SubItemsWrapper>
                    {item.subItems.map((subItem) => {
                        return (
                            <ListFilterItem
                                key={subItem.code}
                                item={subItem}
                                onApply={onApply}
                                searchText={searchText}
                            />
                        );
                    })}
                </SubItemsWrapper>
            )}
        </>
    );
};

export const ListFilterItem: React.FC<ListFilterItemProps> = ({
    item,
    onApply,
    onCaretDownPress,
    searchText,
    areSubItemsVisible,
    ...props
}) => {
    function highlightMatchText() {
        if (!searchText) {
            return item.name;
        }
        const regex = new RegExp(searchText, 'gmi');

        return item.name.replace(regex, (match) => {
            return `<span class="highlight">${match}</span>`;
        });
    }

    return (
        <>
            <ListItemsItem>
                <ListItem
                    onClick={() => {
                        onApply(item.code);
                    }}
                    justifyContent="flex-start"
                    isFull
                    variant="ghost"
                    {...props}
                >
                    <Text size="lg" fontWeight="normal" dangerouslySetInnerHTML={{ __html: highlightMatchText() }} />
                </ListItem>
                {item.subItems && item.subItems.length !== 0 && (
                    <CaretDownWrapper
                        onClick={onCaretDownPress}
                        py={12}
                        pr={20}
                        pl={16}
                        areSubItemsVisible={areSubItemsVisible}
                    >
                        <CaretDownOutlined />
                    </CaretDownWrapper>
                )}
            </ListItemsItem>
        </>
    );
};

ListFilter.Content = ListFilterContent;
ListFilter.Item = ListFilterItem;
export { ListItemsScrollContainer };
