import { gql } from '@apollo/client';

export const GET_LESSONS_POST = gql`
  query LessonsPost(
    $id: ID!
    $idType: LessonsPostIdType
    $lessonPostsFirst: Int
    $lessonPostsWhere: LessonsPostToPostConnectionWhereArgs
  ) {
    lessonsPost(id: $id, idType: $idType) {
      id
      slug
      posts(first: $lessonPostsFirst, where: $lessonPostsWhere) {
        nodes {
          id
          slug
          title
          postFormat
          postFormatVideo {
            videoId
          }
          categories {
            nodes {
              id
              slug
              name
            }
          }
          featuredImage {
            node {
              id
              sourceUrl(size: LARGE)
              altText
            }
          }
          locale {
            id
            locale
          }
        }
      }
    }
  }
`;
