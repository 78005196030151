import { useState, useEffect } from 'react';

export function useKeyPress(targetKey: string, el = window): boolean {
    const [keyPressed, setKeyPressed] = useState(false);

    useEffect(() => {
        const keydownHandler = ({ key }) => (key === targetKey ? setKeyPressed(true) : null);
        const keyupHandler = ({ key }) => (key === targetKey ? setKeyPressed(false) : null);

        el.addEventListener('keydown', keydownHandler);
        el.addEventListener('keyup', keyupHandler);

        return () => {
            el.removeEventListener('keydown', keydownHandler);
            el.removeEventListener('keyup', keyupHandler);
        };
    }, [el, targetKey]);

    return keyPressed;
}
