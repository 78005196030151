import { useState, useRef, useEffect, FC } from 'react';
import { useRouter } from 'next/router';
import { useClickAway } from 'react-use';
import cn from 'classnames';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import Link from 'next/link';
import { PostProps } from 'components';
import { useBreakpoints } from 'hooks';
import { LESSON_NAVIGATION } from 'constants/queryParams';

import CloseIcon from '../../public/icons/close.svg';
import BookmarkIcon from '../../public/icons/bookmark.svg';

import styles from './LessonNavigation.module.scss';

interface LessonNavigationProps {
  title?: string | null;
  lessons: PostProps['relatedPosts'];
  currentLessonId: string;
}

export const LessonNavigation: FC<LessonNavigationProps> = ({
  title = 'Guide to Self Promotion',
  lessons = [],
  currentLessonId,
}) => {
  const { query, push } = useRouter();
  const { isBreakpointXXL } = useBreakpoints();

  const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);
  const menuContentRef = useRef<HTMLDivElement>(null);

  const isOpenMenuByDefault = !!query[LESSON_NAVIGATION];
  const isCloseButtonVisible = !isBreakpointXXL && isMenuVisible;

  const sortedLessonsWithOrder = lessons
    ?.filter((item) => item?.lessonsAdditionalFields?.order)
    .sort((a, b) => {
      const aOrder = a?.lessonsAdditionalFields?.order || 0;
      const bOrder = b?.lessonsAdditionalFields?.order || 0;

      return aOrder - bOrder;
    });
  const lessonsWithoutOrder = lessons?.filter(
    (item) => !item?.lessonsAdditionalFields?.order
  );

  const sortedLessonsByOrder = sortedLessonsWithOrder?.concat(
    lessonsWithoutOrder || []
  );

  const currentLessonsStep = sortedLessonsByOrder?.reduce((acc, val, index) => {
    if (val?.id === currentLessonId) {
      acc = index + 1;
    }

    return acc;
  }, 1);

  useClickAway(
    menuContentRef,
    () => {
      if (isCloseButtonVisible) {
        setMenuVisibility(false);
      }
    },
    ['click']
  );

  useEffect(() => {
    if (isOpenMenuByDefault && isBreakpointXXL) {
      setMenuVisibility(true);
    }
  }, [push, isOpenMenuByDefault, isBreakpointXXL]);

  useEffect(() => {
    if (isMenuVisible && !isBreakpointXXL) {
      disableBodyScroll(document.body);
    } else if (!isMenuVisible && !isBreakpointXXL) {
      enableBodyScroll(document.body);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isMenuVisible, isBreakpointXXL]);

  const handleMenuToggle = () => {
    if (isMenuVisible) {
      setMenuVisibility(false);
    } else {
      setMenuVisibility(true);
    }
  };

  const handleRootBlockClick = () => {
    if (!isMenuVisible && !isBreakpointXXL) {
      setMenuVisibility(true);
    }
  };

  const handleLinkClick = () => {
    if (isMenuVisible) {
      setMenuVisibility(false);
    }
  };

  if (!lessons?.length) {
    return null;
  }

  const CloseButton = () => {
    if (!isCloseButtonVisible) return null;

    return (
      <button
        className={styles.closeButton}
        onClick={handleMenuToggle}
        type="button"
        title="Close menu"
      >
        <CloseIcon className={styles.closeIcon} />
      </button>
    );
  };

  return (
    <div
      className={cn(styles.root, {
        [styles.open]: isMenuVisible,
      })}
      ref={menuContentRef}
      onClick={handleRootBlockClick}
      onKeyPress={handleRootBlockClick}
      tabIndex={0}
      role="button"
    >
      <CloseButton />
      <div className={styles.entry}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{title}</p>
          <BookmarkIcon
            className={styles.bookmarkIcon}
            aria-hidden="true"
            role="presentation"
            focusable="false"
          />
        </div>
        {/* TODO: translate block with ACF */}
        <p className={styles.description} data-testid="text-content-paragraph">
          This lesson is step {currentLessonsStep} /{' '}
          {sortedLessonsByOrder?.length} of a LANDR Lesson Plan.{' '}
          <Link href={`/${sortedLessonsByOrder?.[0]?.slug}`}>Click here</Link>{' '}
          to start from the beginning or keep on reading.
        </p>
        <button className={styles.toggleButton} onClick={handleMenuToggle}>
          Show lesson plan
        </button>
        <nav className={styles.nav}>
          <ul className={styles.list}>
            {sortedLessonsByOrder?.map((item, index) => {
              return (
                <li
                  className={cn(styles.menuItem, {
                    [styles.active]: item?.id === currentLessonId,
                  })}
                  key={item?.id}
                >
                  <Link
                    href={`/${item?.slug}`}
                    className={styles.link}
                    onClick={handleLinkClick}
                  >
                    <span className={styles.linkOrderNumber}>{index + 1}.</span>
                    {item?.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
};
