import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgDownloadOutlined = ({ size, title = 'DownloadOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M18.25 19.25H5.75h12.5zM12 5v9.75V5zm-4.5 5.5L12 15l-4.5-4.5zm9 0L12 15l4.5-4.5z"
            />
        </g>
    </svg>
);

export default withIcon(SvgDownloadOutlined);
