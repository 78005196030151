import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgBlog = ({ size, title = 'Blog', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 36 36"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="#142739"
            fillRule="evenodd"
            d="M9 2a1 1 0 00-1 1v27.25L5 34h26a3 3 0 003-3V3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
        />
        <path fill="#435261" d="M2 19a1 1 0 011-1h5v13a3 3 0 11-6 0V19z" />
        <rect width={16} height={10} x={13} y={7} fill="#435261" rx={1} />
        <rect width={16} height={1.5} x={13} y={21} fill="#F9FAFB" rx={0.75} />
        <rect width={12} height={1.5} x={13} y={26.5} fill="#F9FAFB" rx={0.75} />
    </svg>
);

export default withIcon(SvgBlog);
