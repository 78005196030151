import { FC, useEffect } from 'react';
import { YouTubePlayerProps } from './types';
import styles from './YouTubePlayer.module.scss';
import { LandrYoutubePlayer } from './components/LandrYoutubePlayer';
import { EmbeddedYoutubePlayer } from './components/EmbeddedYoutubePlayer';
import { useYouTubeVideosService } from 'components/YouTubeVideosContext';

type InternalYouTubePlayerProps = YouTubePlayerProps & {
  videoId: string;
};

const InternalYouTubePlayer: FC<InternalYouTubePlayerProps> = ({
  videoId,
  caption,
  isEmbedded = false,
  ...otherProps
}) => {
  const youTubeVideosService = useYouTubeVideosService();
  // Add video id to the list of videos to preload
  // Do not use 'useEffect' as it will not be called on SSR
  youTubeVideosService?.addVideo(videoId);

  const Caption = () => {
    if (!caption) return null;

    return (
      <p
        className={styles.caption}
        dangerouslySetInnerHTML={{ __html: caption }}
      />
    );
  };

  return (
    <div className={styles.root} data-testid="you-tube-player">
      {isEmbedded ? (
        <EmbeddedYoutubePlayer videoId={videoId} />
      ) : (
        <LandrYoutubePlayer
          videoId={videoId}
          caption={caption}
          {...otherProps}
        />
      )}
      <Caption />
    </div>
  );
};

export const YouTubePlayer: FC<YouTubePlayerProps> = (props) => {
  const getYouTubeId = (url: string) => {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
  };

  const videoId = getYouTubeId(props.link);

  if (!videoId) {
    return null;
  }

  return <InternalYouTubePlayer videoId={videoId} {...props} />;
};
