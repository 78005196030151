import { FC } from 'react';
import { Position } from 'components/Common';

import styles from './SignOff.module.scss';

interface SignOffProps {
  title?: string;
  text: string;
}

export const SignOff: FC<SignOffProps> = ({ title, text }) => {
  const SignOffTitle = () => {
    if (!title) return null;

    return (
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      ></p>
    );
  };
  return (
    <Position position="inline">
      <div className={styles.root}>
        <div className={styles.topBorder} />
        <SignOffTitle />
        <p className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </Position>
  );
};
