import {
  attributesToProps,
  domToReact,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { Element } from 'domhandler/lib/node';
import Link from 'next/link';
import { getHost, logger } from 'utils';
import { EventIds } from 'lib/EventIds';

export const getOptions = (path: string): HTMLReactParserOptions => {
  const siteHostUrl = getHost();

  return {
    replace: (domElement) => {
      // kludge as "dom instanceof Element" always returns "false"
      const domNode = domElement as Element;

      if (!domNode) {
        return null;
      }

      try {
        if (
          domNode.attribs &&
          domNode.name === 'a' &&
          domNode.attribs.href &&
          !domNode.attribs.href.startsWith('#')
        ) {
          const { href, ...props } = attributesToProps(domNode.attribs);
          const { host, pathname } = new URL(href, siteHostUrl);

          if (siteHostUrl) {
            const { host: siteHost } = new URL(siteHostUrl);

            // ensure that we change a link of an inner URL
            if (host.includes(siteHost)) {
              return (
                <Link href={pathname} locale={false} {...props}>
                  {domToReact(domNode.children)}
                </Link>
              );
            }
          }

          /**
           * Add "no-border" className only for cases if we have only one image inside a link tag
           */
          if (
            domNode.children.length === 1 &&
            (domNode.children[0] as any)?.name === 'img'
          ) {
            const props = attributesToProps(domNode.attribs);

            return <a {...props}>{domToReact(domNode.children)}</a>;
          }
        }
      } catch (error: any) {
        if (error.code === 'ERR_INVALID_URL') {
          logger.error(`Failed to parse URL`, EventIds.ParseError, error, {
            path,
            input: error.input,
            domNode: domNode.name,
            attributes: domNode.attribs,
          });
        } else {
          logger.error(
            `Failed to parse paragraph`,
            EventIds.ParseError,
            error,
            {
              path,
              domNode: domNode.name,
              attributes: domNode.attribs,
            }
          );
        }
      }

      return domNode;
    },
  };
};
