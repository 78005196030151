import { PostFragmentFragment } from 'types';

export const CategoryUrls = [
  '/category/beat-making/',
  '/category/resources/',
  '/category/songwriting-inspiration/',
  '/category/mixing-mastering/',
  '/category/music-distribution/',
  '/category/music-gear/',
  '/category/music-promotion/',
  '/category/music-theory/',
  '/category/music-recording/',
  '/category/vocal-production/',
  '/category/watch/',
  '/fr/category/from-landr-fr/',
  '/fr/category/mixage-mastering/',
  '/fr/category/distribution-musicale/',
  '/fr/category/theorie-musicale/',
  '/fr/category/materiel-de-musique/',
  '/fr/category/beatmaking/',
  '/fr/category/promotion-musicale/',
  '/fr/category/enregistrement/',
  '/fr/category/inspiration-fr/',
  '/fr/category/production-vocale/',
  '/fr/category/non-classifiee/',
  '/de/category/inspiration/',
  '/de/category/musik-ausrustung/',
  '/de/category/abmischung-mastering/',
  '/de/category/musiktheorie/',
  '/de/category/aufzeichnung/',
  '/de/category/distribution/',
  '/de/category/musik-promotion/',
  '/de/category/beat-making-de/',
  '/de/category/gesangsproduktion/',
  '/de/category/unkategorisiert/',
  '/it/category/distribucion-musical/',
  '/it/category/promozione-musicale/',
  '/it/category/strumenti-musicali/',
  '/it/category/registrazione/',
  '/it/category/teoria-musicale/',
  '/it/category/mix-mastering/',
  '/it/category/creatore-di-beat/',
  '/it/category/ispirazione/',
  '/it/category/produzione-vocale/',
  '/it/category/non-categorizzato-it/',
  '/ja/category/%e3%82%a4%e3%83%b3%e3%82%b9%e3%83%94%e3%83%ac%e3%83%bc%e3%82%b7%e3%83%a7%e3%83%b3/',
  '/ja/category/%e9%9f%b3%e6%a5%bd%e9%85%8d%e4%bf%a1/',
  '/ja/category/%e9%8c%b2%e9%9f%b3/',
  '/ja/category/%e9%9f%b3%e6%a5%bd%e7%90%86%e8%ab%96/',
  '/ja/category/%e9%9f%b3%e6%a5%bd%e3%83%97%e3%83%ad%e3%83%a2%e3%83%bc%e3%82%b7%e3%83%a7%e3%83%b3/',
  '/ja/category/%e3%83%93%e3%83%bc%e3%83%88%e3%83%a1%e3%82%a4%e3%82%ad%e3%83%b3%e3%82%b0/',
  '/ja/category/%e9%9f%b3%e6%a5%bd%e6%a9%9f%e5%99%a8/',
  '/ja/category/%e3%83%9f%e3%82%ad%e3%82%b7%e3%83%b3%e3%82%b0%e3%81%a8%e3%83%9e%e3%82%b9%e3%82%bf%e3%83%aa%e3%83%b3%e3%82%b0/',
  '/ja/category/%e3%82%ab%e3%83%86%e3%82%b4%e3%83%aa%e3%83%bc%e3%81%aa%e3%81%97/',
  '/pt-br/category/criacao-de-batidas/',
  '/pt-br/category/distribuicao/',
  '/pt-br/category/promocao-musica/',
  '/pt-br/category/teoria-musical/',
  '/pt-br/category/inspiracao/',
  '/pt-br/category/mixagem-masterizacao/',
  '/pt-br/category/gravacao/',
  '/pt-br/category/equipamento-musical/',
  '/pt-br/category/producao-vocal/',
  '/pt-br/category/nao-categorizado/',
  '/es/category/distribucion-musical/',
  '/es/category/teoria-musical/',
  '/es/category/promocion-musical/',
  '/es/category/equipo-de-creacion-musical/',
  '/es/category/mezcla-masterizacion/',
  '/es/category/grabacion/',
  '/es/category/creador-de-beats/',
  '/es/category/inspiracion/',
  '/es/category/produccion-vocal/',
  '/es/category/sin-categorizar/',
];

export interface CategoryRelation {
  [key: string]: string | undefined;
}

// The WPGraphQL WPML Extension does not establish relations between categories in different languages.
// We need to manually map each category

export const categoryRelations: CategoryRelation[] = [
  {
    en: '/category/beat-making/',
    fr: '/fr/category/beatmaking/',
    it: '/it/category/creatore-di-beat/',
    de: '/de/category/beat-making-de/',
    ja: '/ja/category/ビートメイキング/',
    pt: '/pt-br/category/criacao-de-batidas/',
    es: '/es/category/creador-de-beats/',
  },
  {
    en: '/category/from-landr/',
    fr: '/fr/category/from-landr-fr/',
  },
  {
    en: '/category/songwriting-inspiration/',
    fr: '/fr/category/inspiration-fr/',
    it: '/it/category/ispirazione/',
    de: '/de/category/inspiration/',
    ja: '/ja/category/インスピレーション/',
    pt: '/pt-br/category/inspiracao/',
    es: '/es/category/inspiracion/',
  },
  {
    en: '/category/mixing-mastering/',
    fr: '/fr/category/mixage-mastering/',
    it: '/it/category/mix-mastering/',
    de: '/de/category/abmischung-mastering/',
    ja: '/ja/category/ミキシングとマスタリング/',
    pt: '/pt-br/category/mixagem-masterizacao/',
    es: '/es/category/mezcla-masterizacion/',
  },
  {
    en: '/category/music-distribution/',
    fr: '/fr/category/distribution-musicale/',
    it: '/it/category/distribucion-musical/',
    de: '/de/category/distribution/',
    ja: '/ja/category/音楽配信/',
    pt: '/pt-br/category/distribuicao/',
    es: '/es/category/distribucion-musical/',
  },
  {
    en: '/category/music-gear/',
    fr: '/fr/category/materiel-de-musique/',
    it: '/it/category/strumenti-musicali/',
    de: '/de/category/musik-ausrustung/',
    ja: '/ja/category/音楽機器/',
    pt: '/pt-br/category/equipamento-musical/',
    es: '/es/category/equipo-de-creacion-musical/',
  },
  {
    en: '/category/music-promotion/',
    fr: '/fr/category/promotion-musicale/',
    it: '/it/category/promozione-musicale/',
    de: '/de/category/musik-promotion/',
    ja: '/ja/category/音楽プロモーション/',
    pt: '/pt-br/category/promocao-musica/',
    es: '/es/category/promocion-musical/',
  },
  {
    en: '/category/music-theory/',
    fr: '/fr/category/theorie-musicale/',
    it: '/it/category/teoria-musicale/',
    de: '/de/category/musiktheorie/',
    ja: '/ja/category/音楽理論/',
    pt: '/pt-br/category/teoria-musical/',
    es: '/es/category/teoria-musical/',
  },
  {
    en: '/category/music-recording/',
    fr: '/fr/category/enregistrement/',
    it: '/it/category/registrazione/',
    de: '/de/category/aufzeichnung/',
    ja: '/ja/category/録音/',
    pt: '/pt-br/category/gravacao/',
    es: '/es/category/grabacion/',
  },
  {
    en: '/category/resources/',
  },
  {
    en: '/category/uncategorized/',
    fr: '/fr/category/non-classifiee/',
    it: '/it/category/non-categorizzato-it/',
    de: '/de/category/unkategorisiert/',
    ja: '/ja/category/カテゴリーなし/',
    pt: '/pt-br/category/nao-categorizado/',
    es: '/es/category/sin-categorizar/',
  },
  {
    en: '/category/watch/',
    fr: '/fr/category/watch-fr/',
    it: '/it/category/watch-it/',
    de: '/de/category/watch-de/',
    ja: '/ja/category/watch-ja/',
    pt: '/pt-br/category/watch-pt-br/',
    es: '/es/category/watch-es/',
  },
  {
    en: '/category/vocal-production/',
    fr: '/fr/category/production-vocale/',
    de: '/de/category/gesangsproduktion/',
    it: '/it/category/produzione-vocale/',
    pt: '/pt-br/category/producao-vocal/',
    es: '/es/category/produccion-vocal/',
  },
];

export const categoryTranslations: PostFragmentFragment['translations'][] = [
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/beat-making/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/beatmaking/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/creatore-di-beat/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/beat-making-de/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/ビートメイキング/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/criacao-de-batidas/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/creador-de-beats/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/from-landr/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/from-landr-fr/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/songwriting-inspiration/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/inspiration-fr/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/ispirazione/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/inspiration/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/インスピレーション/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/inspiracao/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/inspiracion/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/mixing-mastering/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/mixage-mastering/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/mix-mastering/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/abmischung-mastering/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/ミキシングとマスタリング/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/mixagem-masterizacao/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/mezcla-masterizacion/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/music-distribution/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/distribution-musicale/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/distribucion-musical/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/distribution/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/音楽配信/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/distribuicao/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/distribucion-musical/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/music-gear/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/materiel-de-musique/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/strumenti-musicali/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/musik-ausrustung/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/音楽機器/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/equipamento-musical/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/equipo-de-creacion-musical/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/music-promotion/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/promotion-musicale/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/promozione-musicale/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/musik-promotion/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/音楽プロモーション/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/promocao-musica/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/promocion-musical/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/music-theory/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/theorie-musicale/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/teoria-musicale/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/musiktheorie/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/音楽理論/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/teoria-musical/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/teoria-musical/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/music-recording/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/enregistrement/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/registrazione/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/aufzeichnung/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/録音/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/gravacao/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/grabacion/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/resources/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/uncategorized/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/non-classifiee/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/non-categorizzato-it/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/unkategorisiert/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/カテゴリーなし/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/nao-categorizado/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/sin-categorizar/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/watch/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/watch-fr/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/watch-it/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/watch-de/',
    },
    {
      id: '',
      locale: 'ja',
      href: 'ja/category/watch-ja/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/watch-pt-br/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/watch-es/',
    },
  ],
  [
    {
      id: '',
      locale: 'en_US',
      href: 'category/vocal-production/',
    },
    {
      id: '',
      locale: 'fr_FR',
      href: 'fr/category/production-vocale/',
    },
    {
      id: '',
      locale: 'de_DE',
      href: 'de/category/gesangsproduktion/',
    },
    {
      id: '',
      locale: 'it_IT',
      href: 'it/category/produzione-vocale/',
    },
    {
      id: '',
      locale: 'pt_BR',
      href: 'pt-br/category/producao-vocal/',
    },
    {
      id: '',
      locale: 'es_ES',
      href: 'es/category/produccion-vocal/',
    },
  ],
];

export const getCategoryTranslations = (slug?: string) => {
  if (!slug || !categoryTranslations) return [];
  return (
    categoryTranslations.find((subArray) =>
      subArray?.some((obj) => obj?.href?.includes(slug))
    ) || []
  );
};
