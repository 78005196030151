import React, { useState, useEffect } from 'react';
import { ProgressProps } from './types';
import * as Styled from './styled';

type Props = ProgressProps & React.HTMLAttributes<SVGElement>;

export const Progress: React.FC<Props> = ({
    size = 'md',
    progress = 'indeterminate',
    transitionDelay = 850,
    strokeWidth = 3.6,
    ...props
}) => {
    const center = 44;
    const radius = 22 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const [offset, setOffset] = useState(circumference);
    const [isIndeterminate, setIsIndeterminate] = useState<boolean>(true);

    useEffect(() => {
        setIsIndeterminate(progress === 'indeterminate');
    }, [progress]);

    useEffect(() => {
        if (typeof progress !== 'number') {
            return;
        }

        const progressOffset = (1 - progress) * circumference;

        setOffset(progressOffset);
    }, [setOffset, circumference, progress, offset]);

    return (
        <Styled.ProgressWrapper
            size={size}
            progress={progress}
            title={isIndeterminate ? 'Loading' : 'Progress'}
            {...props}
            viewBox="22 22 44 44"
        >
            {!isIndeterminate && (
                <Styled.BackCircle
                    cx={center}
                    cy={center}
                    r={radius}
                    fill="none"
                    strokeWidth={strokeWidth}
                    stroke="currentColor"
                />
            )}
            <Styled.ProgressCircle
                cx={center}
                cy={center}
                r={radius}
                fill="none"
                strokeWidth={strokeWidth}
                stroke="currentColor"
                progress={progress}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                transitionDelay={transitionDelay}
            />
        </Styled.ProgressWrapper>
    );
};

Progress.displayName = 'Progress';
