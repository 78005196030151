import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgVideoSquareOutlined = ({ size, title = 'VideoSquareOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            stroke="currentColor"
            strokeWidth={1.5}
            d="M19 3.75H5c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25z"
        />
        <path
            stroke="currentColor"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M10.25 14.75l4.5-2.744-4.5-2.756v5.5z"
            clipRule="evenodd"
        />
    </svg>
);

export default withIcon(SvgVideoSquareOutlined);
