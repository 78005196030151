import styled from 'styled-components';
import { palette, spacing, themeMode, variant, radius } from '../../utils';
import { Box } from '../../primitives/Box';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { MessageVariant, MessageKind } from './types';

export const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    padding: 0;
`;

export const Title = styled(Heading)`
    margin-bottom: 0;
`;

export const Cta = styled(Button).attrs(() => ({ kind: 'link', size: 'sm' }))`
    margin-right: ${spacing('sm')};
    &:last-child {
        margin-right: 0;
    }
`;

export const ActionGroup = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${spacing('xs')};
`;

const messageVariants = variant({
    prop: 'variant',
    variants: {
        default: {
            backgroundColor: themeMode({
                light: 'rgba(108, 119, 130, 0.1)',
                dark: 'neutral.800',
            }),
        },
        subtle: {
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: themeMode({
                light: 'neutral.200',
                dark: 'neutral.700',
            }),
        },
        contrast: {
            backgroundColor: themeMode({
                light: 'rgba(50, 203, 203, 0.1)',
                dark: 'neutral.800',
            }),
        },
        success: {
            backgroundColor: themeMode({
                light: 'rgba(50, 203, 203, 0.1)',
                dark: 'neutral.800',
            }),
        },
        warning: {
            backgroundColor: themeMode({
                light: 'rgba(238, 194, 0, 0.1)',
                dark: 'neutral.800',
            }),
        },
        danger: {
            backgroundColor: themeMode({
                light: 'rgba(225, 21, 52, 0.1)',
                dark: 'neutral.800',
            }),
        },
    },
});

const messageKinds = variant({
    prop: 'kind',
    variants: {
        default: {},
        inline: { display: 'inline-flex', padding: 0, border: 'none', backgroundColor: 'transparent' },
    },
});

export const Message = styled(Box)<{ variant: MessageVariant; kind: MessageKind }>`
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: ${spacing('lg')};
    border-radius: ${radius('base')};
    color: ${themeMode({ light: palette('neutral.900'), dark: palette('neutral.50') })};

    ${messageVariants};
    ${messageKinds};
`;

const iconVariants = variant({
    prop: 'variant',
    variants: {
        default: {
            color: 'neutral.500',
        },
        subtle: {
            color: 'neutral.500',
        },
        contrast: {
            color: 'success.500',
        },
        success: {
            color: 'success.500',
        },
        warning: {
            color: 'warning.500',
        },
        danger: {
            color: 'danger.500',
        },
    },
});

export const IconWrapper = styled(Box)<{ variant: MessageVariant }>`
    display: inline-flex;
    flex: 0 0 auto;
    align-items: center;
    margin-right: ${spacing('sm')};

    ${iconVariants};
`;
