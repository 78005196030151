import React from 'react';
import { withIcon, SvgIconProps } from '../../../../components/withIcon';

const SvgTiktokOutlined = ({ size, title = 'TiktokOutlined', ...props }: SvgIconProps): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width={size}
        height={size}
        data-icon-name={title}
        aria-label={title}
        role="presentation"
        {...props}
    >
        <path
            fill="currentColor"
            d="M16.058 8.007a3.582 3.582 0 01-1.19-1.296A3.608 3.608 0 0114.433 5H11.87l-.004 10.335a2.174 2.174 0 01-.403 1.18 2.142 2.142 0 01-3.302.238 2.171 2.171 0 01-.427-2.351 2.17 2.17 0 01.795-.959 2.143 2.143 0 011.189-.357c.216 0 .43.034.636.1v-2.633a4.68 4.68 0 00-.636-.046C7.116 10.507 5 12.636 5 15.253a4.769 4.769 0 001.384 3.355A4.71 4.71 0 009.718 20c2.601 0 4.717-2.129 4.717-4.747v-5.24A6.07 6.07 0 0018 11.167v-2.58c-.69.001-1.364-.2-1.942-.58z"
        />
    </svg>
);

export default withIcon(SvgTiktokOutlined);
